<template>
  <canvas :id="'mainCanvas' + uniq" ref="mainCanvas" :width="160 * scale" :height="184 * scale" />
</template>
<script>
// let AvatarStyleKit = require('../../../static/js/AvatarStyleKit.js')
import AvatarStyleKit from '../../../public/js/AvatarStyleKit.js'

export default {
  name: 'AvatarView',
  props: ['data', 'uniq', 'scaleFactor'],
  data () {
    return {
      scale: 1,
      accessory: '',
      face: '',
      backgroundType: 'plain',
      backgroundColor: 'col12',
      shirtType: 'plain',
      eyeType: 'normal',
      mouthType: 'happy',
      hairType: 'boy1',
      hairColor: 'hair1',
      eyeColor: 'eye1',
      skinColor: 'skin1',
      shirtColor: 'col1',
      shirtColor2: 'col2'
    }
  },
  watch: {
    data (data, oldVal) {
      // console.log(JSON.stringify(data))
      if (this.data.accessory !== undefined) {
        this.accessory = this.data.accessory
      }
      if (this.data.face !== undefined) {
        this.face = this.data.face
      }
      if (this.data.backgroundType) {
        this.backgroundType = this.data.backgroundType
      }
      if (this.data.backgroundColor) {
        this.backgroundColor = this.data.backgroundColor
      }
      if (this.data.shirtType) {
        this.shirtType = this.data.shirtType
      }
      if (this.data.eyeType) {
        this.eyeType = this.data.eyeType
      }
      if (this.data.mouthType) {
        this.mouthType = this.data.mouthType
      }
      if (this.data.hairType) {
        this.hairType = this.data.hairType
      }
      if (this.data.hairColor) {
        this.hairColor = this.data.hairColor
      }
      if (this.data.eyeColor) {
        this.eyeColor = this.data.eyeColor
      }
      if (this.data.skinColor) {
        this.skinColor = this.data.skinColor
      }
      if (this.data.shirtColor) {
        this.shirtColor = this.data.shirtColor
      }
      if (this.data.shirtColor2) {
        this.shirtColor2 = this.data.shirtColor2
      }
      const canvas = this.$refs.mainCanvas
      const context = canvas.getContext('2d')

      context.clearRect(0, 0, canvas.width, canvas.height)
      this.drawAvatar()
    }
  },
  created () {
    if (this.scaleFactor) {
      this.scale = parseFloat(this.scaleFactor)
    }
  },
  mounted () {
    this.$nextTick(() => {
      // drawAvatarImage(canvas, accessory, backgroundType, shirtType, eyeType, mouthType, hairType, hairColor, eyeColor, skinColor, shirtColor, shirtColor2, backgroundColor, targetFrame, resizing)
      if (this.data) {
        if (this.data.accessory) {
          this.accessory = this.data.accessory
        }
        if (this.data.face) {
          this.face = this.data.face
        }
        if (this.data.backgroundType) {
          this.backgroundType = this.data.backgroundType
        }
        if (this.data.backgroundColor) {
          this.backgroundColor = this.data.backgroundColor
        }
        if (this.data.shirtType) {
          this.shirtType = this.data.shirtType
        }
        if (this.data.eyeType) {
          this.eyeType = this.data.eyeType
        }
        if (this.data.mouthType) {
          this.mouthType = this.data.mouthType
        }
        if (this.data.hairType) {
          this.hairType = this.data.hairType
        }
        if (this.data.hairColor) {
          this.hairColor = this.data.hairColor
        }
        if (this.data.eyeColor) {
          this.eyeColor = this.data.eyeColor
        }
        if (this.data.skinColor) {
          this.skinColor = this.data.skinColor
        }
        if (this.data.shirtColor) {
          this.shirtColor = this.data.shirtColor
        }
        if (this.data.shirtColor2) {
          this.shirtColor2 = this.data.shirtColor2
        }
      }
      this.drawAvatar()
    })
  },
  methods: {
    drawAvatar () {
      AvatarStyleKit.drawAvatarImage('mainCanvas' + this.uniq, this.accessory, this.face, this.backgroundType, this.shirtType, this.eyeType, this.mouthType, this.hairType, this.hairColor, this.eyeColor, this.skinColor, this.shirtColor, this.shirtColor2, this.backgroundColor, AvatarStyleKit.makeRect(0, 0, 160 * this.scale, 184 * this.scale), 'aspectfit')
    }
  }
}
</script>
