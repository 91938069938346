//
//  AvatarStyleKit.js
//  SpellingShedAvatars
//
//  Created by MartinSaunders on 15/08/2019.
//  Copyright © 2019 SpellingShed. All rights reserved.
//
//  Generated by PaintCode
//  http://www.paintcodeapp.com
//



//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    AvatarStyleKit.drawAvatarImage(canvas, accessory, face, backgroundType, shirtType, eyeType, mouthType, hairType, hairColor, eyeColor, skinColor, shirtColor, shirtColor2, backgroundColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawJesterHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSeaBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainBackground(canvas, backgroundColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawDesertBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBeachBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSpaceBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawMountainsBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBlueScarf(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawForestBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground7(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPirateHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground6(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground8(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground3(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground5(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground4(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground2(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground1(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSquintEyes(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawEyesNormal(canvas, eyeColor, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawWideEyes(canvas, eyeColor, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawClosedEyes(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawWideOpenEyes(canvas, eyeColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawWideTiredEyes(canvas, eyeColor, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawSmileMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawUnhappyMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWorriedMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSadMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSmirkMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGrinMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawAnnoyedMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawTongueMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSmilingMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawLipsMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawEekMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawOMouth(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair6(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair1(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair2(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair3(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair4(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair5(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair7(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair8(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair9(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair10(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair1(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair2(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair3(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair4(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair5(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair6(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair7(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair8(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair9(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair10(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawCatMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPandaMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawDisguiseMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSnorkel(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBatmanMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPuppyMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawEyePatch(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawHeadScarf(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawScaryMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawAstronautHelmet(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSombrero(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPinkCap(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPinkWoolyHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPinkScarf(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFlowerHairband(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBlueWoolyHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPinkHeadphones(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawRoundGlasses(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawCrown(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawStarHeadband(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawAngledGlasses(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBlueTassleHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGreenHeadphones(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawCoolShades(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGreenHeadband(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBlueCap(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawOrangeHeadband(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawDarkShades(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGreenWoolyHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirt(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtPocket(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawVNeck(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawButtons(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawCollar(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawButtons2(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawVNeckCollar(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawWideNeck(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtHex(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtCircle(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtStripe(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtStripe2(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtZip(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawCollarButtons(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtZigZag(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPlainShirtWave(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawPullStrings(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawTassles(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground9(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawPatternBackground10(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawEgyptBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawCastleBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawHeartsBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawRainbowBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSnowyBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSeaBackground2(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawConstellationsBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawArtBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWorldBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWorldBackground2(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawTreasureMapBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawMusicBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawRomanBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawChemistryBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBalloonsBackground(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair11(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair12(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair13(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair14(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair15(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawGirlHair16(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair11(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair12(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair13(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair14(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair15(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair16(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawBoyHair17(canvas, hairColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawPigMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawElephantMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGiraffeMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawLionMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawTigerMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawReindeerMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawZebraMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawDogMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawUnicornMaskBk(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawMonkeyMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawUnicornMask(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFlowerShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawPearlsShirt(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawMidStripeShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawVFrillCollarShirt(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawHZigZagShirt(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawHijabShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawHalloweenShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawBowtieShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawTieShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawCheckShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame*, resizing*)
//    AvatarStyleKit.drawWandShirt(canvas, skinColor, shirtColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt1(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt2(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt3(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt4(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt5(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt6(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt7(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt8(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt9(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt10(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt11(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt12(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt13(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt14(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt15(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt16(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt17(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt18(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt19(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt20(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt21(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt22(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt23(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawFootballShirt24(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawRedFlower(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawLaurel(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFaceLightning(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWizardHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGreenHairBow(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawCowboyHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWitchHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawMouseNose(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawRabbitEars(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSmallGreenBows(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawGoldHoopEarrings(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawHairPearls(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSquareGlasses(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawVikingHelmet(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSmallbeard(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawEgyptHelmet(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawRedBeret(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawWhiteGlasses(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBackwardsCap(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFreckles(canvas, skinColor, targetFrame*, resizing*)
//    AvatarStyleKit.drawLongMustache(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawKippah(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawLinesGlasses(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawSantaHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawChefHat(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBeeWings(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawBeeHeadband(canvas, targetFrame*, resizing*)
//    AvatarStyleKit.drawFaceWand(canvas, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//
// Available Colors:
//    AvatarStyleKit.col1
//    AvatarStyleKit.col2
//    AvatarStyleKit.col3
//    AvatarStyleKit.col4
//    AvatarStyleKit.col5
//    AvatarStyleKit.col6
//    AvatarStyleKit.col7
//    AvatarStyleKit.col8
//    AvatarStyleKit.col9
//    AvatarStyleKit.col10
//    AvatarStyleKit.col11
//    AvatarStyleKit.col12
//    AvatarStyleKit.col13
//    AvatarStyleKit.col14
//    AvatarStyleKit.col15
//    AvatarStyleKit.col16
//    AvatarStyleKit.col17
//    AvatarStyleKit.col18
//    AvatarStyleKit.col19
//    AvatarStyleKit.col20
//    AvatarStyleKit.skin1
//    AvatarStyleKit.skin2
//    AvatarStyleKit.skin3
//    AvatarStyleKit.skin4
//    AvatarStyleKit.skin5
//    AvatarStyleKit.skin6
//    AvatarStyleKit.skin7
//    AvatarStyleKit.skin8
//    AvatarStyleKit.skin9
//    AvatarStyleKit.skin1Secondary
//    AvatarStyleKit.skin2Secondary
//    AvatarStyleKit.skin3Secondary
//    AvatarStyleKit.skin4Secondary
//    AvatarStyleKit.skin6Secondary
//    AvatarStyleKit.skin7Secondary
//    AvatarStyleKit.skin8Secondary
//    AvatarStyleKit.skin9Secondary
//    AvatarStyleKit.skin9Tertiary
//    AvatarStyleKit.hair1
//    AvatarStyleKit.hair2
//    AvatarStyleKit.hair3
//    AvatarStyleKit.hair4
//    AvatarStyleKit.hair5
//    AvatarStyleKit.hair6
//    AvatarStyleKit.hair7
//    AvatarStyleKit.hair8
//    AvatarStyleKit.hair9
//    AvatarStyleKit.hair10
//    AvatarStyleKit.hair11
//    AvatarStyleKit.hair12
//    AvatarStyleKit.hair13
//    AvatarStyleKit.hair14
//    AvatarStyleKit.hair15
//    AvatarStyleKit.hair16
//    AvatarStyleKit.hair17
//    AvatarStyleKit.hair18
//    AvatarStyleKit.hair19
//    AvatarStyleKit.hair20
//    AvatarStyleKit.hair21
//    AvatarStyleKit.hair22
//    AvatarStyleKit.hair23
//
// Available Utilities:
//    AvatarStyleKit.clearCanvas(canvas)
//    AvatarStyleKit.makeRect(x, y, width, height)


//// Create StyleKit Object
var AvatarStyleKit = {};
(function() {

    //// Drawing Methods

    function drawAvatarImage(canvas, accessory, face, backgroundType, shirtType, eyeType, mouthType, hairType, hairColor, eyeColor, skinColor, shirtColor, shirtColor2, backgroundColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 160, 184), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 160, resizedFrame.h / 184);


        //// Color Declarations
        var skin5Secondary = 'rgba(185, 146, 111, 1)';
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _showPirateHat = accessory === 'pirateHat';
        var _showJesterHat = accessory === 'jesterHat';
        var _showBlueScarf = accessory === 'blueScarf';
        var _showHeadScarf = accessory === 'headScarf';
        var _showAstronaut = accessory === 'astronaut';
        var _showSombrero = accessory === 'sombrero';
        var _showPinkCap = accessory === 'pinkCap';
        var _showPinkWoolyHat = accessory === 'pinkWoolyHat';
        var _showPinkScarf = accessory === 'pinkScarf';
        var _showFlowerHairband = accessory === 'flowerHairband';
        var _showBlueWoolyHat = accessory === 'blueWoolyHat';
        var _showPinkHeadphones = accessory === 'pinkHeadphones';
        var _showCrown = accessory === 'crown';
        var _showStarHeadband = accessory === 'starHeadband';
        var _showBlueTassleHat = accessory === 'blueTassleHat';
        var _showGreenHeadphones = accessory === 'greenHeadphones';
        var _showGreenHeadband = accessory === 'greenHeadband';
        var _showBlueCap = accessory === 'blueCap';
        var _showOrangeHeadband = accessory === 'orangeHeadband';
        var _showGreenWoolyHat = accessory === 'greenWoolyHat';
        var _showredFlower = accessory === 'redflower';
        var _showLaurel = accessory === 'laurel';
        var _showWizardHat = accessory === 'wizardhat';
        var _showGreenHairBow = accessory === 'greenhairbow';
        var _showCowboyHat = accessory === 'cowboyhat';
        var _showWitchHat = accessory === 'witchhat';
        var _showRabbitEarHeadband = accessory === 'rabbitearsheadband';
        var _showSmallGreenHairBows = accessory === 'smallgreenbows';
        var _showHairPearls = accessory === 'hairpearls';
        var _showHelmetViking = accessory === 'helmetviking';
        var _showHelmetEgypt = accessory === 'helmetegypt';
        var _showHatBeretRed = accessory === 'hatberetred';
        var _showHatCapBackwards = accessory === 'hatcapbackwards';
        var _showHatKippah = accessory === 'hatkippah';
        var _showHatSanta = accessory === 'hatsanta';
        var _showHatChef = accessory === 'hatchef';
        var _showHeadbandBee = accessory === 'headbandbee';
        var _showCatMask = accessory === 'catMask' || face === 'catMask';
        var _showPuppyMask = accessory === 'puppyMask' || face === 'puppyMask';
        var _showPandaMask = accessory === 'pandaMask' || face === 'pandaMask';
        var _showDisguiseMask = accessory === 'disguiseMask' || face === 'disguiseMask';
        var _showSnorkel = accessory === 'snorkel' || face === 'snorkel';
        var _showEyePatch = accessory === 'eyePatch' || face === 'eyePatch';
        var _showBatmanMask = accessory === 'batmanMask' || face === 'batmanMask';
        var _showScaryMask = accessory === 'scaryMask' || face === 'scaryMask';
        var _showRoundGlasses = accessory === 'roundGlasses' || face === 'roundGlasses';
        var _showAngledGlasses = accessory === 'angledGlasses' || face === 'angledGlasses';
        var _showCoolShades = accessory === 'coolShades' || face === 'coolShades';
        var _showDarkShades = accessory === 'darkShades' || face === 'darkShades';
        var _showPigMask = face === 'pigMask';
        var _showElephantMask = face === 'elephantMask';
        var _showGiraffeMask = face === 'giraffeMask';
        var _showLionMask = face === 'lionMask';
        var _showTigerMask = face === 'tigerMask';
        var _showReindeerMask = face === 'reindeerMask';
        var _showZebraMask = face === 'zebraMask';
        var _showDogMask = face === 'dogMask';
        var _showUnicornMask = face === 'unicornMask';
        var _showMonkeyMask = face === 'monkeyMask';
        var _showFaceLightning = face === 'facelightning';
        var _showFaceMouseNose = face === 'facemousenose';
        var _showGoldHoopEarrings = face === 'goldhoopearrings';
        var _showSquareGlasses = face === 'squareglasses';
        var _showBeardSmall = face === 'beardsmall';
        var _showRoundGlassesWhite = face === 'roundglasseswhite';
        var _showLongMustache = face === 'longmustache';
        var _showGlassesLines = face === 'glasseslines';
        var _showWingsBee = face === 'wingsbee';
        var _showWand = face === 'wand';
        var _backgroundPlainVisible = backgroundType === '' || backgroundType === 'plain';
        var _backgroundPattern1Visible = backgroundType === 'pattern1';
        var _backgroundPattern2Visible = backgroundType === 'pattern2';
        var _backgroundPattern3Visible = backgroundType === 'pattern3';
        var _backgroundPattern4Visible = backgroundType === 'pattern4';
        var _backgroundPattern5Visible = backgroundType === 'pattern5';
        var _backgroundPattern6Visible = backgroundType === 'pattern6';
        var _backgroundPattern7Visible = backgroundType === 'pattern7';
        var _backgroundPattern8Visible = backgroundType === 'pattern8';
        var _backgroundPattern9Visible = backgroundType === 'pattern9';
        var _backgroundPattern10Visible = backgroundType === 'pattern10';
        var _backgroundSeaVisible = backgroundType === 'sea';
        var _backgroundDesertVisible = backgroundType === 'desert';
        var _backgroundBeachVisible = backgroundType === 'beach';
        var _backgroundSpaceVisible = backgroundType === 'space';
        var _backgroundMountainsVisible = backgroundType === 'mountains';
        var _backgroundForestVisible = backgroundType === 'forest';
        var _backgroundEgyptVisible = backgroundType === 'egypt';
        var _backgroundCastleVisible = backgroundType === 'castle';
        var _backgroundHeartsVisible = backgroundType === 'hearts';
        var _backgroundRainbowVisible = backgroundType === 'rainbow';
        var _backgroundSnowyVisible = backgroundType === 'snowy';
        var _backgroundSea2Visible = backgroundType === 'sea2';
        var _backgroundZodiacVisible = backgroundType === 'zodiac';
        var _backgroundArtVisible = backgroundType === 'art';
        var _backgroundWorldVisible = backgroundType === 'world';
        var _backgroundWorld2Visible = backgroundType === 'world2';
        var _backgroundMapVisible = backgroundType === 'map';
        var _backgroundMusicVisible = backgroundType === 'music';
        var _backgroundRomansVisible = backgroundType === 'romans';
        var _backgroundChemistryVisible = backgroundType === 'chemistry';
        var _backgroundFootballVisible = backgroundType === 'football';
        var _backgroundBalloonsVisible = backgroundType === 'balloons';
        var _shirtPlain = shirtType === '' || shirtType === 'plain';
        var _shirtPocket = shirtType === 'pocket';
        var _shirtVeeNeck = shirtType === 'vee';
        var _shirtButtons = shirtType === 'buttons';
        var _shirtCollar = shirtType === 'collar';
        var _shirtButtons2 = shirtType === 'buttons2';
        var _shirtVeeCollar = shirtType === 'veeCollar';
        var _shirtWide = shirtType === 'wide';
        var _shirtHex = shirtType === 'hex';
        var _shirtCircle = shirtType === 'circle';
        var _shirtStripe = shirtType === 'stripe';
        var _shirtStripe2 = shirtType === 'stripe2';
        var _shirtZip = shirtType === 'zip';
        var _shirtCollarButtons = shirtType === 'collarButtons';
        var _shirtZigZag = shirtType === 'zigZag';
        var _shirtWave = shirtType === 'wave';
        var _shirtPullStrings = shirtType === 'pullStrings';
        var _shirtTassles = shirtType === 'tassles';
        var _shirtFlower = shirtType === 'shirtflower';
        var _shirtPearls = shirtType === 'shirtpearls';
        var _shirtMidStripe = shirtType === 'shirtmidstripe';
        var _shirtVFrills = shirtType === 'shirtvfrills';
        var _shirtHZigZag = shirtType === 'shirthzigzag';
        var _shirtHijab = shirtType === 'shirthijab';
        var _shirtHalloween = shirtType === 'shirthalloween';
        var _shirtBowtie = shirtType === 'shirtbowtie';
        var _shirtTie = shirtType === 'shirttie';
        var _shirtCheck = shirtType === 'shirtcheck';
        var _shirtWand = shirtType === 'shirtwand';
        var _shirtFootball1 = shirtType === 'shirtfootball1';
        var _shirtFootball2 = shirtType === 'shirtfootball2';
        var _shirtFootball3 = shirtType === 'shirtfootball3';
        var _shirtFootball4 = shirtType === 'shirtfootball4';
        var _shirtFootball5 = shirtType === 'shirtfootball5';
        var _shirtFootball6 = shirtType === 'shirtfootball6';
        var _shirtFootball7 = shirtType === 'shirtfootball7';
        var _shirtFootball8 = shirtType === 'shirtfootball8';
        var _shirtFootball9 = shirtType === 'shirtfootball9';
        var _shirtFootball10 = shirtType === 'shirtfootball10';
        var _shirtFootball11 = shirtType === 'shirtfootball11';
        var _shirtFootball12 = shirtType === 'shirtfootball12';
        var _shirtFootball13 = shirtType === 'shirtfootball13';
        var _shirtFootball14 = shirtType === 'shirtfootball14';
        var _shirtFootball15 = shirtType === 'shirtfootball15';
        var _shirtFootball16 = shirtType === 'shirtfootball16';
        var _shirtFootball17 = shirtType === 'shirtfootball17';
        var _shirtFootball18 = shirtType === 'shirtfootball18';
        var _shirtFootball19 = shirtType === 'shirtfootball19';
        var _shirtFootball20 = shirtType === 'shirtfootball20';
        var _shirtFootball21 = shirtType === 'shirtfootball21';
        var _shirtFootball22 = shirtType === 'shirtfootball22';
        var _shirtFootball23 = shirtType === 'shirtfootball23';
        var _shirtFootball24 = shirtType === 'shirtfootball24';
        var _eyesSquintVisible = eyeType === 'squint';
        var _eyesNormalVisible = eyeType === '' || eyeType === 'normal';
        var _eyesWideVisible = eyeType === 'wide';
        var _eyesClosedVisible = eyeType === 'closed';
        var _eyesWideOpenVisible = eyeType === 'wideOpen';
        var _eyesWideTiredVisible = eyeType === 'wideTired';
        var _happyMouthVisible = mouthType === 'happy';
        var _unhappyMouthVisible = mouthType === 'unhappy';
        var _worriedMouthVisible = mouthType === 'worried';
        var _sadMouthVisible = mouthType === 'sad';
        var _smirkMouthVisible = mouthType === 'smirk';
        var _grinMouthVisible = mouthType === 'grin';
        var _annoyedMouthVisible = mouthType === 'annoyed';
        var _tongueMouthVisible = mouthType === 'tongue';
        var _smilingMouthVisible = mouthType === 'smiling';
        var _lipsMouthVisible = mouthType === 'lips';
        var _eekMouthVisible = mouthType === 'eek';
        var _ohMouthVisible = mouthType === 'oh';
        var _boyHair1Visible = hairType === 'boy1';
        var _boyHair2Visible = hairType === 'boy2';
        var _boyHair3Visible = hairType === 'boy3';
        var _boyHair4Visible = hairType === 'boy4';
        var _boyHair5Visible = hairType === 'boy5';
        var _boyHair6Visible = hairType === 'boy6';
        var _boyHair7Visible = hairType === 'boy7';
        var _boyHair8Visible = hairType === 'boy8';
        var _boyHair9Visible = hairType === 'boy9';
        var _boyHair10Visible = hairType === 'boy10';
        var _boyHair11Visible = hairType === 'boy11';
        var _boyHair12Visible = hairType === 'boy12';
        var _boyHair13Visible = hairType === 'boy13';
        var _boyHair14Visible = hairType === 'boy14';
        var _boyHair15Visible = hairType === 'boy15';
        var _boyHair16Visible = hairType === 'boy16';
        var _boyHair17Visible = hairType === 'boy17';
        var _girlHair1Visible = hairType === 'girl1';
        var _girlHair2Visible = hairType === 'girl2';
        var _girlHair3Visible = hairType === 'girl3';
        var _girlHair4Visible = hairType === 'girl4';
        var _girlHair5Visible = hairType === 'girl5';
        var _girlHair6Visible = hairType === 'girl6';
        var _girlHair7Visible = hairType === 'girl7';
        var _girlHair8Visible = hairType === 'girl8';
        var _girlHair9Visible = hairType === 'girl9';
        var _girlHair10Visible = hairType === 'girl10';
        var _girlHair11Visible = hairType === 'girl11';
        var _girlHair12Visible = hairType === 'girl12';
        var _girlHair13Visible = hairType === 'girl13';
        var _girlHair14Visible = hairType === 'girl14';
        var _girlHair15Visible = hairType === 'girl15';
        var _girlHair16Visible = hairType === 'girl16';
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _noseColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin1Secondary : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin2Secondary : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin3Secondary : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin4Secondary : (_skinColor === AvatarStyleKit.skin5 ? skin5Secondary : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin6Secondary : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin7Secondary : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin8Secondary : AvatarStyleKit.skin9Secondary)))))));

        if (_backgroundPlainVisible)
        {
            //// backgroundPlain Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPlainRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPlainRect.x, backgroundPlainRect.y, backgroundPlainRect.w, backgroundPlainRect.h);
            context.clip();
            context.translate(backgroundPlainRect.x, backgroundPlainRect.y);

            AvatarStyleKit.drawPlainBackground(canvas, backgroundColor, makeRect(0, 0, backgroundPlainRect.w, backgroundPlainRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern10Visible)
        {
            //// backgroundPattern10 Drawing
            var backgroundPattern10Rect = makeRect(4, 25, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern10Rect.x, backgroundPattern10Rect.y, backgroundPattern10Rect.w, backgroundPattern10Rect.h);
            context.clip();
            context.translate(backgroundPattern10Rect.x, backgroundPattern10Rect.y);

            AvatarStyleKit.drawPatternBackground10(canvas, makeRect(0, 0, backgroundPattern10Rect.w, backgroundPattern10Rect.h), 'stretch');
            context.restore();
        }


        if (_backgroundPattern9Visible)
        {
            //// backgroundPattern9 Drawing
            var backgroundPattern9Rect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern9Rect.x, backgroundPattern9Rect.y, backgroundPattern9Rect.w, backgroundPattern9Rect.h);
            context.clip();
            context.translate(backgroundPattern9Rect.x, backgroundPattern9Rect.y);

            AvatarStyleKit.drawPatternBackground9(canvas, makeRect(0, 0, backgroundPattern9Rect.w, backgroundPattern9Rect.h), 'stretch');
            context.restore();
        }


        if (_backgroundPattern8Visible)
        {
            //// backgroundPattern8 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern8Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern8Rect.x, backgroundPattern8Rect.y, backgroundPattern8Rect.w, backgroundPattern8Rect.h);
            context.clip();
            context.translate(backgroundPattern8Rect.x, backgroundPattern8Rect.y);

            AvatarStyleKit.drawPatternBackground8(canvas, makeRect(0, 0, backgroundPattern8Rect.w, backgroundPattern8Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern7Visible)
        {
            //// backgroundPattern7 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern7Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern7Rect.x, backgroundPattern7Rect.y, backgroundPattern7Rect.w, backgroundPattern7Rect.h);
            context.clip();
            context.translate(backgroundPattern7Rect.x, backgroundPattern7Rect.y);

            AvatarStyleKit.drawPatternBackground7(canvas, makeRect(0, 0, backgroundPattern7Rect.w, backgroundPattern7Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern6Visible)
        {
            //// backgroundPattern6 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern6Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern6Rect.x, backgroundPattern6Rect.y, backgroundPattern6Rect.w, backgroundPattern6Rect.h);
            context.clip();
            context.translate(backgroundPattern6Rect.x, backgroundPattern6Rect.y);

            AvatarStyleKit.drawPatternBackground6(canvas, makeRect(0, 0, backgroundPattern6Rect.w, backgroundPattern6Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern5Visible)
        {
            //// backgroundPattern5 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern5Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern5Rect.x, backgroundPattern5Rect.y, backgroundPattern5Rect.w, backgroundPattern5Rect.h);
            context.clip();
            context.translate(backgroundPattern5Rect.x, backgroundPattern5Rect.y);

            AvatarStyleKit.drawPatternBackground5(canvas, makeRect(0, 0, backgroundPattern5Rect.w, backgroundPattern5Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern4Visible)
        {
            //// backgroundPattern4 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern4Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern4Rect.x, backgroundPattern4Rect.y, backgroundPattern4Rect.w, backgroundPattern4Rect.h);
            context.clip();
            context.translate(backgroundPattern4Rect.x, backgroundPattern4Rect.y);

            AvatarStyleKit.drawPatternBackground4(canvas, makeRect(0, 0, backgroundPattern4Rect.w, backgroundPattern4Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern3Visible)
        {
            //// backgroundPattern3 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern3Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern3Rect.x, backgroundPattern3Rect.y, backgroundPattern3Rect.w, backgroundPattern3Rect.h);
            context.clip();
            context.translate(backgroundPattern3Rect.x, backgroundPattern3Rect.y);

            AvatarStyleKit.drawPatternBackground3(canvas, makeRect(0, 0, backgroundPattern3Rect.w, backgroundPattern3Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern2Visible)
        {
            //// backgroundPattern2 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern2Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern2Rect.x, backgroundPattern2Rect.y, backgroundPattern2Rect.w, backgroundPattern2Rect.h);
            context.clip();
            context.translate(backgroundPattern2Rect.x, backgroundPattern2Rect.y);

            AvatarStyleKit.drawPatternBackground2(canvas, makeRect(0, 0, backgroundPattern2Rect.w, backgroundPattern2Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundPattern1Visible)
        {
            //// backgroundPattern1 Drawing
            context.save();
            context.translate(4, 158);

            var backgroundPattern1Rect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundPattern1Rect.x, backgroundPattern1Rect.y, backgroundPattern1Rect.w, backgroundPattern1Rect.h);
            context.clip();
            context.translate(backgroundPattern1Rect.x, backgroundPattern1Rect.y);

            AvatarStyleKit.drawPatternBackground1(canvas, makeRect(0, 0, backgroundPattern1Rect.w, backgroundPattern1Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundBalloonsVisible)
        {
            //// backgroundBalloons Drawing
            var backgroundBalloonsRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundBalloonsRect.x, backgroundBalloonsRect.y, backgroundBalloonsRect.w, backgroundBalloonsRect.h);
            context.clip();
            context.translate(backgroundBalloonsRect.x, backgroundBalloonsRect.y);

            AvatarStyleKit.drawBalloonsBackground(canvas, makeRect(0, 0, backgroundBalloonsRect.w, backgroundBalloonsRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundFootballVisible)
        {
            //// backgroundFootball Drawing
            var backgroundFootballRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundFootballRect.x, backgroundFootballRect.y, backgroundFootballRect.w, backgroundFootballRect.h);
            context.clip();
            context.translate(backgroundFootballRect.x, backgroundFootballRect.y);

            AvatarStyleKit.drawFootballBackground(canvas, makeRect(0, 0, backgroundFootballRect.w, backgroundFootballRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundChemistryVisible)
        {
            //// backgroundChemistry Drawing
            var backgroundChemistryRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundChemistryRect.x, backgroundChemistryRect.y, backgroundChemistryRect.w, backgroundChemistryRect.h);
            context.clip();
            context.translate(backgroundChemistryRect.x, backgroundChemistryRect.y);

            AvatarStyleKit.drawChemistryBackground(canvas, makeRect(0, 0, backgroundChemistryRect.w, backgroundChemistryRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundRomansVisible)
        {
            //// backgroundRomans Drawing
            var backgroundRomansRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundRomansRect.x, backgroundRomansRect.y, backgroundRomansRect.w, backgroundRomansRect.h);
            context.clip();
            context.translate(backgroundRomansRect.x, backgroundRomansRect.y);

            AvatarStyleKit.drawRomanBackground(canvas, makeRect(0, 0, backgroundRomansRect.w, backgroundRomansRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundMusicVisible)
        {
            //// backgroundMusic Drawing
            var backgroundMusicRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundMusicRect.x, backgroundMusicRect.y, backgroundMusicRect.w, backgroundMusicRect.h);
            context.clip();
            context.translate(backgroundMusicRect.x, backgroundMusicRect.y);

            AvatarStyleKit.drawMusicBackground(canvas, makeRect(0, 0, backgroundMusicRect.w, backgroundMusicRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundMapVisible)
        {
            //// backgroundMap Drawing
            var backgroundMapRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundMapRect.x, backgroundMapRect.y, backgroundMapRect.w, backgroundMapRect.h);
            context.clip();
            context.translate(backgroundMapRect.x, backgroundMapRect.y);

            AvatarStyleKit.drawTreasureMapBackground(canvas, makeRect(0, 0, backgroundMapRect.w, backgroundMapRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundWorld2Visible)
        {
            //// backgroundWorld2 Drawing
            var backgroundWorld2Rect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundWorld2Rect.x, backgroundWorld2Rect.y, backgroundWorld2Rect.w, backgroundWorld2Rect.h);
            context.clip();
            context.translate(backgroundWorld2Rect.x, backgroundWorld2Rect.y);

            AvatarStyleKit.drawWorldBackground2(canvas, makeRect(0, 0, backgroundWorld2Rect.w, backgroundWorld2Rect.h), 'stretch');
            context.restore();
        }


        if (_backgroundWorldVisible)
        {
            //// backgroundWorld Drawing
            var backgroundWorldRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundWorldRect.x, backgroundWorldRect.y, backgroundWorldRect.w, backgroundWorldRect.h);
            context.clip();
            context.translate(backgroundWorldRect.x, backgroundWorldRect.y);

            AvatarStyleKit.drawWorldBackground(canvas, makeRect(0, 0, backgroundWorldRect.w, backgroundWorldRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundArtVisible)
        {
            //// backgroundArt Drawing
            var backgroundArtRect = makeRect(4, 25, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundArtRect.x, backgroundArtRect.y, backgroundArtRect.w, backgroundArtRect.h);
            context.clip();
            context.translate(backgroundArtRect.x, backgroundArtRect.y);

            AvatarStyleKit.drawArtBackground(canvas, makeRect(0, 0, backgroundArtRect.w, backgroundArtRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundZodiacVisible)
        {
            //// backgroundZodiac Drawing
            var backgroundZodiacRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundZodiacRect.x, backgroundZodiacRect.y, backgroundZodiacRect.w, backgroundZodiacRect.h);
            context.clip();
            context.translate(backgroundZodiacRect.x, backgroundZodiacRect.y);

            AvatarStyleKit.drawConstellationsBackground(canvas, makeRect(0, 0, backgroundZodiacRect.w, backgroundZodiacRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundSea2Visible)
        {
            //// backgroundSea2 Drawing
            var backgroundSea2Rect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundSea2Rect.x, backgroundSea2Rect.y, backgroundSea2Rect.w, backgroundSea2Rect.h);
            context.clip();
            context.translate(backgroundSea2Rect.x, backgroundSea2Rect.y);

            AvatarStyleKit.drawSeaBackground2(canvas, makeRect(0, 0, backgroundSea2Rect.w, backgroundSea2Rect.h), 'stretch');
            context.restore();
        }


        if (_backgroundSnowyVisible)
        {
            //// backgroundSnowy Drawing
            var backgroundSnowyRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundSnowyRect.x, backgroundSnowyRect.y, backgroundSnowyRect.w, backgroundSnowyRect.h);
            context.clip();
            context.translate(backgroundSnowyRect.x, backgroundSnowyRect.y);

            AvatarStyleKit.drawSnowyBackground(canvas, makeRect(0, 0, backgroundSnowyRect.w, backgroundSnowyRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundRainbowVisible)
        {
            //// backgroundRainbow Drawing
            var backgroundRainbowRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundRainbowRect.x, backgroundRainbowRect.y, backgroundRainbowRect.w, backgroundRainbowRect.h);
            context.clip();
            context.translate(backgroundRainbowRect.x, backgroundRainbowRect.y);

            AvatarStyleKit.drawRainbowBackground(canvas, makeRect(0, 0, backgroundRainbowRect.w, backgroundRainbowRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundHeartsVisible)
        {
            //// backgroundHearts Drawing
            var backgroundHeartsRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundHeartsRect.x, backgroundHeartsRect.y, backgroundHeartsRect.w, backgroundHeartsRect.h);
            context.clip();
            context.translate(backgroundHeartsRect.x, backgroundHeartsRect.y);

            AvatarStyleKit.drawHeartsBackground(canvas, makeRect(0, 0, backgroundHeartsRect.w, backgroundHeartsRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundCastleVisible)
        {
            //// backgroundCastle Drawing
            var backgroundCastleRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundCastleRect.x, backgroundCastleRect.y, backgroundCastleRect.w, backgroundCastleRect.h);
            context.clip();
            context.translate(backgroundCastleRect.x, backgroundCastleRect.y);

            AvatarStyleKit.drawCastleBackground(canvas, makeRect(0, 0, backgroundCastleRect.w, backgroundCastleRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundEgyptVisible)
        {
            //// backgroundEgypt Drawing
            var backgroundEgyptRect = makeRect(4, 24, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundEgyptRect.x, backgroundEgyptRect.y, backgroundEgyptRect.w, backgroundEgyptRect.h);
            context.clip();
            context.translate(backgroundEgyptRect.x, backgroundEgyptRect.y);

            AvatarStyleKit.drawEgyptBackground(canvas, makeRect(0, 0, backgroundEgyptRect.w, backgroundEgyptRect.h), 'stretch');
            context.restore();
        }


        if (_backgroundSeaVisible)
        {
            //// backgroundSea Drawing
            context.save();
            context.translate(4, 158);

            var backgroundSeaRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundSeaRect.x, backgroundSeaRect.y, backgroundSeaRect.w, backgroundSeaRect.h);
            context.clip();
            context.translate(backgroundSeaRect.x, backgroundSeaRect.y);

            AvatarStyleKit.drawSeaBackground(canvas, makeRect(0, 0, backgroundSeaRect.w, backgroundSeaRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundDesertVisible)
        {
            //// backgroundDesert Drawing
            context.save();
            context.translate(4, 158);

            var backgroundDesertRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundDesertRect.x, backgroundDesertRect.y, backgroundDesertRect.w, backgroundDesertRect.h);
            context.clip();
            context.translate(backgroundDesertRect.x, backgroundDesertRect.y);

            AvatarStyleKit.drawDesertBackground(canvas, makeRect(0, 0, backgroundDesertRect.w, backgroundDesertRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundBeachVisible)
        {
            //// backgroundBeach Drawing
            context.save();
            context.translate(4, 158);

            var backgroundBeachRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundBeachRect.x, backgroundBeachRect.y, backgroundBeachRect.w, backgroundBeachRect.h);
            context.clip();
            context.translate(backgroundBeachRect.x, backgroundBeachRect.y);

            AvatarStyleKit.drawBeachBackground(canvas, makeRect(0, 0, backgroundBeachRect.w, backgroundBeachRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundSpaceVisible)
        {
            //// backgroundSpace Drawing
            context.save();
            context.translate(4, 158);

            var backgroundSpaceRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundSpaceRect.x, backgroundSpaceRect.y, backgroundSpaceRect.w, backgroundSpaceRect.h);
            context.clip();
            context.translate(backgroundSpaceRect.x, backgroundSpaceRect.y);

            AvatarStyleKit.drawSpaceBackground(canvas, makeRect(0, 0, backgroundSpaceRect.w, backgroundSpaceRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundMountainsVisible)
        {
            //// backgroundMountains Drawing
            context.save();
            context.translate(4, 158);

            var backgroundMountainsRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundMountainsRect.x, backgroundMountainsRect.y, backgroundMountainsRect.w, backgroundMountainsRect.h);
            context.clip();
            context.translate(backgroundMountainsRect.x, backgroundMountainsRect.y);

            AvatarStyleKit.drawMountainsBackground(canvas, makeRect(0, 0, backgroundMountainsRect.w, backgroundMountainsRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_backgroundForestVisible)
        {
            //// backgroundForest Drawing
            context.save();
            context.translate(4, 158);

            var backgroundForestRect = makeRect(0, -134, 152, 134);
            context.save();
            context.beginPath();
            context.rect(backgroundForestRect.x, backgroundForestRect.y, backgroundForestRect.w, backgroundForestRect.h);
            context.clip();
            context.translate(backgroundForestRect.x, backgroundForestRect.y);

            AvatarStyleKit.drawForestBackground(canvas, makeRect(0, 0, backgroundForestRect.w, backgroundForestRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        //// Bezier 228 Drawing
        context.beginPath();
        context.moveTo(56.59, 105.37);
        context.bezierCurveTo(56.59, 107.56, 56.26, 112.13, 54.07, 112.13);
        context.bezierCurveTo(51.88, 112.13, 48.65, 107.56, 48.65, 105.37);
        context.bezierCurveTo(48.65, 103.18, 50.43, 101.4, 52.62, 101.4);
        context.bezierCurveTo(54.81, 101.4, 56.59, 103.18, 56.59, 105.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 230 Drawing
        context.beginPath();
        context.moveTo(102.04, 105.37);
        context.bezierCurveTo(102.04, 107.56, 102.36, 112.13, 104.55, 112.13);
        context.bezierCurveTo(106.74, 112.13, 109.97, 107.56, 109.97, 105.37);
        context.bezierCurveTo(109.97, 103.18, 108.2, 101.4, 106.01, 101.4);
        context.bezierCurveTo(103.81, 101.4, 102.04, 103.18, 102.04, 105.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 60 Drawing
        context.save();
        context.translate(79.45, 103.46);
        context.rotate(1.8 * Math.PI / 180);

        oval(context, -27.45, -29.95, 54.9, 59.9);
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();

        context.restore();


        if (_showFaceLightning)
        {
            //// faceLightningPink Drawing
            var faceLightningPinkRect = makeRect(61, 73, 31, 49);
            context.save();
            context.beginPath();
            context.rect(faceLightningPinkRect.x, faceLightningPinkRect.y, faceLightningPinkRect.w, faceLightningPinkRect.h);
            context.clip();
            context.translate(faceLightningPinkRect.x, faceLightningPinkRect.y);

            AvatarStyleKit.drawFaceLightning(canvas, makeRect(0, 0, faceLightningPinkRect.w, faceLightningPinkRect.h), 'stretch');
            context.restore();
        }


        //// Bezier 237 Drawing
        context.save();
        context.globalCompositeOperation = 'multiply';
        context.beginPath();
        context.moveTo(82.44, 110.76);
        context.bezierCurveTo(81.92, 111.99, 80.71, 112.85, 79.29, 112.85);
        context.bezierCurveTo(77.91, 112.85, 76.73, 112.04, 76.19, 110.86);
        context.strokeStyle = _noseColor;
        context.lineWidth = 1.12;
        context.stroke();
        context.restore();


        if (_eyesSquintVisible)
        {
            //// eyesSquint Drawing
            context.save();
            context.translate(66.76, 103.09);

            var eyesSquintRect = makeRect(0, -2, 26, 2);
            context.save();
            context.beginPath();
            context.rect(eyesSquintRect.x, eyesSquintRect.y, eyesSquintRect.w, eyesSquintRect.h);
            context.clip();
            context.translate(eyesSquintRect.x, eyesSquintRect.y);

            AvatarStyleKit.drawSquintEyes(canvas, skinColor, makeRect(0, 0, eyesSquintRect.w, eyesSquintRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eyesNormalVisible)
        {
            //// eyesNormal Drawing
            context.save();
            context.translate(69.2, 105.41);

            var eyesNormalRect = makeRect(0, -6, 21, 6);
            context.save();
            context.beginPath();
            context.rect(eyesNormalRect.x, eyesNormalRect.y, eyesNormalRect.w, eyesNormalRect.h);
            context.clip();
            context.translate(eyesNormalRect.x, eyesNormalRect.y);

            AvatarStyleKit.drawEyesNormal(canvas, eyeColor, skinColor, makeRect(0, 0, eyesNormalRect.w, eyesNormalRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eyesWideOpenVisible)
        {
            //// eyesWideOpen Drawing
            context.save();
            context.translate(69.5, 106.03);

            var eyesWideOpenRect = makeRect(0, -8, 20, 8);
            context.save();
            context.beginPath();
            context.rect(eyesWideOpenRect.x, eyesWideOpenRect.y, eyesWideOpenRect.w, eyesWideOpenRect.h);
            context.clip();
            context.translate(eyesWideOpenRect.x, eyesWideOpenRect.y);

            AvatarStyleKit.drawWideOpenEyes(canvas, eyeColor, makeRect(0, 0, eyesWideOpenRect.w, eyesWideOpenRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eyesWideVisible)
        {
            //// eyesWide Drawing
            context.save();
            context.translate(69.4, 106);

            var eyesWideRect = makeRect(0, -8, 20, 8);
            context.save();
            context.beginPath();
            context.rect(eyesWideRect.x, eyesWideRect.y, eyesWideRect.w, eyesWideRect.h);
            context.clip();
            context.translate(eyesWideRect.x, eyesWideRect.y);

            AvatarStyleKit.drawWideEyes(canvas, eyeColor, skinColor, makeRect(0, 0, eyesWideRect.w, eyesWideRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eyesWideTiredVisible)
        {
            //// eyesWideTired Drawing
            context.save();
            context.translate(69.5, 106);

            var eyesWideTiredRect = makeRect(0, -8, 20, 8);
            context.save();
            context.beginPath();
            context.rect(eyesWideTiredRect.x, eyesWideTiredRect.y, eyesWideTiredRect.w, eyesWideTiredRect.h);
            context.clip();
            context.translate(eyesWideTiredRect.x, eyesWideTiredRect.y);

            AvatarStyleKit.drawWideTiredEyes(canvas, eyeColor, skinColor, makeRect(0, 0, eyesWideTiredRect.w, eyesWideTiredRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eyesClosedVisible)
        {
            //// eyesClosed Drawing
            context.save();
            context.translate(69.74, 103.89);

            var eyesClosedRect = makeRect(0, -3, 20, 3);
            context.save();
            context.beginPath();
            context.rect(eyesClosedRect.x, eyesClosedRect.y, eyesClosedRect.w, eyesClosedRect.h);
            context.clip();
            context.translate(eyesClosedRect.x, eyesClosedRect.y);

            AvatarStyleKit.drawClosedEyes(canvas, skinColor, makeRect(0, 0, eyesClosedRect.w, eyesClosedRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_happyMouthVisible)
        {
            //// mouthSmiles Drawing
            context.save();
            context.translate(72.3, 125);

            var mouthSmilesRect = makeRect(0, -5, 14, 5);
            context.save();
            context.beginPath();
            context.rect(mouthSmilesRect.x, mouthSmilesRect.y, mouthSmilesRect.w, mouthSmilesRect.h);
            context.clip();
            context.translate(mouthSmilesRect.x, mouthSmilesRect.y);

            AvatarStyleKit.drawSmileMouth(canvas, makeRect(0, 0, mouthSmilesRect.w, mouthSmilesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_unhappyMouthVisible)
        {
            //// mouthUnhappy Drawing
            context.save();
            context.translate(71.3, 126.45);

            var mouthUnhappyRect = makeRect(0, -8, 16, 8);
            context.save();
            context.beginPath();
            context.rect(mouthUnhappyRect.x, mouthUnhappyRect.y, mouthUnhappyRect.w, mouthUnhappyRect.h);
            context.clip();
            context.translate(mouthUnhappyRect.x, mouthUnhappyRect.y);

            AvatarStyleKit.drawUnhappyMouth(canvas, makeRect(0, 0, mouthUnhappyRect.w, mouthUnhappyRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_worriedMouthVisible)
        {
            //// mouthWorried Drawing
            context.save();
            context.translate(73.44, 124.75);

            var mouthWorriedRect = makeRect(0, -5, 13, 5);
            context.save();
            context.beginPath();
            context.rect(mouthWorriedRect.x, mouthWorriedRect.y, mouthWorriedRect.w, mouthWorriedRect.h);
            context.clip();
            context.translate(mouthWorriedRect.x, mouthWorriedRect.y);

            AvatarStyleKit.drawWorriedMouth(canvas, makeRect(0, 0, mouthWorriedRect.w, mouthWorriedRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_sadMouthVisible)
        {
            //// mouthSad Drawing
            context.save();
            context.translate(72, 125.4);

            var mouthSadRect = makeRect(0, -5, 15, 5);
            context.save();
            context.beginPath();
            context.rect(mouthSadRect.x, mouthSadRect.y, mouthSadRect.w, mouthSadRect.h);
            context.clip();
            context.translate(mouthSadRect.x, mouthSadRect.y);

            AvatarStyleKit.drawSadMouth(canvas, makeRect(0, 0, mouthSadRect.w, mouthSadRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_smirkMouthVisible)
        {
            //// mouthSmirk Drawing
            context.save();
            context.translate(76.44, 125.26);

            var mouthSmirkRect = makeRect(0, -10, 16, 10);
            context.save();
            context.beginPath();
            context.rect(mouthSmirkRect.x, mouthSmirkRect.y, mouthSmirkRect.w, mouthSmirkRect.h);
            context.clip();
            context.translate(mouthSmirkRect.x, mouthSmirkRect.y);

            AvatarStyleKit.drawSmirkMouth(canvas, makeRect(0, 0, mouthSmirkRect.w, mouthSmirkRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_grinMouthVisible)
        {
            //// mouthGrin Drawing
            context.save();
            context.translate(69, 128);

            var mouthGrinRect = makeRect(0, -12, 21, 12);
            context.save();
            context.beginPath();
            context.rect(mouthGrinRect.x, mouthGrinRect.y, mouthGrinRect.w, mouthGrinRect.h);
            context.clip();
            context.translate(mouthGrinRect.x, mouthGrinRect.y);

            AvatarStyleKit.drawGrinMouth(canvas, makeRect(0, 0, mouthGrinRect.w, mouthGrinRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_annoyedMouthVisible)
        {
            //// mouthAnnoyed Drawing
            context.save();
            context.translate(76.79, 127.09);

            var mouthAnnoyedRect = makeRect(0, -8, 8, 8);
            context.save();
            context.beginPath();
            context.rect(mouthAnnoyedRect.x, mouthAnnoyedRect.y, mouthAnnoyedRect.w, mouthAnnoyedRect.h);
            context.clip();
            context.translate(mouthAnnoyedRect.x, mouthAnnoyedRect.y);

            AvatarStyleKit.drawAnnoyedMouth(canvas, makeRect(0, 0, mouthAnnoyedRect.w, mouthAnnoyedRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_tongueMouthVisible)
        {
            //// mouthTongue Drawing
            context.save();
            context.translate(70.3, 131);

            var mouthTongueRect = makeRect(0, -13, 18, 13);
            context.save();
            context.beginPath();
            context.rect(mouthTongueRect.x, mouthTongueRect.y, mouthTongueRect.w, mouthTongueRect.h);
            context.clip();
            context.translate(mouthTongueRect.x, mouthTongueRect.y);

            AvatarStyleKit.drawTongueMouth(canvas, makeRect(0, 0, mouthTongueRect.w, mouthTongueRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_smilingMouthVisible)
        {
            //// mouthSmiling Drawing
            context.save();
            context.translate(72.08, 125.33);

            var mouthSmilingRect = makeRect(0, -4, 15, 4);
            context.save();
            context.beginPath();
            context.rect(mouthSmilingRect.x, mouthSmilingRect.y, mouthSmilingRect.w, mouthSmilingRect.h);
            context.clip();
            context.translate(mouthSmilingRect.x, mouthSmilingRect.y);

            AvatarStyleKit.drawSmilingMouth(canvas, makeRect(0, 0, mouthSmilingRect.w, mouthSmilingRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_lipsMouthVisible)
        {
            //// mouthLips Drawing
            context.save();
            context.translate(72.8, 125);

            var mouthLipsRect = makeRect(0, -6, 14, 6);
            context.save();
            context.beginPath();
            context.rect(mouthLipsRect.x, mouthLipsRect.y, mouthLipsRect.w, mouthLipsRect.h);
            context.clip();
            context.translate(mouthLipsRect.x, mouthLipsRect.y);

            AvatarStyleKit.drawLipsMouth(canvas, makeRect(0, 0, mouthLipsRect.w, mouthLipsRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_eekMouthVisible)
        {
            //// mouthEek Drawing
            context.save();
            context.translate(70.3, 125.51);

            var mouthEekRect = makeRect(0, -7, 18, 7);
            context.save();
            context.beginPath();
            context.rect(mouthEekRect.x, mouthEekRect.y, mouthEekRect.w, mouthEekRect.h);
            context.clip();
            context.translate(mouthEekRect.x, mouthEekRect.y);

            AvatarStyleKit.drawEekMouth(canvas, makeRect(0, 0, mouthEekRect.w, mouthEekRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_ohMouthVisible)
        {
            //// mouthO Drawing
            context.save();
            context.translate(75.4, 126.61);

            var mouthORect = makeRect(0, -8, 8, 8);
            context.save();
            context.beginPath();
            context.rect(mouthORect.x, mouthORect.y, mouthORect.w, mouthORect.h);
            context.clip();
            context.translate(mouthORect.x, mouthORect.y);

            AvatarStyleKit.drawOMouth(canvas, makeRect(0, 0, mouthORect.w, mouthORect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showRoundGlasses)
        {
            //// accessoryRoundGlasses Drawing
            context.save();
            context.translate(59.4, 111);

            var accessoryRoundGlassesRect = makeRect(0, -19, 40, 19);
            context.save();
            context.beginPath();
            context.rect(accessoryRoundGlassesRect.x, accessoryRoundGlassesRect.y, accessoryRoundGlassesRect.w, accessoryRoundGlassesRect.h);
            context.clip();
            context.translate(accessoryRoundGlassesRect.x, accessoryRoundGlassesRect.y);

            AvatarStyleKit.drawRoundGlasses(canvas, makeRect(0, 0, accessoryRoundGlassesRect.w, accessoryRoundGlassesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showAngledGlasses)
        {
            //// accessoryAngledGlasses Drawing
            context.save();
            context.translate(50.5, 113);

            var accessoryAngledGlassesRect = makeRect(0, -22, 58, 22);
            context.save();
            context.beginPath();
            context.rect(accessoryAngledGlassesRect.x, accessoryAngledGlassesRect.y, accessoryAngledGlassesRect.w, accessoryAngledGlassesRect.h);
            context.clip();
            context.translate(accessoryAngledGlassesRect.x, accessoryAngledGlassesRect.y);

            AvatarStyleKit.drawAngledGlasses(canvas, makeRect(0, 0, accessoryAngledGlassesRect.w, accessoryAngledGlassesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showCoolShades)
        {
            //// accessoryCoolShades Drawing
            context.save();
            context.translate(53.72, 117.42);

            var accessoryCoolShadesRect = makeRect(0, -21, 52, 21);
            context.save();
            context.beginPath();
            context.rect(accessoryCoolShadesRect.x, accessoryCoolShadesRect.y, accessoryCoolShadesRect.w, accessoryCoolShadesRect.h);
            context.clip();
            context.translate(accessoryCoolShadesRect.x, accessoryCoolShadesRect.y);

            AvatarStyleKit.drawCoolShades(canvas, makeRect(0, 0, accessoryCoolShadesRect.w, accessoryCoolShadesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showDarkShades)
        {
            //// accessoryDarkShades Drawing
            context.save();
            context.translate(52.53, 114.85);

            var accessoryDarkShadesRect = makeRect(0, -20, 54, 20);
            context.save();
            context.beginPath();
            context.rect(accessoryDarkShadesRect.x, accessoryDarkShadesRect.y, accessoryDarkShadesRect.w, accessoryDarkShadesRect.h);
            context.clip();
            context.translate(accessoryDarkShadesRect.x, accessoryDarkShadesRect.y);

            AvatarStyleKit.drawDarkShades(canvas, makeRect(0, 0, accessoryDarkShadesRect.w, accessoryDarkShadesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showEyePatch)
        {
            //// accessoryEyePatch Drawing
            context.save();
            context.translate(51.28, 111.67);

            var accessoryEyePatchRect = makeRect(0, -22, 53, 22);
            context.save();
            context.beginPath();
            context.rect(accessoryEyePatchRect.x, accessoryEyePatchRect.y, accessoryEyePatchRect.w, accessoryEyePatchRect.h);
            context.clip();
            context.translate(accessoryEyePatchRect.x, accessoryEyePatchRect.y);

            AvatarStyleKit.drawEyePatch(canvas, makeRect(0, 0, accessoryEyePatchRect.w, accessoryEyePatchRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showGoldHoopEarrings)
        {
            //// GoldHoopEarring Drawing
            var goldHoopEarringRect = makeRect(45, 104, 67, 21);
            context.save();
            context.beginPath();
            context.rect(goldHoopEarringRect.x, goldHoopEarringRect.y, goldHoopEarringRect.w, goldHoopEarringRect.h);
            context.clip();
            context.translate(goldHoopEarringRect.x, goldHoopEarringRect.y);

            AvatarStyleKit.drawGoldHoopEarrings(canvas, makeRect(0, 0, goldHoopEarringRect.w, goldHoopEarringRect.h), 'stretch');
            context.restore();
        }


        if (_showFaceMouseNose)
        {
            //// FaceMouseNose Drawing
            var faceMouseNoseRect = makeRect(61, 106, 37, 13);
            context.save();
            context.beginPath();
            context.rect(faceMouseNoseRect.x, faceMouseNoseRect.y, faceMouseNoseRect.w, faceMouseNoseRect.h);
            context.clip();
            context.translate(faceMouseNoseRect.x, faceMouseNoseRect.y);

            AvatarStyleKit.drawMouseNose(canvas, makeRect(0, 0, faceMouseNoseRect.w, faceMouseNoseRect.h), 'stretch');
            context.restore();
        }


        if (_showSquareGlasses)
        {
            //// GlassesSquare Drawing
            var glassesSquareRect = makeRect(60.7, 96, 37, 13);
            context.save();
            context.beginPath();
            context.rect(glassesSquareRect.x, glassesSquareRect.y, glassesSquareRect.w, glassesSquareRect.h);
            context.clip();
            context.translate(glassesSquareRect.x, glassesSquareRect.y);

            AvatarStyleKit.drawSquareGlasses(canvas, makeRect(0, 0, glassesSquareRect.w, glassesSquareRect.h), 'stretch');
            context.restore();
        }


        if (_showRoundGlassesWhite)
        {
            //// RoundGlassesWhite Drawing
            var roundGlassesWhiteRect = makeRect(55, 94, 49, 23);
            context.save();
            context.beginPath();
            context.rect(roundGlassesWhiteRect.x, roundGlassesWhiteRect.y, roundGlassesWhiteRect.w, roundGlassesWhiteRect.h);
            context.clip();
            context.translate(roundGlassesWhiteRect.x, roundGlassesWhiteRect.y);

            AvatarStyleKit.drawWhiteGlasses(canvas, makeRect(0, 0, roundGlassesWhiteRect.w, roundGlassesWhiteRect.h), 'stretch');
            context.restore();
        }


        if (_showLongMustache)
        {
            //// longCurlyMustache Drawing
            var longCurlyMustacheRect = makeRect(45.5, 113, 67, 11);
            context.save();
            context.beginPath();
            context.rect(longCurlyMustacheRect.x, longCurlyMustacheRect.y, longCurlyMustacheRect.w, longCurlyMustacheRect.h);
            context.clip();
            context.translate(longCurlyMustacheRect.x, longCurlyMustacheRect.y);

            AvatarStyleKit.drawLongMustache(canvas, makeRect(0, 0, longCurlyMustacheRect.w, longCurlyMustacheRect.h), 'stretch');
            context.restore();
        }


        if (_showBeardSmall)
        {
            //// BeardSmall Drawing
            var beardSmallRect = makeRect(48, 101, 63, 33);
            context.save();
            context.beginPath();
            context.rect(beardSmallRect.x, beardSmallRect.y, beardSmallRect.w, beardSmallRect.h);
            context.clip();
            context.translate(beardSmallRect.x, beardSmallRect.y);

            AvatarStyleKit.drawSmallbeard(canvas, makeRect(0, 0, beardSmallRect.w, beardSmallRect.h), 'stretch');
            context.restore();
        }


        if (_showGlassesLines)
        {
            //// GlassesLines Drawing
            var glassesLinesRect = makeRect(51, 92, 58, 23);
            context.save();
            context.beginPath();
            context.rect(glassesLinesRect.x, glassesLinesRect.y, glassesLinesRect.w, glassesLinesRect.h);
            context.clip();
            context.translate(glassesLinesRect.x, glassesLinesRect.y);

            AvatarStyleKit.drawLinesGlasses(canvas, makeRect(0, 0, glassesLinesRect.w, glassesLinesRect.h), 'stretch');
            context.restore();
        }


        if (_showWingsBee)
        {
            //// WingsBee Drawing
            var wingsBeeRect = makeRect(29.1, 96.5, 101, 73);
            context.save();
            context.beginPath();
            context.rect(wingsBeeRect.x, wingsBeeRect.y, wingsBeeRect.w, wingsBeeRect.h);
            context.clip();
            context.translate(wingsBeeRect.x, wingsBeeRect.y);

            AvatarStyleKit.drawBeeWings(canvas, makeRect(0, 0, wingsBeeRect.w, wingsBeeRect.h), 'stretch');
            context.restore();
        }


        if (_boyHair17Visible)
        {
            //// hairBoy17 Drawing
            var hairBoy17Rect = makeRect(42.6, 55, 74, 59);
            context.save();
            context.beginPath();
            context.rect(hairBoy17Rect.x, hairBoy17Rect.y, hairBoy17Rect.w, hairBoy17Rect.h);
            context.clip();
            context.translate(hairBoy17Rect.x, hairBoy17Rect.y);

            AvatarStyleKit.drawBoyHair17(canvas, hairColor, makeRect(0, 0, hairBoy17Rect.w, hairBoy17Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair16Visible)
        {
            //// hairBoy16 Drawing
            var hairBoy16Rect = makeRect(42, 61, 74, 49);
            context.save();
            context.beginPath();
            context.rect(hairBoy16Rect.x, hairBoy16Rect.y, hairBoy16Rect.w, hairBoy16Rect.h);
            context.clip();
            context.translate(hairBoy16Rect.x, hairBoy16Rect.y);

            AvatarStyleKit.drawBoyHair16(canvas, hairColor, makeRect(0, 0, hairBoy16Rect.w, hairBoy16Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair15Visible)
        {
            //// hairBoy15 Drawing
            var hairBoy15Rect = makeRect(65, 45, 29, 43);
            context.save();
            context.beginPath();
            context.rect(hairBoy15Rect.x, hairBoy15Rect.y, hairBoy15Rect.w, hairBoy15Rect.h);
            context.clip();
            context.translate(hairBoy15Rect.x, hairBoy15Rect.y);

            AvatarStyleKit.drawBoyHair15(canvas, hairColor, makeRect(0, 0, hairBoy15Rect.w, hairBoy15Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair14Visible)
        {
            //// hairBoy14 Drawing
            var hairBoy14Rect = makeRect(51.2, 71, 56, 29);
            context.save();
            context.beginPath();
            context.rect(hairBoy14Rect.x, hairBoy14Rect.y, hairBoy14Rect.w, hairBoy14Rect.h);
            context.clip();
            context.translate(hairBoy14Rect.x, hairBoy14Rect.y);

            AvatarStyleKit.drawBoyHair14(canvas, hairColor, makeRect(0, 0, hairBoy14Rect.w, hairBoy14Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair13Visible)
        {
            //// hairBoy13 Drawing
            var hairBoy13Rect = makeRect(45.3, 54, 67, 55);
            context.save();
            context.beginPath();
            context.rect(hairBoy13Rect.x, hairBoy13Rect.y, hairBoy13Rect.w, hairBoy13Rect.h);
            context.clip();
            context.translate(hairBoy13Rect.x, hairBoy13Rect.y);

            AvatarStyleKit.drawBoyHair13(canvas, hairColor, makeRect(0, 0, hairBoy13Rect.w, hairBoy13Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair12Visible)
        {
            //// hairBoy12 Drawing
            var hairBoy12Rect = makeRect(41, 52, 74, 59);
            context.save();
            context.beginPath();
            context.rect(hairBoy12Rect.x, hairBoy12Rect.y, hairBoy12Rect.w, hairBoy12Rect.h);
            context.clip();
            context.translate(hairBoy12Rect.x, hairBoy12Rect.y);

            AvatarStyleKit.drawBoyHair12(canvas, hairColor, makeRect(0, 0, hairBoy12Rect.w, hairBoy12Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair11Visible)
        {
            //// hairBoy11 Drawing
            var hairBoy11Rect = makeRect(41, 57, 74, 48);
            context.save();
            context.beginPath();
            context.rect(hairBoy11Rect.x, hairBoy11Rect.y, hairBoy11Rect.w, hairBoy11Rect.h);
            context.clip();
            context.translate(hairBoy11Rect.x, hairBoy11Rect.y);

            AvatarStyleKit.drawBoyHair11(canvas, hairColor, makeRect(0, 0, hairBoy11Rect.w, hairBoy11Rect.h), 'stretch');
            context.restore();
        }


        if (_boyHair10Visible)
        {
            //// hairBoy10 Drawing
            context.save();
            context.translate(42.15, 122.42);

            var hairBoy10Rect = makeRect(0, -59, 74, 59);
            context.save();
            context.beginPath();
            context.rect(hairBoy10Rect.x, hairBoy10Rect.y, hairBoy10Rect.w, hairBoy10Rect.h);
            context.clip();
            context.translate(hairBoy10Rect.x, hairBoy10Rect.y);

            AvatarStyleKit.drawBoyHair10(canvas, hairColor, makeRect(0, 0, hairBoy10Rect.w, hairBoy10Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair9Visible)
        {
            //// hairBoy9 Drawing
            context.save();
            context.translate(44.88, 107.52);

            var hairBoy9Rect = makeRect(0, -44, 70, 44);
            context.save();
            context.beginPath();
            context.rect(hairBoy9Rect.x, hairBoy9Rect.y, hairBoy9Rect.w, hairBoy9Rect.h);
            context.clip();
            context.translate(hairBoy9Rect.x, hairBoy9Rect.y);

            AvatarStyleKit.drawBoyHair9(canvas, hairColor, makeRect(0, 0, hairBoy9Rect.w, hairBoy9Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair8Visible)
        {
            //// hairBoy8 Drawing
            context.save();
            context.translate(38, 107);

            var hairBoy8Rect = makeRect(0, -47, 76, 47);
            context.save();
            context.beginPath();
            context.rect(hairBoy8Rect.x, hairBoy8Rect.y, hairBoy8Rect.w, hairBoy8Rect.h);
            context.clip();
            context.translate(hairBoy8Rect.x, hairBoy8Rect.y);

            AvatarStyleKit.drawBoyHair8(canvas, hairColor, makeRect(0, 0, hairBoy8Rect.w, hairBoy8Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair7Visible)
        {
            //// hairBoy7 Drawing
            context.save();
            context.translate(43.6, 102);

            var hairBoy7Rect = makeRect(0, -39, 74, 39);
            context.save();
            context.beginPath();
            context.rect(hairBoy7Rect.x, hairBoy7Rect.y, hairBoy7Rect.w, hairBoy7Rect.h);
            context.clip();
            context.translate(hairBoy7Rect.x, hairBoy7Rect.y);

            AvatarStyleKit.drawBoyHair7(canvas, hairColor, makeRect(0, 0, hairBoy7Rect.w, hairBoy7Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair6Visible)
        {
            //// hairBoy6 Drawing
            context.save();
            context.translate(42.67, 100.52);

            var hairBoy6Rect = makeRect(0, -39, 74, 39);
            context.save();
            context.beginPath();
            context.rect(hairBoy6Rect.x, hairBoy6Rect.y, hairBoy6Rect.w, hairBoy6Rect.h);
            context.clip();
            context.translate(hairBoy6Rect.x, hairBoy6Rect.y);

            AvatarStyleKit.drawBoyHair6(canvas, hairColor, makeRect(0, 0, hairBoy6Rect.w, hairBoy6Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair5Visible)
        {
            //// hairBoy5 Drawing
            context.save();
            context.translate(36, 108);

            var hairBoy5Rect = makeRect(0, -53, 80, 53);
            context.save();
            context.beginPath();
            context.rect(hairBoy5Rect.x, hairBoy5Rect.y, hairBoy5Rect.w, hairBoy5Rect.h);
            context.clip();
            context.translate(hairBoy5Rect.x, hairBoy5Rect.y);

            AvatarStyleKit.drawBoyHair5(canvas, hairColor, makeRect(0, 0, hairBoy5Rect.w, hairBoy5Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair4Visible)
        {
            //// hairBoy4 Drawing
            context.save();
            context.translate(43.67, 98.96);

            var hairBoy4Rect = makeRect(0, -43, 73, 43);
            context.save();
            context.beginPath();
            context.rect(hairBoy4Rect.x, hairBoy4Rect.y, hairBoy4Rect.w, hairBoy4Rect.h);
            context.clip();
            context.translate(hairBoy4Rect.x, hairBoy4Rect.y);

            AvatarStyleKit.drawBoyHair4(canvas, hairColor, makeRect(0, 0, hairBoy4Rect.w, hairBoy4Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair3Visible)
        {
            //// hairBoy3 Drawing
            context.save();
            context.translate(43.36, 107.92);

            var hairBoy3Rect = makeRect(0, -47, 72, 47);
            context.save();
            context.beginPath();
            context.rect(hairBoy3Rect.x, hairBoy3Rect.y, hairBoy3Rect.w, hairBoy3Rect.h);
            context.clip();
            context.translate(hairBoy3Rect.x, hairBoy3Rect.y);

            AvatarStyleKit.drawBoyHair3(canvas, hairColor, makeRect(0, 0, hairBoy3Rect.w, hairBoy3Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair2Visible)
        {
            //// hairBoy2 Drawing
            context.save();
            context.translate(43.88, 101.57);

            var hairBoy2Rect = makeRect(0, -39, 72, 39);
            context.save();
            context.beginPath();
            context.rect(hairBoy2Rect.x, hairBoy2Rect.y, hairBoy2Rect.w, hairBoy2Rect.h);
            context.clip();
            context.translate(hairBoy2Rect.x, hairBoy2Rect.y);

            AvatarStyleKit.drawBoyHair2(canvas, hairColor, makeRect(0, 0, hairBoy2Rect.w, hairBoy2Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_boyHair1Visible)
        {
            //// hairBoy1 Drawing
            context.save();
            context.translate(42.4, 106.34);

            var hairBoy1Rect = makeRect(0, -45, 73, 45);
            context.save();
            context.beginPath();
            context.rect(hairBoy1Rect.x, hairBoy1Rect.y, hairBoy1Rect.w, hairBoy1Rect.h);
            context.clip();
            context.translate(hairBoy1Rect.x, hairBoy1Rect.y);

            AvatarStyleKit.drawBoyHair1(canvas, hairColor, makeRect(0, 0, hairBoy1Rect.w, hairBoy1Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair16Visible)
        {
            //// hairGirl16 Drawing
            var hairGirl16Rect = makeRect(32.7, 61, 93, 95);
            context.save();
            context.beginPath();
            context.rect(hairGirl16Rect.x, hairGirl16Rect.y, hairGirl16Rect.w, hairGirl16Rect.h);
            context.clip();
            context.translate(hairGirl16Rect.x, hairGirl16Rect.y);

            AvatarStyleKit.drawGirlHair16(canvas, hairColor, makeRect(0, 0, hairGirl16Rect.w, hairGirl16Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair15Visible)
        {
            //// hairGirl15 Drawing
            var hairGirl15Rect = makeRect(41.8, 61, 80, 74);
            context.save();
            context.beginPath();
            context.rect(hairGirl15Rect.x, hairGirl15Rect.y, hairGirl15Rect.w, hairGirl15Rect.h);
            context.clip();
            context.translate(hairGirl15Rect.x, hairGirl15Rect.y);

            AvatarStyleKit.drawGirlHair15(canvas, hairColor, makeRect(0, 0, hairGirl15Rect.w, hairGirl15Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair14Visible)
        {
            //// hairGirl14 Drawing
            var hairGirl14Rect = makeRect(42, 55, 74, 56);
            context.save();
            context.beginPath();
            context.rect(hairGirl14Rect.x, hairGirl14Rect.y, hairGirl14Rect.w, hairGirl14Rect.h);
            context.clip();
            context.translate(hairGirl14Rect.x, hairGirl14Rect.y);

            AvatarStyleKit.drawGirlHair14(canvas, hairColor, makeRect(0, 0, hairGirl14Rect.w, hairGirl14Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair13Visible)
        {
            //// hairGirl13 Drawing
            var hairGirl13Rect = makeRect(31.7, 40.5, 96, 79);
            context.save();
            context.beginPath();
            context.rect(hairGirl13Rect.x, hairGirl13Rect.y, hairGirl13Rect.w, hairGirl13Rect.h);
            context.clip();
            context.translate(hairGirl13Rect.x, hairGirl13Rect.y);

            AvatarStyleKit.drawGirlHair13(canvas, hairColor, makeRect(0, 0, hairGirl13Rect.w, hairGirl13Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair12Visible)
        {
            //// hairGirl12 Drawing
            var hairGirl12Rect = makeRect(42, 60.9, 74, 74);
            context.save();
            context.beginPath();
            context.rect(hairGirl12Rect.x, hairGirl12Rect.y, hairGirl12Rect.w, hairGirl12Rect.h);
            context.clip();
            context.translate(hairGirl12Rect.x, hairGirl12Rect.y);

            AvatarStyleKit.drawGirlHair12(canvas, hairColor, makeRect(0, 0, hairGirl12Rect.w, hairGirl12Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair11Visible)
        {
            //// hairGirl11 Drawing
            var hairGirl11Rect = makeRect(27, 60, 101, 84);
            context.save();
            context.beginPath();
            context.rect(hairGirl11Rect.x, hairGirl11Rect.y, hairGirl11Rect.w, hairGirl11Rect.h);
            context.clip();
            context.translate(hairGirl11Rect.x, hairGirl11Rect.y);

            AvatarStyleKit.drawGirlHair11(canvas, hairColor, makeRect(0, 0, hairGirl11Rect.w, hairGirl11Rect.h), 'stretch');
            context.restore();
        }


        if (_girlHair10Visible)
        {
            //// hairGirl10 Drawing
            context.save();
            context.translate(42.92, 108.5);

            var hairGirl10Rect = makeRect(0, -46, 72, 46);
            context.save();
            context.beginPath();
            context.rect(hairGirl10Rect.x, hairGirl10Rect.y, hairGirl10Rect.w, hairGirl10Rect.h);
            context.clip();
            context.translate(hairGirl10Rect.x, hairGirl10Rect.y);

            AvatarStyleKit.drawGirlHair10(canvas, hairColor, makeRect(0, 0, hairGirl10Rect.w, hairGirl10Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair9Visible)
        {
            //// hairGirl9 Drawing
            context.save();
            context.translate(36, 102);

            var hairGirl9Rect = makeRect(0, -48, 88, 48);
            context.save();
            context.beginPath();
            context.rect(hairGirl9Rect.x, hairGirl9Rect.y, hairGirl9Rect.w, hairGirl9Rect.h);
            context.clip();
            context.translate(hairGirl9Rect.x, hairGirl9Rect.y);

            AvatarStyleKit.drawGirlHair9(canvas, hairColor, makeRect(0, 0, hairGirl9Rect.w, hairGirl9Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair8Visible)
        {
            //// hairGirl8 Drawing
            context.save();
            context.translate(42.97, 109.94);

            var hairGirl8Rect = makeRect(0, -61, 73, 61);
            context.save();
            context.beginPath();
            context.rect(hairGirl8Rect.x, hairGirl8Rect.y, hairGirl8Rect.w, hairGirl8Rect.h);
            context.clip();
            context.translate(hairGirl8Rect.x, hairGirl8Rect.y);

            AvatarStyleKit.drawGirlHair8(canvas, hairColor, makeRect(0, 0, hairGirl8Rect.w, hairGirl8Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair7Visible)
        {
            //// hairGirl7 Drawing
            context.save();
            context.translate(43, 122.29);

            var hairGirl7Rect = makeRect(0, -61, 74, 61);
            context.save();
            context.beginPath();
            context.rect(hairGirl7Rect.x, hairGirl7Rect.y, hairGirl7Rect.w, hairGirl7Rect.h);
            context.clip();
            context.translate(hairGirl7Rect.x, hairGirl7Rect.y);

            AvatarStyleKit.drawGirlHair7(canvas, hairColor, makeRect(0, 0, hairGirl7Rect.w, hairGirl7Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair6Visible)
        {
            //// hairGirl6 Drawing
            context.save();
            context.translate(33.9, 124);

            var hairGirl6Rect = makeRect(0, -69, 91, 69);
            context.save();
            context.beginPath();
            context.rect(hairGirl6Rect.x, hairGirl6Rect.y, hairGirl6Rect.w, hairGirl6Rect.h);
            context.clip();
            context.translate(hairGirl6Rect.x, hairGirl6Rect.y);

            AvatarStyleKit.drawGirlHair6(canvas, hairColor, makeRect(0, 0, hairGirl6Rect.w, hairGirl6Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair5Visible)
        {
            //// hairGirl5 Drawing
            context.save();
            context.translate(33.32, 156.5);

            var hairGirl5Rect = makeRect(0, -99, 93, 99);
            context.save();
            context.beginPath();
            context.rect(hairGirl5Rect.x, hairGirl5Rect.y, hairGirl5Rect.w, hairGirl5Rect.h);
            context.clip();
            context.translate(hairGirl5Rect.x, hairGirl5Rect.y);

            AvatarStyleKit.drawGirlHair5(canvas, hairColor, makeRect(0, 0, hairGirl5Rect.w, hairGirl5Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair4Visible)
        {
            //// hairGirl4 Drawing
            context.save();
            context.translate(42.5, 112.04);

            var hairGirl4Rect = makeRect(0, -61, 91, 61);
            context.save();
            context.beginPath();
            context.rect(hairGirl4Rect.x, hairGirl4Rect.y, hairGirl4Rect.w, hairGirl4Rect.h);
            context.clip();
            context.translate(hairGirl4Rect.x, hairGirl4Rect.y);

            AvatarStyleKit.drawGirlHair4(canvas, hairColor, makeRect(0, 0, hairGirl4Rect.w, hairGirl4Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair3Visible)
        {
            //// hairGirl3 Drawing
            context.save();
            context.translate(43.4, 138.8);

            var hairGirl3Rect = makeRect(0, -78, 73, 78);
            context.save();
            context.beginPath();
            context.rect(hairGirl3Rect.x, hairGirl3Rect.y, hairGirl3Rect.w, hairGirl3Rect.h);
            context.clip();
            context.translate(hairGirl3Rect.x, hairGirl3Rect.y);

            AvatarStyleKit.drawGirlHair3(canvas, hairColor, makeRect(0, 0, hairGirl3Rect.w, hairGirl3Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair2Visible)
        {
            //// hairGirl2 Drawing
            context.save();
            context.translate(41.5, 156.33);

            var hairGirl2Rect = makeRect(0, -95, 74, 95);
            context.save();
            context.beginPath();
            context.rect(hairGirl2Rect.x, hairGirl2Rect.y, hairGirl2Rect.w, hairGirl2Rect.h);
            context.clip();
            context.translate(hairGirl2Rect.x, hairGirl2Rect.y);

            AvatarStyleKit.drawGirlHair2(canvas, hairColor, makeRect(0, 0, hairGirl2Rect.w, hairGirl2Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_girlHair1Visible)
        {
            //// hairGirl1 Drawing
            context.save();
            context.translate(41.81, 137.09);

            var hairGirl1Rect = makeRect(0, -76, 74, 76);
            context.save();
            context.beginPath();
            context.rect(hairGirl1Rect.x, hairGirl1Rect.y, hairGirl1Rect.w, hairGirl1Rect.h);
            context.clip();
            context.translate(hairGirl1Rect.x, hairGirl1Rect.y);

            AvatarStyleKit.drawGirlHair1(canvas, hairColor, makeRect(0, 0, hairGirl1Rect.w, hairGirl1Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtPlain)
        {
            //// shirtPlain Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtPlainRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtPlainRect.x, shirtPlainRect.y, shirtPlainRect.w, shirtPlainRect.h);
            context.clip();
            context.translate(shirtPlainRect.x, shirtPlainRect.y);

            AvatarStyleKit.drawPlainShirt(canvas, skinColor, shirtColor, makeRect(0, 0, shirtPlainRect.w, shirtPlainRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtPocket)
        {
            //// shortPocket Drawing
            context.save();
            context.translate(50, 156.48);

            var shortPocketRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shortPocketRect.x, shortPocketRect.y, shortPocketRect.w, shortPocketRect.h);
            context.clip();
            context.translate(shortPocketRect.x, shortPocketRect.y);

            AvatarStyleKit.drawPlainShirtPocket(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shortPocketRect.w, shortPocketRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtVeeNeck)
        {
            //// shirtVeeNeck Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtVeeNeckRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtVeeNeckRect.x, shirtVeeNeckRect.y, shirtVeeNeckRect.w, shirtVeeNeckRect.h);
            context.clip();
            context.translate(shirtVeeNeckRect.x, shirtVeeNeckRect.y);

            AvatarStyleKit.drawVNeck(canvas, skinColor, shirtColor, makeRect(0, 0, shirtVeeNeckRect.w, shirtVeeNeckRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtButtons)
        {
            //// shirtButtons Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtButtonsRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtButtonsRect.x, shirtButtonsRect.y, shirtButtonsRect.w, shirtButtonsRect.h);
            context.clip();
            context.translate(shirtButtonsRect.x, shirtButtonsRect.y);

            AvatarStyleKit.drawButtons(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtButtonsRect.w, shirtButtonsRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtCollar)
        {
            //// shirtCollar Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtCollarRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtCollarRect.x, shirtCollarRect.y, shirtCollarRect.w, shirtCollarRect.h);
            context.clip();
            context.translate(shirtCollarRect.x, shirtCollarRect.y);

            AvatarStyleKit.drawCollar(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtCollarRect.w, shirtCollarRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtButtons2)
        {
            //// shirtButtons2 Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtButtons2Rect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtButtons2Rect.x, shirtButtons2Rect.y, shirtButtons2Rect.w, shirtButtons2Rect.h);
            context.clip();
            context.translate(shirtButtons2Rect.x, shirtButtons2Rect.y);

            AvatarStyleKit.drawButtons2(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtButtons2Rect.w, shirtButtons2Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtVeeCollar)
        {
            //// ShirtVeeCollar Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtVeeCollarRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtVeeCollarRect.x, shirtVeeCollarRect.y, shirtVeeCollarRect.w, shirtVeeCollarRect.h);
            context.clip();
            context.translate(shirtVeeCollarRect.x, shirtVeeCollarRect.y);

            AvatarStyleKit.drawVNeckCollar(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtVeeCollarRect.w, shirtVeeCollarRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtWide)
        {
            //// shirtWide Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtWideRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtWideRect.x, shirtWideRect.y, shirtWideRect.w, shirtWideRect.h);
            context.clip();
            context.translate(shirtWideRect.x, shirtWideRect.y);

            AvatarStyleKit.drawWideNeck(canvas, skinColor, shirtColor, makeRect(0, 0, shirtWideRect.w, shirtWideRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtHex)
        {
            //// shortHex Drawing
            context.save();
            context.translate(50, 156.48);

            var shortHexRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shortHexRect.x, shortHexRect.y, shortHexRect.w, shortHexRect.h);
            context.clip();
            context.translate(shortHexRect.x, shortHexRect.y);

            AvatarStyleKit.drawPlainShirtHex(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shortHexRect.w, shortHexRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtCircle)
        {
            //// shirtCircle Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtCircleRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtCircleRect.x, shirtCircleRect.y, shirtCircleRect.w, shirtCircleRect.h);
            context.clip();
            context.translate(shirtCircleRect.x, shirtCircleRect.y);

            AvatarStyleKit.drawPlainShirtCircle(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtCircleRect.w, shirtCircleRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtStripe)
        {
            //// shortStripe Drawing
            context.save();
            context.translate(50, 156.48);

            var shortStripeRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shortStripeRect.x, shortStripeRect.y, shortStripeRect.w, shortStripeRect.h);
            context.clip();
            context.translate(shortStripeRect.x, shortStripeRect.y);

            AvatarStyleKit.drawPlainShirtStripe(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shortStripeRect.w, shortStripeRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtStripe2)
        {
            //// shortStripe2 Drawing
            context.save();
            context.translate(50, 156.48);

            var shortStripe2Rect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shortStripe2Rect.x, shortStripe2Rect.y, shortStripe2Rect.w, shortStripe2Rect.h);
            context.clip();
            context.translate(shortStripe2Rect.x, shortStripe2Rect.y);

            AvatarStyleKit.drawPlainShirtStripe2(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shortStripe2Rect.w, shortStripe2Rect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtZip)
        {
            //// shirtZip Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtZipRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtZipRect.x, shirtZipRect.y, shirtZipRect.w, shirtZipRect.h);
            context.clip();
            context.translate(shirtZipRect.x, shirtZipRect.y);

            AvatarStyleKit.drawPlainShirtZip(canvas, skinColor, shirtColor, makeRect(0, 0, shirtZipRect.w, shirtZipRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtCollarButtons)
        {
            //// shirtCollarButtons Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtCollarButtonsRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtCollarButtonsRect.x, shirtCollarButtonsRect.y, shirtCollarButtonsRect.w, shirtCollarButtonsRect.h);
            context.clip();
            context.translate(shirtCollarButtonsRect.x, shirtCollarButtonsRect.y);

            AvatarStyleKit.drawCollarButtons(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtCollarButtonsRect.w, shirtCollarButtonsRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtZigZag)
        {
            //// shirtZigZag Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtZigZagRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtZigZagRect.x, shirtZigZagRect.y, shirtZigZagRect.w, shirtZigZagRect.h);
            context.clip();
            context.translate(shirtZigZagRect.x, shirtZigZagRect.y);

            AvatarStyleKit.drawPlainShirtZigZag(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtZigZagRect.w, shirtZigZagRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtWave)
        {
            //// shirtWave Drawing
            context.save();
            context.translate(50, 156.48);

            var shirtWaveRect = makeRect(0, -26, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtWaveRect.x, shirtWaveRect.y, shirtWaveRect.w, shirtWaveRect.h);
            context.clip();
            context.translate(shirtWaveRect.x, shirtWaveRect.y);

            AvatarStyleKit.drawPlainShirtWave(canvas, skinColor, shirtColor, makeRect(0, 0, shirtWaveRect.w, shirtWaveRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtPullStrings)
        {
            //// shirtPullStrings Drawing
            context.save();
            context.translate(50, 163.48);

            var shirtPullStringsRect = makeRect(0, -33, 59, 33);
            context.save();
            context.beginPath();
            context.rect(shirtPullStringsRect.x, shirtPullStringsRect.y, shirtPullStringsRect.w, shirtPullStringsRect.h);
            context.clip();
            context.translate(shirtPullStringsRect.x, shirtPullStringsRect.y);

            AvatarStyleKit.drawPullStrings(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtPullStringsRect.w, shirtPullStringsRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtTassles)
        {
            //// shirtTassles Drawing
            context.save();
            context.translate(50, 165.48);

            var shirtTasslesRect = makeRect(0, -35, 59, 35);
            context.save();
            context.beginPath();
            context.rect(shirtTasslesRect.x, shirtTasslesRect.y, shirtTasslesRect.w, shirtTasslesRect.h);
            context.clip();
            context.translate(shirtTasslesRect.x, shirtTasslesRect.y);

            AvatarStyleKit.drawTassles(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtTasslesRect.w, shirtTasslesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_shirtFlower)
        {
            //// shirtFlower Drawing
            var shirtFlowerRect = makeRect(50, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFlowerRect.x, shirtFlowerRect.y, shirtFlowerRect.w, shirtFlowerRect.h);
            context.clip();
            context.translate(shirtFlowerRect.x, shirtFlowerRect.y);

            AvatarStyleKit.drawFlowerShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtFlowerRect.w, shirtFlowerRect.h), 'stretch');
            context.restore();
        }


        if (_shirtPearls)
        {
            //// shirtPearls Drawing
            var shirtPearlsRect = makeRect(49.3, 130.6, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtPearlsRect.x, shirtPearlsRect.y, shirtPearlsRect.w, shirtPearlsRect.h);
            context.clip();
            context.translate(shirtPearlsRect.x, shirtPearlsRect.y);

            AvatarStyleKit.drawPearlsShirt(canvas, skinColor, shirtColor, makeRect(0, 0, shirtPearlsRect.w, shirtPearlsRect.h), 'stretch');
            context.restore();
        }


        if (_shirtMidStripe)
        {
            //// shirtMidStripe Drawing
            var shirtMidStripeRect = makeRect(50.2, 130.7, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtMidStripeRect.x, shirtMidStripeRect.y, shirtMidStripeRect.w, shirtMidStripeRect.h);
            context.clip();
            context.translate(shirtMidStripeRect.x, shirtMidStripeRect.y);

            AvatarStyleKit.drawMidStripeShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtMidStripeRect.w, shirtMidStripeRect.h), 'stretch');
            context.restore();
        }


        if (_shirtVFrills)
        {
            //// shirtVFrills Drawing
            var shirtVFrillsRect = makeRect(49.5, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtVFrillsRect.x, shirtVFrillsRect.y, shirtVFrillsRect.w, shirtVFrillsRect.h);
            context.clip();
            context.translate(shirtVFrillsRect.x, shirtVFrillsRect.y);

            AvatarStyleKit.drawVFrillCollarShirt(canvas, skinColor, shirtColor, makeRect(0, 0, shirtVFrillsRect.w, shirtVFrillsRect.h), 'stretch');
            context.restore();
        }


        if (_shirtHZigZag)
        {
            //// shirtHZigZag Drawing
            var shirtHZigZagRect = makeRect(50, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtHZigZagRect.x, shirtHZigZagRect.y, shirtHZigZagRect.w, shirtHZigZagRect.h);
            context.clip();
            context.translate(shirtHZigZagRect.x, shirtHZigZagRect.y);

            AvatarStyleKit.drawHZigZagShirt(canvas, skinColor, shirtColor, makeRect(0, 0, shirtHZigZagRect.w, shirtHZigZagRect.h), 'stretch');
            context.restore();
        }


        if (_shirtHalloween)
        {
            //// shirtHalloween Drawing
            var shirtHalloweenRect = makeRect(49.5, 130.6, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtHalloweenRect.x, shirtHalloweenRect.y, shirtHalloweenRect.w, shirtHalloweenRect.h);
            context.clip();
            context.translate(shirtHalloweenRect.x, shirtHalloweenRect.y);

            AvatarStyleKit.drawHalloweenShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtHalloweenRect.w, shirtHalloweenRect.h), 'stretch');
            context.restore();
        }


        if (_shirtBowtie)
        {
            //// shirtBowtie Drawing
            var shirtBowtieRect = makeRect(50, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtBowtieRect.x, shirtBowtieRect.y, shirtBowtieRect.w, shirtBowtieRect.h);
            context.clip();
            context.translate(shirtBowtieRect.x, shirtBowtieRect.y);

            AvatarStyleKit.drawBowtieShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtBowtieRect.w, shirtBowtieRect.h), 'stretch');
            context.restore();
        }


        if (_shirtHijab)
        {
            //// shirtHijab Drawing
            var shirtHijabRect = makeRect(45.3, 130.7, 77, 26);
            context.save();
            context.beginPath();
            context.rect(shirtHijabRect.x, shirtHijabRect.y, shirtHijabRect.w, shirtHijabRect.h);
            context.clip();
            context.translate(shirtHijabRect.x, shirtHijabRect.y);

            AvatarStyleKit.drawHijabShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtHijabRect.w, shirtHijabRect.h), 'stretch');
            context.restore();
        }


        if (_shirtTie)
        {
            //// shirttie Drawing
            var shirttieRect = makeRect(50.2, 131.4, 59, 39);
            context.save();
            context.beginPath();
            context.rect(shirttieRect.x, shirttieRect.y, shirttieRect.w, shirttieRect.h);
            context.clip();
            context.translate(shirttieRect.x, shirttieRect.y);

            AvatarStyleKit.drawTieShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirttieRect.w, shirttieRect.h), 'stretch');
            context.restore();
        }


        if (_shirtCheck)
        {
            //// shirtcheck Drawing
            var shirtcheckRect = makeRect(50, 131.4, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtcheckRect.x, shirtcheckRect.y, shirtcheckRect.w, shirtcheckRect.h);
            context.clip();
            context.translate(shirtcheckRect.x, shirtcheckRect.y);

            AvatarStyleKit.drawCheckShirt(canvas, skinColor, shirtColor, shirtColor2, makeRect(0, 0, shirtcheckRect.w, shirtcheckRect.h), 'stretch');
            context.restore();
        }


        if (_shirtWand)
        {
            //// shirtWand Drawing
            var shirtWandRect = makeRect(48.8, 120.3, 70, 37);
            context.save();
            context.beginPath();
            context.rect(shirtWandRect.x, shirtWandRect.y, shirtWandRect.w, shirtWandRect.h);
            context.clip();
            context.translate(shirtWandRect.x, shirtWandRect.y);

            AvatarStyleKit.drawWandShirt(canvas, skinColor, shirtColor, makeRect(0, 0, shirtWandRect.w, shirtWandRect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball24)
        {
            //// shirtFootball24 Drawing
            var shirtFootball24Rect = makeRect(50, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball24Rect.x, shirtFootball24Rect.y, shirtFootball24Rect.w, shirtFootball24Rect.h);
            context.clip();
            context.translate(shirtFootball24Rect.x, shirtFootball24Rect.y);

            AvatarStyleKit.drawFootballShirt24(canvas, skinColor, makeRect(0, 0, shirtFootball24Rect.w, shirtFootball24Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball23)
        {
            //// shirtFootball23 Drawing
            var shirtFootball23Rect = makeRect(49.3, 130.5, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball23Rect.x, shirtFootball23Rect.y, shirtFootball23Rect.w, shirtFootball23Rect.h);
            context.clip();
            context.translate(shirtFootball23Rect.x, shirtFootball23Rect.y);

            AvatarStyleKit.drawFootballShirt23(canvas, skinColor, makeRect(0, 0, shirtFootball23Rect.w, shirtFootball23Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball22)
        {
            //// shirtFootball22 Drawing
            var shirtFootball22Rect = makeRect(50.3, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball22Rect.x, shirtFootball22Rect.y, shirtFootball22Rect.w, shirtFootball22Rect.h);
            context.clip();
            context.translate(shirtFootball22Rect.x, shirtFootball22Rect.y);

            AvatarStyleKit.drawFootballShirt22(canvas, skinColor, makeRect(0, 0, shirtFootball22Rect.w, shirtFootball22Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball21)
        {
            //// shirtFootball21 Drawing
            var shirtFootball21Rect = makeRect(50.1, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball21Rect.x, shirtFootball21Rect.y, shirtFootball21Rect.w, shirtFootball21Rect.h);
            context.clip();
            context.translate(shirtFootball21Rect.x, shirtFootball21Rect.y);

            AvatarStyleKit.drawFootballShirt21(canvas, skinColor, makeRect(0, 0, shirtFootball21Rect.w, shirtFootball21Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball20)
        {
            //// shirtFootball20 Drawing
            var shirtFootball20Rect = makeRect(49.7, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball20Rect.x, shirtFootball20Rect.y, shirtFootball20Rect.w, shirtFootball20Rect.h);
            context.clip();
            context.translate(shirtFootball20Rect.x, shirtFootball20Rect.y);

            AvatarStyleKit.drawFootballShirt20(canvas, skinColor, makeRect(0, 0, shirtFootball20Rect.w, shirtFootball20Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball19)
        {
            //// shirtFootball19 Drawing
            var shirtFootball19Rect = makeRect(50, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball19Rect.x, shirtFootball19Rect.y, shirtFootball19Rect.w, shirtFootball19Rect.h);
            context.clip();
            context.translate(shirtFootball19Rect.x, shirtFootball19Rect.y);

            AvatarStyleKit.drawFootballShirt19(canvas, skinColor, makeRect(0, 0, shirtFootball19Rect.w, shirtFootball19Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball18)
        {
            //// shirtFootball18 Drawing
            var shirtFootball18Rect = makeRect(49.5, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball18Rect.x, shirtFootball18Rect.y, shirtFootball18Rect.w, shirtFootball18Rect.h);
            context.clip();
            context.translate(shirtFootball18Rect.x, shirtFootball18Rect.y);

            AvatarStyleKit.drawFootballShirt18(canvas, skinColor, makeRect(0, 0, shirtFootball18Rect.w, shirtFootball18Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball17)
        {
            //// shirtFootball17 Drawing
            var shirtFootball17Rect = makeRect(50.2, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball17Rect.x, shirtFootball17Rect.y, shirtFootball17Rect.w, shirtFootball17Rect.h);
            context.clip();
            context.translate(shirtFootball17Rect.x, shirtFootball17Rect.y);

            AvatarStyleKit.drawFootballShirt17(canvas, skinColor, makeRect(0, 0, shirtFootball17Rect.w, shirtFootball17Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball16)
        {
            //// shirtFootball16 Drawing
            var shirtFootball16Rect = makeRect(49.4, 130.8, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball16Rect.x, shirtFootball16Rect.y, shirtFootball16Rect.w, shirtFootball16Rect.h);
            context.clip();
            context.translate(shirtFootball16Rect.x, shirtFootball16Rect.y);

            AvatarStyleKit.drawFootballShirt16(canvas, skinColor, makeRect(0, 0, shirtFootball16Rect.w, shirtFootball16Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball15)
        {
            //// shirtFootball15 Drawing
            var shirtFootball15Rect = makeRect(49.5, 130.8, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball15Rect.x, shirtFootball15Rect.y, shirtFootball15Rect.w, shirtFootball15Rect.h);
            context.clip();
            context.translate(shirtFootball15Rect.x, shirtFootball15Rect.y);

            AvatarStyleKit.drawFootballShirt15(canvas, skinColor, makeRect(0, 0, shirtFootball15Rect.w, shirtFootball15Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball14)
        {
            //// shirtFootball14 Drawing
            var shirtFootball14Rect = makeRect(49.5, 130.7, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball14Rect.x, shirtFootball14Rect.y, shirtFootball14Rect.w, shirtFootball14Rect.h);
            context.clip();
            context.translate(shirtFootball14Rect.x, shirtFootball14Rect.y);

            AvatarStyleKit.drawFootballShirt14(canvas, skinColor, makeRect(0, 0, shirtFootball14Rect.w, shirtFootball14Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball13)
        {
            //// shirtFootball13 Drawing
            var shirtFootball13Rect = makeRect(49.4, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball13Rect.x, shirtFootball13Rect.y, shirtFootball13Rect.w, shirtFootball13Rect.h);
            context.clip();
            context.translate(shirtFootball13Rect.x, shirtFootball13Rect.y);

            AvatarStyleKit.drawFootballShirt13(canvas, skinColor, makeRect(0, 0, shirtFootball13Rect.w, shirtFootball13Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball12)
        {
            //// shirtFootball12 Drawing
            var shirtFootball12Rect = makeRect(49.3, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball12Rect.x, shirtFootball12Rect.y, shirtFootball12Rect.w, shirtFootball12Rect.h);
            context.clip();
            context.translate(shirtFootball12Rect.x, shirtFootball12Rect.y);

            AvatarStyleKit.drawFootballShirt12(canvas, skinColor, makeRect(0, 0, shirtFootball12Rect.w, shirtFootball12Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball11)
        {
            //// shirtFootball11 Drawing
            var shirtFootball11Rect = makeRect(49.7, 130.5, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball11Rect.x, shirtFootball11Rect.y, shirtFootball11Rect.w, shirtFootball11Rect.h);
            context.clip();
            context.translate(shirtFootball11Rect.x, shirtFootball11Rect.y);

            AvatarStyleKit.drawFootballShirt11(canvas, skinColor, makeRect(0, 0, shirtFootball11Rect.w, shirtFootball11Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball10)
        {
            //// shirtFootball10 Drawing
            var shirtFootball10Rect = makeRect(49.7, 130.6, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball10Rect.x, shirtFootball10Rect.y, shirtFootball10Rect.w, shirtFootball10Rect.h);
            context.clip();
            context.translate(shirtFootball10Rect.x, shirtFootball10Rect.y);

            AvatarStyleKit.drawFootballShirt10(canvas, skinColor, makeRect(0, 0, shirtFootball10Rect.w, shirtFootball10Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball9)
        {
            //// shirtFootball9 Drawing
            var shirtFootball9Rect = makeRect(49.5, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball9Rect.x, shirtFootball9Rect.y, shirtFootball9Rect.w, shirtFootball9Rect.h);
            context.clip();
            context.translate(shirtFootball9Rect.x, shirtFootball9Rect.y);

            AvatarStyleKit.drawFootballShirt9(canvas, skinColor, makeRect(0, 0, shirtFootball9Rect.w, shirtFootball9Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball8)
        {
            //// shirtFootball8 Drawing
            var shirtFootball8Rect = makeRect(49.7, 130.7, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball8Rect.x, shirtFootball8Rect.y, shirtFootball8Rect.w, shirtFootball8Rect.h);
            context.clip();
            context.translate(shirtFootball8Rect.x, shirtFootball8Rect.y);

            AvatarStyleKit.drawFootballShirt8(canvas, skinColor, makeRect(0, 0, shirtFootball8Rect.w, shirtFootball8Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball7)
        {
            //// shirtFootball7 Drawing
            var shirtFootball7Rect = makeRect(49.5, 130.6, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball7Rect.x, shirtFootball7Rect.y, shirtFootball7Rect.w, shirtFootball7Rect.h);
            context.clip();
            context.translate(shirtFootball7Rect.x, shirtFootball7Rect.y);

            AvatarStyleKit.drawFootballShirt7(canvas, skinColor, makeRect(0, 0, shirtFootball7Rect.w, shirtFootball7Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball6)
        {
            //// shirtFootball6 Drawing
            var shirtFootball6Rect = makeRect(49.5, 130.6, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball6Rect.x, shirtFootball6Rect.y, shirtFootball6Rect.w, shirtFootball6Rect.h);
            context.clip();
            context.translate(shirtFootball6Rect.x, shirtFootball6Rect.y);

            AvatarStyleKit.drawFootballShirt6(canvas, skinColor, makeRect(0, 0, shirtFootball6Rect.w, shirtFootball6Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball5)
        {
            //// shirtFootball5 Drawing
            var shirtFootball5Rect = makeRect(49.5, 130.5, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball5Rect.x, shirtFootball5Rect.y, shirtFootball5Rect.w, shirtFootball5Rect.h);
            context.clip();
            context.translate(shirtFootball5Rect.x, shirtFootball5Rect.y);

            AvatarStyleKit.drawFootballShirt5(canvas, skinColor, makeRect(0, 0, shirtFootball5Rect.w, shirtFootball5Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball4)
        {
            //// shirtFootball4 Drawing
            var shirtFootball4Rect = makeRect(50.3, 131.2, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball4Rect.x, shirtFootball4Rect.y, shirtFootball4Rect.w, shirtFootball4Rect.h);
            context.clip();
            context.translate(shirtFootball4Rect.x, shirtFootball4Rect.y);

            AvatarStyleKit.drawFootballShirt4(canvas, skinColor, makeRect(0, 0, shirtFootball4Rect.w, shirtFootball4Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball3)
        {
            //// shirtFootball3 Drawing
            var shirtFootball3Rect = makeRect(49.5, 131.3, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball3Rect.x, shirtFootball3Rect.y, shirtFootball3Rect.w, shirtFootball3Rect.h);
            context.clip();
            context.translate(shirtFootball3Rect.x, shirtFootball3Rect.y);

            AvatarStyleKit.drawFootballShirt3(canvas, skinColor, makeRect(0, 0, shirtFootball3Rect.w, shirtFootball3Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball2)
        {
            //// shirtFootball2 Drawing
            var shirtFootball2Rect = makeRect(50, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball2Rect.x, shirtFootball2Rect.y, shirtFootball2Rect.w, shirtFootball2Rect.h);
            context.clip();
            context.translate(shirtFootball2Rect.x, shirtFootball2Rect.y);

            AvatarStyleKit.drawFootballShirt2(canvas, skinColor, makeRect(0, 0, shirtFootball2Rect.w, shirtFootball2Rect.h), 'stretch');
            context.restore();
        }


        if (_shirtFootball1)
        {
            //// shirtFootball1 Drawing
            var shirtFootball1Rect = makeRect(50, 131, 59, 26);
            context.save();
            context.beginPath();
            context.rect(shirtFootball1Rect.x, shirtFootball1Rect.y, shirtFootball1Rect.w, shirtFootball1Rect.h);
            context.clip();
            context.translate(shirtFootball1Rect.x, shirtFootball1Rect.y);

            AvatarStyleKit.drawFootballShirt1(canvas, skinColor, makeRect(0, 0, shirtFootball1Rect.w, shirtFootball1Rect.h), 'stretch');
            context.restore();
        }


        if (_showMonkeyMask)
        {
            //// accessoryMonkeyMask Drawing
            var accessoryMonkeyMaskRect = makeRect(45.2, 76, 70, 41);
            context.save();
            context.beginPath();
            context.rect(accessoryMonkeyMaskRect.x, accessoryMonkeyMaskRect.y, accessoryMonkeyMaskRect.w, accessoryMonkeyMaskRect.h);
            context.clip();
            context.translate(accessoryMonkeyMaskRect.x, accessoryMonkeyMaskRect.y);

            AvatarStyleKit.drawMonkeyMask(canvas, makeRect(0, 0, accessoryMonkeyMaskRect.w, accessoryMonkeyMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showUnicornMask)
        {
            //// accessoryUnicornMask Drawing
            var accessoryUnicornMaskRect = makeRect(47.5, 64, 65, 54);
            context.save();
            context.beginPath();
            context.rect(accessoryUnicornMaskRect.x, accessoryUnicornMaskRect.y, accessoryUnicornMaskRect.w, accessoryUnicornMaskRect.h);
            context.clip();
            context.translate(accessoryUnicornMaskRect.x, accessoryUnicornMaskRect.y);

            AvatarStyleKit.drawUnicornMask(canvas, makeRect(0, 0, accessoryUnicornMaskRect.w, accessoryUnicornMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showDogMask)
        {
            //// accessoryDogMask Drawing
            var accessoryDogMaskRect = makeRect(47, 75, 74, 44);
            context.save();
            context.beginPath();
            context.rect(accessoryDogMaskRect.x, accessoryDogMaskRect.y, accessoryDogMaskRect.w, accessoryDogMaskRect.h);
            context.clip();
            context.translate(accessoryDogMaskRect.x, accessoryDogMaskRect.y);

            AvatarStyleKit.drawDogMask(canvas, makeRect(0, 0, accessoryDogMaskRect.w, accessoryDogMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showZebraMask)
        {
            //// accessoryZebraMask Drawing
            var accessoryZebraMaskRect = makeRect(48, 74, 63, 44);
            context.save();
            context.beginPath();
            context.rect(accessoryZebraMaskRect.x, accessoryZebraMaskRect.y, accessoryZebraMaskRect.w, accessoryZebraMaskRect.h);
            context.clip();
            context.translate(accessoryZebraMaskRect.x, accessoryZebraMaskRect.y);

            AvatarStyleKit.drawZebraMask(canvas, makeRect(0, 0, accessoryZebraMaskRect.w, accessoryZebraMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showReindeerMask)
        {
            //// accessoryReindeerMask Drawing
            var accessoryReindeerMaskRect = makeRect(45, 69, 69, 51);
            context.save();
            context.beginPath();
            context.rect(accessoryReindeerMaskRect.x, accessoryReindeerMaskRect.y, accessoryReindeerMaskRect.w, accessoryReindeerMaskRect.h);
            context.clip();
            context.translate(accessoryReindeerMaskRect.x, accessoryReindeerMaskRect.y);

            AvatarStyleKit.drawReindeerMask(canvas, makeRect(0, 0, accessoryReindeerMaskRect.w, accessoryReindeerMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showTigerMask)
        {
            //// accessoryTigerMask Drawing
            var accessoryTigerMaskRect = makeRect(48, 79, 64, 39);
            context.save();
            context.beginPath();
            context.rect(accessoryTigerMaskRect.x, accessoryTigerMaskRect.y, accessoryTigerMaskRect.w, accessoryTigerMaskRect.h);
            context.clip();
            context.translate(accessoryTigerMaskRect.x, accessoryTigerMaskRect.y);

            AvatarStyleKit.drawTigerMask(canvas, makeRect(0, 0, accessoryTigerMaskRect.w, accessoryTigerMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showLionMask)
        {
            //// accessoryLionMask Drawing
            var accessoryLionMaskRect = makeRect(36.5, 58, 87, 80);
            context.save();
            context.beginPath();
            context.rect(accessoryLionMaskRect.x, accessoryLionMaskRect.y, accessoryLionMaskRect.w, accessoryLionMaskRect.h);
            context.clip();
            context.translate(accessoryLionMaskRect.x, accessoryLionMaskRect.y);

            AvatarStyleKit.drawLionMask(canvas, makeRect(0, 0, accessoryLionMaskRect.w, accessoryLionMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showGiraffeMask)
        {
            //// accessoryGiraffeMask Drawing
            var accessoryGiraffeMaskRect = makeRect(46, 73, 66, 45);
            context.save();
            context.beginPath();
            context.rect(accessoryGiraffeMaskRect.x, accessoryGiraffeMaskRect.y, accessoryGiraffeMaskRect.w, accessoryGiraffeMaskRect.h);
            context.clip();
            context.translate(accessoryGiraffeMaskRect.x, accessoryGiraffeMaskRect.y);

            AvatarStyleKit.drawGiraffeMask(canvas, makeRect(0, 0, accessoryGiraffeMaskRect.w, accessoryGiraffeMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showElephantMask)
        {
            //// accessoryElephantMask Drawing
            var accessoryElephantMaskRect = makeRect(27, 73, 104, 66);
            context.save();
            context.beginPath();
            context.rect(accessoryElephantMaskRect.x, accessoryElephantMaskRect.y, accessoryElephantMaskRect.w, accessoryElephantMaskRect.h);
            context.clip();
            context.translate(accessoryElephantMaskRect.x, accessoryElephantMaskRect.y);

            AvatarStyleKit.drawElephantMask(canvas, makeRect(0, 0, accessoryElephantMaskRect.w, accessoryElephantMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showPigMask)
        {
            //// accessoryPigMask Drawing
            var accessoryPigMaskRect = makeRect(46, 83, 66, 36);
            context.save();
            context.beginPath();
            context.rect(accessoryPigMaskRect.x, accessoryPigMaskRect.y, accessoryPigMaskRect.w, accessoryPigMaskRect.h);
            context.clip();
            context.translate(accessoryPigMaskRect.x, accessoryPigMaskRect.y);

            AvatarStyleKit.drawPigMask(canvas, makeRect(0, 0, accessoryPigMaskRect.w, accessoryPigMaskRect.h), 'stretch');
            context.restore();
        }


        if (_showCatMask)
        {
            //// accessoryCatMask Drawing
            context.save();
            context.translate(48, 117);

            var accessoryCatMaskRect = makeRect(0, -37, 63, 37);
            context.save();
            context.beginPath();
            context.rect(accessoryCatMaskRect.x, accessoryCatMaskRect.y, accessoryCatMaskRect.w, accessoryCatMaskRect.h);
            context.clip();
            context.translate(accessoryCatMaskRect.x, accessoryCatMaskRect.y);

            AvatarStyleKit.drawCatMask(canvas, makeRect(0, 0, accessoryCatMaskRect.w, accessoryCatMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPandaMask)
        {
            //// accessoryPandaMask Drawing
            context.save();
            context.translate(47.98, 117.37);

            var accessoryPandaMaskRect = makeRect(0, -40, 63, 40);
            context.save();
            context.beginPath();
            context.rect(accessoryPandaMaskRect.x, accessoryPandaMaskRect.y, accessoryPandaMaskRect.w, accessoryPandaMaskRect.h);
            context.clip();
            context.translate(accessoryPandaMaskRect.x, accessoryPandaMaskRect.y);

            AvatarStyleKit.drawPandaMask(canvas, makeRect(0, 0, accessoryPandaMaskRect.w, accessoryPandaMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showDisguiseMask)
        {
            //// accessoryDisguiseMask Drawing
            context.save();
            context.translate(51.79, 126.22);

            var accessoryDisguiseMaskRect = makeRect(0, -37, 56, 37);
            context.save();
            context.beginPath();
            context.rect(accessoryDisguiseMaskRect.x, accessoryDisguiseMaskRect.y, accessoryDisguiseMaskRect.w, accessoryDisguiseMaskRect.h);
            context.clip();
            context.translate(accessoryDisguiseMaskRect.x, accessoryDisguiseMaskRect.y);

            AvatarStyleKit.drawDisguiseMask(canvas, makeRect(0, 0, accessoryDisguiseMaskRect.w, accessoryDisguiseMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showSnorkel)
        {
            //// accessorySnorkel Drawing
            context.save();
            context.translate(50.18, 150.85);

            var accessorySnorkelRect = makeRect(0, -119, 73, 119);
            context.save();
            context.beginPath();
            context.rect(accessorySnorkelRect.x, accessorySnorkelRect.y, accessorySnorkelRect.w, accessorySnorkelRect.h);
            context.clip();
            context.translate(accessorySnorkelRect.x, accessorySnorkelRect.y);

            AvatarStyleKit.drawSnorkel(canvas, makeRect(0, 0, accessorySnorkelRect.w, accessorySnorkelRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showBatmanMask)
        {
            //// accessoryBatmanMask Drawing
            context.save();
            context.translate(50.4, 139);

            var accessoryBatmanMaskRect = makeRect(0, -76, 57, 76);
            context.save();
            context.beginPath();
            context.rect(accessoryBatmanMaskRect.x, accessoryBatmanMaskRect.y, accessoryBatmanMaskRect.w, accessoryBatmanMaskRect.h);
            context.clip();
            context.translate(accessoryBatmanMaskRect.x, accessoryBatmanMaskRect.y);

            AvatarStyleKit.drawBatmanMask(canvas, makeRect(0, 0, accessoryBatmanMaskRect.w, accessoryBatmanMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPuppyMask)
        {
            //// accessoryPuppyMask Drawing
            context.save();
            context.translate(48, 118);

            var accessoryPuppyMaskRect = makeRect(0, -36, 63, 36);
            context.save();
            context.beginPath();
            context.rect(accessoryPuppyMaskRect.x, accessoryPuppyMaskRect.y, accessoryPuppyMaskRect.w, accessoryPuppyMaskRect.h);
            context.clip();
            context.translate(accessoryPuppyMaskRect.x, accessoryPuppyMaskRect.y);

            AvatarStyleKit.drawPuppyMask(canvas, makeRect(0, 0, accessoryPuppyMaskRect.w, accessoryPuppyMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showScaryMask)
        {
            //// accessoryScaryMask Drawing
            context.save();
            context.translate(50.2, 130.53);

            var accessoryScaryMaskRect = makeRect(0, -50, 59, 50);
            context.save();
            context.beginPath();
            context.rect(accessoryScaryMaskRect.x, accessoryScaryMaskRect.y, accessoryScaryMaskRect.w, accessoryScaryMaskRect.h);
            context.clip();
            context.translate(accessoryScaryMaskRect.x, accessoryScaryMaskRect.y);

            AvatarStyleKit.drawScaryMask(canvas, makeRect(0, 0, accessoryScaryMaskRect.w, accessoryScaryMaskRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPirateHat)
        {
            //// accessoryPirateHat Drawing
            context.save();
            context.translate(32, 92);

            var accessoryPirateHatRect = makeRect(0, -48, 96, 48);
            context.save();
            context.beginPath();
            context.rect(accessoryPirateHatRect.x, accessoryPirateHatRect.y, accessoryPirateHatRect.w, accessoryPirateHatRect.h);
            context.clip();
            context.translate(accessoryPirateHatRect.x, accessoryPirateHatRect.y);

            AvatarStyleKit.drawPirateHat(canvas, makeRect(0, 0, accessoryPirateHatRect.w, accessoryPirateHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showJesterHat)
        {
            //// accessoryJesterHat Drawing
            context.save();
            context.translate(14.95, 84.42);

            var accessoryJesterHatRect = makeRect(0, -83, 136, 83);
            context.save();
            context.beginPath();
            context.rect(accessoryJesterHatRect.x, accessoryJesterHatRect.y, accessoryJesterHatRect.w, accessoryJesterHatRect.h);
            context.clip();
            context.translate(accessoryJesterHatRect.x, accessoryJesterHatRect.y);

            AvatarStyleKit.drawJesterHat(canvas, makeRect(0, 0, accessoryJesterHatRect.w, accessoryJesterHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showBlueScarf)
        {
            //// accessoryBlueScarf Drawing
            context.save();
            context.translate(59.17, 183.52);

            var accessoryBlueScarfRect = makeRect(0, -55, 44, 55);
            context.save();
            context.beginPath();
            context.rect(accessoryBlueScarfRect.x, accessoryBlueScarfRect.y, accessoryBlueScarfRect.w, accessoryBlueScarfRect.h);
            context.clip();
            context.translate(accessoryBlueScarfRect.x, accessoryBlueScarfRect.y);

            AvatarStyleKit.drawBlueScarf(canvas, makeRect(0, 0, accessoryBlueScarfRect.w, accessoryBlueScarfRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showHeadScarf)
        {
            //// accessoryHeadScarf Drawing
            context.save();
            context.translate(43.58, 98.2);

            var accessoryHeadScarfRect = makeRect(0, -33, 88, 33);
            context.save();
            context.beginPath();
            context.rect(accessoryHeadScarfRect.x, accessoryHeadScarfRect.y, accessoryHeadScarfRect.w, accessoryHeadScarfRect.h);
            context.clip();
            context.translate(accessoryHeadScarfRect.x, accessoryHeadScarfRect.y);

            AvatarStyleKit.drawHeadScarf(canvas, makeRect(0, 0, accessoryHeadScarfRect.w, accessoryHeadScarfRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showAstronaut)
        {
            //// accessoryAstronautHelmet Drawing
            context.save();
            context.translate(32.64, 146.29);

            var accessoryAstronautHelmetRect = makeRect(0, -90, 94, 90);
            context.save();
            context.beginPath();
            context.rect(accessoryAstronautHelmetRect.x, accessoryAstronautHelmetRect.y, accessoryAstronautHelmetRect.w, accessoryAstronautHelmetRect.h);
            context.clip();
            context.translate(accessoryAstronautHelmetRect.x, accessoryAstronautHelmetRect.y);

            AvatarStyleKit.drawAstronautHelmet(canvas, makeRect(0, 0, accessoryAstronautHelmetRect.w, accessoryAstronautHelmetRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showSombrero)
        {
            //// accessorySombrero Drawing
            context.save();
            context.translate(27.16, 89.1);

            var accessorySombreroRect = makeRect(0, -54, 103, 54);
            context.save();
            context.beginPath();
            context.rect(accessorySombreroRect.x, accessorySombreroRect.y, accessorySombreroRect.w, accessorySombreroRect.h);
            context.clip();
            context.translate(accessorySombreroRect.x, accessorySombreroRect.y);

            AvatarStyleKit.drawSombrero(canvas, makeRect(0, 0, accessorySombreroRect.w, accessorySombreroRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPinkCap)
        {
            //// accessoryPinkHat Drawing
            context.save();
            context.translate(46.24, 100.12);

            var accessoryPinkHatRect = makeRect(0, -55, 79, 55);
            context.save();
            context.beginPath();
            context.rect(accessoryPinkHatRect.x, accessoryPinkHatRect.y, accessoryPinkHatRect.w, accessoryPinkHatRect.h);
            context.clip();
            context.translate(accessoryPinkHatRect.x, accessoryPinkHatRect.y);

            AvatarStyleKit.drawPinkCap(canvas, makeRect(0, 0, accessoryPinkHatRect.w, accessoryPinkHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPinkWoolyHat)
        {
            //// accessoryPinkWoolyHat Drawing
            context.save();
            context.translate(43.39, 95.56);

            var accessoryPinkWoolyHatRect = makeRect(0, -77, 71, 77);
            context.save();
            context.beginPath();
            context.rect(accessoryPinkWoolyHatRect.x, accessoryPinkWoolyHatRect.y, accessoryPinkWoolyHatRect.w, accessoryPinkWoolyHatRect.h);
            context.clip();
            context.translate(accessoryPinkWoolyHatRect.x, accessoryPinkWoolyHatRect.y);

            AvatarStyleKit.drawPinkWoolyHat(canvas, makeRect(0, 0, accessoryPinkWoolyHatRect.w, accessoryPinkWoolyHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPinkScarf)
        {
            //// accessoryPinkScarf Drawing
            context.save();
            context.translate(59.2, 182.5);

            var accessoryPinkScarfRect = makeRect(0, -55, 44, 55);
            context.save();
            context.beginPath();
            context.rect(accessoryPinkScarfRect.x, accessoryPinkScarfRect.y, accessoryPinkScarfRect.w, accessoryPinkScarfRect.h);
            context.clip();
            context.translate(accessoryPinkScarfRect.x, accessoryPinkScarfRect.y);

            AvatarStyleKit.drawPinkScarf(canvas, makeRect(0, 0, accessoryPinkScarfRect.w, accessoryPinkScarfRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showFlowerHairband)
        {
            //// accessoryFlowerHairband Drawing
            context.save();
            context.translate(42.3, 97.58);

            var accessoryFlowerHairbandRect = makeRect(0, -34, 72, 34);
            context.save();
            context.beginPath();
            context.rect(accessoryFlowerHairbandRect.x, accessoryFlowerHairbandRect.y, accessoryFlowerHairbandRect.w, accessoryFlowerHairbandRect.h);
            context.clip();
            context.translate(accessoryFlowerHairbandRect.x, accessoryFlowerHairbandRect.y);

            AvatarStyleKit.drawFlowerHairband(canvas, makeRect(0, 0, accessoryFlowerHairbandRect.w, accessoryFlowerHairbandRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showBlueWoolyHat)
        {
            //// accessoryBlueWoolyHat Drawing
            context.save();
            context.translate(43.24, 94.31);

            var accessoryBlueWoolyHatRect = makeRect(0, -77, 72, 77);
            context.save();
            context.beginPath();
            context.rect(accessoryBlueWoolyHatRect.x, accessoryBlueWoolyHatRect.y, accessoryBlueWoolyHatRect.w, accessoryBlueWoolyHatRect.h);
            context.clip();
            context.translate(accessoryBlueWoolyHatRect.x, accessoryBlueWoolyHatRect.y);

            AvatarStyleKit.drawBlueWoolyHat(canvas, makeRect(0, 0, accessoryBlueWoolyHatRect.w, accessoryBlueWoolyHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showPinkHeadphones)
        {
            //// accessoryPinkHeadphones Drawing
            context.save();
            context.translate(37.8, 115.28);

            var accessoryPinkHeadphonesRect = makeRect(0, -51, 84, 51);
            context.save();
            context.beginPath();
            context.rect(accessoryPinkHeadphonesRect.x, accessoryPinkHeadphonesRect.y, accessoryPinkHeadphonesRect.w, accessoryPinkHeadphonesRect.h);
            context.clip();
            context.translate(accessoryPinkHeadphonesRect.x, accessoryPinkHeadphonesRect.y);

            AvatarStyleKit.drawPinkHeadphones(canvas, makeRect(0, 0, accessoryPinkHeadphonesRect.w, accessoryPinkHeadphonesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showCrown)
        {
            //// accessoryCrown Drawing
            context.save();
            context.translate(47.45, 81.87);

            var accessoryCrownRect = makeRect(0, -38, 66, 38);
            context.save();
            context.beginPath();
            context.rect(accessoryCrownRect.x, accessoryCrownRect.y, accessoryCrownRect.w, accessoryCrownRect.h);
            context.clip();
            context.translate(accessoryCrownRect.x, accessoryCrownRect.y);

            AvatarStyleKit.drawCrown(canvas, makeRect(0, 0, accessoryCrownRect.w, accessoryCrownRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showStarHeadband)
        {
            //// accessoryStarHeadband Drawing
            context.save();
            context.translate(44, 96.58);

            var accessoryStarHeadbandRect = makeRect(0, -34, 71, 34);
            context.save();
            context.beginPath();
            context.rect(accessoryStarHeadbandRect.x, accessoryStarHeadbandRect.y, accessoryStarHeadbandRect.w, accessoryStarHeadbandRect.h);
            context.clip();
            context.translate(accessoryStarHeadbandRect.x, accessoryStarHeadbandRect.y);

            AvatarStyleKit.drawStarHeadband(canvas, makeRect(0, 0, accessoryStarHeadbandRect.w, accessoryStarHeadbandRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showBlueTassleHat)
        {
            //// accessoryBlueTassleHat Drawing
            context.save();
            context.translate(30.65, 150.4);

            var accessoryBlueTassleHatRect = makeRect(0, -111, 100, 111);
            context.save();
            context.beginPath();
            context.rect(accessoryBlueTassleHatRect.x, accessoryBlueTassleHatRect.y, accessoryBlueTassleHatRect.w, accessoryBlueTassleHatRect.h);
            context.clip();
            context.translate(accessoryBlueTassleHatRect.x, accessoryBlueTassleHatRect.y);

            AvatarStyleKit.drawBlueTassleHat(canvas, makeRect(0, 0, accessoryBlueTassleHatRect.w, accessoryBlueTassleHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showGreenHeadphones)
        {
            //// accessoryGreenHeadphones Drawing
            context.save();
            context.translate(37.8, 115.28);

            var accessoryGreenHeadphonesRect = makeRect(0, -51, 84, 51);
            context.save();
            context.beginPath();
            context.rect(accessoryGreenHeadphonesRect.x, accessoryGreenHeadphonesRect.y, accessoryGreenHeadphonesRect.w, accessoryGreenHeadphonesRect.h);
            context.clip();
            context.translate(accessoryGreenHeadphonesRect.x, accessoryGreenHeadphonesRect.y);

            AvatarStyleKit.drawGreenHeadphones(canvas, makeRect(0, 0, accessoryGreenHeadphonesRect.w, accessoryGreenHeadphonesRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showGreenHeadband)
        {
            //// accessoryGreenHeadband Drawing
            context.save();
            context.translate(44.62, 93.46);

            var accessoryGreenHeadbandRect = makeRect(0, -28, 70, 28);
            context.save();
            context.beginPath();
            context.rect(accessoryGreenHeadbandRect.x, accessoryGreenHeadbandRect.y, accessoryGreenHeadbandRect.w, accessoryGreenHeadbandRect.h);
            context.clip();
            context.translate(accessoryGreenHeadbandRect.x, accessoryGreenHeadbandRect.y);

            AvatarStyleKit.drawGreenHeadband(canvas, makeRect(0, 0, accessoryGreenHeadbandRect.w, accessoryGreenHeadbandRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showBlueCap)
        {
            //// accessoryBlueCap Drawing
            context.save();
            context.translate(34, 100);

            var accessoryBlueCapRect = makeRect(0, -55, 79, 55);
            context.save();
            context.beginPath();
            context.rect(accessoryBlueCapRect.x, accessoryBlueCapRect.y, accessoryBlueCapRect.w, accessoryBlueCapRect.h);
            context.clip();
            context.translate(accessoryBlueCapRect.x, accessoryBlueCapRect.y);

            AvatarStyleKit.drawBlueCap(canvas, makeRect(0, 0, accessoryBlueCapRect.w, accessoryBlueCapRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showOrangeHeadband)
        {
            //// accessoryOrangeHeadband Drawing
            context.save();
            context.translate(43, 96);

            var accessoryOrangeHeadbandRect = makeRect(0, -30, 72, 30);
            context.save();
            context.beginPath();
            context.rect(accessoryOrangeHeadbandRect.x, accessoryOrangeHeadbandRect.y, accessoryOrangeHeadbandRect.w, accessoryOrangeHeadbandRect.h);
            context.clip();
            context.translate(accessoryOrangeHeadbandRect.x, accessoryOrangeHeadbandRect.y);

            AvatarStyleKit.drawOrangeHeadband(canvas, makeRect(0, 0, accessoryOrangeHeadbandRect.w, accessoryOrangeHeadbandRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showGreenWoolyHat)
        {
            //// accessoryGreenWookyHat Drawing
            context.save();
            context.translate(43, 96);

            var accessoryGreenWookyHatRect = makeRect(0, -58, 72, 58);
            context.save();
            context.beginPath();
            context.rect(accessoryGreenWookyHatRect.x, accessoryGreenWookyHatRect.y, accessoryGreenWookyHatRect.w, accessoryGreenWookyHatRect.h);
            context.clip();
            context.translate(accessoryGreenWookyHatRect.x, accessoryGreenWookyHatRect.y);

            AvatarStyleKit.drawGreenWoolyHat(canvas, makeRect(0, 0, accessoryGreenWookyHatRect.w, accessoryGreenWookyHatRect.h), 'stretch');
            context.restore();

            context.restore();
        }


        if (_showredFlower)
        {
            //// HairFlower Drawing
            var hairFlowerRect = makeRect(88, 70, 31, 30);
            context.save();
            context.beginPath();
            context.rect(hairFlowerRect.x, hairFlowerRect.y, hairFlowerRect.w, hairFlowerRect.h);
            context.clip();
            context.translate(hairFlowerRect.x, hairFlowerRect.y);

            AvatarStyleKit.drawRedFlower(canvas, makeRect(0, 0, hairFlowerRect.w, hairFlowerRect.h), 'stretch');
            context.restore();
        }


        if (_showLaurel)
        {
            //// HatLaurel Drawing
            var hatLaurelRect = makeRect(44, 66, 70, 43);
            context.save();
            context.beginPath();
            context.rect(hatLaurelRect.x, hatLaurelRect.y, hatLaurelRect.w, hatLaurelRect.h);
            context.clip();
            context.translate(hatLaurelRect.x, hatLaurelRect.y);

            AvatarStyleKit.drawLaurel(canvas, makeRect(0, 0, hatLaurelRect.w, hatLaurelRect.h), 'stretch');
            context.restore();
        }


        if (_showWizardHat)
        {
            //// HatWizard Drawing
            var hatWizardRect = makeRect(36, 28, 87, 68);
            context.save();
            context.beginPath();
            context.rect(hatWizardRect.x, hatWizardRect.y, hatWizardRect.w, hatWizardRect.h);
            context.clip();
            context.translate(hatWizardRect.x, hatWizardRect.y);

            AvatarStyleKit.drawWizardHat(canvas, makeRect(0, 0, hatWizardRect.w, hatWizardRect.h), 'stretch');
            context.restore();
        }


        if (_showGreenHairBow)
        {
            //// HairBowGreen Drawing
            var hairBowGreenRect = makeRect(51, 61, 56, 31);
            context.save();
            context.beginPath();
            context.rect(hairBowGreenRect.x, hairBowGreenRect.y, hairBowGreenRect.w, hairBowGreenRect.h);
            context.clip();
            context.translate(hairBowGreenRect.x, hairBowGreenRect.y);

            AvatarStyleKit.drawGreenHairBow(canvas, makeRect(0, 0, hairBowGreenRect.w, hairBowGreenRect.h), 'stretch');
            context.restore();
        }


        if (_showCowboyHat)
        {
            //// BrownCowboyHat Drawing
            var brownCowboyHatRect = makeRect(30, 39, 99, 59);
            context.save();
            context.beginPath();
            context.rect(brownCowboyHatRect.x, brownCowboyHatRect.y, brownCowboyHatRect.w, brownCowboyHatRect.h);
            context.clip();
            context.translate(brownCowboyHatRect.x, brownCowboyHatRect.y);

            AvatarStyleKit.drawCowboyHat(canvas, makeRect(0, 0, brownCowboyHatRect.w, brownCowboyHatRect.h), 'stretch');
            context.restore();
        }


        if (_showWitchHat)
        {
            //// HatWitch Drawing
            var hatWitchRect = makeRect(35.5, 30, 87, 68);
            context.save();
            context.beginPath();
            context.rect(hatWitchRect.x, hatWitchRect.y, hatWitchRect.w, hatWitchRect.h);
            context.clip();
            context.translate(hatWitchRect.x, hatWitchRect.y);

            AvatarStyleKit.drawWitchHat(canvas, makeRect(0, 0, hatWitchRect.w, hatWitchRect.h), 'stretch');
            context.restore();
        }


        if (_showRabbitEarHeadband)
        {
            //// RabbitEarHeadband Drawing
            var rabbitEarHeadbandRect = makeRect(36, 16, 87, 86);
            context.save();
            context.beginPath();
            context.rect(rabbitEarHeadbandRect.x, rabbitEarHeadbandRect.y, rabbitEarHeadbandRect.w, rabbitEarHeadbandRect.h);
            context.clip();
            context.translate(rabbitEarHeadbandRect.x, rabbitEarHeadbandRect.y);

            AvatarStyleKit.drawRabbitEars(canvas, makeRect(0, 0, rabbitEarHeadbandRect.w, rabbitEarHeadbandRect.h), 'stretch');
            context.restore();
        }


        if (_showSmallGreenHairBows)
        {
            //// SmallGreenHairBows Drawing
            var smallGreenHairBowsRect = makeRect(50.3, 71, 59, 21);
            context.save();
            context.beginPath();
            context.rect(smallGreenHairBowsRect.x, smallGreenHairBowsRect.y, smallGreenHairBowsRect.w, smallGreenHairBowsRect.h);
            context.clip();
            context.translate(smallGreenHairBowsRect.x, smallGreenHairBowsRect.y);

            AvatarStyleKit.drawSmallGreenBows(canvas, makeRect(0, 0, smallGreenHairBowsRect.w, smallGreenHairBowsRect.h), 'stretch');
            context.restore();
        }


        if (_showHairPearls)
        {
            //// PearlsHair Drawing
            var pearlsHairRect = makeRect(48, 67, 63, 31);
            context.save();
            context.beginPath();
            context.rect(pearlsHairRect.x, pearlsHairRect.y, pearlsHairRect.w, pearlsHairRect.h);
            context.clip();
            context.translate(pearlsHairRect.x, pearlsHairRect.y);

            AvatarStyleKit.drawHairPearls(canvas, makeRect(0, 0, pearlsHairRect.w, pearlsHairRect.h), 'stretch');
            context.restore();
        }


        if (_showHelmetViking)
        {
            //// HelmetViking Drawing
            var helmetVikingRect = makeRect(35, 31, 87, 68);
            context.save();
            context.beginPath();
            context.rect(helmetVikingRect.x, helmetVikingRect.y, helmetVikingRect.w, helmetVikingRect.h);
            context.clip();
            context.translate(helmetVikingRect.x, helmetVikingRect.y);

            AvatarStyleKit.drawVikingHelmet(canvas, makeRect(0, 0, helmetVikingRect.w, helmetVikingRect.h), 'stretch');
            context.restore();
        }


        if (_showHelmetEgypt)
        {
            //// HelmetEgypt Drawing
            var helmetEgyptRect = makeRect(36, 42, 87, 103);
            context.save();
            context.beginPath();
            context.rect(helmetEgyptRect.x, helmetEgyptRect.y, helmetEgyptRect.w, helmetEgyptRect.h);
            context.clip();
            context.translate(helmetEgyptRect.x, helmetEgyptRect.y);

            AvatarStyleKit.drawEgyptHelmet(canvas, makeRect(0, 0, helmetEgyptRect.w, helmetEgyptRect.h), 'stretch');
            context.restore();
        }


        if (_showHatBeretRed)
        {
            //// HatBeretRed Drawing
            var hatBeretRedRect = makeRect(39, 47, 67, 60);
            context.save();
            context.beginPath();
            context.rect(hatBeretRedRect.x, hatBeretRedRect.y, hatBeretRedRect.w, hatBeretRedRect.h);
            context.clip();
            context.translate(hatBeretRedRect.x, hatBeretRedRect.y);

            AvatarStyleKit.drawRedBeret(canvas, makeRect(0, 0, hatBeretRedRect.w, hatBeretRedRect.h), 'stretch');
            context.restore();
        }


        if (_showHatCapBackwards)
        {
            //// CapBackwards Drawing
            var capBackwardsRect = makeRect(44, 44, 70, 50);
            context.save();
            context.beginPath();
            context.rect(capBackwardsRect.x, capBackwardsRect.y, capBackwardsRect.w, capBackwardsRect.h);
            context.clip();
            context.translate(capBackwardsRect.x, capBackwardsRect.y);

            AvatarStyleKit.drawBackwardsCap(canvas, makeRect(0, 0, capBackwardsRect.w, capBackwardsRect.h), 'stretch');
            context.restore();
        }


        if (_showHatKippah)
        {
            //// HatKippah Drawing
            var hatKippahRect = makeRect(63.3, 68, 32, 13);
            context.save();
            context.beginPath();
            context.rect(hatKippahRect.x, hatKippahRect.y, hatKippahRect.w, hatKippahRect.h);
            context.clip();
            context.translate(hatKippahRect.x, hatKippahRect.y);

            AvatarStyleKit.drawKippah(canvas, makeRect(0, 0, hatKippahRect.w, hatKippahRect.h), 'stretch');
            context.restore();
        }


        if (_showHatSanta)
        {
            //// HatSanta Drawing
            var hatSantaRect = makeRect(42.3, 25, 73, 73);
            context.save();
            context.beginPath();
            context.rect(hatSantaRect.x, hatSantaRect.y, hatSantaRect.w, hatSantaRect.h);
            context.clip();
            context.translate(hatSantaRect.x, hatSantaRect.y);

            AvatarStyleKit.drawSantaHat(canvas, makeRect(0, 0, hatSantaRect.w, hatSantaRect.h), 'stretch');
            context.restore();
        }


        if (_showHatChef)
        {
            //// HatChef Drawing
            var hatChefRect = makeRect(30, 23, 101, 73);
            context.save();
            context.beginPath();
            context.rect(hatChefRect.x, hatChefRect.y, hatChefRect.w, hatChefRect.h);
            context.clip();
            context.translate(hatChefRect.x, hatChefRect.y);

            AvatarStyleKit.drawChefHat(canvas, makeRect(0, 0, hatChefRect.w, hatChefRect.h), 'stretch');
            context.restore();
        }


        if (_showHeadbandBee)
        {
            //// HeadbandBee Drawing
            var headbandBeeRect = makeRect(29, 34, 101, 73);
            context.save();
            context.beginPath();
            context.rect(headbandBeeRect.x, headbandBeeRect.y, headbandBeeRect.w, headbandBeeRect.h);
            context.clip();
            context.translate(headbandBeeRect.x, headbandBeeRect.y);

            AvatarStyleKit.drawBeeHeadband(canvas, makeRect(0, 0, headbandBeeRect.w, headbandBeeRect.h), 'stretch');
            context.restore();
        }


        if (_showWand)
        {
            //// Wand Drawing
            var wandRect = makeRect(93, 114, 31, 49);
            context.save();
            context.beginPath();
            context.rect(wandRect.x, wandRect.y, wandRect.w, wandRect.h);
            context.clip();
            context.translate(wandRect.x, wandRect.y);

            AvatarStyleKit.drawFaceWand(canvas, makeRect(0, 0, wandRect.w, wandRect.h), 'stretch');
            context.restore();
        }
        
        context.restore();

    }

    function drawJesterHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 140, 87), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 140, resizedFrame.h / 87);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// AccessoryJester
        //// Bezier 1990 Drawing
        context.beginPath();
        context.moveTo(100.64, 83.92);
        context.bezierCurveTo(69.44, 64.43, 36.04, 78.65, 32.43, 84);
        context.bezierCurveTo(32.43, 84, 38, 38.36, 8.67, 39.04);
        context.bezierCurveTo(8.67, 39.04, 31.33, 20.72, 51.67, 43.72);
        context.bezierCurveTo(51.67, 43.72, 54, 15.39, 86.33, 6.72);
        context.bezierCurveTo(86.33, 6.72, 75.46, 32.39, 90.23, 51.39);
        context.bezierCurveTo(90.23, 51.39, 115.96, 29.67, 133.65, 49.36);
        context.bezierCurveTo(133.65, 49.36, 96.18, 44.38, 100.64, 83.92);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();


        //// Oval 622 Drawing
        oval(context, 2, 34.04, 10.5, 10.5);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();


        //// Oval 624 Drawing
        oval(context, 80.05, 1.49, 10.5, 10.5);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();


        //// Oval 626 Drawing
        oval(context, 127.35, 45.34, 10.5, 10.5);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();
        
        context.restore();

    }

    function drawSeaBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 21
        context.save();

        //// Clip Clip 21

        context.beginPath();
        context.moveTo(41.67, 1.6);
        context.bezierCurveTo(39.68, 1.6, 37.84, 2.66, 36.84, 4.38);
        context.lineTo(36.84, 4.38);
        context.lineTo(2.27, 64.27);
        context.bezierCurveTo(1.27, 65.99, 1.27, 68.11, 2.27, 69.84);
        context.lineTo(2.27, 69.84);
        context.lineTo(36.84, 129.73);
        context.bezierCurveTo(37.84, 131.45, 39.68, 132.51, 41.67, 132.51);
        context.lineTo(41.67, 132.51);
        context.lineTo(110.82, 132.51);
        context.bezierCurveTo(112.81, 132.51, 114.65, 131.45, 115.64, 129.73);
        context.lineTo(115.64, 129.73);
        context.lineTo(150.22, 69.84);
        context.bezierCurveTo(151.21, 68.11, 151.21, 65.99, 150.22, 64.27);
        context.lineTo(150.22, 64.27);
        context.lineTo(115.64, 4.38);
        context.bezierCurveTo(114.65, 2.66, 112.81, 1.6, 110.82, 1.6);
        context.lineTo(110.82, 1.6);
        context.lineTo(41.67, 1.6);
        context.closePath();
        context.clip();



        //// Bezier 692 Drawing
        context.beginPath();
        context.moveTo(-5, 66.27);
        context.lineTo(6, 72.17);
        context.lineTo(55.63, 74.16);
        context.bezierCurveTo(55.63, 74.16, 68, 65.42, 69.75, 64.67);
        context.bezierCurveTo(71.5, 63.92, 107, 56.92, 107, 56.92);
        context.lineTo(157.75, 38.42);
        context.lineTo(139.75, 1.6);
        context.lineTo(88.5, -13.83);
        context.lineTo(41.67, 1.6);
        context.lineTo(20.25, 11.67);
        context.lineTo(5.25, 32.67);
        context.lineTo(-5, 66.27);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 693 Drawing
        context.beginPath();
        context.moveTo(-5, 66.27);
        context.lineTo(6, 72.17);
        context.lineTo(55.63, 74.16);
        context.bezierCurveTo(55.63, 74.16, 68, 65.42, 69.75, 64.67);
        context.bezierCurveTo(71.5, 63.92, 107, 56.92, 107, 56.92);
        context.lineTo(157.75, 38.42);
        context.lineTo(139.75, 1.6);
        context.lineTo(88.5, -13.83);
        context.lineTo(41.67, 1.6);
        context.lineTo(20.25, 11.67);
        context.lineTo(5.25, 32.67);
        context.lineTo(-5, 66.27);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 694 Drawing
        context.beginPath();
        context.moveTo(74.97, 29.6);
        context.bezierCurveTo(75.73, 30.48, 76.33, 32.36, 76.28, 35.46);
        context.bezierCurveTo(76.18, 40.9, 71.74, 38.18, 70.53, 41.3);
        context.bezierCurveTo(69.32, 44.42, 71.64, 44.42, 73.56, 45.93);
        context.bezierCurveTo(75.47, 47.44, 73.76, 49.46, 71.44, 50.6);
        context.bezierCurveTo(69.12, 51.75, 69.93, 53.57, 69.93, 53.57);
        context.lineTo(68.92, 54.09);
        context.lineTo(79.7, 54.8);
        context.bezierCurveTo(79.7, 54.8, 80, 53.79, 81, 52.28);
        context.bezierCurveTo(81.99, 50.77, 82.83, 49.96, 81, 48.25);
        context.bezierCurveTo(79.17, 46.54, 77.69, 43.11, 77.99, 40.59);
        context.bezierCurveTo(78.29, 38.08, 79.56, 37.78, 80.21, 35.05);
        context.bezierCurveTo(80.85, 32.32, 79.4, 32.13, 77.89, 27.97);
        context.bezierCurveTo(76.38, 23.82, 77.99, 23.87, 78.59, 20.34);
        context.bezierCurveTo(79.2, 16.82, 77.59, 19.13, 76.28, 21.25);
        context.bezierCurveTo(75.5, 22.51, 74.86, 24.9, 74.47, 26.61);
        context.bezierCurveTo(74.47, 26.61, 73.91, 28.38, 74.97, 29.6);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 695 Drawing
        context.beginPath();
        context.moveTo(74.97, 29.6);
        context.bezierCurveTo(75.73, 30.48, 76.33, 32.36, 76.28, 35.46);
        context.bezierCurveTo(76.18, 40.9, 71.74, 38.18, 70.53, 41.3);
        context.bezierCurveTo(69.32, 44.42, 71.64, 44.42, 73.56, 45.93);
        context.bezierCurveTo(75.47, 47.44, 73.76, 49.46, 71.44, 50.6);
        context.bezierCurveTo(69.12, 51.75, 69.93, 53.57, 69.93, 53.57);
        context.lineTo(68.92, 54.09);
        context.lineTo(79.7, 54.8);
        context.bezierCurveTo(79.7, 54.8, 80, 53.79, 81, 52.28);
        context.bezierCurveTo(81.99, 50.77, 82.83, 49.96, 81, 48.25);
        context.bezierCurveTo(79.17, 46.54, 77.69, 43.11, 77.99, 40.59);
        context.bezierCurveTo(78.29, 38.08, 79.56, 37.78, 80.21, 35.05);
        context.bezierCurveTo(80.85, 32.32, 79.4, 32.13, 77.89, 27.97);
        context.bezierCurveTo(76.38, 23.82, 77.99, 23.87, 78.59, 20.34);
        context.bezierCurveTo(79.2, 16.82, 77.59, 19.13, 76.28, 21.25);
        context.bezierCurveTo(75.5, 22.51, 74.86, 24.9, 74.47, 26.61);
        context.bezierCurveTo(74.47, 26.61, 73.91, 28.38, 74.97, 29.6);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 696 Drawing
        context.beginPath();
        context.moveTo(112.17, 20.82);
        context.bezierCurveTo(112.93, 21.69, 113.53, 23.58, 113.47, 26.67);
        context.bezierCurveTo(113.37, 32.11, 108.94, 29.39, 107.73, 32.51);
        context.bezierCurveTo(106.52, 35.64, 108.84, 35.64, 110.75, 37.15);
        context.bezierCurveTo(112.67, 38.66, 110.96, 40.67, 108.64, 41.82);
        context.bezierCurveTo(106.32, 42.96, 107.13, 44.78, 107.13, 44.78);
        context.lineTo(106.12, 45.31);
        context.lineTo(116.9, 46.01);
        context.bezierCurveTo(116.9, 46.01, 117.2, 45.01, 118.2, 43.49);
        context.bezierCurveTo(119.19, 41.98, 120.02, 41.18, 118.2, 39.46);
        context.bezierCurveTo(116.37, 37.75, 114.88, 34.33, 115.19, 31.81);
        context.bezierCurveTo(115.49, 29.29, 116.76, 29, 117.4, 26.27);
        context.bezierCurveTo(118.05, 23.54, 116.6, 23.34, 115.09, 19.19);
        context.bezierCurveTo(113.57, 15.03, 115.19, 15.08, 115.79, 11.56);
        context.bezierCurveTo(116.4, 8.03, 114.78, 10.35, 113.48, 12.46);
        context.bezierCurveTo(112.7, 13.73, 112.06, 16.11, 111.67, 17.82);
        context.bezierCurveTo(111.67, 17.82, 111.11, 19.59, 112.17, 20.82);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 697 Drawing
        context.beginPath();
        context.moveTo(112.17, 20.82);
        context.bezierCurveTo(112.93, 21.69, 113.53, 23.58, 113.47, 26.67);
        context.bezierCurveTo(113.37, 32.11, 108.94, 29.39, 107.73, 32.51);
        context.bezierCurveTo(106.52, 35.64, 108.84, 35.64, 110.75, 37.15);
        context.bezierCurveTo(112.67, 38.66, 110.96, 40.67, 108.64, 41.82);
        context.bezierCurveTo(106.32, 42.96, 107.13, 44.78, 107.13, 44.78);
        context.lineTo(106.12, 45.31);
        context.lineTo(116.9, 46.01);
        context.bezierCurveTo(116.9, 46.01, 117.2, 45.01, 118.2, 43.49);
        context.bezierCurveTo(119.19, 41.98, 120.02, 41.18, 118.2, 39.46);
        context.bezierCurveTo(116.37, 37.75, 114.88, 34.33, 115.19, 31.81);
        context.bezierCurveTo(115.49, 29.29, 116.76, 29, 117.4, 26.27);
        context.bezierCurveTo(118.05, 23.54, 116.6, 23.34, 115.09, 19.19);
        context.bezierCurveTo(113.57, 15.03, 115.19, 15.08, 115.79, 11.56);
        context.bezierCurveTo(116.4, 8.03, 114.78, 10.35, 113.48, 12.46);
        context.bezierCurveTo(112.7, 13.73, 112.06, 16.11, 111.67, 17.82);
        context.bezierCurveTo(111.67, 17.82, 111.11, 19.59, 112.17, 20.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 698 Drawing
        context.beginPath();
        context.moveTo(27.08, 39.85);
        context.bezierCurveTo(28.25, 41.19, 29.17, 44.08, 29.09, 48.84);
        context.bezierCurveTo(28.93, 57.2, 22.12, 53.02, 20.26, 57.82);
        context.bezierCurveTo(18.4, 62.62, 21.96, 62.62, 24.91, 64.94);
        context.bezierCurveTo(27.85, 67.26, 25.22, 70.36, 21.65, 72.12);
        context.bezierCurveTo(18.09, 73.88, 19.34, 76.67, 19.34, 76.67);
        context.lineTo(17.78, 77.48);
        context.lineTo(34.35, 78.57);
        context.bezierCurveTo(34.35, 78.57, 34.81, 77.02, 36.34, 74.7);
        context.bezierCurveTo(37.87, 72.37, 39.15, 71.13, 36.34, 68.5);
        context.bezierCurveTo(33.53, 65.87, 31.25, 60.61, 31.72, 56.74);
        context.bezierCurveTo(32.18, 52.86, 34.13, 52.41, 35.13, 48.22);
        context.bezierCurveTo(36.12, 44.02, 33.89, 43.73, 31.56, 37.34);
        context.bezierCurveTo(29.24, 30.95, 31.72, 31.03, 32.65, 25.61);
        context.bezierCurveTo(33.58, 20.19, 31.1, 23.76, 29.09, 27.01);
        context.bezierCurveTo(27.89, 28.95, 26.91, 32.61, 26.31, 35.24);
        context.bezierCurveTo(26.31, 35.24, 25.45, 37.96, 27.08, 39.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 699 Drawing
        context.beginPath();
        context.moveTo(27.08, 39.85);
        context.bezierCurveTo(28.25, 41.19, 29.17, 44.08, 29.09, 48.84);
        context.bezierCurveTo(28.93, 57.2, 22.12, 53.02, 20.26, 57.82);
        context.bezierCurveTo(18.4, 62.62, 21.96, 62.62, 24.91, 64.94);
        context.bezierCurveTo(27.85, 67.26, 25.22, 70.36, 21.65, 72.12);
        context.bezierCurveTo(18.09, 73.88, 19.34, 76.67, 19.34, 76.67);
        context.lineTo(17.78, 77.48);
        context.lineTo(34.35, 78.57);
        context.bezierCurveTo(34.35, 78.57, 34.81, 77.02, 36.34, 74.7);
        context.bezierCurveTo(37.87, 72.37, 39.15, 71.13, 36.34, 68.5);
        context.bezierCurveTo(33.53, 65.87, 31.25, 60.61, 31.72, 56.74);
        context.bezierCurveTo(32.18, 52.86, 34.13, 52.41, 35.13, 48.22);
        context.bezierCurveTo(36.12, 44.02, 33.89, 43.73, 31.56, 37.34);
        context.bezierCurveTo(29.24, 30.95, 31.72, 31.03, 32.65, 25.61);
        context.bezierCurveTo(33.58, 20.19, 31.1, 23.76, 29.09, 27.01);
        context.bezierCurveTo(27.89, 28.95, 26.91, 32.61, 26.31, 35.24);
        context.bezierCurveTo(26.31, 35.24, 25.45, 37.96, 27.08, 39.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 700 Drawing
        context.beginPath();
        context.moveTo(93.57, 20.82);
        context.bezierCurveTo(94.33, 21.69, 94.93, 23.58, 94.88, 26.67);
        context.bezierCurveTo(94.77, 32.11, 90.34, 29.39, 89.13, 32.51);
        context.bezierCurveTo(87.92, 35.64, 90.24, 35.64, 92.16, 37.15);
        context.bezierCurveTo(94.07, 38.66, 92.36, 40.67, 90.04, 41.82);
        context.bezierCurveTo(87.72, 42.96, 88.53, 44.78, 88.53, 44.78);
        context.lineTo(87.52, 45.31);
        context.lineTo(98.3, 46.01);
        context.bezierCurveTo(98.3, 46.01, 98.6, 45.01, 99.6, 43.49);
        context.bezierCurveTo(100.59, 41.98, 101.42, 41.18, 99.6, 39.46);
        context.bezierCurveTo(97.77, 37.75, 96.29, 34.33, 96.59, 31.81);
        context.bezierCurveTo(96.89, 29.29, 98.16, 29, 98.8, 26.27);
        context.bezierCurveTo(99.45, 23.54, 98, 23.34, 96.49, 19.19);
        context.bezierCurveTo(94.98, 15.03, 96.59, 15.08, 97.19, 11.56);
        context.bezierCurveTo(97.8, 8.03, 96.18, 10.35, 94.88, 12.46);
        context.bezierCurveTo(94.1, 13.73, 93.46, 16.11, 93.07, 17.82);
        context.bezierCurveTo(93.07, 17.82, 92.51, 19.59, 93.57, 20.82);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 701 Drawing
        context.beginPath();
        context.moveTo(93.57, 20.82);
        context.bezierCurveTo(94.33, 21.69, 94.93, 23.58, 94.88, 26.67);
        context.bezierCurveTo(94.77, 32.11, 90.34, 29.39, 89.13, 32.51);
        context.bezierCurveTo(87.92, 35.64, 90.24, 35.64, 92.16, 37.15);
        context.bezierCurveTo(94.07, 38.66, 92.36, 40.67, 90.04, 41.82);
        context.bezierCurveTo(87.72, 42.96, 88.53, 44.78, 88.53, 44.78);
        context.lineTo(87.52, 45.31);
        context.lineTo(98.3, 46.01);
        context.bezierCurveTo(98.3, 46.01, 98.6, 45.01, 99.6, 43.49);
        context.bezierCurveTo(100.59, 41.98, 101.42, 41.18, 99.6, 39.46);
        context.bezierCurveTo(97.77, 37.75, 96.29, 34.33, 96.59, 31.81);
        context.bezierCurveTo(96.89, 29.29, 98.16, 29, 98.8, 26.27);
        context.bezierCurveTo(99.45, 23.54, 98, 23.34, 96.49, 19.19);
        context.bezierCurveTo(94.98, 15.03, 96.59, 15.08, 97.19, 11.56);
        context.bezierCurveTo(97.8, 8.03, 96.18, 10.35, 94.88, 12.46);
        context.bezierCurveTo(94.1, 13.73, 93.46, 16.11, 93.07, 17.82);
        context.bezierCurveTo(93.07, 17.82, 92.51, 19.59, 93.57, 20.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 702 Drawing
        context.beginPath();
        context.moveTo(40, 74.17);
        context.bezierCurveTo(40, 74.17, 42.25, 55.17, 50, 54.42);
        context.bezierCurveTo(57.75, 53.67, 61.5, 55.67, 71.75, 51.92);
        context.bezierCurveTo(82, 48.17, 82.5, 44.92, 88, 42.42);
        context.bezierCurveTo(93.5, 39.92, 104.75, 40.42, 104.75, 40.42);
        context.bezierCurveTo(104.75, 40.42, 115, 44.17, 118, 38.42);
        context.bezierCurveTo(121, 32.67, 117.5, 30.92, 124, 28.67);
        context.bezierCurveTo(130.5, 26.42, 141.75, 28.67, 141.75, 28.67);
        context.lineTo(168.5, 69.67);
        context.lineTo(150.97, 86.53);
        context.bezierCurveTo(150.97, 86.53, 124.75, 91.67, 121, 92.17);
        context.bezierCurveTo(117.25, 92.67, 66.5, 86.37, 66.5, 86.37);
        context.lineTo(40, 74.17);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 703 Drawing
        context.beginPath();
        context.moveTo(40, 74.17);
        context.bezierCurveTo(40, 74.17, 42.25, 55.17, 50, 54.42);
        context.bezierCurveTo(57.75, 53.67, 61.5, 55.67, 71.75, 51.92);
        context.bezierCurveTo(82, 48.17, 82.5, 44.92, 88, 42.42);
        context.bezierCurveTo(93.5, 39.92, 104.75, 40.42, 104.75, 40.42);
        context.bezierCurveTo(104.75, 40.42, 115, 44.17, 118, 38.42);
        context.bezierCurveTo(121, 32.67, 117.5, 30.92, 124, 28.67);
        context.bezierCurveTo(130.5, 26.42, 141.75, 28.67, 141.75, 28.67);
        context.lineTo(168.5, 69.67);
        context.lineTo(150.97, 86.53);
        context.bezierCurveTo(150.97, 86.53, 124.75, 91.67, 121, 92.17);
        context.bezierCurveTo(117.25, 92.67, 66.5, 86.37, 66.5, 86.37);
        context.lineTo(40, 74.17);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 704 Drawing
        context.beginPath();
        context.moveTo(25.8, 114.54);
        context.lineTo(-3, 68.42);
        context.bezierCurveTo(-3, 68.42, 7.75, 64.68, 20.25, 67.05);
        context.bezierCurveTo(32.75, 69.42, 36.25, 71.17, 65, 75.67);
        context.bezierCurveTo(93.75, 80.17, 123.5, 83.67, 131.25, 80.92);
        context.bezierCurveTo(139, 78.17, 161.25, 82.92, 161.25, 82.92);
        context.lineTo(145.48, 99.42);
        context.lineTo(25.8, 114.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Bezier 705 Drawing
        context.beginPath();
        context.moveTo(25.8, 114.54);
        context.lineTo(-3, 68.42);
        context.bezierCurveTo(-3, 68.42, 7.75, 64.68, 20.25, 67.05);
        context.bezierCurveTo(32.75, 69.42, 36.25, 71.17, 65, 75.67);
        context.bezierCurveTo(93.75, 80.17, 123.5, 83.67, 131.25, 80.92);
        context.bezierCurveTo(139, 78.17, 161.25, 82.92, 161.25, 82.92);
        context.lineTo(145.48, 99.42);
        context.lineTo(25.8, 114.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 706 Drawing
        context.beginPath();
        context.moveTo(110.65, 60.83);
        context.bezierCurveTo(111.81, 62.18, 112.74, 65.07, 112.65, 69.82);
        context.bezierCurveTo(112.5, 78.18, 105.68, 74, 103.83, 78.8);
        context.bezierCurveTo(101.97, 83.6, 105.53, 83.6, 108.47, 85.92);
        context.bezierCurveTo(111.41, 88.25, 108.78, 91.34, 105.22, 93.1);
        context.bezierCurveTo(101.66, 94.86, 102.9, 97.66, 102.9, 97.66);
        context.lineTo(101.35, 98.47);
        context.lineTo(117.92, 99.55);
        context.bezierCurveTo(117.92, 99.55, 118.38, 98, 119.91, 95.68);
        context.bezierCurveTo(121.44, 93.36, 122.72, 92.12, 119.91, 89.49);
        context.bezierCurveTo(117.1, 86.85, 114.82, 81.59, 115.28, 77.72);
        context.bezierCurveTo(115.75, 73.85, 117.7, 73.4, 118.69, 69.2);
        context.bezierCurveTo(119.68, 65.01, 117.45, 64.71, 115.13, 58.32);
        context.bezierCurveTo(112.81, 51.93, 115.28, 52.02, 116.21, 46.6);
        context.bezierCurveTo(117.14, 41.18, 114.67, 44.74, 112.66, 47.99);
        context.bezierCurveTo(111.46, 49.93, 110.47, 53.6, 109.87, 56.23);
        context.bezierCurveTo(109.87, 56.23, 109.02, 58.94, 110.65, 60.83);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 707 Drawing
        context.beginPath();
        context.moveTo(110.65, 60.83);
        context.bezierCurveTo(111.81, 62.18, 112.74, 65.07, 112.65, 69.82);
        context.bezierCurveTo(112.5, 78.18, 105.68, 74, 103.83, 78.8);
        context.bezierCurveTo(101.97, 83.6, 105.53, 83.6, 108.47, 85.92);
        context.bezierCurveTo(111.41, 88.25, 108.78, 91.34, 105.22, 93.1);
        context.bezierCurveTo(101.66, 94.86, 102.9, 97.66, 102.9, 97.66);
        context.lineTo(101.35, 98.47);
        context.lineTo(117.92, 99.55);
        context.bezierCurveTo(117.92, 99.55, 118.38, 98, 119.91, 95.68);
        context.bezierCurveTo(121.44, 93.36, 122.72, 92.12, 119.91, 89.49);
        context.bezierCurveTo(117.1, 86.85, 114.82, 81.59, 115.28, 77.72);
        context.bezierCurveTo(115.75, 73.85, 117.7, 73.4, 118.69, 69.2);
        context.bezierCurveTo(119.68, 65.01, 117.45, 64.71, 115.13, 58.32);
        context.bezierCurveTo(112.81, 51.93, 115.28, 52.02, 116.21, 46.6);
        context.bezierCurveTo(117.14, 41.18, 114.67, 44.74, 112.66, 47.99);
        context.bezierCurveTo(111.46, 49.93, 110.47, 53.6, 109.87, 56.23);
        context.bezierCurveTo(109.87, 56.23, 109.02, 58.94, 110.65, 60.83);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 708 Drawing
        context.beginPath();
        context.moveTo(92.63, 81.3);
        context.bezierCurveTo(93.1, 81.85, 93.48, 83.02, 93.44, 84.94);
        context.bezierCurveTo(93.38, 88.31, 90.63, 86.62, 89.88, 88.56);
        context.bezierCurveTo(89.13, 90.5, 90.57, 90.5, 91.75, 91.44);
        context.bezierCurveTo(92.94, 92.38, 91.88, 93.63, 90.44, 94.34);
        context.bezierCurveTo(89, 95.05, 89.5, 96.18, 89.5, 96.18);
        context.lineTo(88.88, 96.51);
        context.lineTo(95.57, 96.94);
        context.bezierCurveTo(95.57, 96.94, 95.76, 96.32, 96.37, 95.38);
        context.bezierCurveTo(96.99, 94.44, 97.51, 93.94, 96.37, 92.88);
        context.bezierCurveTo(95.24, 91.82, 94.32, 89.69, 94.51, 88.13);
        context.bezierCurveTo(94.69, 86.56, 95.48, 86.38, 95.88, 84.69);
        context.bezierCurveTo(96.28, 82.99, 95.38, 82.87, 94.44, 80.29);
        context.bezierCurveTo(93.51, 77.71, 94.51, 77.74, 94.88, 75.55);
        context.bezierCurveTo(95.26, 73.37, 94.26, 74.8, 93.45, 76.12);
        context.bezierCurveTo(92.96, 76.9, 92.56, 78.38, 92.32, 79.44);
        context.bezierCurveTo(92.32, 79.44, 91.97, 80.54, 92.63, 81.3);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 709 Drawing
        context.beginPath();
        context.moveTo(92.63, 81.3);
        context.bezierCurveTo(93.1, 81.85, 93.48, 83.02, 93.44, 84.94);
        context.bezierCurveTo(93.38, 88.31, 90.63, 86.62, 89.88, 88.56);
        context.bezierCurveTo(89.13, 90.5, 90.57, 90.5, 91.75, 91.44);
        context.bezierCurveTo(92.94, 92.38, 91.88, 93.63, 90.44, 94.34);
        context.bezierCurveTo(89, 95.05, 89.5, 96.18, 89.5, 96.18);
        context.lineTo(88.88, 96.51);
        context.lineTo(95.57, 96.94);
        context.bezierCurveTo(95.57, 96.94, 95.76, 96.32, 96.37, 95.38);
        context.bezierCurveTo(96.99, 94.44, 97.51, 93.94, 96.37, 92.88);
        context.bezierCurveTo(95.24, 91.82, 94.32, 89.69, 94.51, 88.13);
        context.bezierCurveTo(94.69, 86.56, 95.48, 86.38, 95.88, 84.69);
        context.bezierCurveTo(96.28, 82.99, 95.38, 82.87, 94.44, 80.29);
        context.bezierCurveTo(93.51, 77.71, 94.51, 77.74, 94.88, 75.55);
        context.bezierCurveTo(95.26, 73.37, 94.26, 74.8, 93.45, 76.12);
        context.bezierCurveTo(92.96, 76.9, 92.56, 78.38, 92.32, 79.44);
        context.bezierCurveTo(92.32, 79.44, 91.97, 80.54, 92.63, 81.3);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 710 Drawing
        context.beginPath();
        context.moveTo(132.7, 81.3);
        context.bezierCurveTo(133.17, 81.85, 133.54, 83.02, 133.51, 84.94);
        context.bezierCurveTo(133.44, 88.31, 130.69, 86.62, 129.94, 88.56);
        context.bezierCurveTo(129.19, 90.5, 130.63, 90.5, 131.82, 91.44);
        context.bezierCurveTo(133.01, 92.38, 131.94, 93.63, 130.5, 94.34);
        context.bezierCurveTo(129.07, 95.05, 129.57, 96.18, 129.57, 96.18);
        context.lineTo(128.94, 96.51);
        context.lineTo(135.63, 96.94);
        context.bezierCurveTo(135.63, 96.94, 135.82, 96.32, 136.44, 95.38);
        context.bezierCurveTo(137.05, 94.44, 137.57, 93.94, 136.44, 92.88);
        context.bezierCurveTo(135.3, 91.82, 134.38, 89.69, 134.57, 88.13);
        context.bezierCurveTo(134.76, 86.56, 135.54, 86.38, 135.95, 84.69);
        context.bezierCurveTo(136.35, 82.99, 135.45, 82.87, 134.51, 80.29);
        context.bezierCurveTo(133.57, 77.71, 134.57, 77.74, 134.94, 75.55);
        context.bezierCurveTo(135.32, 73.37, 134.32, 74.8, 133.51, 76.12);
        context.bezierCurveTo(133.02, 76.9, 132.63, 78.38, 132.38, 79.44);
        context.bezierCurveTo(132.38, 79.44, 132.04, 80.54, 132.7, 81.3);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 711 Drawing
        context.beginPath();
        context.moveTo(132.7, 81.3);
        context.bezierCurveTo(133.17, 81.85, 133.54, 83.02, 133.51, 84.94);
        context.bezierCurveTo(133.44, 88.31, 130.69, 86.62, 129.94, 88.56);
        context.bezierCurveTo(129.19, 90.5, 130.63, 90.5, 131.82, 91.44);
        context.bezierCurveTo(133.01, 92.38, 131.94, 93.63, 130.5, 94.34);
        context.bezierCurveTo(129.07, 95.05, 129.57, 96.18, 129.57, 96.18);
        context.lineTo(128.94, 96.51);
        context.lineTo(135.63, 96.94);
        context.bezierCurveTo(135.63, 96.94, 135.82, 96.32, 136.44, 95.38);
        context.bezierCurveTo(137.05, 94.44, 137.57, 93.94, 136.44, 92.88);
        context.bezierCurveTo(135.3, 91.82, 134.38, 89.69, 134.57, 88.13);
        context.bezierCurveTo(134.76, 86.56, 135.54, 86.38, 135.95, 84.69);
        context.bezierCurveTo(136.35, 82.99, 135.45, 82.87, 134.51, 80.29);
        context.bezierCurveTo(133.57, 77.71, 134.57, 77.74, 134.94, 75.55);
        context.bezierCurveTo(135.32, 73.37, 134.32, 74.8, 133.51, 76.12);
        context.bezierCurveTo(133.02, 76.9, 132.63, 78.38, 132.38, 79.44);
        context.bezierCurveTo(132.38, 79.44, 132.04, 80.54, 132.7, 81.3);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 712 Drawing
        context.beginPath();
        context.moveTo(75.92, 69.66);
        context.bezierCurveTo(77.08, 71.01, 78.01, 73.9, 77.92, 78.65);
        context.bezierCurveTo(77.76, 87.02, 70.95, 82.84, 69.09, 87.63);
        context.bezierCurveTo(67.24, 92.43, 70.8, 92.43, 73.74, 94.76);
        context.bezierCurveTo(76.68, 97.08, 74.05, 100.18, 70.49, 101.93);
        context.bezierCurveTo(66.93, 103.69, 68.17, 106.49, 68.17, 106.49);
        context.lineTo(66.62, 107.3);
        context.lineTo(83.18, 108.38);
        context.bezierCurveTo(83.18, 108.38, 83.65, 106.83, 85.18, 104.51);
        context.bezierCurveTo(86.7, 102.19, 87.98, 100.95, 85.18, 98.32);
        context.bezierCurveTo(82.37, 95.69, 80.09, 90.42, 80.55, 86.55);
        context.bezierCurveTo(81.02, 82.68, 82.96, 82.23, 83.96, 78.04);
        context.bezierCurveTo(84.95, 73.84, 82.72, 73.55, 80.4, 67.16);
        context.bezierCurveTo(78.07, 60.77, 80.55, 60.85, 81.48, 55.43);
        context.bezierCurveTo(82.41, 50.01, 79.93, 53.57, 77.92, 56.82);
        context.bezierCurveTo(76.72, 58.77, 75.74, 62.43, 75.14, 65.06);
        context.bezierCurveTo(75.14, 65.06, 74.28, 67.77, 75.92, 69.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 713 Drawing
        context.beginPath();
        context.moveTo(75.92, 69.66);
        context.bezierCurveTo(77.08, 71.01, 78.01, 73.9, 77.92, 78.65);
        context.bezierCurveTo(77.76, 87.02, 70.95, 82.84, 69.09, 87.63);
        context.bezierCurveTo(67.24, 92.43, 70.8, 92.43, 73.74, 94.76);
        context.bezierCurveTo(76.68, 97.08, 74.05, 100.18, 70.49, 101.93);
        context.bezierCurveTo(66.93, 103.69, 68.17, 106.49, 68.17, 106.49);
        context.lineTo(66.62, 107.3);
        context.lineTo(83.18, 108.38);
        context.bezierCurveTo(83.18, 108.38, 83.65, 106.83, 85.18, 104.51);
        context.bezierCurveTo(86.7, 102.19, 87.98, 100.95, 85.18, 98.32);
        context.bezierCurveTo(82.37, 95.69, 80.09, 90.42, 80.55, 86.55);
        context.bezierCurveTo(81.02, 82.68, 82.96, 82.23, 83.96, 78.04);
        context.bezierCurveTo(84.95, 73.84, 82.72, 73.55, 80.4, 67.16);
        context.bezierCurveTo(78.07, 60.77, 80.55, 60.85, 81.48, 55.43);
        context.bezierCurveTo(82.41, 50.01, 79.93, 53.57, 77.92, 56.82);
        context.bezierCurveTo(76.72, 58.77, 75.74, 62.43, 75.14, 65.06);
        context.bezierCurveTo(75.14, 65.06, 74.28, 67.77, 75.92, 69.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 714 Drawing
        context.beginPath();
        context.moveTo(20.25, 112.28);
        context.bezierCurveTo(20.25, 112.28, 46.5, 113.28, 51, 109.78);
        context.bezierCurveTo(55.5, 106.28, 61.5, 103.28, 54, 102.78);
        context.bezierCurveTo(46.5, 102.28, 50.62, 95.78, 57.56, 96.03);
        context.bezierCurveTo(64.5, 96.28, 100, 95.28, 110, 93.28);
        context.bezierCurveTo(120, 91.28, 124, 92.53, 132.25, 93.28);
        context.bezierCurveTo(140.5, 94.03, 150.97, 100.03, 150.97, 100.03);
        context.lineTo(144.5, 135.03);
        context.lineTo(97.75, 145.03);
        context.lineTo(37.73, 148);
        context.lineTo(12.73, 111.75);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 715 Drawing
        context.beginPath();
        context.moveTo(71.58, 133.59);
        context.bezierCurveTo(71.58, 133.59, 65.08, 125.43, 70.58, 120.76);
        context.bezierCurveTo(76.08, 116.09, 76.92, 117.26, 83.92, 116.59);
        context.bezierCurveTo(90.92, 115.92, 94.08, 113.92, 97.92, 111.92);
        context.bezierCurveTo(101.75, 109.92, 100.08, 105.76, 110.42, 110.09);
        context.bezierCurveTo(120.75, 114.42, 124.58, 117.69, 125.08, 120.89);
        context.bezierCurveTo(125.58, 124.09, 120.75, 135.26, 120.75, 135.26);
        context.lineTo(105.25, 138.26);
        context.lineTo(71.58, 133.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 716 Drawing
        context.beginPath();
        context.moveTo(71.58, 133.59);
        context.bezierCurveTo(71.58, 133.59, 65.08, 125.43, 70.58, 120.76);
        context.bezierCurveTo(76.08, 116.09, 76.92, 117.26, 83.92, 116.59);
        context.bezierCurveTo(90.92, 115.92, 94.08, 113.92, 97.92, 111.92);
        context.bezierCurveTo(101.75, 109.92, 100.08, 105.76, 110.42, 110.09);
        context.bezierCurveTo(120.75, 114.42, 124.58, 117.69, 125.08, 120.89);
        context.bezierCurveTo(125.58, 124.09, 120.75, 135.26, 120.75, 135.26);
        context.lineTo(105.25, 138.26);
        context.lineTo(71.58, 133.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 718 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.6);
        context.lineTo(41.67, 1.6);
        context.bezierCurveTo(39.68, 1.6, 37.84, 2.66, 36.84, 4.38);
        context.lineTo(2.27, 64.27);
        context.bezierCurveTo(1.27, 65.99, 1.27, 68.12, 2.27, 69.84);
        context.lineTo(36.84, 129.73);
        context.bezierCurveTo(37.84, 131.45, 39.68, 132.51, 41.67, 132.51);
        context.lineTo(110.82, 132.51);
        context.bezierCurveTo(112.81, 132.51, 114.65, 131.45, 115.64, 129.73);
        context.lineTo(150.22, 69.84);
        context.bezierCurveTo(151.22, 68.12, 151.22, 65.99, 150.22, 64.27);
        context.lineTo(115.64, 4.38);
        context.bezierCurveTo(114.65, 2.66, 112.81, 1.6, 110.82, 1.6);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPlainBackground(canvas, backgroundColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _backgroundColor = backgroundColor === 'col1' ? AvatarStyleKit.col1 : (backgroundColor === 'col2' ? AvatarStyleKit.col2 : (backgroundColor === 'col3' ? AvatarStyleKit.col3 : (backgroundColor === 'col4' ? AvatarStyleKit.col4 : (backgroundColor === 'col5' ? AvatarStyleKit.col5 : (backgroundColor === 'col6' ? AvatarStyleKit.col6 : (backgroundColor === 'col7' ? AvatarStyleKit.col7 : (backgroundColor === 'col8' ? AvatarStyleKit.col8 : (backgroundColor === 'col9' ? AvatarStyleKit.col9 : (backgroundColor === 'col10' ? AvatarStyleKit.col10 : (backgroundColor === 'col11' ? AvatarStyleKit.col11 : (backgroundColor === 'col12' ? AvatarStyleKit.col12 : (backgroundColor === 'col13' ? AvatarStyleKit.col13 : (backgroundColor === 'col14' ? AvatarStyleKit.col14 : (backgroundColor === 'col15' ? AvatarStyleKit.col15 : (backgroundColor === 'col16' ? AvatarStyleKit.col16 : (backgroundColor === 'col17' ? AvatarStyleKit.col17 : (backgroundColor === 'col18' ? AvatarStyleKit.col18 : (backgroundColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Background Drawing
        context.beginPath();
        context.moveTo(111.3, 1.08);
        context.lineTo(42.15, 1.08);
        context.bezierCurveTo(40.16, 1.08, 38.32, 2.15, 37.32, 3.87);
        context.lineTo(2.75, 63.76);
        context.bezierCurveTo(1.75, 65.48, 1.75, 67.6, 2.75, 69.33);
        context.lineTo(37.32, 129.22);
        context.bezierCurveTo(38.32, 130.94, 40.16, 132, 42.15, 132);
        context.lineTo(111.3, 132);
        context.bezierCurveTo(113.29, 132, 115.13, 130.94, 116.12, 129.22);
        context.lineTo(150.7, 69.33);
        context.bezierCurveTo(151.69, 67.6, 151.69, 65.48, 150.7, 63.76);
        context.lineTo(116.12, 3.87);
        context.bezierCurveTo(115.13, 2.15, 113.29, 1.08, 111.3, 1.08);
        context.closePath();
        context.fillStyle = _backgroundColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawDesertBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 23
        context.save();

        //// Clip Clip 23

        context.beginPath();
        context.moveTo(41.39, 1.18);
        context.bezierCurveTo(39.4, 1.18, 37.56, 2.25, 36.56, 3.97);
        context.lineTo(36.56, 3.97);
        context.lineTo(1.99, 63.86);
        context.bezierCurveTo(0.99, 65.58, 0.99, 67.7, 1.99, 69.43);
        context.lineTo(1.99, 69.43);
        context.lineTo(36.56, 129.31);
        context.bezierCurveTo(37.56, 131.04, 39.4, 132.1, 41.39, 132.1);
        context.lineTo(41.39, 132.1);
        context.lineTo(110.54, 132.1);
        context.bezierCurveTo(112.53, 132.1, 114.37, 131.04, 115.36, 129.31);
        context.lineTo(115.36, 129.31);
        context.lineTo(149.94, 69.43);
        context.bezierCurveTo(150.93, 67.7, 150.93, 65.58, 149.94, 63.86);
        context.lineTo(149.94, 63.86);
        context.lineTo(115.36, 3.97);
        context.bezierCurveTo(114.37, 2.25, 112.53, 1.18, 110.54, 1.18);
        context.lineTo(110.54, 1.18);
        context.lineTo(41.39, 1.18);
        context.closePath();
        context.clip();



        //// Bezier 789 Drawing
        context.beginPath();
        context.moveTo(21.25, -3.38);
        context.bezierCurveTo(21.25, -3.38, -73.75, 28.12, -34.5, 63.87);
        context.bezierCurveTo(4.75, 99.62, -23.75, 88.87, 4.75, 99.62);
        context.lineTo(33.25, 110.37);
        context.lineTo(75.05, 116.14);
        context.lineTo(137.75, 103.87);
        context.lineTo(162.25, 74.12);
        context.lineTo(145.75, 36.12);
        context.lineTo(129.46, -23.88);
        context.lineTo(32.25, -8.79);
        context.lineTo(21.25, -3.38);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 790 Drawing
        context.beginPath();
        context.moveTo(21.25, -3.38);
        context.bezierCurveTo(21.25, -3.38, -73.75, 28.12, -34.5, 63.87);
        context.bezierCurveTo(4.75, 99.62, -23.75, 88.87, 4.75, 99.62);
        context.lineTo(33.25, 110.37);
        context.lineTo(75.05, 116.14);
        context.lineTo(137.75, 103.87);
        context.lineTo(162.25, 74.12);
        context.lineTo(145.75, 36.12);
        context.lineTo(129.46, -23.88);
        context.lineTo(32.25, -8.79);
        context.lineTo(21.25, -3.38);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 791 Drawing
        context.beginPath();
        context.moveTo(30.65, 92.85);
        context.bezierCurveTo(30.65, 92.85, 27.84, 91.87, 25.34, 89.04);
        context.bezierCurveTo(22.84, 86.21, 17.84, 82.21, 22, 81.87);
        context.bezierCurveTo(26.17, 81.54, 25.34, 82.87, 26.67, 85.54);
        context.bezierCurveTo(28, 88.21, 29.69, 89.54, 32.5, 89.54);
        context.bezierCurveTo(35.32, 89.54, 35, 90.87, 35, 90.87);
        context.lineTo(30.65, 92.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 792 Drawing
        context.beginPath();
        context.moveTo(30.65, 92.85);
        context.bezierCurveTo(30.65, 92.85, 27.84, 91.87, 25.34, 89.04);
        context.bezierCurveTo(22.84, 86.21, 17.84, 82.21, 22, 81.87);
        context.bezierCurveTo(26.17, 81.54, 25.34, 82.87, 26.67, 85.54);
        context.bezierCurveTo(28, 88.21, 29.69, 89.54, 32.5, 89.54);
        context.bezierCurveTo(35.32, 89.54, 35, 90.87, 35, 90.87);
        context.lineTo(30.65, 92.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 793 Drawing
        context.beginPath();
        context.moveTo(50.63, 89);
        context.bezierCurveTo(50.63, 89, 79.67, 61.54, 111.51, 93.04);
        context.bezierCurveTo(143.34, 124.54, 107.51, 108.54, 107.51, 108.54);
        context.bezierCurveTo(107.51, 108.54, 80.17, 107.37, 79.67, 107.37);
        context.bezierCurveTo(79.17, 107.37, 50.63, 89, 50.63, 89);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        //// Bezier 794 Drawing
        context.beginPath();
        context.moveTo(50.63, 89);
        context.bezierCurveTo(50.63, 89, 79.67, 61.54, 111.51, 93.04);
        context.bezierCurveTo(143.34, 124.54, 107.51, 108.54, 107.51, 108.54);
        context.bezierCurveTo(107.51, 108.54, 80.17, 107.37, 79.67, 107.37);
        context.bezierCurveTo(79.17, 107.37, 50.63, 89, 50.63, 89);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 795 Drawing
        context.beginPath();
        context.moveTo(35.02, 82.54);
        context.bezierCurveTo(37.86, 83.04, 37, 82.71, 40.5, 80.87);
        context.bezierCurveTo(44, 79.04, 42.5, 77.04, 43, 72.57);
        context.bezierCurveTo(43.5, 68.1, 42.84, 66.71, 40, 66.64);
        context.bezierCurveTo(37.17, 66.57, 39.34, 71.21, 39.17, 74.04);
        context.bezierCurveTo(39, 76.87, 35.02, 78.5, 35.02, 78.5);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 796 Drawing
        context.beginPath();
        context.moveTo(32.67, 92.85);
        context.bezierCurveTo(31.89, 90.58, 20.67, 66.79, 24.34, 60.17);
        context.bezierCurveTo(28, 53.54, 32.5, 62.71, 32.67, 64.37);
        context.bezierCurveTo(32.84, 66.04, 37.69, 92.85, 37.69, 92.85);
        context.lineTo(32.67, 92.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 797 Drawing
        context.beginPath();
        context.moveTo(32.67, 92.85);
        context.bezierCurveTo(31.89, 90.58, 20.67, 66.79, 24.34, 60.17);
        context.bezierCurveTo(28, 53.54, 32.5, 62.71, 32.67, 64.37);
        context.bezierCurveTo(32.84, 66.04, 37.69, 92.85, 37.69, 92.85);
        context.lineTo(32.67, 92.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 798 Drawing
        context.beginPath();
        context.moveTo(12.75, 108.13);
        context.bezierCurveTo(12.75, 107.75, 14.88, 97.49, 14.88, 97.49);
        context.bezierCurveTo(14.88, 97.49, 23.63, 92.63, 30.75, 90.88);
        context.bezierCurveTo(37.88, 89.13, 42.6, 88.82, 46.88, 88.88);
        context.bezierCurveTo(60.19, 89.03, 66.38, 92.14, 68.67, 93.21);
        context.bezierCurveTo(79.64, 98.32, 87.05, 104.54, 65.17, 104.04);
        context.bezierCurveTo(63.4, 104, 63.75, 101.88, 63.75, 101.88);
        context.lineTo(11.63, 108.13);
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 799 Drawing
        context.beginPath();
        context.moveTo(77.17, 58.37);
        context.bezierCurveTo(77.17, 58.37, 69.84, 59.21, 69.34, 52.71);
        context.bezierCurveTo(68.84, 46.21, 64.84, 30.04, 69.34, 29.37);
        context.bezierCurveTo(73.84, 28.71, 72.56, 30.04, 72.2, 36.12);
        context.bezierCurveTo(71.84, 42.21, 72.67, 54.87, 80.17, 54.21);
        context.bezierCurveTo(87.67, 53.54, 77.17, 58.37, 77.17, 58.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 800 Drawing
        context.beginPath();
        context.moveTo(77.17, 58.37);
        context.bezierCurveTo(77.17, 58.37, 69.84, 59.21, 69.34, 52.71);
        context.bezierCurveTo(68.84, 46.21, 64.84, 30.04, 69.34, 29.37);
        context.bezierCurveTo(73.84, 28.71, 72.56, 30.04, 72.2, 36.12);
        context.bezierCurveTo(71.84, 42.21, 72.67, 54.87, 80.17, 54.21);
        context.bezierCurveTo(87.67, 53.54, 77.17, 58.37, 77.17, 58.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 801 Drawing
        context.beginPath();
        context.moveTo(132.79, 96.42);
        context.bezierCurveTo(132.79, 96.42, 136.04, 94.81, 138.63, 91.01);
        context.bezierCurveTo(141.22, 87.21, 146.65, 81.62, 141.56, 81.85);
        context.bezierCurveTo(136.48, 82.08, 137.69, 83.57, 136.49, 86.99);
        context.bezierCurveTo(135.28, 90.41, 133.45, 92.28, 130.05, 92.7);
        context.bezierCurveTo(126.65, 93.13, 127.23, 94.69, 127.23, 94.69);
        context.lineTo(132.79, 96.42);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 802 Drawing
        context.beginPath();
        context.moveTo(132.79, 96.42);
        context.bezierCurveTo(132.79, 96.42, 136.04, 94.81, 138.63, 91.01);
        context.bezierCurveTo(141.22, 87.21, 146.65, 81.62, 141.56, 81.85);
        context.bezierCurveTo(136.48, 82.08, 137.69, 83.57, 136.49, 86.99);
        context.bezierCurveTo(135.28, 90.41, 133.45, 92.28, 130.05, 92.7);
        context.bezierCurveTo(126.65, 93.13, 127.23, 94.69, 127.23, 94.69);
        context.lineTo(132.79, 96.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 803 Drawing
        context.beginPath();
        context.moveTo(125.95, 84.63);
        context.bezierCurveTo(122.6, 85.67, 123.58, 85.14, 119.07, 83.45);
        context.bezierCurveTo(114.57, 81.77, 116.08, 79.13, 114.79, 73.81);
        context.bezierCurveTo(113.51, 68.49, 114.11, 66.7, 117.52, 66.19);
        context.bezierCurveTo(120.93, 65.68, 119.01, 71.6, 119.65, 75);
        context.bezierCurveTo(120.28, 78.4, 125.34, 79.76, 125.34, 79.76);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 804 Drawing
        context.beginPath();
        context.moveTo(130.35, 96.73);
        context.bezierCurveTo(130.95, 93.86, 140.89, 63.44, 135.45, 55.99);
        context.bezierCurveTo(130.02, 48.55, 125.98, 60.3, 126.03, 62.34);
        context.bezierCurveTo(126.08, 64.38, 124.29, 97.49, 124.29, 97.49);
        context.lineTo(130.35, 96.73);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 805 Drawing
        context.beginPath();
        context.moveTo(130.35, 96.73);
        context.bezierCurveTo(130.95, 93.86, 140.89, 63.44, 135.45, 55.99);
        context.bezierCurveTo(130.02, 48.55, 125.98, 60.3, 126.03, 62.34);
        context.bezierCurveTo(126.08, 64.38, 124.29, 97.49, 124.29, 97.49);
        context.lineTo(130.35, 96.73);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 806 Drawing
        context.beginPath();
        context.moveTo(135.86, 74.53);
        context.bezierCurveTo(135.86, 74.53, 136.47, 75, 138.54, 73.43);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 807 Drawing
        context.beginPath();
        context.moveTo(137, 65.68);
        context.bezierCurveTo(137, 65.68, 139.52, 65.55, 140.25, 64.08);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 808 Drawing
        context.beginPath();
        context.moveTo(128.56, 55.78);
        context.bezierCurveTo(128.56, 55.78, 127.15, 54.17, 127.26, 52.42);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 809 Drawing
        context.beginPath();
        context.moveTo(125.85, 67.09);
        context.bezierCurveTo(125.85, 67.09, 123.58, 64.64, 123.29, 63.45);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 810 Drawing
        context.beginPath();
        context.moveTo(115.18, 75.66);
        context.bezierCurveTo(115.18, 75.66, 114.16, 77.32, 113.22, 75.91);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 811 Drawing
        context.beginPath();
        context.moveTo(120.34, 76.6);
        context.bezierCurveTo(120.34, 76.6, 122.18, 75.24, 122.24, 73.24);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 812 Drawing
        context.beginPath();
        context.moveTo(118.73, 83.19);
        context.bezierCurveTo(118.73, 83.19, 117.83, 84.56, 116.35, 84.96);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 813 Drawing
        context.beginPath();
        context.moveTo(85.84, 73.54);
        context.bezierCurveTo(85.84, 73.54, 92.51, 75.04, 93.67, 70.54);
        context.bezierCurveTo(94.84, 66.04, 97.34, 55.61, 99.84, 58.41);
        context.bezierCurveTo(102.34, 61.21, 102.17, 62.18, 100.67, 65.86);
        context.bezierCurveTo(99.17, 69.54, 99.1, 77.54, 93.8, 78.21);
        context.bezierCurveTo(88.51, 78.88, 83.67, 78.21, 83.67, 78.21);
        context.lineTo(85.84, 73.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 814 Drawing
        context.beginPath();
        context.moveTo(85.84, 73.54);
        context.bezierCurveTo(85.84, 73.54, 92.51, 75.04, 93.67, 70.54);
        context.bezierCurveTo(94.84, 66.04, 97.34, 55.61, 99.84, 58.41);
        context.bezierCurveTo(102.34, 61.21, 102.17, 62.18, 100.67, 65.86);
        context.bezierCurveTo(99.17, 69.54, 99.1, 77.54, 93.8, 78.21);
        context.bezierCurveTo(88.51, 78.88, 83.67, 78.21, 83.67, 78.21);
        context.lineTo(85.84, 73.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 815 Drawing
        context.beginPath();
        context.moveTo(95.63, 99.88);
        context.bezierCurveTo(95.63, 99.88, 101, 95, 105.88, 93.5);
        context.bezierCurveTo(110.75, 92, 114, 93.12, 116.5, 96.69);
        context.bezierCurveTo(119, 100.25, 117.13, 103.75, 120.63, 105.12);
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 816 Drawing
        context.beginPath();
        context.moveTo(77.17, 100.54);
        context.bezierCurveTo(77.17, 100.54, 68.84, 21.87, 91, 15.04);
        context.bezierCurveTo(113.17, 8.21, 77.84, 108.12, 77.84, 108.12);
        context.lineTo(77.17, 100.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 817 Drawing
        context.beginPath();
        context.moveTo(77.17, 100.54);
        context.bezierCurveTo(77.17, 100.54, 68.84, 21.87, 91, 15.04);
        context.bezierCurveTo(113.17, 8.21, 77.84, 108.12, 77.84, 108.12);
        context.lineTo(77.17, 100.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 818 Drawing
        context.beginPath();
        context.moveTo(106.92, 101.07);
        context.bezierCurveTo(108.59, 98.86, 122.28, 85.37, 140.96, 97.45);
        context.bezierCurveTo(154.78, 106.38, 135.78, 110.4, 133.42, 111.07);
        context.bezierCurveTo(133.42, 111.07, 122.67, 113.32, 122.17, 113.07);
        context.bezierCurveTo(121.67, 112.82, 106.92, 101.07, 106.92, 101.07);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        //// Bezier 819 Drawing
        context.beginPath();
        context.moveTo(106.92, 101.07);
        context.bezierCurveTo(108.59, 98.86, 122.28, 85.37, 140.96, 97.45);
        context.bezierCurveTo(154.78, 106.38, 135.78, 110.4, 133.42, 111.07);
        context.bezierCurveTo(133.42, 111.07, 122.67, 113.32, 122.17, 113.07);
        context.bezierCurveTo(121.67, 112.82, 106.92, 101.07, 106.92, 101.07);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 820 Drawing
        context.beginPath();
        context.moveTo(8, 107.75);
        context.bezierCurveTo(8, 107.75, 43.88, 97.75, 67.25, 97.5);
        context.bezierCurveTo(90.63, 97.25, 127.25, 100.25, 144, 107.75);
        context.bezierCurveTo(160.75, 115.25, 141.25, 138.88, 141.25, 138.88);
        context.lineTo(50.38, 143);
        context.lineTo(24.75, 142.38);
        context.lineTo(8, 107.75);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Bezier 821 Drawing
        context.beginPath();
        context.moveTo(8, 107.75);
        context.bezierCurveTo(8, 107.75, 43.88, 97.75, 67.25, 97.5);
        context.bezierCurveTo(90.63, 97.25, 127.25, 100.25, 144, 107.75);
        context.bezierCurveTo(160.75, 115.25, 141.25, 138.88, 141.25, 138.88);
        context.lineTo(50.38, 143);
        context.lineTo(24.75, 142.38);
        context.lineTo(8, 107.75);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 822 Drawing
        context.beginPath();
        context.moveTo(84.64, 87.49);
        context.bezierCurveTo(85.38, 87.5, 86.76, 88.25, 88.13, 86.25);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 823 Drawing
        context.beginPath();
        context.moveTo(76.13, 83.87);
        context.bezierCurveTo(76.13, 83.87, 74.88, 85, 73.63, 82.75);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 824 Drawing
        context.beginPath();
        context.moveTo(73.63, 58.41);
        context.bezierCurveTo(73.63, 58.41, 74, 59.33, 72.63, 60.17);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 825 Drawing
        context.beginPath();
        context.moveTo(68.94, 50.94);
        context.bezierCurveTo(68.94, 50.94, 67.19, 50.69, 66.69, 49.56);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 826 Drawing
        context.beginPath();
        context.moveTo(85.38, 18.79);
        context.bezierCurveTo(85.38, 18.42, 84.38, 17.21, 85.38, 15.75);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 827 Drawing
        context.beginPath();
        context.moveTo(97.88, 32.62);
        context.bezierCurveTo(97.88, 32.62, 100.5, 34.25, 100.75, 31.75);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 828 Drawing
        context.beginPath();
        context.moveTo(97.54, 17.75);
        context.bezierCurveTo(97.54, 17.75, 98.29, 17.75, 98.72, 15.75);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 829 Drawing
        context.beginPath();
        context.moveTo(95.63, 46.43);
        context.bezierCurveTo(96.75, 46.12, 97.24, 48.48, 98.31, 46.43);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 830 Drawing
        context.beginPath();
        context.moveTo(93.38, 56);
        context.bezierCurveTo(93.38, 56, 95.81, 57.37, 96.97, 55.12);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 831 Drawing
        context.beginPath();
        context.moveTo(100, 68.23);
        context.bezierCurveTo(100, 68.23, 100.9, 69.73, 101.77, 68.23);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 832 Drawing
        context.beginPath();
        context.moveTo(95.63, 77.59);
        context.bezierCurveTo(95.63, 77.59, 95.69, 79, 96.97, 79.37);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 833 Drawing
        context.beginPath();
        context.moveTo(25.25, 89.25);
        context.bezierCurveTo(25.25, 89.25, 25.95, 89.63, 23.6, 89.75);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 834 Drawing
        context.beginPath();
        context.moveTo(25.91, 75.32);
        context.bezierCurveTo(25.91, 75.32, 25.45, 75.77, 23.6, 74.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 835 Drawing
        context.beginPath();
        context.moveTo(24.07, 68.22);
        context.bezierCurveTo(24.07, 68.22, 22, 68.37, 21.25, 67.25);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 836 Drawing
        context.beginPath();
        context.moveTo(29.93, 59.29);
        context.bezierCurveTo(29.93, 59.29, 30.92, 57.83, 30.65, 56.42);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 837 Drawing
        context.beginPath();
        context.moveTo(33.3, 68.23);
        context.bezierCurveTo(33.3, 68.23, 34.9, 66, 35.02, 65);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 838 Drawing
        context.beginPath();
        context.moveTo(42.88, 74.12);
        context.bezierCurveTo(42.88, 74.12, 43.88, 75.37, 44.5, 74.12);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 839 Drawing
        context.beginPath();
        context.moveTo(38.77, 75.41);
        context.bezierCurveTo(38.77, 75.41, 37.13, 74.5, 36.88, 72.88);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 840 Drawing
        context.beginPath();
        context.moveTo(40.75, 80.63);
        context.bezierCurveTo(40.75, 80.63, 41.63, 81.65, 42.88, 81.83);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 842 Drawing
        context.beginPath();
        context.moveTo(110.54, 1.18);
        context.lineTo(41.39, 1.18);
        context.bezierCurveTo(39.4, 1.18, 37.56, 2.25, 36.56, 3.97);
        context.lineTo(1.99, 63.86);
        context.bezierCurveTo(0.99, 65.58, 0.99, 67.7, 1.99, 69.43);
        context.lineTo(36.56, 129.31);
        context.bezierCurveTo(37.56, 131.04, 39.4, 132.1, 41.39, 132.1);
        context.lineTo(110.54, 132.1);
        context.bezierCurveTo(112.53, 132.1, 114.37, 131.04, 115.36, 129.31);
        context.lineTo(149.94, 69.43);
        context.bezierCurveTo(150.93, 67.7, 150.93, 65.58, 149.94, 63.86);
        context.lineTo(115.36, 3.97);
        context.bezierCurveTo(114.37, 2.25, 112.53, 1.18, 110.54, 1.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBeachBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 24
        context.save();

        //// Clip Clip 24

        context.beginPath();
        context.moveTo(41.39, 2);
        context.bezierCurveTo(39.4, 2, 37.56, 3.05, 36.56, 4.76);
        context.lineTo(36.56, 4.76);
        context.lineTo(1.99, 64.23);
        context.bezierCurveTo(0.99, 65.94, 0.99, 68.05, 1.99, 69.77);
        context.lineTo(1.99, 69.77);
        context.lineTo(36.56, 129.23);
        context.bezierCurveTo(37.56, 130.95, 39.4, 132, 41.39, 132);
        context.lineTo(41.39, 132);
        context.lineTo(110.54, 132);
        context.bezierCurveTo(112.53, 132, 114.37, 130.95, 115.36, 129.23);
        context.lineTo(115.36, 129.23);
        context.lineTo(149.94, 69.77);
        context.bezierCurveTo(150.93, 68.05, 150.93, 65.94, 149.94, 64.23);
        context.lineTo(149.94, 64.23);
        context.lineTo(115.36, 4.76);
        context.bezierCurveTo(114.37, 3.05, 112.53, 2, 110.54, 2);
        context.lineTo(110.54, 2);
        context.lineTo(41.39, 2);
        context.closePath();
        context.clip();



        //// Bezier 843 Drawing
        context.beginPath();
        context.moveTo(27, -2);
        context.lineTo(-18, 60.33);
        context.lineTo(-3, 74.67);
        context.lineTo(11.17, 79.67);
        context.lineTo(111, 80.83);
        context.lineTo(164, 82.09);
        context.lineTo(188.17, 78.17);
        context.lineTo(174, -22.67);
        context.lineTo(80.83, -8.5);
        context.lineTo(27, -2);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 844 Drawing
        context.beginPath();
        context.moveTo(27, -2);
        context.lineTo(-18, 60.33);
        context.lineTo(-3, 74.67);
        context.lineTo(11.17, 79.67);
        context.lineTo(111, 80.83);
        context.lineTo(164, 82.09);
        context.lineTo(188.17, 78.17);
        context.lineTo(174, -22.67);
        context.lineTo(80.83, -8.5);
        context.lineTo(27, -2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 845 Drawing
        context.beginPath();
        context.moveTo(61.33, 50.67);
        context.bezierCurveTo(61.33, 51.33, 56, 43, 49.17, 43.67);
        context.bezierCurveTo(42.33, 44.33, 26.17, 52, 27.83, 65.17);
        context.lineTo(33.67, 61.33);
        context.lineTo(36.17, 72.83);
        context.bezierCurveTo(36.17, 72.83, 57.33, 60.67, 61.33, 57.5);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 846 Drawing
        context.beginPath();
        context.moveTo(61.33, 50.67);
        context.bezierCurveTo(61.33, 50.67, 68.17, 35.67, 75.17, 33.5);
        context.bezierCurveTo(82.17, 31.33, 98.83, 30.58, 105.5, 39.12);
        context.lineTo(100.83, 41.37);
        context.bezierCurveTo(100.83, 41.37, 105.83, 45, 106.5, 50.67);
        context.bezierCurveTo(106.5, 50.67, 81.83, 46.17, 75.5, 50.67);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 847 Drawing
        context.beginPath();
        context.moveTo(1.24, 74.67);
        context.lineTo(175.67, 74.67);
        context.lineTo(174.5, 103.5);
        context.lineTo(132, 112.55);
        context.lineTo(75.96, 111);
        context.lineTo(30.83, 105.67);
        context.lineTo(-4.17, 98);
        context.lineTo(1.24, 74.67);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 848 Drawing
        context.beginPath();
        context.moveTo(1.24, 74.67);
        context.lineTo(175.67, 74.67);
        context.lineTo(174.5, 103.5);
        context.lineTo(132, 112.55);
        context.lineTo(75.96, 111);
        context.lineTo(30.83, 105.67);
        context.lineTo(-4.17, 98);
        context.lineTo(1.24, 74.67);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 849 Drawing
        context.beginPath();
        context.moveTo(5.33, 82.17);
        context.bezierCurveTo(5.83, 82, 35.75, 84.3, 35.75, 84.3);
        context.bezierCurveTo(35.75, 84.3, 52.5, 85.17, 62.33, 85);
        context.bezierCurveTo(72.17, 84.83, 87.17, 79.01, 81.67, 85.01);
        context.bezierCurveTo(76.17, 91, 60.83, 90.33, 75, 94.5);
        context.bezierCurveTo(89.17, 98.67, 93.83, 99, 100.17, 98.5);
        context.bezierCurveTo(106.5, 98, 141.16, 89.57, 138.25, 96.83);
        context.bezierCurveTo(135.34, 104.1, 125, 101.5, 134.5, 106.17);
        context.bezierCurveTo(144, 110.83, 132.5, 128, 132.5, 128);
        context.lineTo(109.17, 143);
        context.lineTo(38, 137.83);
        context.lineTo(19.83, 129.28);
        context.lineTo(5.33, 82.17);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Bezier 850 Drawing
        context.beginPath();
        context.moveTo(5.33, 82.17);
        context.bezierCurveTo(5.83, 82, 35.75, 84.3, 35.75, 84.3);
        context.bezierCurveTo(35.75, 84.3, 52.5, 85.17, 62.33, 85);
        context.bezierCurveTo(72.17, 84.83, 87.17, 79.01, 81.67, 85.01);
        context.bezierCurveTo(76.17, 91, 60.83, 90.33, 75, 94.5);
        context.bezierCurveTo(89.17, 98.67, 93.83, 99, 100.17, 98.5);
        context.bezierCurveTo(106.5, 98, 141.16, 89.57, 138.25, 96.83);
        context.bezierCurveTo(135.34, 104.1, 125, 101.5, 134.5, 106.17);
        context.bezierCurveTo(144, 110.83, 132.5, 128, 132.5, 128);
        context.lineTo(109.17, 143);
        context.lineTo(38, 137.83);
        context.lineTo(19.83, 129.28);
        context.lineTo(5.33, 82.17);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 851 Drawing
        context.beginPath();
        context.moveTo(54.62, 44.91);
        context.bezierCurveTo(54.62, 44.91, 47.33, 12.17, 73.67, 8);
        context.lineTo(73.67, 12.83);
        context.lineTo(84.67, 10.17);
        context.bezierCurveTo(84.67, 10.17, 80.76, 28.71, 75.96, 33.27);
        context.bezierCurveTo(75.96, 33.27, 66.79, 41.75, 61.33, 50.67);
        context.lineTo(54.62, 44.91);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 852 Drawing
        context.beginPath();
        context.moveTo(54.62, 44.91);
        context.bezierCurveTo(54.62, 44.91, 47.33, 12.17, 73.67, 8);
        context.lineTo(73.67, 12.83);
        context.lineTo(84.67, 10.17);
        context.bezierCurveTo(84.67, 10.17, 80.76, 28.71, 75.96, 33.27);
        context.bezierCurveTo(75.96, 33.27, 66.79, 41.75, 61.33, 50.67);
        context.lineTo(54.62, 44.91);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 853 Drawing
        context.beginPath();
        context.moveTo(55.68, 22.56);
        context.bezierCurveTo(55.68, 22.56, 46.5, 17, 37.83, 19);
        context.lineTo(40, 23.67);
        context.bezierCurveTo(40, 23.67, 25, 29.33, 23, 35.83);
        context.bezierCurveTo(23, 35.83, 44.83, 36.76, 50.33, 43.63);
        context.bezierCurveTo(50.33, 43.63, 54.78, 44.21, 56.62, 46.21);
        context.bezierCurveTo(56.62, 46.21, 53.4, 27.34, 55.68, 22.56);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 854 Drawing
        context.beginPath();
        context.moveTo(55.68, 22.56);
        context.bezierCurveTo(55.68, 22.56, 46.5, 17, 37.83, 19);
        context.lineTo(40, 23.67);
        context.bezierCurveTo(40, 23.67, 25, 29.33, 23, 35.83);
        context.bezierCurveTo(23, 35.83, 44.83, 36.76, 50.33, 43.63);
        context.bezierCurveTo(50.33, 43.63, 54.78, 44.21, 56.62, 46.21);
        context.bezierCurveTo(56.62, 46.21, 53.4, 27.34, 55.68, 22.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 855 Drawing
        context.beginPath();
        context.moveTo(67.39, 62.93);
        context.bezierCurveTo(63.29, 96.53, 23, 116.67, 23, 116.67);
        context.lineTo(20.5, 105.17);
        context.bezierCurveTo(60.17, 89.17, 59.14, 59.04, 59.14, 59.04);
        context.lineTo(61.33, 57.5);
        context.lineTo(66.17, 61.97);
        context.lineTo(67.39, 62.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        //// Bezier 856 Drawing
        context.beginPath();
        context.moveTo(67.39, 62.93);
        context.bezierCurveTo(63.29, 96.53, 23, 116.67, 23, 116.67);
        context.lineTo(20.5, 105.17);
        context.bezierCurveTo(60.17, 89.17, 59.14, 59.04, 59.14, 59.04);
        context.lineTo(61.33, 57.5);
        context.lineTo(66.17, 61.97);
        context.lineTo(67.39, 62.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 857 Drawing
        context.beginPath();
        context.moveTo(61.33, 50.67);
        context.bezierCurveTo(61.33, 50.67, 58, 57.33, 65.5, 61.83);
        context.bezierCurveTo(73, 66.33, 88.83, 73.67, 88.83, 73.67);
        context.lineTo(90.67, 69.5);
        context.lineTo(97.5, 72.83);
        context.bezierCurveTo(97.5, 72.83, 100.5, 46.83, 61.33, 50.67);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 858 Drawing
        context.beginPath();
        context.moveTo(61.33, 50.67);
        context.bezierCurveTo(61.33, 50.67, 58, 57.33, 65.5, 61.83);
        context.bezierCurveTo(73, 66.33, 88.83, 73.67, 88.83, 73.67);
        context.lineTo(90.67, 69.5);
        context.lineTo(97.5, 72.83);
        context.bezierCurveTo(97.5, 72.83, 100.5, 46.83, 61.33, 50.67);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 860 Drawing
        context.beginPath();
        context.moveTo(110.54, 1.68);
        context.lineTo(41.39, 1.68);
        context.bezierCurveTo(39.4, 1.68, 37.56, 2.73, 36.56, 4.44);
        context.lineTo(1.99, 63.91);
        context.bezierCurveTo(0.99, 65.63, 0.99, 67.73, 1.99, 69.44);
        context.lineTo(36.56, 128.91);
        context.bezierCurveTo(37.56, 130.63, 39.4, 131.68, 41.39, 131.68);
        context.lineTo(110.54, 131.68);
        context.bezierCurveTo(112.53, 131.68, 114.37, 130.63, 115.36, 128.91);
        context.lineTo(149.94, 69.44);
        context.bezierCurveTo(150.93, 67.73, 150.93, 65.63, 149.94, 63.91);
        context.lineTo(115.36, 4.44);
        context.bezierCurveTo(114.37, 2.73, 112.53, 1.68, 110.54, 1.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSpaceBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor15 = 'rgba(255, 147, 3, 1)';

        //// Group 26
        context.save();

        //// Clip Clip 26

        context.beginPath();
        context.moveTo(42.05, 1.51);
        context.bezierCurveTo(40.06, 1.51, 38.22, 2.57, 37.23, 4.3);
        context.lineTo(37.23, 4.3);
        context.lineTo(2.65, 64.19);
        context.bezierCurveTo(1.65, 65.91, 1.65, 68.03, 2.65, 69.76);
        context.lineTo(2.65, 69.76);
        context.lineTo(37.23, 129.64);
        context.bezierCurveTo(38.22, 131.37, 40.06, 132.43, 42.05, 132.43);
        context.lineTo(42.05, 132.43);
        context.lineTo(111.2, 132.43);
        context.bezierCurveTo(113.19, 132.43, 115.03, 131.37, 116.02, 129.64);
        context.lineTo(116.02, 129.64);
        context.lineTo(150.6, 69.76);
        context.bezierCurveTo(151.6, 68.03, 151.6, 65.91, 150.6, 64.19);
        context.lineTo(150.6, 64.19);
        context.lineTo(116.02, 4.3);
        context.bezierCurveTo(115.03, 2.57, 113.19, 1.51, 111.2, 1.51);
        context.lineTo(111.2, 1.51);
        context.lineTo(42.05, 1.51);
        context.closePath();
        context.clip();



        //// Bezier 895 Drawing
        context.beginPath();
        context.moveTo(97.55, -16.5);
        context.bezierCurveTo(95, -15.75, 23.64, 0.25, 23.64, 0.25);
        context.lineTo(5, 28.42);
        context.bezierCurveTo(5, 28.42, -17.09, 46.42, -9.75, 69.37);
        context.lineTo(-2.41, 92.31);
        context.lineTo(69.75, 118.75);
        context.lineTo(113.88, 136.25);
        context.lineTo(132.55, 139.25);
        context.bezierCurveTo(132.55, 139.25, 155.5, 96.75, 156.5, 92.5);
        context.bezierCurveTo(157.5, 88.25, 160.77, 22.75, 160.77, 22.75);
        context.lineTo(121.79, -35.75);
        context.lineTo(97.55, -16.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 896 Drawing
        context.beginPath();
        context.moveTo(97.55, -16.5);
        context.bezierCurveTo(95, -15.75, 23.64, 0.25, 23.64, 0.25);
        context.lineTo(5, 28.42);
        context.bezierCurveTo(5, 28.42, -17.09, 46.42, -9.75, 69.37);
        context.lineTo(-2.41, 92.31);
        context.lineTo(69.75, 118.75);
        context.lineTo(113.88, 136.25);
        context.lineTo(132.55, 139.25);
        context.bezierCurveTo(132.55, 139.25, 155.5, 96.75, 156.5, 92.5);
        context.bezierCurveTo(157.5, 88.25, 160.77, 22.75, 160.77, 22.75);
        context.lineTo(121.79, -35.75);
        context.lineTo(97.55, -16.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 897 Drawing
        context.beginPath();
        context.moveTo(1.9, 84.25);
        context.bezierCurveTo(1.9, 84.25, 78, 82, 115.5, 147);
        context.lineTo(20.5, 150);
        context.lineTo(-12, 110.25);
        context.lineTo(1.9, 84.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 898 Drawing
        context.beginPath();
        context.moveTo(1.9, 84.25);
        context.bezierCurveTo(1.9, 84.25, 78, 82, 115.5, 147);
        context.lineTo(20.5, 150);
        context.lineTo(-12, 110.25);
        context.lineTo(1.9, 84.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 187 Drawing
        oval(context, 92.01, 57.73, 39, 39);
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Oval 188 Drawing
        oval(context, 92.01, 57.73, 39, 39);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 189 Drawing
        oval(context, 13.51, 44.98, 34, 34);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 190 Drawing
        oval(context, 13.51, 44.98, 34, 34);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 191 Drawing
        oval(context, 30.51, 59.98, 3.8, 7);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 192 Drawing
        oval(context, 30.51, 59.98, 3.8, 7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 193 Drawing
        oval(context, 24.36, 67.48, 3.8, 7);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 194 Drawing
        oval(context, 24.36, 67.48, 3.8, 7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 195 Drawing
        oval(context, 20.51, 50.73, 3.8, 7);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 196 Drawing
        oval(context, 20.51, 50.73, 3.8, 7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 197 Drawing
        oval(context, 41.21, 57.73, 2.4, 4.4);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 198 Drawing
        oval(context, 41.21, 57.73, 2.4, 4.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 199 Drawing
        oval(context, 30.51, 47.73, 2.4, 4.4);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 200 Drawing
        oval(context, 30.51, 47.73, 2.4, 4.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 201 Drawing
        oval(context, 17.06, 62.13, 2.4, 4.4);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 202 Drawing
        oval(context, 17.06, 62.13, 2.4, 4.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 203 Drawing
        oval(context, 37.41, 67.48, 2.4, 4.4);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 204 Drawing
        oval(context, 37.41, 67.48, 2.4, 4.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 205 Drawing
        oval(context, 22.41, 61.03, 1.2, 2.2);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 206 Drawing
        oval(context, 22.41, 61.03, 1.2, 2.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 207 Drawing
        context.save();
        context.translate(36.81, 55.38);
        context.rotate(-0.05 * Math.PI / 180);

        oval(context, -0.6, -1.1, 1.2, 2.2);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();

        context.restore();


        //// Oval 208 Drawing
        context.save();
        context.translate(36.81, 55.38);
        context.rotate(-0.05 * Math.PI / 180);

        oval(context, -0.6, -1.1, 1.2, 2.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();


        //// Oval 209 Drawing
        oval(context, 106.31, 81.48, 4.5, 4.5);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 210 Drawing
        oval(context, 106.31, 81.48, 4.5, 4.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 211 Drawing
        oval(context, 110.46, 59.88, 4.5, 4.5);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 212 Drawing
        oval(context, 110.46, 59.88, 4.5, 4.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 213 Drawing
        oval(context, 116.86, 88.03, 4.5, 4.5);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 214 Drawing
        oval(context, 116.86, 88.03, 4.5, 4.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 215 Drawing
        oval(context, 99.56, 85.18, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 216 Drawing
        oval(context, 99.56, 85.18, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 217 Drawing
        oval(context, 106.31, 66.23, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 218 Drawing
        oval(context, 106.31, 66.23, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 219 Drawing
        oval(context, 95.96, 76.28, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 220 Drawing
        oval(context, 95.96, 76.28, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 221 Drawing
        oval(context, 125.16, 72.88, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 222 Drawing
        oval(context, 125.16, 72.88, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 223 Drawing
        oval(context, 120.76, 82.13, 2.1, 2.1);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 224 Drawing
        oval(context, 120.76, 82.13, 2.1, 2.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 225 Drawing
        oval(context, 104.86, 76.18, 2.1, 2.1);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 226 Drawing
        oval(context, 104.86, 76.18, 2.1, 2.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 899 Drawing
        context.beginPath();
        context.moveTo(71.28, 30.25);
        context.bezierCurveTo(71.28, 30.25, 50.25, 35.92, 51.08, 42.67);
        context.bezierCurveTo(51.92, 49.42, 75.54, 42.84, 75.54, 42.84);
        context.strokeStyle = strokeColor15;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 900 Drawing
        context.beginPath();
        context.moveTo(112.72, 32.25);
        context.bezierCurveTo(112.72, 32.25, 134.83, 25, 131.58, 18.58);
        context.bezierCurveTo(131.58, 18.58, 127.92, 16.78, 118.83, 18.17);
        context.lineTo(109.75, 19.55);
        context.strokeStyle = strokeColor15;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 227 Drawing
        oval(context, 110.46, 90.88, 2.1, 2.1);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 228 Drawing
        oval(context, 110.46, 90.88, 2.1, 2.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 229 Drawing
        oval(context, 126.76, 79.48, 2.1, 2.1);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 230 Drawing
        oval(context, 126.76, 79.48, 2.1, 2.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 231 Drawing
        oval(context, 97.56, 67.48, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 232 Drawing
        oval(context, 97.56, 67.48, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 233 Drawing
        oval(context, 117.51, 69.38, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 234 Drawing
        oval(context, 117.51, 69.38, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 235 Drawing
        oval(context, 113.91, 76.28, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 236 Drawing
        oval(context, 113.91, 76.28, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 237 Drawing
        oval(context, 118.11, 62.78, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Oval 238 Drawing
        oval(context, 118.11, 62.78, 3.2, 3.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Star 9 Drawing
        context.beginPath();
        context.moveTo(62.26, 61.38);
        context.lineTo(62.88, 62.63);
        context.lineTo(64.26, 62.83);
        context.lineTo(63.26, 63.8);
        context.lineTo(63.49, 65.18);
        context.lineTo(62.26, 64.53);
        context.lineTo(61.03, 65.18);
        context.lineTo(61.26, 63.8);
        context.lineTo(60.26, 62.83);
        context.lineTo(61.64, 62.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 10 Drawing
        context.beginPath();
        context.moveTo(65.31, 88.68);
        context.lineTo(65.93, 89.93);
        context.lineTo(67.31, 90.13);
        context.lineTo(66.31, 91.1);
        context.lineTo(66.54, 92.48);
        context.lineTo(65.31, 91.83);
        context.lineTo(64.08, 92.48);
        context.lineTo(64.31, 91.1);
        context.lineTo(63.31, 90.13);
        context.lineTo(64.69, 89.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 11 Drawing
        context.beginPath();
        context.moveTo(116.91, 113.33);
        context.lineTo(117.53, 114.58);
        context.lineTo(118.91, 114.78);
        context.lineTo(117.91, 115.75);
        context.lineTo(118.14, 117.13);
        context.lineTo(116.91, 116.48);
        context.lineTo(115.68, 117.13);
        context.lineTo(115.91, 115.75);
        context.lineTo(114.91, 114.78);
        context.lineTo(116.29, 114.58);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 12 Drawing
        context.beginPath();
        context.moveTo(93.21, 102.68);
        context.lineTo(93.83, 103.93);
        context.lineTo(95.21, 104.13);
        context.lineTo(94.21, 105.1);
        context.lineTo(94.44, 106.48);
        context.lineTo(93.21, 105.83);
        context.lineTo(91.98, 106.48);
        context.lineTo(92.21, 105.1);
        context.lineTo(91.21, 104.13);
        context.lineTo(92.59, 103.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 13 Drawing
        context.beginPath();
        context.moveTo(75.56, 75.18);
        context.lineTo(76, 76.07);
        context.lineTo(76.99, 76.22);
        context.lineTo(76.27, 76.91);
        context.lineTo(76.44, 77.89);
        context.lineTo(75.56, 77.43);
        context.lineTo(74.68, 77.89);
        context.lineTo(74.85, 76.91);
        context.lineTo(74.13, 76.22);
        context.lineTo(75.12, 76.07);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 14 Drawing
        context.beginPath();
        context.moveTo(86.96, 59.68);
        context.lineTo(87.4, 60.57);
        context.lineTo(88.39, 60.72);
        context.lineTo(87.67, 61.41);
        context.lineTo(87.84, 62.39);
        context.lineTo(86.96, 61.93);
        context.lineTo(86.08, 62.39);
        context.lineTo(86.25, 61.41);
        context.lineTo(85.53, 60.72);
        context.lineTo(86.52, 60.57);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 15 Drawing
        context.beginPath();
        context.moveTo(46.06, 77.78);
        context.lineTo(46.5, 78.67);
        context.lineTo(47.49, 78.82);
        context.lineTo(46.77, 79.51);
        context.lineTo(46.94, 80.49);
        context.lineTo(46.06, 80.03);
        context.lineTo(45.18, 80.49);
        context.lineTo(45.35, 79.51);
        context.lineTo(44.63, 78.82);
        context.lineTo(45.62, 78.67);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 16 Drawing
        context.beginPath();
        context.moveTo(43.16, 33.13);
        context.lineTo(43.6, 34.02);
        context.lineTo(44.59, 34.17);
        context.lineTo(43.87, 34.86);
        context.lineTo(44.04, 35.84);
        context.lineTo(43.16, 35.38);
        context.lineTo(42.28, 35.84);
        context.lineTo(42.45, 34.86);
        context.lineTo(41.73, 34.17);
        context.lineTo(42.72, 34.02);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 17 Drawing
        context.beginPath();
        context.moveTo(36.81, 13.43);
        context.lineTo(37.25, 14.32);
        context.lineTo(38.24, 14.47);
        context.lineTo(37.52, 15.16);
        context.lineTo(37.69, 16.14);
        context.lineTo(36.81, 15.68);
        context.lineTo(35.93, 16.14);
        context.lineTo(36.1, 15.16);
        context.lineTo(35.38, 14.47);
        context.lineTo(36.37, 14.32);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 18 Drawing
        context.beginPath();
        context.moveTo(65.31, 12.83);
        context.lineTo(65.75, 13.72);
        context.lineTo(66.74, 13.87);
        context.lineTo(66.02, 14.56);
        context.lineTo(66.19, 15.54);
        context.lineTo(65.31, 15.08);
        context.lineTo(64.43, 15.54);
        context.lineTo(64.6, 14.56);
        context.lineTo(63.88, 13.87);
        context.lineTo(64.87, 13.72);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 19 Drawing
        context.beginPath();
        context.moveTo(122.86, 45.73);
        context.lineTo(123.3, 46.62);
        context.lineTo(124.29, 46.77);
        context.lineTo(123.57, 47.46);
        context.lineTo(123.74, 48.44);
        context.lineTo(122.86, 47.98);
        context.lineTo(121.98, 48.44);
        context.lineTo(122.15, 47.46);
        context.lineTo(121.43, 46.77);
        context.lineTo(122.42, 46.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 20 Drawing
        context.beginPath();
        context.moveTo(134.01, 55.13);
        context.lineTo(134.45, 56.02);
        context.lineTo(135.44, 56.17);
        context.lineTo(134.72, 56.86);
        context.lineTo(134.89, 57.84);
        context.lineTo(134.01, 57.38);
        context.lineTo(133.13, 57.84);
        context.lineTo(133.3, 56.86);
        context.lineTo(132.58, 56.17);
        context.lineTo(133.57, 56.02);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        context.restore();



        //// Group 27
        context.save();

        //// Clip Clip 27

        context.beginPath();
        context.moveTo(37.23, 129.64);
        context.bezierCurveTo(38.22, 131.37, 40.06, 132.43, 42.05, 132.43);
        context.lineTo(42.05, 132.43);
        context.lineTo(105.64, 132.43);
        context.bezierCurveTo(75.32, 94.01, 30.48, 86.19, 11.23, 84.62);
        context.lineTo(11.23, 84.62);
        context.lineTo(37.23, 129.64);
        context.closePath();
        context.clip();



        //// Bezier 902 Drawing
        context.beginPath();
        context.moveTo(78.72, 107.54);
        context.lineTo(78.08, 110.63);
        context.bezierCurveTo(77.45, 113.72, 76.42, 112.25, 71.28, 115.08);
        context.bezierCurveTo(66.14, 117.92, 67.92, 114.42, 65.58, 110.63);
        context.bezierCurveTo(63.25, 106.85, 58.58, 108.08, 54.92, 108.25);
        context.bezierCurveTo(51.25, 108.42, 46.08, 111.58, 45.25, 117.12);
        context.bezierCurveTo(44.42, 122.66, 47.92, 121.25, 47.5, 126.42);
        context.bezierCurveTo(47.08, 131.58, 46.25, 132.43, 43.59, 135.08);
        context.bezierCurveTo(40.92, 137.74, 40.42, 134.25, 36.2, 133.25);
        context.bezierCurveTo(31.99, 132.25, 27.75, 125.58, 23.03, 122.42);
        context.bezierCurveTo(18.31, 119.25, 23.92, 120.08, 28.62, 114.73);
        context.bezierCurveTo(33.32, 109.38, 30.08, 110.08, 27.42, 104.42);
        context.bezierCurveTo(24.75, 98.75, 17.05, 104.42, 17.05, 104.42);
        context.bezierCurveTo(17.05, 104.42, 8.92, 97.42, 12.25, 95.08);
        context.bezierCurveTo(15.58, 92.75, 14.92, 86.25, 14.75, 85.21);
        context.lineTo(26.25, 82.15);
        context.lineTo(73.76, 99);
        context.lineTo(78.72, 107.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 903 Drawing
        context.beginPath();
        context.moveTo(78.72, 107.54);
        context.lineTo(78.08, 110.63);
        context.bezierCurveTo(77.45, 113.72, 76.42, 112.25, 71.28, 115.08);
        context.bezierCurveTo(66.14, 117.92, 67.92, 114.42, 65.58, 110.63);
        context.bezierCurveTo(63.25, 106.85, 58.58, 108.08, 54.92, 108.25);
        context.bezierCurveTo(51.25, 108.42, 46.08, 111.58, 45.25, 117.12);
        context.bezierCurveTo(44.42, 122.66, 47.92, 121.25, 47.5, 126.42);
        context.bezierCurveTo(47.08, 131.58, 46.25, 132.43, 43.59, 135.08);
        context.bezierCurveTo(40.92, 137.74, 40.42, 134.25, 36.2, 133.25);
        context.bezierCurveTo(31.99, 132.25, 27.75, 125.58, 23.03, 122.42);
        context.bezierCurveTo(18.31, 119.25, 23.92, 120.08, 28.62, 114.73);
        context.bezierCurveTo(33.32, 109.38, 30.08, 110.08, 27.42, 104.42);
        context.bezierCurveTo(24.75, 98.75, 17.05, 104.42, 17.05, 104.42);
        context.bezierCurveTo(17.05, 104.42, 8.92, 97.42, 12.25, 95.08);
        context.bezierCurveTo(15.58, 92.75, 14.92, 86.25, 14.75, 85.21);
        context.lineTo(26.25, 82.15);
        context.lineTo(73.76, 99);
        context.lineTo(78.72, 107.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 904 Drawing
        context.beginPath();
        context.moveTo(78.72, 107.54);
        context.lineTo(78.08, 110.63);
        context.bezierCurveTo(77.45, 113.72, 76.42, 112.25, 71.28, 115.08);
        context.bezierCurveTo(66.14, 117.92, 67.92, 114.42, 65.58, 110.63);
        context.bezierCurveTo(63.25, 106.85, 58.58, 108.08, 54.92, 108.25);
        context.bezierCurveTo(51.25, 108.42, 46.08, 111.58, 45.25, 117.12);
        context.bezierCurveTo(44.42, 122.66, 47.92, 121.25, 47.5, 126.42);
        context.bezierCurveTo(47.08, 131.58, 46.25, 132.43, 43.59, 135.08);
        context.bezierCurveTo(40.92, 137.74, 40.42, 134.25, 36.2, 133.25);
        context.bezierCurveTo(31.99, 132.25, 27.75, 125.58, 23.03, 122.42);
        context.bezierCurveTo(18.31, 119.25, 23.92, 120.08, 28.62, 114.73);
        context.bezierCurveTo(33.32, 109.38, 30.08, 110.08, 27.42, 104.42);
        context.bezierCurveTo(24.75, 98.75, 17.05, 104.42, 17.05, 104.42);
        context.bezierCurveTo(17.05, 104.42, 8.92, 97.42, 12.25, 95.08);
        context.bezierCurveTo(15.58, 92.75, 14.92, 86.25, 14.75, 85.21);
        context.lineTo(26.25, 82.15);
        context.lineTo(73.76, 99);
        context.lineTo(78.72, 107.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 905 Drawing
        context.beginPath();
        context.moveTo(78.72, 107.54);
        context.lineTo(78.08, 110.63);
        context.bezierCurveTo(77.45, 113.72, 76.42, 112.25, 71.28, 115.08);
        context.bezierCurveTo(66.14, 117.92, 67.92, 114.42, 65.58, 110.63);
        context.bezierCurveTo(63.25, 106.85, 58.58, 108.08, 54.92, 108.25);
        context.bezierCurveTo(51.25, 108.42, 46.08, 111.58, 45.25, 117.12);
        context.bezierCurveTo(44.42, 122.66, 47.92, 121.25, 47.5, 126.42);
        context.bezierCurveTo(47.08, 131.58, 46.25, 132.43, 43.59, 135.08);
        context.bezierCurveTo(40.92, 137.74, 40.42, 134.25, 36.2, 133.25);
        context.bezierCurveTo(31.99, 132.25, 27.75, 125.58, 23.03, 122.42);
        context.bezierCurveTo(18.31, 119.25, 23.92, 120.08, 28.62, 114.73);
        context.bezierCurveTo(33.32, 109.38, 30.08, 110.08, 27.42, 104.42);
        context.bezierCurveTo(24.75, 98.75, 17.05, 104.42, 17.05, 104.42);
        context.bezierCurveTo(17.05, 104.42, 8.92, 97.42, 12.25, 95.08);
        context.bezierCurveTo(15.58, 92.75, 14.92, 86.25, 14.75, 85.21);
        context.lineTo(26.25, 82.15);
        context.lineTo(73.76, 99);
        context.lineTo(78.72, 107.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 906 Drawing
        context.beginPath();
        context.moveTo(107.62, 135.01);
        context.lineTo(84.14, 143.08);
        context.lineTo(62.25, 136.06);
        context.bezierCurveTo(62.25, 136.06, 61.75, 132.43, 62.25, 129.08);
        context.bezierCurveTo(62.75, 125.74, 68.25, 126.08, 75.58, 123.42);
        context.bezierCurveTo(82.92, 120.75, 84.14, 111.4, 84.14, 111.4);
        context.lineTo(92.58, 112.87);
        context.bezierCurveTo(92.58, 112.87, 102.16, 123.37, 102.52, 123.87);
        context.bezierCurveTo(102.88, 124.37, 107.62, 135.01, 107.62, 135.01);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 907 Drawing
        context.beginPath();
        context.moveTo(107.62, 135.01);
        context.lineTo(84.14, 143.08);
        context.lineTo(62.25, 136.06);
        context.bezierCurveTo(62.25, 136.06, 61.75, 132.43, 62.25, 129.08);
        context.bezierCurveTo(62.75, 125.74, 68.25, 126.08, 75.58, 123.42);
        context.bezierCurveTo(82.92, 120.75, 84.14, 111.4, 84.14, 111.4);
        context.lineTo(92.58, 112.87);
        context.bezierCurveTo(92.58, 112.87, 102.16, 123.37, 102.52, 123.87);
        context.bezierCurveTo(102.88, 124.37, 107.62, 135.01, 107.62, 135.01);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 28
        context.save();

        //// Clip Clip 28

        context.beginPath();
        context.moveTo(42.05, 1.51);
        context.bezierCurveTo(40.06, 1.51, 38.22, 2.57, 37.23, 4.3);
        context.lineTo(37.23, 4.3);
        context.lineTo(2.65, 64.19);
        context.bezierCurveTo(1.65, 65.91, 1.65, 68.03, 2.65, 69.76);
        context.lineTo(2.65, 69.76);
        context.lineTo(37.23, 129.64);
        context.bezierCurveTo(38.22, 131.37, 40.06, 132.43, 42.05, 132.43);
        context.lineTo(42.05, 132.43);
        context.lineTo(111.2, 132.43);
        context.bezierCurveTo(113.19, 132.43, 115.03, 131.37, 116.02, 129.64);
        context.lineTo(116.02, 129.64);
        context.lineTo(150.6, 69.76);
        context.bezierCurveTo(151.6, 68.03, 151.6, 65.91, 150.6, 64.19);
        context.lineTo(150.6, 64.19);
        context.lineTo(116.02, 4.3);
        context.bezierCurveTo(115.03, 2.57, 113.19, 1.51, 111.2, 1.51);
        context.lineTo(111.2, 1.51);
        context.lineTo(42.05, 1.51);
        context.closePath();
        context.clip();



        //// Bezier 909 Drawing
        context.beginPath();
        context.moveTo(1.9, 84.25);
        context.bezierCurveTo(1.9, 84.25, 78, 82, 115.5, 147);
        context.lineTo(20.5, 150);
        context.lineTo(-12, 110.25);
        context.lineTo(1.9, 84.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 239 Drawing
        oval(context, 71.31, 9.53, 41.4, 41.4);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Oval 240 Drawing
        oval(context, 71.31, 9.53, 41.4, 41.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 911 Drawing
        context.beginPath();
        context.moveTo(111.2, 1.51);
        context.lineTo(42.05, 1.51);
        context.bezierCurveTo(40.06, 1.51, 38.22, 2.57, 37.23, 4.3);
        context.lineTo(2.65, 64.19);
        context.bezierCurveTo(1.65, 65.91, 1.65, 68.03, 2.65, 69.75);
        context.lineTo(37.23, 129.64);
        context.bezierCurveTo(38.22, 131.37, 40.06, 132.43, 42.05, 132.43);
        context.lineTo(111.2, 132.43);
        context.bezierCurveTo(113.19, 132.43, 115.03, 131.37, 116.02, 129.64);
        context.lineTo(150.6, 69.75);
        context.bezierCurveTo(151.6, 68.03, 151.6, 65.91, 150.6, 64.19);
        context.lineTo(116.02, 4.3);
        context.bezierCurveTo(115.03, 2.57, 113.19, 1.51, 111.2, 1.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawMountainsBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 32
        context.save();

        //// Clip Clip 32

        context.beginPath();
        context.moveTo(41.56, 1);
        context.bezierCurveTo(39.57, 1, 37.73, 2.06, 36.74, 3.78);
        context.lineTo(36.74, 3.78);
        context.lineTo(2.16, 63.67);
        context.bezierCurveTo(1.17, 65.39, 1.17, 67.52, 2.16, 69.24);
        context.lineTo(2.16, 69.24);
        context.lineTo(36.74, 129.13);
        context.bezierCurveTo(37.73, 130.85, 39.57, 131.91, 41.56, 131.91);
        context.lineTo(41.56, 131.91);
        context.lineTo(110.71, 131.91);
        context.bezierCurveTo(112.7, 131.91, 114.54, 130.85, 115.54, 129.13);
        context.lineTo(115.54, 129.13);
        context.lineTo(150.11, 69.24);
        context.bezierCurveTo(151.11, 67.52, 151.11, 65.39, 150.11, 63.67);
        context.lineTo(150.11, 63.67);
        context.lineTo(115.54, 3.78);
        context.bezierCurveTo(114.54, 2.06, 112.7, 1, 110.71, 1);
        context.lineTo(110.71, 1);
        context.lineTo(41.56, 1);
        context.closePath();
        context.clip();



        //// Bezier 947 Drawing
        context.beginPath();
        context.moveTo(-9, 54.16);
        context.lineTo(6.33, 83.18);
        context.lineTo(37.5, 84);
        context.bezierCurveTo(37.5, 84, 70.83, 81, 71.5, 81.16);
        context.bezierCurveTo(72.17, 81.33, 108.5, 83.18, 108.5, 83.18);
        context.lineTo(141, 95.66);
        context.lineTo(156.33, 98.17);
        context.bezierCurveTo(156.33, 98.17, 175.33, 77.83, 173.33, 73.75);
        context.bezierCurveTo(171.33, 69.66, 129.17, 1, 129.17, 1);
        context.lineTo(98.33, -20.67);
        context.bezierCurveTo(98.33, -20.67, 80.83, -29.17, 48, -14.5);
        context.lineTo(15.17, 0.16);
        context.lineTo(-9, 54.16);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 948 Drawing
        context.beginPath();
        context.moveTo(-9, 54.16);
        context.lineTo(6.33, 83.18);
        context.lineTo(37.5, 84);
        context.bezierCurveTo(37.5, 84, 70.83, 81, 71.5, 81.16);
        context.bezierCurveTo(72.17, 81.33, 108.5, 83.18, 108.5, 83.18);
        context.lineTo(141, 95.66);
        context.lineTo(156.33, 98.17);
        context.bezierCurveTo(156.33, 98.17, 175.33, 77.83, 173.33, 73.75);
        context.bezierCurveTo(171.33, 69.66, 129.17, 1, 129.17, 1);
        context.lineTo(98.33, -20.67);
        context.bezierCurveTo(98.33, -20.67, 80.83, -29.17, 48, -14.5);
        context.lineTo(15.17, 0.16);
        context.lineTo(-9, 54.16);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 949 Drawing
        context.beginPath();
        context.moveTo(114.33, 107.37);
        context.bezierCurveTo(114.33, 107.37, 123, 85.83, 135.5, 84);
        context.bezierCurveTo(148, 82.16, 177, 84.15, 177, 84.15);
        context.lineTo(156.33, 100.5);
        context.lineTo(122.67, 113.16);
        context.lineTo(114.33, 107.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 950 Drawing
        context.beginPath();
        context.moveTo(114.33, 107.37);
        context.bezierCurveTo(114.33, 107.37, 123, 85.83, 135.5, 84);
        context.bezierCurveTo(148, 82.16, 177, 84.15, 177, 84.15);
        context.lineTo(156.33, 100.5);
        context.lineTo(122.67, 113.16);
        context.lineTo(114.33, 107.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 951 Drawing
        context.beginPath();
        context.moveTo(81.83, 66.45);
        context.lineTo(106.67, 26);
        context.lineTo(143.42, 90.66);
        context.lineTo(99.17, 108.33);
        context.lineTo(79.33, 77.33);
        context.lineTo(81.83, 66.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col12;
        context.fill();


        //// Bezier 952 Drawing
        context.beginPath();
        context.moveTo(81.83, 66.45);
        context.lineTo(106.67, 26);
        context.lineTo(143.42, 90.66);
        context.lineTo(99.17, 108.33);
        context.lineTo(79.33, 77.33);
        context.lineTo(81.83, 66.45);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 953 Drawing
        context.beginPath();
        context.moveTo(6.33, 91.33);
        context.lineTo(51.83, 9);
        context.lineTo(93.5, 85.33);
        context.lineTo(28.5, 104.5);
        context.lineTo(6.33, 91.33);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col12;
        context.fill();


        //// Bezier 954 Drawing
        context.beginPath();
        context.moveTo(6.33, 91.33);
        context.lineTo(51.83, 9);
        context.lineTo(93.5, 85.33);
        context.lineTo(28.5, 104.5);
        context.lineTo(6.33, 91.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 955 Drawing
        context.beginPath();
        context.moveTo(6.33, 91.33);
        context.lineTo(51.83, 9);
        context.lineTo(93.5, 85.33);
        context.lineTo(28.5, 104.5);
        context.lineTo(6.33, 91.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 956 Drawing
        context.beginPath();
        context.moveTo(-2.83, 91.66);
        context.bezierCurveTo(-2.83, 91.66, 16.01, 86.51, 28.5, 89.66);
        context.bezierCurveTo(40.99, 92.82, 49.67, 96.83, 49.67, 96.83);
        context.lineTo(34.67, 109.16);
        context.lineTo(17.86, 114.97);
        context.lineTo(7.83, 116.33);
        context.lineTo(-2.83, 91.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 957 Drawing
        context.beginPath();
        context.moveTo(-2.83, 91.66);
        context.bezierCurveTo(-2.83, 91.66, 16.01, 86.51, 28.5, 89.66);
        context.bezierCurveTo(40.99, 92.82, 49.67, 96.83, 49.67, 96.83);
        context.lineTo(34.67, 109.16);
        context.lineTo(17.86, 114.97);
        context.lineTo(7.83, 116.33);
        context.lineTo(-2.83, 91.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 958 Drawing
        context.beginPath();
        context.moveTo(13.17, 106.33);
        context.bezierCurveTo(13.17, 106.33, 42.5, 94, 48.33, 87.5);
        context.bezierCurveTo(54.17, 81, 67.33, 72.83, 79, 73.83);
        context.bezierCurveTo(90.67, 74.83, 103.33, 83.66, 107.33, 89.16);
        context.bezierCurveTo(111.33, 94.66, 123, 105.5, 131.5, 107.83);
        context.bezierCurveTo(140, 110.16, 149.33, 117, 149.33, 117);
        context.lineTo(123.17, 139.66);
        context.lineTo(76.14, 141);
        context.bezierCurveTo(76.14, 141, 34, 140.66, 33, 141);
        context.bezierCurveTo(32, 141.33, 13.17, 106.33, 13.17, 106.33);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 959 Drawing
        context.beginPath();
        context.moveTo(13.17, 106.33);
        context.bezierCurveTo(13.17, 106.33, 42.5, 94, 48.33, 87.5);
        context.bezierCurveTo(54.17, 81, 67.33, 72.83, 79, 73.83);
        context.bezierCurveTo(90.67, 74.83, 103.33, 83.66, 107.33, 89.16);
        context.bezierCurveTo(111.33, 94.66, 123, 105.5, 131.5, 107.83);
        context.bezierCurveTo(140, 110.16, 149.33, 117, 149.33, 117);
        context.lineTo(123.17, 139.66);
        context.lineTo(76.14, 141);
        context.bezierCurveTo(76.14, 141, 34, 140.66, 33, 141);
        context.bezierCurveTo(32, 141.33, 13.17, 106.33, 13.17, 106.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 301 Drawing
        oval(context, 80.67, 79.66, 12.2, 12.2);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 302 Drawing
        oval(context, 80.67, 79.66, 12.2, 12.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 960 Drawing
        context.beginPath();
        context.moveTo(86.75, 113.16);
        context.lineTo(86.75, 86.37);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 303 Drawing
        oval(context, 78.02, 92.26, 6.5, 6.5);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 304 Drawing
        oval(context, 78.02, 92.26, 6.5, 6.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 305 Drawing
        oval(context, 89.02, 93.76, 7.7, 7.7);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 306 Drawing
        oval(context, 89.02, 93.76, 7.7, 7.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 961 Drawing
        context.beginPath();
        context.moveTo(96.85, 56.44);
        context.lineTo(89.38, 74.56);
        context.lineTo(96.85, 74.56);
        context.lineTo(104.32, 74.56);
        context.lineTo(96.85, 56.44);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 962 Drawing
        context.beginPath();
        context.moveTo(96.85, 56.44);
        context.lineTo(89.38, 74.56);
        context.lineTo(96.85, 74.56);
        context.lineTo(104.32, 74.56);
        context.lineTo(96.85, 56.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 963 Drawing
        context.beginPath();
        context.moveTo(86.75, 99.75);
        context.bezierCurveTo(86.75, 99.75, 82.36, 99.87, 82.24, 95.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 964 Drawing
        context.beginPath();
        context.moveTo(86.75, 102.49);
        context.bezierCurveTo(86.75, 102.49, 91.5, 102.5, 92, 99);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 307 Drawing
        oval(context, 52.07, 59.41, 7.9, 7.9);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 308 Drawing
        oval(context, 52.07, 59.41, 7.9, 7.9);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 965 Drawing
        context.beginPath();
        context.moveTo(56.01, 81.12);
        context.lineTo(56.01, 63.76);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 309 Drawing
        oval(context, 50.32, 67.56, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 310 Drawing
        oval(context, 50.32, 67.56, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 311 Drawing
        oval(context, 57.47, 68.56, 5, 5);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 312 Drawing
        oval(context, 57.47, 68.56, 5, 5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 966 Drawing
        context.beginPath();
        context.moveTo(56.01, 72.43);
        context.bezierCurveTo(56.01, 72.43, 53.16, 72.51, 53.09, 69.67);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 967 Drawing
        context.beginPath();
        context.moveTo(56.01, 74.2);
        context.bezierCurveTo(56.01, 74.2, 59.09, 74.21, 59.41, 71.94);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 313 Drawing
        oval(context, 113.62, 72.16, 10.3, 10.3);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 314 Drawing
        oval(context, 113.62, 72.16, 10.3, 10.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 968 Drawing
        context.beginPath();
        context.moveTo(118.8, 100.59);
        context.lineTo(118.8, 77.85);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 315 Drawing
        oval(context, 111.37, 82.86, 5.5, 5.5);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 316 Drawing
        oval(context, 111.37, 82.86, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 317 Drawing
        oval(context, 120.72, 84.16, 6.5, 6.5);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Oval 318 Drawing
        oval(context, 120.72, 84.16, 6.5, 6.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 969 Drawing
        context.beginPath();
        context.moveTo(118.8, 89.2);
        context.bezierCurveTo(118.8, 89.2, 115.07, 89.31, 114.97, 85.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 970 Drawing
        context.beginPath();
        context.moveTo(118.8, 91.53);
        context.bezierCurveTo(118.8, 91.53, 122.83, 91.54, 123.25, 88.57);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 971 Drawing
        context.beginPath();
        context.moveTo(96.85, 80.3);
        context.lineTo(96.85, 63.62);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 972 Drawing
        context.beginPath();
        context.moveTo(27.65, 69.64);
        context.lineTo(21.37, 84.86);
        context.lineTo(27.65, 84.86);
        context.lineTo(33.92, 84.86);
        context.lineTo(27.65, 69.64);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 973 Drawing
        context.beginPath();
        context.moveTo(27.65, 69.64);
        context.lineTo(21.37, 84.86);
        context.lineTo(27.65, 84.86);
        context.lineTo(33.92, 84.86);
        context.lineTo(27.65, 69.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 974 Drawing
        context.beginPath();
        context.moveTo(27.65, 89.68);
        context.lineTo(27.65, 75.67);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 35
        context.save();

        //// Clip Clip 35

        context.beginPath();
        context.moveTo(41.56, 1);
        context.bezierCurveTo(39.57, 1, 37.73, 2.06, 36.74, 3.78);
        context.lineTo(36.74, 3.78);
        context.lineTo(2.16, 63.67);
        context.bezierCurveTo(1.17, 65.39, 1.17, 67.52, 2.16, 69.24);
        context.lineTo(2.16, 69.24);
        context.lineTo(36.74, 129.13);
        context.bezierCurveTo(37.73, 130.85, 39.57, 131.91, 41.56, 131.91);
        context.lineTo(41.56, 131.91);
        context.lineTo(110.71, 131.91);
        context.bezierCurveTo(112.7, 131.91, 114.54, 130.85, 115.54, 129.13);
        context.lineTo(115.54, 129.13);
        context.lineTo(150.11, 69.24);
        context.bezierCurveTo(151.11, 67.52, 151.11, 65.39, 150.11, 63.67);
        context.lineTo(150.11, 63.67);
        context.lineTo(115.54, 3.78);
        context.bezierCurveTo(114.54, 2.06, 112.7, 1, 110.71, 1);
        context.lineTo(110.71, 1);
        context.lineTo(41.56, 1);
        context.closePath();
        context.clip();



        //// Bezier 982 Drawing
        context.beginPath();
        context.moveTo(27.65, 52.76);
        context.lineTo(51.83, 9);
        context.lineTo(81.65, 63.62);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 983 Drawing
        context.beginPath();
        context.moveTo(84.5, 62.12);
        context.lineTo(106.67, 26);
        context.lineTo(143.42, 90.66);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 34
        context.save();

        //// Clip Clip 34

        context.beginPath();
        context.moveTo(10.53, 83.74);
        context.lineTo(19.39, 99.09);
        context.lineTo(28.5, 104.5);
        context.lineTo(93.5, 85.33);
        context.lineTo(51.83, 9);
        context.lineTo(10.53, 83.74);
        context.closePath();
        context.clip();



        //// Bezier 979 Drawing
        context.beginPath();
        context.moveTo(38.52, 33.08);
        context.bezierCurveTo(38.52, 33.08, 38.63, 37.68, 42.13, 37.53);
        context.bezierCurveTo(45.63, 37.37, 47.38, 33.08, 47.38, 33.08);
        context.bezierCurveTo(47.95, 32.56, 45.01, 36.97, 49, 40.5);
        context.bezierCurveTo(51.65, 42.84, 54.13, 42.75, 56.75, 41.87);
        context.bezierCurveTo(59.38, 41, 60.13, 39.75, 60, 35.62);
        context.bezierCurveTo(60, 35.62, 64.63, 40.87, 69, 35);
        context.lineTo(67.63, 21.37);
        context.lineTo(55.63, 7.87);
        context.bezierCurveTo(55.63, 7.87, 52.75, -11.88, 46.5, 4.87);
        context.lineTo(40.25, 21.62);
        context.lineTo(38.52, 33.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 980 Drawing
        context.beginPath();
        context.moveTo(38.52, 33.08);
        context.bezierCurveTo(38.52, 33.08, 38.63, 37.68, 42.13, 37.53);
        context.bezierCurveTo(45.63, 37.37, 47.38, 33.08, 47.38, 33.08);
        context.bezierCurveTo(47.95, 32.56, 45.01, 36.97, 49, 40.5);
        context.bezierCurveTo(51.65, 42.84, 54.13, 42.75, 56.75, 41.87);
        context.bezierCurveTo(59.38, 41, 60.13, 39.75, 60, 35.62);
        context.bezierCurveTo(60, 35.62, 64.63, 40.87, 69, 35);
        context.lineTo(67.63, 21.37);
        context.lineTo(55.63, 7.87);
        context.bezierCurveTo(55.63, 7.87, 52.75, -11.88, 46.5, 4.87);
        context.lineTo(40.25, 21.62);
        context.lineTo(38.52, 33.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 985 Drawing
        context.beginPath();
        context.moveTo(110.71, 1);
        context.lineTo(41.56, 1);
        context.bezierCurveTo(39.57, 1, 37.73, 2.06, 36.74, 3.78);
        context.lineTo(2.16, 63.67);
        context.bezierCurveTo(1.17, 65.39, 1.17, 67.52, 2.16, 69.24);
        context.lineTo(36.74, 129.13);
        context.bezierCurveTo(37.73, 130.85, 39.57, 131.91, 41.56, 131.91);
        context.lineTo(110.71, 131.91);
        context.bezierCurveTo(112.7, 131.91, 114.54, 130.85, 115.54, 129.13);
        context.lineTo(150.11, 69.24);
        context.bezierCurveTo(151.11, 67.52, 151.11, 65.39, 150.11, 63.67);
        context.lineTo(115.54, 3.78);
        context.bezierCurveTo(114.54, 2.06, 112.7, 1, 110.71, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 33
        context.save();

        //// Clip Clip 33

        context.beginPath();
        context.moveTo(81.83, 66.46);
        context.lineTo(99.17, 108.33);
        context.lineTo(136.04, 93.61);
        context.lineTo(140.6, 85.71);
        context.lineTo(106.67, 26);
        context.lineTo(81.83, 66.46);
        context.closePath();
        context.clip();



        //// Bezier 976 Drawing
        context.beginPath();
        context.moveTo(92.88, 42.49);
        context.bezierCurveTo(92.88, 42.49, 95.75, 49.37, 100, 46.87);
        context.bezierCurveTo(100, 46.87, 100.88, 53.75, 105.75, 53);
        context.bezierCurveTo(110.62, 52.25, 110.38, 47.77, 110.38, 47.77);
        context.bezierCurveTo(110.38, 47.77, 116.76, 52.24, 117.82, 45.62);
        context.bezierCurveTo(118.88, 39, 119, 34.25, 119, 34.25);
        context.lineTo(111.38, 26);
        context.lineTo(102, 26);
        context.lineTo(99.62, 30);
        context.lineTo(92.88, 42.49);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 977 Drawing
        context.beginPath();
        context.moveTo(92.88, 42.49);
        context.bezierCurveTo(92.88, 42.49, 95.75, 49.37, 100, 46.87);
        context.bezierCurveTo(100, 46.87, 100.88, 53.75, 105.75, 53);
        context.bezierCurveTo(110.62, 52.25, 110.38, 47.77, 110.38, 47.77);
        context.bezierCurveTo(110.38, 47.77, 116.76, 52.24, 117.82, 45.62);
        context.bezierCurveTo(118.88, 39, 119, 34.25, 119, 34.25);
        context.lineTo(111.38, 26);
        context.lineTo(102, 26);
        context.lineTo(99.62, 30);
        context.lineTo(92.88, 42.49);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();
        
        context.restore();

    }

    function drawBlueScarf(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 44, 55), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 44, resizedFrame.h / 55);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// scarfBlue
        //// Bezier 246 Drawing
        context.beginPath();
        context.moveTo(32.84, 13.87);
        context.lineTo(24.94, 40.67);
        context.bezierCurveTo(24.94, 40.67, 14.24, 38.99, 12.75, 38.79);
        context.lineTo(20.3, 11.48);
        context.lineTo(34, 16.68);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 247 Drawing
        context.beginPath();
        context.moveTo(17.69, 7.74);
        context.bezierCurveTo(17.67, 7.77, 17.55, 8.37, 17.37, 9.38);
        context.fillStyle = AvatarStyleKit.col12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 248 Drawing
        context.beginPath();
        context.moveTo(5.31, 0.51);
        context.lineTo(5.3, 0.51);
        context.lineTo(1.95, 11.34);
        context.bezierCurveTo(9.6, 13.27, 15.78, 14.11, 20.7, 14.32);
        context.lineTo(34.56, 12.8);
        context.bezierCurveTo(36.71, 12.06, 38.15, 11.08, 38.15, 11.08);
        context.lineTo(33.94, 1.14);
        context.lineTo(33.93, 1.14);
        context.bezierCurveTo(29.86, 3.73, 25.15, 5.23, 20.1, 5.24);
        context.bezierCurveTo(14.65, 5.23, 9.58, 3.48, 5.31, 0.51);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 249 Drawing
        context.beginPath();
        context.moveTo(5.31, 0.51);
        context.lineTo(5.3, 0.51);
        context.lineTo(1.95, 11.34);
        context.bezierCurveTo(9.6, 13.27, 15.78, 14.11, 20.7, 14.32);
        context.lineTo(34.56, 12.8);
        context.bezierCurveTo(36.71, 12.06, 38.15, 11.08, 38.15, 11.08);
        context.lineTo(33.94, 1.14);
        context.lineTo(33.93, 1.14);
        context.bezierCurveTo(29.86, 3.73, 25.15, 5.23, 20.1, 5.24);
        context.bezierCurveTo(14.65, 5.23, 9.58, 3.48, 5.31, 0.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Group 2
        context.save();

        //// Clip Clip 2

        context.beginPath();
        context.moveTo(20.1, 5.24);
        context.bezierCurveTo(19.97, 5.24, 19.84, 5.23, 19.72, 5.23);
        context.lineTo(19.72, 5.23);
        context.lineTo(19.39, 20.62);
        context.lineTo(20.14, 53.38);
        context.lineTo(42.98, 51.57);
        context.lineTo(31.89, 2.32);
        context.bezierCurveTo(28.32, 4.18, 24.32, 5.23, 20.1, 5.24);
        context.closePath();
        context.clip();



        //// Rectangle 3 Drawing
        context.beginPath();
        context.rect(1, 46.55, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Rectangle 4 Drawing
        context.beginPath();
        context.rect(1, 37.1, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Rectangle 5 Drawing
        context.beginPath();
        context.rect(1, 27.65, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Rectangle 6 Drawing
        context.beginPath();
        context.rect(1, 18.15, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Rectangle 7 Drawing
        context.beginPath();
        context.rect(1, 8.7, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Rectangle 8 Drawing
        context.beginPath();
        context.rect(1, -0.75, 56.65, 9.45);
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        context.restore();



        //// Bezier 251 Drawing
        context.beginPath();
        context.moveTo(31.9, 2.32);
        context.bezierCurveTo(28.32, 4.18, 24.33, 5.23, 20.1, 5.24);
        context.bezierCurveTo(19.97, 5.24, 19.85, 5.23, 19.72, 5.23);
        context.lineTo(19.4, 20.62);
        context.lineTo(20.14, 53.38);
        context.lineTo(42.98, 51.58);
        context.lineTo(31.9, 2.32);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();
        
        context.restore();

    }

    function drawForestBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor41 = 'rgba(168, 131, 102, 1)';

        //// Group 40
        context.save();

        //// Clip Clip 40

        context.beginPath();
        context.moveTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.65, 129.21);
        context.lineTo(115.65, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.21, 67.6, 151.21, 65.48, 150.22, 63.75);
        context.lineTo(150.22, 63.75);
        context.lineTo(115.65, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.lineTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.closePath();
        context.clip();



        //// Bezier 1043 Drawing
        context.beginPath();
        context.moveTo(-5, 62.58);
        context.lineTo(-1, 70.74);
        context.bezierCurveTo(-1, 70.74, 11, 74.84, 13.17, 75.04);
        context.bezierCurveTo(15.33, 75.24, 58.76, 80.41, 58.76, 80.41);
        context.lineTo(96.17, 91.74);
        context.lineTo(118.67, 87.91);
        context.lineTo(146.5, 82.24);
        context.lineTo(158, 79.08);
        context.lineTo(160.5, 63.24);
        context.lineTo(147.33, 38.74);
        context.lineTo(127.25, 25.91);
        context.bezierCurveTo(127.25, 25.91, 163.33, 27.91, 108.17, 25.91);
        context.lineTo(53, 23.91);
        context.lineTo(26.33, 25.08);
        context.lineTo(13.33, 32.41);
        context.lineTo(2.33, 49.08);
        context.lineTo(-5, 62.58);
        context.closePath();
        context.fillStyle = AvatarStyleKit.skin9;
        context.fill();


        //// Bezier 1044 Drawing
        context.beginPath();
        context.moveTo(-5, 62.58);
        context.lineTo(-1, 70.74);
        context.bezierCurveTo(-1, 70.74, 11, 74.84, 13.17, 75.04);
        context.bezierCurveTo(15.33, 75.24, 58.76, 80.41, 58.76, 80.41);
        context.lineTo(96.17, 91.74);
        context.lineTo(118.67, 87.91);
        context.lineTo(146.5, 82.24);
        context.lineTo(158, 79.08);
        context.lineTo(160.5, 63.24);
        context.lineTo(147.33, 38.74);
        context.lineTo(127.25, 25.91);
        context.bezierCurveTo(127.25, 25.91, 163.33, 27.91, 108.17, 25.91);
        context.lineTo(53, 23.91);
        context.lineTo(26.33, 25.08);
        context.lineTo(13.33, 32.41);
        context.lineTo(2.33, 49.08);
        context.lineTo(-5, 62.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1045 Drawing
        context.beginPath();
        context.moveTo(18.17, 31.58);
        context.bezierCurveTo(19.66, 31.16, 24.86, 19.77, 28, 31.58);
        context.bezierCurveTo(29.94, 38.85, 34.55, 43.52, 43.5, 39.04);
        context.bezierCurveTo(46.08, 38.12, 49.24, 37.25, 48, 32.08);
        context.bezierCurveTo(46.68, 26.56, 57.17, 39.25, 61.17, 39.66);
        context.bezierCurveTo(65.17, 40.08, 71, 33.58, 74.5, 33.41);
        context.bezierCurveTo(78, 33.25, 74.5, 35.37, 81.17, 35.98);
        context.bezierCurveTo(87.83, 36.58, 85.9, 36.09, 89.92, 37.16);
        context.bezierCurveTo(93.93, 38.24, 94.05, 36.62, 95.67, 37.41);
        context.bezierCurveTo(100.71, 39.87, 108.84, 30.51, 110.33, 33.08);
        context.bezierCurveTo(113.47, 38.47, 115.5, 38.75, 119.67, 38.58);
        context.bezierCurveTo(123.83, 38.41, 126.33, 32.91, 133, 32.91);
        context.bezierCurveTo(139.67, 32.91, 136.17, 34.75, 143.17, 34.25);
        context.bezierCurveTo(150.17, 33.75, 153.5, 26.41, 153.5, 26.41);
        context.lineTo(166.83, 6.25);
        context.lineTo(131.67, -16.09);
        context.lineTo(106, -14.09);
        context.lineTo(66.17, -11.42);
        context.lineTo(15.54, -6.84);
        context.lineTo(18.17, 31.58);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 1046 Drawing
        context.beginPath();
        context.moveTo(18.17, 31.58);
        context.bezierCurveTo(19.66, 31.16, 24.86, 19.77, 28, 31.58);
        context.bezierCurveTo(29.94, 38.85, 34.55, 43.52, 43.5, 39.04);
        context.bezierCurveTo(46.08, 38.12, 49.24, 37.25, 48, 32.08);
        context.bezierCurveTo(46.68, 26.56, 57.17, 39.25, 61.17, 39.66);
        context.bezierCurveTo(65.17, 40.08, 71, 33.58, 74.5, 33.41);
        context.bezierCurveTo(78, 33.25, 74.5, 35.37, 81.17, 35.98);
        context.bezierCurveTo(87.83, 36.58, 85.9, 36.09, 89.92, 37.16);
        context.bezierCurveTo(93.93, 38.24, 94.05, 36.62, 95.67, 37.41);
        context.bezierCurveTo(100.71, 39.87, 108.84, 30.51, 110.33, 33.08);
        context.bezierCurveTo(113.47, 38.47, 115.5, 38.75, 119.67, 38.58);
        context.bezierCurveTo(123.83, 38.41, 126.33, 32.91, 133, 32.91);
        context.bezierCurveTo(139.67, 32.91, 136.17, 34.75, 143.17, 34.25);
        context.bezierCurveTo(150.17, 33.75, 153.5, 26.41, 153.5, 26.41);
        context.lineTo(166.83, 6.25);
        context.lineTo(131.67, -16.09);
        context.lineTo(106, -14.09);
        context.lineTo(66.17, -11.42);
        context.lineTo(15.54, -6.84);
        context.lineTo(18.17, 31.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1047 Drawing
        context.beginPath();
        context.moveTo(24.17, 1.08);
        context.bezierCurveTo(24.33, 1.08, 27.67, 49.41, 19.33, 68.74);
        context.lineTo(35, 75.04);
        context.lineTo(43.5, 66.54);
        context.bezierCurveTo(43.5, 66.54, 38.83, 6.91, 42.33, 0.24);
        context.bezierCurveTo(45.83, -6.42, 38.17, -4.42, 38.17, -4.42);
        context.lineTo(24.17, 1.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.skin8;
        context.fill();


        //// Bezier 1048 Drawing
        context.beginPath();
        context.moveTo(24.17, 1.08);
        context.bezierCurveTo(24.33, 1.08, 27.67, 49.41, 19.33, 68.74);
        context.lineTo(35, 75.04);
        context.lineTo(43.5, 66.54);
        context.bezierCurveTo(43.5, 66.54, 38.83, 6.91, 42.33, 0.24);
        context.bezierCurveTo(45.83, -6.42, 38.17, -4.42, 38.17, -4.42);
        context.lineTo(24.17, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1049 Drawing
        context.beginPath();
        context.moveTo(56.67, -1.26);
        context.bezierCurveTo(56.67, -0.76, 58.5, 51.17, 52, 66.54);
        context.lineTo(59.33, 81.58);
        context.lineTo(78.67, 76.08);
        context.bezierCurveTo(78.67, 76.08, 69.17, 10.58, 73.67, -2.42);
        context.lineTo(56.67, -1.26);
        context.closePath();
        context.fillStyle = AvatarStyleKit.skin6;
        context.fill();


        //// Bezier 1050 Drawing
        context.beginPath();
        context.moveTo(56.67, -1.26);
        context.bezierCurveTo(56.67, -0.76, 58.5, 51.17, 52, 66.54);
        context.lineTo(59.33, 81.58);
        context.lineTo(78.67, 76.08);
        context.bezierCurveTo(78.67, 76.08, 69.17, 10.58, 73.67, -2.42);
        context.lineTo(56.67, -1.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1051 Drawing
        context.beginPath();
        context.moveTo(91.17, -1.09);
        context.bezierCurveTo(91.17, -0.59, 92.67, 65.58, 84.17, 80.41);
        context.lineTo(112.83, 78.41);
        context.bezierCurveTo(112.83, 78.41, 100.17, 13.08, 108.17, -1.09);
        context.lineTo(91.17, -1.09);
        context.closePath();
        context.fillStyle = fillColor41;
        context.fill();


        //// Bezier 1052 Drawing
        context.beginPath();
        context.moveTo(91.17, -1.09);
        context.bezierCurveTo(91.17, -0.59, 92.67, 65.58, 84.17, 80.41);
        context.lineTo(112.83, 78.41);
        context.bezierCurveTo(112.83, 78.41, 100.17, 13.08, 108.17, -1.09);
        context.lineTo(91.17, -1.09);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1053 Drawing
        context.beginPath();
        context.moveTo(20.33, 72.41);
        context.bezierCurveTo(20.56, 70.89, 28.61, 77.29, 36.17, 67.41);
        context.bezierCurveTo(37.88, 65.17, 43.52, 60.81, 43.5, 69.84);
        context.bezierCurveTo(43.5, 72.03, 43.79, 67.8, 47.29, 66.54);
        context.bezierCurveTo(50.79, 65.28, 53.83, 68.08, 56.33, 68.41);
        context.bezierCurveTo(58.83, 68.74, 61.5, 69.24, 62.67, 71.41);
        context.bezierCurveTo(63.83, 73.58, 66.01, 78.75, 67.5, 76.74);
        context.bezierCurveTo(70.56, 72.63, 83.79, 59.58, 84.17, 76.66);
        context.bezierCurveTo(84.25, 80.24, 84.92, 71.74, 90.92, 71.41);
        context.bezierCurveTo(96.92, 71.08, 107, 82.58, 107, 82.58);
        context.bezierCurveTo(105.99, 80.58, 119.38, 65.12, 128.17, 72.24);
        context.bezierCurveTo(131.92, 75.29, 134.17, 69.08, 138.67, 69.41);
        context.bezierCurveTo(143.17, 69.74, 142, 66.41, 147.17, 68.74);
        context.bezierCurveTo(152.33, 71.08, 156.33, 73.74, 156.67, 75.91);
        context.bezierCurveTo(157, 78.08, 150.97, 87.41, 150.97, 87.41);
        context.lineTo(136.14, 93.71);
        context.lineTo(98.5, 96.58);
        context.bezierCurveTo(98.5, 96.58, 60.33, 100.74, 59.17, 100.74);
        context.bezierCurveTo(58, 100.74, 16.82, 94.53, 16.82, 94.53);
        context.lineTo(4.33, 93.16);
        context.lineTo(1.52, 85.24);
        context.lineTo(20.33, 72.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 1054 Drawing
        context.beginPath();
        context.moveTo(20.33, 72.41);
        context.bezierCurveTo(20.56, 70.89, 28.61, 77.29, 36.17, 67.41);
        context.bezierCurveTo(37.88, 65.17, 43.52, 60.81, 43.5, 69.84);
        context.bezierCurveTo(43.5, 72.03, 43.79, 67.8, 47.29, 66.54);
        context.bezierCurveTo(50.79, 65.28, 53.83, 68.08, 56.33, 68.41);
        context.bezierCurveTo(58.83, 68.74, 61.5, 69.24, 62.67, 71.41);
        context.bezierCurveTo(63.83, 73.58, 66.01, 78.75, 67.5, 76.74);
        context.bezierCurveTo(70.56, 72.63, 83.79, 59.58, 84.17, 76.66);
        context.bezierCurveTo(84.25, 80.24, 84.92, 71.74, 90.92, 71.41);
        context.bezierCurveTo(96.92, 71.08, 107, 82.58, 107, 82.58);
        context.bezierCurveTo(105.99, 80.58, 119.38, 65.12, 128.17, 72.24);
        context.bezierCurveTo(131.92, 75.29, 134.17, 69.08, 138.67, 69.41);
        context.bezierCurveTo(143.17, 69.74, 142, 66.41, 147.17, 68.74);
        context.bezierCurveTo(152.33, 71.08, 156.33, 73.74, 156.67, 75.91);
        context.bezierCurveTo(157, 78.08, 150.97, 87.41, 150.97, 87.41);
        context.lineTo(136.14, 93.71);
        context.lineTo(98.5, 96.58);
        context.bezierCurveTo(98.5, 96.58, 60.33, 100.74, 59.17, 100.74);
        context.bezierCurveTo(58, 100.74, 16.82, 94.53, 16.82, 94.53);
        context.lineTo(4.33, 93.16);
        context.lineTo(1.52, 85.24);
        context.lineTo(20.33, 72.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1055 Drawing
        context.beginPath();
        context.moveTo(118.33, 0.08);
        context.bezierCurveTo(118.33, 1.08, 119, 68.29, 115.83, 72.91);
        context.lineTo(138.67, 75.04);
        context.bezierCurveTo(138.67, 75.04, 130.17, 5.41, 137.5, -3.09);
        context.lineTo(118.33, 0.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.skin8;
        context.fill();


        //// Bezier 1056 Drawing
        context.beginPath();
        context.moveTo(118.33, 0.08);
        context.bezierCurveTo(118.33, 1.08, 119, 68.29, 115.83, 72.91);
        context.lineTo(138.67, 75.04);
        context.bezierCurveTo(138.67, 75.04, 130.17, 5.41, 137.5, -3.09);
        context.lineTo(118.33, 0.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1057 Drawing
        context.beginPath();
        context.moveTo(0.83, 71.74);
        context.bezierCurveTo(0.83, 71.74, 2.67, 65, 8.33, 66.54);
        context.bezierCurveTo(14, 68.08, 14.17, 69.81, 16.17, 69.03);
        context.bezierCurveTo(18.17, 68.24, 19.17, 66.74, 24, 67.74);
        context.bezierCurveTo(28.83, 68.74, 32.33, 71.24, 34.5, 72.58);
        context.bezierCurveTo(36.67, 73.91, 39.67, 73.58, 40, 77.08);
        context.bezierCurveTo(40.33, 80.58, 38, 81.41, 42, 82.91);
        context.bezierCurveTo(46, 84.41, 47.5, 84.58, 47.67, 86.24);
        context.bezierCurveTo(47.83, 87.91, 48.17, 88.41, 51, 84.24);
        context.bezierCurveTo(53.83, 80.08, 54, 81.08, 56.83, 80.41);
        context.bezierCurveTo(59.67, 79.74, 59.17, 80.08, 62.67, 77.74);
        context.bezierCurveTo(66.17, 75.41, 66.26, 79.82, 68.5, 75.04);
        context.bezierCurveTo(72.64, 66.21, 72.25, 78.46, 75.17, 78.74);
        context.bezierCurveTo(79.33, 79.15, 77.99, 77.88, 80.04, 80.41);
        context.bezierCurveTo(81.85, 82.64, 80.52, 85.78, 83, 85.74);
        context.bezierCurveTo(85.72, 85.71, 85.66, 86.7, 85, 88.58);
        context.bezierCurveTo(84.72, 89.36, 88.33, 90.24, 89.5, 86.41);
        context.bezierCurveTo(90.67, 82.58, 92.61, 82.21, 90.33, 79.91);
        context.bezierCurveTo(87.79, 77.35, 87.89, 75.51, 93.17, 75.04);
        context.bezierCurveTo(97.49, 74.65, 97.33, 79.17, 98.5, 75.04);
        context.bezierCurveTo(99.67, 70.91, 95.83, 67.58, 103.67, 67.91);
        context.bezierCurveTo(111.5, 68.24, 108.5, 71.58, 111.33, 70.91);
        context.bezierCurveTo(114.17, 70.24, 114.12, 66.86, 119.46, 68.03);
        context.bezierCurveTo(124.79, 69.2, 121.83, 70.41, 123.33, 72.74);
        context.bezierCurveTo(124.83, 75.08, 124.33, 75.84, 125.17, 77.62);
        context.bezierCurveTo(126, 79.41, 126, 78.24, 128.5, 80.41);
        context.bezierCurveTo(131, 82.58, 130.67, 83.41, 131, 84.91);
        context.bezierCurveTo(131.33, 86.41, 131, 89.3, 134.33, 88.94);
        context.bezierCurveTo(137.67, 88.58, 137.56, 88.23, 139.78, 87.41);
        context.bezierCurveTo(142, 86.58, 150.42, 87.49, 150.42, 87.49);
        context.lineTo(150.42, 94.49);
        context.lineTo(138.67, 103.24);
        context.lineTo(89.33, 105.41);
        context.bezierCurveTo(89.33, 105.41, 66, 106.41, 64.33, 106.24);
        context.bezierCurveTo(62.67, 106.08, 38, 103.91, 37, 103.91);
        context.bezierCurveTo(36, 103.91, 14, 100.41, 14, 100.41);
        context.lineTo(4.17, 99.58);
        context.lineTo(0.83, 71.74);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 1058 Drawing
        context.beginPath();
        context.moveTo(0.83, 71.74);
        context.bezierCurveTo(0.83, 71.74, 2.67, 65, 8.33, 66.54);
        context.bezierCurveTo(14, 68.08, 14.17, 69.81, 16.17, 69.03);
        context.bezierCurveTo(18.17, 68.24, 19.17, 66.74, 24, 67.74);
        context.bezierCurveTo(28.83, 68.74, 32.33, 71.24, 34.5, 72.58);
        context.bezierCurveTo(36.67, 73.91, 39.67, 73.58, 40, 77.08);
        context.bezierCurveTo(40.33, 80.58, 38, 81.41, 42, 82.91);
        context.bezierCurveTo(46, 84.41, 47.5, 84.58, 47.67, 86.24);
        context.bezierCurveTo(47.83, 87.91, 48.17, 88.41, 51, 84.24);
        context.bezierCurveTo(53.83, 80.08, 54, 81.08, 56.83, 80.41);
        context.bezierCurveTo(59.67, 79.74, 59.17, 80.08, 62.67, 77.74);
        context.bezierCurveTo(66.17, 75.41, 66.26, 79.82, 68.5, 75.04);
        context.bezierCurveTo(72.64, 66.21, 72.25, 78.46, 75.17, 78.74);
        context.bezierCurveTo(79.33, 79.15, 77.99, 77.88, 80.04, 80.41);
        context.bezierCurveTo(81.85, 82.64, 80.52, 85.78, 83, 85.74);
        context.bezierCurveTo(85.72, 85.71, 85.66, 86.7, 85, 88.58);
        context.bezierCurveTo(84.72, 89.36, 88.33, 90.24, 89.5, 86.41);
        context.bezierCurveTo(90.67, 82.58, 92.61, 82.21, 90.33, 79.91);
        context.bezierCurveTo(87.79, 77.35, 87.89, 75.51, 93.17, 75.04);
        context.bezierCurveTo(97.49, 74.65, 97.33, 79.17, 98.5, 75.04);
        context.bezierCurveTo(99.67, 70.91, 95.83, 67.58, 103.67, 67.91);
        context.bezierCurveTo(111.5, 68.24, 108.5, 71.58, 111.33, 70.91);
        context.bezierCurveTo(114.17, 70.24, 114.12, 66.86, 119.46, 68.03);
        context.bezierCurveTo(124.79, 69.2, 121.83, 70.41, 123.33, 72.74);
        context.bezierCurveTo(124.83, 75.08, 124.33, 75.84, 125.17, 77.62);
        context.bezierCurveTo(126, 79.41, 126, 78.24, 128.5, 80.41);
        context.bezierCurveTo(131, 82.58, 130.67, 83.41, 131, 84.91);
        context.bezierCurveTo(131.33, 86.41, 131, 89.3, 134.33, 88.94);
        context.bezierCurveTo(137.67, 88.58, 137.56, 88.23, 139.78, 87.41);
        context.bezierCurveTo(142, 86.58, 150.42, 87.49, 150.42, 87.49);
        context.lineTo(150.42, 94.49);
        context.lineTo(138.67, 103.24);
        context.lineTo(89.33, 105.41);
        context.bezierCurveTo(89.33, 105.41, 66, 106.41, 64.33, 106.24);
        context.bezierCurveTo(62.67, 106.08, 38, 103.91, 37, 103.91);
        context.bezierCurveTo(36, 103.91, 14, 100.41, 14, 100.41);
        context.lineTo(4.17, 99.58);
        context.lineTo(0.83, 71.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1059 Drawing
        context.beginPath();
        context.moveTo(10.17, 93.16);
        context.lineTo(149.92, 93.16);
        context.lineTo(149.92, 115.91);
        context.bezierCurveTo(149.92, 115.91, 122.42, 145.91, 120.92, 146.16);
        context.bezierCurveTo(119.42, 146.41, 85.42, 149, 85.42, 149);
        context.lineTo(46.67, 141.91);
        context.bezierCurveTo(46.67, 141.91, 21.67, 111.91, 21.17, 111.16);
        context.bezierCurveTo(20.67, 110.41, 10.17, 93.16, 10.17, 93.16);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 1060 Drawing
        context.beginPath();
        context.moveTo(10.17, 93.16);
        context.lineTo(149.92, 93.16);
        context.lineTo(149.92, 115.91);
        context.bezierCurveTo(149.92, 115.91, 122.42, 145.91, 120.92, 146.16);
        context.bezierCurveTo(119.42, 146.41, 85.42, 149, 85.42, 149);
        context.lineTo(46.67, 141.91);
        context.bezierCurveTo(46.67, 141.91, 21.67, 111.91, 21.17, 111.16);
        context.bezierCurveTo(20.67, 110.41, 10.17, 93.16, 10.17, 93.16);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground7(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 388 Drawing
        context.beginPath();
        context.moveTo(111.47, 0.99);
        context.lineTo(42.31, 0.99);
        context.bezierCurveTo(40.32, 0.99, 38.49, 2.05, 37.49, 3.77);
        context.lineTo(2.91, 63.66);
        context.bezierCurveTo(1.92, 65.39, 1.92, 67.51, 2.91, 69.23);
        context.lineTo(37.49, 129.12);
        context.bezierCurveTo(38.49, 130.84, 40.32, 131.91, 42.31, 131.91);
        context.lineTo(111.47, 131.91);
        context.bezierCurveTo(113.46, 131.91, 115.3, 130.84, 116.29, 129.12);
        context.lineTo(150.87, 69.23);
        context.bezierCurveTo(151.86, 67.51, 151.86, 65.39, 150.87, 63.66);
        context.lineTo(116.29, 3.77);
        context.bezierCurveTo(115.3, 2.05, 113.46, 0.99, 111.47, 0.99);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Group 7
        context.save();

        //// Clip Clip 7

        context.beginPath();
        context.moveTo(42.42, 1);
        context.bezierCurveTo(40.42, 1, 38.58, 2.06, 37.58, 3.78);
        context.lineTo(37.58, 3.78);
        context.lineTo(2.92, 63.71);
        context.bezierCurveTo(1.92, 65.44, 1.92, 67.56, 2.92, 69.29);
        context.lineTo(2.92, 69.29);
        context.lineTo(37.58, 129.21);
        context.bezierCurveTo(38.58, 130.94, 40.42, 132, 42.42, 132);
        context.lineTo(42.42, 132);
        context.lineTo(111.75, 132);
        context.bezierCurveTo(113.74, 132, 115.59, 130.94, 116.59, 129.21);
        context.lineTo(116.59, 129.21);
        context.lineTo(151.25, 69.29);
        context.bezierCurveTo(152.25, 67.56, 152.25, 65.44, 151.25, 63.71);
        context.lineTo(151.25, 63.71);
        context.lineTo(116.59, 3.78);
        context.bezierCurveTo(115.59, 2.06, 113.74, 1, 111.75, 1);
        context.lineTo(111.75, 1);
        context.lineTo(42.42, 1);
        context.closePath();
        context.clip();



        //// Oval 98 Drawing
        oval(context, 24.6, -6.74, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 99 Drawing
        oval(context, 71.9, 16.56, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 100 Drawing
        oval(context, 122.25, 50.91, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 101 Drawing
        oval(context, 71.6, 75.11, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 102 Drawing
        oval(context, 15.15, 108.16, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 103 Drawing
        oval(context, -13.3, 48.61, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 104 Drawing
        oval(context, 32.35, 43.56, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 105 Drawing
        oval(context, 90.65, 118.91, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Oval 106 Drawing
        oval(context, 101.65, -20.94, 35.7, 35.7);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        context.restore();



        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPirateHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 96, 48), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 96, resizedFrame.h / 48);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var strokeColor16 = 'rgba(255, 255, 255, 1)';

        //// Group
        //// Bezier 1978 Drawing
        context.beginPath();
        context.moveTo(82.59, 27.74);
        context.lineTo(93.67, 25.37);
        context.bezierCurveTo(93.67, 25.37, 101.53, 15.9, 78.06, 20.32);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1979 Drawing
        context.beginPath();
        context.moveTo(2.26, 25.37);
        context.bezierCurveTo(2.26, 25.37, -5.59, 15.9, 17.88, 20.32);
        context.bezierCurveTo(41.35, 24.74, 26.6, 24.33, 26.6, 24.33);
        context.lineTo(13.35, 27.74);
        context.lineTo(2.26, 25.37);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 1980 Drawing
        context.beginPath();
        context.moveTo(2.26, 25.37);
        context.bezierCurveTo(2.26, 25.37, -5.59, 15.9, 17.88, 20.32);
        context.bezierCurveTo(41.35, 24.74, 26.6, 24.33, 26.6, 24.33);
        context.lineTo(13.35, 27.74);
        context.lineTo(2.26, 25.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1981 Drawing
        context.beginPath();
        context.moveTo(91.25, 25.25);
        context.bezierCurveTo(89.66, 25.25, 80.25, 24.92, 69.75, 12.33);
        context.bezierCurveTo(60.67, 1.45, 50.54, 0.85, 47.96, 0.89);
        context.bezierCurveTo(45.39, 0.85, 35.26, 1.45, 26.18, 12.33);
        context.bezierCurveTo(15.68, 24.92, 6.26, 25.25, 4.68, 25.25);
        context.lineTo(2.26, 25.25);
        context.lineTo(12.6, 43.17);
        context.bezierCurveTo(12.6, 43.17, 13.93, 46.08, 24.26, 46);
        context.bezierCurveTo(34.11, 45.92, 46.74, 45.99, 47.91, 46);
        context.lineTo(47.91, 46);
        context.lineTo(47.96, 46);
        context.lineTo(48.01, 46);
        context.lineTo(48.01, 46);
        context.bezierCurveTo(49.19, 45.99, 61.82, 45.92, 71.66, 46);
        context.bezierCurveTo(82, 46.08, 83.33, 43.17, 83.33, 43.17);
        context.lineTo(93.66, 25.25);
        context.lineTo(91.25, 25.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 1982 Drawing
        context.beginPath();
        context.moveTo(91.25, 25.25);
        context.bezierCurveTo(89.66, 25.25, 80.25, 24.92, 69.75, 12.33);
        context.bezierCurveTo(60.67, 1.45, 50.54, 0.85, 47.96, 0.89);
        context.bezierCurveTo(45.39, 0.85, 35.26, 1.45, 26.18, 12.33);
        context.bezierCurveTo(15.68, 24.92, 6.26, 25.25, 4.68, 25.25);
        context.lineTo(2.26, 25.25);
        context.lineTo(12.6, 43.17);
        context.bezierCurveTo(12.6, 43.17, 13.93, 46.08, 24.26, 46);
        context.bezierCurveTo(34.11, 45.92, 46.74, 45.99, 47.91, 46);
        context.lineTo(47.91, 46);
        context.lineTo(47.96, 46);
        context.lineTo(48.01, 46);
        context.lineTo(48.01, 46);
        context.bezierCurveTo(49.19, 45.99, 61.82, 45.92, 71.66, 46);
        context.bezierCurveTo(82, 46.08, 83.33, 43.17, 83.33, 43.17);
        context.lineTo(93.66, 25.25);
        context.lineTo(91.25, 25.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1983 Drawing
        context.beginPath();
        context.moveTo(89.97, 31.93);
        context.lineTo(87.75, 31.93);
        context.bezierCurveTo(86.29, 31.93, 77.64, 31.62, 67.99, 20.06);
        context.bezierCurveTo(59.64, 10.06, 50.33, 9.51, 47.96, 9.54);
        context.bezierCurveTo(45.6, 9.51, 36.29, 10.06, 27.94, 20.06);
        context.bezierCurveTo(18.29, 31.62, 9.64, 31.93, 8.18, 31.93);
        context.lineTo(5.96, 31.93);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1984 Drawing
        context.beginPath();
        context.moveTo(91.25, 25.25);
        context.bezierCurveTo(89.66, 25.25, 80.25, 24.92, 69.75, 12.33);
        context.bezierCurveTo(60.67, 1.45, 50.54, 0.85, 47.96, 0.89);
        context.bezierCurveTo(45.39, 0.85, 35.26, 1.45, 26.18, 12.33);
        context.bezierCurveTo(15.68, 24.92, 6.26, 25.25, 4.68, 25.25);
        context.lineTo(2.26, 25.25);
        context.lineTo(12.6, 43.17);
        context.bezierCurveTo(12.6, 43.17, 13.93, 46.08, 24.26, 46);
        context.bezierCurveTo(34.11, 45.92, 46.74, 45.99, 47.91, 46);
        context.lineTo(47.91, 46);
        context.lineTo(47.96, 46);
        context.lineTo(48.01, 46);
        context.lineTo(48.01, 46);
        context.bezierCurveTo(49.19, 45.99, 61.82, 45.92, 71.66, 46);
        context.bezierCurveTo(82, 46.08, 83.33, 43.17, 83.33, 43.17);
        context.lineTo(93.66, 25.25);
        context.lineTo(91.25, 25.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1985 Drawing
        context.beginPath();
        context.moveTo(54.52, 25.43);
        context.bezierCurveTo(54.73, 17.76, 48.16, 18.02, 48.16, 18.02);
        context.bezierCurveTo(48.16, 18.02, 41.59, 17.76, 41.8, 25.43);
        context.bezierCurveTo(41.8, 25.43, 41.53, 27.8, 42.85, 27.74);
        context.lineTo(44.11, 27.74);
        context.lineTo(44.11, 30);
        context.bezierCurveTo(44.11, 30, 45.59, 30.89, 48.16, 30.79);
        context.bezierCurveTo(50.73, 30.89, 52.2, 30, 52.2, 30);
        context.lineTo(52.2, 27.74);
        context.lineTo(53.46, 27.74);
        context.bezierCurveTo(54.78, 27.8, 54.52, 25.43, 54.52, 25.43);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 1986 Drawing
        context.beginPath();
        context.moveTo(54.52, 25.43);
        context.bezierCurveTo(54.73, 17.76, 48.16, 18.02, 48.16, 18.02);
        context.bezierCurveTo(48.16, 18.02, 41.59, 17.76, 41.8, 25.43);
        context.bezierCurveTo(41.8, 25.43, 41.53, 27.8, 42.85, 27.74);
        context.lineTo(44.11, 27.74);
        context.lineTo(44.11, 30);
        context.bezierCurveTo(44.11, 30, 45.59, 30.89, 48.16, 30.79);
        context.bezierCurveTo(50.73, 30.89, 52.2, 30, 52.2, 30);
        context.lineTo(52.2, 27.74);
        context.lineTo(53.46, 27.74);
        context.bezierCurveTo(54.78, 27.8, 54.52, 25.43, 54.52, 25.43);
        context.closePath();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 619 Drawing
        oval(context, 44.85, 21.95, 2.2, 2.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 620 Drawing
        oval(context, 49, 21.95, 2.2, 2.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 1987 Drawing
        context.beginPath();
        context.moveTo(40.12, 38.88);
        context.lineTo(55.82, 32.47);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1988 Drawing
        context.beginPath();
        context.moveTo(55.79, 38.96);
        context.lineTo(40.15, 32.39);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground6(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 388 Drawing
        context.beginPath();
        context.moveTo(111.47, 0.99);
        context.lineTo(42.31, 0.99);
        context.bezierCurveTo(40.32, 0.99, 38.49, 2.05, 37.49, 3.77);
        context.lineTo(2.91, 63.66);
        context.bezierCurveTo(1.92, 65.39, 1.92, 67.51, 2.91, 69.23);
        context.lineTo(37.49, 129.12);
        context.bezierCurveTo(38.49, 130.84, 40.32, 131.91, 42.31, 131.91);
        context.lineTo(111.47, 131.91);
        context.bezierCurveTo(113.46, 131.91, 115.3, 130.84, 116.29, 129.12);
        context.lineTo(150.87, 69.23);
        context.bezierCurveTo(151.86, 67.51, 151.86, 65.39, 150.87, 63.66);
        context.lineTo(116.29, 3.77);
        context.bezierCurveTo(115.3, 2.05, 113.46, 0.99, 111.47, 0.99);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Group 7
        context.save();

        //// Clip Clip 7

        context.beginPath();
        context.moveTo(42.42, 1);
        context.bezierCurveTo(40.42, 1, 38.58, 2.06, 37.58, 3.78);
        context.lineTo(37.58, 3.78);
        context.lineTo(2.92, 63.71);
        context.bezierCurveTo(1.92, 65.44, 1.92, 67.56, 2.92, 69.29);
        context.lineTo(2.92, 69.29);
        context.lineTo(37.58, 129.21);
        context.bezierCurveTo(38.58, 130.94, 40.42, 132, 42.42, 132);
        context.lineTo(42.42, 132);
        context.lineTo(111.75, 132);
        context.bezierCurveTo(113.74, 132, 115.59, 130.94, 116.59, 129.21);
        context.lineTo(116.59, 129.21);
        context.lineTo(151.25, 69.29);
        context.bezierCurveTo(152.25, 67.56, 152.25, 65.44, 151.25, 63.71);
        context.lineTo(151.25, 63.71);
        context.lineTo(116.59, 3.78);
        context.bezierCurveTo(115.59, 2.06, 113.74, 1, 111.75, 1);
        context.lineTo(111.75, 1);
        context.lineTo(42.42, 1);
        context.closePath();
        context.clip();



        //// Oval 2 Drawing
        oval(context, 104, -2, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval Drawing
        oval(context, 74, -2, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 4 Drawing
        oval(context, 44, -2, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 3 Drawing
        oval(context, 89, 22, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 5 Drawing
        oval(context, 119, 22, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 6 Drawing
        oval(context, 29, 22, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 7 Drawing
        oval(context, 59, 22, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 8 Drawing
        oval(context, 104, 47, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 9 Drawing
        oval(context, 134, 47, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 10 Drawing
        oval(context, 44, 47, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 11 Drawing
        oval(context, 74, 47, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 12 Drawing
        oval(context, 14, 47, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 13 Drawing
        oval(context, 89, 72, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 14 Drawing
        oval(context, 119, 72, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 15 Drawing
        oval(context, 29, 72, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 16 Drawing
        oval(context, 59, 72, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 17 Drawing
        oval(context, 104, 97, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 18 Drawing
        oval(context, 44, 97, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 19 Drawing
        oval(context, 74, 97, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 20 Drawing
        oval(context, -0, 73, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 21 Drawing
        oval(context, 15, 98, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 22 Drawing
        oval(context, 90, 123, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 23 Drawing
        oval(context, 30, 123, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Oval 24 Drawing
        oval(context, 60, 123, 19, 19);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        context.restore();



        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground8(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 388 Drawing
        context.beginPath();
        context.moveTo(111.47, 0.99);
        context.lineTo(42.31, 0.99);
        context.bezierCurveTo(40.32, 0.99, 38.49, 2.05, 37.49, 3.77);
        context.lineTo(2.91, 63.66);
        context.bezierCurveTo(1.92, 65.39, 1.92, 67.51, 2.91, 69.23);
        context.lineTo(37.49, 129.12);
        context.bezierCurveTo(38.49, 130.84, 40.32, 131.91, 42.31, 131.91);
        context.lineTo(111.47, 131.91);
        context.bezierCurveTo(113.46, 131.91, 115.3, 130.84, 116.29, 129.12);
        context.lineTo(150.87, 69.23);
        context.bezierCurveTo(151.86, 67.51, 151.86, 65.39, 150.87, 63.66);
        context.lineTo(116.29, 3.77);
        context.bezierCurveTo(115.3, 2.05, 113.46, 0.99, 111.47, 0.99);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Group 7
        context.save();

        //// Clip Clip 7

        context.beginPath();
        context.moveTo(42.42, 1);
        context.bezierCurveTo(40.42, 1, 38.58, 2.06, 37.58, 3.78);
        context.lineTo(37.58, 3.78);
        context.lineTo(2.92, 63.71);
        context.bezierCurveTo(1.92, 65.44, 1.92, 67.56, 2.92, 69.29);
        context.lineTo(2.92, 69.29);
        context.lineTo(37.58, 129.21);
        context.bezierCurveTo(38.58, 130.94, 40.42, 132, 42.42, 132);
        context.lineTo(42.42, 132);
        context.lineTo(111.75, 132);
        context.bezierCurveTo(113.74, 132, 115.59, 130.94, 116.59, 129.21);
        context.lineTo(116.59, 129.21);
        context.lineTo(151.25, 69.29);
        context.bezierCurveTo(152.25, 67.56, 152.25, 65.44, 151.25, 63.71);
        context.lineTo(151.25, 63.71);
        context.lineTo(116.59, 3.78);
        context.bezierCurveTo(115.59, 2.06, 113.74, 1, 111.75, 1);
        context.lineTo(111.75, 1);
        context.lineTo(42.42, 1);
        context.closePath();
        context.clip();



        //// Polygon Drawing
        context.save();
        context.translate(21, 12);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 2 Drawing
        context.save();
        context.translate(71, 12);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 3 Drawing
        context.save();
        context.translate(-4, 62);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 4 Drawing
        context.save();
        context.translate(46, 62);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 5 Drawing
        context.save();
        context.translate(98, 62);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 7 Drawing
        context.save();
        context.translate(73, 112);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 8 Drawing
        context.save();
        context.translate(123, 112);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 6 Drawing
        context.save();
        context.translate(22, 113);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 9 Drawing
        context.save();
        context.translate(49, 163);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        //// Polygon 10 Drawing
        context.save();
        context.translate(99, 163);
        context.rotate(-90 * Math.PI / 180);

        context.beginPath();
        context.moveTo(18.5, 0);
        context.lineTo(34.52, 9.25);
        context.lineTo(34.52, 27.75);
        context.lineTo(18.5, 37);
        context.lineTo(2.48, 27.75);
        context.lineTo(2.48, 9.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();

        context.restore();


        context.restore();



        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground3(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 388 Drawing
        context.beginPath();
        context.moveTo(111.47, 0.99);
        context.lineTo(42.31, 0.99);
        context.bezierCurveTo(40.32, 0.99, 38.49, 2.05, 37.49, 3.77);
        context.lineTo(2.91, 63.66);
        context.bezierCurveTo(1.92, 65.39, 1.92, 67.51, 2.91, 69.23);
        context.lineTo(37.49, 129.12);
        context.bezierCurveTo(38.49, 130.84, 40.32, 131.91, 42.31, 131.91);
        context.lineTo(111.47, 131.91);
        context.bezierCurveTo(113.46, 131.91, 115.3, 130.84, 116.29, 129.12);
        context.lineTo(150.87, 69.23);
        context.bezierCurveTo(151.86, 67.51, 151.86, 65.39, 150.87, 63.66);
        context.lineTo(116.29, 3.77);
        context.bezierCurveTo(115.3, 2.05, 113.46, 0.99, 111.47, 0.99);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Group 7
        context.save();

        //// Clip Clip 7

        context.beginPath();
        context.moveTo(42.42, 1);
        context.bezierCurveTo(40.42, 1, 38.58, 2.06, 37.58, 3.78);
        context.lineTo(37.58, 3.78);
        context.lineTo(2.92, 63.71);
        context.bezierCurveTo(1.92, 65.44, 1.92, 67.56, 2.92, 69.29);
        context.lineTo(2.92, 69.29);
        context.lineTo(37.58, 129.21);
        context.bezierCurveTo(38.58, 130.94, 40.42, 132, 42.42, 132);
        context.lineTo(42.42, 132);
        context.lineTo(111.75, 132);
        context.bezierCurveTo(113.74, 132, 115.59, 130.94, 116.59, 129.21);
        context.lineTo(116.59, 129.21);
        context.lineTo(151.25, 69.29);
        context.bezierCurveTo(152.25, 67.56, 152.25, 65.44, 151.25, 63.71);
        context.lineTo(151.25, 63.71);
        context.lineTo(116.59, 3.78);
        context.bezierCurveTo(115.59, 2.06, 113.74, 1, 111.75, 1);
        context.lineTo(111.75, 1);
        context.lineTo(42.42, 1);
        context.closePath();
        context.clip();



        //// Star Drawing
        context.beginPath();
        context.moveTo(54, 36.5);
        context.lineTo(59.48, 52.45);
        context.lineTo(76.35, 52.74);
        context.lineTo(62.87, 62.88);
        context.lineTo(67.81, 79.01);
        context.lineTo(54, 69.33);
        context.lineTo(40.19, 79.01);
        context.lineTo(45.13, 62.88);
        context.lineTo(31.65, 52.74);
        context.lineTo(48.52, 52.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 2 Drawing
        context.beginPath();
        context.moveTo(108, 36.5);
        context.lineTo(113.48, 52.45);
        context.lineTo(130.35, 52.74);
        context.lineTo(116.87, 62.88);
        context.lineTo(121.81, 79.01);
        context.lineTo(108, 69.33);
        context.lineTo(94.19, 79.01);
        context.lineTo(99.13, 62.88);
        context.lineTo(85.65, 52.74);
        context.lineTo(102.52, 52.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 3 Drawing
        context.beginPath();
        context.moveTo(27, 83.5);
        context.lineTo(32.48, 99.45);
        context.lineTo(49.35, 99.74);
        context.lineTo(35.87, 109.88);
        context.lineTo(40.81, 126.01);
        context.lineTo(27, 116.33);
        context.lineTo(13.19, 126.01);
        context.lineTo(18.13, 109.88);
        context.lineTo(4.65, 99.74);
        context.lineTo(21.52, 99.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 4 Drawing
        context.beginPath();
        context.moveTo(81, 83.5);
        context.lineTo(86.48, 99.45);
        context.lineTo(103.35, 99.74);
        context.lineTo(89.87, 109.88);
        context.lineTo(94.81, 126.01);
        context.lineTo(81, 116.33);
        context.lineTo(67.19, 126.01);
        context.lineTo(72.13, 109.88);
        context.lineTo(58.65, 99.74);
        context.lineTo(75.52, 99.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 5 Drawing
        context.beginPath();
        context.moveTo(135, 83.5);
        context.lineTo(140.48, 99.45);
        context.lineTo(157.35, 99.74);
        context.lineTo(143.87, 109.88);
        context.lineTo(148.81, 126.01);
        context.lineTo(135, 116.33);
        context.lineTo(121.19, 126.01);
        context.lineTo(126.13, 109.88);
        context.lineTo(112.65, 99.74);
        context.lineTo(129.52, 99.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 6 Drawing
        context.beginPath();
        context.moveTo(-2, 36.5);
        context.lineTo(3.48, 52.45);
        context.lineTo(20.35, 52.74);
        context.lineTo(6.87, 62.88);
        context.lineTo(11.81, 79.01);
        context.lineTo(-2, 69.33);
        context.lineTo(-15.81, 79.01);
        context.lineTo(-10.87, 62.88);
        context.lineTo(-24.35, 52.74);
        context.lineTo(-7.48, 52.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 7 Drawing
        context.beginPath();
        context.moveTo(82, -11.5);
        context.lineTo(87.48, 4.45);
        context.lineTo(104.35, 4.74);
        context.lineTo(90.87, 14.88);
        context.lineTo(95.81, 31.01);
        context.lineTo(82, 21.33);
        context.lineTo(68.19, 31.01);
        context.lineTo(73.13, 14.88);
        context.lineTo(59.65, 4.74);
        context.lineTo(76.52, 4.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 8 Drawing
        context.beginPath();
        context.moveTo(136, -11.5);
        context.lineTo(141.48, 4.45);
        context.lineTo(158.35, 4.74);
        context.lineTo(144.87, 14.88);
        context.lineTo(149.81, 31.01);
        context.lineTo(136, 21.33);
        context.lineTo(122.19, 31.01);
        context.lineTo(127.13, 14.88);
        context.lineTo(113.65, 4.74);
        context.lineTo(130.52, 4.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Star 9 Drawing
        context.beginPath();
        context.moveTo(26, -11.5);
        context.lineTo(31.48, 4.45);
        context.lineTo(48.35, 4.74);
        context.lineTo(34.87, 14.88);
        context.lineTo(39.81, 31.01);
        context.lineTo(26, 21.33);
        context.lineTo(12.19, 31.01);
        context.lineTo(17.13, 14.88);
        context.lineTo(3.65, 4.74);
        context.lineTo(20.52, 4.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        context.restore();



        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground5(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 12
        context.save();

        //// Clip Clip 12

        context.beginPath();
        context.moveTo(42.09, 1.22);
        context.bezierCurveTo(40.1, 1.22, 38.26, 2.28, 37.27, 4.01);
        context.lineTo(37.27, 4.01);
        context.lineTo(2.69, 63.9);
        context.bezierCurveTo(1.69, 65.62, 1.69, 67.74, 2.69, 69.46);
        context.lineTo(2.69, 69.46);
        context.lineTo(37.27, 129.35);
        context.bezierCurveTo(38.26, 131.08, 40.1, 132.14, 42.09, 132.14);
        context.lineTo(42.09, 132.14);
        context.lineTo(111.24, 132.14);
        context.bezierCurveTo(113.23, 132.14, 115.07, 131.08, 116.07, 129.35);
        context.lineTo(116.07, 129.35);
        context.lineTo(150.64, 69.46);
        context.bezierCurveTo(151.64, 67.74, 151.64, 65.62, 150.64, 63.9);
        context.lineTo(150.64, 63.9);
        context.lineTo(116.07, 4.01);
        context.bezierCurveTo(115.07, 2.28, 113.23, 1.22, 111.24, 1.22);
        context.lineTo(111.24, 1.22);
        context.lineTo(42.09, 1.22);
        context.closePath();
        context.clip();



        //// Bezier 529 Drawing
        context.beginPath();
        context.moveTo(166.33, 143);
        context.lineTo(-13, 143);
        context.lineTo(-13, 126.39);
        context.lineTo(166.33, 126.39);
        context.lineTo(166.33, 143);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 530 Drawing
        context.beginPath();
        context.moveTo(166.33, 126.39);
        context.lineTo(-13, 126.39);
        context.lineTo(-13, 109.78);
        context.lineTo(166.33, 109.78);
        context.lineTo(166.33, 126.39);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 531 Drawing
        context.beginPath();
        context.moveTo(166.33, 109.78);
        context.lineTo(-13, 109.78);
        context.lineTo(-13, 93.16);
        context.lineTo(166.33, 93.16);
        context.lineTo(166.33, 109.78);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 532 Drawing
        context.beginPath();
        context.moveTo(166.33, 93.16);
        context.lineTo(-13, 93.16);
        context.lineTo(-13, 76.55);
        context.lineTo(166.33, 76.55);
        context.lineTo(166.33, 93.16);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 533 Drawing
        context.beginPath();
        context.moveTo(166.33, 77.31);
        context.lineTo(-13, 77.31);
        context.lineTo(-13, 60.7);
        context.lineTo(166.33, 60.7);
        context.lineTo(166.33, 77.31);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 534 Drawing
        context.beginPath();
        context.moveTo(166.33, 60.74);
        context.lineTo(-13, 60.74);
        context.lineTo(-13, 44.13);
        context.lineTo(166.33, 44.13);
        context.lineTo(166.33, 60.74);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 535 Drawing
        context.beginPath();
        context.moveTo(166.33, 44.13);
        context.lineTo(-13, 44.13);
        context.lineTo(-13, 27.51);
        context.lineTo(166.33, 27.51);
        context.lineTo(166.33, 44.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 536 Drawing
        context.beginPath();
        context.moveTo(166.33, 27.51);
        context.lineTo(-13, 27.51);
        context.lineTo(-13, 10.9);
        context.lineTo(166.33, 10.9);
        context.lineTo(166.33, 27.51);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 537 Drawing
        context.beginPath();
        context.moveTo(166.33, 11.66);
        context.lineTo(-13, 11.66);
        context.lineTo(-13, -4.95);
        context.lineTo(166.33, -4.95);
        context.lineTo(166.33, 11.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        context.restore();



        //// Bezier 539 Drawing
        context.beginPath();
        context.moveTo(111.09, 1.39);
        context.lineTo(41.94, 1.39);
        context.bezierCurveTo(39.95, 1.39, 38.11, 2.45, 37.12, 4.17);
        context.lineTo(2.54, 64.06);
        context.bezierCurveTo(1.55, 65.78, 1.55, 67.91, 2.54, 69.63);
        context.lineTo(37.12, 129.52);
        context.bezierCurveTo(38.11, 131.24, 39.95, 132.3, 41.94, 132.3);
        context.lineTo(111.09, 132.3);
        context.bezierCurveTo(113.08, 132.3, 114.92, 131.24, 115.92, 129.52);
        context.lineTo(150.49, 69.63);
        context.bezierCurveTo(151.49, 67.91, 151.49, 65.78, 150.49, 64.06);
        context.lineTo(115.92, 4.17);
        context.bezierCurveTo(114.92, 2.45, 113.08, 1.39, 111.09, 1.39);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground4(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 528 Drawing
        context.beginPath();
        context.moveTo(111.3, 1.56);
        context.lineTo(42.15, 1.56);
        context.bezierCurveTo(40.16, 1.56, 38.32, 2.62, 37.32, 4.34);
        context.lineTo(2.75, 64.23);
        context.bezierCurveTo(1.75, 65.96, 1.75, 68.08, 2.75, 69.8);
        context.lineTo(37.32, 129.69);
        context.bezierCurveTo(38.32, 131.41, 40.16, 132.47, 42.15, 132.47);
        context.lineTo(111.3, 132.47);
        context.bezierCurveTo(113.29, 132.47, 115.13, 131.41, 116.12, 129.69);
        context.lineTo(150.7, 69.8);
        context.bezierCurveTo(151.69, 68.08, 151.69, 65.96, 150.7, 64.23);
        context.lineTo(116.12, 4.34);
        context.bezierCurveTo(115.13, 2.62, 113.29, 1.56, 111.3, 1.56);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Group 11
        context.save();

        //// Clip Clip 11

        context.beginPath();
        context.moveTo(42.15, 1.39);
        context.bezierCurveTo(40.16, 1.39, 38.32, 2.45, 37.32, 4.17);
        context.lineTo(37.32, 4.17);
        context.lineTo(2.75, 64.06);
        context.bezierCurveTo(1.75, 65.78, 1.75, 67.91, 2.75, 69.63);
        context.lineTo(2.75, 69.63);
        context.lineTo(37.32, 129.52);
        context.bezierCurveTo(38.32, 131.24, 40.16, 132.3, 42.15, 132.3);
        context.lineTo(42.15, 132.3);
        context.lineTo(111.3, 132.3);
        context.bezierCurveTo(113.29, 132.3, 115.13, 131.24, 116.12, 129.52);
        context.lineTo(116.12, 129.52);
        context.lineTo(150.7, 69.63);
        context.bezierCurveTo(151.69, 67.91, 151.69, 65.78, 150.7, 64.06);
        context.lineTo(150.7, 64.06);
        context.lineTo(116.12, 4.17);
        context.bezierCurveTo(115.13, 2.45, 113.29, 1.39, 111.3, 1.39);
        context.lineTo(111.3, 1.39);
        context.lineTo(42.15, 1.39);
        context.closePath();
        context.clip();



        //// Bezier 481 Drawing
        context.beginPath();
        context.moveTo(19.36, 157);
        context.lineTo(2.75, 157);
        context.lineTo(2.75, -22.33);
        context.lineTo(19.36, -22.33);
        context.lineTo(19.36, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 482 Drawing
        context.beginPath();
        context.moveTo(35.97, 157);
        context.lineTo(19.36, 157);
        context.lineTo(19.36, -22.33);
        context.lineTo(35.97, -22.33);
        context.lineTo(35.97, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 483 Drawing
        context.beginPath();
        context.moveTo(52.58, 157);
        context.lineTo(35.97, 157);
        context.lineTo(35.97, -22.33);
        context.lineTo(52.58, -22.33);
        context.lineTo(52.58, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 484 Drawing
        context.beginPath();
        context.moveTo(69.19, 157);
        context.lineTo(52.58, 157);
        context.lineTo(52.58, -22.33);
        context.lineTo(69.19, -22.33);
        context.lineTo(69.19, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 485 Drawing
        context.beginPath();
        context.moveTo(85.05, 157);
        context.lineTo(68.44, 157);
        context.lineTo(68.44, -22.33);
        context.lineTo(85.05, -22.33);
        context.lineTo(85.05, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 486 Drawing
        context.beginPath();
        context.moveTo(101.62, 157);
        context.lineTo(85.01, 157);
        context.lineTo(85.01, -22.33);
        context.lineTo(101.62, -22.33);
        context.lineTo(101.62, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 487 Drawing
        context.beginPath();
        context.moveTo(118.23, 157);
        context.lineTo(101.62, 157);
        context.lineTo(101.62, -22.33);
        context.lineTo(118.23, -22.33);
        context.lineTo(118.23, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 488 Drawing
        context.beginPath();
        context.moveTo(134.84, 157);
        context.lineTo(118.23, 157);
        context.lineTo(118.23, -22.33);
        context.lineTo(134.84, -22.33);
        context.lineTo(134.84, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 489 Drawing
        context.beginPath();
        context.moveTo(150.7, 157);
        context.lineTo(134.09, 157);
        context.lineTo(134.09, -22.33);
        context.lineTo(150.7, -22.33);
        context.lineTo(150.7, 157);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        context.restore();



        //// Bezier Drawing
        context.beginPath();
        context.moveTo(111.3, 1.56);
        context.lineTo(42.15, 1.56);
        context.bezierCurveTo(40.16, 1.56, 38.32, 2.62, 37.32, 4.34);
        context.lineTo(2.75, 64.23);
        context.bezierCurveTo(1.75, 65.96, 1.75, 68.08, 2.75, 69.8);
        context.lineTo(37.32, 129.69);
        context.bezierCurveTo(38.32, 131.41, 40.16, 132.47, 42.15, 132.47);
        context.lineTo(111.3, 132.47);
        context.bezierCurveTo(113.29, 132.47, 115.13, 131.41, 116.12, 129.69);
        context.lineTo(150.7, 69.8);
        context.bezierCurveTo(151.69, 68.08, 151.69, 65.96, 150.7, 64.23);
        context.lineTo(116.12, 4.34);
        context.bezierCurveTo(115.13, 2.62, 113.29, 1.56, 111.3, 1.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground2(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor13 = 'rgba(248, 218, 37, 1)';

        //// Bezier 349 Drawing
        context.beginPath();
        context.moveTo(110.94, 0.83);
        context.lineTo(41.79, 0.83);
        context.bezierCurveTo(39.8, 0.83, 37.96, 1.89, 36.96, 3.62);
        context.lineTo(2.39, 63.51);
        context.bezierCurveTo(1.39, 65.23, 1.39, 67.35, 2.39, 69.07);
        context.lineTo(36.96, 128.96);
        context.bezierCurveTo(37.96, 130.69, 39.8, 131.75, 41.79, 131.75);
        context.lineTo(110.94, 131.75);
        context.bezierCurveTo(112.93, 131.75, 114.77, 130.69, 115.76, 128.96);
        context.lineTo(150.34, 69.07);
        context.bezierCurveTo(151.33, 67.35, 151.33, 65.23, 150.34, 63.51);
        context.lineTo(115.76, 3.62);
        context.bezierCurveTo(114.77, 1.89, 112.93, 0.83, 110.94, 0.83);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Group 5
        context.save();

        //// Clip Clip 5

        context.beginPath();
        context.moveTo(41.79, 0.83);
        context.bezierCurveTo(39.8, 0.83, 37.96, 1.89, 36.96, 3.62);
        context.lineTo(36.96, 3.62);
        context.lineTo(2.39, 63.51);
        context.bezierCurveTo(1.39, 65.23, 1.39, 67.35, 2.39, 69.08);
        context.lineTo(2.39, 69.08);
        context.lineTo(36.96, 128.96);
        context.bezierCurveTo(37.96, 130.69, 39.8, 131.75, 41.79, 131.75);
        context.lineTo(41.79, 131.75);
        context.lineTo(110.94, 131.75);
        context.bezierCurveTo(112.93, 131.75, 114.77, 130.69, 115.76, 128.96);
        context.lineTo(115.76, 128.96);
        context.lineTo(150.34, 69.08);
        context.bezierCurveTo(151.33, 67.35, 151.33, 65.23, 150.34, 63.51);
        context.lineTo(150.34, 63.51);
        context.lineTo(115.76, 3.62);
        context.bezierCurveTo(114.77, 1.89, 112.93, 0.83, 110.94, 0.83);
        context.lineTo(110.94, 0.83);
        context.lineTo(41.79, 0.83);
        context.closePath();
        context.clip();



        //// Bezier 351 Drawing
        context.beginPath();
        context.moveTo(90.89, 138);
        context.lineTo(130.45, 69.48);
        context.bezierCurveTo(131.59, 67.5, 131.59, 65.08, 130.45, 63.1);
        context.lineTo(90.89, -5.42);
        context.strokeStyle = strokeColor13;
        context.lineWidth = 8;
        context.stroke();


        //// Bezier 352 Drawing
        context.beginPath();
        context.moveTo(65.42, 138);
        context.lineTo(104.98, 69.48);
        context.bezierCurveTo(106.12, 67.5, 106.12, 65.08, 104.98, 63.1);
        context.lineTo(65.42, -5.42);
        context.strokeStyle = strokeColor13;
        context.lineWidth = 8;
        context.stroke();


        //// Bezier 353 Drawing
        context.beginPath();
        context.moveTo(39.94, 138);
        context.lineTo(79.51, 69.48);
        context.bezierCurveTo(80.64, 67.5, 80.64, 65.08, 79.51, 63.1);
        context.lineTo(39.94, -5.42);
        context.strokeStyle = strokeColor13;
        context.lineWidth = 8;
        context.stroke();


        //// Bezier 354 Drawing
        context.beginPath();
        context.moveTo(14.47, 138);
        context.lineTo(54.03, 69.48);
        context.bezierCurveTo(55.17, 67.5, 55.17, 65.08, 54.03, 63.1);
        context.lineTo(14.47, -5.42);
        context.strokeStyle = strokeColor13;
        context.lineWidth = 8;
        context.stroke();


        //// Bezier 355 Drawing
        context.beginPath();
        context.moveTo(-11, 138);
        context.lineTo(28.56, 69.48);
        context.bezierCurveTo(29.7, 67.5, 29.7, 65.08, 28.56, 63.1);
        context.lineTo(-11, -5.42);
        context.strokeStyle = strokeColor13;
        context.lineWidth = 8;
        context.stroke();


        context.restore();



        //// Bezier 350 Drawing
        context.beginPath();
        context.moveTo(110.94, 0.83);
        context.lineTo(41.79, 0.83);
        context.bezierCurveTo(39.8, 0.83, 37.96, 1.89, 36.96, 3.62);
        context.lineTo(2.39, 63.51);
        context.bezierCurveTo(1.39, 65.23, 1.39, 67.35, 2.39, 69.07);
        context.lineTo(36.96, 128.96);
        context.bezierCurveTo(37.96, 130.69, 39.8, 131.75, 41.79, 131.75);
        context.lineTo(110.94, 131.75);
        context.bezierCurveTo(112.93, 131.75, 114.77, 130.69, 115.76, 128.96);
        context.lineTo(150.34, 69.07);
        context.bezierCurveTo(151.33, 67.35, 151.33, 65.23, 150.34, 63.51);
        context.lineTo(115.76, 3.62);
        context.bezierCurveTo(114.77, 1.89, 112.93, 0.83, 110.94, 0.83);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground1(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 15
        context.save();

        //// Clip Clip 15

        context.beginPath();
        context.moveTo(42.03, 1);
        context.bezierCurveTo(40.04, 1, 38.21, 2.06, 37.22, 3.78);
        context.lineTo(37.22, 3.78);
        context.lineTo(2.74, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.74, 69.24);
        context.lineTo(2.74, 69.24);
        context.lineTo(37.22, 129.13);
        context.bezierCurveTo(38.21, 130.85, 40.04, 131.91, 42.03, 131.91);
        context.lineTo(42.03, 131.91);
        context.lineTo(110.97, 131.91);
        context.bezierCurveTo(112.96, 131.91, 114.79, 130.85, 115.78, 129.13);
        context.lineTo(115.78, 129.13);
        context.lineTo(150.26, 69.24);
        context.bezierCurveTo(151.25, 67.52, 151.25, 65.4, 150.26, 63.67);
        context.lineTo(150.26, 63.67);
        context.lineTo(115.78, 3.78);
        context.bezierCurveTo(114.79, 2.06, 112.96, 1, 110.97, 1);
        context.lineTo(110.97, 1);
        context.lineTo(42.03, 1);
        context.closePath();
        context.clip();



        //// Bezier 573 Drawing
        context.beginPath();
        context.moveTo(-75.58, 102.97);
        context.lineTo(-86, 94.87);
        context.lineTo(72.22, -108.84);
        context.lineTo(82.64, -100.75);
        context.lineTo(-75.58, 102.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 574 Drawing
        context.beginPath();
        context.moveTo(-65.15, 111.06);
        context.lineTo(-75.58, 102.97);
        context.lineTo(82.64, -100.75);
        context.lineTo(93.07, -92.65);
        context.lineTo(-65.15, 111.06);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 575 Drawing
        context.beginPath();
        context.moveTo(-54.73, 119.16);
        context.lineTo(-65.15, 111.06);
        context.lineTo(93.07, -92.65);
        context.lineTo(103.49, -84.55);
        context.lineTo(-54.73, 119.16);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 576 Drawing
        context.beginPath();
        context.moveTo(-44.3, 127.25);
        context.lineTo(-54.73, 119.16);
        context.lineTo(103.49, -84.55);
        context.lineTo(113.92, -76.46);
        context.lineTo(-44.3, 127.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 577 Drawing
        context.beginPath();
        context.moveTo(-34.35, 134.98);
        context.lineTo(-44.78, 126.89);
        context.lineTo(113.44, -76.83);
        context.lineTo(123.86, -68.73);
        context.lineTo(-34.35, 134.98);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 578 Drawing
        context.beginPath();
        context.moveTo(-23.96, 143.06);
        context.lineTo(-34.38, 134.96);
        context.lineTo(123.84, -68.75);
        context.lineTo(134.26, -60.65);
        context.lineTo(-23.96, 143.06);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 579 Drawing
        context.beginPath();
        context.moveTo(-13.53, 151.15);
        context.lineTo(-23.96, 143.06);
        context.lineTo(134.26, -60.65);
        context.lineTo(144.69, -52.56);
        context.lineTo(-13.53, 151.15);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 580 Drawing
        context.beginPath();
        context.moveTo(-3.11, 159.25);
        context.lineTo(-13.53, 151.15);
        context.lineTo(144.69, -52.56);
        context.lineTo(155.11, -44.46);
        context.lineTo(-3.11, 159.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 581 Drawing
        context.beginPath();
        context.moveTo(6.84, 166.98);
        context.lineTo(-3.58, 158.88);
        context.lineTo(154.64, -44.83);
        context.lineTo(165.06, -36.73);
        context.lineTo(6.84, 166.98);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 582 Drawing
        context.beginPath();
        context.moveTo(17.28, 175.09);
        context.lineTo(6.86, 166.99);
        context.lineTo(165.07, -36.72);
        context.lineTo(175.5, -28.63);
        context.lineTo(17.28, 175.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 583 Drawing
        context.beginPath();
        context.moveTo(27.7, 183.18);
        context.lineTo(17.28, 175.08);
        context.lineTo(175.5, -28.63);
        context.lineTo(185.92, -20.53);
        context.lineTo(27.7, 183.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 584 Drawing
        context.beginPath();
        context.moveTo(38.13, 191.28);
        context.lineTo(27.7, 183.18);
        context.lineTo(185.92, -20.53);
        context.lineTo(196.35, -12.43);
        context.lineTo(38.13, 191.28);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 585 Drawing
        context.beginPath();
        context.moveTo(48.08, 199);
        context.lineTo(37.65, 190.91);
        context.lineTo(195.87, -12.8);
        context.lineTo(206.3, -4.71);
        context.lineTo(48.08, 199);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 586 Drawing
        context.beginPath();
        context.moveTo(58.47, 207.08);
        context.lineTo(48.05, 198.98);
        context.lineTo(206.27, -4.73);
        context.lineTo(216.69, 3.37);
        context.lineTo(58.47, 207.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 587 Drawing
        context.beginPath();
        context.moveTo(68.9, 215.18);
        context.lineTo(58.47, 207.08);
        context.lineTo(216.69, 3.37);
        context.lineTo(227.12, 11.46);
        context.lineTo(68.9, 215.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 588 Drawing
        context.beginPath();
        context.moveTo(79.32, 223.27);
        context.lineTo(68.9, 215.18);
        context.lineTo(227.12, 11.46);
        context.lineTo(237.54, 19.56);
        context.lineTo(79.32, 223.27);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 589 Drawing
        context.beginPath();
        context.moveTo(89.27, 231);
        context.lineTo(78.85, 222.9);
        context.lineTo(237.07, 19.19);
        context.lineTo(247.49, 27.29);
        context.lineTo(89.27, 231);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        context.restore();



        //// Bezier 591 Drawing
        context.beginPath();
        context.moveTo(110.84, 1.08);
        context.lineTo(41.69, 1.08);
        context.bezierCurveTo(39.7, 1.08, 37.86, 2.14, 36.86, 3.86);
        context.lineTo(2.29, 63.75);
        context.bezierCurveTo(1.29, 65.47, 1.29, 67.6, 2.29, 69.32);
        context.lineTo(36.86, 129.21);
        context.bezierCurveTo(37.86, 130.93, 39.7, 131.99, 41.69, 131.99);
        context.lineTo(110.84, 131.99);
        context.bezierCurveTo(112.83, 131.99, 114.67, 130.93, 115.66, 129.21);
        context.lineTo(150.24, 69.32);
        context.bezierCurveTo(151.23, 67.6, 151.23, 65.47, 150.24, 63.75);
        context.lineTo(115.66, 3.86);
        context.bezierCurveTo(114.67, 2.14, 112.83, 1.08, 110.84, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSquintEyes(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 26, 2), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 26, resizedFrame.h / 2);



        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _eyeSkinColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin2 : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin3 : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin4 : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin5 : (_skinColor === AvatarStyleKit.skin5 ? AvatarStyleKit.skin6 : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin7 : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin8 : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin9 : AvatarStyleKit.skin9Tertiary)))))));

        //// Bezier 231 Drawing
        context.beginPath();
        context.moveTo(17.79, 2);
        context.lineTo(17.79, 1.56);
        context.bezierCurveTo(17.79, 0.64, 20.53, 0.32, 21.44, 0.32);
        context.bezierCurveTo(22.36, 0.32, 25.1, 0.64, 25.1, 1.56);
        context.lineTo(25.1, 2);
        context.fillStyle = _eyeSkinColor;
        context.fill();


        //// Bezier 232 Drawing
        context.beginPath();
        context.moveTo(0, 2);
        context.lineTo(0, 1.56);
        context.bezierCurveTo(0, 0.64, 2.74, 0.32, 3.66, 0.32);
        context.bezierCurveTo(4.57, 0.32, 7.31, 0.64, 7.31, 1.56);
        context.lineTo(7.31, 2);
        context.fillStyle = _eyeSkinColor;
        context.fill();
        
        context.restore();

    }

    function drawEyesNormal(canvas, eyeColor, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 21, 6), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 21, resizedFrame.h / 6);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _eyeColor = eyeColor === 'eye1' ? AvatarStyleKit.col10 : (eyeColor === 'eye2' ? AvatarStyleKit.col6 : (eyeColor === 'eye3' ? AvatarStyleKit.hair6 : (eyeColor === 'eye4' ? AvatarStyleKit.col12 : AvatarStyleKit.col4)));
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _eyeSkinColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin2 : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin3 : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin4 : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin5 : (_skinColor === AvatarStyleKit.skin5 ? AvatarStyleKit.skin6 : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin7 : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin8 : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin9 : AvatarStyleKit.skin9Tertiary)))))));

        //// Bezier 117 Drawing
        context.beginPath();
        context.moveTo(17.94, 5.96);
        context.lineTo(17.94, 5.96);
        context.bezierCurveTo(16.73, 5.96, 15.75, 4.99, 15.75, 3.78);
        context.lineTo(15.75, 3.2);
        context.bezierCurveTo(15.75, 1.99, 16.73, 1.01, 17.94, 1.01);
        context.bezierCurveTo(19.14, 1.01, 20.12, 1.99, 20.12, 3.2);
        context.lineTo(20.12, 3.78);
        context.bezierCurveTo(20.12, 4.99, 19.14, 5.96, 17.94, 5.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 116 Drawing
        context.beginPath();
        context.moveTo(2.15, 6);
        context.lineTo(2.15, 6);
        context.bezierCurveTo(0.96, 6, 0, 5.04, 0, 3.85);
        context.lineTo(0, 3.28);
        context.bezierCurveTo(0, 2.09, 0.96, 1.13, 2.15, 1.13);
        context.bezierCurveTo(3.34, 1.13, 4.3, 2.09, 4.3, 3.28);
        context.lineTo(4.3, 3.85);
        context.bezierCurveTo(4.3, 5.04, 3.34, 6, 2.15, 6);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 37 Drawing
        oval(context, 16.27, 2, 3.3, 3.3);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 35 Drawing
        oval(context, 0.52, 2.1, 3.3, 3.3);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 36 Drawing
        oval(context, 1.47, 3.05, 1.4, 1.4);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 38 Drawing
        oval(context, 17.22, 2.95, 1.4, 1.4);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 118 Drawing
        context.beginPath();
        context.moveTo(0, 3.05);
        context.lineTo(0, 2.48);
        context.bezierCurveTo(0, 1.29, 0.96, 0.33, 2.15, 0.33);
        context.bezierCurveTo(3.34, 0.33, 4.3, 1.29, 4.3, 2.48);
        context.lineTo(4.3, 3.05);
        context.fillStyle = _eyeSkinColor;
        context.fill();


        //// Bezier 119 Drawing
        context.beginPath();
        context.moveTo(15.75, 3.06);
        context.lineTo(15.75, 2.48);
        context.bezierCurveTo(15.75, 1.27, 16.73, 0.3, 17.94, 0.3);
        context.bezierCurveTo(19.14, 0.3, 20.12, 1.27, 20.12, 2.48);
        context.lineTo(20.12, 3.06);
        context.fillStyle = _eyeSkinColor;
        context.fill();
        
        context.restore();

    }

    function drawWideEyes(canvas, eyeColor, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 20, 8), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 20, resizedFrame.h / 8);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _eyeColor = eyeColor === 'eye1' ? AvatarStyleKit.col10 : (eyeColor === 'eye2' ? AvatarStyleKit.col6 : (eyeColor === 'eye3' ? AvatarStyleKit.hair6 : (eyeColor === 'eye4' ? AvatarStyleKit.col12 : AvatarStyleKit.col4)));
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _eyeSkinColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin2 : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin3 : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin4 : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin5 : (_skinColor === AvatarStyleKit.skin5 ? AvatarStyleKit.skin6 : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin7 : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin8 : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin9 : AvatarStyleKit.skin9Tertiary)))))));

        //// Bezier 201 Drawing
        context.beginPath();
        context.moveTo(1.98, 8);
        context.lineTo(1.98, 8);
        context.bezierCurveTo(0.89, 8, 0, 7.11, 0, 6.02);
        context.lineTo(0, 2.98);
        context.bezierCurveTo(0, 1.88, 0.89, 1, 1.98, 1);
        context.bezierCurveTo(3.08, 1, 3.96, 1.88, 3.96, 2.98);
        context.lineTo(3.96, 6.02);
        context.bezierCurveTo(3.96, 7.11, 3.08, 8, 1.98, 8);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 202 Drawing
        context.beginPath();
        context.moveTo(17.77, 7.96);
        context.lineTo(17.77, 7.96);
        context.bezierCurveTo(16.66, 7.96, 15.76, 7.07, 15.76, 5.96);
        context.lineTo(15.76, 2.88);
        context.bezierCurveTo(15.76, 1.78, 16.66, 0.88, 17.77, 0.88);
        context.bezierCurveTo(18.87, 0.88, 19.77, 1.78, 19.77, 2.88);
        context.lineTo(19.77, 5.96);
        context.bezierCurveTo(19.77, 7.07, 18.87, 7.96, 17.77, 7.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 55 Drawing
        oval(context, 0.46, 4.44, 3, 3);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 57 Drawing
        oval(context, 16.21, 4.34, 3.1, 3.1);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 56 Drawing
        oval(context, 1.31, 5.29, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 58 Drawing
        oval(context, 17.11, 5.19, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 203 Drawing
        context.beginPath();
        context.moveTo(0, 3.42);
        context.lineTo(0, 2.89);
        context.bezierCurveTo(0, 1.8, 0.89, 0.91, 1.98, 0.91);
        context.bezierCurveTo(3.08, 0.91, 3.96, 1.8, 3.96, 2.89);
        context.lineTo(3.96, 3.42);
        context.fillStyle = _eyeSkinColor;
        context.fill();


        //// Bezier 204 Drawing
        context.beginPath();
        context.moveTo(15.76, 3.42);
        context.lineTo(15.76, 2.88);
        context.bezierCurveTo(15.76, 1.78, 16.66, 0.88, 17.77, 0.88);
        context.bezierCurveTo(18.87, 0.88, 19.77, 1.78, 19.77, 2.88);
        context.lineTo(19.77, 3.42);
        context.fillStyle = _eyeSkinColor;
        context.fill();
        
        context.restore();

    }

    function drawClosedEyes(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 20, 3), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 20, resizedFrame.h / 3);



        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _eyeSkinColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin2 : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin3 : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin4 : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin5 : (_skinColor === AvatarStyleKit.skin5 ? AvatarStyleKit.skin6 : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin7 : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin8 : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin9 : AvatarStyleKit.skin9Tertiary)))))));

        //// Bezier 395 Drawing
        context.beginPath();
        context.moveTo(15.79, 3);
        context.lineTo(15.79, 2.56);
        context.bezierCurveTo(15.79, 1.65, 16.53, 0.9, 17.44, 0.9);
        context.bezierCurveTo(18.35, 0.9, 19.1, 1.65, 19.1, 2.56);
        context.lineTo(19.1, 3);
        context.fillStyle = _eyeSkinColor;
        context.fill();


        //// Bezier 394 Drawing
        context.beginPath();
        context.moveTo(0, 3);
        context.lineTo(0, 2.56);
        context.bezierCurveTo(0, 1.65, 0.74, 0.9, 1.65, 0.9);
        context.bezierCurveTo(2.57, 0.9, 3.31, 1.65, 3.31, 2.56);
        context.lineTo(3.31, 3);
        context.fillStyle = _eyeSkinColor;
        context.fill();
        
        context.restore();

    }

    function drawWideOpenEyes(canvas, eyeColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 20, 8), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 20, resizedFrame.h / 8);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _eyeColor = eyeColor === 'eye1' ? AvatarStyleKit.col10 : (eyeColor === 'eye2' ? AvatarStyleKit.col6 : (eyeColor === 'eye3' ? AvatarStyleKit.hair6 : (eyeColor === 'eye4' ? AvatarStyleKit.col12 : AvatarStyleKit.col4)));

        //// Bezier 421 Drawing
        context.beginPath();
        context.moveTo(2.01, 8);
        context.lineTo(2.01, 8);
        context.bezierCurveTo(0.9, 8, 0, 7.1, 0, 6);
        context.lineTo(0, 2.92);
        context.bezierCurveTo(0, 1.81, 0.9, 0.91, 2.01, 0.91);
        context.bezierCurveTo(3.11, 0.91, 4.01, 1.81, 4.01, 2.92);
        context.lineTo(4.01, 6);
        context.bezierCurveTo(4.01, 7.1, 3.11, 8, 2.01, 8);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 422 Drawing
        context.beginPath();
        context.moveTo(17.79, 7.93);
        context.lineTo(17.79, 7.93);
        context.bezierCurveTo(16.69, 7.93, 15.79, 7.03, 15.79, 5.92);
        context.lineTo(15.79, 2.85);
        context.bezierCurveTo(15.79, 1.74, 16.69, 0.84, 17.79, 0.84);
        context.bezierCurveTo(18.9, 0.84, 19.8, 1.74, 19.8, 2.85);
        context.lineTo(19.8, 5.92);
        context.bezierCurveTo(19.8, 7.03, 18.9, 7.93, 17.79, 7.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 111 Drawing
        oval(context, 0.43, 3.13, 3.1, 3.1);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 113 Drawing
        oval(context, 16.23, 3.08, 3.1, 3.1);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 114 Drawing
        oval(context, 17.13, 3.98, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 112 Drawing
        oval(context, 1.38, 4.03, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawWideTiredEyes(canvas, eyeColor, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 20, 8), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 20, resizedFrame.h / 8);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _eyeColor = eyeColor === 'eye1' ? AvatarStyleKit.col10 : (eyeColor === 'eye2' ? AvatarStyleKit.col6 : (eyeColor === 'eye3' ? AvatarStyleKit.hair6 : (eyeColor === 'eye4' ? AvatarStyleKit.col12 : AvatarStyleKit.col4)));
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _eyeSkinColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin2 : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin3 : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin4 : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin5 : (_skinColor === AvatarStyleKit.skin5 ? AvatarStyleKit.skin6 : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin7 : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin8 : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin9 : AvatarStyleKit.skin9Tertiary)))))));

        //// Bezier 545 Drawing
        context.beginPath();
        context.moveTo(17.77, 7.96);
        context.lineTo(17.77, 7.96);
        context.bezierCurveTo(16.66, 7.96, 15.76, 7.07, 15.76, 5.96);
        context.lineTo(15.76, 2.88);
        context.bezierCurveTo(15.76, 1.78, 16.66, 0.88, 17.77, 0.88);
        context.bezierCurveTo(18.87, 0.88, 19.77, 1.78, 19.77, 2.88);
        context.lineTo(19.77, 5.96);
        context.bezierCurveTo(19.77, 7.07, 18.87, 7.96, 17.77, 7.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 544 Drawing
        context.beginPath();
        context.moveTo(1.98, 8);
        context.lineTo(1.98, 8);
        context.bezierCurveTo(0.89, 8, 0, 7.11, 0, 6.02);
        context.lineTo(0, 2.98);
        context.bezierCurveTo(0, 1.88, 0.89, 1, 1.98, 1);
        context.bezierCurveTo(3.08, 1, 3.96, 1.88, 3.96, 2.98);
        context.lineTo(3.96, 6.02);
        context.bezierCurveTo(3.96, 7.11, 3.08, 8, 1.98, 8);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 147 Drawing
        oval(context, 0.46, 4.43, 3, 3);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 149 Drawing
        oval(context, 16.21, 4.33, 3.1, 3.1);
        context.fillStyle = _eyeColor;
        context.fill();


        //// Oval 148 Drawing
        oval(context, 1.31, 5.28, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 150 Drawing
        oval(context, 17.11, 5.23, 1.3, 1.3);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 547 Drawing
        context.beginPath();
        context.moveTo(15.76, 5.7);
        context.lineTo(15.76, 2.88);
        context.bezierCurveTo(15.76, 1.78, 16.66, 0.88, 17.77, 0.88);
        context.bezierCurveTo(18.87, 0.88, 19.77, 1.78, 19.77, 2.88);
        context.lineTo(19.77, 5.7);
        context.fillStyle = _eyeSkinColor;
        context.fill();


        //// Bezier 546 Drawing
        context.beginPath();
        context.moveTo(0, 5.68);
        context.lineTo(0, 2.89);
        context.bezierCurveTo(0, 1.8, 0.89, 0.91, 1.98, 0.91);
        context.bezierCurveTo(3.08, 0.91, 3.96, 1.8, 3.96, 2.89);
        context.lineTo(3.96, 5.68);
        context.fillStyle = _eyeSkinColor;
        context.fill();
        
        context.restore();

    }

    function drawSmileMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 14, 5), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 14, resizedFrame.h / 5);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// bezier Drawing
        context.beginPath();
        context.moveTo(1, 1);
        context.bezierCurveTo(4.31, 4.31, 9.68, 4.31, 13, 1);
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.68;
        context.stroke();
        
        context.restore();

    }

    function drawUnhappyMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 16, 8), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 16, resizedFrame.h / 8);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 239 Drawing
        context.beginPath();
        context.moveTo(2.71, 6.19);
        context.bezierCurveTo(4.35, 5.25, 7.96, 4.86, 7.96, 4.86);
        context.bezierCurveTo(7.96, 4.86, 12.27, 5.32, 13.44, 6.19);
        context.bezierCurveTo(14.86, 7.26, 16.1, 6.13, 13.98, 4.02);
        context.bezierCurveTo(11.87, 1.9, 10.02, 1.06, 8.07, 1);
        context.bezierCurveTo(6.13, 1.06, 4.28, 1.9, 2.17, 4.02);
        context.bezierCurveTo(0.05, 6.13, 1.17, 7.08, 2.71, 6.19);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.01;
        context.stroke();


        //// Bezier 238 Drawing
        context.beginPath();
        context.moveTo(2.71, 6.19);
        context.bezierCurveTo(4.35, 5.25, 7.96, 4.86, 7.96, 4.86);
        context.bezierCurveTo(7.96, 4.86, 12.27, 5.32, 13.44, 6.19);
        context.bezierCurveTo(14.86, 7.26, 16.1, 6.13, 13.98, 4.02);
        context.bezierCurveTo(11.87, 1.9, 10.02, 1.06, 8.07, 1);
        context.bezierCurveTo(6.13, 1.06, 4.28, 1.9, 2.17, 4.02);
        context.bezierCurveTo(0.05, 6.13, 1.17, 7.08, 2.71, 6.19);
        context.closePath();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 0;
        context.stroke();
        
        context.restore();

    }

    function drawWorriedMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 13, 5), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 13, resizedFrame.h / 5);


        //// Color Declarations
        var fillColor36 = 'rgba(235, 71, 59, 1)';

        //// Bezier 368 Drawing
        context.beginPath();
        context.moveTo(0, 0.53);
        context.bezierCurveTo(0, 0.53, 1.62, 5.84, 7.15, 3.2);
        context.bezierCurveTo(12.69, 0.55, 12.55, 5, 12.55, 5);
        context.bezierCurveTo(12.55, 5, 12.8, -0.74, 7.38, 1.06);
        context.bezierCurveTo(7.38, 1.06, 5.97, 1.9, 5.31, 1.95);
        context.bezierCurveTo(4.65, 2, 2.54, 2.84, 0, 0.53);
        context.closePath();
        context.fillStyle = fillColor36;
        context.fill();
        
        context.restore();

    }

    function drawSadMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 15, 5), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 15, resizedFrame.h / 5);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 553 Drawing
        context.beginPath();
        context.moveTo(13.99, 3.48);
        context.bezierCurveTo(10.41, 0.17, 4.59, 0.17, 1, 3.48);
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.68;
        context.stroke();
        
        context.restore();

    }

    function drawSmirkMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 16, 10), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 16, resizedFrame.h / 10);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 607 Drawing
        context.beginPath();
        context.moveTo(14.37, 3.58);
        context.bezierCurveTo(13.98, 5.91, 13.03, 11.53, 5, 7.7);
        context.bezierCurveTo(5, 7.7, 11.35, 8.96, 14.37, 3.58);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 608 Drawing
        context.beginPath();
        context.moveTo(14.37, 3.58);
        context.bezierCurveTo(13.98, 5.91, 13.03, 11.53, 5, 7.7);
        context.bezierCurveTo(5, 7.7, 11.35, 8.96, 14.37, 3.58);
        context.closePath();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGrinMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 21, 12), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 21, resizedFrame.h / 12);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 154 Drawing
        context.beginPath();
        context.moveTo(18.78, 2.74);
        context.bezierCurveTo(10.98, 20.78, 2.05, 3.68, 2, 3.51);
        context.bezierCurveTo(2, 3.51, 11.98, 8.72, 18.78, 2.74);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 155 Drawing
        context.beginPath();
        context.moveTo(18.78, 2.74);
        context.bezierCurveTo(10.98, 20.78, 2.05, 3.68, 2, 3.51);
        context.bezierCurveTo(2, 3.51, 11.98, 8.72, 18.78, 2.74);
        context.closePath();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawAnnoyedMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 8, 8), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 8, resizedFrame.h / 8);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 210 Drawing
        context.beginPath();
        context.moveTo(1, 7);
        context.lineTo(7.16, 0.84);
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.68;
        context.stroke();
        
        context.restore();

    }

    function drawTongueMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 18, 13), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 18, resizedFrame.h / 13);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor36 = 'rgba(235, 71, 59, 1)';
        var strokeColor11 = 'rgba(235, 46, 49, 1)';

        //// Bezier 637 Drawing
        context.beginPath();
        context.moveTo(17.78, 1.85);
        context.bezierCurveTo(17.78, 1.85, 11.47, 6.47, 1, 2.62);
        context.bezierCurveTo(1, 2.62, 8.7, 16.78, 17.78, 1.85);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 639 Drawing
        context.beginPath();
        context.moveTo(12.77, 9.45);
        context.lineTo(12.77, 5.38);
        context.lineTo(6.01, 5.38);
        context.lineTo(6.01, 9.45);
        context.lineTo(6.02, 9.46);
        context.bezierCurveTo(6.02, 12.85, 12.76, 12.85, 12.76, 9.46);
        context.lineTo(12.77, 9.45);
        context.closePath();
        context.strokeStyle = strokeColor11;
        context.lineWidth = 0.56;
        context.stroke();


        //// Bezier 638 Drawing
        context.beginPath();
        context.moveTo(12.77, 9.45);
        context.lineTo(12.77, 5.38);
        context.lineTo(6.01, 5.38);
        context.lineTo(6.01, 9.45);
        context.lineTo(6.02, 9.46);
        context.bezierCurveTo(6.02, 12.85, 12.76, 12.85, 12.76, 9.46);
        context.lineTo(12.77, 9.45);
        context.closePath();
        context.fillStyle = fillColor36;
        context.fill();
        
        context.restore();

    }

    function drawSmilingMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 15, 4), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 15, resizedFrame.h / 4);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 458 Drawing
        context.beginPath();
        context.moveTo(1.5, 0.6);
        context.bezierCurveTo(4.81, 3.91, 10.18, 3.91, 13.5, 0.6);
        context.lineTo(1.5, 0.6);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 459 Drawing
        context.beginPath();
        context.moveTo(1.5, 0.6);
        context.bezierCurveTo(4.81, 3.91, 10.18, 3.91, 13.5, 0.6);
        context.lineTo(1.5, 0.6);
        context.closePath();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawLipsMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 14, 6), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 14, resizedFrame.h / 6);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Bezier 513 Drawing
        context.beginPath();
        context.moveTo(0.07, 1.8);
        context.bezierCurveTo(0.29, 2.09, 3.46, 4.04, 3.46, 4.04);
        context.lineTo(9.18, 4.53);
        context.lineTo(13.69, 1.8);
        context.lineTo(13.69, 1.41);
        context.bezierCurveTo(13.69, 1.41, 11.83, 1.03, 11.67, 1.06);
        context.bezierCurveTo(11.5, 1.1, 0, 1.41, 0, 1.41);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 514 Drawing
        context.beginPath();
        context.moveTo(0.07, 1.83);
        context.bezierCurveTo(0.95, 3.02, 6.27, 9.5, 13.69, 1.8);
        context.bezierCurveTo(7.09, 4.61, 0.79, 2.13, 0.07, 1.83);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 515 Drawing
        context.beginPath();
        context.moveTo(6.84, 1.06);
        context.bezierCurveTo(3.75, -0.63, 0, 1, 0, 1);
        context.lineTo(0, 1.26);
        context.lineTo(0, 1.34);
        context.lineTo(0, 1.41);
        context.bezierCurveTo(0, 1.41, 0.03, 1.42, 0.07, 1.44);
        context.bezierCurveTo(0.79, 1.74, 7.09, 4.23, 13.69, 1.41);
        context.lineTo(13.69, 1.06);
        context.bezierCurveTo(10.59, -0.63, 6.84, 1.06, 6.84, 1.06);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();
        
        context.restore();

    }

    function drawEekMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 18, 7), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 18, resizedFrame.h / 7);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Bezier 401 Drawing
        context.beginPath();
        context.moveTo(14.54, 6);
        context.lineTo(3.67, 6);
        context.bezierCurveTo(2.2, 6, 1, 4.81, 1, 3.33);
        context.bezierCurveTo(1, 1.86, 2.2, 0.67, 3.67, 0.67);
        context.lineTo(14.54, 0.67);
        context.bezierCurveTo(16.01, 0.67, 17.2, 1.86, 17.2, 3.33);
        context.bezierCurveTo(17.2, 4.81, 16.01, 6, 14.54, 6);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 402 Drawing
        context.beginPath();
        context.moveTo(14.54, 6);
        context.lineTo(3.67, 6);
        context.bezierCurveTo(2.2, 6, 1, 4.81, 1, 3.33);
        context.bezierCurveTo(1, 1.86, 2.2, 0.67, 3.67, 0.67);
        context.lineTo(14.54, 0.67);
        context.bezierCurveTo(16.01, 0.67, 17.2, 1.86, 17.2, 3.33);
        context.bezierCurveTo(17.2, 4.81, 16.01, 6, 14.54, 6);
        context.closePath();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawOMouth(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 9, 9), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 9, resizedFrame.h / 9);


        //// Color Declarations
        var strokeColor4 = 'rgba(235, 71, 59, 1)';

        //// Oval 116 Drawing
        oval(context, 0.7, 0.6, 7.7, 7.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor4;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair6(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 39), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 39);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier Drawing
        context.beginPath();
        context.moveTo(72.38, 37);
        context.bezierCurveTo(66.87, 11.26, 51.39, 1.72, 43.23, 1.49);
        context.lineTo(31.61, 1.42);
        context.bezierCurveTo(23.71, 1.02, 6.73, 8.06, 1, 36.22);
        context.bezierCurveTo(6.98, 38.62, 25.91, 29.39, 25.59, 15.54);
        context.bezierCurveTo(27.58, 24.55, 64.55, 42.4, 72.38, 37);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair1(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 45), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 45);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// hairBoy1 Drawing
        context.beginPath();
        context.moveTo(36.37, 14.92);
        context.bezierCurveTo(33.9, 42.3, 2.69, 43.2, 1, 44);
        context.bezierCurveTo(3.4, -9.25, 36.24, 1.62, 36.4, 1.65);
        context.bezierCurveTo(36.55, 1.65, 69.27, -8.36, 71.98, 43.77);
        context.bezierCurveTo(71.98, 43.77, 36.34, 26.7, 36.37, 14.92);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair2(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 39), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 39);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1289 Drawing
        context.beginPath();
        context.moveTo(70.15, 38.18);
        context.bezierCurveTo(70.2, 37.76, 70.23, 37.35, 70.23, 36.92);
        context.bezierCurveTo(70.23, 32.3, 69.97, 19.24, 65.85, 17.79);
        context.bezierCurveTo(65.85, 17.73, 65.86, 17.66, 65.86, 17.59);
        context.bezierCurveTo(65.86, 11.74, 61.12, 7, 55.27, 7);
        context.bezierCurveTo(54.98, 7, 54.71, 7.02, 54.43, 7.05);
        context.bezierCurveTo(52.81, 3.26, 49.06, 0.6, 44.68, 0.6);
        context.bezierCurveTo(40.83, 0.6, 37.47, 2.66, 35.62, 5.74);
        context.bezierCurveTo(33.76, 2.66, 30.4, 0.6, 26.55, 0.6);
        context.bezierCurveTo(22.18, 0.6, 18.42, 3.26, 16.81, 7.05);
        context.bezierCurveTo(16.53, 7.02, 16.25, 7, 15.96, 7);
        context.bezierCurveTo(10.12, 7, 5.38, 11.74, 5.38, 17.59);
        context.bezierCurveTo(5.38, 17.66, 5.38, 17.73, 5.38, 17.79);
        context.bezierCurveTo(1.26, 19.24, 1, 31.36, 1, 35.98);
        context.bezierCurveTo(1, 36.4, 1.03, 36.82, 1.08, 37.23);
        context.bezierCurveTo(1.08, 37.23, 25.68, 32, 33.24, 20.52);
        context.bezierCurveTo(40.81, 9.04, 52.9, 37.35, 70.15, 38.18);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair3(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 47), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 47);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1291 Drawing
        context.beginPath();
        context.moveTo(31.86, 1.22);
        context.bezierCurveTo(22.47, 0.54, -0.28, 3.63, 1.06, 44.42);
        context.bezierCurveTo(7.2, 41.84, 16.28, 38.59, 18.8, 24.25);
        context.bezierCurveTo(18.8, 24.25, 25.89, 25.93, 21.97, 32.09);
        context.bezierCurveTo(21.97, 32.09, 30.76, 29.77, 29.25, 19.77);
        context.bezierCurveTo(29.16, 18.93, 40.64, 29.48, 53.52, 30.6);
        context.bezierCurveTo(61.85, 31.32, 70.06, 45, 70.06, 45);
        context.bezierCurveTo(75.24, 5.81, 52.07, 1.02, 41.95, 1.22);
        context.lineTo(31.86, 1.22);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair4(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 43), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 43);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1287 Drawing
        context.beginPath();
        context.moveTo(1, 41.65);
        context.bezierCurveTo(1.52, 33.22, 6.61, 23.72, 9.67, 19.12);
        context.bezierCurveTo(11.68, 15.2, 19.94, 1.31, 36.16, 0.83);
        context.bezierCurveTo(36.16, 0.83, 34.81, 3.35, 36.16, 6.99);
        context.bezierCurveTo(36.16, 6.99, 45.33, 1.11, 52.89, 0.83);
        context.bezierCurveTo(52.89, 0.83, 48.97, 4.22, 49.35, 8.87);
        context.bezierCurveTo(49.9, 9.05, 50.45, 9.26, 51.01, 9.5);
        context.bezierCurveTo(53.12, 8.11, 57.33, 5.87, 60.99, 7.27);
        context.bezierCurveTo(60.99, 7.27, 56.4, 9.79, 60.99, 17.35);
        context.lineTo(60.91, 17.35);
        context.bezierCurveTo(64.62, 22.1, 69.21, 30.33, 71.25, 40.52);
        context.bezierCurveTo(71.25, 40.52, 36.03, 46.1, 38.95, 27.27);
        context.bezierCurveTo(41.02, 13.91, 8.28, 45.65, 1, 41.65);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair5(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 80, 53), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 80, resizedFrame.h / 53);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1301 Drawing
        context.beginPath();
        context.moveTo(79.21, 50.76);
        context.bezierCurveTo(78.37, 41.68, 76.75, 34.49, 74.64, 28.8);
        context.bezierCurveTo(73, 23.7, 73.89, 17.05, 73.89, 17.05);
        context.bezierCurveTo(74.44, 6.59, 55.13, 1.85, 55.13, 1.85);
        context.bezierCurveTo(57.54, 3, 59.69, 6.66, 59.69, 6.66);
        context.bezierCurveTo(50.61, 4.26, 41.69, 5.63, 34.41, 7.97);
        context.bezierCurveTo(30.9, 7.99, 18.8, 15.28, 18.8, 15.28);
        context.bezierCurveTo(14, 18.21, 5.27, 17.67, 5.27, 17.67);
        context.bezierCurveTo(6.19, 21.15, 13.43, 24.31, 13.43, 24.31);
        context.bezierCurveTo(10.13, 27.52, 2, 26.11, 2, 26.11);
        context.bezierCurveTo(4.61, 28.49, 11, 30.28, 14.33, 31.59);
        context.lineTo(13.14, 32.15);
        context.bezierCurveTo(6.99, 35.96, 8.88, 43.18, 8.23, 51);
        context.bezierCurveTo(13.34, 47.22, 22.59, 43.85, 26.28, 30.91);
        context.lineTo(26.34, 30.91);
        context.bezierCurveTo(26.97, 31.08, 33.18, 32.88, 29.46, 38.74);
        context.bezierCurveTo(29.46, 38.74, 35.78, 37.06, 36.78, 30.45);
        context.bezierCurveTo(36.78, 30.45, 61.71, 27.77, 79.21, 50.76);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair7(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 39), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 39);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1299 Drawing
        context.beginPath();
        context.moveTo(72.38, 36.33);
        context.bezierCurveTo(66.87, 10.59, 51.39, 1.05, 43.23, 0.82);
        context.lineTo(31.61, 0.75);
        context.bezierCurveTo(23.71, 0.35, 6.73, 7.39, 1, 35.55);
        context.bezierCurveTo(1, 35.55, 13.53, 34.75, 20.44, 27.66);
        context.bezierCurveTo(28.36, 19.55, 32.51, 27.28, 39.77, 28.78);
        context.bezierCurveTo(47.03, 30.28, 53.19, 26.41, 57.94, 29.91);
        context.bezierCurveTo(62.69, 33.41, 61.81, 38.91, 72.38, 36.33);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair8(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 79, 50), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 79, resizedFrame.h / 50);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1303 Drawing
        context.beginPath();
        context.moveTo(77.35, 35.73);
        context.bezierCurveTo(76.56, 26.11, 73.36, 18.15, 73.36, 18.15);
        context.bezierCurveTo(67.83, 5.25, 59.69, 2.65, 59.69, 2.65);
        context.bezierCurveTo(50.61, 0.25, 41.69, 1.62, 34.41, 3.97);
        context.bezierCurveTo(30.9, 3.98, 18.8, 11.27, 18.8, 11.27);
        context.bezierCurveTo(14, 14.2, 5.27, 13.66, 5.27, 13.66);
        context.bezierCurveTo(6.19, 17.14, 13.43, 20.31, 13.43, 20.31);
        context.bezierCurveTo(10.13, 23.52, 2, 22.1, 2, 22.1);
        context.bezierCurveTo(4.07, 23.99, 8.5, 25.5, 11.91, 26.7);
        context.bezierCurveTo(10.36, 30.38, 7.21, 36.99, 6.22, 41.85);
        context.bezierCurveTo(6.22, 41.85, 21.82, 40.9, 27.49, 31.56);
        context.bezierCurveTo(33.37, 21.88, 41.53, 29.63, 48.79, 31.13);
        context.bezierCurveTo(56.05, 32.63, 61.57, 27.94, 64.99, 33.81);
        context.bezierCurveTo(66.98, 37.23, 68.5, 40.01, 71.75, 41.13);
        context.bezierCurveTo(73.44, 43.06, 74.72, 45.58, 76.44, 48);
        context.bezierCurveTo(77, 45.81, 77.3, 43.31, 77.42, 41.2);
        context.lineTo(77.35, 35.73);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair9(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 47), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 47);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1304 Drawing
        context.beginPath();
        context.moveTo(20.41, 20.37);
        context.bezierCurveTo(20.32, 19.53, 40.64, 29.48, 53.52, 30.6);
        context.bezierCurveTo(61.85, 31.32, 70.06, 45, 70.06, 45);
        context.bezierCurveTo(75.24, 5.81, 52.07, 1.02, 41.95, 1.22);
        context.lineTo(31.86, 1.22);
        context.bezierCurveTo(22.47, 0.54, -0.28, 3.63, 1.06, 44.42);
        context.bezierCurveTo(7.2, 41.84, 17.89, 34.71, 20.41, 20.37);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair10(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 59), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 59);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1297 Drawing
        context.beginPath();
        context.moveTo(57.11, 25.01);
        context.bezierCurveTo(59.36, 35.01, 72.57, 36.68, 72.57, 36.68);
        context.bezierCurveTo(67.07, 10.94, 51.59, 1.39, 43.43, 1.16);
        context.lineTo(37.28, 2.18);
        context.bezierCurveTo(36.95, 2.23, 36.62, 2.22, 36.3, 2.14);
        context.lineTo(31.81, 1.1);
        context.bezierCurveTo(23.93, 0.7, 7.03, 7.69, 1.25, 35.62);
        context.bezierCurveTo(1.22, 35.81, 1.19, 36.01, 1.18, 36.2);
        context.bezierCurveTo(1.03, 38.27, 0.32, 50.61, 3.36, 57.92);
        context.bezierCurveTo(6.05, 59.3, 24.19, 41.44, 29.97, 29.53);
        context.bezierCurveTo(40.42, 8, 55.94, 20.56, 57.11, 25.01);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair1(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 76), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 76);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1269 Drawing
        context.beginPath();
        context.moveTo(37.54, 72.55);
        context.bezierCurveTo(22.27, 72.52, 9.96, 58.86, 9.97, 42.74);
        context.bezierCurveTo(9.97, 38.28, 10.91, 34.02, 12.59, 30.18);
        context.lineTo(34.96, 30.18);
        context.lineTo(42.5, 30.18);
        context.lineTo(45.38, 26.81);
        context.lineTo(48.25, 30.18);
        context.lineTo(62.5, 30.18);
        context.bezierCurveTo(64.18, 34.02, 65.12, 38.28, 65.12, 42.74);
        context.bezierCurveTo(65.12, 58.86, 52.82, 72.52, 37.54, 72.55);
        context.closePath();
        context.moveTo(36.94, 2.17);
        context.bezierCurveTo(36.94, 2.17, -4.4, -12.8, 1.59, 75);
        context.lineTo(36.94, 75);
        context.lineTo(71.73, 75);
        context.bezierCurveTo(81.48, -12.17, 36.94, 2.17, 36.94, 2.17);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair2(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 95), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 95);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1275 Drawing
        context.beginPath();
        context.moveTo(37.9, 71.91);
        context.bezierCurveTo(34.76, 71.9, 31.74, 71.32, 28.93, 70.26);
        context.bezierCurveTo(18.84, 66.44, 11.42, 56.44, 10.44, 44.75);
        context.bezierCurveTo(13.14, 35.43, 40.81, 37.87, 37.9, 12.28);
        context.bezierCurveTo(53.18, 12.31, 65.48, 25.96, 65.48, 42.09);
        context.bezierCurveTo(65.48, 54.52, 58.18, 65.47, 47.85, 69.87);
        context.bezierCurveTo(44.76, 71.18, 41.41, 71.9, 37.9, 71.91);
        context.closePath();
        context.moveTo(37.3, 1.53);
        context.bezierCurveTo(37.3, 1.53, -5.89, -13.38, 1.95, 74.36);
        context.bezierCurveTo(2.78, 77.99, 2.56, 90.98, 3.1, 94);
        context.lineTo(72.25, 94);
        context.bezierCurveTo(72.25, 94, 71.9, 76.52, 72.08, 74.36);
        context.bezierCurveTo(79.92, -13.38, 37.3, 1.53, 37.3, 1.53);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair3(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 78), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 78);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1314 Drawing
        context.beginPath();
        context.moveTo(36.37, 15.34);
        context.bezierCurveTo(36.34, 27.11, 71.98, 44.18, 71.98, 44.18);
        context.bezierCurveTo(69.21, -8.22, 36.4, 2.06, 36.4, 2.06);
        context.bezierCurveTo(36.4, 2.06, 4, -8.47, 1, 44.41);
        context.bezierCurveTo(1, 44.41, 38.32, 27.96, 36.37, 15.34);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1271 Drawing
        context.beginPath();
        context.moveTo(14.07, 60.83);
        context.bezierCurveTo(11.62, 61.81, 5.85, 64.14, 4.65, 64.8);
        context.bezierCurveTo(3.12, 65.65, 2.61, 67.52, 5.5, 70.76);
        context.bezierCurveTo(8.39, 73.99, 12.47, 80.62, 16.72, 74.5);
        context.bezierCurveTo(19.73, 70.17, 20.61, 68.4, 20.86, 67.73);
        context.bezierCurveTo(18.26, 65.85, 15.96, 63.51, 14.07, 60.83);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1273 Drawing
        context.beginPath();
        context.moveTo(67.97, 64.8);
        context.bezierCurveTo(66.69, 64.09, 60.15, 61.47, 58.08, 60.64);
        context.bezierCurveTo(56.28, 63.23, 54.11, 65.51, 51.64, 67.36);
        context.bezierCurveTo(51.65, 67.46, 51.84, 68.65, 55.9, 74.5);
        context.bezierCurveTo(60.15, 80.62, 64.23, 73.99, 67.12, 70.76);
        context.bezierCurveTo(70.01, 67.52, 69.5, 65.65, 67.97, 64.8);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair4(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 91, 61), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 91, resizedFrame.h / 61);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1277 Drawing
        context.beginPath();
        context.moveTo(88.18, 36.14);
        context.bezierCurveTo(83.06, 55.91, 78.85, 44.35, 76.24, 26.8);
        context.bezierCurveTo(73.62, 9.26, 67.28, 2.16, 50.85, 1.42);
        context.bezierCurveTo(34.42, 0.67, 37.41, 16.35, 37.41, 16.35);
        context.lineTo(35.41, 24.14);
        context.bezierCurveTo(35.77, 24.12, 36.13, 24.11, 36.5, 24.11);
        context.bezierCurveTo(51.78, 24.14, 64.08, 37.79, 64.08, 53.92);
        context.bezierCurveTo(64.08, 55.44, 63.97, 56.94, 63.76, 58.41);
        context.bezierCurveTo(67.73, 59.39, 72.44, 60, 78.02, 60);
        context.bezierCurveTo(96.14, 60, 88.18, 36.14, 88.18, 36.14);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1279 Drawing
        context.beginPath();
        context.moveTo(36.37, 26.46);
        context.bezierCurveTo(34.97, 61.46, 1, 55.53, 1, 55.53);
        context.bezierCurveTo(3.63, 2.39, 36.4, 13.18, 36.4, 13.18);
        context.bezierCurveTo(36.4, 13.18, 67.17, 3.1, 71.98, 55.3);
        context.bezierCurveTo(71.98, 55.3, 36.34, 38.23, 36.37, 26.46);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair5(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 95, 100), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 95, resizedFrame.h / 100);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1281 Drawing
        context.beginPath();
        context.moveTo(47.52, 76.89);
        context.bezierCurveTo(32.24, 76.87, 19.94, 63.21, 19.94, 47.08);
        context.bezierCurveTo(18.85, 43.24, 49.77, 38.9, 66.4, 25.47);
        context.bezierCurveTo(71.76, 30.95, 75.1, 38.68, 75.09, 47.08);
        context.bezierCurveTo(75.1, 63.21, 62.8, 76.87, 47.52, 76.89);
        context.closePath();
        context.moveTo(82.05, 38.34);
        context.bezierCurveTo(82.1, 37.93, 82.13, 37.51, 82.13, 37.09);
        context.bezierCurveTo(82.13, 32.47, 81.87, 19.41, 77.75, 17.96);
        context.bezierCurveTo(77.75, 17.89, 77.76, 17.83, 77.76, 17.76);
        context.bezierCurveTo(77.76, 11.91, 73.02, 7.17, 67.17, 7.17);
        context.bezierCurveTo(66.89, 7.17, 66.61, 7.19, 66.33, 7.21);
        context.bezierCurveTo(64.72, 3.42, 60.96, 0.77, 56.58, 0.77);
        context.bezierCurveTo(52.73, 0.77, 49.37, 2.83, 47.52, 5.9);
        context.bezierCurveTo(45.66, 2.83, 42.3, 0.77, 38.45, 0.77);
        context.bezierCurveTo(34.08, 0.77, 30.32, 3.42, 28.71, 7.21);
        context.bezierCurveTo(28.43, 7.19, 28.15, 7.17, 27.87, 7.17);
        context.bezierCurveTo(22.02, 7.17, 17.28, 11.91, 17.28, 17.76);
        context.bezierCurveTo(17.28, 17.83, 17.29, 17.89, 17.29, 17.96);
        context.bezierCurveTo(13.16, 19.41, 12.9, 31.52, 12.9, 36.14);
        context.bezierCurveTo(12.9, 36.57, 12.93, 36.98, 12.98, 37.4);
        context.bezierCurveTo(12.98, 37.4, 7.76, 42.08, 7.22, 45.39);
        context.bezierCurveTo(6.88, 46.43, 6.69, 47.54, 6.69, 48.7);
        context.bezierCurveTo(6.69, 49.49, 6.78, 50.26, 6.95, 51.01);
        context.bezierCurveTo(4.52, 52.95, 2.95, 55.93, 2.95, 59.29);
        context.bezierCurveTo(2.95, 61.92, 3.92, 64.32, 5.51, 66.17);
        context.bezierCurveTo(4.98, 67.42, 4.69, 68.79, 4.69, 70.24);
        context.bezierCurveTo(4.69, 72.33, 5.31, 74.27, 6.36, 75.92);
        context.bezierCurveTo(4.27, 77.85, 2.95, 80.61, 2.95, 83.68);
        context.bezierCurveTo(2.95, 83.68, -1.44, 98.89, 2.95, 99);
        context.lineTo(93.06, 99);
        context.bezierCurveTo(93.06, 99, 95.05, 89.34, 92.08, 83.68);
        context.bezierCurveTo(92.08, 80.61, 90.77, 77.85, 88.68, 75.92);
        context.bezierCurveTo(89.73, 74.27, 90.34, 72.33, 90.34, 70.24);
        context.bezierCurveTo(90.34, 68.79, 90.05, 67.42, 89.53, 66.17);
        context.bezierCurveTo(91.12, 64.32, 92.08, 61.92, 92.08, 59.29);
        context.bezierCurveTo(92.08, 55.93, 90.52, 52.95, 88.09, 51.01);
        context.bezierCurveTo(88.25, 50.26, 88.35, 49.49, 88.35, 48.7);
        context.bezierCurveTo(88.35, 47.54, 88.16, 46.43, 87.81, 45.39);
        context.bezierCurveTo(87.28, 42.08, 82.05, 38.34, 82.05, 38.34);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair6(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 91, 69), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 91, resizedFrame.h / 69);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1285 Drawing
        context.beginPath();
        context.moveTo(25.4, 69);
        context.bezierCurveTo(20.27, 63.05, 17.98, 56.88, 17.98, 47.99);
        context.bezierCurveTo(17.98, 47.88, 17.99, 47.77, 17.99, 47.65);
        context.bezierCurveTo(17.99, 47.64, 17.99, 47.63, 17.99, 47.62);
        context.bezierCurveTo(17.95, 47.49, 17.96, 47.35, 18, 47.21);
        context.bezierCurveTo(18.24, 37.14, 23.86, 28.55, 30.8, 22.89);
        context.bezierCurveTo(35.58, 27.3, 52.9, 41.63, 73.03, 45.17);
        context.bezierCurveTo(73.1, 45.98, 73.14, 46.8, 73.14, 47.62);
        context.bezierCurveTo(73.14, 47.73, 73.13, 47.85, 73.13, 47.96);
        context.bezierCurveTo(73.13, 47.97, 73.13, 47.98, 73.13, 47.99);
        context.bezierCurveTo(73.13, 56.77, 71.04, 61.17, 66.81, 67.65);
        context.bezierCurveTo(66.69, 67.84, 66.81, 67.65, 66.81, 67.65);
        context.lineTo(87.58, 66.71);
        context.bezierCurveTo(89.16, 64.86, 90.13, 62.46, 90.13, 59.83);
        context.bezierCurveTo(90.13, 56.47, 88.56, 53.49, 86.13, 51.55);
        context.bezierCurveTo(86.3, 50.81, 86.39, 50.03, 86.39, 49.24);
        context.bezierCurveTo(86.39, 48.08, 86.2, 46.97, 85.86, 45.93);
        context.bezierCurveTo(85.33, 42.62, 80.1, 38.88, 80.1, 38.88);
        context.bezierCurveTo(80.15, 38.47, 80.18, 38.05, 80.18, 37.63);
        context.bezierCurveTo(80.18, 33.01, 79.92, 19.95, 75.79, 18.5);
        context.bezierCurveTo(75.79, 18.43, 75.8, 18.37, 75.8, 18.3);
        context.bezierCurveTo(75.8, 12.45, 71.06, 7.71, 65.22, 7.71);
        context.bezierCurveTo(64.93, 7.71, 64.65, 7.73, 64.37, 7.75);
        context.bezierCurveTo(62.76, 3.96, 59, 1.31, 54.63, 1.31);
        context.bezierCurveTo(50.78, 1.31, 47.42, 3.37, 45.56, 6.44);
        context.bezierCurveTo(43.71, 3.37, 40.35, 1.31, 36.5, 1.31);
        context.bezierCurveTo(32.12, 1.31, 28.37, 3.96, 26.75, 7.75);
        context.bezierCurveTo(26.47, 7.73, 26.19, 7.71, 25.91, 7.71);
        context.bezierCurveTo(20.06, 7.71, 15.32, 12.45, 15.32, 18.3);
        context.bezierCurveTo(15.32, 18.37, 15.33, 18.43, 15.33, 18.5);
        context.bezierCurveTo(11.21, 19.95, 10.95, 32.06, 10.95, 36.68);
        context.bezierCurveTo(10.95, 37.11, 10.98, 37.53, 11.03, 37.94);
        context.bezierCurveTo(11.03, 37.94, 5.8, 42.62, 5.27, 45.93);
        context.bezierCurveTo(4.92, 46.97, 4.73, 48.08, 4.73, 49.24);
        context.bezierCurveTo(4.73, 50.03, 4.83, 50.81, 4.99, 51.55);
        context.bezierCurveTo(2.56, 53.49, 1, 56.47, 1, 59.83);
        context.bezierCurveTo(1, 62.46, 1.96, 64.86, 3.55, 66.71);
        context.lineTo(24.61, 67.96);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair7(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 61), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 61);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1316 Drawing
        context.beginPath();
        context.moveTo(72.92, 43.13);
        context.bezierCurveTo(70.13, -6.12, 40.78, 1.15, 36.91, 2.18);
        context.bezierCurveTo(33.03, 1.15, 3.79, -4.48, 1, 44.76);
        context.bezierCurveTo(1, 44.76, 1.89, 63.81, 17.92, 59.31);
        context.bezierCurveTo(17.92, 59.31, 7.42, 38.48, 24.25, 28.65);
        context.bezierCurveTo(31.89, 24.18, 35.4, 21.69, 36.91, 20.01);
        context.bezierCurveTo(38.42, 21.69, 41.92, 24.18, 49.57, 28.65);
        context.bezierCurveTo(66.4, 38.48, 55.9, 58.31, 55.9, 58.31);
        context.bezierCurveTo(73.73, 58.31, 72.92, 43.13, 72.92, 43.13);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair8(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 61), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 61);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Oval 455 Drawing
        oval(context, 22.33, 0.92, 28.3, 28.3);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1306 Drawing
        context.beginPath();
        context.moveTo(31.93, 15.87);
        context.bezierCurveTo(22.93, 15.18, 3.3, 18.41, 1, 59);
        context.bezierCurveTo(1, 59, 50.07, 43.27, 48.12, 30.65);
        context.bezierCurveTo(48.09, 42.42, 71.98, 58.76, 71.98, 58.76);
        context.bezierCurveTo(69.9, 19.47, 50.93, 15.42, 41.57, 15.86);
        context.lineTo(31.93, 15.87);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair9(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 88, 48), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 88, resizedFrame.h / 48);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1310 Drawing
        context.beginPath();
        context.moveTo(2.26, 26.7);
        context.bezierCurveTo(5.95, 40.97, 8.99, 32.63, 10.88, 19.96);
        context.bezierCurveTo(12.76, 7.3, 17.34, 2.18, 29.2, 1.64);
        context.bezierCurveTo(41.05, 1.11, 38.9, 12.42, 38.9, 12.42);
        context.lineTo(40.34, 18.04);
        context.bezierCurveTo(40.08, 18.03, 39.82, 18.02, 39.55, 18.02);
        context.bezierCurveTo(28.53, 18.04, 19.65, 27.89, 19.65, 39.53);
        context.bezierCurveTo(19.65, 40.63, 19.73, 41.71, 19.88, 42.77);
        context.bezierCurveTo(17.02, 43.48, 13.62, 43.92, 9.59, 43.92);
        context.bezierCurveTo(-3.49, 43.92, 2.26, 26.7, 2.26, 26.7);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1308 Drawing
        context.beginPath();
        context.moveTo(84.75, 26.7);
        context.bezierCurveTo(81.05, 40.97, 78.02, 32.63, 76.13, 19.96);
        context.bezierCurveTo(74.24, 7.3, 69.66, 2.18, 57.81, 1.64);
        context.bezierCurveTo(45.95, 1.11, 48.11, 12.42, 48.11, 12.42);
        context.lineTo(46.66, 18.04);
        context.bezierCurveTo(46.93, 18.03, 47.19, 18.02, 47.45, 18.02);
        context.bezierCurveTo(58.48, 18.04, 67.36, 27.89, 67.35, 39.53);
        context.bezierCurveTo(67.35, 40.63, 67.27, 41.71, 67.12, 42.77);
        context.bezierCurveTo(69.99, 43.48, 73.39, 43.92, 77.42, 43.92);
        context.bezierCurveTo(90.49, 43.92, 84.75, 26.7, 84.75, 26.7);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1312 Drawing
        context.beginPath();
        context.moveTo(78.57, 47);
        context.bezierCurveTo(74.21, 16.35, 57.6, 11.17, 49.05, 11.57);
        context.lineTo(43.5, 12.75);
        context.lineTo(39.4, 11.58);
        context.bezierCurveTo(31.58, 10.98, 15.74, 13.34, 10.21, 40.6);
        context.bezierCurveTo(9.85, 42.36, 9.54, 44.22, 9.28, 46.18);
        context.bezierCurveTo(9.28, 46.18, 41.85, 41.14, 43.51, 23.29);
        context.bezierCurveTo(43.51, 23.29, 49.46, 42.62, 75.96, 46.62);
        context.lineTo(78.57, 47);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair10(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 46), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 46);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1283 Drawing
        context.beginPath();
        context.moveTo(70.06, 45);
        context.bezierCurveTo(70.36, 42.7, 70.57, 40.52, 70.68, 38.45);
        context.bezierCurveTo(70.71, 38.02, 70.73, 37.6, 70.75, 37.18);
        context.bezierCurveTo(72.02, 5.16, 51.35, 1.04, 41.95, 1.22);
        context.lineTo(37.7, 2.04);
        context.bezierCurveTo(37.12, 2.29, 36.45, 2.28, 35.87, 2.02);
        context.lineTo(31.86, 1.21);
        context.bezierCurveTo(22.47, 0.54, -0.28, 3.63, 1.06, 44.42);
        context.bezierCurveTo(7.2, 41.84, 15.1, 34.39, 17.62, 20.05);
        context.bezierCurveTo(17.62, 20.05, 24.54, 25.89, 20.62, 32.05);
        context.bezierCurveTo(20.62, 32.05, 30, 25.8, 28.49, 15.8);
        context.bezierCurveTo(29.04, 13.39, 30.52, 22.78, 46.63, 33.46);
        context.bezierCurveTo(47.49, 34.03, 48.38, 34.6, 49.32, 35.17);
        context.bezierCurveTo(50.72, 36.02, 52.2, 36.87, 53.79, 37.72);
        context.bezierCurveTo(57.86, 39.91, 62.6, 42.09, 68.12, 44.18);
        context.lineTo(70.06, 45);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawCatMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 37), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 37);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor42 = 'rgba(255, 169, 53, 1)';

        //// Bezier 2025 Drawing
        context.beginPath();
        context.moveTo(56.65, 15.62);
        context.bezierCurveTo(56.65, 15.62, 59.98, 4.58, 56.65, 1.18);
        context.bezierCurveTo(56.65, 1.18, 45.23, 2.5, 43.79, 7.75);
        context.lineTo(56.65, 15.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Bezier 2026 Drawing
        context.beginPath();
        context.moveTo(56.65, 15.62);
        context.bezierCurveTo(56.65, 15.62, 59.98, 4.58, 56.65, 1.18);
        context.bezierCurveTo(56.65, 1.18, 45.23, 2.5, 43.79, 7.75);
        context.lineTo(56.65, 15.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2027 Drawing
        context.beginPath();
        context.moveTo(5.81, 15.62);
        context.bezierCurveTo(5.81, 15.62, 2.48, 4.58, 5.81, 1.18);
        context.bezierCurveTo(5.81, 1.18, 17.23, 2.5, 18.67, 7.75);
        context.lineTo(5.81, 15.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Bezier 2028 Drawing
        context.beginPath();
        context.moveTo(5.81, 15.62);
        context.bezierCurveTo(5.81, 15.62, 2.48, 4.58, 5.81, 1.18);
        context.bezierCurveTo(5.81, 1.18, 17.23, 2.5, 18.67, 7.75);
        context.lineTo(5.81, 15.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2029 Drawing
        context.beginPath();
        context.moveTo(7.62, 11.7);
        context.lineTo(8.45, 5.51);
        context.lineTo(14.93, 8.6);
        context.lineTo(7.62, 11.7);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 2030 Drawing
        context.beginPath();
        context.moveTo(7.62, 11.7);
        context.lineTo(8.45, 5.51);
        context.lineTo(14.93, 8.6);
        context.lineTo(7.62, 11.7);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 68 Drawing
        roundedRect(context, 1, 19.67, 9.65, 2, 0.8);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Rectangle 69 Drawing
        roundedRect(context, 1, 19.67, 9.65, 2, 0.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2031 Drawing
        context.beginPath();
        context.moveTo(54.84, 11.7);
        context.lineTo(54.01, 5.51);
        context.lineTo(47.52, 8.6);
        context.lineTo(54.84, 11.7);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 2032 Drawing
        context.beginPath();
        context.moveTo(54.84, 11.7);
        context.lineTo(54.01, 5.51);
        context.lineTo(47.52, 8.6);
        context.lineTo(54.84, 11.7);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 70 Drawing
        roundedRect(context, 51.85, 19.67, 9.65, 2, 0.8);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Rectangle 71 Drawing
        roundedRect(context, 51.85, 19.67, 9.65, 2, 0.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2033 Drawing
        context.beginPath();
        context.moveTo(31.25, 30.2);
        context.bezierCurveTo(33.41, 30.2, 35.57, 31.98, 35.57, 31.98);
        context.bezierCurveTo(42.29, 38.38, 49.7, 35.22, 49.7, 35.22);
        context.bezierCurveTo(49.7, 35.22, 59.81, 30.9, 57.88, 17.54);
        context.bezierCurveTo(55.95, 4.18, 31.25, 6.35, 31.25, 6.35);
        context.lineTo(31.24, 6.35);
        context.bezierCurveTo(31.24, 6.35, 6.54, 4.18, 4.61, 17.54);
        context.bezierCurveTo(2.68, 30.9, 12.79, 35.22, 12.79, 35.22);
        context.bezierCurveTo(12.79, 35.22, 20.2, 38.38, 26.92, 31.98);
        context.bezierCurveTo(26.92, 31.98, 29.08, 30.2, 31.24, 30.2);
        context.lineTo(31.25, 30.2);
        context.closePath();
        context.fillStyle = fillColor42;
        context.fill();


        //// Bezier 2034 Drawing
        context.beginPath();
        context.moveTo(31.25, 30.2);
        context.bezierCurveTo(33.41, 30.2, 35.57, 31.98, 35.57, 31.98);
        context.bezierCurveTo(42.29, 38.38, 49.7, 35.22, 49.7, 35.22);
        context.bezierCurveTo(49.7, 35.22, 59.81, 30.9, 57.88, 17.54);
        context.bezierCurveTo(55.95, 4.18, 31.25, 6.35, 31.25, 6.35);
        context.lineTo(31.24, 6.35);
        context.bezierCurveTo(31.24, 6.35, 6.54, 4.18, 4.61, 17.54);
        context.bezierCurveTo(2.68, 30.9, 12.79, 35.22, 12.79, 35.22);
        context.bezierCurveTo(12.79, 35.22, 20.2, 38.38, 26.92, 31.98);
        context.bezierCurveTo(26.92, 31.98, 29.08, 30.2, 31.24, 30.2);
        context.lineTo(31.25, 30.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 643 Drawing
        oval(context, 14.53, 17.42, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 644 Drawing
        oval(context, 15.78, 18.67, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 645 Drawing
        oval(context, 41.43, 17.42, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 646 Drawing
        oval(context, 42.68, 18.67, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2035 Drawing
        context.beginPath();
        context.moveTo(35.01, 23.65);
        context.bezierCurveTo(34.08, 21.87, 30.91, 22.18, 30.91, 22.18);
        context.bezierCurveTo(30.91, 22.18, 27.75, 21.87, 26.82, 23.65);
        context.bezierCurveTo(25.89, 25.43, 28.52, 25.27, 28.52, 25.27);
        context.bezierCurveTo(29.4, 25.33, 29.45, 24.96, 29.37, 26.45);
        context.bezierCurveTo(29.29, 27.94, 30.91, 27.51, 30.91, 27.51);
        context.bezierCurveTo(30.91, 27.51, 32.53, 27.94, 32.46, 26.45);
        context.bezierCurveTo(32.38, 24.96, 32.43, 25.33, 33.31, 25.27);
        context.bezierCurveTo(33.31, 25.27, 35.94, 25.43, 35.01, 23.65);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Group 57
        context.save();

        //// Clip Clip 57

        context.beginPath();
        context.moveTo(31.25, 6.35);
        context.lineTo(31.24, 6.35);
        context.bezierCurveTo(31.24, 6.35, 6.54, 4.18, 4.61, 17.54);
        context.lineTo(4.61, 17.54);
        context.bezierCurveTo(2.68, 30.9, 12.79, 35.22, 12.79, 35.22);
        context.lineTo(12.79, 35.22);
        context.bezierCurveTo(12.79, 35.22, 20.2, 38.38, 26.92, 31.98);
        context.lineTo(26.92, 31.98);
        context.bezierCurveTo(26.92, 31.98, 29.08, 30.2, 31.24, 30.2);
        context.lineTo(31.24, 30.2);
        context.lineTo(31.25, 30.2);
        context.bezierCurveTo(33.41, 30.2, 35.57, 31.98, 35.57, 31.98);
        context.lineTo(35.57, 31.98);
        context.bezierCurveTo(42.29, 38.38, 49.7, 35.22, 49.7, 35.22);
        context.lineTo(49.7, 35.22);
        context.bezierCurveTo(49.7, 35.22, 59.81, 30.9, 57.88, 17.54);
        context.lineTo(57.88, 17.54);
        context.bezierCurveTo(56.42, 7.45, 41.97, 6.22, 34.99, 6.22);
        context.lineTo(34.99, 6.22);
        context.bezierCurveTo(32.72, 6.22, 31.25, 6.35, 31.25, 6.35);
        context.closePath();
        context.clip();



        //// Rectangle 72 Drawing
        roundedRect(context, 22.58, 4.32, 6.5, 10.8, 2.8);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Rectangle 73 Drawing
        roundedRect(context, 22.58, 4.32, 6.5, 10.8, 2.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 74 Drawing
        roundedRect(context, 32.38, 4.32, 6.5, 10.8, 2.8);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Rectangle 75 Drawing
        roundedRect(context, 32.38, 4.32, 6.5, 10.8, 2.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2037 Drawing
        context.beginPath();
        context.moveTo(31.25, 30.2);
        context.bezierCurveTo(33.41, 30.2, 35.57, 31.98, 35.57, 31.98);
        context.bezierCurveTo(42.29, 38.38, 49.7, 35.22, 49.7, 35.22);
        context.bezierCurveTo(49.7, 35.22, 59.81, 30.9, 57.88, 17.54);
        context.bezierCurveTo(55.95, 4.18, 31.25, 6.35, 31.25, 6.35);
        context.lineTo(31.24, 6.35);
        context.bezierCurveTo(31.24, 6.35, 6.54, 4.18, 4.61, 17.54);
        context.bezierCurveTo(2.68, 30.9, 12.79, 35.22, 12.79, 35.22);
        context.bezierCurveTo(12.79, 35.22, 20.2, 38.38, 26.92, 31.98);
        context.bezierCurveTo(26.92, 31.98, 29.08, 30.2, 31.24, 30.2);
        context.lineTo(31.25, 30.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPandaMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 40), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 40);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Rectangle 66 Drawing
        roundedRect(context, 1, 23.64, 9.65, 2, 0.8);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Rectangle 67 Drawing
        roundedRect(context, 1, 23.64, 9.65, 2, 0.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2012 Drawing
        context.beginPath();
        context.moveTo(6.32, 17.37);
        context.bezierCurveTo(6.32, 17.37, -3.41, 13.59, 3.46, 5.79);
        context.bezierCurveTo(10.33, -2, 18.82, 3.32, 17.43, 11.12);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2013 Drawing
        context.beginPath();
        context.moveTo(8.71, 15.29);
        context.bezierCurveTo(8.71, 15.29, 3.23, 12.01, 5.24, 9.67);
        context.bezierCurveTo(7.24, 7.34, 10.56, 5.33, 12.42, 7.19);
        context.bezierCurveTo(14.27, 9.04, 13.88, 13.28, 13.88, 13.28);
        context.lineTo(8.71, 15.29);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 2014 Drawing
        context.beginPath();
        context.moveTo(8.71, 15.29);
        context.bezierCurveTo(8.71, 15.29, 3.23, 12.01, 5.24, 9.67);
        context.bezierCurveTo(7.24, 7.34, 10.56, 5.33, 12.42, 7.19);
        context.bezierCurveTo(14.27, 9.04, 13.88, 13.28, 13.88, 13.28);
        context.lineTo(8.71, 15.29);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2015 Drawing
        context.beginPath();
        context.moveTo(56.2, 17.37);
        context.bezierCurveTo(56.2, 17.37, 65.92, 13.59, 59.05, 5.79);
        context.bezierCurveTo(52.18, -2, 43.69, 3.32, 45.08, 11.12);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2016 Drawing
        context.beginPath();
        context.moveTo(51.86, 24.77);
        context.bezierCurveTo(51.86, 25.25, 52.25, 25.64, 52.73, 25.64);
        context.lineTo(60.63, 25.64);
        context.bezierCurveTo(61.11, 25.64, 61.51, 25.25, 61.51, 24.77);
        context.lineTo(61.51, 24.51);
        context.bezierCurveTo(61.51, 24.03, 61.11, 23.63, 60.63, 23.63);
        context.lineTo(52.73, 23.63);
        context.bezierCurveTo(52.25, 23.63, 51.86, 24.03, 51.86, 24.51);
        context.lineTo(51.86, 24.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2017 Drawing
        context.beginPath();
        context.moveTo(51.86, 24.77);
        context.bezierCurveTo(51.86, 25.25, 52.25, 25.64, 52.73, 25.64);
        context.lineTo(60.63, 25.64);
        context.bezierCurveTo(61.11, 25.64, 61.51, 25.25, 61.51, 24.77);
        context.lineTo(61.51, 24.51);
        context.bezierCurveTo(61.51, 24.03, 61.11, 23.63, 60.63, 23.63);
        context.lineTo(52.73, 23.63);
        context.bezierCurveTo(52.25, 23.63, 51.86, 24.03, 51.86, 24.51);
        context.lineTo(51.86, 24.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2018 Drawing
        context.beginPath();
        context.moveTo(53.8, 15.29);
        context.bezierCurveTo(53.8, 15.29, 59.28, 12.01, 57.28, 9.67);
        context.bezierCurveTo(55.27, 7.34, 51.95, 5.33, 50.1, 7.19);
        context.bezierCurveTo(48.24, 9.04, 48.63, 13.28, 48.63, 13.28);
        context.lineTo(53.8, 15.29);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 2019 Drawing
        context.beginPath();
        context.moveTo(53.8, 15.29);
        context.bezierCurveTo(53.8, 15.29, 59.28, 12.01, 57.28, 9.67);
        context.bezierCurveTo(55.27, 7.34, 51.95, 5.33, 50.1, 7.19);
        context.bezierCurveTo(48.24, 9.04, 48.63, 13.28, 48.63, 13.28);
        context.lineTo(53.8, 15.29);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2020 Drawing
        context.beginPath();
        context.moveTo(31.26, 33.2);
        context.bezierCurveTo(33.42, 33.2, 35.58, 34.98, 35.58, 34.98);
        context.bezierCurveTo(42.3, 41.38, 49.71, 38.22, 49.71, 38.22);
        context.bezierCurveTo(49.71, 38.22, 59.82, 33.9, 57.89, 20.54);
        context.bezierCurveTo(55.96, 7.18, 31.26, 9.35, 31.26, 9.35);
        context.lineTo(31.25, 9.35);
        context.bezierCurveTo(31.25, 9.35, 6.55, 7.18, 4.62, 20.54);
        context.bezierCurveTo(2.69, 33.9, 12.8, 38.22, 12.8, 38.22);
        context.bezierCurveTo(12.8, 38.22, 20.21, 41.38, 26.93, 34.98);
        context.bezierCurveTo(26.93, 34.98, 29.09, 33.2, 31.25, 33.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2021 Drawing
        context.beginPath();
        context.moveTo(12.11, 19.15);
        context.bezierCurveTo(11.45, 20.21, 9.1, 22.93, 9.95, 25.79);
        context.bezierCurveTo(10.8, 28.65, 13.27, 30.5, 16.59, 29.96);
        context.bezierCurveTo(19.9, 29.42, 24.15, 24.48, 24.46, 23.47);
        context.bezierCurveTo(24.77, 22.47, 26.77, 17.53, 23.76, 17.37);
        context.bezierCurveTo(20.75, 17.22, 13.65, 16.68, 12.11, 19.15);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2022 Drawing
        context.beginPath();
        context.moveTo(12.11, 19.15);
        context.bezierCurveTo(11.45, 20.21, 9.1, 22.93, 9.95, 25.79);
        context.bezierCurveTo(10.8, 28.65, 13.27, 30.5, 16.59, 29.96);
        context.bezierCurveTo(19.9, 29.42, 24.15, 24.48, 24.46, 23.47);
        context.bezierCurveTo(24.77, 22.47, 26.77, 17.53, 23.76, 17.37);
        context.bezierCurveTo(20.75, 17.22, 13.65, 16.68, 12.11, 19.15);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 637 Drawing
        oval(context, 15.13, 20.94, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 638 Drawing
        oval(context, 16.38, 22.19, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2023 Drawing
        context.beginPath();
        context.moveTo(50.41, 19.15);
        context.bezierCurveTo(51.06, 20.21, 53.42, 22.93, 52.57, 25.79);
        context.bezierCurveTo(51.72, 28.65, 49.25, 30.5, 45.93, 29.96);
        context.bezierCurveTo(42.61, 29.42, 38.36, 24.48, 38.05, 23.47);
        context.bezierCurveTo(37.74, 22.47, 35.74, 17.53, 38.75, 17.37);
        context.bezierCurveTo(41.76, 17.22, 48.87, 16.68, 50.41, 19.15);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2024 Drawing
        context.beginPath();
        context.moveTo(50.41, 19.15);
        context.bezierCurveTo(51.06, 20.21, 53.42, 22.93, 52.57, 25.79);
        context.bezierCurveTo(51.72, 28.65, 49.25, 30.5, 45.93, 29.96);
        context.bezierCurveTo(42.61, 29.42, 38.36, 24.48, 38.05, 23.47);
        context.bezierCurveTo(37.74, 22.47, 35.74, 17.53, 38.75, 17.37);
        context.bezierCurveTo(41.76, 17.22, 48.87, 16.68, 50.41, 19.15);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 639 Drawing
        oval(context, 41.98, 20.94, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 640 Drawing
        oval(context, 43.23, 22.19, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 641 Drawing
        oval(context, 26.78, 25.64, 8, 4.9);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Oval 642 Drawing
        oval(context, 26.78, 25.64, 8, 4.9);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawDisguiseMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 56, 37), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 56, resizedFrame.h / 37);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2002 Drawing
        context.beginPath();
        context.moveTo(28.65, 27.22);
        context.bezierCurveTo(29.63, 27.16, 30.98, 26.97, 31.56, 26.27);
        context.bezierCurveTo(32.51, 25.15, 32.37, 25.42, 34.2, 23.58);
        context.bezierCurveTo(36.04, 21.75, 34.29, 18.97, 33.49, 17.72);
        context.bezierCurveTo(32.68, 16.46, 30.31, 14.09, 29.46, 11.85);
        context.bezierCurveTo(28.6, 9.61, 28.11, 9.34, 28.11, 9.34);
        context.bezierCurveTo(27.92, 9.1, 27.78, 9.09, 27.68, 9.15);
        context.bezierCurveTo(27.59, 9.09, 27.45, 9.1, 27.25, 9.34);
        context.bezierCurveTo(27.25, 9.34, 26.76, 9.61, 25.91, 11.85);
        context.bezierCurveTo(25.06, 14.09, 22.69, 16.46, 21.88, 17.72);
        context.bezierCurveTo(21.07, 18.97, 19.33, 21.75, 21.16, 23.58);
        context.bezierCurveTo(23, 25.42, 22.86, 25.15, 23.8, 26.27);
        context.bezierCurveTo(24.34, 26.91, 25.52, 27.13, 26.47, 27.2);
        context.lineTo(28.65, 27.22);
        context.closePath();
        context.fillStyle = AvatarStyleKit.skin4;
        context.fill();


        //// Bezier 2003 Drawing
        context.beginPath();
        context.moveTo(28.65, 27.22);
        context.bezierCurveTo(29.63, 27.16, 30.98, 26.97, 31.56, 26.27);
        context.bezierCurveTo(32.51, 25.15, 32.37, 25.42, 34.2, 23.58);
        context.bezierCurveTo(36.04, 21.75, 34.29, 18.97, 33.49, 17.72);
        context.bezierCurveTo(32.68, 16.46, 30.31, 14.09, 29.46, 11.85);
        context.bezierCurveTo(28.6, 9.61, 28.11, 9.34, 28.11, 9.34);
        context.bezierCurveTo(27.92, 9.1, 27.78, 9.09, 27.68, 9.15);
        context.bezierCurveTo(27.59, 9.09, 27.45, 9.1, 27.25, 9.34);
        context.bezierCurveTo(27.25, 9.34, 26.76, 9.61, 25.91, 11.85);
        context.bezierCurveTo(25.06, 14.09, 22.69, 16.46, 21.88, 17.72);
        context.bezierCurveTo(21.07, 18.97, 19.33, 21.75, 21.16, 23.58);
        context.bezierCurveTo(23, 25.42, 22.86, 25.15, 23.8, 26.27);
        context.bezierCurveTo(24.34, 26.91, 25.52, 27.13, 26.47, 27.2);
        context.lineTo(28.65, 27.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2004 Drawing
        context.beginPath();
        context.moveTo(27.83, 28.98);
        context.bezierCurveTo(27.83, 28.98, 21.61, 29.2, 19.52, 24.9);
        context.bezierCurveTo(19.52, 24.9, 18.47, 26.61, 15.38, 28.7);
        context.bezierCurveTo(12.3, 30.79, 8.39, 33.49, 7.61, 26.66);
        context.bezierCurveTo(7.61, 26.66, 4.91, 27.54, 7.12, 31.68);
        context.bezierCurveTo(9.32, 35.81, 11.58, 36.64, 19.79, 35.59);
        context.bezierCurveTo(28, 34.54, 27.83, 28.98, 27.83, 28.98);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair6;
        context.fill();


        //// Bezier 2005 Drawing
        context.beginPath();
        context.moveTo(27.83, 28.98);
        context.bezierCurveTo(27.83, 28.98, 21.61, 29.2, 19.52, 24.9);
        context.bezierCurveTo(19.52, 24.9, 18.47, 26.61, 15.38, 28.7);
        context.bezierCurveTo(12.3, 30.79, 8.39, 33.49, 7.61, 26.66);
        context.bezierCurveTo(7.61, 26.66, 4.91, 27.54, 7.12, 31.68);
        context.bezierCurveTo(9.32, 35.81, 11.58, 36.64, 19.79, 35.59);
        context.bezierCurveTo(28, 34.54, 27.83, 28.98, 27.83, 28.98);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2006 Drawing
        context.beginPath();
        context.moveTo(27.54, 28.98);
        context.bezierCurveTo(27.54, 28.98, 33.75, 29.2, 35.85, 24.9);
        context.bezierCurveTo(35.85, 24.9, 36.9, 26.61, 39.98, 28.7);
        context.bezierCurveTo(43.07, 30.79, 46.98, 33.49, 47.75, 26.66);
        context.bezierCurveTo(47.75, 26.66, 50.45, 27.54, 48.25, 31.68);
        context.bezierCurveTo(46.04, 35.81, 43.78, 36.64, 35.57, 35.59);
        context.bezierCurveTo(27.36, 34.54, 27.54, 28.98, 27.54, 28.98);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair6;
        context.fill();


        //// Bezier 2007 Drawing
        context.beginPath();
        context.moveTo(27.54, 28.98);
        context.bezierCurveTo(27.54, 28.98, 33.75, 29.2, 35.85, 24.9);
        context.bezierCurveTo(35.85, 24.9, 36.9, 26.61, 39.98, 28.7);
        context.bezierCurveTo(43.07, 30.79, 46.98, 33.49, 47.75, 26.66);
        context.bezierCurveTo(47.75, 26.66, 50.45, 27.54, 48.25, 31.68);
        context.bezierCurveTo(46.04, 35.81, 43.78, 36.64, 35.57, 35.59);
        context.bezierCurveTo(27.36, 34.54, 27.54, 28.98, 27.54, 28.98);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2008 Drawing
        context.beginPath();
        context.moveTo(54.34, 3.07);
        context.bezierCurveTo(51.75, 0.32, 38.42, 1.97, 34.83, 2.63);
        context.bezierCurveTo(31.25, 3.3, 27.68, 4.07, 27.68, 4.07);
        context.bezierCurveTo(27.68, 4.07, 24.11, 3.3, 20.53, 2.63);
        context.bezierCurveTo(16.95, 1.97, 3.61, 0.32, 1.02, 3.07);
        context.bezierCurveTo(1.02, 3.07, 0.97, 4.56, 1.02, 6.16);
        context.bezierCurveTo(1.02, 6.16, 2.02, 6.38, 2.24, 7.87);
        context.bezierCurveTo(2.46, 9.36, 2.73, 16.8, 5.87, 18.17);
        context.bezierCurveTo(9.01, 19.55, 18.27, 22.03, 22.02, 14.98);
        context.bezierCurveTo(25.77, 7.92, 25.96, 7.15, 27.68, 7.37);
        context.bezierCurveTo(29.4, 7.15, 29.6, 7.92, 33.35, 14.98);
        context.bezierCurveTo(37.09, 22.03, 46.35, 19.55, 49.49, 18.17);
        context.bezierCurveTo(52.63, 16.8, 52.91, 9.36, 53.13, 7.87);
        context.bezierCurveTo(53.35, 6.38, 54.34, 6.16, 54.34, 6.16);
        context.bezierCurveTo(54.4, 4.56, 54.34, 3.07, 54.34, 3.07);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col12;
        context.fill();


        //// Bezier 2009 Drawing
        context.beginPath();
        context.moveTo(54.34, 3.07);
        context.bezierCurveTo(51.75, 0.32, 38.42, 1.97, 34.83, 2.63);
        context.bezierCurveTo(31.25, 3.3, 27.68, 4.07, 27.68, 4.07);
        context.bezierCurveTo(27.68, 4.07, 24.11, 3.3, 20.53, 2.63);
        context.bezierCurveTo(16.95, 1.97, 3.61, 0.32, 1.02, 3.07);
        context.bezierCurveTo(1.02, 3.07, 0.97, 4.56, 1.02, 6.16);
        context.bezierCurveTo(1.02, 6.16, 2.02, 6.38, 2.24, 7.87);
        context.bezierCurveTo(2.46, 9.36, 2.73, 16.8, 5.87, 18.17);
        context.bezierCurveTo(9.01, 19.55, 18.27, 22.03, 22.02, 14.98);
        context.bezierCurveTo(25.77, 7.92, 25.96, 7.15, 27.68, 7.37);
        context.bezierCurveTo(29.4, 7.15, 29.6, 7.92, 33.35, 14.98);
        context.bezierCurveTo(37.09, 22.03, 46.35, 19.55, 49.49, 18.17);
        context.bezierCurveTo(52.63, 16.8, 52.91, 9.36, 53.13, 7.87);
        context.bezierCurveTo(53.35, 6.38, 54.34, 6.16, 54.34, 6.16);
        context.bezierCurveTo(54.4, 4.56, 54.34, 3.07, 54.34, 3.07);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSnorkel(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 119), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 119);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 1993 Drawing
        context.beginPath();
        context.moveTo(44.49, 118);
        context.bezierCurveTo(39.6, 118, 35.56, 116.5, 32.4, 113.52);
        context.bezierCurveTo(25.86, 107.39, 25.64, 97.21, 25.63, 96.78);
        context.lineTo(30.63, 96.71);
        context.lineTo(28.13, 96.75);
        context.lineTo(30.63, 96.71);
        context.bezierCurveTo(30.63, 96.79, 30.85, 105.23, 35.84, 109.9);
        context.bezierCurveTo(38.23, 112.13, 41.43, 113.17, 45.36, 112.98);
        context.bezierCurveTo(45.94, 112.93, 61.74, 111.32, 62.84, 95.17);
        context.lineTo(62.84, 26.97);
        context.lineTo(67.84, 26.97);
        context.lineTo(67.83, 95.41);
        context.bezierCurveTo(66.76, 111.87, 52.88, 117.36, 45.69, 117.97);
        context.bezierCurveTo(45.29, 117.99, 44.88, 118, 44.49, 118);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1994 Drawing
        context.beginPath();
        context.moveTo(44.49, 118);
        context.bezierCurveTo(39.6, 118, 35.56, 116.5, 32.4, 113.52);
        context.bezierCurveTo(25.86, 107.39, 25.64, 97.21, 25.63, 96.78);
        context.lineTo(30.63, 96.71);
        context.lineTo(28.13, 96.75);
        context.lineTo(30.63, 96.71);
        context.bezierCurveTo(30.63, 96.79, 30.85, 105.23, 35.84, 109.9);
        context.bezierCurveTo(38.23, 112.13, 41.43, 113.17, 45.36, 112.98);
        context.bezierCurveTo(45.94, 112.93, 61.74, 111.32, 62.84, 95.17);
        context.lineTo(62.84, 26.97);
        context.lineTo(67.84, 26.97);
        context.lineTo(67.83, 95.41);
        context.bezierCurveTo(66.76, 111.87, 52.88, 117.36, 45.69, 117.97);
        context.bezierCurveTo(45.29, 117.99, 44.88, 118, 44.49, 118);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1995 Drawing
        context.beginPath();
        context.moveTo(4.54, 73.37);
        context.lineTo(2.78, 73.37);
        context.bezierCurveTo(1.8, 73.37, 1, 72.57, 1, 71.59);
        context.lineTo(1, 66.08);
        context.bezierCurveTo(1, 65.1, 1.8, 64.3, 2.78, 64.3);
        context.lineTo(4.54, 64.3);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1996 Drawing
        context.beginPath();
        context.moveTo(53.88, 64.23);
        context.lineTo(55.63, 64.23);
        context.bezierCurveTo(56.62, 64.23, 57.42, 65.03, 57.42, 66.01);
        context.lineTo(57.42, 71.51);
        context.bezierCurveTo(57.42, 72.5, 56.62, 73.3, 55.63, 73.3);
        context.lineTo(53.88, 73.3);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1997 Drawing
        context.beginPath();
        context.moveTo(34.79, 55.22);
        context.lineTo(29.15, 55.22);
        context.lineTo(23.51, 55.22);
        context.lineTo(7.15, 55.22);
        context.bezierCurveTo(5.39, 55.22, 3.96, 56.65, 3.96, 58.4);
        context.lineTo(3.96, 80.52);
        context.bezierCurveTo(3.96, 82.27, 5.39, 83.71, 7.15, 83.71);
        context.lineTo(19.36, 83.71);
        context.bezierCurveTo(21.11, 83.71, 23.48, 82.63, 24.64, 81.31);
        context.lineTo(25.09, 80.79);
        context.bezierCurveTo(26.24, 79.47, 27.51, 78.39, 27.91, 78.39);
        context.lineTo(29.15, 78.39);
        context.lineTo(30.39, 78.39);
        context.bezierCurveTo(30.79, 78.39, 32.06, 79.47, 33.21, 80.79);
        context.lineTo(33.66, 81.31);
        context.bezierCurveTo(34.82, 82.63, 37.19, 83.71, 38.95, 83.71);
        context.lineTo(51.15, 83.71);
        context.bezierCurveTo(52.91, 83.71, 54.34, 82.27, 54.34, 80.52);
        context.lineTo(54.34, 58.4);
        context.bezierCurveTo(54.34, 56.65, 52.91, 55.22, 51.15, 55.22);
        context.lineTo(34.79, 55.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1998 Drawing
        context.beginPath();
        context.moveTo(34.79, 55.22);
        context.lineTo(29.15, 55.22);
        context.lineTo(23.51, 55.22);
        context.lineTo(7.15, 55.22);
        context.bezierCurveTo(5.39, 55.22, 3.96, 56.65, 3.96, 58.4);
        context.lineTo(3.96, 80.52);
        context.bezierCurveTo(3.96, 82.27, 5.39, 83.71, 7.15, 83.71);
        context.lineTo(19.36, 83.71);
        context.bezierCurveTo(21.11, 83.71, 23.48, 82.63, 24.64, 81.31);
        context.lineTo(25.09, 80.79);
        context.bezierCurveTo(26.24, 79.47, 27.51, 78.39, 27.91, 78.39);
        context.lineTo(29.15, 78.39);
        context.lineTo(30.39, 78.39);
        context.bezierCurveTo(30.79, 78.39, 32.06, 79.47, 33.21, 80.79);
        context.lineTo(33.66, 81.31);
        context.bezierCurveTo(34.82, 82.63, 37.19, 83.71, 38.95, 83.71);
        context.lineTo(51.15, 83.71);
        context.bezierCurveTo(52.91, 83.71, 54.34, 82.27, 54.34, 80.52);
        context.lineTo(54.34, 58.4);
        context.bezierCurveTo(54.34, 56.65, 52.91, 55.22, 51.15, 55.22);
        context.lineTo(34.79, 55.22);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1999 Drawing
        context.beginPath();
        context.moveTo(34.79, 55.22);
        context.lineTo(29.15, 55.22);
        context.lineTo(23.51, 55.22);
        context.lineTo(7.15, 55.22);
        context.bezierCurveTo(5.39, 55.22, 3.96, 56.65, 3.96, 58.4);
        context.lineTo(3.96, 80.52);
        context.bezierCurveTo(3.96, 82.27, 5.39, 83.71, 7.15, 83.71);
        context.lineTo(19.36, 83.71);
        context.bezierCurveTo(21.11, 83.71, 23.48, 82.63, 24.64, 81.31);
        context.lineTo(25.09, 80.79);
        context.bezierCurveTo(26.24, 79.47, 27.51, 78.39, 27.91, 78.39);
        context.lineTo(29.15, 78.39);
        context.lineTo(30.39, 78.39);
        context.bezierCurveTo(30.79, 78.39, 32.06, 79.47, 33.21, 80.79);
        context.lineTo(33.66, 81.31);
        context.bezierCurveTo(34.82, 82.63, 37.19, 83.71, 38.95, 83.71);
        context.lineTo(51.15, 83.71);
        context.bezierCurveTo(52.91, 83.71, 54.34, 82.27, 54.34, 80.52);
        context.lineTo(54.34, 58.4);
        context.bezierCurveTo(54.34, 56.65, 52.91, 55.22, 51.15, 55.22);
        context.lineTo(34.79, 55.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2000 Drawing
        context.beginPath();
        context.moveTo(28.62, 96.56);
        context.bezierCurveTo(28.62, 96.56, 35.6, 96.86, 38.23, 92.81);
        context.bezierCurveTo(40.85, 88.77, 37.1, 88.77, 37.1, 88.77);
        context.lineTo(28.62, 88.77);
        context.lineTo(28.13, 88.79);
        context.lineTo(19.65, 88.79);
        context.bezierCurveTo(19.65, 88.79, 15.9, 88.79, 18.53, 92.84);
        context.bezierCurveTo(21.15, 96.89, 28.13, 96.59, 28.13, 96.59);
        context.lineTo(28.62, 96.56);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 2001 Drawing
        context.beginPath();
        context.moveTo(28.62, 96.56);
        context.bezierCurveTo(28.62, 96.56, 35.6, 96.86, 38.23, 92.81);
        context.bezierCurveTo(40.85, 88.77, 37.1, 88.77, 37.1, 88.77);
        context.lineTo(28.62, 88.77);
        context.lineTo(28.13, 88.79);
        context.lineTo(19.65, 88.79);
        context.bezierCurveTo(19.65, 88.79, 15.9, 88.79, 18.53, 92.84);
        context.bezierCurveTo(21.15, 96.89, 28.13, 96.59, 28.13, 96.59);
        context.lineTo(28.62, 96.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 627 Drawing
        oval(context, 64.07, 18.11, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 628 Drawing
        oval(context, 64.07, 18.11, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 629 Drawing
        oval(context, 65.22, 8.96, 5.9, 5.9);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 630 Drawing
        oval(context, 65.22, 8.96, 5.9, 5.9);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 631 Drawing
        oval(context, 60.47, 13.41, 3.5, 3.5);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 632 Drawing
        oval(context, 60.47, 13.41, 3.5, 3.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 633 Drawing
        oval(context, 59.97, 4.96, 4.7, 4.7);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 634 Drawing
        oval(context, 59.97, 4.96, 4.7, 4.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 635 Drawing
        oval(context, 65.72, 1.26, 3.3, 3.3);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 636 Drawing
        oval(context, 65.72, 1.26, 3.3, 3.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBatmanMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 57, 76), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 57, resizedFrame.h / 76);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2010 Drawing
        context.beginPath();
        context.moveTo(46.71, 36.53);
        context.bezierCurveTo(44.96, 41.03, 40.96, 43.66, 35.58, 44.66);
        context.bezierCurveTo(30.21, 45.66, 28.96, 40.28, 31.71, 38.91);
        context.bezierCurveTo(34.46, 37.53, 38.33, 35.28, 38.33, 35.28);
        context.bezierCurveTo(38.33, 35.28, 45.71, 31.16, 46.71, 31.16);
        context.bezierCurveTo(47.71, 31.16, 48.46, 32.03, 46.71, 36.53);
        context.closePath();
        context.moveTo(22.28, 44.66);
        context.bezierCurveTo(16.9, 43.66, 12.9, 41.03, 11.15, 36.53);
        context.bezierCurveTo(9.4, 32.03, 10.15, 31.16, 11.15, 31.16);
        context.bezierCurveTo(12.15, 31.16, 19.53, 35.28, 19.53, 35.28);
        context.bezierCurveTo(19.53, 35.28, 23.4, 37.53, 26.15, 38.91);
        context.bezierCurveTo(28.9, 40.28, 27.65, 45.66, 22.28, 44.66);
        context.closePath();
        context.moveTo(55.45, 23.34);
        context.bezierCurveTo(55.23, 16.86, 48.22, 2.84, 48.22, 2.84);
        context.lineTo(45.89, 21);
        context.bezierCurveTo(40.26, 15.8, 28.32, 16.65, 28.32, 16.65);
        context.bezierCurveTo(28.32, 16.65, 16.39, 15.8, 10.76, 21);
        context.lineTo(8.42, 2.84);
        context.bezierCurveTo(8.42, 2.84, 1.41, 16.86, 1.2, 23.34);
        context.bezierCurveTo(0.99, 29.82, 0.14, 65.19, 4.81, 74);
        context.lineTo(6.93, 57.64);
        context.lineTo(28.32, 66.99);
        context.lineTo(49.71, 57.64);
        context.lineTo(51.83, 74);
        context.bezierCurveTo(56.51, 65.19, 55.66, 29.82, 55.45, 23.34);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2011 Drawing
        context.beginPath();
        context.moveTo(46.71, 36.53);
        context.bezierCurveTo(44.96, 41.03, 40.96, 43.66, 35.58, 44.66);
        context.bezierCurveTo(30.21, 45.66, 28.96, 40.28, 31.71, 38.91);
        context.bezierCurveTo(34.46, 37.53, 38.33, 35.28, 38.33, 35.28);
        context.bezierCurveTo(38.33, 35.28, 45.71, 31.16, 46.71, 31.16);
        context.bezierCurveTo(47.71, 31.16, 48.46, 32.03, 46.71, 36.53);
        context.closePath();
        context.moveTo(22.28, 44.66);
        context.bezierCurveTo(16.9, 43.66, 12.9, 41.03, 11.15, 36.53);
        context.bezierCurveTo(9.4, 32.03, 10.15, 31.16, 11.15, 31.16);
        context.bezierCurveTo(12.15, 31.16, 19.53, 35.28, 19.53, 35.28);
        context.bezierCurveTo(19.53, 35.28, 23.4, 37.53, 26.15, 38.91);
        context.bezierCurveTo(28.9, 40.28, 27.65, 45.66, 22.28, 44.66);
        context.closePath();
        context.moveTo(55.45, 23.34);
        context.bezierCurveTo(55.23, 16.86, 48.22, 2.84, 48.22, 2.84);
        context.lineTo(45.89, 21);
        context.bezierCurveTo(40.26, 15.8, 28.32, 16.65, 28.32, 16.65);
        context.bezierCurveTo(28.32, 16.65, 16.39, 15.8, 10.76, 21);
        context.lineTo(8.42, 2.84);
        context.bezierCurveTo(8.42, 2.84, 1.41, 16.86, 1.2, 23.34);
        context.bezierCurveTo(0.99, 29.82, 0.14, 65.19, 4.81, 74);
        context.lineTo(6.93, 57.64);
        context.lineTo(28.32, 66.99);
        context.lineTo(49.71, 57.64);
        context.lineTo(51.83, 74);
        context.bezierCurveTo(56.51, 65.19, 55.66, 29.82, 55.45, 23.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.3;
        context.stroke();
        
        context.restore();

    }

    function drawPuppyMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 36), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 36);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Bezier 2038 Drawing
        context.beginPath();
        context.moveTo(5.83, 14.62);
        context.bezierCurveTo(5.83, 14.62, 1.69, 4.27, 7.9, 1.69);
        context.bezierCurveTo(14.11, -0.9, 20.21, 8.76, 20.21, 8.76);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2039 Drawing
        context.beginPath();
        context.moveTo(8.4, 11.84);
        context.bezierCurveTo(8.4, 11.84, 7.19, 6.36, 9.99, 5.9);
        context.bezierCurveTo(12.8, 5.43, 15.35, 7.93, 15.35, 7.93);
        context.lineTo(8.4, 11.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 2040 Drawing
        context.beginPath();
        context.moveTo(8.4, 11.84);
        context.bezierCurveTo(8.4, 11.84, 7.19, 6.36, 9.99, 5.9);
        context.bezierCurveTo(12.8, 5.43, 15.35, 7.93, 15.35, 7.93);
        context.lineTo(8.4, 11.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2041 Drawing
        context.beginPath();
        context.moveTo(56.14, 14.62);
        context.bezierCurveTo(56.14, 14.62, 60.27, 4.27, 54.06, 1.69);
        context.bezierCurveTo(47.85, -0.9, 41.75, 8.76, 41.75, 8.76);
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2042 Drawing
        context.beginPath();
        context.moveTo(53.57, 11.84);
        context.bezierCurveTo(53.57, 11.84, 54.78, 6.36, 51.97, 5.9);
        context.bezierCurveTo(49.17, 5.43, 46.62, 7.93, 46.62, 7.93);
        context.lineTo(53.57, 11.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 2043 Drawing
        context.beginPath();
        context.moveTo(53.57, 11.84);
        context.bezierCurveTo(53.57, 11.84, 54.78, 6.36, 51.97, 5.9);
        context.bezierCurveTo(49.17, 5.43, 46.62, 7.93, 46.62, 7.93);
        context.lineTo(53.57, 11.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2044 Drawing
        context.beginPath();
        context.moveTo(10.65, 19.79);
        context.bezierCurveTo(10.65, 20.27, 10.26, 20.67, 9.77, 20.67);
        context.lineTo(1.88, 20.67);
        context.bezierCurveTo(1.39, 20.67, 1, 20.27, 1, 19.79);
        context.lineTo(1, 19.54);
        context.bezierCurveTo(1, 19.06, 1.39, 18.66, 1.88, 18.66);
        context.lineTo(9.77, 18.66);
        context.bezierCurveTo(10.26, 18.66, 10.65, 19.06, 10.65, 19.54);
        context.lineTo(10.65, 19.79);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2045 Drawing
        context.beginPath();
        context.moveTo(10.65, 19.79);
        context.bezierCurveTo(10.65, 20.27, 10.26, 20.67, 9.77, 20.67);
        context.lineTo(1.88, 20.67);
        context.bezierCurveTo(1.39, 20.67, 1, 20.27, 1, 19.79);
        context.lineTo(1, 19.54);
        context.bezierCurveTo(1, 19.06, 1.39, 18.66, 1.88, 18.66);
        context.lineTo(9.77, 18.66);
        context.bezierCurveTo(10.26, 18.66, 10.65, 19.06, 10.65, 19.54);
        context.lineTo(10.65, 19.79);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2046 Drawing
        context.beginPath();
        context.moveTo(51.85, 19.79);
        context.bezierCurveTo(51.85, 20.27, 52.25, 20.67, 52.73, 20.67);
        context.lineTo(60.63, 20.67);
        context.bezierCurveTo(61.11, 20.67, 61.5, 20.27, 61.5, 19.79);
        context.lineTo(61.5, 19.54);
        context.bezierCurveTo(61.5, 19.06, 61.11, 18.66, 60.63, 18.66);
        context.lineTo(52.73, 18.66);
        context.bezierCurveTo(52.25, 18.66, 51.85, 19.06, 51.85, 19.54);
        context.lineTo(51.85, 19.79);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2047 Drawing
        context.beginPath();
        context.moveTo(51.85, 19.79);
        context.bezierCurveTo(51.85, 20.27, 52.25, 20.67, 52.73, 20.67);
        context.lineTo(60.63, 20.67);
        context.bezierCurveTo(61.11, 20.67, 61.5, 20.27, 61.5, 19.79);
        context.lineTo(61.5, 19.54);
        context.bezierCurveTo(61.5, 19.06, 61.11, 18.66, 60.63, 18.66);
        context.lineTo(52.73, 18.66);
        context.bezierCurveTo(52.25, 18.66, 51.85, 19.06, 51.85, 19.54);
        context.lineTo(51.85, 19.79);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2048 Drawing
        context.beginPath();
        context.moveTo(31.26, 29.2);
        context.bezierCurveTo(33.42, 29.2, 35.58, 30.98, 35.58, 30.98);
        context.bezierCurveTo(42.3, 37.38, 49.71, 34.22, 49.71, 34.22);
        context.bezierCurveTo(49.71, 34.22, 59.82, 29.9, 57.89, 16.54);
        context.bezierCurveTo(55.96, 3.18, 31.26, 5.35, 31.26, 5.35);
        context.lineTo(31.25, 5.35);
        context.bezierCurveTo(31.25, 5.35, 6.55, 3.18, 4.62, 16.54);
        context.bezierCurveTo(2.69, 29.9, 12.8, 34.22, 12.8, 34.22);
        context.bezierCurveTo(12.8, 34.22, 20.21, 37.38, 26.93, 30.98);
        context.bezierCurveTo(26.93, 30.98, 29.09, 29.2, 31.25, 29.2);
        context.lineTo(31.26, 29.2);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2049 Drawing
        context.beginPath();
        context.moveTo(31.26, 29.2);
        context.bezierCurveTo(33.42, 29.2, 35.58, 30.98, 35.58, 30.98);
        context.bezierCurveTo(42.3, 37.38, 49.71, 34.22, 49.71, 34.22);
        context.bezierCurveTo(49.71, 34.22, 59.82, 29.9, 57.89, 16.54);
        context.bezierCurveTo(55.96, 3.18, 31.26, 5.35, 31.26, 5.35);
        context.lineTo(31.25, 5.35);
        context.bezierCurveTo(31.25, 5.35, 6.55, 3.18, 4.62, 16.54);
        context.bezierCurveTo(2.69, 29.9, 12.8, 34.22, 12.8, 34.22);
        context.bezierCurveTo(12.8, 34.22, 20.21, 37.38, 26.93, 30.98);
        context.bezierCurveTo(26.93, 30.98, 29.09, 29.2, 31.25, 29.2);
        context.lineTo(31.26, 29.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2050 Drawing
        context.beginPath();
        context.moveTo(12.34, 15.62);
        context.bezierCurveTo(11.68, 16.68, 9.33, 19.41, 10.18, 22.26);
        context.bezierCurveTo(11.03, 25.12, 13.5, 26.97, 16.82, 26.43);
        context.bezierCurveTo(20.13, 25.89, 24.38, 20.95, 24.69, 19.95);
        context.bezierCurveTo(25, 18.94, 27.01, 14, 23.99, 13.85);
        context.bezierCurveTo(20.98, 13.69, 13.88, 13.15, 12.34, 15.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col10;
        context.fill();


        //// Bezier 2051 Drawing
        context.beginPath();
        context.moveTo(12.34, 15.62);
        context.bezierCurveTo(11.68, 16.68, 9.33, 19.41, 10.18, 22.26);
        context.bezierCurveTo(11.03, 25.12, 13.5, 26.97, 16.82, 26.43);
        context.bezierCurveTo(20.13, 25.89, 24.38, 20.95, 24.69, 19.95);
        context.bezierCurveTo(25, 18.94, 27.01, 14, 23.99, 13.85);
        context.bezierCurveTo(20.98, 13.69, 13.88, 13.15, 12.34, 15.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 647 Drawing
        oval(context, 14.53, 16.4, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 648 Drawing
        oval(context, 15.78, 17.65, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 649 Drawing
        oval(context, 41.43, 16.4, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 650 Drawing
        oval(context, 42.68, 17.65, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2052 Drawing
        context.beginPath();
        context.moveTo(35.02, 22.65);
        context.bezierCurveTo(34.09, 20.87, 30.93, 21.18, 30.93, 21.18);
        context.bezierCurveTo(30.93, 21.18, 27.76, 20.87, 26.83, 22.65);
        context.bezierCurveTo(25.9, 24.43, 28.53, 24.27, 28.53, 24.27);
        context.bezierCurveTo(29.41, 24.33, 29.46, 23.96, 29.38, 25.45);
        context.bezierCurveTo(29.31, 26.94, 30.93, 26.51, 30.93, 26.51);
        context.bezierCurveTo(30.93, 26.51, 32.55, 26.94, 32.47, 25.45);
        context.bezierCurveTo(32.39, 23.96, 32.44, 24.33, 33.32, 24.27);
        context.bezierCurveTo(33.32, 24.27, 35.95, 24.43, 35.02, 22.65);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawEyePatch(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 53, 22), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 53, resizedFrame.h / 22);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Bezier 1991 Drawing
        context.beginPath();
        context.moveTo(30.97, 6.48);
        context.bezierCurveTo(31.24, 7.45, 31.38, 8.48, 31.38, 9.54);
        context.bezierCurveTo(31.38, 15.87, 26.25, 21, 19.92, 21);
        context.bezierCurveTo(13.59, 21, 8.45, 15.87, 8.45, 9.54);
        context.bezierCurveTo(8.45, 8.48, 8.6, 7.45, 8.87, 6.48);
        context.lineTo(30.97, 6.48);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 1992 Drawing
        context.beginPath();
        context.moveTo(1, 5.68);
        context.bezierCurveTo(7.58, 5.87, 30.52, 9.98, 51.02, 1.66);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();
        
        context.restore();

    }

    function drawHeadScarf(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 90, 35), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 90, resizedFrame.h / 35);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 73
        //// Bezier 1648 Drawing
        context.beginPath();
        context.moveTo(72.3, 17.44);
        context.bezierCurveTo(72.3, 17.44, 73.35, 6.57, 87.49, 8.06);
        context.bezierCurveTo(87.49, 8.06, 91.52, 18.33, 75.43, 22.95);
        context.lineTo(72.3, 17.44);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 1649 Drawing
        context.beginPath();
        context.moveTo(72.3, 17.44);
        context.bezierCurveTo(72.3, 17.44, 73.35, 6.57, 87.49, 8.06);
        context.bezierCurveTo(87.49, 8.06, 91.52, 18.33, 75.43, 22.95);
        context.lineTo(72.3, 17.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1650 Drawing
        context.beginPath();
        context.moveTo(80.12, 21.2);
        context.bezierCurveTo(80.12, 21.2, 89.43, 24.74, 87.94, 33.23);
        context.bezierCurveTo(87.94, 33.23, 72.64, 37.54, 73.66, 25.78);
        context.lineTo(80.12, 21.2);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 1651 Drawing
        context.beginPath();
        context.moveTo(80.12, 21.2);
        context.bezierCurveTo(80.12, 21.2, 89.43, 24.74, 87.94, 33.23);
        context.bezierCurveTo(87.94, 33.23, 72.64, 37.54, 73.66, 25.78);
        context.lineTo(80.12, 21.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 520 Drawing
        oval(context, 65.87, 17.06, 11.8, 11.8);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Oval 521 Drawing
        oval(context, 65.87, 17.06, 11.8, 11.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1976 Drawing
        context.beginPath();
        context.moveTo(35.79, 2.02);
        context.lineTo(35.79, 1.99);
        context.bezierCurveTo(22.18, 1.99, 11.73, 7.13, 1.34, 17.4);
        context.bezierCurveTo(0.86, 23.52, 1.03, 22.77, 1.03, 29.13);
        context.lineTo(1.04, 29.13);
        context.bezierCurveTo(11.54, 18.62, 22.08, 13.37, 35.85, 13.37);
        context.lineTo(35.85, 13.39);
        context.bezierCurveTo(49.62, 13.39, 59.63, 18.65, 70.13, 29.15);
        context.lineTo(70.14, 29.16);
        context.bezierCurveTo(70.14, 22.92, 70.23, 23.68, 69.84, 17.56);
        context.bezierCurveTo(59.42, 7.2, 49.46, 2.02, 35.79, 2.02);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 1977 Drawing
        context.beginPath();
        context.moveTo(35.79, 2.02);
        context.lineTo(35.79, 1.99);
        context.bezierCurveTo(22.18, 1.99, 11.73, 7.13, 1.34, 17.4);
        context.bezierCurveTo(0.86, 23.52, 1.03, 22.77, 1.03, 29.13);
        context.lineTo(1.04, 29.13);
        context.bezierCurveTo(11.54, 18.62, 22.08, 13.37, 35.85, 13.37);
        context.lineTo(35.85, 13.39);
        context.bezierCurveTo(49.62, 13.39, 59.63, 18.65, 70.13, 29.15);
        context.lineTo(70.14, 29.16);
        context.bezierCurveTo(70.14, 22.92, 70.23, 23.68, 69.84, 17.56);
        context.bezierCurveTo(59.42, 7.2, 49.46, 2.02, 35.79, 2.02);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawScaryMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 50), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 50);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 71
        //// Bezier 2053 Drawing
        context.beginPath();
        context.moveTo(45.69, 26.5);
        context.bezierCurveTo(45.69, 26.5, 38.23, 27.86, 32.65, 24.39);
        context.bezierCurveTo(30.8, 23.24, 36.6, 18.47, 40.54, 16.43);
        context.bezierCurveTo(44.49, 14.39, 44.76, 15.61, 47.07, 13.44);
        context.bezierCurveTo(49.39, 11.26, 51.16, 11.12, 51.16, 11.12);
        context.bezierCurveTo(51.16, 11.12, 53.57, 24.87, 45.69, 26.5);
        context.closePath();
        context.moveTo(26.52, 24.39);
        context.bezierCurveTo(20.94, 27.86, 13.47, 26.5, 13.47, 26.5);
        context.bezierCurveTo(5.6, 24.87, 8.01, 11.12, 8.01, 11.12);
        context.bezierCurveTo(8.01, 11.12, 9.78, 11.26, 12.09, 13.44);
        context.bezierCurveTo(14.41, 15.61, 14.68, 14.39, 18.63, 16.43);
        context.bezierCurveTo(22.57, 18.47, 28.37, 23.24, 26.52, 24.39);
        context.closePath();
        context.moveTo(55.53, 0.78);
        context.bezierCurveTo(55.53, 0.78, 45.59, 7.04, 42.19, 7.11);
        context.bezierCurveTo(38.79, 7.18, 37.16, 2.14, 37.16, 2.14);
        context.lineTo(29.39, 12.07);
        context.lineTo(21.62, 2.14);
        context.bezierCurveTo(21.62, 2.14, 19.99, 7.18, 16.58, 7.11);
        context.bezierCurveTo(13.18, 7.04, 3.25, 0.78, 3.25, 0.78);
        context.bezierCurveTo(3.25, 0.78, -4.78, 29.49, 9.51, 48);
        context.bezierCurveTo(9.51, 48, 12.37, 35.07, 21.21, 34.53);
        context.bezierCurveTo(21.21, 34.53, 26.25, 31.4, 26.93, 28.54);
        context.lineTo(29.39, 27.72);
        context.lineTo(31.85, 28.54);
        context.bezierCurveTo(32.53, 31.4, 37.56, 34.53, 37.56, 34.53);
        context.bezierCurveTo(46.41, 35.07, 49.27, 48, 49.27, 48);
        context.bezierCurveTo(63.56, 29.49, 55.53, 0.78, 55.53, 0.78);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        //// Bezier 2054 Drawing
        context.beginPath();
        context.moveTo(45.69, 26.5);
        context.bezierCurveTo(45.69, 26.5, 38.23, 27.86, 32.65, 24.39);
        context.bezierCurveTo(30.8, 23.24, 36.6, 18.47, 40.54, 16.43);
        context.bezierCurveTo(44.49, 14.39, 44.76, 15.61, 47.07, 13.44);
        context.bezierCurveTo(49.39, 11.26, 51.16, 11.12, 51.16, 11.12);
        context.bezierCurveTo(51.16, 11.12, 53.57, 24.87, 45.69, 26.5);
        context.closePath();
        context.moveTo(26.52, 24.39);
        context.bezierCurveTo(20.94, 27.86, 13.47, 26.5, 13.47, 26.5);
        context.bezierCurveTo(5.6, 24.87, 8.01, 11.12, 8.01, 11.12);
        context.bezierCurveTo(8.01, 11.12, 9.78, 11.26, 12.09, 13.44);
        context.bezierCurveTo(14.41, 15.61, 14.68, 14.39, 18.63, 16.43);
        context.bezierCurveTo(22.57, 18.47, 28.37, 23.24, 26.52, 24.39);
        context.closePath();
        context.moveTo(55.53, 0.78);
        context.bezierCurveTo(55.53, 0.78, 45.59, 7.04, 42.19, 7.11);
        context.bezierCurveTo(38.79, 7.18, 37.16, 2.14, 37.16, 2.14);
        context.lineTo(29.39, 12.07);
        context.lineTo(21.62, 2.14);
        context.bezierCurveTo(21.62, 2.14, 19.99, 7.18, 16.58, 7.11);
        context.bezierCurveTo(13.18, 7.04, 3.25, 0.78, 3.25, 0.78);
        context.bezierCurveTo(3.25, 0.78, -4.78, 29.49, 9.51, 48);
        context.bezierCurveTo(9.51, 48, 12.37, 35.07, 21.21, 34.53);
        context.bezierCurveTo(21.21, 34.53, 26.25, 31.4, 26.93, 28.54);
        context.lineTo(29.39, 27.72);
        context.lineTo(31.85, 28.54);
        context.bezierCurveTo(32.53, 31.4, 37.56, 34.53, 37.56, 34.53);
        context.bezierCurveTo(46.41, 35.07, 49.27, 48, 49.27, 48);
        context.bezierCurveTo(63.56, 29.49, 55.53, 0.78, 55.53, 0.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawAstronautHelmet(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 94, 90), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 94, resizedFrame.h / 90);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2073 Drawing
        context.beginPath();
        context.moveTo(7.36, 41.12);
        context.bezierCurveTo(7.36, 40.21, 6.62, 39.48, 5.72, 39.48);
        context.lineTo(2.64, 39.48);
        context.bezierCurveTo(1.74, 39.48, 1, 40.21, 1, 41.12);
        context.lineTo(1, 53.83);
        context.bezierCurveTo(1, 54.73, 1.74, 55.46, 2.64, 55.46);
        context.lineTo(5.72, 55.46);
        context.bezierCurveTo(6.62, 55.46, 7.36, 54.73, 7.36, 53.83);
        context.lineTo(7.36, 41.12);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 2074 Drawing
        context.beginPath();
        context.moveTo(7.36, 41.12);
        context.bezierCurveTo(7.36, 40.21, 6.62, 39.48, 5.72, 39.48);
        context.lineTo(2.64, 39.48);
        context.bezierCurveTo(1.74, 39.48, 1, 40.21, 1, 41.12);
        context.lineTo(1, 53.83);
        context.bezierCurveTo(1, 54.73, 1.74, 55.46, 2.64, 55.46);
        context.lineTo(5.72, 55.46);
        context.bezierCurveTo(6.62, 55.46, 7.36, 54.73, 7.36, 53.83);
        context.lineTo(7.36, 41.12);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2075 Drawing
        context.beginPath();
        context.moveTo(86.79, 41.12);
        context.bezierCurveTo(86.79, 40.21, 87.52, 39.48, 88.43, 39.48);
        context.lineTo(91.51, 39.48);
        context.bezierCurveTo(92.41, 39.48, 93.14, 40.21, 93.14, 41.12);
        context.lineTo(93.14, 53.83);
        context.bezierCurveTo(93.14, 54.73, 92.41, 55.46, 91.51, 55.46);
        context.lineTo(88.43, 55.46);
        context.bezierCurveTo(87.52, 55.46, 86.79, 54.73, 86.79, 53.83);
        context.lineTo(86.79, 41.12);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 2076 Drawing
        context.beginPath();
        context.moveTo(86.79, 41.12);
        context.bezierCurveTo(86.79, 40.21, 87.52, 39.48, 88.43, 39.48);
        context.lineTo(91.51, 39.48);
        context.bezierCurveTo(92.41, 39.48, 93.14, 40.21, 93.14, 41.12);
        context.lineTo(93.14, 53.83);
        context.bezierCurveTo(93.14, 54.73, 92.41, 55.46, 91.51, 55.46);
        context.lineTo(88.43, 55.46);
        context.bezierCurveTo(87.52, 55.46, 86.79, 54.73, 86.79, 53.83);
        context.lineTo(86.79, 41.12);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2077 Drawing
        context.beginPath();
        context.moveTo(57.55, 64.36);
        context.bezierCurveTo(57.55, 64.36, 74.11, 68.6, 78.93, 59.55);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 657 Drawing
        oval(context, 52.31, 61.02, 7.1, 7.1);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 658 Drawing
        oval(context, 52.31, 61.02, 7.1, 7.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2078 Drawing
        context.beginPath();
        context.moveTo(67.33, 69.55);
        context.bezierCurveTo(58.23, 72.1, 49.94, 72.54, 47.35, 72.61);
        context.lineTo(47.35, 72.63);
        context.bezierCurveTo(47.35, 72.63, 47.2, 72.63, 46.93, 72.62);
        context.bezierCurveTo(46.66, 72.63, 46.51, 72.63, 46.51, 72.63);
        context.lineTo(46.51, 72.61);
        context.bezierCurveTo(43.92, 72.54, 35.63, 72.1, 26.54, 69.55);
        context.bezierCurveTo(15.55, 66.46, 14.22, 56.06, 16.15, 37.19);
        context.bezierCurveTo(17.93, 19.7, 43.03, 18.41, 46.71, 18.32);
        context.lineTo(46.71, 18.31);
        context.bezierCurveTo(46.71, 18.31, 46.79, 18.31, 46.93, 18.31);
        context.bezierCurveTo(47.08, 18.31, 47.16, 18.31, 47.16, 18.31);
        context.lineTo(47.16, 18.32);
        context.bezierCurveTo(50.84, 18.41, 75.93, 19.7, 77.72, 37.19);
        context.bezierCurveTo(79.64, 56.06, 78.31, 66.46, 67.33, 69.55);
        context.closePath();
        context.moveTo(44.8, 1.14);
        context.bezierCurveTo(44.37, 1.21, 43.9, 1.29, 43.38, 1.38);
        context.bezierCurveTo(33.56, 3.18, 9.2, 9.94, 6.9, 32.95);
        context.bezierCurveTo(4.01, 61.84, 6.9, 76.67, 6.9, 76.67);
        context.bezierCurveTo(6.9, 76.67, 22.87, 88.25, 46.93, 88.98);
        context.lineTo(46.93, 89);
        context.bezierCurveTo(47.02, 89, 47.11, 88.99, 47.2, 88.99);
        context.bezierCurveTo(47.28, 88.99, 47.37, 89, 47.46, 89);
        context.lineTo(47.46, 88.98);
        context.bezierCurveTo(71.52, 88.25, 87.49, 76.67, 87.49, 76.67);
        context.bezierCurveTo(87.49, 76.67, 90.38, 61.84, 87.49, 32.95);
        context.bezierCurveTo(85.2, 10.05, 61.07, 3.24, 51.15, 1.41);
        context.bezierCurveTo(50.99, 1.37, 50.82, 1.35, 50.67, 1.32);
        context.bezierCurveTo(48.56, 0.91, 44.8, 1.14, 44.8, 1.14);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 2079 Drawing
        context.beginPath();
        context.moveTo(67.33, 69.55);
        context.bezierCurveTo(58.23, 72.1, 49.94, 72.54, 47.35, 72.61);
        context.lineTo(47.35, 72.63);
        context.bezierCurveTo(47.35, 72.63, 47.2, 72.63, 46.93, 72.62);
        context.bezierCurveTo(46.66, 72.63, 46.51, 72.63, 46.51, 72.63);
        context.lineTo(46.51, 72.61);
        context.bezierCurveTo(43.92, 72.54, 35.63, 72.1, 26.54, 69.55);
        context.bezierCurveTo(15.55, 66.46, 14.22, 56.06, 16.15, 37.19);
        context.bezierCurveTo(17.93, 19.7, 43.03, 18.41, 46.71, 18.32);
        context.lineTo(46.71, 18.31);
        context.bezierCurveTo(46.71, 18.31, 46.79, 18.31, 46.93, 18.31);
        context.bezierCurveTo(47.08, 18.31, 47.16, 18.31, 47.16, 18.31);
        context.lineTo(47.16, 18.32);
        context.bezierCurveTo(50.84, 18.41, 75.93, 19.7, 77.72, 37.19);
        context.bezierCurveTo(79.64, 56.06, 78.31, 66.46, 67.33, 69.55);
        context.closePath();
        context.moveTo(44.8, 1.14);
        context.bezierCurveTo(44.37, 1.21, 43.9, 1.29, 43.38, 1.38);
        context.bezierCurveTo(33.56, 3.18, 9.2, 9.94, 6.9, 32.95);
        context.bezierCurveTo(4.01, 61.84, 6.9, 76.67, 6.9, 76.67);
        context.bezierCurveTo(6.9, 76.67, 22.87, 88.25, 46.93, 88.98);
        context.lineTo(46.93, 89);
        context.bezierCurveTo(47.02, 89, 47.11, 88.99, 47.2, 88.99);
        context.bezierCurveTo(47.28, 88.99, 47.37, 89, 47.46, 89);
        context.lineTo(47.46, 88.98);
        context.bezierCurveTo(71.52, 88.25, 87.49, 76.67, 87.49, 76.67);
        context.bezierCurveTo(87.49, 76.67, 90.38, 61.84, 87.49, 32.95);
        context.bezierCurveTo(85.2, 10.05, 61.07, 3.24, 51.15, 1.41);
        context.bezierCurveTo(50.99, 1.37, 50.82, 1.35, 50.67, 1.32);
        context.bezierCurveTo(48.56, 0.91, 44.8, 1.14, 44.8, 1.14);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSombrero(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 103, 54), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 103, resizedFrame.h / 54);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor17 = 'rgba(235, 72, 99, 1)';

        //// Group 75
        //// Bezier 2099 Drawing
        context.beginPath();
        context.moveTo(52.19, 31.7);
        context.lineTo(72.12, 31.7);
        context.lineTo(72.12, 17.99);
        context.bezierCurveTo(72.12, 17.99, 71.19, 2.12, 52.19, 1.38);
        context.lineTo(52.19, 1.44);
        context.bezierCurveTo(33.18, 2.18, 32.25, 18.05, 32.25, 18.05);
        context.lineTo(32.25, 31.75);
        context.lineTo(52.19, 31.75);
        context.lineTo(52.19, 31.7);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Bezier 2100 Drawing
        context.beginPath();
        context.moveTo(52.19, 31.7);
        context.lineTo(72.12, 31.7);
        context.lineTo(72.12, 17.99);
        context.bezierCurveTo(72.12, 17.99, 71.19, 2.12, 52.19, 1.38);
        context.lineTo(52.19, 1.44);
        context.bezierCurveTo(33.18, 2.18, 32.25, 18.05, 32.25, 18.05);
        context.lineTo(32.25, 31.75);
        context.lineTo(52.19, 31.75);
        context.lineTo(52.19, 31.7);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2101 Drawing
        context.beginPath();
        context.moveTo(101.56, 30.41);
        context.lineTo(51.61, 30.41);
        context.lineTo(51.61, 30.47);
        context.lineTo(1.67, 30.47);
        context.bezierCurveTo(1.67, 30.47, -0.91, 53.3, 20.86, 53);
        context.lineTo(51.61, 53);
        context.lineTo(51.61, 52.94);
        context.lineTo(82.37, 52.94);
        context.bezierCurveTo(104.14, 53.25, 101.56, 30.41, 101.56, 30.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Bezier 2102 Drawing
        context.beginPath();
        context.moveTo(101.56, 30.41);
        context.lineTo(51.61, 30.41);
        context.lineTo(51.61, 30.47);
        context.lineTo(1.67, 30.47);
        context.bezierCurveTo(1.67, 30.47, -0.91, 53.3, 20.86, 53);
        context.lineTo(51.61, 53);
        context.lineTo(51.61, 52.94);
        context.lineTo(82.37, 52.94);
        context.bezierCurveTo(104.14, 53.25, 101.56, 30.41, 101.56, 30.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 58
        context.save();

        //// Clip Clip 58

        context.beginPath();
        context.moveTo(51.62, 30.41);
        context.lineTo(51.62, 30.46);
        context.lineTo(1.67, 30.46);
        context.bezierCurveTo(1.67, 30.46, -0.91, 53.3, 20.86, 53);
        context.lineTo(20.86, 53);
        context.lineTo(51.62, 53);
        context.lineTo(51.62, 52.94);
        context.lineTo(82.37, 52.94);
        context.bezierCurveTo(104.14, 53.25, 101.56, 30.41, 101.56, 30.41);
        context.lineTo(101.56, 30.41);
        context.lineTo(51.62, 30.41);
        context.closePath();
        context.clip();



        //// Bezier 2103 Drawing
        context.beginPath();
        context.moveTo(0, 39.56);
        context.lineTo(8.46, 45.05);
        context.lineTo(16.91, 39.56);
        context.lineTo(25.37, 45.05);
        context.lineTo(33.83, 39.56);
        context.lineTo(42.28, 45.05);
        context.lineTo(50.74, 39.56);
        context.lineTo(59.19, 45.05);
        context.lineTo(67.65, 39.56);
        context.lineTo(76.11, 45.05);
        context.lineTo(84.57, 39.56);
        context.lineTo(93.03, 45.05);
        context.lineTo(101.49, 39.56);
        context.lineTo(109.95, 45.05);
        context.strokeStyle = strokeColor17;
        context.lineWidth = 3;
        context.stroke();


        context.restore();



        //// Bezier 2105 Drawing
        context.beginPath();
        context.moveTo(101.56, 30.41);
        context.lineTo(51.61, 30.41);
        context.lineTo(51.61, 30.47);
        context.lineTo(1.67, 30.47);
        context.bezierCurveTo(1.67, 30.47, -0.91, 53.3, 20.86, 53);
        context.lineTo(51.61, 53);
        context.lineTo(51.61, 52.94);
        context.lineTo(82.37, 52.94);
        context.bezierCurveTo(104.14, 53.25, 101.56, 30.41, 101.56, 30.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPinkCap(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 79, 55), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 79, resizedFrame.h / 55);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 1693 Drawing
        context.beginPath();
        context.moveTo(73.25, 37.36);
        context.lineTo(62.68, 30.48);
        context.bezierCurveTo(62.68, 30.48, 62.56, 30.51, 62.36, 30.55);
        context.bezierCurveTo(58.92, 31.35, 26.23, 38.76, 3.62, 38.93);
        context.bezierCurveTo(3.37, 38.93, 3.11, 38.94, 2.85, 38.94);
        context.bezierCurveTo(3.04, 38.93, 17.99, 65.82, 69.76, 47.76);
        context.bezierCurveTo(69.76, 47.76, 83.82, 44.25, 73.25, 37.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 1694 Drawing
        context.beginPath();
        context.moveTo(73.25, 37.36);
        context.lineTo(62.68, 30.48);
        context.bezierCurveTo(62.68, 30.48, 62.56, 30.51, 62.36, 30.55);
        context.bezierCurveTo(58.92, 31.35, 26.23, 38.76, 3.62, 38.93);
        context.bezierCurveTo(3.37, 38.93, 3.11, 38.94, 2.85, 38.94);
        context.bezierCurveTo(3.04, 38.93, 17.99, 65.82, 69.76, 47.76);
        context.bezierCurveTo(69.76, 47.76, 83.82, 44.25, 73.25, 37.36);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.04;
        context.stroke();


        //// Bezier 1695 Drawing
        context.beginPath();
        context.moveTo(62.37, 30.48);
        context.bezierCurveTo(60.63, 18.96, 55.33, 8.95, 39.47, 2.89);
        context.bezierCurveTo(29.16, -1.05, 20.67, 2.57, 20.67, 2.57);
        context.bezierCurveTo(2.4, 8.91, 1.66, 23.51, 1, 34.83);
        context.bezierCurveTo(1, 34.83, -0.18, 54.93, 62.37, 30.48);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 1696 Drawing
        context.beginPath();
        context.moveTo(62.37, 30.48);
        context.bezierCurveTo(60.63, 18.96, 55.33, 8.95, 39.47, 2.89);
        context.bezierCurveTo(29.16, -1.05, 20.67, 2.57, 20.67, 2.57);
        context.bezierCurveTo(2.4, 8.91, 1.66, 23.51, 1, 34.83);
        context.bezierCurveTo(1, 34.83, -0.18, 54.93, 62.37, 30.48);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.04;
        context.stroke();


        //// Bezier 1697 Drawing
        context.beginPath();
        context.moveTo(31.68, 27.13);
        context.lineTo(25.64, 31.13);
        context.lineTo(27.2, 23.99);
        context.lineTo(21.9, 19.63);
        context.lineTo(28.66, 19.06);
        context.lineTo(31.68, 12.52);
        context.lineTo(34.71, 19.06);
        context.lineTo(41.47, 19.63);
        context.lineTo(36.16, 24.4);
        context.lineTo(37.73, 31.13);
        context.lineTo(31.68, 27.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Bezier 1698 Drawing
        context.beginPath();
        context.moveTo(31.68, 27.13);
        context.lineTo(25.64, 31.13);
        context.lineTo(27.2, 23.99);
        context.lineTo(21.9, 19.63);
        context.lineTo(28.66, 19.06);
        context.lineTo(31.68, 12.52);
        context.lineTo(34.71, 19.06);
        context.lineTo(41.47, 19.63);
        context.lineTo(36.16, 24.4);
        context.lineTo(37.73, 31.13);
        context.lineTo(31.68, 27.13);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.04;
        context.stroke();
        
        context.restore();

    }

    function drawPinkWoolyHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 71, 77), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 71, resizedFrame.h / 77);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 77
        //// Bezier 1652 Drawing
        context.beginPath();
        context.moveTo(45.58, 22.29);
        context.bezierCurveTo(71.9, 33.11, 70.13, 53.76, 70.12, 76);
        context.lineTo(70.12, 76);
        context.bezierCurveTo(59.61, 65.49, 49.61, 60.24, 35.84, 60.24);
        context.lineTo(35.84, 60.21);
        context.bezierCurveTo(22.07, 60.21, 11.53, 65.46, 1.02, 75.97);
        context.lineTo(1.01, 75.97);
        context.bezierCurveTo(1, 53.9, -0.27, 34.78, 26.09, 21.94);
        context.bezierCurveTo(26.09, 21.94, 34.89, 17.9, 45.58, 22.29);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Bezier 1653 Drawing
        context.beginPath();
        context.moveTo(45.58, 22.29);
        context.bezierCurveTo(71.9, 33.11, 70.13, 53.76, 70.12, 76);
        context.lineTo(70.12, 76);
        context.bezierCurveTo(59.61, 65.49, 49.61, 60.24, 35.84, 60.24);
        context.lineTo(35.84, 60.21);
        context.bezierCurveTo(22.07, 60.21, 11.53, 65.46, 1.02, 75.97);
        context.lineTo(1.01, 75.97);
        context.bezierCurveTo(1, 53.9, -0.27, 34.78, 26.09, 21.94);
        context.bezierCurveTo(26.09, 21.94, 34.89, 17.9, 45.58, 22.29);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1654 Drawing
        context.beginPath();
        context.moveTo(35.81, 40.25);
        context.lineTo(35.81, 40.23);
        context.bezierCurveTo(22.2, 40.23, 11.74, 45.37, 1.36, 55.63);
        context.bezierCurveTo(0.88, 61.76, 1.04, 69.55, 1.04, 75.91);
        context.lineTo(1.05, 75.91);
        context.bezierCurveTo(11.56, 65.4, 22.1, 60.15, 35.87, 60.15);
        context.lineTo(35.87, 60.18);
        context.bezierCurveTo(49.64, 60.18, 59.64, 65.43, 70.15, 75.93);
        context.lineTo(70.15, 75.94);
        context.bezierCurveTo(70.16, 69.7, 70.24, 61.92, 69.86, 55.79);
        context.bezierCurveTo(59.44, 45.44, 49.48, 40.25, 35.81, 40.25);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 1655 Drawing
        context.beginPath();
        context.moveTo(35.81, 40.25);
        context.lineTo(35.81, 40.23);
        context.bezierCurveTo(22.2, 40.23, 11.74, 45.37, 1.36, 55.63);
        context.bezierCurveTo(0.88, 61.76, 1.04, 69.55, 1.04, 75.91);
        context.lineTo(1.05, 75.91);
        context.bezierCurveTo(11.56, 65.4, 22.1, 60.15, 35.87, 60.15);
        context.lineTo(35.87, 60.18);
        context.bezierCurveTo(49.64, 60.18, 59.64, 65.43, 70.15, 75.93);
        context.lineTo(70.15, 75.94);
        context.bezierCurveTo(70.16, 69.7, 70.24, 61.92, 69.86, 55.79);
        context.bezierCurveTo(59.44, 45.44, 49.48, 40.25, 35.81, 40.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1685 Drawing
        context.beginPath();
        context.moveTo(49.65, 12.68);
        context.bezierCurveTo(49.65, 14.62, 45.38, 15.44, 44.47, 17.02);
        context.bezierCurveTo(43.53, 18.64, 44.93, 22.74, 43.3, 23.68);
        context.bezierCurveTo(41.73, 24.59, 38.9, 21.36, 36.95, 21.36);
        context.bezierCurveTo(35, 21.36, 32.17, 24.59, 30.59, 23.68);
        context.bezierCurveTo(28.97, 22.74, 30.37, 18.64, 29.43, 17.02);
        context.bezierCurveTo(28.52, 15.44, 24.24, 14.62, 24.24, 12.68);
        context.bezierCurveTo(24.24, 10.73, 28.52, 9.91, 29.43, 8.34);
        context.bezierCurveTo(30.37, 6.71, 28.97, 2.61, 30.59, 1.67);
        context.bezierCurveTo(32.17, 0.76, 35, 4, 36.95, 4);
        context.bezierCurveTo(38.9, 4, 41.73, 0.76, 43.3, 1.67);
        context.bezierCurveTo(44.93, 2.61, 43.53, 6.71, 44.47, 8.34);
        context.bezierCurveTo(45.38, 9.91, 49.65, 10.73, 49.65, 12.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Bezier 1686 Drawing
        context.beginPath();
        context.moveTo(49.65, 12.68);
        context.bezierCurveTo(49.65, 14.62, 45.38, 15.44, 44.47, 17.02);
        context.bezierCurveTo(43.53, 18.64, 44.93, 22.74, 43.3, 23.68);
        context.bezierCurveTo(41.73, 24.59, 38.9, 21.36, 36.95, 21.36);
        context.bezierCurveTo(35, 21.36, 32.17, 24.59, 30.59, 23.68);
        context.bezierCurveTo(28.97, 22.74, 30.37, 18.64, 29.43, 17.02);
        context.bezierCurveTo(28.52, 15.44, 24.24, 14.62, 24.24, 12.68);
        context.bezierCurveTo(24.24, 10.73, 28.52, 9.91, 29.43, 8.34);
        context.bezierCurveTo(30.37, 6.71, 28.97, 2.61, 30.59, 1.67);
        context.bezierCurveTo(32.17, 0.76, 35, 4, 36.95, 4);
        context.bezierCurveTo(38.9, 4, 41.73, 0.76, 43.3, 1.67);
        context.bezierCurveTo(44.93, 2.61, 43.53, 6.71, 44.47, 8.34);
        context.bezierCurveTo(45.38, 9.91, 49.65, 10.73, 49.65, 12.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1687 Drawing
        context.beginPath();
        context.moveTo(45.73, 56.08);
        context.lineTo(45.73, 47.91);
        context.bezierCurveTo(34.9, 44.33, 25.1, 47.91, 25.1, 47.91);
        context.lineTo(25.1, 56.08);
        context.bezierCurveTo(25.1, 56.08, 34.9, 52.42, 45.73, 56.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Bezier 1688 Drawing
        context.beginPath();
        context.moveTo(45.73, 56.08);
        context.lineTo(45.73, 47.91);
        context.bezierCurveTo(34.9, 44.33, 25.1, 47.91, 25.1, 47.91);
        context.lineTo(25.1, 56.08);
        context.bezierCurveTo(25.1, 56.08, 34.9, 52.42, 45.73, 56.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.05;
        context.stroke();
        
        context.restore();

    }

    function drawPinkScarf(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 44, 55), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 44, resizedFrame.h / 55);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 78
        //// Bezier 1715 Drawing
        context.beginPath();
        context.moveTo(32.9, 14.37);
        context.lineTo(25, 41.17);
        context.bezierCurveTo(25, 41.17, 14.29, 39.49, 12.81, 39.29);
        context.lineTo(20.35, 11.97);
        context.lineTo(34.05, 17.18);
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 1716 Drawing
        context.beginPath();
        context.moveTo(17.75, 8.23);
        context.bezierCurveTo(17.72, 8.26, 17.6, 8.86, 17.42, 9.87);
        context.fillStyle = AvatarStyleKit.col12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 1717 Drawing
        context.beginPath();
        context.moveTo(5.37, 1);
        context.lineTo(5.35, 1);
        context.lineTo(2, 11.83);
        context.bezierCurveTo(9.66, 13.76, 15.83, 14.6, 20.75, 14.81);
        context.lineTo(34.62, 13.29);
        context.bezierCurveTo(36.76, 12.56, 38.21, 11.57, 38.21, 11.57);
        context.lineTo(33.99, 1.64);
        context.lineTo(33.98, 1.64);
        context.bezierCurveTo(29.92, 4.22, 25.2, 5.72, 20.15, 5.73);
        context.bezierCurveTo(14.7, 5.72, 9.64, 3.97, 5.37, 1);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 1718 Drawing
        context.beginPath();
        context.moveTo(5.37, 1);
        context.lineTo(5.35, 1);
        context.lineTo(2, 11.83);
        context.bezierCurveTo(9.66, 13.76, 15.83, 14.6, 20.75, 14.81);
        context.lineTo(34.62, 13.29);
        context.bezierCurveTo(36.76, 12.56, 38.21, 11.57, 38.21, 11.57);
        context.lineTo(33.99, 1.64);
        context.lineTo(33.98, 1.64);
        context.bezierCurveTo(29.92, 4.22, 25.2, 5.72, 20.15, 5.73);
        context.bezierCurveTo(14.7, 5.72, 9.64, 3.97, 5.37, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 1719 Drawing
        context.beginPath();
        context.moveTo(31.95, 2.81);
        context.bezierCurveTo(28.38, 4.67, 24.38, 5.73, 20.15, 5.73);
        context.bezierCurveTo(20.03, 5.73, 19.9, 5.73, 19.77, 5.72);
        context.lineTo(19.45, 21.11);
        context.lineTo(20.2, 53.88);
        context.lineTo(43.03, 52.07);
        context.lineTo(31.95, 2.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 1720 Drawing
        context.beginPath();
        context.moveTo(31.95, 2.81);
        context.bezierCurveTo(28.38, 4.67, 24.38, 5.73, 20.15, 5.73);
        context.bezierCurveTo(20.03, 5.73, 19.9, 5.73, 19.77, 5.72);
        context.lineTo(19.45, 21.11);
        context.lineTo(20.2, 53.88);
        context.lineTo(43.03, 52.07);
        context.lineTo(31.95, 2.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Group 51
        context.save();

        //// Clip Clip 51

        context.beginPath();
        context.moveTo(20.15, 5.73);
        context.bezierCurveTo(20.03, 5.73, 19.9, 5.72, 19.77, 5.72);
        context.lineTo(19.77, 5.72);
        context.lineTo(19.45, 21.11);
        context.lineTo(20.2, 53.88);
        context.lineTo(43.03, 52.07);
        context.lineTo(31.95, 2.81);
        context.bezierCurveTo(28.38, 4.67, 24.38, 5.73, 20.15, 5.73);
        context.closePath();
        context.clip();



        //// Oval 529 Drawing
        oval(context, 16.32, 9.98, 10.1, 10.1);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 530 Drawing
        oval(context, 16.32, 9.98, 10.1, 10.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Oval 531 Drawing
        oval(context, 31.32, 19.98, 10.1, 10.1);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 532 Drawing
        oval(context, 31.32, 19.98, 10.1, 10.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Oval 533 Drawing
        oval(context, 14.77, 30.33, 10.1, 10.1);
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Oval 534 Drawing
        oval(context, 14.77, 30.33, 10.1, 10.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Oval 535 Drawing
        oval(context, 26.17, 46.48, 10.1, 10.1);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 536 Drawing
        oval(context, 26.17, 46.48, 10.1, 10.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Oval 537 Drawing
        oval(context, 34.07, 34.43, 10.1, 10.1);
        context.fillStyle = AvatarStyleKit.col11;
        context.fill();


        //// Oval 538 Drawing
        oval(context, 34.07, 34.43, 10.1, 10.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        context.restore();



        //// Bezier 1722 Drawing
        context.beginPath();
        context.moveTo(31.95, 2.81);
        context.bezierCurveTo(28.38, 4.67, 24.38, 5.73, 20.15, 5.73);
        context.bezierCurveTo(20.03, 5.73, 19.9, 5.73, 19.77, 5.72);
        context.lineTo(19.45, 21.11);
        context.lineTo(20.2, 53.88);
        context.lineTo(43.03, 52.07);
        context.lineTo(31.95, 2.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();
        
        context.restore();

    }

    function drawFlowerHairband(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 34), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 34);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Group 79
        //// Bezier 1656 Drawing
        context.beginPath();
        context.moveTo(36.8, 5.64);
        context.lineTo(36.8, 5.61);
        context.bezierCurveTo(23.18, 5.61, 12.73, 10.75, 2.34, 21.02);
        context.bezierCurveTo(1.86, 27.14, 2.03, 26.39, 2.03, 32.75);
        context.lineTo(2.04, 32.74);
        context.bezierCurveTo(12.54, 22.24, 23.09, 16.99, 36.86, 16.99);
        context.lineTo(36.86, 17.01);
        context.bezierCurveTo(50.63, 17.01, 60.63, 22.26, 71.13, 32.77);
        context.lineTo(71.14, 32.77);
        context.bezierCurveTo(71.14, 26.53, 71.23, 27.3, 70.85, 21.17);
        context.bezierCurveTo(60.42, 10.82, 50.47, 5.64, 36.8, 5.64);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 1657 Drawing
        context.beginPath();
        context.moveTo(36.8, 5.64);
        context.lineTo(36.8, 5.61);
        context.bezierCurveTo(23.18, 5.61, 12.73, 10.75, 2.34, 21.02);
        context.bezierCurveTo(1.86, 27.14, 2.03, 26.39, 2.03, 32.75);
        context.lineTo(2.04, 32.74);
        context.bezierCurveTo(12.54, 22.24, 23.09, 16.99, 36.86, 16.99);
        context.lineTo(36.86, 17.01);
        context.bezierCurveTo(50.63, 17.01, 60.63, 22.26, 71.13, 32.77);
        context.lineTo(71.14, 32.77);
        context.bezierCurveTo(71.14, 26.53, 71.23, 27.3, 70.85, 21.17);
        context.bezierCurveTo(60.42, 10.82, 50.47, 5.64, 36.8, 5.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1675 Drawing
        context.beginPath();
        context.moveTo(26.59, 6.52);
        context.bezierCurveTo(29, 6.48, 30.82, 7.33, 31.73, 9.56);
        context.bezierCurveTo(32.63, 11.76, 31.91, 13.61, 30.26, 15.25);
        context.bezierCurveTo(31.98, 16.96, 32.67, 18.91, 31.63, 21.2);
        context.bezierCurveTo(30.81, 23.02, 29.45, 23.77, 26.64, 24.03);
        context.bezierCurveTo(26.65, 26.28, 25.86, 28.14, 23.64, 29.08);
        context.bezierCurveTo(21.41, 30.03, 19.53, 29.31, 17.87, 27.64);
        context.bezierCurveTo(16.22, 29.31, 14.33, 30.03, 12.11, 29.08);
        context.bezierCurveTo(9.93, 28.16, 9.1, 26.36, 9.14, 24);
        context.bezierCurveTo(6.81, 24.03, 4.96, 23.22, 4.03, 21);
        context.bezierCurveTo(3.13, 18.81, 3.78, 16.94, 5.49, 15.28);
        context.bezierCurveTo(3.89, 13.66, 3.09, 11.8, 4.01, 9.56);
        context.bezierCurveTo(4.93, 7.34, 6.73, 6.46, 9.13, 6.53);
        context.bezierCurveTo(9.09, 4.05, 10.02, 2.19, 12.36, 1.32);
        context.bezierCurveTo(14.48, 0.54, 16.28, 1.25, 17.85, 2.87);
        context.bezierCurveTo(19.49, 1.25, 21.36, 0.46, 23.59, 1.4);
        context.bezierCurveTo(25.76, 2.32, 26.66, 4.07, 26.59, 6.52);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Oval 522 Drawing
        oval(context, 13.84, 11.2, 8.1, 8.1);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 1676 Drawing
        context.beginPath();
        context.moveTo(21.2, 15.29);
        context.bezierCurveTo(21.22, 13.49, 19.71, 11.95, 17.91, 11.93);
        context.bezierCurveTo(16.1, 11.91, 14.58, 13.41, 14.56, 15.22);
        context.bezierCurveTo(14.54, 17.02, 16.04, 18.55, 17.84, 18.58);
        context.bezierCurveTo(19.64, 18.6, 21.17, 17.09, 21.2, 15.29);
        context.closePath();
        context.moveTo(17.22, 7.46);
        context.bezierCurveTo(17.22, 6.84, 17.13, 6.19, 17.23, 5.58);
        context.bezierCurveTo(17.64, 3.24, 14.96, 2.03, 13.35, 2.37);
        context.bezierCurveTo(11.97, 2.67, 11.01, 3.48, 10.6, 4.83);
        context.bezierCurveTo(10.23, 6.05, 10.46, 7.11, 11.52, 8.01);
        context.bezierCurveTo(12.61, 8.94, 13.59, 10.01, 14.58, 11.06);
        context.bezierCurveTo(15, 11.5, 15.31, 11.47, 15.9, 11.31);
        context.bezierCurveTo(17.15, 10.97, 17.36, 10.23, 17.23, 9.13);
        context.bezierCurveTo(17.16, 8.58, 17.22, 8.02, 17.22, 7.46);
        context.closePath();
        context.moveTo(22.35, 15.91);
        context.bezierCurveTo(22.24, 16.28, 22.17, 16.45, 22.02, 16.89);
        context.bezierCurveTo(21.7, 17.9, 21.75, 18.3, 22.42, 18.91);
        context.bezierCurveTo(23.52, 19.91, 24.51, 21.01, 25.59, 22.02);
        context.bezierCurveTo(25.91, 22.32, 26.39, 22.54, 26.83, 22.61);
        context.bezierCurveTo(28.61, 22.86, 30.22, 21.75, 30.7, 20.03);
        context.bezierCurveTo(31.18, 18.29, 29.98, 15.99, 28.47, 15.93);
        context.bezierCurveTo(26.45, 15.85, 24.42, 15.91, 22.35, 15.91);
        context.closePath();
        context.moveTo(13.16, 15.91);
        context.lineTo(8.12, 15.91);
        context.bezierCurveTo(6.46, 15.91, 5.44, 16.71, 5.04, 18.31);
        context.bezierCurveTo(4.66, 19.82, 5.35, 21.41, 6.71, 22.2);
        context.bezierCurveTo(8.12, 23.02, 9.39, 22.81, 10.66, 21.54);
        context.bezierCurveTo(11.34, 20.86, 11.97, 20.12, 12.72, 19.52);
        context.bezierCurveTo(13.94, 18.52, 14.61, 17.49, 13.16, 15.91);
        context.closePath();
        context.moveTo(25.57, 14.6);
        context.bezierCurveTo(26.46, 14.6, 27.35, 14.64, 28.23, 14.58);
        context.bezierCurveTo(28.65, 14.55, 29.12, 14.4, 29.44, 14.15);
        context.bezierCurveTo(30.55, 13.29, 31, 12.09, 30.74, 10.71);
        context.bezierCurveTo(30.5, 9.38, 29.7, 8.33, 28.37, 8.05);
        context.bezierCurveTo(27.55, 7.88, 26.4, 7.9, 25.8, 8.36);
        context.bezierCurveTo(24.37, 9.45, 23.18, 10.85, 21.92, 12.15);
        context.bezierCurveTo(21.75, 12.32, 21.62, 12.68, 21.68, 12.89);
        context.bezierCurveTo(22.16, 14.6, 22.18, 14.6, 24.02, 14.6);
        context.bezierCurveTo(24.54, 14.6, 25.05, 14.6, 25.57, 14.6);
        context.closePath();
        context.moveTo(10.18, 14.6);
        context.bezierCurveTo(11.03, 14.6, 11.88, 14.55, 12.73, 14.61);
        context.bezierCurveTo(13.96, 14.68, 13.9, 13.66, 14.01, 12.96);
        context.bezierCurveTo(14.04, 12.82, 13.94, 12.29, 13.74, 12.08);
        context.bezierCurveTo(12.65, 10.92, 11.47, 9.85, 10.4, 8.68);
        context.bezierCurveTo(9.72, 7.93, 8.94, 7.75, 8.01, 7.87);
        context.bezierCurveTo(6.17, 8.1, 4.85, 9.7, 4.96, 11.6);
        context.bezierCurveTo(5.07, 13.5, 6.28, 14.6, 8.29, 14.6);
        context.bezierCurveTo(8.92, 14.6, 9.55, 14.6, 10.18, 14.6);
        context.closePath();
        context.moveTo(18.53, 10.76);
        context.bezierCurveTo(18.93, 10.9, 19.25, 11.06, 19.61, 11.16);
        context.bezierCurveTo(20.75, 11.42, 20.82, 11.47, 21.38, 10.86);
        context.bezierCurveTo(22.42, 9.71, 23.57, 8.66, 24.64, 7.54);
        context.bezierCurveTo(24.91, 7.26, 25.15, 6.88, 25.23, 6.52);
        context.bezierCurveTo(25.6, 4.64, 24.4, 2.83, 22.55, 2.4);
        context.bezierCurveTo(20.83, 1.99, 18.61, 3.19, 18.55, 4.65);
        context.bezierCurveTo(18.48, 6.68, 18.53, 8.71, 18.53, 10.76);
        context.closePath();
        context.moveTo(17.22, 23.14);
        context.bezierCurveTo(17.22, 22.25, 17.16, 21.36, 17.24, 20.48);
        context.bezierCurveTo(17.34, 19.14, 16.21, 19.23, 15.66, 19.17);
        context.bezierCurveTo(15.45, 19.14, 14.9, 19.19, 14.68, 19.4);
        context.bezierCurveTo(13.54, 20.46, 12.51, 21.63, 11.35, 22.66);
        context.bezierCurveTo(10.49, 23.44, 10.33, 24.33, 10.52, 25.36);
        context.bezierCurveTo(10.84, 27.12, 12.52, 28.33, 14.36, 28.16);
        context.bezierCurveTo(16.16, 28, 17.22, 26.8, 17.22, 24.92);
        context.lineTo(17.22, 23.14);
        context.closePath();
        context.moveTo(18.53, 22.96);
        context.bezierCurveTo(18.53, 23.81, 18.47, 24.66, 18.55, 25.51);
        context.bezierCurveTo(18.6, 25.99, 18.76, 26.54, 19.05, 26.9);
        context.bezierCurveTo(19.91, 27.97, 21.09, 28.37, 22.42, 28.12);
        context.bezierCurveTo(23.76, 27.87, 24.8, 27.07, 25.08, 25.75);
        context.bezierCurveTo(25.25, 24.93, 25.22, 23.78, 24.76, 23.17);
        context.bezierCurveTo(23.67, 21.75, 22.27, 20.55, 20.97, 19.29);
        context.bezierCurveTo(20.8, 19.13, 20.44, 19, 20.23, 19.06);
        context.bezierCurveTo(18.53, 19.55, 18.53, 19.57, 18.53, 21.4);
        context.lineTo(18.53, 22.96);
        context.closePath();
        context.moveTo(26.59, 6.52);
        context.bezierCurveTo(29, 6.48, 30.82, 7.33, 31.73, 9.56);
        context.bezierCurveTo(32.63, 11.76, 31.91, 13.61, 30.26, 15.25);
        context.bezierCurveTo(31.98, 16.96, 32.67, 18.91, 31.63, 21.2);
        context.bezierCurveTo(30.81, 23.01, 29.45, 23.77, 26.64, 24.03);
        context.bezierCurveTo(26.65, 26.28, 25.86, 28.14, 23.64, 29.08);
        context.bezierCurveTo(21.41, 30.03, 19.53, 29.31, 17.87, 27.64);
        context.bezierCurveTo(16.22, 29.31, 14.33, 30.03, 12.11, 29.08);
        context.bezierCurveTo(9.94, 28.16, 9.1, 26.36, 9.14, 24);
        context.bezierCurveTo(6.81, 24.03, 4.96, 23.22, 4.04, 21);
        context.bezierCurveTo(3.13, 18.81, 3.78, 16.94, 5.49, 15.28);
        context.bezierCurveTo(3.89, 13.66, 3.09, 11.8, 4.01, 9.56);
        context.bezierCurveTo(4.93, 7.34, 6.74, 6.46, 9.13, 6.53);
        context.bezierCurveTo(9.09, 4.05, 10.02, 2.19, 12.36, 1.32);
        context.bezierCurveTo(14.48, 0.54, 16.28, 1.25, 17.85, 2.87);
        context.bezierCurveTo(19.49, 1.25, 21.36, 0.46, 23.59, 1.4);
        context.bezierCurveTo(25.76, 2.32, 26.66, 4.07, 26.59, 6.52);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawBlueWoolyHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 77), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 77);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 80
        //// Bezier 1681 Drawing
        context.beginPath();
        context.moveTo(45.72, 21.07);
        context.bezierCurveTo(72.04, 31.88, 70.27, 52.54, 70.26, 74.77);
        context.lineTo(70.26, 74.77);
        context.bezierCurveTo(59.75, 64.26, 49.75, 59.01, 35.98, 59.01);
        context.lineTo(35.98, 58.98);
        context.bezierCurveTo(22.21, 58.98, 11.67, 64.24, 1.16, 74.74);
        context.lineTo(1.15, 74.75);
        context.bezierCurveTo(1.14, 52.68, -0.13, 33.55, 26.23, 20.71);
        context.bezierCurveTo(26.23, 20.71, 35.04, 16.68, 45.72, 21.07);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1682 Drawing
        context.beginPath();
        context.moveTo(45.72, 21.07);
        context.bezierCurveTo(72.04, 31.88, 70.27, 52.54, 70.26, 74.77);
        context.lineTo(70.26, 74.77);
        context.bezierCurveTo(59.75, 64.26, 49.75, 59.01, 35.98, 59.01);
        context.lineTo(35.98, 58.98);
        context.bezierCurveTo(22.21, 58.98, 11.67, 64.24, 1.16, 74.74);
        context.lineTo(1.15, 74.75);
        context.bezierCurveTo(1.14, 52.68, -0.13, 33.55, 26.23, 20.71);
        context.bezierCurveTo(26.23, 20.71, 35.04, 16.68, 45.72, 21.07);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 523 Drawing
        oval(context, 26.4, 0.72, 21.4, 21.4);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Oval 524 Drawing
        oval(context, 26.4, 0.72, 21.4, 21.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 50
        context.save();

        //// Clip Clip 50

        context.beginPath();
        context.moveTo(1.5, 62.95);
        context.bezierCurveTo(1.02, 69.08, 1.18, 68.32, 1.19, 74.68);
        context.lineTo(1.19, 74.68);
        context.lineTo(1.19, 74.68);
        context.bezierCurveTo(11.7, 64.18, 22.24, 58.92, 36.01, 58.92);
        context.lineTo(36.01, 58.92);
        context.lineTo(36.01, 58.95);
        context.bezierCurveTo(49.78, 58.95, 59.78, 64.2, 70.29, 74.71);
        context.lineTo(70.29, 74.71);
        context.lineTo(70.3, 74.71);
        context.bezierCurveTo(70.3, 68.47, 70.38, 69.24, 70, 63.11);
        context.lineTo(70, 63.11);
        context.bezierCurveTo(59.58, 52.76, 49.62, 47.57, 35.95, 47.57);
        context.lineTo(35.95, 47.57);
        context.lineTo(35.95, 47.55);
        context.bezierCurveTo(22.34, 47.55, 11.88, 52.69, 1.5, 62.95);
        context.closePath();
        context.clip();



        //// Rectangle 47 Drawing
        context.beginPath();
        context.rect(-3, 12.15, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Rectangle 48 Drawing
        context.beginPath();
        context.rect(9.9, 12.15, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Rectangle 49 Drawing
        context.beginPath();
        context.rect(22.8, 12.15, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Rectangle 50 Drawing
        context.beginPath();
        context.rect(35.75, 11.7, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Rectangle 51 Drawing
        context.beginPath();
        context.rect(48.65, 11.7, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Rectangle 52 Drawing
        context.beginPath();
        context.rect(61.55, 11.7, 12.9, 97.85);
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        context.restore();



        //// Bezier 1684 Drawing
        context.beginPath();
        context.moveTo(35.95, 47.57);
        context.lineTo(35.95, 47.55);
        context.bezierCurveTo(22.34, 47.55, 11.88, 52.69, 1.5, 62.95);
        context.bezierCurveTo(1.02, 69.08, 1.18, 68.32, 1.19, 74.68);
        context.lineTo(1.19, 74.68);
        context.bezierCurveTo(11.7, 64.18, 22.24, 58.92, 36.01, 58.92);
        context.lineTo(36.01, 58.95);
        context.bezierCurveTo(49.78, 58.95, 59.78, 64.2, 70.29, 74.71);
        context.lineTo(70.3, 74.71);
        context.bezierCurveTo(70.3, 68.47, 70.38, 69.24, 70, 63.11);
        context.bezierCurveTo(59.58, 52.76, 49.62, 47.57, 35.95, 47.57);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPinkHeadphones(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 84, 51), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 84, resizedFrame.h / 51);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 81
        //// Bezier 1663 Drawing
        context.beginPath();
        context.moveTo(72.31, 39.86);
        context.bezierCurveTo(71.31, 26.55, 69.87, 15.06, 51.01, 8.1);
        context.bezierCurveTo(40.32, 4.16, 31.52, 7.78, 31.52, 7.78);
        context.bezierCurveTo(12.78, 15.97, 12, 27.02, 10.81, 39.84);
        context.lineTo(10.81, 34.84);
        context.bezierCurveTo(12, 22.02, 12.78, 10.97, 31.52, 2.78);
        context.bezierCurveTo(31.52, 2.78, 40.32, -0.84, 51.01, 3.1);
        context.bezierCurveTo(69.87, 10.05, 71.31, 21.55, 72.31, 34.86);
        context.lineTo(72.31, 39.86);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 1664 Drawing
        context.beginPath();
        context.moveTo(72.31, 39.86);
        context.bezierCurveTo(71.31, 26.55, 69.87, 15.06, 51.01, 8.1);
        context.bezierCurveTo(40.32, 4.16, 31.52, 7.78, 31.52, 7.78);
        context.bezierCurveTo(12.78, 15.97, 12, 27.02, 10.81, 39.84);
        context.lineTo(10.81, 34.84);
        context.bezierCurveTo(12, 22.02, 12.78, 10.97, 31.52, 2.78);
        context.bezierCurveTo(31.52, 2.78, 40.32, -0.84, 51.01, 3.1);
        context.bezierCurveTo(69.87, 10.05, 71.31, 21.55, 72.31, 34.86);
        context.lineTo(72.31, 39.86);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.06;
        context.stroke();


        //// Bezier 1665 Drawing
        context.beginPath();
        context.moveTo(13.93, 50);
        context.bezierCurveTo(6.79, 50, 1, 44.21, 1, 37.07);
        context.bezierCurveTo(1, 29.92, 6.79, 24.13, 13.93, 24.13);
        context.lineTo(13.93, 50);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 1666 Drawing
        context.beginPath();
        context.moveTo(13.93, 50);
        context.bezierCurveTo(6.79, 50, 1, 44.21, 1, 37.07);
        context.bezierCurveTo(1, 29.92, 6.79, 24.13, 13.93, 24.13);
        context.lineTo(13.93, 50);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1667 Drawing
        context.beginPath();
        context.moveTo(69.49, 24.13);
        context.bezierCurveTo(76.63, 24.13, 82.42, 29.92, 82.42, 37.07);
        context.bezierCurveTo(82.42, 44.21, 76.63, 50, 69.49, 50);
        context.lineTo(69.49, 24.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Bezier 1668 Drawing
        context.beginPath();
        context.moveTo(69.49, 24.13);
        context.bezierCurveTo(76.63, 24.13, 82.42, 29.92, 82.42, 37.07);
        context.bezierCurveTo(82.42, 44.21, 76.63, 50, 69.49, 50);
        context.lineTo(69.49, 24.13);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawRoundGlasses(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 40, 19), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 40, resizedFrame.h / 19);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Oval 525 Drawing
        oval(context, 1, 1.6, 16.4, 16.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 526 Drawing
        oval(context, 22.35, 1.1, 16.4, 16.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 527 Drawing
        oval(context, 1, 1.6, 16.4, 16.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 528 Drawing
        oval(context, 22.35, 1.1, 16.4, 16.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1699 Drawing
        context.beginPath();
        context.moveTo(16.96, 8.97);
        context.lineTo(22.37, 8.97);
        context.fillStyle = AvatarStyleKit.hair7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawCrown(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 66, 38), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 66, resizedFrame.h / 38);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';

        //// Bezier 1727 Drawing
        context.beginPath();
        context.moveTo(61.06, 35.93);
        context.lineTo(62.22, 14.43);
        context.lineTo(51.84, 19.76);
        context.lineTo(47.03, 7.08);
        context.lineTo(38.79, 18.07);
        context.lineTo(32.01, 3.53);
        context.lineTo(25.23, 18.07);
        context.lineTo(16.99, 7.08);
        context.lineTo(12.09, 19.76);
        context.lineTo(3.17, 13.56);
        context.lineTo(2.97, 36);
        context.bezierCurveTo(6.04, 31.44, 34.49, 19.33, 61.06, 35.93);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 1728 Drawing
        context.beginPath();
        context.moveTo(61.06, 35.93);
        context.lineTo(62.22, 14.43);
        context.lineTo(51.84, 19.76);
        context.lineTo(47.03, 7.08);
        context.lineTo(38.79, 18.07);
        context.lineTo(32.01, 3.53);
        context.lineTo(25.23, 18.07);
        context.lineTo(16.99, 7.08);
        context.lineTo(12.09, 19.76);
        context.lineTo(3.17, 13.56);
        context.lineTo(2.97, 36);
        context.bezierCurveTo(6.04, 31.44, 34.49, 19.33, 61.06, 35.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 539 Drawing
        oval(context, 1, 11.99, 4.3, 4.3);
        context.fillStyle = AvatarStyleKit.col5;
        context.fill();


        //// Oval 540 Drawing
        oval(context, 1, 11.99, 4.3, 4.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 541 Drawing
        oval(context, 14.55, 4.44, 4.3, 4.3);
        context.fillStyle = AvatarStyleKit.col3;
        context.fill();


        //// Oval 542 Drawing
        oval(context, 14.55, 4.44, 4.3, 4.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 543 Drawing
        oval(context, 29.85, 0.99, 4.3, 4.3);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Oval 544 Drawing
        oval(context, 29.85, 0.99, 4.3, 4.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 545 Drawing
        oval(context, 44.95, 4.44, 4.3, 4.3);
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Oval 546 Drawing
        oval(context, 44.95, 4.44, 4.3, 4.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 547 Drawing
        oval(context, 60.05, 12.49, 4.3, 4.2);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 548 Drawing
        oval(context, 60.05, 12.49, 4.3, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawStarHeadband(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 71, 34), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 71, resizedFrame.h / 34);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';

        //// Group 84
        //// Bezier 1658 Drawing
        context.beginPath();
        context.moveTo(35.8, 4.86);
        context.lineTo(35.8, 4.83);
        context.bezierCurveTo(22.18, 4.83, 11.73, 9.98, 1.34, 20.24);
        context.bezierCurveTo(0.86, 26.37, 1.03, 25.61, 1.03, 31.97);
        context.lineTo(1.04, 31.97);
        context.bezierCurveTo(11.54, 21.46, 22.08, 16.21, 35.86, 16.21);
        context.lineTo(35.86, 16.24);
        context.bezierCurveTo(49.62, 16.24, 59.63, 21.49, 70.13, 32);
        context.lineTo(70.14, 32);
        context.bezierCurveTo(70.14, 25.76, 70.23, 26.53, 69.85, 20.4);
        context.bezierCurveTo(59.42, 10.05, 49.47, 4.86, 35.8, 4.86);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Bezier 1659 Drawing
        context.beginPath();
        context.moveTo(35.8, 4.86);
        context.lineTo(35.8, 4.83);
        context.bezierCurveTo(22.18, 4.83, 11.73, 9.98, 1.34, 20.24);
        context.bezierCurveTo(0.86, 26.37, 1.03, 25.61, 1.03, 31.97);
        context.lineTo(1.04, 31.97);
        context.bezierCurveTo(11.54, 21.46, 22.08, 16.21, 35.86, 16.21);
        context.lineTo(35.86, 16.24);
        context.bezierCurveTo(49.62, 16.24, 59.63, 21.49, 70.13, 32);
        context.lineTo(70.14, 32);
        context.bezierCurveTo(70.14, 25.76, 70.23, 26.53, 69.85, 20.4);
        context.bezierCurveTo(59.42, 10.05, 49.47, 4.86, 35.8, 4.86);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1725 Drawing
        context.beginPath();
        context.moveTo(53.64, 20.74);
        context.lineTo(45.47, 26.15);
        context.lineTo(47.59, 16.5);
        context.lineTo(40.43, 10.62);
        context.lineTo(49.55, 9.85);
        context.lineTo(53.64, 1.02);
        context.lineTo(57.72, 9.85);
        context.lineTo(66.85, 10.62);
        context.lineTo(59.69, 17.05);
        context.lineTo(61.8, 26.15);
        context.lineTo(53.64, 20.74);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 1726 Drawing
        context.beginPath();
        context.moveTo(53.64, 20.74);
        context.lineTo(45.47, 26.15);
        context.lineTo(47.59, 16.5);
        context.lineTo(40.43, 10.62);
        context.lineTo(49.55, 9.85);
        context.lineTo(53.64, 1.02);
        context.lineTo(57.72, 9.85);
        context.lineTo(66.85, 10.62);
        context.lineTo(59.69, 17.05);
        context.lineTo(61.8, 26.15);
        context.lineTo(53.64, 20.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawAngledGlasses(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 58, 22), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 58, resizedFrame.h / 22);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 85
        //// Bezier 1703 Drawing
        context.beginPath();
        context.moveTo(32.5, 17.79);
        context.bezierCurveTo(38.33, 23.22, 44.86, 20.34, 49.25, 18.66);
        context.bezierCurveTo(53.9, 16.89, 56.15, 8.84, 56.86, 3.99);
        context.bezierCurveTo(57.58, -0.87, 46.27, 3.66, 41.91, 5.03);
        context.bezierCurveTo(37.56, 6.41, 30.71, 10, 30.71, 10);
        context.bezierCurveTo(30.18, 10.28, 28.95, 10.39, 28.95, 10.39);
        context.bezierCurveTo(28.95, 10.39, 27.72, 10.28, 27.18, 10);
        context.bezierCurveTo(27.18, 10, 20.34, 6.41, 15.98, 5.03);
        context.bezierCurveTo(11.63, 3.66, 0.32, -0.87, 1.03, 3.99);
        context.bezierCurveTo(1.75, 8.84, 4, 16.89, 8.65, 18.66);
        context.bezierCurveTo(12.99, 20.32, 19.55, 23.3, 25.2, 17.97);
        context.bezierCurveTo(25.2, 17.97, 26.23, 16.77, 26.59, 16.05);
        context.bezierCurveTo(27.22, 14.79, 28.25, 12.8, 28.95, 12.7);
        context.bezierCurveTo(29.63, 12.8, 30.64, 14.69, 31.26, 15.95);
        context.bezierCurveTo(31.26, 15.95, 32.02, 17.34, 32.5, 17.79);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 1704 Drawing
        context.beginPath();
        context.moveTo(32.5, 17.79);
        context.bezierCurveTo(38.33, 23.22, 44.86, 20.34, 49.25, 18.66);
        context.bezierCurveTo(53.9, 16.89, 56.15, 8.84, 56.86, 3.99);
        context.bezierCurveTo(57.58, -0.87, 46.27, 3.66, 41.91, 5.03);
        context.bezierCurveTo(37.56, 6.41, 30.71, 10, 30.71, 10);
        context.bezierCurveTo(30.18, 10.28, 28.95, 10.39, 28.95, 10.39);
        context.bezierCurveTo(28.95, 10.39, 27.72, 10.28, 27.18, 10);
        context.bezierCurveTo(27.18, 10, 20.34, 6.41, 15.98, 5.03);
        context.bezierCurveTo(11.63, 3.66, 0.32, -0.87, 1.03, 3.99);
        context.bezierCurveTo(1.75, 8.84, 4, 16.89, 8.65, 18.66);
        context.bezierCurveTo(12.99, 20.32, 19.55, 23.3, 25.2, 17.97);
        context.bezierCurveTo(25.2, 17.97, 26.23, 16.77, 26.59, 16.05);
        context.bezierCurveTo(27.22, 14.79, 28.25, 12.8, 28.95, 12.7);
        context.bezierCurveTo(29.63, 12.8, 30.64, 14.69, 31.26, 15.95);
        context.bezierCurveTo(31.26, 15.95, 32.02, 17.34, 32.5, 17.79);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBlueTassleHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 100, 111), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 100, resizedFrame.h / 111);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 86
        //// Bezier 1729 Drawing
        context.beginPath();
        context.moveTo(14.81, 55.94);
        context.bezierCurveTo(14.81, 55.94, 12.98, 76.61, 11.31, 91.94);
        context.bezierCurveTo(11.31, 91.94, 12.52, 94.44, 13.75, 91.94);
        context.bezierCurveTo(14.98, 89.44, 17.14, 62.11, 17.31, 52.11);
        context.bezierCurveTo(17.48, 42.11, 14.81, 55.94, 14.81, 55.94);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1730 Drawing
        context.beginPath();
        context.moveTo(14.81, 55.94);
        context.bezierCurveTo(14.81, 55.94, 12.98, 76.61, 11.31, 91.94);
        context.bezierCurveTo(11.31, 91.94, 12.52, 94.44, 13.75, 91.94);
        context.bezierCurveTo(14.98, 89.44, 17.14, 62.11, 17.31, 52.11);
        context.bezierCurveTo(17.48, 42.11, 14.81, 55.94, 14.81, 55.94);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1731 Drawing
        context.beginPath();
        context.moveTo(83.82, 57.21);
        context.bezierCurveTo(83.82, 57.21, 85.65, 77.88, 87.32, 93.21);
        context.bezierCurveTo(87.32, 93.21, 86.1, 95.71, 84.88, 93.21);
        context.bezierCurveTo(83.65, 90.71, 81.48, 63.38, 81.32, 53.38);
        context.bezierCurveTo(81.15, 43.38, 83.82, 57.21, 83.82, 57.21);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1732 Drawing
        context.beginPath();
        context.moveTo(83.82, 57.21);
        context.bezierCurveTo(83.82, 57.21, 85.65, 77.88, 87.32, 93.21);
        context.bezierCurveTo(87.32, 93.21, 86.1, 95.71, 84.88, 93.21);
        context.bezierCurveTo(83.65, 90.71, 81.48, 63.38, 81.32, 53.38);
        context.bezierCurveTo(81.15, 43.38, 83.82, 57.21, 83.82, 57.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1733 Drawing
        context.beginPath();
        context.moveTo(59.23, 3.66);
        context.bezierCurveTo(85.55, 14.48, 83.78, 35.14, 83.77, 57.37);
        context.lineTo(83.76, 57.37);
        context.bezierCurveTo(73.26, 46.86, 63.26, 41.61, 49.49, 41.61);
        context.lineTo(49.49, 41.58);
        context.bezierCurveTo(35.72, 41.58, 25.17, 46.84, 14.67, 57.34);
        context.lineTo(14.66, 57.34);
        context.bezierCurveTo(14.65, 35.27, 13.38, 16.15, 39.74, 3.31);
        context.bezierCurveTo(39.74, 3.31, 48.54, -0.73, 59.23, 3.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col4;
        context.fill();


        //// Bezier 1734 Drawing
        context.beginPath();
        context.moveTo(59.23, 3.66);
        context.bezierCurveTo(85.55, 14.48, 83.78, 35.14, 83.77, 57.37);
        context.lineTo(83.76, 57.37);
        context.bezierCurveTo(73.26, 46.86, 63.26, 41.61, 49.49, 41.61);
        context.lineTo(49.49, 41.58);
        context.bezierCurveTo(35.72, 41.58, 25.17, 46.84, 14.67, 57.34);
        context.lineTo(14.66, 57.34);
        context.bezierCurveTo(14.65, 35.27, 13.38, 16.15, 39.74, 3.31);
        context.bezierCurveTo(39.74, 3.31, 48.54, -0.73, 59.23, 3.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1735 Drawing
        context.beginPath();
        context.moveTo(49.46, 24.69);
        context.lineTo(49.46, 24.67);
        context.bezierCurveTo(35.85, 24.67, 25.39, 29.81, 15, 40.07);
        context.bezierCurveTo(14.52, 46.2, 14.69, 50.92, 14.69, 57.28);
        context.lineTo(14.7, 57.28);
        context.bezierCurveTo(25.21, 46.77, 35.75, 41.52, 49.52, 41.52);
        context.lineTo(49.52, 41.55);
        context.bezierCurveTo(63.29, 41.55, 73.29, 46.8, 83.8, 57.31);
        context.lineTo(83.8, 57.31);
        context.bezierCurveTo(83.81, 51.07, 83.89, 46.36, 83.51, 40.23);
        context.bezierCurveTo(73.09, 29.88, 63.13, 24.69, 49.46, 24.69);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 1736 Drawing
        context.beginPath();
        context.moveTo(49.46, 24.69);
        context.lineTo(49.46, 24.67);
        context.bezierCurveTo(35.85, 24.67, 25.39, 29.81, 15, 40.07);
        context.bezierCurveTo(14.52, 46.2, 14.69, 50.92, 14.69, 57.28);
        context.lineTo(14.7, 57.28);
        context.bezierCurveTo(25.21, 46.77, 35.75, 41.52, 49.52, 41.52);
        context.lineTo(49.52, 41.55);
        context.bezierCurveTo(63.29, 41.55, 73.29, 46.8, 83.8, 57.31);
        context.lineTo(83.8, 57.31);
        context.bezierCurveTo(83.81, 51.07, 83.89, 46.36, 83.51, 40.23);
        context.bezierCurveTo(73.09, 29.88, 63.13, 24.69, 49.46, 24.69);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Star 33 Drawing
        context.save();
        context.translate(11.25, 98.86);
        context.rotate(-4.4 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.01, -6.18);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.26, -3.82);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.5, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.26, 3.82);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.01, 6.18);
        context.lineTo(0, 9.55);
        context.lineTo(-2.01, 6.18);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.26, 3.82);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.5, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.26, -3.82);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.01, -6.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();

        context.restore();


        //// Star 34 Drawing
        context.save();
        context.translate(11.25, 98.86);
        context.rotate(-4.4 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.01, -6.18);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.26, -3.82);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.5, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.26, 3.82);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.01, 6.18);
        context.lineTo(0, 9.55);
        context.lineTo(-2.01, 6.18);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.26, 3.82);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.5, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.26, -3.82);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.01, -6.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.75;
        context.stroke();

        context.restore();


        //// Star 35 Drawing
        context.save();
        context.translate(11.25, 98.86);
        context.rotate(-4.4 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.01, -6.18);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.26, -3.82);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.5, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.26, 3.82);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.01, 6.18);
        context.lineTo(0, 9.55);
        context.lineTo(-2.01, 6.18);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.26, 3.82);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.5, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.26, -3.82);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.01, -6.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();

        context.restore();


        //// Star 36 Drawing
        context.save();
        context.translate(11.25, 98.86);
        context.rotate(-4.4 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.01, -6.18);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.26, -3.82);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.5, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.26, 3.82);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.01, 6.18);
        context.lineTo(0, 9.55);
        context.lineTo(-2.01, 6.18);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.26, 3.82);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.5, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.26, -3.82);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.01, -6.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.75;
        context.stroke();

        context.restore();


        //// Star 37 Drawing
        context.save();
        context.translate(87.25, 98.86);
        context.rotate(-31.6 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.02, -6.23);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.3, -3.85);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.55, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.3, 3.85);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.02, 6.23);
        context.lineTo(0, 9.55);
        context.lineTo(-2.02, 6.23);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.3, 3.85);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.55, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.3, -3.85);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.02, -6.23);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();

        context.restore();


        //// Star 38 Drawing
        context.save();
        context.translate(87.25, 98.86);
        context.rotate(-31.6 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.02, -6.23);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.3, -3.85);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.55, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.3, 3.85);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.02, 6.23);
        context.lineTo(0, 9.55);
        context.lineTo(-2.02, 6.23);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.3, 3.85);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.55, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.3, -3.85);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.02, -6.23);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.75;
        context.stroke();

        context.restore();


        //// Star 39 Drawing
        context.save();
        context.translate(87.25, 98.86);
        context.rotate(-31.6 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.02, -6.23);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.3, -3.85);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.55, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.3, 3.85);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.02, 6.23);
        context.lineTo(0, 9.55);
        context.lineTo(-2.02, 6.23);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.3, 3.85);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.55, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.3, -3.85);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.02, -6.23);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();

        context.restore();


        //// Star 40 Drawing
        context.save();
        context.translate(87.25, 98.86);
        context.rotate(-31.6 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -9.55);
        context.lineTo(2.02, -6.23);
        context.lineTo(5.61, -7.73);
        context.lineTo(5.3, -3.85);
        context.lineTo(9.08, -2.95);
        context.lineTo(6.55, 0);
        context.lineTo(9.08, 2.95);
        context.lineTo(5.3, 3.85);
        context.lineTo(5.61, 7.73);
        context.lineTo(2.02, 6.23);
        context.lineTo(0, 9.55);
        context.lineTo(-2.02, 6.23);
        context.lineTo(-5.61, 7.73);
        context.lineTo(-5.3, 3.85);
        context.lineTo(-9.08, 2.95);
        context.lineTo(-6.55, 0);
        context.lineTo(-9.08, -2.95);
        context.lineTo(-5.3, -3.85);
        context.lineTo(-5.61, -7.73);
        context.lineTo(-2.02, -6.23);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.75;
        context.stroke();

        context.restore();
        
        context.restore();

    }

    function drawGreenHeadphones(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 84, 51), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 84, resizedFrame.h / 51);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 64
        //// Bezier 1669 Drawing
        context.beginPath();
        context.moveTo(72.3, 39.86);
        context.bezierCurveTo(71.31, 26.55, 69.87, 15.06, 51.01, 8.1);
        context.bezierCurveTo(40.32, 4.16, 31.52, 7.78, 31.52, 7.78);
        context.bezierCurveTo(12.78, 15.97, 12, 27.02, 10.81, 39.84);
        context.lineTo(10.81, 34.84);
        context.bezierCurveTo(12, 22.02, 12.78, 10.97, 31.52, 2.78);
        context.bezierCurveTo(31.52, 2.78, 40.32, -0.84, 51.01, 3.1);
        context.bezierCurveTo(69.87, 10.05, 71.31, 21.55, 72.3, 34.86);
        context.lineTo(72.3, 39.86);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 1670 Drawing
        context.beginPath();
        context.moveTo(72.3, 39.86);
        context.bezierCurveTo(71.31, 26.55, 69.87, 15.06, 51.01, 8.1);
        context.bezierCurveTo(40.32, 4.16, 31.52, 7.78, 31.52, 7.78);
        context.bezierCurveTo(12.78, 15.97, 12, 27.02, 10.81, 39.84);
        context.lineTo(10.81, 34.84);
        context.bezierCurveTo(12, 22.02, 12.78, 10.97, 31.52, 2.78);
        context.bezierCurveTo(31.52, 2.78, 40.32, -0.84, 51.01, 3.1);
        context.bezierCurveTo(69.87, 10.05, 71.31, 21.55, 72.3, 34.86);
        context.lineTo(72.3, 39.86);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.06;
        context.stroke();


        //// Bezier 1671 Drawing
        context.beginPath();
        context.moveTo(13.93, 50);
        context.bezierCurveTo(6.79, 50, 1, 44.21, 1, 37.07);
        context.bezierCurveTo(1, 29.92, 6.79, 24.13, 13.93, 24.13);
        context.lineTo(13.93, 50);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 1672 Drawing
        context.beginPath();
        context.moveTo(13.93, 50);
        context.bezierCurveTo(6.79, 50, 1, 44.21, 1, 37.07);
        context.bezierCurveTo(1, 29.92, 6.79, 24.13, 13.93, 24.13);
        context.lineTo(13.93, 50);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1673 Drawing
        context.beginPath();
        context.moveTo(69.49, 24.13);
        context.bezierCurveTo(76.63, 24.13, 82.42, 29.92, 82.42, 37.07);
        context.bezierCurveTo(82.42, 44.21, 76.63, 50, 69.49, 50);
        context.lineTo(69.49, 24.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 1674 Drawing
        context.beginPath();
        context.moveTo(69.49, 24.13);
        context.bezierCurveTo(76.63, 24.13, 82.42, 29.92, 82.42, 37.07);
        context.bezierCurveTo(82.42, 44.21, 76.63, 50, 69.49, 50);
        context.lineTo(69.49, 24.13);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawCoolShades(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 52, 21), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 52, resizedFrame.h / 21);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 87
        //// Bezier 1705 Drawing
        context.beginPath();
        context.moveTo(15.47, 1.88);
        context.lineTo(36.38, 1.88);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1706 Drawing
        context.beginPath();
        context.moveTo(23.07, 6.94);
        context.bezierCurveTo(23.8, 3.91, 28.11, 3.91, 28.84, 6.94);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1707 Drawing
        context.beginPath();
        context.moveTo(23.62, 7.59);
        context.bezierCurveTo(22.65, 15.17, 17.15, 18.92, 11.29, 19.84);
        context.bezierCurveTo(5.44, 20.76, 1.48, 17.76, 1.02, 9.02);
        context.bezierCurveTo(0.55, 0.29, 10.4, 1.86, 14.24, 1.67);
        context.bezierCurveTo(14.24, 1.67, 24.37, 1.05, 23.62, 7.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 1708 Drawing
        context.beginPath();
        context.moveTo(23.62, 7.59);
        context.bezierCurveTo(22.65, 15.17, 17.15, 18.92, 11.29, 19.84);
        context.bezierCurveTo(5.44, 20.76, 1.48, 17.76, 1.02, 9.02);
        context.bezierCurveTo(0.55, 0.29, 10.4, 1.86, 14.24, 1.67);
        context.bezierCurveTo(14.24, 1.67, 24.37, 1.05, 23.62, 7.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1709 Drawing
        context.beginPath();
        context.moveTo(37.48, 1.67);
        context.bezierCurveTo(41.32, 1.67, 51.17, 0.29, 50.7, 9.02);
        context.bezierCurveTo(50.24, 17.76, 46.28, 20.76, 40.43, 19.84);
        context.bezierCurveTo(34.57, 18.91, 29.07, 15.17, 28.1, 7.59);
        context.bezierCurveTo(28.1, 7.59, 26.85, 1.7, 37.48, 1.67);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 1710 Drawing
        context.beginPath();
        context.moveTo(37.48, 1.67);
        context.bezierCurveTo(41.32, 1.67, 51.17, 0.29, 50.7, 9.02);
        context.bezierCurveTo(50.24, 17.76, 46.28, 20.76, 40.43, 19.84);
        context.bezierCurveTo(34.57, 18.91, 29.07, 15.17, 28.1, 7.59);
        context.bezierCurveTo(28.1, 7.59, 26.85, 1.7, 37.48, 1.67);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawGreenHeadband(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 30), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 30);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 1660 Drawing
        context.beginPath();
        context.moveTo(35.8, 1.86);
        context.lineTo(35.8, 1.83);
        context.bezierCurveTo(22.18, 1.83, 11.73, 6.98, 1.34, 17.24);
        context.bezierCurveTo(0.86, 23.37, 1.03, 22.61, 1.03, 28.97);
        context.lineTo(1.04, 28.97);
        context.bezierCurveTo(11.54, 18.46, 22.08, 13.21, 35.85, 13.21);
        context.lineTo(35.85, 13.24);
        context.bezierCurveTo(49.62, 13.24, 59.63, 18.49, 70.13, 29);
        context.lineTo(70.14, 29);
        context.bezierCurveTo(70.14, 22.76, 70.23, 23.53, 69.85, 17.4);
        context.bezierCurveTo(59.42, 7.04, 49.47, 1.86, 35.8, 1.86);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Bezier 1661 Drawing
        context.beginPath();
        context.moveTo(35.8, 1.86);
        context.lineTo(35.8, 1.83);
        context.bezierCurveTo(22.18, 1.83, 11.73, 6.98, 1.34, 17.24);
        context.bezierCurveTo(0.86, 23.37, 1.03, 22.61, 1.03, 28.97);
        context.lineTo(1.04, 28.97);
        context.bezierCurveTo(11.54, 18.46, 22.08, 13.21, 35.85, 13.21);
        context.lineTo(35.85, 13.24);
        context.bezierCurveTo(49.62, 13.24, 59.63, 18.49, 70.13, 29);
        context.lineTo(70.14, 29);
        context.bezierCurveTo(70.14, 22.76, 70.23, 23.53, 69.85, 17.4);
        context.bezierCurveTo(59.42, 7.04, 49.47, 1.86, 35.8, 1.86);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBlueCap(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 79, 55), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 79, resizedFrame.h / 55);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 91
        //// Bezier 1689 Drawing
        context.beginPath();
        context.moveTo(4.94, 37.36);
        context.lineTo(15.51, 30.48);
        context.bezierCurveTo(15.51, 30.48, 15.63, 30.51, 15.83, 30.55);
        context.bezierCurveTo(19.27, 31.35, 51.96, 38.76, 74.56, 38.93);
        context.bezierCurveTo(74.82, 38.93, 75.08, 38.94, 75.33, 38.94);
        context.bezierCurveTo(75.14, 38.93, 60.19, 65.82, 8.42, 47.76);
        context.bezierCurveTo(8.42, 47.76, -5.63, 44.25, 4.94, 37.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Bezier 1690 Drawing
        context.beginPath();
        context.moveTo(4.94, 37.36);
        context.lineTo(15.51, 30.48);
        context.bezierCurveTo(15.51, 30.48, 15.63, 30.51, 15.83, 30.55);
        context.bezierCurveTo(19.27, 31.35, 51.96, 38.76, 74.56, 38.93);
        context.bezierCurveTo(74.82, 38.93, 75.08, 38.94, 75.33, 38.94);
        context.bezierCurveTo(75.14, 38.93, 60.19, 65.82, 8.42, 47.76);
        context.bezierCurveTo(8.42, 47.76, -5.63, 44.25, 4.94, 37.36);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.04;
        context.stroke();


        //// Bezier 1691 Drawing
        context.beginPath();
        context.moveTo(15.82, 30.48);
        context.bezierCurveTo(17.56, 18.96, 22.85, 8.95, 38.72, 2.89);
        context.bezierCurveTo(49.03, -1.05, 57.51, 2.57, 57.51, 2.57);
        context.bezierCurveTo(75.78, 8.91, 76.53, 23.51, 77.18, 34.83);
        context.bezierCurveTo(77.18, 34.83, 78.37, 54.93, 15.82, 30.48);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col1;
        context.fill();


        //// Bezier 1692 Drawing
        context.beginPath();
        context.moveTo(15.82, 30.48);
        context.bezierCurveTo(17.56, 18.96, 22.85, 8.95, 38.72, 2.89);
        context.bezierCurveTo(49.03, -1.05, 57.51, 2.57, 57.51, 2.57);
        context.bezierCurveTo(75.78, 8.91, 76.53, 23.51, 77.18, 34.83);
        context.bezierCurveTo(77.18, 34.83, 78.37, 54.93, 15.82, 30.48);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.04;
        context.stroke();
        
        context.restore();

    }

    function drawOrangeHeadband(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 30), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 30);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 92
        //// Group 48
        context.save();

        //// Clip Clip 48

        context.beginPath();
        context.moveTo(2.03, 16.27);
        context.bezierCurveTo(1.55, 22.39, 1.72, 21.64, 1.72, 28);
        context.lineTo(1.72, 28);
        context.lineTo(1.73, 28);
        context.bezierCurveTo(12.23, 17.49, 22.77, 12.24, 36.54, 12.24);
        context.lineTo(36.54, 12.24);
        context.lineTo(36.54, 12.27);
        context.bezierCurveTo(50.31, 12.27, 60.32, 17.52, 70.82, 28.03);
        context.lineTo(70.82, 28.03);
        context.lineTo(70.83, 28.03);
        context.bezierCurveTo(70.83, 21.79, 70.91, 22.55, 70.53, 16.43);
        context.lineTo(70.53, 16.43);
        context.bezierCurveTo(60.11, 6.07, 50.15, 0.89, 36.48, 0.89);
        context.lineTo(36.48, 0.89);
        context.lineTo(36.48, 0.86);
        context.bezierCurveTo(22.87, 0.86, 12.42, 6, 2.03, 16.27);
        context.closePath();
        context.clip();



        //// Rectangle 35 Drawing
        context.beginPath();
        context.rect(1, -30.85, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Rectangle 36 Drawing
        context.beginPath();
        context.rect(12.55, -30.85, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Rectangle 37 Drawing
        context.beginPath();
        context.rect(24.15, -30.85, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        //// Rectangle 38 Drawing
        context.beginPath();
        context.rect(35.75, -31.25, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.hair2;
        context.fill();


        //// Rectangle 39 Drawing
        context.beginPath();
        context.rect(47.35, -31.25, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();


        //// Rectangle 40 Drawing
        context.beginPath();
        context.rect(58.95, -31.25, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();


        context.restore();



        //// Bezier 1737 Drawing
        context.beginPath();
        context.moveTo(36.48, 0.89);
        context.lineTo(36.48, 0.86);
        context.bezierCurveTo(22.87, 0.86, 12.42, 6, 2.03, 16.27);
        context.bezierCurveTo(1.55, 22.39, 1.71, 21.64, 1.72, 28);
        context.lineTo(1.72, 28);
        context.bezierCurveTo(12.23, 17.49, 22.77, 12.24, 36.54, 12.24);
        context.lineTo(36.54, 12.27);
        context.bezierCurveTo(50.31, 12.27, 60.31, 17.52, 70.82, 28.03);
        context.lineTo(70.83, 28.03);
        context.bezierCurveTo(70.83, 21.79, 70.91, 22.55, 70.53, 16.43);
        context.bezierCurveTo(60.11, 6.07, 50.15, 0.89, 36.48, 0.89);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawDarkShades(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 54, 20), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 54, resizedFrame.h / 20);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Group 88
        //// Bezier 1700 Drawing
        context.beginPath();
        context.moveTo(52.13, 3.38);
        context.bezierCurveTo(51.55, 0.72, 46.97, 1.36, 46.97, 1.36);
        context.bezierCurveTo(42.11, 1.53, 27.58, 4.51, 26.89, 4.65);
        context.bezierCurveTo(26.2, 4.51, 11.67, 1.53, 6.81, 1.36);
        context.bezierCurveTo(6.81, 1.36, 2.23, 0.72, 1.65, 3.38);
        context.bezierCurveTo(1.07, 6.05, -1.02, 17.99, 7.56, 18.75);
        context.bezierCurveTo(16.14, 19.5, 21.12, 19.04, 23.39, 10.69);
        context.bezierCurveTo(23.39, 10.69, 24.42, 6.96, 26.89, 6.64);
        context.bezierCurveTo(29.36, 6.96, 30.39, 10.69, 30.39, 10.69);
        context.bezierCurveTo(32.65, 19.04, 37.64, 19.5, 46.22, 18.75);
        context.bezierCurveTo(54.8, 17.99, 52.71, 6.05, 52.13, 3.38);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1701 Drawing
        context.beginPath();
        context.moveTo(52.13, 3.38);
        context.bezierCurveTo(51.55, 0.72, 46.97, 1.36, 46.97, 1.36);
        context.bezierCurveTo(42.11, 1.53, 27.58, 4.51, 26.89, 4.65);
        context.bezierCurveTo(26.2, 4.51, 11.67, 1.53, 6.81, 1.36);
        context.bezierCurveTo(6.81, 1.36, 2.23, 0.72, 1.65, 3.38);
        context.bezierCurveTo(1.07, 6.05, -1.02, 17.99, 7.56, 18.75);
        context.bezierCurveTo(16.14, 19.5, 21.12, 19.04, 23.39, 10.69);
        context.bezierCurveTo(23.39, 10.69, 24.42, 6.96, 26.89, 6.64);
        context.bezierCurveTo(29.36, 6.96, 30.39, 10.69, 30.39, 10.69);
        context.bezierCurveTo(32.65, 19.04, 37.64, 19.5, 46.22, 18.75);
        context.bezierCurveTo(54.8, 17.99, 52.71, 6.05, 52.13, 3.38);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 1702 Drawing
        context.beginPath();
        context.moveTo(52.13, 3.38);
        context.bezierCurveTo(51.55, 0.72, 46.97, 1.36, 46.97, 1.36);
        context.bezierCurveTo(42.11, 1.53, 27.58, 4.51, 26.89, 4.65);
        context.bezierCurveTo(26.2, 4.51, 11.67, 1.53, 6.81, 1.36);
        context.bezierCurveTo(6.81, 1.36, 2.23, 0.72, 1.65, 3.38);
        context.bezierCurveTo(1.07, 6.05, -1.02, 17.99, 7.56, 18.75);
        context.bezierCurveTo(16.14, 19.5, 21.12, 19.04, 23.39, 10.69);
        context.bezierCurveTo(23.39, 10.69, 24.42, 6.96, 26.89, 6.64);
        context.bezierCurveTo(29.36, 6.96, 30.39, 10.69, 30.39, 10.69);
        context.bezierCurveTo(32.65, 19.04, 37.64, 19.5, 46.22, 18.75);
        context.bezierCurveTo(54.8, 17.99, 52.71, 6.05, 52.13, 3.38);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawGreenWoolyHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 72, 58), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 72, resizedFrame.h / 58);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Group 93
        //// Group 49
        context.save();

        //// Clip Clip 49

        context.beginPath();
        context.moveTo(27.09, 2.69);
        context.bezierCurveTo(0.73, 15.53, 2, 34.66, 2.01, 56.73);
        context.lineTo(2.01, 56.73);
        context.lineTo(2.02, 56.73);
        context.bezierCurveTo(12.53, 46.22, 23.07, 40.97, 36.84, 40.97);
        context.lineTo(36.84, 40.97);
        context.lineTo(36.84, 40.99);
        context.bezierCurveTo(50.61, 40.99, 60.61, 46.25, 71.12, 56.75);
        context.lineTo(71.12, 56.75);
        context.lineTo(71.12, 56.76);
        context.bezierCurveTo(71.13, 34.52, 72.9, 13.86, 46.58, 3.05);
        context.lineTo(46.58, 3.05);
        context.bezierCurveTo(42.81, 1.5, 39.28, 1, 36.29, 1);
        context.lineTo(36.29, 1);
        context.bezierCurveTo(30.79, 1, 27.09, 2.69, 27.09, 2.69);
        context.closePath();
        context.clip();



        //// Rectangle 41 Drawing
        context.beginPath();
        context.rect(2.3, -14.18, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Rectangle 42 Drawing
        context.beginPath();
        context.rect(13.9, -14.18, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Rectangle 43 Drawing
        context.beginPath();
        context.rect(25.5, -14.18, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        //// Rectangle 44 Drawing
        context.beginPath();
        context.rect(37.1, -14.58, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();


        //// Rectangle 45 Drawing
        context.beginPath();
        context.rect(48.7, -14.58, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Rectangle 46 Drawing
        context.beginPath();
        context.rect(60.3, -14.58, 11.6, 87.85);
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();


        context.restore();



        //// Bezier 1678 Drawing
        context.beginPath();
        context.moveTo(36.81, 29.56);
        context.lineTo(36.81, 29.53);
        context.bezierCurveTo(23.2, 29.53, 12.74, 34.67, 2.36, 44.94);
        context.bezierCurveTo(1.88, 51.06, 2.04, 50.31, 2.04, 56.67);
        context.lineTo(2.05, 56.66);
        context.bezierCurveTo(12.56, 46.16, 23.1, 40.91, 36.87, 40.91);
        context.lineTo(36.87, 40.93);
        context.bezierCurveTo(50.64, 40.93, 60.64, 46.18, 71.15, 56.69);
        context.lineTo(71.16, 56.69);
        context.bezierCurveTo(71.16, 50.45, 71.24, 51.22, 70.86, 45.09);
        context.bezierCurveTo(60.44, 34.74, 50.48, 29.56, 36.81, 29.56);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 1679 Drawing
        context.beginPath();
        context.moveTo(36.81, 29.56);
        context.lineTo(36.81, 29.53);
        context.bezierCurveTo(23.2, 29.53, 12.74, 34.67, 2.36, 44.94);
        context.bezierCurveTo(1.88, 51.06, 2.04, 50.31, 2.04, 56.67);
        context.lineTo(2.05, 56.66);
        context.bezierCurveTo(12.56, 46.16, 23.1, 40.91, 36.87, 40.91);
        context.lineTo(36.87, 40.93);
        context.bezierCurveTo(50.64, 40.93, 60.64, 46.18, 71.15, 56.69);
        context.lineTo(71.16, 56.69);
        context.bezierCurveTo(71.16, 50.45, 71.24, 51.22, 70.86, 45.09);
        context.bezierCurveTo(60.44, 34.74, 50.48, 29.56, 36.81, 29.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1680 Drawing
        context.beginPath();
        context.moveTo(46.58, 3.05);
        context.bezierCurveTo(72.9, 13.86, 71.13, 34.52, 71.12, 56.76);
        context.lineTo(71.12, 56.75);
        context.bezierCurveTo(60.61, 46.25, 50.61, 40.99, 36.84, 40.99);
        context.lineTo(36.84, 40.97);
        context.bezierCurveTo(23.07, 40.97, 12.53, 46.22, 2.02, 56.73);
        context.lineTo(2.01, 56.73);
        context.bezierCurveTo(2, 34.66, 0.73, 15.53, 27.09, 2.69);
        context.bezierCurveTo(27.09, 2.69, 35.9, -1.34, 46.58, 3.05);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirt(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtPocket(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1567 Drawing
        context.beginPath();
        context.moveTo(43.43, 22.63);
        context.lineTo(43.19, 22.63);
        context.bezierCurveTo(40.54, 22.63, 38.38, 20.48, 38.38, 17.83);
        context.lineTo(38.38, 12.77);
        context.lineTo(48.24, 12.77);
        context.lineTo(48.24, 17.83);
        context.bezierCurveTo(48.24, 20.48, 46.09, 22.63, 43.43, 22.63);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawVNeck(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1554 Drawing
        context.beginPath();
        context.moveTo(38.84, 5.48);
        context.bezierCurveTo(43.38, 6.25, 57.34, 8.67, 57.9, 24.96);
        context.lineTo(29.53, 24.96);
        context.lineTo(1.15, 24.96);
        context.bezierCurveTo(1.71, 8.66, 15.68, 6.25, 20.21, 5.48);
        context.lineTo(29.54, 18.92);
        context.lineTo(38.84, 5.48);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1632 Drawing
        context.beginPath();
        context.moveTo(38.79, 5.57);
        context.lineTo(38.79, 5.45);
        context.bezierCurveTo(36.07, 4.09, 36.49, 2.92, 37.09, 1.85);
        context.bezierCurveTo(34.69, 2.61, 31.91, 2.93, 29.29, 2.94);
        context.bezierCurveTo(26.67, 2.94, 24.38, 2.61, 21.98, 1.86);
        context.bezierCurveTo(22.58, 2.93, 20.3, 5.63, 20.3, 5.63);
        context.lineTo(29.53, 19.26);
        context.lineTo(38.79, 5.57);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawButtons(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 503 Drawing
        oval(context, 28.2, 14.36, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 505 Drawing
        oval(context, 28.2, 19.61, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawCollar(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 42
        context.save();

        //// Clip Clip 42

        context.beginPath();
        context.moveTo(29.37, 11.73);
        context.bezierCurveTo(25.3, 11.73, 21.78, 9.39, 20.06, 6);
        context.lineTo(20.06, 6);
        context.bezierCurveTo(15.52, 6.77, 1.56, 9.18, 1, 25.48);
        context.lineTo(1, 25.48);
        context.lineTo(29.37, 25.48);
        context.lineTo(57.74, 25.48);
        context.bezierCurveTo(57.18, 9.18, 43.23, 6.77, 38.69, 6);
        context.lineTo(38.69, 6);
        context.bezierCurveTo(36.96, 9.39, 33.44, 11.73, 29.37, 11.73);
        context.closePath();
        context.clip();



        //// Bezier 1561 Drawing
        context.beginPath();
        context.moveTo(14.47, 1.66);
        context.bezierCurveTo(14.81, 2, 21.97, 20.91, 21.97, 20.91);
        context.lineTo(30.23, 10.84);
        context.bezierCurveTo(30.23, 10.84, 21.52, 5.68, 21.27, 1.04);
        context.lineTo(14.47, 1.66);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1563 Drawing
        context.beginPath();
        context.moveTo(44.25, 1.16);
        context.bezierCurveTo(43.91, 1.5, 36.75, 20.41, 36.75, 20.41);
        context.lineTo(28.49, 10.34);
        context.bezierCurveTo(28.49, 10.34, 37.2, 5.18, 37.45, 0.54);
        context.lineTo(44.25, 1.16);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawButtons2(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Rectangle 33 Drawing
        context.beginPath();
        context.rect(26.88, 11.21, 5.35, 13.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 503 Drawing
        oval(context, 28.2, 14.36, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 505 Drawing
        oval(context, 28.2, 19.61, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawVNeckCollar(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1554 Drawing
        context.beginPath();
        context.moveTo(38.84, 5.48);
        context.bezierCurveTo(43.38, 6.25, 57.34, 8.67, 57.9, 24.96);
        context.lineTo(29.53, 24.96);
        context.lineTo(1.15, 24.96);
        context.bezierCurveTo(1.71, 8.66, 15.68, 6.25, 20.21, 5.48);
        context.lineTo(29.54, 18.92);
        context.lineTo(38.84, 5.48);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 46
        context.save();

        //// Clip Clip 46

        context.beginPath();
        context.moveTo(29.49, 11.59);
        context.bezierCurveTo(25.42, 11.59, 21.9, 9.26, 20.18, 5.87);
        context.lineTo(20.18, 5.87);
        context.bezierCurveTo(15.64, 6.63, 1.68, 9.05, 1.12, 25.35);
        context.lineTo(1.12, 25.35);
        context.lineTo(29.49, 25.35);
        context.lineTo(57.86, 25.35);
        context.bezierCurveTo(57.3, 9.05, 43.35, 6.63, 38.81, 5.87);
        context.lineTo(38.81, 5.87);
        context.bezierCurveTo(37.08, 9.26, 33.56, 11.59, 29.49, 11.59);
        context.closePath();
        context.clip();



        //// Bezier 1598 Drawing
        context.beginPath();
        context.moveTo(15.22, -0.57);
        context.bezierCurveTo(15.48, -0.16, 18.26, 19.87, 18.26, 19.87);
        context.lineTo(28.55, 11.88);
        context.bezierCurveTo(28.55, 11.88, 21.2, 4.92, 21.99, 0.33);
        context.lineTo(15.22, -0.57);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1600 Drawing
        context.beginPath();
        context.moveTo(44.25, -0.28);
        context.bezierCurveTo(43.97, 0.11, 40.29, 19.99, 40.29, 19.99);
        context.lineTo(30.37, 11.55);
        context.bezierCurveTo(30.37, 11.55, 38.03, 4.93, 37.45, 0.31);
        context.lineTo(44.25, -0.28);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1632 Drawing
        context.beginPath();
        context.moveTo(38.79, 5.57);
        context.lineTo(38.79, 5.45);
        context.bezierCurveTo(36.07, 4.09, 36.49, 2.92, 37.09, 1.85);
        context.bezierCurveTo(34.69, 2.61, 31.91, 2.93, 29.29, 2.94);
        context.bezierCurveTo(26.67, 2.94, 24.38, 2.61, 21.98, 1.86);
        context.bezierCurveTo(22.58, 2.93, 20.3, 5.63, 20.3, 5.63);
        context.lineTo(29.53, 19.26);
        context.lineTo(38.79, 5.57);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawWideNeck(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1604 Drawing
        context.beginPath();
        context.moveTo(38.81, 5.55);
        context.bezierCurveTo(37.08, 8.94, 44.11, 18.32, 29.37, 18.32);
        context.bezierCurveTo(14.63, 18.32, 21.91, 8.94, 20.18, 5.55);
        context.bezierCurveTo(15.65, 6.32, 1.68, 8.73, 1.12, 25.03);
        context.lineTo(29.49, 25.03);
        context.lineTo(57.87, 25.03);
        context.bezierCurveTo(57.3, 8.73, 43.35, 6.32, 38.81, 5.55);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1640 Drawing
        context.beginPath();
        context.moveTo(29.37, 18.38);
        context.bezierCurveTo(44.07, 18.38, 40.8, 10, 42.54, 6.66);
        context.lineTo(38.76, 5.57);
        context.bezierCurveTo(36.04, 4.22, 36.46, 3.05, 37.06, 1.98);
        context.bezierCurveTo(34.66, 2.73, 31.88, 3.06, 29.26, 3.07);
        context.bezierCurveTo(26.64, 3.06, 24.35, 2.74, 21.95, 1.99);
        context.bezierCurveTo(22.55, 3.06, 22.96, 4.22, 20.26, 5.57);
        context.lineTo(16.96, 6.52);
        context.bezierCurveTo(18.71, 9.83, 14.69, 18.38, 29.37, 18.38);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtHex(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Polygon 12 Drawing
        context.save();
        context.translate(42.75, 17.21);
        context.rotate(-30 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -5.35);
        context.lineTo(4.63, -2.68);
        context.lineTo(4.63, 2.67);
        context.lineTo(0, 5.35);
        context.lineTo(-4.63, 2.68);
        context.lineTo(-4.63, -2.67);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();
        
        context.restore();

    }

    function drawPlainShirtCircle(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 519 Drawing
        oval(context, 37.7, 11.36, 10.3, 10.3);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtStripe(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 47
        context.save();

        //// Clip Clip 47

        context.beginPath();
        context.moveTo(29.37, 11.01);
        context.bezierCurveTo(25.3, 11.01, 21.78, 8.68, 20.06, 5.28);
        context.lineTo(20.06, 5.28);
        context.bezierCurveTo(15.52, 6.05, 1.56, 8.46, 1, 24.76);
        context.lineTo(1, 24.76);
        context.lineTo(29.37, 24.76);
        context.lineTo(57.74, 24.76);
        context.bezierCurveTo(57.18, 8.47, 43.23, 6.05, 38.69, 5.28);
        context.lineTo(38.69, 5.28);
        context.bezierCurveTo(36.96, 8.68, 33.44, 11.01, 29.37, 11.01);
        context.closePath();
        context.clip();



        //// Rectangle 34 Drawing
        context.beginPath();
        context.rect(-1.28, 20.11, 62.75, 9.2);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtStripe2(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 44
        context.save();

        //// Clip Clip 44

        context.beginPath();
        context.moveTo(29.37, 11.73);
        context.bezierCurveTo(25.3, 11.73, 21.78, 9.39, 20.06, 6);
        context.lineTo(20.06, 6);
        context.bezierCurveTo(15.52, 6.77, 1.56, 9.18, 1, 25.48);
        context.lineTo(1, 25.48);
        context.lineTo(29.37, 25.48);
        context.lineTo(57.74, 25.48);
        context.bezierCurveTo(57.18, 9.18, 43.23, 6.77, 38.69, 6);
        context.lineTo(38.69, 6);
        context.bezierCurveTo(36.96, 9.39, 33.44, 11.73, 29.37, 11.73);
        context.closePath();
        context.clip();



        //// Bezier 1584 Drawing
        context.beginPath();
        context.moveTo(12.39, 3.29);
        context.bezierCurveTo(16.09, 10.29, 20.88, 15.52, 29.34, 15.52);
        context.bezierCurveTo(37.85, 15.52, 42.61, 11.2, 46.29, 4.14);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtZip(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1580 Drawing
        context.beginPath();
        context.moveTo(29.38, 10.88);
        context.lineTo(29.38, 25.34);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawCollarButtons(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 503 Drawing
        oval(context, 28.2, 14.36, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 505 Drawing
        oval(context, 28.2, 19.61, 2.5, 2.5);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 42
        context.save();

        //// Clip Clip 42

        context.beginPath();
        context.moveTo(29.37, 11.73);
        context.bezierCurveTo(25.3, 11.73, 21.78, 9.39, 20.06, 6);
        context.lineTo(20.06, 6);
        context.bezierCurveTo(15.52, 6.77, 1.56, 9.18, 1, 25.48);
        context.lineTo(1, 25.48);
        context.lineTo(29.37, 25.48);
        context.lineTo(57.74, 25.48);
        context.bezierCurveTo(57.18, 9.18, 43.23, 6.77, 38.69, 6);
        context.lineTo(38.69, 6);
        context.bezierCurveTo(36.96, 9.39, 33.44, 11.73, 29.37, 11.73);
        context.closePath();
        context.clip();



        //// Bezier 1561 Drawing
        context.beginPath();
        context.moveTo(14.47, 1.66);
        context.bezierCurveTo(14.81, 2, 21.97, 20.91, 21.97, 20.91);
        context.lineTo(30.23, 10.84);
        context.bezierCurveTo(30.23, 10.84, 21.52, 5.68, 21.27, 1.04);
        context.lineTo(14.47, 1.66);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1563 Drawing
        context.beginPath();
        context.moveTo(44.25, 1.16);
        context.bezierCurveTo(43.91, 1.5, 36.75, 20.41, 36.75, 20.41);
        context.lineTo(28.49, 10.34);
        context.bezierCurveTo(28.49, 10.34, 37.2, 5.18, 37.45, 0.54);
        context.lineTo(44.25, 1.16);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtZigZag(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1580 Drawing
        context.beginPath();
        context.moveTo(29.38, 10.88);
        context.lineTo(29.38, 25.34);
        context.fillStyle = AvatarStyleKit.col2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1579 Drawing
        context.beginPath();
        context.moveTo(30.18, 25.08);
        context.lineTo(32.16, 22.34);
        context.lineTo(30.18, 19.6);
        context.lineTo(32.16, 16.87);
        context.lineTo(30.18, 14.13);
        context.lineTo(32.16, 11.4);
        context.lineTo(29.08, 11.4);
        context.lineTo(27.1, 14.13);
        context.lineTo(29.08, 16.87);
        context.lineTo(27.1, 19.6);
        context.lineTo(29.08, 22.34);
        context.lineTo(27.1, 25.08);
        context.lineTo(30.18, 25.08);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPlainShirtWave(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1581 Drawing
        context.beginPath();
        context.moveTo(31.42, 10.88);
        context.bezierCurveTo(31.42, 12.27, 27.4, 12.27, 27.4, 13.66);
        context.bezierCurveTo(27.4, 15.04, 31.42, 15.04, 31.42, 16.43);
        context.bezierCurveTo(31.42, 17.82, 27.4, 17.82, 27.4, 19.21);
        context.bezierCurveTo(27.4, 20.6, 31.42, 20.6, 31.42, 21.99);
        context.bezierCurveTo(31.42, 23.37, 27.4, 23.37, 27.4, 24.76);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.98;
        context.stroke();
        
        context.restore();

    }

    function drawPullStrings(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 33), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 33);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group
        //// Oval 515 Drawing
        oval(context, 25.1, 12.56, 2.9, 2.9);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 517 Drawing
        oval(context, 30.5, 12.56, 2.9, 2.9);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1576 Drawing
        context.beginPath();
        context.moveTo(26.56, 13.89);
        context.lineTo(26.56, 31.92);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();


        //// Bezier 1577 Drawing
        context.beginPath();
        context.moveTo(31.94, 13.89);
        context.lineTo(31.94, 31.92);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();
        
        context.restore();

    }

    function drawTassles(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 35), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 35);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(38.69, 5.52);
        context.bezierCurveTo(36.96, 8.92, 33.44, 11.25, 29.37, 11.25);
        context.bezierCurveTo(25.3, 11.25, 21.79, 8.92, 20.06, 5.52);
        context.bezierCurveTo(15.52, 6.29, 1.56, 8.7, 1, 25);
        context.lineTo(29.37, 25);
        context.lineTo(57.74, 25);
        context.bezierCurveTo(57.18, 8.71, 43.23, 6.29, 38.69, 5.52);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(29.31, 11.33);
        context.bezierCurveTo(33.34, 11.33, 36.83, 9.04, 38.57, 5.7);
        context.lineTo(38.57, 5.57);
        context.bezierCurveTo(35.86, 4.21, 36.28, 3.05, 36.88, 1.98);
        context.bezierCurveTo(34.48, 2.73, 31.7, 3.06, 29.07, 3.06);
        context.bezierCurveTo(26.45, 3.06, 24.17, 2.73, 21.77, 1.98);
        context.bezierCurveTo(22.37, 3.06, 22.78, 4.21, 20.07, 5.57);
        context.lineTo(20.08, 5.75);
        context.bezierCurveTo(21.84, 9.06, 25.3, 11.33, 29.31, 11.33);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group
        //// Bezier 1574 Drawing
        context.beginPath();
        context.moveTo(19.95, 5.81);
        context.bezierCurveTo(19.95, 5.81, 26.46, 13.13, 16.29, 30.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1575 Drawing
        context.beginPath();
        context.moveTo(38.79, 5.81);
        context.bezierCurveTo(38.79, 5.81, 31.87, 13.76, 42.04, 30.92);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Polygon 8 Drawing
        context.save();
        context.translate(15.25, 31.51);
        context.rotate(-45 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -2.65);
        context.lineTo(2.29, -1.33);
        context.lineTo(2.29, 1.32);
        context.lineTo(0, 2.65);
        context.lineTo(-2.29, 1.33);
        context.lineTo(-2.29, -1.32);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();


        //// Polygon 10 Drawing
        context.save();
        context.translate(43, 31.51);
        context.rotate(-15 * Math.PI / 180);

        context.beginPath();
        context.moveTo(0, -2.65);
        context.lineTo(2.29, -1.33);
        context.lineTo(2.29, 1.32);
        context.lineTo(0, 2.65);
        context.lineTo(-2.29, 1.33);
        context.lineTo(-2.29, -1.32);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();
        
        context.restore();

    }

    function drawPatternBackground9(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Bezier 683 Drawing
        context.beginPath();
        context.moveTo(111.55, 1.28);
        context.lineTo(42.4, 1.28);
        context.bezierCurveTo(40.41, 1.28, 38.57, 2.35, 37.57, 4.07);
        context.lineTo(3, 63.96);
        context.bezierCurveTo(2, 65.68, 2, 67.8, 3, 69.53);
        context.lineTo(37.57, 129.42);
        context.bezierCurveTo(38.57, 131.14, 40.41, 132.2, 42.4, 132.2);
        context.lineTo(111.55, 132.2);
        context.bezierCurveTo(113.54, 132.2, 115.38, 131.14, 116.37, 129.42);
        context.lineTo(150.95, 69.53);
        context.bezierCurveTo(151.94, 67.8, 151.94, 65.68, 150.95, 63.96);
        context.lineTo(116.37, 4.07);
        context.bezierCurveTo(115.38, 2.35, 113.54, 1.28, 111.55, 1.28);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Oval 89 Drawing
        oval(context, 17.4, 7.19, 119.1, 119.1);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Oval 90 Drawing
        oval(context, 26.25, 16.04, 101.4, 101.4);
        context.fillStyle = fillColor12;
        context.fill();


        //// Oval 91 Drawing
        oval(context, 34.3, 24.09, 85.3, 85.3);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Oval 92 Drawing
        oval(context, 42.05, 31.84, 69.8, 69.8);
        context.fillStyle = fillColor12;
        context.fill();


        //// Oval 125 Drawing
        oval(context, 54.15, 43.94, 45.6, 45.6);
        context.fillStyle = fillColor12;
        context.fill();


        //// Oval 126 Drawing
        oval(context, 64.15, 53.94, 25.6, 25.6);
        context.fillStyle = fillColor12;
        context.fill();


        //// Oval 127 Drawing
        oval(context, 50.85, 40.64, 52.2, 52.2);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Oval 253 Drawing
        oval(context, 59.1, 48.89, 35.7, 35.7);
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawPatternBackground10(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Group 23
        context.save();

        //// Clip Clip 21

        context.beginPath();
        context.moveTo(42.24, 0.4);
        context.bezierCurveTo(40.25, 0.4, 38.41, 1.46, 37.41, 3.19);
        context.lineTo(37.41, 3.19);
        context.lineTo(2.84, 63.08);
        context.bezierCurveTo(1.84, 64.8, 1.84, 66.92, 2.84, 68.64);
        context.lineTo(2.84, 68.64);
        context.lineTo(37.41, 128.53);
        context.bezierCurveTo(38.41, 130.26, 40.25, 131.32, 42.24, 131.32);
        context.lineTo(42.24, 131.32);
        context.lineTo(111.39, 131.32);
        context.bezierCurveTo(113.38, 131.32, 115.22, 130.26, 116.21, 128.53);
        context.lineTo(116.21, 128.53);
        context.lineTo(150.79, 68.64);
        context.bezierCurveTo(151.78, 66.92, 151.78, 64.8, 150.79, 63.08);
        context.lineTo(150.79, 63.08);
        context.lineTo(116.21, 3.19);
        context.bezierCurveTo(115.22, 1.46, 113.38, 0.4, 111.39, 0.4);
        context.lineTo(111.39, 0.4);
        context.lineTo(42.24, 0.4);
        context.closePath();
        context.clip();



        //// Bezier 566 Drawing
        context.beginPath();
        context.moveTo(19.45, 156.02);
        context.lineTo(1.86, 156.02);
        context.lineTo(1.86, -23.32);
        context.lineTo(19.45, -23.32);
        context.lineTo(19.45, 156.02);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 567 Drawing
        context.beginPath();
        context.moveTo(36.06, 156.02);
        context.lineTo(19.45, 156.02);
        context.lineTo(19.45, -23.32);
        context.lineTo(36.06, -23.32);
        context.lineTo(36.06, 156.02);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 568 Drawing
        context.beginPath();
        context.moveTo(52.67, 156.02);
        context.lineTo(36.06, 156.02);
        context.lineTo(36.06, -23.32);
        context.lineTo(52.67, -23.32);
        context.lineTo(52.67, 156.02);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 569 Drawing
        context.beginPath();
        context.moveTo(69.28, 156.02);
        context.lineTo(52.67, 156.02);
        context.lineTo(52.67, -23.32);
        context.lineTo(69.28, -23.32);
        context.lineTo(69.28, 156.02);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 570 Drawing
        context.beginPath();
        context.moveTo(85.14, 156.02);
        context.lineTo(68.53, 156.02);
        context.lineTo(68.53, -23.32);
        context.lineTo(85.14, -23.32);
        context.lineTo(85.14, 156.02);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 571 Drawing
        context.beginPath();
        context.moveTo(101.71, 156.02);
        context.lineTo(85.1, 156.02);
        context.lineTo(85.1, -23.32);
        context.lineTo(101.71, -23.32);
        context.lineTo(101.71, 156.02);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 572 Drawing
        context.beginPath();
        context.moveTo(118.32, 156.02);
        context.lineTo(101.71, 156.02);
        context.lineTo(101.71, -23.32);
        context.lineTo(118.32, -23.32);
        context.lineTo(118.32, 156.02);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 573 Drawing
        context.beginPath();
        context.moveTo(134.93, 156.02);
        context.lineTo(118.32, 156.02);
        context.lineTo(118.32, -23.32);
        context.lineTo(134.93, -23.32);
        context.lineTo(134.93, 156.02);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 574 Drawing
        context.beginPath();
        context.moveTo(151.49, 156.02);
        context.lineTo(134.18, 156.02);
        context.lineTo(134.18, -23.32);
        context.lineTo(151.49, -23.32);
        context.lineTo(151.49, 156.02);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        context.restore();



        //// Bezier 772 Drawing
        context.beginPath();
        context.moveTo(111.3, 1);
        context.lineTo(42.15, 1);
        context.bezierCurveTo(40.16, 1, 38.32, 2.06, 37.32, 3.78);
        context.lineTo(2.75, 63.67);
        context.bezierCurveTo(1.75, 65.4, 1.75, 67.52, 2.75, 69.24);
        context.lineTo(37.32, 129.13);
        context.bezierCurveTo(38.32, 130.85, 40.16, 131.91, 42.15, 131.91);
        context.lineTo(111.3, 131.91);
        context.bezierCurveTo(113.29, 131.91, 115.13, 130.85, 116.12, 129.13);
        context.lineTo(150.7, 69.24);
        context.bezierCurveTo(151.69, 67.52, 151.69, 65.4, 150.7, 63.67);
        context.lineTo(116.12, 3.78);
        context.bezierCurveTo(115.13, 2.06, 113.29, 1, 111.3, 1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawEgyptBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor4 = 'rgba(242, 229, 201, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';

        //// Group 7
        context.save();

        //// Clip Clip 5

        context.beginPath();
        context.moveTo(41.71, 1.01);
        context.bezierCurveTo(39.72, 1.01, 37.88, 2.07, 36.89, 3.8);
        context.lineTo(36.89, 3.8);
        context.lineTo(2.31, 63.68);
        context.bezierCurveTo(1.31, 65.41, 1.31, 67.53, 2.31, 69.25);
        context.lineTo(2.31, 69.25);
        context.lineTo(36.89, 129.14);
        context.bezierCurveTo(37.88, 130.86, 39.72, 131.93, 41.71, 131.93);
        context.lineTo(41.71, 131.93);
        context.lineTo(110.86, 131.93);
        context.bezierCurveTo(112.85, 131.93, 114.69, 130.86, 115.68, 129.14);
        context.lineTo(115.68, 129.14);
        context.lineTo(150.26, 69.25);
        context.bezierCurveTo(151.26, 67.53, 151.26, 65.41, 150.26, 63.68);
        context.lineTo(150.26, 63.68);
        context.lineTo(115.68, 3.8);
        context.bezierCurveTo(114.69, 2.07, 112.85, 1.01, 110.86, 1.01);
        context.lineTo(110.86, 1.01);
        context.lineTo(41.71, 1.01);
        context.closePath();
        context.clip();



        //// Bezier 40 Drawing
        context.beginPath();
        context.moveTo(34.64, -5.14);
        context.lineTo(-10, 65.02);
        context.lineTo(30.9, 117.23);
        context.lineTo(150.95, 101.27);
        context.lineTo(160.92, 63.69);
        context.lineTo(114.7, -5.81);
        context.lineTo(34.64, -5.14);
        context.closePath();
        context.fillStyle = fillColor4;
        context.fill();


        //// Bezier 41 Drawing
        context.beginPath();
        context.moveTo(34.64, -5.14);
        context.lineTo(-10, 65.02);
        context.lineTo(30.9, 117.23);
        context.lineTo(150.95, 101.27);
        context.lineTo(160.92, 63.69);
        context.lineTo(114.7, -5.81);
        context.lineTo(34.64, -5.14);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 42 Drawing
        context.beginPath();
        context.moveTo(29.51, 151);
        context.lineTo(0.71, 72.28);
        context.bezierCurveTo(0.71, 72.28, 11.46, 65.89, 23.96, 69.94);
        context.bezierCurveTo(36.46, 73.99, 39.96, 76.98, 68.71, 84.66);
        context.bezierCurveTo(97.46, 92.34, 127.21, 98.31, 134.96, 93.62);
        context.bezierCurveTo(142.71, 88.92, 164.96, 97.03, 164.96, 97.03);
        context.lineTo(149.19, 125.2);
        context.lineTo(29.51, 151);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 43 Drawing
        context.beginPath();
        context.moveTo(29.51, 151);
        context.lineTo(0.71, 72.28);
        context.bezierCurveTo(0.71, 72.28, 11.46, 65.89, 23.96, 69.94);
        context.bezierCurveTo(36.46, 73.99, 39.96, 76.98, 68.71, 84.66);
        context.bezierCurveTo(97.46, 92.34, 127.21, 98.31, 134.96, 93.62);
        context.bezierCurveTo(142.71, 88.92, 164.96, 97.03, 164.96, 97.03);
        context.lineTo(149.19, 125.2);
        context.lineTo(29.51, 151);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 44 Drawing
        context.beginPath();
        context.moveTo(61.99, 97.36);
        context.lineTo(102.26, 31.61);
        context.lineTo(139.14, 96.91);
        context.lineTo(61.99, 97.36);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 45 Drawing
        context.beginPath();
        context.moveTo(61.99, 97.36);
        context.lineTo(102.26, 31.61);
        context.lineTo(139.14, 96.91);
        context.lineTo(61.99, 97.36);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 46 Drawing
        context.beginPath();
        context.moveTo(97.08, 106.01);
        context.lineTo(-4.02, 106.01);
        context.lineTo(46.43, 14.61);
        context.lineTo(97.08, 106.01);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 47 Drawing
        context.beginPath();
        context.moveTo(97.08, 106.01);
        context.lineTo(-4.02, 106.01);
        context.lineTo(46.43, 14.61);
        context.lineTo(97.08, 106.01);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 48 Drawing
        context.beginPath();
        context.moveTo(39.88, 25.95);
        context.lineTo(52.47, 25.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 49 Drawing
        context.beginPath();
        context.moveTo(58.33, 35.95);
        context.lineTo(34.56, 35.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 50 Drawing
        context.beginPath();
        context.moveTo(29.24, 45.95);
        context.lineTo(63.53, 45.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 51 Drawing
        context.beginPath();
        context.moveTo(23.61, 55.95);
        context.lineTo(34.89, 55.95);
        context.lineTo(69.35, 55.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 52 Drawing
        context.beginPath();
        context.moveTo(18.14, 65.95);
        context.lineTo(74.71, 65.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 53 Drawing
        context.beginPath();
        context.moveTo(12.53, 75.95);
        context.lineTo(80.43, 75.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 54 Drawing
        context.beginPath();
        context.moveTo(7.25, 85.95);
        context.lineTo(86.35, 85.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 55 Drawing
        context.beginPath();
        context.moveTo(1.55, 95.95);
        context.lineTo(91.59, 95.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 56 Drawing
        context.beginPath();
        context.moveTo(46.31, 26);
        context.lineTo(46.31, 35.98);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 57 Drawing
        context.beginPath();
        context.moveTo(46.39, 46.18);
        context.lineTo(46.39, 56.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 58 Drawing
        context.beginPath();
        context.moveTo(34.53, 66.33);
        context.lineTo(34.53, 55.7);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 59 Drawing
        context.beginPath();
        context.moveTo(58.22, 65.66);
        context.lineTo(58.22, 55.69);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 60 Drawing
        context.beginPath();
        context.moveTo(46.39, 66.18);
        context.lineTo(46.39, 76.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 61 Drawing
        context.beginPath();
        context.moveTo(34.53, 85.99);
        context.lineTo(34.53, 75.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 62 Drawing
        context.beginPath();
        context.moveTo(58.22, 85.91);
        context.lineTo(58.22, 75.93);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 63 Drawing
        context.beginPath();
        context.moveTo(34.53, 46.17);
        context.lineTo(34.53, 36.2);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 64 Drawing
        context.beginPath();
        context.moveTo(58.22, 45.91);
        context.lineTo(58.22, 35.93);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 65 Drawing
        context.beginPath();
        context.moveTo(69.39, 66.18);
        context.lineTo(69.39, 76.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 66 Drawing
        context.beginPath();
        context.moveTo(23.39, 66.18);
        context.lineTo(23.39, 76.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 67 Drawing
        context.beginPath();
        context.moveTo(69.39, 86.18);
        context.lineTo(69.39, 96.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 68 Drawing
        context.beginPath();
        context.moveTo(23.39, 86.18);
        context.lineTo(23.39, 96.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 69 Drawing
        context.beginPath();
        context.moveTo(46.39, 86.18);
        context.lineTo(46.39, 96.16);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 70 Drawing
        context.beginPath();
        context.moveTo(34.53, 105.99);
        context.lineTo(34.53, 95.95);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 71 Drawing
        context.beginPath();
        context.moveTo(58.22, 105.91);
        context.lineTo(58.22, 95.93);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 72 Drawing
        context.beginPath();
        context.moveTo(82.05, 105.91);
        context.lineTo(82.05, 95.55);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 73 Drawing
        context.beginPath();
        context.moveTo(11.03, 106.17);
        context.lineTo(11.03, 95.51);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 74 Drawing
        context.beginPath();
        context.moveTo(96.22, 41.48);
        context.lineTo(107.97, 41.48);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 75 Drawing
        context.beginPath();
        context.moveTo(112.31, 49.41);
        context.lineTo(91.32, 49.41);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 76 Drawing
        context.beginPath();
        context.moveTo(86.37, 57.34);
        context.lineTo(116.82, 57.34);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 77 Drawing
        context.beginPath();
        context.moveTo(81.4, 65.27);
        context.lineTo(93.1, 65.27);
        context.lineTo(121.68, 65.27);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 78 Drawing
        context.beginPath();
        context.moveTo(79.16, 73.21);
        context.lineTo(125.88, 73.21);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 79 Drawing
        context.beginPath();
        context.moveTo(83.36, 81.14);
        context.lineTo(130.54, 81.14);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 80 Drawing
        context.beginPath();
        context.moveTo(87.68, 89.07);
        context.lineTo(134.99, 89.07);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 81 Drawing
        context.beginPath();
        context.moveTo(102.16, 41.52);
        context.lineTo(102.16, 49.43);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 82 Drawing
        context.beginPath();
        context.moveTo(102.22, 57.52);
        context.lineTo(102.22, 65.44);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 83 Drawing
        context.beginPath();
        context.moveTo(92.82, 73.51);
        context.lineTo(92.82, 65.08);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 84 Drawing
        context.beginPath();
        context.moveTo(111.61, 72.98);
        context.lineTo(111.61, 65.06);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 85 Drawing
        context.beginPath();
        context.moveTo(102.22, 73.39);
        context.lineTo(102.22, 81.3);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 86 Drawing
        context.beginPath();
        context.moveTo(92.82, 89.11);
        context.lineTo(92.82, 81.14);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 87 Drawing
        context.beginPath();
        context.moveTo(111.61, 89.04);
        context.lineTo(111.61, 81.13);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 88 Drawing
        context.beginPath();
        context.moveTo(92.82, 57.52);
        context.lineTo(92.82, 49.41);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 89 Drawing
        context.beginPath();
        context.moveTo(111.61, 57.31);
        context.lineTo(111.61, 49.4);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 90 Drawing
        context.beginPath();
        context.moveTo(120.47, 73.39);
        context.lineTo(120.47, 81.3);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 91 Drawing
        context.beginPath();
        context.moveTo(83.98, 73.39);
        context.lineTo(83.98, 81.3);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 92 Drawing
        context.beginPath();
        context.moveTo(120.47, 89.25);
        context.lineTo(120.47, 97.17);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 93 Drawing
        context.beginPath();
        context.moveTo(102.22, 89.25);
        context.lineTo(102.22, 97.17);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 94 Drawing
        context.beginPath();
        context.moveTo(130.51, 89.04);
        context.lineTo(130.51, 81.4);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 63 Drawing
        oval(context, 96.1, -18.71, 37.5, 37.5);
        context.fillStyle = fillColor7;
        context.fill();


        //// Oval 64 Drawing
        oval(context, 96.1, -18.71, 37.5, 37.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawCastleBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';
        var fillColor21 = 'rgba(227, 211, 197, 1)';
        var fillColor22 = 'rgba(199, 168, 140, 1)';
        var fillColor23 = 'rgba(211, 193, 179, 1)';
        var fillColor29 = 'rgba(51, 185, 245, 1)';

        //// Group 19
        context.save();

        //// Clip Clip 17

        context.beginPath();
        context.moveTo(41.49, 1.01);
        context.bezierCurveTo(39.5, 1.01, 37.67, 2.07, 36.67, 3.8);
        context.lineTo(36.67, 3.8);
        context.lineTo(2.09, 63.68);
        context.bezierCurveTo(1.1, 65.41, 1.1, 67.53, 2.09, 69.25);
        context.lineTo(2.09, 69.25);
        context.lineTo(36.67, 129.14);
        context.bezierCurveTo(37.67, 130.86, 39.5, 131.93, 41.49, 131.93);
        context.lineTo(41.49, 131.93);
        context.lineTo(110.65, 131.93);
        context.bezierCurveTo(112.64, 131.93, 114.47, 130.86, 115.47, 129.14);
        context.lineTo(115.47, 129.14);
        context.lineTo(150.05, 69.25);
        context.bezierCurveTo(151.04, 67.53, 151.04, 65.41, 150.05, 63.68);
        context.lineTo(150.05, 63.68);
        context.lineTo(115.47, 3.8);
        context.bezierCurveTo(114.47, 2.07, 112.64, 1.01, 110.65, 1.01);
        context.lineTo(110.65, 1.01);
        context.lineTo(41.49, 1.01);
        context.closePath();
        context.clip();



        //// Bezier 443 Drawing
        context.beginPath();
        context.moveTo(29.8, -3.86);
        context.lineTo(-4.78, 63.98);
        context.lineTo(27.14, 120.84);
        context.lineTo(110.94, 122.51);
        context.lineTo(158.83, 71.96);
        context.lineTo(151.18, 47.69);
        context.lineTo(111.28, -7.18);
        context.lineTo(29.8, -3.86);
        context.closePath();
        context.fillStyle = fillColor29;
        context.fill();


        //// Bezier 444 Drawing
        context.beginPath();
        context.moveTo(29.8, -3.86);
        context.lineTo(-4.78, 63.98);
        context.lineTo(27.14, 120.84);
        context.lineTo(110.94, 122.51);
        context.lineTo(158.83, 71.96);
        context.lineTo(151.18, 47.69);
        context.lineTo(111.28, -7.18);
        context.lineTo(29.8, -3.86);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 445 Drawing
        context.beginPath();
        context.moveTo(28.47, 114.64);
        context.lineTo(127.79, 114.64);
        context.lineTo(116.04, 137.69);
        context.lineTo(29.58, 136.36);
        context.lineTo(23.37, 114.86);
        context.lineTo(28.47, 114.64);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 446 Drawing
        context.beginPath();
        context.moveTo(28.47, 114.64);
        context.lineTo(127.79, 114.64);
        context.lineTo(116.04, 137.69);
        context.lineTo(29.58, 136.36);
        context.lineTo(23.37, 114.86);
        context.lineTo(28.47, 114.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 447 Drawing
        context.beginPath();
        context.moveTo(80.15, 116.82);
        context.lineTo(63.63, 116.82);
        context.lineTo(62.32, 94.08);
        context.lineTo(78.36, 92.56);
        context.lineTo(80.15, 116.82);
        context.closePath();
        context.fillStyle = fillColor21;
        context.fill();


        //// Bezier 448 Drawing
        context.beginPath();
        context.moveTo(80.15, 116.82);
        context.lineTo(63.63, 116.82);
        context.lineTo(62.32, 94.08);
        context.lineTo(78.36, 92.56);
        context.lineTo(80.15, 116.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 449 Drawing
        context.beginPath();
        context.moveTo(58.97, 87.74);
        context.lineTo(59.57, 93.72);
        context.lineTo(62.32, 94.08);
        context.lineTo(78.36, 92.56);
        context.lineTo(77.8, 85.03);
        context.lineTo(76.2, 85.11);
        context.lineTo(76.32, 87.86);
        context.lineTo(74.17, 87.98);
        context.lineTo(73.93, 85.47);
        context.lineTo(70.46, 85.94);
        context.lineTo(70.34, 88.34);
        context.lineTo(68.06, 88.34);
        context.lineTo(67.82, 86.3);
        context.lineTo(63.87, 86.78);
        context.lineTo(63.99, 88.94);
        context.lineTo(62.2, 89.06);
        context.lineTo(61.72, 87.02);
        context.lineTo(58.97, 87.74);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 450 Drawing
        context.beginPath();
        context.moveTo(58.97, 87.74);
        context.lineTo(59.57, 93.72);
        context.lineTo(62.32, 94.08);
        context.lineTo(78.36, 92.56);
        context.lineTo(77.8, 85.03);
        context.lineTo(76.2, 85.11);
        context.lineTo(76.32, 87.86);
        context.lineTo(74.17, 87.98);
        context.lineTo(73.93, 85.47);
        context.lineTo(70.46, 85.94);
        context.lineTo(70.34, 88.34);
        context.lineTo(68.06, 88.34);
        context.lineTo(67.82, 86.3);
        context.lineTo(63.87, 86.78);
        context.lineTo(63.99, 88.94);
        context.lineTo(62.2, 89.06);
        context.lineTo(61.72, 87.02);
        context.lineTo(58.97, 87.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 451 Drawing
        context.beginPath();
        context.moveTo(86.02, 47.65);
        context.lineTo(94.51, 62.61);
        context.lineTo(94.63, 67.75);
        context.lineTo(77.04, 68.59);
        context.lineTo(76.92, 63.68);
        context.lineTo(86.02, 47.65);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Bezier 452 Drawing
        context.beginPath();
        context.moveTo(86.02, 47.65);
        context.lineTo(94.51, 62.61);
        context.lineTo(94.63, 67.75);
        context.lineTo(77.04, 68.59);
        context.lineTo(76.92, 63.68);
        context.lineTo(86.02, 47.65);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 453 Drawing
        context.beginPath();
        context.moveTo(96.43, 75.41);
        context.lineTo(77.16, 75.53);
        context.lineTo(77.25, 77.99);
        context.lineTo(75.24, 75.65);
        context.lineTo(73.09, 65.72);
        context.lineTo(76.92, 63.68);
        context.lineTo(78.21, 66.32);
        context.lineTo(81.95, 65.24);
        context.lineTo(81.59, 62.25);
        context.lineTo(88.77, 62.25);
        context.lineTo(88.77, 64.88);
        context.lineTo(93.79, 65.6);
        context.lineTo(94.51, 62.61);
        context.lineTo(98.82, 64.16);
        context.lineTo(96.43, 75.41);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 454 Drawing
        context.beginPath();
        context.moveTo(96.43, 75.41);
        context.lineTo(77.16, 75.53);
        context.lineTo(77.25, 77.99);
        context.lineTo(75.24, 75.65);
        context.lineTo(73.09, 65.72);
        context.lineTo(76.92, 63.68);
        context.lineTo(78.21, 66.32);
        context.lineTo(81.95, 65.24);
        context.lineTo(81.59, 62.25);
        context.lineTo(88.77, 62.25);
        context.lineTo(88.77, 64.88);
        context.lineTo(93.79, 65.6);
        context.lineTo(94.51, 62.61);
        context.lineTo(98.82, 64.16);
        context.lineTo(96.43, 75.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 455 Drawing
        context.beginPath();
        context.moveTo(77.16, 75.53);
        context.lineTo(80.15, 116.82);
        context.bezierCurveTo(80.15, 116.82, 87.36, 122.45, 97.86, 115.74);
        context.lineTo(96.43, 75.41);
        context.bezierCurveTo(96.43, 75.41, 86.14, 70.15, 77.16, 75.53);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 456 Drawing
        context.beginPath();
        context.moveTo(77.16, 75.53);
        context.lineTo(80.15, 116.82);
        context.bezierCurveTo(80.15, 116.82, 87.36, 122.45, 97.86, 115.74);
        context.lineTo(96.43, 75.41);
        context.bezierCurveTo(96.43, 75.41, 86.14, 70.15, 77.16, 75.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 457 Drawing
        context.beginPath();
        context.moveTo(89.06, 87.26);
        context.lineTo(89.06, 80.99);
        context.bezierCurveTo(89.06, 79.31, 87.69, 77.94, 86.01, 77.94);
        context.lineTo(85.17, 77.94);
        context.bezierCurveTo(83.48, 77.94, 82.12, 79.31, 82.12, 80.99);
        context.lineTo(82.12, 87.26);
        context.lineTo(89.06, 87.26);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 458 Drawing
        context.beginPath();
        context.moveTo(89.06, 87.26);
        context.lineTo(89.06, 80.99);
        context.bezierCurveTo(89.06, 79.31, 87.69, 77.94, 86.01, 77.94);
        context.lineTo(85.17, 77.94);
        context.bezierCurveTo(83.48, 77.94, 82.12, 79.31, 82.12, 80.99);
        context.lineTo(82.12, 87.26);
        context.lineTo(89.06, 87.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 459 Drawing
        context.beginPath();
        context.moveTo(147.09, 116.82);
        context.lineTo(163.6, 116.82);
        context.lineTo(164.92, 94.08);
        context.lineTo(148.88, 92.56);
        context.lineTo(147.09, 116.82);
        context.closePath();
        context.fillStyle = fillColor21;
        context.fill();


        //// Bezier 460 Drawing
        context.beginPath();
        context.moveTo(147.09, 116.82);
        context.lineTo(163.6, 116.82);
        context.lineTo(164.92, 94.08);
        context.lineTo(148.88, 92.56);
        context.lineTo(147.09, 116.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 461 Drawing
        context.beginPath();
        context.moveTo(168.27, 87.74);
        context.lineTo(167.67, 93.72);
        context.lineTo(164.92, 94.08);
        context.lineTo(148.88, 92.56);
        context.lineTo(149.44, 85.03);
        context.lineTo(151.04, 85.11);
        context.lineTo(150.92, 87.86);
        context.lineTo(153.07, 87.98);
        context.lineTo(153.31, 85.47);
        context.lineTo(156.78, 85.94);
        context.lineTo(156.9, 88.34);
        context.lineTo(159.17, 88.34);
        context.lineTo(159.41, 86.3);
        context.lineTo(163.36, 86.78);
        context.lineTo(163.24, 88.94);
        context.lineTo(165.04, 89.06);
        context.lineTo(165.52, 87.02);
        context.lineTo(168.27, 87.74);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 462 Drawing
        context.beginPath();
        context.moveTo(168.27, 87.74);
        context.lineTo(167.67, 93.72);
        context.lineTo(164.92, 94.08);
        context.lineTo(148.88, 92.56);
        context.lineTo(149.44, 85.03);
        context.lineTo(151.04, 85.11);
        context.lineTo(150.92, 87.86);
        context.lineTo(153.07, 87.98);
        context.lineTo(153.31, 85.47);
        context.lineTo(156.78, 85.94);
        context.lineTo(156.9, 88.34);
        context.lineTo(159.17, 88.34);
        context.lineTo(159.41, 86.3);
        context.lineTo(163.36, 86.78);
        context.lineTo(163.24, 88.94);
        context.lineTo(165.04, 89.06);
        context.lineTo(165.52, 87.02);
        context.lineTo(168.27, 87.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 463 Drawing
        context.beginPath();
        context.moveTo(141.22, 47.65);
        context.lineTo(132.72, 62.61);
        context.lineTo(132.61, 67.75);
        context.lineTo(150.2, 68.59);
        context.lineTo(150.32, 63.68);
        context.lineTo(141.22, 47.65);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Bezier 464 Drawing
        context.beginPath();
        context.moveTo(141.22, 47.65);
        context.lineTo(132.72, 62.61);
        context.lineTo(132.61, 67.75);
        context.lineTo(150.2, 68.59);
        context.lineTo(150.32, 63.68);
        context.lineTo(141.22, 47.65);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 465 Drawing
        context.beginPath();
        context.moveTo(130.81, 75.41);
        context.lineTo(150.08, 75.53);
        context.lineTo(149.99, 77.99);
        context.lineTo(151.99, 75.65);
        context.lineTo(154.15, 65.72);
        context.lineTo(150.32, 63.68);
        context.lineTo(149.03, 66.32);
        context.lineTo(145.29, 65.24);
        context.lineTo(145.65, 62.25);
        context.lineTo(138.47, 62.25);
        context.lineTo(138.47, 64.88);
        context.lineTo(133.44, 65.6);
        context.lineTo(132.72, 62.61);
        context.lineTo(128.42, 64.16);
        context.lineTo(130.81, 75.41);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 466 Drawing
        context.beginPath();
        context.moveTo(130.81, 75.41);
        context.lineTo(150.08, 75.53);
        context.lineTo(149.99, 77.99);
        context.lineTo(151.99, 75.65);
        context.lineTo(154.15, 65.72);
        context.lineTo(150.32, 63.68);
        context.lineTo(149.03, 66.32);
        context.lineTo(145.29, 65.24);
        context.lineTo(145.65, 62.25);
        context.lineTo(138.47, 62.25);
        context.lineTo(138.47, 64.88);
        context.lineTo(133.44, 65.6);
        context.lineTo(132.72, 62.61);
        context.lineTo(128.42, 64.16);
        context.lineTo(130.81, 75.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 467 Drawing
        context.beginPath();
        context.moveTo(150.08, 75.53);
        context.lineTo(147.09, 116.82);
        context.bezierCurveTo(147.09, 116.82, 139.88, 122.45, 129.37, 115.74);
        context.lineTo(130.81, 75.41);
        context.bezierCurveTo(130.81, 75.41, 141.1, 70.15, 150.08, 75.53);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 468 Drawing
        context.beginPath();
        context.moveTo(150.08, 75.53);
        context.lineTo(147.09, 116.82);
        context.bezierCurveTo(147.09, 116.82, 139.88, 122.45, 129.37, 115.74);
        context.lineTo(130.81, 75.41);
        context.bezierCurveTo(130.81, 75.41, 141.1, 70.15, 150.08, 75.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 469 Drawing
        context.beginPath();
        context.moveTo(138.18, 87.26);
        context.lineTo(138.18, 80.99);
        context.bezierCurveTo(138.18, 79.31, 139.55, 77.94, 141.23, 77.94);
        context.lineTo(142.07, 77.94);
        context.bezierCurveTo(143.75, 77.94, 145.12, 79.31, 145.12, 80.99);
        context.lineTo(145.12, 87.26);
        context.lineTo(138.18, 87.26);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 470 Drawing
        context.beginPath();
        context.moveTo(138.18, 87.26);
        context.lineTo(138.18, 80.99);
        context.bezierCurveTo(138.18, 79.31, 139.55, 77.94, 141.23, 77.94);
        context.lineTo(142.07, 77.94);
        context.bezierCurveTo(143.75, 77.94, 145.12, 79.31, 145.12, 80.99);
        context.lineTo(145.12, 87.26);
        context.lineTo(138.18, 87.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 471 Drawing
        context.beginPath();
        context.moveTo(113.7, 17.96);
        context.bezierCurveTo(113.7, 17.96, 113.67, 18.02, 113.62, 18.12);
        context.bezierCurveTo(113.57, 18.02, 113.54, 17.96, 113.54, 17.96);
        context.bezierCurveTo(105.76, 36.75, 98.94, 43.58, 98.94, 43.58);
        context.lineTo(98.94, 49.44);
        context.lineTo(113.61, 49.44);
        context.lineTo(113.63, 49.44);
        context.lineTo(128.3, 49.44);
        context.lineTo(128.3, 43.58);
        context.bezierCurveTo(128.3, 43.58, 121.47, 36.75, 113.7, 17.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Bezier 472 Drawing
        context.beginPath();
        context.moveTo(113.7, 17.96);
        context.bezierCurveTo(113.7, 17.96, 113.67, 18.02, 113.62, 18.12);
        context.bezierCurveTo(113.57, 18.02, 113.54, 17.96, 113.54, 17.96);
        context.bezierCurveTo(105.76, 36.75, 98.94, 43.58, 98.94, 43.58);
        context.lineTo(98.94, 49.44);
        context.lineTo(113.61, 49.44);
        context.lineTo(113.63, 49.44);
        context.lineTo(128.3, 49.44);
        context.lineTo(128.3, 43.58);
        context.bezierCurveTo(128.3, 43.58, 121.47, 36.75, 113.7, 17.96);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 473 Drawing
        context.beginPath();
        context.moveTo(128.9, 43.58);
        context.lineTo(128.18, 45.97);
        context.lineTo(123.39, 44.89);
        context.lineTo(123.39, 41.9);
        context.lineTo(117.05, 41.18);
        context.lineTo(116.81, 43.82);
        context.lineTo(113.63, 43.82);
        context.lineTo(113.61, 43.82);
        context.lineTo(110.43, 43.82);
        context.lineTo(110.19, 41.18);
        context.lineTo(103.85, 41.9);
        context.lineTo(103.85, 44.89);
        context.lineTo(99.06, 45.97);
        context.lineTo(98.34, 43.58);
        context.lineTo(93.79, 46.21);
        context.lineTo(95.47, 54.83);
        context.lineTo(98.1, 56.02);
        context.lineTo(113.61, 56.02);
        context.lineTo(113.63, 56.02);
        context.lineTo(129.13, 56.02);
        context.lineTo(131.77, 54.83);
        context.lineTo(133.44, 46.21);
        context.lineTo(128.9, 43.58);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 474 Drawing
        context.beginPath();
        context.moveTo(128.9, 43.58);
        context.lineTo(128.18, 45.97);
        context.lineTo(123.39, 44.89);
        context.lineTo(123.39, 41.9);
        context.lineTo(117.05, 41.18);
        context.lineTo(116.81, 43.82);
        context.lineTo(113.63, 43.82);
        context.lineTo(113.61, 43.82);
        context.lineTo(110.43, 43.82);
        context.lineTo(110.19, 41.18);
        context.lineTo(103.85, 41.9);
        context.lineTo(103.85, 44.89);
        context.lineTo(99.06, 45.97);
        context.lineTo(98.34, 43.58);
        context.lineTo(93.79, 46.21);
        context.lineTo(95.47, 54.83);
        context.lineTo(98.1, 56.02);
        context.lineTo(113.61, 56.02);
        context.lineTo(113.63, 56.02);
        context.lineTo(129.13, 56.02);
        context.lineTo(131.77, 54.83);
        context.lineTo(133.44, 46.21);
        context.lineTo(128.9, 43.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 475 Drawing
        context.beginPath();
        context.moveTo(113.63, 50.65);
        context.lineTo(113.63, 50.65);
        context.lineTo(113.62, 50.65);
        context.lineTo(113.61, 50.65);
        context.lineTo(113.61, 50.65);
        context.bezierCurveTo(102.34, 50.88, 98.1, 56.02, 98.1, 56.02);
        context.lineTo(98.1, 78.4);
        context.lineTo(113.61, 78.4);
        context.lineTo(113.63, 78.4);
        context.lineTo(129.13, 78.4);
        context.lineTo(129.13, 56.02);
        context.bezierCurveTo(129.13, 56.02, 124.89, 50.88, 113.63, 50.65);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 476 Drawing
        context.beginPath();
        context.moveTo(113.63, 50.65);
        context.lineTo(113.63, 50.65);
        context.lineTo(113.62, 50.65);
        context.lineTo(113.61, 50.65);
        context.lineTo(113.61, 50.65);
        context.bezierCurveTo(102.34, 50.88, 98.1, 56.02, 98.1, 56.02);
        context.lineTo(98.1, 78.4);
        context.lineTo(113.61, 78.4);
        context.lineTo(113.63, 78.4);
        context.lineTo(129.13, 78.4);
        context.lineTo(129.13, 56.02);
        context.bezierCurveTo(129.13, 56.02, 124.89, 50.88, 113.63, 50.65);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 477 Drawing
        context.beginPath();
        context.moveTo(113.63, 79.24);
        context.lineTo(113.61, 79.24);
        context.lineTo(96.43, 79.24);
        context.lineTo(97.86, 115.98);
        context.lineTo(113.61, 115.98);
        context.lineTo(113.63, 115.98);
        context.lineTo(129.37, 115.98);
        context.lineTo(130.81, 79.24);
        context.lineTo(113.63, 79.24);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 478 Drawing
        context.beginPath();
        context.moveTo(113.63, 79.24);
        context.lineTo(113.61, 79.24);
        context.lineTo(96.43, 79.24);
        context.lineTo(97.86, 115.98);
        context.lineTo(113.61, 115.98);
        context.lineTo(113.63, 115.98);
        context.lineTo(129.37, 115.98);
        context.lineTo(130.81, 79.24);
        context.lineTo(113.63, 79.24);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 479 Drawing
        context.beginPath();
        context.moveTo(126.38, 74.93);
        context.lineTo(126.14, 77.45);
        context.lineTo(121.24, 77.45);
        context.lineTo(121.24, 74.81);
        context.lineTo(115.49, 74.81);
        context.lineTo(115.49, 77.69);
        context.lineTo(113.63, 77.69);
        context.lineTo(113.61, 77.69);
        context.lineTo(111.75, 77.69);
        context.lineTo(111.75, 74.81);
        context.lineTo(106, 74.81);
        context.lineTo(106, 77.45);
        context.lineTo(101.09, 77.45);
        context.lineTo(100.86, 74.93);
        context.lineTo(96.43, 75.41);
        context.lineTo(96.43, 79.24);
        context.lineTo(113.61, 79.24);
        context.lineTo(113.63, 79.24);
        context.lineTo(130.81, 79.24);
        context.lineTo(130.81, 75.41);
        context.lineTo(126.38, 74.93);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 480 Drawing
        context.beginPath();
        context.moveTo(126.38, 74.93);
        context.lineTo(126.14, 77.45);
        context.lineTo(121.24, 77.45);
        context.lineTo(121.24, 74.81);
        context.lineTo(115.49, 74.81);
        context.lineTo(115.49, 77.69);
        context.lineTo(113.63, 77.69);
        context.lineTo(113.61, 77.69);
        context.lineTo(111.75, 77.69);
        context.lineTo(111.75, 74.81);
        context.lineTo(106, 74.81);
        context.lineTo(106, 77.45);
        context.lineTo(101.09, 77.45);
        context.lineTo(100.86, 74.93);
        context.lineTo(96.43, 75.41);
        context.lineTo(96.43, 79.24);
        context.lineTo(113.61, 79.24);
        context.lineTo(113.63, 79.24);
        context.lineTo(130.81, 79.24);
        context.lineTo(130.81, 75.41);
        context.lineTo(126.38, 74.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 481 Drawing
        context.beginPath();
        context.moveTo(115.13, 88.1);
        context.lineTo(112.11, 88.1);
        context.bezierCurveTo(106.83, 88.1, 102.55, 92.38, 102.55, 97.66);
        context.lineTo(102.55, 115.98);
        context.lineTo(124.69, 115.98);
        context.lineTo(124.69, 97.66);
        context.bezierCurveTo(124.69, 92.38, 120.41, 88.1, 115.13, 88.1);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 482 Drawing
        context.beginPath();
        context.moveTo(115.13, 88.1);
        context.lineTo(112.11, 88.1);
        context.bezierCurveTo(106.83, 88.1, 102.55, 92.38, 102.55, 97.66);
        context.lineTo(102.55, 115.98);
        context.lineTo(124.69, 115.98);
        context.lineTo(124.69, 97.66);
        context.bezierCurveTo(124.69, 92.38, 120.41, 88.1, 115.13, 88.1);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 483 Drawing
        context.beginPath();
        context.moveTo(114.46, 53);
        context.lineTo(112.78, 53);
        context.bezierCurveTo(109.85, 53, 107.47, 55.38, 107.47, 58.31);
        context.lineTo(107.47, 69.52);
        context.lineTo(119.77, 69.52);
        context.lineTo(119.77, 58.31);
        context.bezierCurveTo(119.77, 55.38, 117.39, 53, 114.46, 53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 484 Drawing
        context.beginPath();
        context.moveTo(114.46, 53);
        context.lineTo(112.78, 53);
        context.bezierCurveTo(109.85, 53, 107.47, 55.38, 107.47, 58.31);
        context.lineTo(107.47, 69.52);
        context.lineTo(119.77, 69.52);
        context.lineTo(119.77, 58.31);
        context.bezierCurveTo(119.77, 55.38, 117.39, 53, 114.46, 53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawHeartsBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor36 = 'rgba(235, 71, 59, 1)';

        //// Group 25
        context.save();

        //// Clip Bezier 95

        context.beginPath();
        context.moveTo(110.34, 0.77);
        context.lineTo(41.19, 0.77);
        context.bezierCurveTo(39.2, 0.77, 37.36, 1.83, 36.36, 3.55);
        context.lineTo(1.79, 63.44);
        context.bezierCurveTo(0.79, 65.16, 0.79, 67.29, 1.79, 69.01);
        context.lineTo(36.36, 128.9);
        context.bezierCurveTo(37.36, 130.62, 39.2, 131.68, 41.19, 131.68);
        context.lineTo(110.34, 131.68);
        context.bezierCurveTo(112.33, 131.68, 114.17, 130.62, 115.16, 128.9);
        context.lineTo(149.74, 69.01);
        context.bezierCurveTo(150.73, 67.29, 150.73, 65.16, 149.74, 63.44);
        context.lineTo(115.16, 3.55);
        context.bezierCurveTo(114.17, 1.83, 112.33, 0.77, 110.34, 0.77);
        context.closePath();
        context.clip();



        //// Bezier 577 Drawing
        context.beginPath();
        context.moveTo(60.84, -9.14);
        context.lineTo(28.59, -4.82);
        context.lineTo(-10.32, 66.68);
        context.lineTo(26.92, 135.85);
        context.lineTo(126.02, 137.18);
        context.lineTo(162.27, 61.03);
        context.lineTo(116.04, -6.81);
        context.lineTo(60.84, -9.14);
        context.closePath();
        context.fillStyle = fillColor36;
        context.fill();


        //// Bezier 579 Drawing
        context.beginPath();
        context.moveTo(31.39, 140.09);
        context.bezierCurveTo(13.84, 130.05, 12.36, 120.66, 12.36, 116.61);
        context.bezierCurveTo(12.36, 112.55, 15.56, 106.17, 22.8, 106.17);
        context.bezierCurveTo(30.05, 106.17, 31.39, 111.37, 31.39, 111.37);
        context.lineTo(31.41, 111.37);
        context.bezierCurveTo(31.41, 111.37, 32.75, 106.17, 40, 106.17);
        context.bezierCurveTo(47.25, 106.17, 50.44, 112.55, 50.44, 116.61);
        context.bezierCurveTo(50.44, 120.66, 48.96, 130.05, 31.41, 140.09);
        context.lineTo(31.39, 140.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 580 Drawing
        context.beginPath();
        context.moveTo(30.73, 60.09);
        context.bezierCurveTo(13.17, 50.05, 11.7, 40.66, 11.7, 36.61);
        context.bezierCurveTo(11.7, 32.55, 14.89, 26.17, 22.14, 26.17);
        context.bezierCurveTo(29.39, 26.17, 30.73, 31.37, 30.73, 31.37);
        context.lineTo(30.75, 31.37);
        context.bezierCurveTo(30.75, 31.37, 32.09, 26.17, 39.33, 26.17);
        context.bezierCurveTo(46.58, 26.17, 49.78, 32.55, 49.78, 36.61);
        context.bezierCurveTo(49.78, 40.66, 48.3, 50.05, 30.75, 60.09);
        context.lineTo(30.73, 60.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 581 Drawing
        context.beginPath();
        context.moveTo(70.73, 100.09);
        context.bezierCurveTo(53.17, 90.05, 51.7, 80.66, 51.7, 76.61);
        context.bezierCurveTo(51.7, 72.55, 54.89, 66.17, 62.14, 66.17);
        context.bezierCurveTo(69.39, 66.17, 70.73, 71.37, 70.73, 71.37);
        context.lineTo(70.75, 71.37);
        context.bezierCurveTo(70.75, 71.37, 72.09, 66.17, 79.33, 66.17);
        context.bezierCurveTo(86.58, 66.17, 89.78, 72.55, 89.78, 76.61);
        context.bezierCurveTo(89.78, 80.66, 88.3, 90.05, 70.75, 100.09);
        context.lineTo(70.73, 100.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 582 Drawing
        context.beginPath();
        context.moveTo(70.73, 20.09);
        context.bezierCurveTo(53.17, 10.05, 51.7, 0.66, 51.7, -3.39);
        context.bezierCurveTo(51.7, -7.45, 54.89, -13.83, 62.14, -13.83);
        context.bezierCurveTo(69.39, -13.83, 70.73, -8.63, 70.73, -8.63);
        context.lineTo(70.75, -8.63);
        context.bezierCurveTo(70.75, -8.63, 72.09, -13.83, 79.33, -13.83);
        context.bezierCurveTo(86.58, -13.83, 89.78, -7.45, 89.78, -3.39);
        context.bezierCurveTo(89.78, 0.66, 88.3, 10.05, 70.75, 20.09);
        context.lineTo(70.73, 20.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 583 Drawing
        context.beginPath();
        context.moveTo(110.73, 140.09);
        context.bezierCurveTo(93.17, 130.05, 91.7, 120.66, 91.7, 116.61);
        context.bezierCurveTo(91.7, 112.55, 94.89, 106.17, 102.14, 106.17);
        context.bezierCurveTo(109.39, 106.17, 110.73, 111.37, 110.73, 111.37);
        context.lineTo(110.75, 111.37);
        context.bezierCurveTo(110.75, 111.37, 112.09, 106.17, 119.33, 106.17);
        context.bezierCurveTo(126.58, 106.17, 129.78, 112.55, 129.78, 116.61);
        context.bezierCurveTo(129.78, 120.66, 128.3, 130.05, 110.75, 140.09);
        context.lineTo(110.73, 140.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 584 Drawing
        context.beginPath();
        context.moveTo(110.73, 60.09);
        context.bezierCurveTo(93.17, 50.05, 91.7, 40.66, 91.7, 36.61);
        context.bezierCurveTo(91.7, 32.55, 94.89, 26.17, 102.14, 26.17);
        context.bezierCurveTo(109.39, 26.17, 110.73, 31.37, 110.73, 31.37);
        context.lineTo(110.75, 31.37);
        context.bezierCurveTo(110.75, 31.37, 112.09, 26.17, 119.33, 26.17);
        context.bezierCurveTo(126.58, 26.17, 129.78, 32.55, 129.78, 36.61);
        context.bezierCurveTo(129.78, 40.66, 128.3, 50.05, 110.75, 60.09);
        context.lineTo(110.73, 60.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 585 Drawing
        context.beginPath();
        context.moveTo(150.73, 100.09);
        context.bezierCurveTo(133.17, 90.05, 131.7, 80.66, 131.7, 76.61);
        context.bezierCurveTo(131.7, 72.55, 134.89, 66.17, 142.14, 66.17);
        context.bezierCurveTo(149.39, 66.17, 150.73, 71.37, 150.73, 71.37);
        context.lineTo(150.75, 71.37);
        context.bezierCurveTo(150.75, 71.37, 152.09, 66.17, 159.33, 66.17);
        context.bezierCurveTo(166.58, 66.17, 169.78, 72.55, 169.78, 76.61);
        context.bezierCurveTo(169.78, 80.66, 168.3, 90.05, 150.75, 100.09);
        context.lineTo(150.73, 100.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawRainbowBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor36 = 'rgba(235, 71, 59, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';
        var fillColor11 = 'rgba(255, 147, 3, 1)';

        //// Group 8
        context.save();

        //// Clip Clip 6

        context.beginPath();
        context.moveTo(41.01, 1.01);
        context.bezierCurveTo(39.02, 1.01, 37.18, 2.07, 36.18, 3.8);
        context.lineTo(36.18, 3.8);
        context.lineTo(1.61, 63.68);
        context.bezierCurveTo(0.61, 65.41, 0.61, 67.53, 1.61, 69.25);
        context.lineTo(1.61, 69.25);
        context.lineTo(36.18, 129.14);
        context.bezierCurveTo(37.18, 130.86, 39.02, 131.93, 41.01, 131.93);
        context.lineTo(41.01, 131.93);
        context.lineTo(110.16, 131.93);
        context.bezierCurveTo(112.15, 131.93, 113.99, 130.86, 114.98, 129.14);
        context.lineTo(114.98, 129.14);
        context.lineTo(149.56, 69.25);
        context.bezierCurveTo(150.56, 67.53, 150.56, 65.41, 149.56, 63.68);
        context.lineTo(149.56, 63.68);
        context.lineTo(114.98, 3.8);
        context.bezierCurveTo(113.99, 2.07, 112.15, 1.01, 110.16, 1.01);
        context.lineTo(110.16, 1.01);
        context.lineTo(41.01, 1.01);
        context.closePath();
        context.clip();



        //// Rectangle Drawing
        context.beginPath();
        context.rect(0.47, -17.34, 21.75, 179.35);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Rectangle 2 Drawing
        context.beginPath();
        context.rect(22.22, -17.34, 21.75, 179.35);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Rectangle 3 Drawing
        context.beginPath();
        context.rect(43.92, -17.34, 21.75, 179.35);
        context.fillStyle = fillColor9;
        context.fill();


        //// Rectangle 4 Drawing
        context.beginPath();
        context.rect(65.67, -17.34, 21.75, 179.35);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Rectangle 5 Drawing
        context.beginPath();
        context.rect(86.42, -17.34, 21.75, 179.35);
        context.fillStyle = fillColor40;
        context.fill();


        //// Rectangle 6 Drawing
        context.beginPath();
        context.rect(108.12, -17.34, 21.75, 179.35);
        context.fillStyle = fillColor11;
        context.fill();


        //// Rectangle 7 Drawing
        context.beginPath();
        context.rect(129.82, -17.34, 21.75, 179.35);
        context.fillStyle = fillColor36;
        context.fill();


        //// Rectangle 8 Drawing
        context.beginPath();
        context.rect(151.57, -17.34, 21.75, 179.35);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Rectangle 9 Drawing
        context.beginPath();
        context.rect(172.32, -17.34, 21.75, 179.35);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 97 Drawing
        context.beginPath();
        context.moveTo(35.67, 55.64);
        context.bezierCurveTo(35.67, 55.64, 39.83, 46.13, 50.13, 51.28);
        context.bezierCurveTo(50.13, 51.28, 55.29, 44.94, 62.02, 47.12);
        context.bezierCurveTo(68.76, 49.3, 69.16, 55.44, 69.16, 55.44);
        context.bezierCurveTo(69.16, 55.44, 78.07, 54.05, 78.07, 62.97);
        context.bezierCurveTo(78.07, 71.89, 70.74, 70.5, 70.74, 70.5);
        context.bezierCurveTo(70.74, 70.5, 66.98, 77.04, 57.66, 73.08);
        context.bezierCurveTo(57.66, 73.08, 52.91, 78.62, 45.38, 73.28);
        context.bezierCurveTo(45.38, 73.28, 38.05, 76.64, 34.48, 70.7);
        context.bezierCurveTo(34.48, 70.7, 25.56, 71.1, 25.56, 62.97);
        context.bezierCurveTo(25.56, 54.85, 31.9, 55.04, 35.67, 55.64);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 98 Drawing
        context.beginPath();
        context.moveTo(35.67, 55.64);
        context.bezierCurveTo(35.67, 55.64, 39.83, 46.13, 50.13, 51.28);
        context.bezierCurveTo(50.13, 51.28, 55.29, 44.94, 62.02, 47.12);
        context.bezierCurveTo(68.76, 49.3, 69.16, 55.44, 69.16, 55.44);
        context.bezierCurveTo(69.16, 55.44, 78.07, 54.05, 78.07, 62.97);
        context.bezierCurveTo(78.07, 71.89, 70.74, 70.5, 70.74, 70.5);
        context.bezierCurveTo(70.74, 70.5, 66.98, 77.04, 57.66, 73.08);
        context.bezierCurveTo(57.66, 73.08, 52.91, 78.62, 45.38, 73.28);
        context.bezierCurveTo(45.38, 73.28, 38.05, 76.64, 34.48, 70.7);
        context.bezierCurveTo(34.48, 70.7, 25.56, 71.1, 25.56, 62.97);
        context.bezierCurveTo(25.56, 54.85, 31.9, 55.04, 35.67, 55.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 99 Drawing
        context.beginPath();
        context.moveTo(84.78, 10.23);
        context.bezierCurveTo(84.78, 10.23, 88.94, 0.71, 99.25, 5.87);
        context.bezierCurveTo(99.25, 5.87, 104.4, -0.47, 111.14, 1.71);
        context.bezierCurveTo(117.88, 3.88, 118.27, 10.03, 118.27, 10.03);
        context.bezierCurveTo(118.27, 10.03, 127.19, 8.64, 127.19, 17.56);
        context.bezierCurveTo(127.19, 26.47, 119.86, 25.09, 119.86, 25.09);
        context.bezierCurveTo(119.86, 25.09, 116.09, 31.63, 106.78, 27.66);
        context.bezierCurveTo(106.78, 27.66, 102.02, 33.21, 94.49, 27.86);
        context.bezierCurveTo(94.49, 27.86, 87.16, 31.23, 83.6, 25.29);
        context.bezierCurveTo(83.6, 25.29, 74.68, 25.68, 74.68, 17.56);
        context.bezierCurveTo(74.68, 9.43, 81.02, 9.63, 84.78, 10.23);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 100 Drawing
        context.beginPath();
        context.moveTo(84.78, 10.23);
        context.bezierCurveTo(84.78, 10.23, 88.94, 0.71, 99.25, 5.87);
        context.bezierCurveTo(99.25, 5.87, 104.4, -0.47, 111.14, 1.71);
        context.bezierCurveTo(117.88, 3.88, 118.27, 10.03, 118.27, 10.03);
        context.bezierCurveTo(118.27, 10.03, 127.19, 8.64, 127.19, 17.56);
        context.bezierCurveTo(127.19, 26.47, 119.86, 25.09, 119.86, 25.09);
        context.bezierCurveTo(119.86, 25.09, 116.09, 31.63, 106.78, 27.66);
        context.bezierCurveTo(106.78, 27.66, 102.02, 33.21, 94.49, 27.86);
        context.bezierCurveTo(94.49, 27.86, 87.16, 31.23, 83.6, 25.29);
        context.bezierCurveTo(83.6, 25.29, 74.68, 25.68, 74.68, 17.56);
        context.bezierCurveTo(74.68, 9.43, 81.02, 9.63, 84.78, 10.23);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 101 Drawing
        context.beginPath();
        context.moveTo(27.04, 107.75);
        context.bezierCurveTo(27.04, 107.75, 31.2, 98.24, 41.51, 103.39);
        context.bezierCurveTo(41.51, 103.39, 46.66, 97.05, 53.4, 99.23);
        context.bezierCurveTo(60.13, 101.41, 60.53, 107.55, 60.53, 107.55);
        context.bezierCurveTo(60.53, 107.55, 69.45, 106.17, 69.45, 115.08);
        context.bezierCurveTo(69.45, 124, 62.12, 122.61, 62.12, 122.61);
        context.bezierCurveTo(62.12, 122.61, 58.35, 129.15, 49.04, 125.19);
        context.bezierCurveTo(49.04, 125.19, 44.28, 130.74, 36.75, 125.39);
        context.bezierCurveTo(36.75, 125.39, 29.42, 128.76, 25.85, 122.81);
        context.bezierCurveTo(25.85, 122.81, 16.94, 123.21, 16.94, 115.08);
        context.bezierCurveTo(16.94, 106.96, 23.28, 107.16, 27.04, 107.75);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 102 Drawing
        context.beginPath();
        context.moveTo(27.04, 107.75);
        context.bezierCurveTo(27.04, 107.75, 31.2, 98.24, 41.51, 103.39);
        context.bezierCurveTo(41.51, 103.39, 46.66, 97.05, 53.4, 99.23);
        context.bezierCurveTo(60.13, 101.41, 60.53, 107.55, 60.53, 107.55);
        context.bezierCurveTo(60.53, 107.55, 69.45, 106.17, 69.45, 115.08);
        context.bezierCurveTo(69.45, 124, 62.12, 122.61, 62.12, 122.61);
        context.bezierCurveTo(62.12, 122.61, 58.35, 129.15, 49.04, 125.19);
        context.bezierCurveTo(49.04, 125.19, 44.28, 130.74, 36.75, 125.39);
        context.bezierCurveTo(36.75, 125.39, 29.42, 128.76, 25.85, 122.81);
        context.bezierCurveTo(25.85, 122.81, 16.94, 123.21, 16.94, 115.08);
        context.bezierCurveTo(16.94, 106.96, 23.28, 107.16, 27.04, 107.75);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 103 Drawing
        context.beginPath();
        context.moveTo(104.75, 84.29);
        context.bezierCurveTo(104.75, 84.29, 108.92, 74.78, 119.22, 79.93);
        context.bezierCurveTo(119.22, 79.93, 124.37, 73.59, 131.11, 75.77);
        context.bezierCurveTo(137.85, 77.95, 138.24, 84.09, 138.24, 84.09);
        context.bezierCurveTo(138.24, 84.09, 147.16, 82.7, 147.16, 91.62);
        context.bezierCurveTo(147.16, 100.54, 139.83, 99.15, 139.83, 99.15);
        context.bezierCurveTo(139.83, 99.15, 136.06, 105.69, 126.75, 101.73);
        context.bezierCurveTo(126.75, 101.73, 121.99, 107.27, 114.46, 101.92);
        context.bezierCurveTo(114.46, 101.92, 107.13, 105.29, 103.57, 99.35);
        context.bezierCurveTo(103.57, 99.35, 94.65, 99.74, 94.65, 91.62);
        context.bezierCurveTo(94.65, 83.5, 100.99, 83.69, 104.75, 84.29);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 104 Drawing
        context.beginPath();
        context.moveTo(104.75, 84.29);
        context.bezierCurveTo(104.75, 84.29, 108.92, 74.78, 119.22, 79.93);
        context.bezierCurveTo(119.22, 79.93, 124.37, 73.59, 131.11, 75.77);
        context.bezierCurveTo(137.85, 77.95, 138.24, 84.09, 138.24, 84.09);
        context.bezierCurveTo(138.24, 84.09, 147.16, 82.7, 147.16, 91.62);
        context.bezierCurveTo(147.16, 100.54, 139.83, 99.15, 139.83, 99.15);
        context.bezierCurveTo(139.83, 99.15, 136.06, 105.69, 126.75, 101.73);
        context.bezierCurveTo(126.75, 101.73, 121.99, 107.27, 114.46, 101.92);
        context.bezierCurveTo(114.46, 101.92, 107.13, 105.29, 103.57, 99.35);
        context.bezierCurveTo(103.57, 99.35, 94.65, 99.74, 94.65, 91.62);
        context.bezierCurveTo(94.65, 83.5, 100.99, 83.69, 104.75, 84.29);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSnowyBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor = 'rgba(198, 198, 198, 1)';

        //// Group 3
        context.save();

        //// Clip Clip

        context.beginPath();
        context.moveTo(41.08, 1.01);
        context.bezierCurveTo(39.09, 1.01, 37.25, 2.07, 36.25, 3.8);
        context.lineTo(36.25, 3.8);
        context.lineTo(1.68, 63.68);
        context.bezierCurveTo(0.68, 65.41, 0.68, 67.53, 1.68, 69.25);
        context.lineTo(1.68, 69.25);
        context.lineTo(36.25, 129.14);
        context.bezierCurveTo(37.25, 130.86, 39.09, 131.93, 41.08, 131.93);
        context.lineTo(41.08, 131.93);
        context.lineTo(110.23, 131.93);
        context.bezierCurveTo(112.22, 131.93, 114.06, 130.86, 115.05, 129.14);
        context.lineTo(115.05, 129.14);
        context.lineTo(149.63, 69.25);
        context.bezierCurveTo(150.62, 67.53, 150.62, 65.41, 149.63, 63.68);
        context.lineTo(149.63, 63.68);
        context.lineTo(115.05, 3.8);
        context.bezierCurveTo(114.06, 2.07, 112.22, 1.01, 110.23, 1.01);
        context.lineTo(110.23, 1.01);
        context.lineTo(41.08, 1.01);
        context.closePath();
        context.clip();



        //// Bezier Drawing
        context.beginPath();
        context.moveTo(-9.49, 54.18);
        context.lineTo(5.85, 83.2);
        context.lineTo(37.01, 84.01);
        context.bezierCurveTo(37.01, 84.01, 70.35, 81.01, 71.01, 81.18);
        context.bezierCurveTo(71.68, 81.34, 108.01, 83.2, 108.01, 83.2);
        context.lineTo(140.51, 95.68);
        context.lineTo(155.85, 98.19);
        context.bezierCurveTo(155.85, 98.19, 174.85, 77.84, 172.85, 73.76);
        context.bezierCurveTo(170.85, 69.68, 128.68, 1.01, 128.68, 1.01);
        context.lineTo(157.85, -20.66);
        context.bezierCurveTo(157.85, -20.66, 80.35, -29.16, 47.51, -14.49);
        context.lineTo(14.68, 0.18);
        context.lineTo(-9.49, 54.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2 Drawing
        context.beginPath();
        context.moveTo(-9.49, 54.18);
        context.lineTo(5.85, 83.2);
        context.lineTo(37.01, 84.01);
        context.bezierCurveTo(37.01, 84.01, 70.35, 81.01, 71.01, 81.18);
        context.bezierCurveTo(71.68, 81.34, 108.01, 83.2, 108.01, 83.2);
        context.lineTo(140.51, 95.68);
        context.lineTo(155.85, 98.19);
        context.bezierCurveTo(155.85, 98.19, 174.85, 77.84, 172.85, 73.76);
        context.bezierCurveTo(170.85, 69.68, 128.68, 1.01, 128.68, 1.01);
        context.lineTo(157.85, -20.66);
        context.bezierCurveTo(157.85, -20.66, 80.35, -29.16, 47.51, -14.49);
        context.lineTo(14.68, 0.18);
        context.lineTo(-9.49, 54.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 3 Drawing
        context.beginPath();
        context.moveTo(113.85, 107.39);
        context.bezierCurveTo(113.85, 107.39, 122.51, 85.84, 135.01, 84.01);
        context.bezierCurveTo(147.51, 82.18, 176.51, 84.16, 176.51, 84.16);
        context.lineTo(155.85, 100.51);
        context.lineTo(122.18, 113.18);
        context.lineTo(113.85, 107.39);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 4 Drawing
        context.beginPath();
        context.moveTo(113.85, 107.39);
        context.bezierCurveTo(113.85, 107.39, 122.51, 85.84, 135.01, 84.01);
        context.bezierCurveTo(147.51, 82.18, 176.51, 84.16, 176.51, 84.16);
        context.lineTo(155.85, 100.51);
        context.lineTo(122.18, 113.18);
        context.lineTo(113.85, 107.39);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 5 Drawing
        context.beginPath();
        context.moveTo(81.35, 66.47);
        context.lineTo(106.18, 26.01);
        context.lineTo(142.93, 90.68);
        context.lineTo(98.68, 108.34);
        context.lineTo(78.85, 77.34);
        context.lineTo(81.35, 66.47);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 6 Drawing
        context.beginPath();
        context.moveTo(81.35, 66.47);
        context.lineTo(106.18, 26.01);
        context.lineTo(142.93, 90.68);
        context.lineTo(98.68, 108.34);
        context.lineTo(78.85, 77.34);
        context.lineTo(81.35, 66.47);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 7 Drawing
        context.beginPath();
        context.moveTo(5.85, 91.34);
        context.lineTo(51.35, 9.01);
        context.lineTo(93.01, 85.34);
        context.lineTo(28.01, 104.51);
        context.lineTo(5.85, 91.34);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 8 Drawing
        context.beginPath();
        context.moveTo(5.85, 91.34);
        context.lineTo(51.35, 9.01);
        context.lineTo(93.01, 85.34);
        context.lineTo(28.01, 104.51);
        context.lineTo(5.85, 91.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 9 Drawing
        context.beginPath();
        context.moveTo(5.85, 91.34);
        context.lineTo(51.35, 9.01);
        context.lineTo(93.01, 85.34);
        context.lineTo(28.01, 104.51);
        context.lineTo(5.85, 91.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 10 Drawing
        context.beginPath();
        context.moveTo(-3.32, 91.68);
        context.bezierCurveTo(-3.32, 91.68, 15.52, 86.52, 28.01, 89.68);
        context.bezierCurveTo(40.51, 92.83, 49.18, 96.84, 49.18, 96.84);
        context.lineTo(34.18, 109.18);
        context.lineTo(17.37, 114.98);
        context.lineTo(7.35, 116.34);
        context.lineTo(-3.32, 91.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 11 Drawing
        context.beginPath();
        context.moveTo(-3.32, 91.68);
        context.bezierCurveTo(-3.32, 91.68, 15.52, 86.52, 28.01, 89.68);
        context.bezierCurveTo(40.51, 92.83, 49.18, 96.84, 49.18, 96.84);
        context.lineTo(34.18, 109.18);
        context.lineTo(17.37, 114.98);
        context.lineTo(7.35, 116.34);
        context.lineTo(-3.32, 91.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 12 Drawing
        context.beginPath();
        context.moveTo(12.68, 106.34);
        context.bezierCurveTo(12.68, 106.34, 42.01, 94.01, 47.85, 87.51);
        context.bezierCurveTo(53.68, 81.01, 66.85, 72.84, 78.51, 73.84);
        context.bezierCurveTo(90.18, 74.84, 102.85, 83.68, 106.85, 89.18);
        context.bezierCurveTo(110.85, 94.68, 122.51, 105.51, 131.01, 107.84);
        context.bezierCurveTo(139.51, 110.18, 148.85, 117.01, 148.85, 117.01);
        context.lineTo(122.68, 139.68);
        context.lineTo(75.65, 141.01);
        context.bezierCurveTo(75.65, 141.01, 33.51, 140.68, 32.51, 141.01);
        context.bezierCurveTo(31.51, 141.34, 12.68, 106.34, 12.68, 106.34);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 13 Drawing
        context.beginPath();
        context.moveTo(12.68, 106.34);
        context.bezierCurveTo(12.68, 106.34, 42.01, 94.01, 47.85, 87.51);
        context.bezierCurveTo(53.68, 81.01, 66.85, 72.84, 78.51, 73.84);
        context.bezierCurveTo(90.18, 74.84, 102.85, 83.68, 106.85, 89.18);
        context.bezierCurveTo(110.85, 94.68, 122.51, 105.51, 131.01, 107.84);
        context.bezierCurveTo(139.51, 110.18, 148.85, 117.01, 148.85, 117.01);
        context.lineTo(122.68, 139.68);
        context.lineTo(75.65, 141.01);
        context.bezierCurveTo(75.65, 141.01, 33.51, 140.68, 32.51, 141.01);
        context.bezierCurveTo(31.51, 141.34, 12.68, 106.34, 12.68, 106.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval Drawing
        oval(context, 80.15, 79.69, 12.2, 12.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 2 Drawing
        oval(context, 80.15, 79.69, 12.2, 12.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 14 Drawing
        context.beginPath();
        context.moveTo(86.26, 113.18);
        context.lineTo(86.26, 86.38);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 3 Drawing
        oval(context, 77.5, 92.24, 6.5, 6.5);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 4 Drawing
        oval(context, 77.5, 92.24, 6.5, 6.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 5 Drawing
        oval(context, 88.55, 93.79, 7.7, 7.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 6 Drawing
        oval(context, 88.55, 93.79, 7.7, 7.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 15 Drawing
        context.beginPath();
        context.moveTo(96.36, 56.45);
        context.lineTo(88.89, 74.57);
        context.lineTo(96.36, 74.57);
        context.lineTo(103.83, 74.57);
        context.lineTo(96.36, 56.45);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 16 Drawing
        context.beginPath();
        context.moveTo(96.36, 56.45);
        context.lineTo(88.89, 74.57);
        context.lineTo(96.36, 74.57);
        context.lineTo(103.83, 74.57);
        context.lineTo(96.36, 56.45);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 17 Drawing
        context.beginPath();
        context.moveTo(86.26, 99.76);
        context.bezierCurveTo(86.26, 99.76, 81.87, 99.88, 81.76, 95.51);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 18 Drawing
        context.beginPath();
        context.moveTo(86.26, 102.5);
        context.bezierCurveTo(86.26, 102.5, 91.01, 102.51, 91.51, 99.01);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 7 Drawing
        oval(context, 51.55, 59.44, 7.9, 7.9);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 8 Drawing
        oval(context, 51.55, 59.44, 7.9, 7.9);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 19 Drawing
        context.beginPath();
        context.moveTo(55.52, 81.13);
        context.lineTo(55.52, 63.77);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 9 Drawing
        oval(context, 49.85, 67.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 10 Drawing
        oval(context, 49.85, 67.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 11 Drawing
        oval(context, 57, 68.59, 5, 5);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 12 Drawing
        oval(context, 57, 68.59, 5, 5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 20 Drawing
        context.beginPath();
        context.moveTo(55.52, 72.44);
        context.bezierCurveTo(55.52, 72.44, 52.68, 72.52, 52.6, 69.68);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 21 Drawing
        context.beginPath();
        context.moveTo(55.52, 74.21);
        context.bezierCurveTo(55.52, 74.21, 58.6, 74.22, 58.93, 71.95);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 13 Drawing
        oval(context, 113.15, 72.19, 10.3, 10.3);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 14 Drawing
        oval(context, 113.15, 72.19, 10.3, 10.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 22 Drawing
        context.beginPath();
        context.moveTo(118.31, 100.6);
        context.lineTo(118.31, 77.87);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 15 Drawing
        oval(context, 110.9, 82.84, 5.5, 5.5);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 16 Drawing
        oval(context, 110.9, 82.84, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 17 Drawing
        oval(context, 120.25, 84.19, 6.5, 6.5);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 18 Drawing
        oval(context, 120.25, 84.19, 6.5, 6.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 23 Drawing
        context.beginPath();
        context.moveTo(118.31, 89.21);
        context.bezierCurveTo(118.31, 89.21, 114.58, 89.32, 114.48, 85.61);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 24 Drawing
        context.beginPath();
        context.moveTo(118.31, 91.54);
        context.bezierCurveTo(118.31, 91.54, 122.34, 91.55, 122.77, 88.58);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 25 Drawing
        context.beginPath();
        context.moveTo(96.36, 80.31);
        context.lineTo(96.36, 63.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 26 Drawing
        context.beginPath();
        context.moveTo(27.16, 69.65);
        context.lineTo(20.89, 84.88);
        context.lineTo(27.16, 84.88);
        context.lineTo(33.44, 84.88);
        context.lineTo(27.16, 69.65);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 27 Drawing
        context.beginPath();
        context.moveTo(27.16, 69.65);
        context.lineTo(20.89, 84.88);
        context.lineTo(27.16, 84.88);
        context.lineTo(33.44, 84.88);
        context.lineTo(27.16, 69.65);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 28 Drawing
        context.beginPath();
        context.moveTo(27.16, 89.7);
        context.lineTo(27.16, 75.69);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 4
        context.save();

        //// Clip Clip 2

        context.beginPath();
        context.moveTo(81.35, 66.47);
        context.lineTo(98.68, 108.34);
        context.lineTo(135.56, 93.62);
        context.lineTo(140.12, 85.72);
        context.lineTo(106.18, 26.01);
        context.lineTo(81.35, 66.47);
        context.closePath();
        context.clip();



        //// Bezier 30 Drawing
        context.beginPath();
        context.moveTo(92.39, 42.5);
        context.bezierCurveTo(92.39, 42.5, 95.26, 49.39, 99.51, 46.89);
        context.bezierCurveTo(99.51, 46.89, 100.39, 53.76, 105.26, 53.01);
        context.bezierCurveTo(110.14, 52.26, 109.89, 47.78, 109.89, 47.78);
        context.bezierCurveTo(109.89, 47.78, 116.27, 52.25, 117.33, 45.63);
        context.bezierCurveTo(118.39, 39.01, 118.51, 34.26, 118.51, 34.26);
        context.lineTo(110.89, 26.01);
        context.lineTo(101.51, 26.01);
        context.lineTo(99.14, 30.01);
        context.lineTo(92.39, 42.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 31 Drawing
        context.beginPath();
        context.moveTo(92.39, 42.5);
        context.bezierCurveTo(92.39, 42.5, 95.26, 49.39, 99.51, 46.89);
        context.bezierCurveTo(99.51, 46.89, 100.39, 53.76, 105.26, 53.01);
        context.bezierCurveTo(110.14, 52.26, 109.89, 47.78, 109.89, 47.78);
        context.bezierCurveTo(109.89, 47.78, 116.27, 52.25, 117.33, 45.63);
        context.bezierCurveTo(118.39, 39.01, 118.51, 34.26, 118.51, 34.26);
        context.lineTo(110.89, 26.01);
        context.lineTo(101.51, 26.01);
        context.lineTo(99.14, 30.01);
        context.lineTo(92.39, 42.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 5
        context.save();

        //// Clip Clip 3

        context.beginPath();
        context.moveTo(10.05, 83.75);
        context.lineTo(18.91, 99.1);
        context.lineTo(28.01, 104.51);
        context.lineTo(93.02, 85.34);
        context.lineTo(51.35, 9.01);
        context.lineTo(10.05, 83.75);
        context.closePath();
        context.clip();



        //// Bezier 33 Drawing
        context.beginPath();
        context.moveTo(38.04, 33.09);
        context.bezierCurveTo(38.04, 33.09, 38.14, 37.69, 41.64, 37.54);
        context.bezierCurveTo(45.14, 37.38, 46.89, 33.09, 46.89, 33.09);
        context.bezierCurveTo(47.47, 32.57, 44.52, 36.98, 48.52, 40.51);
        context.bezierCurveTo(51.16, 42.85, 53.64, 42.76, 56.27, 41.88);
        context.bezierCurveTo(58.89, 41.01, 59.64, 39.76, 59.52, 35.63);
        context.bezierCurveTo(59.52, 35.63, 64.14, 40.88, 68.52, 35.01);
        context.lineTo(67.14, 21.38);
        context.lineTo(55.14, 7.88);
        context.bezierCurveTo(55.14, 7.88, 52.27, -11.87, 46.02, 4.88);
        context.lineTo(39.77, 21.63);
        context.lineTo(38.04, 33.09);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 34 Drawing
        context.beginPath();
        context.moveTo(38.04, 33.09);
        context.bezierCurveTo(38.04, 33.09, 38.14, 37.69, 41.64, 37.54);
        context.bezierCurveTo(45.14, 37.38, 46.89, 33.09, 46.89, 33.09);
        context.bezierCurveTo(47.47, 32.57, 44.52, 36.98, 48.52, 40.51);
        context.bezierCurveTo(51.16, 42.85, 53.64, 42.76, 56.27, 41.88);
        context.bezierCurveTo(58.89, 41.01, 59.64, 39.76, 59.52, 35.63);
        context.bezierCurveTo(59.52, 35.63, 64.14, 40.88, 68.52, 35.01);
        context.lineTo(67.14, 21.38);
        context.lineTo(55.14, 7.88);
        context.bezierCurveTo(55.14, 7.88, 52.27, -11.87, 46.02, 4.88);
        context.lineTo(39.77, 21.63);
        context.lineTo(38.04, 33.09);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Group 6
        context.save();

        //// Clip Clip 4

        context.beginPath();
        context.moveTo(41.08, 1.01);
        context.bezierCurveTo(39.09, 1.01, 37.25, 2.07, 36.25, 3.8);
        context.lineTo(36.25, 3.8);
        context.lineTo(1.68, 63.68);
        context.bezierCurveTo(0.68, 65.41, 0.68, 67.53, 1.68, 69.25);
        context.lineTo(1.68, 69.25);
        context.lineTo(36.25, 129.14);
        context.bezierCurveTo(37.25, 130.86, 39.09, 131.93, 41.08, 131.93);
        context.lineTo(41.08, 131.93);
        context.lineTo(110.23, 131.93);
        context.bezierCurveTo(112.22, 131.93, 114.06, 130.86, 115.05, 129.14);
        context.lineTo(115.05, 129.14);
        context.lineTo(149.63, 69.25);
        context.bezierCurveTo(150.62, 67.53, 150.62, 65.41, 149.63, 63.68);
        context.lineTo(149.63, 63.68);
        context.lineTo(115.05, 3.8);
        context.bezierCurveTo(114.06, 2.07, 112.22, 1.01, 110.23, 1.01);
        context.lineTo(110.23, 1.01);
        context.lineTo(41.08, 1.01);
        context.closePath();
        context.clip();



        //// Bezier 36 Drawing
        context.beginPath();
        context.moveTo(27.16, 52.78);
        context.lineTo(51.35, 9.01);
        context.lineTo(81.17, 63.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 37 Drawing
        context.beginPath();
        context.moveTo(84.01, 62.13);
        context.lineTo(106.18, 26.01);
        context.lineTo(142.93, 90.68);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 19 Drawing
        oval(context, 6.85, 64.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 20 Drawing
        oval(context, 6.85, 64.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 21 Drawing
        oval(context, 19.85, 46.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 22 Drawing
        oval(context, 19.85, 46.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 23 Drawing
        oval(context, 18.85, 26.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 24 Drawing
        oval(context, 18.85, 26.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 25 Drawing
        oval(context, 37.85, 25.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 26 Drawing
        oval(context, 37.85, 25.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 27 Drawing
        oval(context, 33.85, 11.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 28 Drawing
        oval(context, 33.85, 11.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 29 Drawing
        oval(context, 45.85, -0.41, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 30 Drawing
        oval(context, 45.85, -0.41, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 31 Drawing
        oval(context, 60.85, 5.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 32 Drawing
        oval(context, 60.85, 5.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 33 Drawing
        oval(context, 66.85, 21.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 34 Drawing
        oval(context, 66.85, 21.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 35 Drawing
        oval(context, 68.85, 42.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 36 Drawing
        oval(context, 68.85, 42.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 37 Drawing
        oval(context, 79.85, 32.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 38 Drawing
        oval(context, 79.85, 32.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 39 Drawing
        oval(context, 84.85, 52.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 40 Drawing
        oval(context, 84.85, 52.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 41 Drawing
        oval(context, 94.85, 22.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 42 Drawing
        oval(context, 94.85, 22.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 43 Drawing
        oval(context, 81.85, 12.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 44 Drawing
        oval(context, 81.85, 12.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 45 Drawing
        oval(context, 88.85, 0.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 46 Drawing
        oval(context, 88.85, 0.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 47 Drawing
        oval(context, 102.85, 7.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 48 Drawing
        oval(context, 102.85, 7.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 49 Drawing
        oval(context, 116.85, 18.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 50 Drawing
        oval(context, 116.85, 18.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 51 Drawing
        oval(context, 112.85, 37.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 52 Drawing
        oval(context, 112.85, 37.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 53 Drawing
        oval(context, 128.85, 30.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 54 Drawing
        oval(context, 128.85, 30.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 55 Drawing
        oval(context, 123.85, 51.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 56 Drawing
        oval(context, 123.85, 51.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 57 Drawing
        oval(context, 136.85, 44.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 58 Drawing
        oval(context, 136.85, 44.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 59 Drawing
        oval(context, 136.85, 64.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 60 Drawing
        oval(context, 136.85, 64.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 61 Drawing
        oval(context, 141.85, 77.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 62 Drawing
        oval(context, 141.85, 77.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSeaBackground2(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';
        var fillColor11 = 'rgba(255, 147, 3, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Group 18
        context.save();

        //// Clip Clip 16

        context.beginPath();
        context.moveTo(41.49, 1.01);
        context.bezierCurveTo(39.5, 1.01, 37.66, 2.07, 36.67, 3.8);
        context.lineTo(36.67, 3.8);
        context.lineTo(2.09, 63.68);
        context.bezierCurveTo(1.1, 65.41, 1.1, 67.53, 2.09, 69.25);
        context.lineTo(2.09, 69.25);
        context.lineTo(36.67, 129.14);
        context.bezierCurveTo(37.66, 130.86, 39.5, 131.93, 41.49, 131.93);
        context.lineTo(41.49, 131.93);
        context.lineTo(110.64, 131.93);
        context.bezierCurveTo(112.63, 131.93, 114.47, 130.86, 115.47, 129.14);
        context.lineTo(115.47, 129.14);
        context.lineTo(150.04, 69.25);
        context.bezierCurveTo(151.04, 67.53, 151.04, 65.41, 150.04, 63.68);
        context.lineTo(150.04, 63.68);
        context.lineTo(115.47, 3.8);
        context.bezierCurveTo(114.47, 2.07, 112.63, 1.01, 110.64, 1.01);
        context.lineTo(110.64, 1.01);
        context.lineTo(41.49, 1.01);
        context.closePath();
        context.clip();



        //// Bezier 410 Drawing
        context.beginPath();
        context.moveTo(32.09, -4.15);
        context.lineTo(-4.16, 64.86);
        context.lineTo(34.75, 135.69);
        context.lineTo(120.88, 135.02);
        context.bezierCurveTo(120.88, 135.02, 158.95, 65.36, 158.45, 64.19);
        context.bezierCurveTo(157.95, 63.03, 115.39, -5.48, 115.39, -5.48);
        context.lineTo(32.09, -4.15);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 411 Drawing
        context.beginPath();
        context.moveTo(94.99, 92.58);
        context.bezierCurveTo(87.88, 92.8, 84.22, 86.55, 77.76, 81.81);
        context.bezierCurveTo(71.3, 77.08, 68.29, 79.23, 61.61, 77.94);
        context.bezierCurveTo(54.93, 76.64, 51.92, 71.26, 53, 67.6);
        context.bezierCurveTo(54.07, 63.94, 54.93, 67.39, 60.1, 71.69);
        context.bezierCurveTo(65.27, 76, 61.39, 67.82, 61.61, 65.02);
        context.bezierCurveTo(61.83, 62.22, 66.35, 61.14, 66.99, 66.74);
        context.bezierCurveTo(67.64, 72.34, 74.75, 78.58, 74.75, 74.28);
        context.bezierCurveTo(74.75, 69.97, 76.25, 65.23, 77.98, 70.61);
        context.bezierCurveTo(79.7, 76, 85.3, 83.54, 92.4, 83.97);
        context.bezierCurveTo(99.51, 84.4, 96.07, 74.28, 93.7, 70.83);
        context.bezierCurveTo(91.33, 67.39, 76.04, 65.66, 74.53, 63.94);
        context.bezierCurveTo(73.02, 62.22, 70.22, 59.85, 63.12, 59.85);
        context.bezierCurveTo(56.01, 59.85, 49.55, 56.83, 48.47, 52.96);
        context.bezierCurveTo(47.4, 49.08, 54.5, 53.39, 60.96, 54.03);
        context.bezierCurveTo(67.43, 54.68, 55.58, 44.13, 62.47, 42.62);
        context.bezierCurveTo(69.36, 41.11, 70.87, 55.97, 76.25, 59.63);
        context.bezierCurveTo(81.64, 63.29, 79.27, 54.03, 82.93, 49.51);
        context.bezierCurveTo(86.59, 44.99, 69.58, 38.74, 74.1, 35.73);
        context.bezierCurveTo(78.62, 32.71, 87.24, 42.84, 90.04, 40.68);
        context.bezierCurveTo(92.84, 38.53, 82.5, 23.02, 87.88, 23.45);
        context.bezierCurveTo(93.27, 23.89, 89.82, 34.65, 96.93, 34.87);
        context.bezierCurveTo(104.03, 35.08, 97.36, 17.43, 105.76, 23.45);
        context.bezierCurveTo(114.15, 29.48, 96.93, 39.6, 89.39, 46.5);
        context.bezierCurveTo(81.85, 53.39, 86.81, 62.22, 90.68, 64.37);
        context.bezierCurveTo(94.56, 66.52, 92.62, 52.31, 96.71, 53.39);
        context.bezierCurveTo(100.8, 54.46, 100.37, 59.42, 99.73, 62.65);
        context.bezierCurveTo(99.08, 65.88, 100.8, 73.41, 106.19, 69.75);
        context.bezierCurveTo(111.57, 66.09, 98.86, 48.65, 102.74, 47.79);
        context.bezierCurveTo(106.62, 46.93, 108.34, 55.76, 111.57, 54.03);
        context.bezierCurveTo(114.8, 52.31, 108.55, 40.68, 112.43, 40.04);
        context.bezierCurveTo(116.31, 39.39, 118.46, 41.54, 120.61, 50.16);
        context.bezierCurveTo(122.77, 58.77, 112.65, 59.42, 114.15, 63.72);
        context.bezierCurveTo(115.66, 68.03, 113.51, 71.91, 109.63, 73.63);
        context.bezierCurveTo(105.76, 75.35, 103.82, 80.95, 103.82, 80.95);
        context.bezierCurveTo(103.82, 80.95, 102.3, 89.77, 103.16, 92.93);
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 412 Drawing
        context.beginPath();
        context.moveTo(136.2, 98.9);
        context.bezierCurveTo(136.2, 98.9, 134.53, 92.91, 136.36, 89.75);
        context.bezierCurveTo(138.19, 86.59, 140.69, 80.11, 137.86, 75.62);
        context.bezierCurveTo(135.03, 71.13, 134.37, 59.74, 139.19, 57.58);
        context.bezierCurveTo(144.01, 55.42, 150, 50.51, 146.34, 48.35);
        context.bezierCurveTo(142.68, 46.19, 135.74, 50.4, 133.54, 56.17);
        context.bezierCurveTo(132.45, 59, 131.54, 60.63, 130.89, 61.54);
        context.bezierCurveTo(129.54, 63.46, 128.66, 65.71, 128.7, 68.05);
        context.bezierCurveTo(128.73, 69.71, 129.13, 71.6, 130.3, 73.46);
        context.bezierCurveTo(133.62, 78.78, 132.87, 82.77, 130.88, 87.59);
        context.bezierCurveTo(128.88, 92.41, 129.88, 98.73, 129.88, 98.73);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 413 Drawing
        context.beginPath();
        context.moveTo(127.97, 96.9);
        context.bezierCurveTo(127.97, 96.9, 123.39, 92.16, 125.39, 88.01);
        context.bezierCurveTo(127.38, 83.85, 127.22, 79.28, 124.06, 75.79);
        context.bezierCurveTo(120.9, 72.29, 117.41, 66.64, 120.4, 61.65);
        context.bezierCurveTo(123.39, 56.66, 125.56, 39.37, 116.91, 40.2);
        context.bezierCurveTo(108.26, 41.04, 118.9, 51.01, 116.91, 57.16);
        context.bezierCurveTo(114.91, 63.32, 111.76, 69.13, 116.24, 73.96);
        context.bezierCurveTo(120.73, 78.78, 121.07, 83.77, 119.9, 87.26);
        context.bezierCurveTo(118.74, 90.75, 119.4, 95.74, 119.4, 95.74);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 414 Drawing
        context.beginPath();
        context.moveTo(116.58, 94.24);
        context.bezierCurveTo(116.58, 94.24, 115.41, 92.75, 115.91, 88.42);
        context.bezierCurveTo(116.41, 84.1, 114.08, 79.61, 110.26, 76.95);
        context.bezierCurveTo(106.43, 74.29, 109.26, 70.3, 105.6, 70.3);
        context.bezierCurveTo(101.95, 70.3, 100.62, 74.79, 102.94, 77.28);
        context.bezierCurveTo(105.27, 79.78, 110.34, 81.77, 109.93, 86.09);
        context.bezierCurveTo(109.54, 90.13, 108.93, 92.75, 108.93, 92.75);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 415 Drawing
        context.beginPath();
        context.moveTo(32.61, 96.19);
        context.bezierCurveTo(32.61, 96.19, 27.08, 89.76, 29.14, 79.93);
        context.bezierCurveTo(31.19, 70.09, 24.62, 65.09, 25.26, 58.81);
        context.bezierCurveTo(25.89, 52.54, 31.01, 41.17, 31.01, 41.17);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 416 Drawing
        context.beginPath();
        context.moveTo(21.29, 32.78);
        context.bezierCurveTo(21.29, 32.78, 22.58, 46.12, 28.39, 46.18);
        context.bezierCurveTo(28.39, 46.18, 31.44, 40.05, 21.29, 32.78);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 417 Drawing
        context.beginPath();
        context.moveTo(21.29, 32.78);
        context.bezierCurveTo(21.29, 32.78, 22.58, 46.12, 28.39, 46.18);
        context.bezierCurveTo(28.39, 46.18, 31.44, 40.05, 21.29, 32.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 418 Drawing
        context.beginPath();
        context.moveTo(18.46, 59.66);
        context.bezierCurveTo(18.46, 59.66, 21.53, 74.13, 29.02, 74.84);
        context.bezierCurveTo(29.02, 74.84, 28.28, 63.93, 18.46, 59.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 419 Drawing
        context.beginPath();
        context.moveTo(18.46, 59.66);
        context.bezierCurveTo(18.46, 59.66, 21.53, 74.13, 29.02, 74.84);
        context.bezierCurveTo(29.02, 74.84, 28.28, 63.93, 18.46, 59.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 420 Drawing
        context.beginPath();
        context.moveTo(19.56, 72.19);
        context.bezierCurveTo(19.56, 72.19, 21.61, 88.94, 29.01, 90.01);
        context.bezierCurveTo(29.01, 90.01, 28.5, 77.85, 19.56, 72.19);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 421 Drawing
        context.beginPath();
        context.moveTo(19.56, 72.19);
        context.bezierCurveTo(19.56, 72.19, 21.61, 88.94, 29.01, 90.01);
        context.bezierCurveTo(29.01, 90.01, 28.5, 77.85, 19.56, 72.19);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 422 Drawing
        context.beginPath();
        context.moveTo(25.33, 57.61);
        context.bezierCurveTo(25.33, 57.61, 19.44, 54.15, 18.14, 43.03);
        context.bezierCurveTo(18.14, 43.03, 25.58, 48.86, 25.33, 57.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 423 Drawing
        context.beginPath();
        context.moveTo(25.33, 57.61);
        context.bezierCurveTo(25.33, 57.61, 19.44, 54.15, 18.14, 43.03);
        context.bezierCurveTo(18.14, 43.03, 25.58, 48.86, 25.33, 57.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 424 Drawing
        context.beginPath();
        context.moveTo(25.36, 59.93);
        context.bezierCurveTo(25.36, 59.93, 28.56, 50.75, 37.67, 49.56);
        context.bezierCurveTo(37.67, 49.56, 37.17, 63.93, 25.36, 59.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 425 Drawing
        context.beginPath();
        context.moveTo(25.36, 59.93);
        context.bezierCurveTo(25.36, 59.93, 28.56, 50.75, 37.67, 49.56);
        context.bezierCurveTo(37.67, 49.56, 37.17, 63.93, 25.36, 59.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 426 Drawing
        context.beginPath();
        context.moveTo(28.39, 46.18);
        context.bezierCurveTo(28.39, 46.18, 33.54, 40.26, 40.88, 40.32);
        context.bezierCurveTo(40.88, 40.32, 36.46, 49.49, 28.39, 46.18);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 427 Drawing
        context.beginPath();
        context.moveTo(28.39, 46.18);
        context.bezierCurveTo(28.39, 46.18, 33.54, 40.26, 40.88, 40.32);
        context.bezierCurveTo(40.88, 40.32, 36.46, 49.49, 28.39, 46.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 428 Drawing
        context.beginPath();
        context.moveTo(29.65, 76.91);
        context.bezierCurveTo(29.65, 76.91, 31.57, 67.96, 39.82, 66.8);
        context.bezierCurveTo(39.82, 66.8, 36.98, 80.72, 29.65, 76.91);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 429 Drawing
        context.beginPath();
        context.moveTo(29.65, 76.91);
        context.bezierCurveTo(29.65, 76.91, 31.57, 67.96, 39.82, 66.8);
        context.bezierCurveTo(39.82, 66.8, 36.98, 80.72, 29.65, 76.91);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 430 Drawing
        context.beginPath();
        context.moveTo(28.57, 89.63);
        context.bezierCurveTo(28.57, 89.63, 31.5, 81.3, 40.14, 81.6);
        context.bezierCurveTo(40.14, 81.6, 37.28, 92.93, 28.57, 89.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 431 Drawing
        context.beginPath();
        context.moveTo(28.57, 89.63);
        context.bezierCurveTo(28.57, 89.63, 31.5, 81.3, 40.14, 81.6);
        context.bezierCurveTo(40.14, 81.6, 37.28, 92.93, 28.57, 89.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 432 Drawing
        context.beginPath();
        context.moveTo(13.19, 99.27);
        context.bezierCurveTo(13.19, 99.27, 31.56, 79.19, 56.52, 83.42);
        context.bezierCurveTo(81.47, 87.65, 95.33, 97.16, 116.82, 89.76);
        context.bezierCurveTo(138.31, 82.36, 150.78, 86.06, 150.78, 86.06);
        context.lineTo(134.5, 137.32);
        context.lineTo(46.46, 148.41);
        context.lineTo(9.73, 135.73);
        context.lineTo(13.19, 99.27);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 433 Drawing
        context.beginPath();
        context.moveTo(13.19, 99.27);
        context.bezierCurveTo(13.19, 99.27, 31.56, 79.19, 56.52, 83.42);
        context.bezierCurveTo(81.47, 87.65, 95.33, 97.16, 116.82, 89.76);
        context.bezierCurveTo(138.31, 82.36, 150.78, 86.06, 150.78, 86.06);
        context.lineTo(134.5, 137.32);
        context.lineTo(46.46, 148.41);
        context.lineTo(9.73, 135.73);
        context.lineTo(13.19, 99.27);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 434 Drawing
        context.beginPath();
        context.moveTo(51.24, 85.51);
        context.bezierCurveTo(51.24, 85.51, 49.33, 78.53, 50.91, 75.78);
        context.bezierCurveTo(52.08, 73.75, 53.4, 72.79, 52.41, 69.13);
        context.bezierCurveTo(51.41, 65.48, 53.75, 64.25, 54.24, 63.32);
        context.bezierCurveTo(55.07, 61.74, 55.9, 59.32, 51.24, 59.99);
        context.bezierCurveTo(46.59, 60.66, 50.74, 65.64, 49.58, 67.8);
        context.bezierCurveTo(48.42, 69.97, 44.76, 72.96, 45.76, 77.12);
        context.bezierCurveTo(46.75, 81.27, 46.67, 85.6, 46.67, 85.6);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 435 Drawing
        context.beginPath();
        context.moveTo(37.28, 85.43);
        context.bezierCurveTo(37.28, 85.43, 39.77, 73.46, 39.27, 70.46);
        context.bezierCurveTo(38.79, 67.56, 34.78, 63.48, 37.28, 58.83);
        context.bezierCurveTo(39.77, 54.17, 31.46, 49.18, 34.95, 46.69);
        context.bezierCurveTo(38.44, 44.19, 41.93, 49.68, 41.6, 54.67);
        context.bezierCurveTo(41.27, 59.66, 41.6, 60.12, 43.1, 62.65);
        context.bezierCurveTo(44.43, 64.89, 46.17, 69.38, 43.18, 74.37);
        context.bezierCurveTo(40.19, 79.36, 42.43, 82.94, 42.43, 82.94);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 436 Drawing
        context.beginPath();
        context.moveTo(103.33, 99.27);
        context.lineTo(108.64, 103.81);
        context.bezierCurveTo(108.85, 104, 108.91, 104.32, 108.76, 104.56);
        context.lineTo(104.06, 112.63);
        context.bezierCurveTo(103.77, 113.11, 104.27, 113.69, 104.79, 113.48);
        context.lineTo(115.85, 109.08);
        context.bezierCurveTo(116.04, 109, 116.26, 109.04, 116.43, 109.16);
        context.lineTo(124.38, 115.19);
        context.bezierCurveTo(124.84, 115.54, 125.47, 115.11, 125.31, 114.56);
        context.lineTo(122.83, 105.95);
        context.bezierCurveTo(122.73, 105.6, 122.96, 105.25, 123.31, 105.2);
        context.lineTo(129.13, 104.28);
        context.bezierCurveTo(129.72, 104.19, 129.82, 103.39, 129.28, 103.15);
        context.lineTo(122.79, 100.28);
        context.bezierCurveTo(122.6, 100.2, 122.46, 100.02, 122.44, 99.81);
        context.lineTo(121.76, 93.51);
        context.bezierCurveTo(121.71, 93.06, 121.19, 92.83, 120.82, 93.1);
        context.lineTo(113.34, 98.53);
        context.bezierCurveTo(113.23, 98.61, 113.09, 98.65, 112.96, 98.64);
        context.lineTo(103.75, 98.23);
        context.bezierCurveTo(103.18, 98.2, 102.91, 98.9, 103.33, 99.27);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 437 Drawing
        context.beginPath();
        context.moveTo(103.33, 99.27);
        context.lineTo(108.64, 103.81);
        context.bezierCurveTo(108.85, 104, 108.91, 104.32, 108.76, 104.56);
        context.lineTo(104.06, 112.63);
        context.bezierCurveTo(103.77, 113.11, 104.27, 113.69, 104.79, 113.48);
        context.lineTo(115.85, 109.08);
        context.bezierCurveTo(116.04, 109, 116.26, 109.04, 116.43, 109.16);
        context.lineTo(124.38, 115.19);
        context.bezierCurveTo(124.84, 115.54, 125.47, 115.11, 125.31, 114.56);
        context.lineTo(122.83, 105.95);
        context.bezierCurveTo(122.73, 105.6, 122.96, 105.25, 123.31, 105.2);
        context.lineTo(129.13, 104.28);
        context.bezierCurveTo(129.72, 104.19, 129.82, 103.39, 129.28, 103.15);
        context.lineTo(122.79, 100.28);
        context.bezierCurveTo(122.6, 100.2, 122.46, 100.02, 122.44, 99.81);
        context.lineTo(121.76, 93.51);
        context.bezierCurveTo(121.71, 93.06, 121.19, 92.83, 120.82, 93.1);
        context.lineTo(113.34, 98.53);
        context.bezierCurveTo(113.23, 98.61, 113.09, 98.65, 112.96, 98.64);
        context.lineTo(103.75, 98.23);
        context.bezierCurveTo(103.18, 98.2, 102.91, 98.9, 103.33, 99.27);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 438 Drawing
        context.beginPath();
        context.moveTo(41.48, 95.57);
        context.bezierCurveTo(41.48, 95.57, 69.45, 96.14, 71.99, 95.57);
        context.bezierCurveTo(73.82, 95.15, 74.9, 89.75, 70.74, 84.93);
        context.bezierCurveTo(66.58, 80.11, 61.1, 75.95, 53.95, 77.94);
        context.bezierCurveTo(46.8, 79.94, 39.98, 84.6, 39.98, 84.6);
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 439 Drawing
        context.beginPath();
        context.moveTo(31.17, 84.93);
        context.bezierCurveTo(31.17, 84.93, 22.69, 89.75, 28.68, 93.24);
        context.bezierCurveTo(33.17, 95.86, 40.65, 96.51, 44.88, 95.42);
        context.bezierCurveTo(46.29, 95.05, 47.34, 94.49, 47.8, 93.74);
        context.bezierCurveTo(49.63, 90.75, 46.47, 86.92, 41.15, 83.27);
        context.bezierCurveTo(35.83, 79.61, 34.08, 83.18, 31.17, 84.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 440 Drawing
        context.beginPath();
        context.moveTo(31.17, 84.93);
        context.bezierCurveTo(31.17, 84.93, 22.69, 89.75, 28.68, 93.24);
        context.bezierCurveTo(33.17, 95.86, 40.65, 96.51, 44.88, 95.42);
        context.bezierCurveTo(46.29, 95.05, 47.34, 94.49, 47.8, 93.74);
        context.bezierCurveTo(49.63, 90.75, 46.47, 86.92, 41.15, 83.27);
        context.bezierCurveTo(35.83, 79.61, 34.08, 83.18, 31.17, 84.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawConstellationsBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';

        //// Group 24
        context.save();

        //// Clip Clip 22

        context.beginPath();
        context.moveTo(41.49, 1.01);
        context.bezierCurveTo(39.5, 1.01, 37.66, 2.07, 36.67, 3.8);
        context.lineTo(36.67, 3.8);
        context.lineTo(2.09, 63.68);
        context.bezierCurveTo(1.1, 65.41, 1.1, 67.53, 2.09, 69.25);
        context.lineTo(2.09, 69.25);
        context.lineTo(36.67, 129.14);
        context.bezierCurveTo(37.66, 130.86, 39.5, 131.93, 41.49, 131.93);
        context.lineTo(41.49, 131.93);
        context.lineTo(110.64, 131.93);
        context.bezierCurveTo(112.63, 131.93, 114.47, 130.86, 115.47, 129.14);
        context.lineTo(115.47, 129.14);
        context.lineTo(150.04, 69.25);
        context.bezierCurveTo(151.04, 67.53, 151.04, 65.41, 150.04, 63.68);
        context.lineTo(150.04, 63.68);
        context.lineTo(115.47, 3.8);
        context.bezierCurveTo(114.47, 2.07, 112.63, 1.01, 110.64, 1.01);
        context.lineTo(110.64, 1.01);
        context.lineTo(41.49, 1.01);
        context.closePath();
        context.clip();



        //// Bezier 685 Drawing
        context.beginPath();
        context.moveTo(31.09, -3.54);
        context.lineTo(-5.26, 65.19);
        context.lineTo(34.86, 136.35);
        context.lineTo(119.99, 135.02);
        context.lineTo(160.34, 59.65);
        context.lineTo(115.56, -5.09);
        context.lineTo(31.09, -3.54);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 686 Drawing
        context.beginPath();
        context.moveTo(53.72, 35.89);
        context.lineTo(48.16, 39.57);
        context.lineTo(49.6, 33);
        context.lineTo(44.73, 28.99);
        context.lineTo(50.94, 28.47);
        context.lineTo(53.72, 22.46);
        context.lineTo(56.5, 28.47);
        context.lineTo(62.72, 28.99);
        context.lineTo(57.84, 33.38);
        context.lineTo(59.28, 39.57);
        context.lineTo(53.72, 35.89);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 687 Drawing
        context.beginPath();
        context.moveTo(53.72, 35.89);
        context.lineTo(48.16, 39.57);
        context.lineTo(49.6, 33);
        context.lineTo(44.73, 28.99);
        context.lineTo(50.94, 28.47);
        context.lineTo(53.72, 22.46);
        context.lineTo(56.5, 28.47);
        context.lineTo(62.72, 28.99);
        context.lineTo(57.84, 33.38);
        context.lineTo(59.28, 39.57);
        context.lineTo(53.72, 35.89);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 688 Drawing
        context.beginPath();
        context.moveTo(32.13, 63.04);
        context.lineTo(28.55, 65.41);
        context.lineTo(29.48, 61.18);
        context.lineTo(26.33, 58.6);
        context.lineTo(30.34, 58.26);
        context.lineTo(32.13, 54.39);
        context.lineTo(33.92, 58.26);
        context.lineTo(37.93, 58.6);
        context.lineTo(34.79, 61.42);
        context.lineTo(35.71, 65.41);
        context.lineTo(32.13, 63.04);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 689 Drawing
        context.beginPath();
        context.moveTo(32.13, 63.04);
        context.lineTo(28.55, 65.41);
        context.lineTo(29.48, 61.18);
        context.lineTo(26.33, 58.6);
        context.lineTo(30.34, 58.26);
        context.lineTo(32.13, 54.39);
        context.lineTo(33.92, 58.26);
        context.lineTo(37.93, 58.6);
        context.lineTo(34.79, 61.42);
        context.lineTo(35.71, 65.41);
        context.lineTo(32.13, 63.04);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 690 Drawing
        context.beginPath();
        context.moveTo(63.04, 84.25);
        context.lineTo(58.16, 87.48);
        context.lineTo(59.43, 81.72);
        context.lineTo(55.15, 78.2);
        context.lineTo(60.6, 77.74);
        context.lineTo(63.04, 72.47);
        context.lineTo(65.48, 77.74);
        context.lineTo(70.93, 78.2);
        context.lineTo(66.65, 82.05);
        context.lineTo(67.91, 87.48);
        context.lineTo(63.04, 84.25);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 691 Drawing
        context.beginPath();
        context.moveTo(63.04, 84.25);
        context.lineTo(58.16, 87.48);
        context.lineTo(59.43, 81.72);
        context.lineTo(55.15, 78.2);
        context.lineTo(60.6, 77.74);
        context.lineTo(63.04, 72.47);
        context.lineTo(65.48, 77.74);
        context.lineTo(70.93, 78.2);
        context.lineTo(66.65, 82.05);
        context.lineTo(67.91, 87.48);
        context.lineTo(63.04, 84.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 692 Drawing
        context.beginPath();
        context.moveTo(27.77, 109.52);
        context.lineTo(25.72, 110.87);
        context.lineTo(26.25, 108.45);
        context.lineTo(24.45, 106.97);
        context.lineTo(26.75, 106.78);
        context.lineTo(27.77, 104.56);
        context.lineTo(28.8, 106.78);
        context.lineTo(31.09, 106.97);
        context.lineTo(29.29, 108.59);
        context.lineTo(29.82, 110.87);
        context.lineTo(27.77, 109.52);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 693 Drawing
        context.beginPath();
        context.moveTo(27.77, 109.52);
        context.lineTo(25.72, 110.87);
        context.lineTo(26.25, 108.45);
        context.lineTo(24.45, 106.97);
        context.lineTo(26.75, 106.78);
        context.lineTo(27.77, 104.56);
        context.lineTo(28.8, 106.78);
        context.lineTo(31.09, 106.97);
        context.lineTo(29.29, 108.59);
        context.lineTo(29.82, 110.87);
        context.lineTo(27.77, 109.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 694 Drawing
        context.beginPath();
        context.moveTo(22.44, 28.72);
        context.lineTo(19.94, 30.37);
        context.lineTo(20.59, 27.42);
        context.lineTo(18.39, 25.61);
        context.lineTo(21.19, 25.38);
        context.lineTo(22.44, 22.67);
        context.lineTo(23.69, 25.38);
        context.lineTo(26.49, 25.61);
        context.lineTo(24.3, 27.59);
        context.lineTo(24.95, 30.37);
        context.lineTo(22.44, 28.72);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 695 Drawing
        context.beginPath();
        context.moveTo(22.44, 28.72);
        context.lineTo(19.94, 30.37);
        context.lineTo(20.59, 27.42);
        context.lineTo(18.39, 25.61);
        context.lineTo(21.19, 25.38);
        context.lineTo(22.44, 22.67);
        context.lineTo(23.69, 25.38);
        context.lineTo(26.49, 25.61);
        context.lineTo(24.3, 27.59);
        context.lineTo(24.95, 30.37);
        context.lineTo(22.44, 28.72);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 696 Drawing
        context.beginPath();
        context.moveTo(80.33, 7.57);
        context.lineTo(76.75, 9.94);
        context.lineTo(77.67, 5.7);
        context.lineTo(74.53, 3.12);
        context.lineTo(78.54, 2.78);
        context.lineTo(80.33, -1.09);
        context.lineTo(82.12, 2.78);
        context.lineTo(86.13, 3.12);
        context.lineTo(82.98, 5.95);
        context.lineTo(83.91, 9.94);
        context.lineTo(80.33, 7.57);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 697 Drawing
        context.beginPath();
        context.moveTo(80.33, 7.57);
        context.lineTo(76.75, 9.94);
        context.lineTo(77.67, 5.7);
        context.lineTo(74.53, 3.12);
        context.lineTo(78.54, 2.78);
        context.lineTo(80.33, -1.09);
        context.lineTo(82.12, 2.78);
        context.lineTo(86.13, 3.12);
        context.lineTo(82.98, 5.95);
        context.lineTo(83.91, 9.94);
        context.lineTo(80.33, 7.57);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 698 Drawing
        context.beginPath();
        context.moveTo(85.87, 48.94);
        context.lineTo(83.39, 50.58);
        context.lineTo(84.03, 47.65);
        context.lineTo(81.86, 45.87);
        context.lineTo(84.63, 45.63);
        context.lineTo(85.87, 42.95);
        context.lineTo(87.11, 45.63);
        context.lineTo(89.88, 45.87);
        context.lineTo(87.71, 47.82);
        context.lineTo(88.35, 50.58);
        context.lineTo(85.87, 48.94);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 699 Drawing
        context.beginPath();
        context.moveTo(85.87, 48.94);
        context.lineTo(83.39, 50.58);
        context.lineTo(84.03, 47.65);
        context.lineTo(81.86, 45.87);
        context.lineTo(84.63, 45.63);
        context.lineTo(85.87, 42.95);
        context.lineTo(87.11, 45.63);
        context.lineTo(89.88, 45.87);
        context.lineTo(87.71, 47.82);
        context.lineTo(88.35, 50.58);
        context.lineTo(85.87, 48.94);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 700 Drawing
        context.beginPath();
        context.moveTo(105.86, 29.14);
        context.lineTo(101.8, 31.83);
        context.lineTo(102.85, 27.03);
        context.lineTo(99.29, 24.11);
        context.lineTo(103.83, 23.72);
        context.lineTo(105.86, 19.33);
        context.lineTo(107.89, 23.72);
        context.lineTo(112.43, 24.11);
        context.lineTo(108.87, 27.31);
        context.lineTo(109.92, 31.83);
        context.lineTo(105.86, 29.14);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 701 Drawing
        context.beginPath();
        context.moveTo(105.86, 29.14);
        context.lineTo(101.8, 31.83);
        context.lineTo(102.85, 27.03);
        context.lineTo(99.29, 24.11);
        context.lineTo(103.83, 23.72);
        context.lineTo(105.86, 19.33);
        context.lineTo(107.89, 23.72);
        context.lineTo(112.43, 24.11);
        context.lineTo(108.87, 27.31);
        context.lineTo(109.92, 31.83);
        context.lineTo(105.86, 29.14);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 702 Drawing
        context.beginPath();
        context.moveTo(59.68, 120.78);
        context.lineTo(57.29, 122.36);
        context.lineTo(57.91, 119.53);
        context.lineTo(55.81, 117.81);
        context.lineTo(58.49, 117.58);
        context.lineTo(59.68, 115);
        context.lineTo(60.88, 117.58);
        context.lineTo(63.55, 117.81);
        context.lineTo(61.45, 119.7);
        context.lineTo(62.07, 122.36);
        context.lineTo(59.68, 120.78);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 703 Drawing
        context.beginPath();
        context.moveTo(59.68, 120.78);
        context.lineTo(57.29, 122.36);
        context.lineTo(57.91, 119.53);
        context.lineTo(55.81, 117.81);
        context.lineTo(58.49, 117.58);
        context.lineTo(59.68, 115);
        context.lineTo(60.88, 117.58);
        context.lineTo(63.55, 117.81);
        context.lineTo(61.45, 119.7);
        context.lineTo(62.07, 122.36);
        context.lineTo(59.68, 120.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 704 Drawing
        context.beginPath();
        context.moveTo(90.74, 101.06);
        context.lineTo(87.15, 103.43);
        context.lineTo(88.08, 99.2);
        context.lineTo(84.94, 96.62);
        context.lineTo(88.95, 96.28);
        context.lineTo(90.74, 92.41);
        context.lineTo(92.53, 96.28);
        context.lineTo(96.53, 96.62);
        context.lineTo(93.39, 99.44);
        context.lineTo(94.32, 103.43);
        context.lineTo(90.74, 101.06);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 705 Drawing
        context.beginPath();
        context.moveTo(90.74, 101.06);
        context.lineTo(87.15, 103.43);
        context.lineTo(88.08, 99.2);
        context.lineTo(84.94, 96.62);
        context.lineTo(88.95, 96.28);
        context.lineTo(90.74, 92.41);
        context.lineTo(92.53, 96.28);
        context.lineTo(96.53, 96.62);
        context.lineTo(93.39, 99.44);
        context.lineTo(94.32, 103.43);
        context.lineTo(90.74, 101.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 706 Drawing
        context.beginPath();
        context.moveTo(121.77, 67.36);
        context.lineTo(118.19, 69.73);
        context.lineTo(119.12, 65.5);
        context.lineTo(115.98, 62.92);
        context.lineTo(119.98, 62.58);
        context.lineTo(121.77, 58.71);
        context.lineTo(123.56, 62.58);
        context.lineTo(127.57, 62.92);
        context.lineTo(124.43, 65.75);
        context.lineTo(125.36, 69.73);
        context.lineTo(121.77, 67.36);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 707 Drawing
        context.beginPath();
        context.moveTo(121.77, 67.36);
        context.lineTo(118.19, 69.73);
        context.lineTo(119.12, 65.5);
        context.lineTo(115.98, 62.92);
        context.lineTo(119.98, 62.58);
        context.lineTo(121.77, 58.71);
        context.lineTo(123.56, 62.58);
        context.lineTo(127.57, 62.92);
        context.lineTo(124.43, 65.75);
        context.lineTo(125.36, 69.73);
        context.lineTo(121.77, 67.36);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 708 Drawing
        context.beginPath();
        context.moveTo(131.1, 100.68);
        context.lineTo(127.51, 103.05);
        context.lineTo(128.44, 98.82);
        context.lineTo(125.3, 96.23);
        context.lineTo(129.31, 95.9);
        context.lineTo(131.1, 92.02);
        context.lineTo(132.89, 95.9);
        context.lineTo(136.89, 96.23);
        context.lineTo(133.75, 99.06);
        context.lineTo(134.68, 103.05);
        context.lineTo(131.1, 100.68);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 709 Drawing
        context.beginPath();
        context.moveTo(131.1, 100.68);
        context.lineTo(127.51, 103.05);
        context.lineTo(128.44, 98.82);
        context.lineTo(125.3, 96.23);
        context.lineTo(129.31, 95.9);
        context.lineTo(131.1, 92.02);
        context.lineTo(132.89, 95.9);
        context.lineTo(136.89, 96.23);
        context.lineTo(133.75, 99.06);
        context.lineTo(134.68, 103.05);
        context.lineTo(131.1, 100.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 710 Drawing
        context.beginPath();
        context.moveTo(104.51, 133.48);
        context.lineTo(102.02, 135.12);
        context.lineTo(102.67, 132.19);
        context.lineTo(100.49, 130.4);
        context.lineTo(103.27, 130.17);
        context.lineTo(104.51, 127.48);
        context.lineTo(105.75, 130.17);
        context.lineTo(108.53, 130.4);
        context.lineTo(106.35, 132.36);
        context.lineTo(106.99, 135.12);
        context.lineTo(104.51, 133.48);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 711 Drawing
        context.beginPath();
        context.moveTo(104.51, 133.48);
        context.lineTo(102.02, 135.12);
        context.lineTo(102.67, 132.19);
        context.lineTo(100.49, 130.4);
        context.lineTo(103.27, 130.17);
        context.lineTo(104.51, 127.48);
        context.lineTo(105.75, 130.17);
        context.lineTo(108.53, 130.4);
        context.lineTo(106.35, 132.36);
        context.lineTo(106.99, 135.12);
        context.lineTo(104.51, 133.48);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 712 Drawing
        context.beginPath();
        context.moveTo(135.74, 40.32);
        context.lineTo(133.47, 41.82);
        context.lineTo(134.06, 39.14);
        context.lineTo(132.07, 37.5);
        context.lineTo(134.6, 37.29);
        context.lineTo(135.74, 34.84);
        context.lineTo(136.87, 37.29);
        context.lineTo(139.41, 37.5);
        context.lineTo(137.42, 39.29);
        context.lineTo(138.01, 41.82);
        context.lineTo(135.74, 40.32);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 713 Drawing
        context.beginPath();
        context.moveTo(135.74, 40.32);
        context.lineTo(133.47, 41.82);
        context.lineTo(134.06, 39.14);
        context.lineTo(132.07, 37.5);
        context.lineTo(134.6, 37.29);
        context.lineTo(135.74, 34.84);
        context.lineTo(136.87, 37.29);
        context.lineTo(139.41, 37.5);
        context.lineTo(137.42, 39.29);
        context.lineTo(138.01, 41.82);
        context.lineTo(135.74, 40.32);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 714 Drawing
        context.beginPath();
        context.moveTo(24.3, 27.59);
        context.lineTo(44.73, 28.99);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 715 Drawing
        context.beginPath();
        context.moveTo(56.5, 28.47);
        context.lineTo(77.67, 5.7);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 716 Drawing
        context.beginPath();
        context.moveTo(34.79, 61.42);
        context.lineTo(60.6, 77.74);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 717 Drawing
        context.beginPath();
        context.moveTo(32.13, 63.04);
        context.lineTo(27.77, 104.56);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 718 Drawing
        context.beginPath();
        context.moveTo(31.09, 106.97);
        context.lineTo(58.16, 87.48);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 719 Drawing
        context.beginPath();
        context.moveTo(63.04, 84.25);
        context.lineTo(59.68, 115);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 720 Drawing
        context.beginPath();
        context.moveTo(55.81, 117.81);
        context.lineTo(29.29, 108.59);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 721 Drawing
        context.beginPath();
        context.moveTo(65.48, 77.75);
        context.lineTo(83.73, 49.91);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 722 Drawing
        context.beginPath();
        context.moveTo(108.87, 27.31);
        context.lineTo(132.07, 37.5);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 723 Drawing
        context.beginPath();
        context.moveTo(105.86, 29.14);
        context.lineTo(119.98, 62.58);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 724 Drawing
        context.beginPath();
        context.moveTo(123.56, 62.58);
        context.lineTo(135.74, 40.32);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 725 Drawing
        context.beginPath();
        context.moveTo(129.31, 95.9);
        context.lineTo(121.77, 67.36);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 726 Drawing
        context.beginPath();
        context.moveTo(90.74, 101.06);
        context.lineTo(103.27, 130.17);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 93 Drawing
        oval(context, 29.6, 42.24, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 94 Drawing
        oval(context, 29.6, 42.24, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 95 Drawing
        oval(context, 43.05, 74.84, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 96 Drawing
        oval(context, 43.05, 74.84, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 97 Drawing
        oval(context, 68.15, 108.59, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 98 Drawing
        oval(context, 68.15, 108.59, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 99 Drawing
        oval(context, 95.1, 87.29, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 100 Drawing
        oval(context, 95.1, 87.29, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 727 Drawing
        context.beginPath();
        context.moveTo(96.69, 71.6);
        context.lineTo(94.42, 73.1);
        context.lineTo(95.01, 70.42);
        context.lineTo(93.02, 68.79);
        context.lineTo(95.55, 68.57);
        context.lineTo(96.69, 66.12);
        context.lineTo(97.83, 68.57);
        context.lineTo(100.36, 68.79);
        context.lineTo(98.37, 70.58);
        context.lineTo(98.96, 73.1);
        context.lineTo(96.69, 71.6);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 728 Drawing
        context.beginPath();
        context.moveTo(96.69, 71.6);
        context.lineTo(94.42, 73.1);
        context.lineTo(95.01, 70.42);
        context.lineTo(93.02, 68.79);
        context.lineTo(95.55, 68.57);
        context.lineTo(96.69, 66.12);
        context.lineTo(97.83, 68.57);
        context.lineTo(100.36, 68.79);
        context.lineTo(98.37, 70.58);
        context.lineTo(98.96, 73.1);
        context.lineTo(96.69, 71.6);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 729 Drawing
        context.beginPath();
        context.moveTo(111.51, 2.08);
        context.lineTo(109.24, 3.59);
        context.lineTo(109.83, 0.9);
        context.lineTo(107.84, -0.73);
        context.lineTo(110.37, -0.95);
        context.lineTo(111.51, -3.4);
        context.lineTo(112.64, -0.95);
        context.lineTo(115.18, -0.73);
        context.lineTo(113.19, 1.06);
        context.lineTo(113.78, 3.59);
        context.lineTo(111.51, 2.08);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        //// Bezier 730 Drawing
        context.beginPath();
        context.moveTo(111.51, 2.08);
        context.lineTo(109.24, 3.59);
        context.lineTo(109.83, 0.9);
        context.lineTo(107.84, -0.73);
        context.lineTo(110.37, -0.95);
        context.lineTo(111.51, -3.4);
        context.lineTo(112.64, -0.95);
        context.lineTo(115.18, -0.73);
        context.lineTo(113.19, 1.06);
        context.lineTo(113.78, 3.59);
        context.lineTo(111.51, 2.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 731 Drawing
        context.beginPath();
        context.moveTo(96.69, 71.6);
        context.lineTo(90.74, 92.41);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 732 Drawing
        context.beginPath();
        context.moveTo(86.12, 3.12);
        context.lineTo(107.84, -0.73);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 101 Drawing
        oval(context, 71.95, 45.89, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 102 Drawing
        oval(context, 71.95, 45.89, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 103 Drawing
        oval(context, 87.45, 25.79, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 104 Drawing
        oval(context, 87.45, 25.79, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 105 Drawing
        oval(context, 134.7, 57.84, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 106 Drawing
        oval(context, 134.7, 57.84, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 107 Drawing
        oval(context, 107.8, 106.89, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 108 Drawing
        oval(context, 107.8, 106.89, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 109 Drawing
        oval(context, 79.5, 70.04, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 110 Drawing
        oval(context, 79.5, 70.04, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 111 Drawing
        oval(context, 43.8, 11.04, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 112 Drawing
        oval(context, 43.8, 11.04, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 113 Drawing
        oval(context, 9.3, 69.34, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 114 Drawing
        oval(context, 9.3, 69.34, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 115 Drawing
        oval(context, 122.65, 18.14, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 116 Drawing
        oval(context, 122.65, 18.14, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 117 Drawing
        oval(context, 41.85, 125.89, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 118 Drawing
        oval(context, 41.85, 125.89, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 119 Drawing
        oval(context, 138.55, 84.64, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 120 Drawing
        oval(context, 138.55, 84.64, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 121 Drawing
        oval(context, 93.05, 124.89, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 122 Drawing
        oval(context, 93.05, 124.89, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 123 Drawing
        oval(context, 106.4, 45.24, 3.1, 3.1);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 124 Drawing
        oval(context, 106.4, 45.24, 3.1, 3.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawArtBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor25 = 'rgba(185, 146, 111, 1)';

        //// Group 20
        context.save();

        //// Clip Clip 18

        context.beginPath();
        context.moveTo(41.71, 0.28);
        context.bezierCurveTo(39.72, 0.28, 37.88, 1.35, 36.89, 3.07);
        context.lineTo(36.89, 3.07);
        context.lineTo(2.31, 62.96);
        context.bezierCurveTo(1.31, 64.68, 1.31, 66.8, 2.31, 68.53);
        context.lineTo(2.31, 68.53);
        context.lineTo(36.89, 128.41);
        context.bezierCurveTo(37.88, 130.14, 39.72, 131.2, 41.71, 131.2);
        context.lineTo(41.71, 131.2);
        context.lineTo(110.86, 131.2);
        context.bezierCurveTo(112.85, 131.2, 114.69, 130.14, 115.68, 128.41);
        context.lineTo(115.68, 128.41);
        context.lineTo(150.26, 68.53);
        context.bezierCurveTo(151.26, 66.8, 151.26, 64.68, 150.26, 62.96);
        context.lineTo(150.26, 62.96);
        context.lineTo(115.68, 3.07);
        context.bezierCurveTo(114.69, 1.35, 112.85, 0.28, 110.86, 0.28);
        context.lineTo(110.86, 0.28);
        context.lineTo(41.71, 0.28);
        context.closePath();
        context.clip();



        //// Bezier 487 Drawing
        context.beginPath();
        context.moveTo(34.64, -3.21);
        context.lineTo(-4.02, 66.96);
        context.lineTo(32.56, 132.14);
        context.lineTo(117.36, 135.79);
        context.lineTo(155.27, 62.97);
        context.lineTo(115.7, -3.87);
        context.lineTo(34.64, -3.21);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col16;
        context.fill();


        //// Bezier 488 Drawing
        context.beginPath();
        context.moveTo(34.64, -3.21);
        context.lineTo(-4.02, 66.96);
        context.lineTo(32.56, 132.14);
        context.lineTo(117.36, 135.79);
        context.lineTo(155.27, 62.97);
        context.lineTo(115.7, -3.87);
        context.lineTo(34.64, -3.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 489 Drawing
        context.beginPath();
        context.moveTo(77.49, 4.37);
        context.bezierCurveTo(77.49, 4.37, 69.56, 1.85, 69.77, -0.69);
        context.bezierCurveTo(69.97, -3.23, 63.22, -7.71, 58.67, -0.61);
        context.bezierCurveTo(54.11, 6.48, 51.42, 3.66, 51.36, 12.14);
        context.bezierCurveTo(51.29, 20.62, 54.83, 26.22, 60.96, 26.87);
        context.bezierCurveTo(67.09, 27.51, 62.89, 23.24, 67.9, 20.68);
        context.bezierCurveTo(72.91, 18.11, 74.42, 22.89, 79.86, 20.05);
        context.bezierCurveTo(85.3, 17.22, 88.85, 8.04, 77.49, 4.37);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 490 Drawing
        context.beginPath();
        context.moveTo(39.05, 53.65);
        context.bezierCurveTo(39.05, 53.65, 35.74, 61.77, 28.68, 58.7);
        context.bezierCurveTo(21.61, 55.63, 16.39, 64.05, 18.52, 69.33);
        context.bezierCurveTo(20.65, 74.62, 22.65, 85.41, 38.76, 82.76);
        context.bezierCurveTo(54.87, 80.12, 44.47, 68.91, 51.09, 64.12);
        context.bezierCurveTo(57.71, 59.34, 54.88, 53.9, 50.59, 49.97);
        context.bezierCurveTo(46.3, 46.05, 39.05, 53.65, 39.05, 53.65);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 491 Drawing
        context.beginPath();
        context.moveTo(48.89, 103.47);
        context.bezierCurveTo(48.89, 103.47, 38.92, 103.07, 36.24, 119.83);
        context.bezierCurveTo(33.57, 136.58, 46.98, 141.82, 54.18, 135.69);
        context.bezierCurveTo(61.38, 129.56, 88.7, 109.13, 48.89, 103.47);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 492 Drawing
        context.beginPath();
        context.moveTo(129.9, 24.12);
        context.bezierCurveTo(128.88, 23.78, 118.08, 19.24, 113.45, 23.37);
        context.bezierCurveTo(108.83, 27.49, 99.93, 35.83, 110.7, 40.47);
        context.bezierCurveTo(121.47, 45.12, 121.34, 46.94, 124.9, 49.59);
        context.bezierCurveTo(128.46, 52.23, 141.72, 51.16, 143.14, 44.84);
        context.bezierCurveTo(144.57, 38.51, 140.56, 27.64, 129.9, 24.12);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 493 Drawing
        context.beginPath();
        context.moveTo(84.52, 57.89);
        context.bezierCurveTo(84.52, 57.89, 76.44, 58.82, 74.36, 55.05);
        context.bezierCurveTo(72.28, 51.29, 81.12, 49.44, 81.12, 49.44);
        context.bezierCurveTo(81.12, 49.44, 88.69, 47.49, 90.23, 52.04);
        context.bezierCurveTo(91.76, 56.6, 84.52, 57.89, 84.52, 57.89);
        context.closePath();
        context.moveTo(124.91, 43.58);
        context.bezierCurveTo(124.91, 43.58, 94.62, 17.16, 70.32, 26.68);
        context.bezierCurveTo(70.32, 26.68, 50.91, 34.02, 58.3, 44.29);
        context.bezierCurveTo(65.68, 54.56, 67.65, 55.55, 65.46, 57.99);
        context.bezierCurveTo(63.26, 60.42, 50.86, 56.16, 50.86, 56.16);
        context.bezierCurveTo(50.86, 56.16, 42.49, 51.89, 38.28, 59.18);
        context.bezierCurveTo(34.07, 66.47, 32.87, 78.28, 38.82, 84.38);
        context.bezierCurveTo(44.76, 90.48, 48.58, 109.28, 93.53, 116.13);
        context.bezierCurveTo(93.53, 116.13, 110.2, 121.72, 139.98, 96.04);
        context.bezierCurveTo(139.98, 96.04, 162.21, 73.78, 124.91, 43.58);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Bezier 494 Drawing
        context.beginPath();
        context.moveTo(84.52, 57.89);
        context.bezierCurveTo(84.52, 57.89, 76.44, 58.82, 74.36, 55.05);
        context.bezierCurveTo(72.28, 51.29, 81.12, 49.44, 81.12, 49.44);
        context.bezierCurveTo(81.12, 49.44, 88.69, 47.49, 90.23, 52.04);
        context.bezierCurveTo(91.76, 56.6, 84.52, 57.89, 84.52, 57.89);
        context.closePath();
        context.moveTo(124.91, 43.58);
        context.bezierCurveTo(124.91, 43.58, 94.62, 17.16, 70.32, 26.68);
        context.bezierCurveTo(70.32, 26.68, 50.91, 34.02, 58.3, 44.29);
        context.bezierCurveTo(65.68, 54.56, 67.65, 55.55, 65.46, 57.99);
        context.bezierCurveTo(63.26, 60.42, 50.86, 56.16, 50.86, 56.16);
        context.bezierCurveTo(50.86, 56.16, 42.49, 51.89, 38.28, 59.18);
        context.bezierCurveTo(34.07, 66.47, 32.87, 78.28, 38.82, 84.38);
        context.bezierCurveTo(44.76, 90.48, 48.58, 109.28, 93.53, 116.13);
        context.bezierCurveTo(93.53, 116.13, 110.2, 121.72, 139.98, 96.04);
        context.bezierCurveTo(139.98, 96.04, 162.21, 73.78, 124.91, 43.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 495 Drawing
        context.beginPath();
        context.moveTo(57.54, 69.56);
        context.bezierCurveTo(57.54, 69.56, 53.61, 68.31, 53.71, 67.05);
        context.bezierCurveTo(53.81, 65.79, 50.47, 63.58, 48.21, 67.09);
        context.bezierCurveTo(45.95, 70.61, 44.62, 69.21, 44.59, 73.41);
        context.bezierCurveTo(44.55, 77.62, 46.31, 80.39, 49.35, 80.71);
        context.bezierCurveTo(52.39, 81.03, 50.3, 78.91, 52.78, 77.64);
        context.bezierCurveTo(55.27, 76.37, 56.02, 78.74, 58.71, 77.33);
        context.bezierCurveTo(61.41, 75.93, 63.17, 71.38, 57.54, 69.56);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 496 Drawing
        context.beginPath();
        context.moveTo(57.54, 69.56);
        context.bezierCurveTo(57.54, 69.56, 53.61, 68.31, 53.71, 67.05);
        context.bezierCurveTo(53.81, 65.79, 50.47, 63.58, 48.21, 67.09);
        context.bezierCurveTo(45.95, 70.61, 44.62, 69.21, 44.59, 73.41);
        context.bezierCurveTo(44.55, 77.62, 46.31, 80.39, 49.35, 80.71);
        context.bezierCurveTo(52.39, 81.03, 50.3, 78.91, 52.78, 77.64);
        context.bezierCurveTo(55.27, 76.37, 56.02, 78.74, 58.71, 77.33);
        context.bezierCurveTo(61.41, 75.93, 63.17, 71.38, 57.54, 69.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 497 Drawing
        context.beginPath();
        context.moveTo(68.65, 83.6);
        context.bezierCurveTo(68.65, 83.6, 67.01, 87.63, 63.51, 86.1);
        context.bezierCurveTo(60, 84.58, 57.42, 88.76, 58.47, 91.38);
        context.bezierCurveTo(59.53, 93.99, 60.52, 99.34, 68.5, 98.03);
        context.bezierCurveTo(76.49, 96.72, 71.33, 91.17, 74.62, 88.79);
        context.bezierCurveTo(77.9, 86.42, 76.49, 83.73, 74.37, 81.78);
        context.bezierCurveTo(72.24, 79.84, 68.65, 83.6, 68.65, 83.6);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 498 Drawing
        context.beginPath();
        context.moveTo(68.65, 83.6);
        context.bezierCurveTo(68.65, 83.6, 67.01, 87.63, 63.51, 86.1);
        context.bezierCurveTo(60, 84.58, 57.42, 88.76, 58.47, 91.38);
        context.bezierCurveTo(59.53, 93.99, 60.52, 99.34, 68.5, 98.03);
        context.bezierCurveTo(76.49, 96.72, 71.33, 91.17, 74.62, 88.79);
        context.bezierCurveTo(77.9, 86.42, 76.49, 83.73, 74.37, 81.78);
        context.bezierCurveTo(72.24, 79.84, 68.65, 83.6, 68.65, 83.6);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 499 Drawing
        context.beginPath();
        context.moveTo(91.82, 89.01);
        context.bezierCurveTo(91.82, 89.01, 86.88, 88.81, 85.55, 97.12);
        context.bezierCurveTo(84.23, 105.42, 90.87, 108.02, 94.44, 104.98);
        context.bezierCurveTo(98.01, 101.94, 111.55, 91.82, 91.82, 89.01);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 500 Drawing
        context.beginPath();
        context.moveTo(91.82, 89.01);
        context.bezierCurveTo(91.82, 89.01, 86.88, 88.81, 85.55, 97.12);
        context.bezierCurveTo(84.23, 105.42, 90.87, 108.02, 94.44, 104.98);
        context.bezierCurveTo(98.01, 101.94, 111.55, 91.82, 91.82, 89.01);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 501 Drawing
        context.beginPath();
        context.moveTo(107.58, 36.01);
        context.bezierCurveTo(107.07, 35.84, 101.72, 33.58, 99.43, 35.63);
        context.bezierCurveTo(97.13, 37.68, 92.72, 41.81, 98.06, 44.11);
        context.bezierCurveTo(103.4, 46.41, 103.33, 47.31, 105.1, 48.63);
        context.bezierCurveTo(106.86, 49.94, 113.43, 49.41, 114.14, 46.27);
        context.bezierCurveTo(114.85, 43.14, 112.86, 37.75, 107.58, 36.01);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Bezier 502 Drawing
        context.beginPath();
        context.moveTo(107.58, 36.01);
        context.bezierCurveTo(107.07, 35.84, 101.72, 33.58, 99.43, 35.63);
        context.bezierCurveTo(97.13, 37.68, 92.72, 41.81, 98.06, 44.11);
        context.bezierCurveTo(103.4, 46.41, 103.33, 47.31, 105.1, 48.63);
        context.bezierCurveTo(106.86, 49.94, 113.43, 49.41, 114.14, 46.27);
        context.bezierCurveTo(114.85, 43.14, 112.86, 37.75, 107.58, 36.01);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 503 Drawing
        context.beginPath();
        context.moveTo(120.33, 48.04);
        context.bezierCurveTo(120.33, 48.04, 115.35, 50.21, 115.69, 53.58);
        context.bezierCurveTo(116.03, 56.95, 113.82, 54.99, 115.47, 58.65);
        context.bezierCurveTo(117.11, 62.32, 116.35, 66.91, 120.3, 67.25);
        context.bezierCurveTo(124.25, 67.59, 128.79, 71, 131.32, 66.26);
        context.bezierCurveTo(133.86, 61.52, 133.6, 56.16, 129.33, 53.18);
        context.bezierCurveTo(125.06, 50.2, 125.21, 47.12, 120.33, 48.04);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair12;
        context.fill();


        //// Bezier 504 Drawing
        context.beginPath();
        context.moveTo(120.33, 48.04);
        context.bezierCurveTo(120.33, 48.04, 115.35, 50.21, 115.69, 53.58);
        context.bezierCurveTo(116.03, 56.95, 113.82, 54.99, 115.47, 58.65);
        context.bezierCurveTo(117.11, 62.32, 116.35, 66.91, 120.3, 67.25);
        context.bezierCurveTo(124.25, 67.59, 128.79, 71, 131.32, 66.26);
        context.bezierCurveTo(133.86, 61.52, 133.6, 56.16, 129.33, 53.18);
        context.bezierCurveTo(125.06, 50.2, 125.21, 47.12, 120.33, 48.04);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 505 Drawing
        context.beginPath();
        context.moveTo(135.18, 71.89);
        context.bezierCurveTo(134.75, 72.22, 130.9, 76.23, 127.19, 74.85);
        context.bezierCurveTo(123.47, 73.46, 121.45, 75.93, 121.09, 79.15);
        context.bezierCurveTo(120.73, 82.36, 122.5, 85.87, 121.87, 88.66);
        context.bezierCurveTo(121.25, 91.44, 126.79, 95.26, 128.78, 92.96);
        context.bezierCurveTo(130.76, 90.66, 131.02, 90.72, 133.12, 89.91);
        context.bezierCurveTo(135.23, 89.11, 138.8, 89.91, 135.86, 86.32);
        context.bezierCurveTo(132.91, 82.73, 130.34, 81.96, 134.5, 82.17);
        context.bezierCurveTo(138.67, 82.38, 140.02, 77.19, 138.53, 74.48);
        context.bezierCurveTo(137.04, 71.76, 138.28, 69.48, 135.18, 71.89);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 506 Drawing
        context.beginPath();
        context.moveTo(135.18, 71.89);
        context.bezierCurveTo(134.75, 72.22, 130.9, 76.23, 127.19, 74.85);
        context.bezierCurveTo(123.47, 73.46, 121.45, 75.93, 121.09, 79.15);
        context.bezierCurveTo(120.73, 82.36, 122.5, 85.87, 121.87, 88.66);
        context.bezierCurveTo(121.25, 91.44, 126.79, 95.26, 128.78, 92.96);
        context.bezierCurveTo(130.76, 90.66, 131.02, 90.72, 133.12, 89.91);
        context.bezierCurveTo(135.23, 89.11, 138.8, 89.91, 135.86, 86.32);
        context.bezierCurveTo(132.91, 82.73, 130.34, 81.96, 134.5, 82.17);
        context.bezierCurveTo(138.67, 82.38, 140.02, 77.19, 138.53, 74.48);
        context.bezierCurveTo(137.04, 71.76, 138.28, 69.48, 135.18, 71.89);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 507 Drawing
        context.beginPath();
        context.moveTo(110.27, 92.63);
        context.bezierCurveTo(110.27, 92.63, 109.04, 97.66, 107.64, 98.99);
        context.bezierCurveTo(106.25, 100.32, 104.45, 105.75, 107.17, 106.36);
        context.bezierCurveTo(109.89, 106.97, 112.42, 108.67, 114.38, 107.28);
        context.bezierCurveTo(116.34, 105.9, 115.87, 106.34, 118.38, 104.16);
        context.bezierCurveTo(120.88, 101.98, 118.63, 96.16, 116.1, 93.76);
        context.bezierCurveTo(113.58, 91.36, 110.86, 90.02, 110.27, 92.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 508 Drawing
        context.beginPath();
        context.moveTo(110.27, 92.63);
        context.bezierCurveTo(110.27, 92.63, 109.04, 97.66, 107.64, 98.99);
        context.bezierCurveTo(106.25, 100.32, 104.45, 105.75, 107.17, 106.36);
        context.bezierCurveTo(109.89, 106.97, 112.42, 108.67, 114.38, 107.28);
        context.bezierCurveTo(116.34, 105.9, 115.87, 106.34, 118.38, 104.16);
        context.bezierCurveTo(120.88, 101.98, 118.63, 96.16, 116.1, 93.76);
        context.bezierCurveTo(113.58, 91.36, 110.86, 90.02, 110.27, 92.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 509 Drawing
        context.beginPath();
        context.moveTo(106.52, 86.11);
        context.bezierCurveTo(105.11, 88.62, 102.91, 89.14, 104.13, 91.06);
        context.bezierCurveTo(105.34, 92.98, 109.76, 92.88, 109.43, 89.88);
        context.bezierCurveTo(109.1, 86.87, 107.44, 84.49, 106.52, 86.11);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 510 Drawing
        context.beginPath();
        context.moveTo(106.52, 86.11);
        context.bezierCurveTo(105.11, 88.62, 102.91, 89.14, 104.13, 91.06);
        context.bezierCurveTo(105.34, 92.98, 109.76, 92.88, 109.43, 89.88);
        context.bezierCurveTo(109.1, 86.87, 107.44, 84.49, 106.52, 86.11);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 511 Drawing
        context.beginPath();
        context.moveTo(21.19, 7.36);
        context.bezierCurveTo(20.16, 7.02, 9.36, 2.48, 4.74, 6.6);
        context.bezierCurveTo(0.11, 10.73, -8.79, 19.07, 1.98, 23.71);
        context.bezierCurveTo(12.76, 28.36, 12.62, 30.18, 16.18, 32.83);
        context.bezierCurveTo(19.74, 35.47, 33, 34.4, 34.43, 28.08);
        context.bezierCurveTo(35.85, 21.75, 31.84, 10.88, 21.19, 7.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawWorldBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Group 2
        context.save();

        //// Clip Bezier 35

        context.beginPath();
        context.moveTo(110.52, 0.56);
        context.lineTo(41.37, 0.56);
        context.bezierCurveTo(39.38, 0.56, 37.54, 1.62, 36.54, 3.35);
        context.lineTo(1.97, 63.23);
        context.bezierCurveTo(0.97, 64.96, 0.97, 67.08, 1.97, 68.8);
        context.lineTo(36.54, 128.69);
        context.bezierCurveTo(37.54, 130.42, 39.38, 131.48, 41.37, 131.48);
        context.lineTo(110.52, 131.48);
        context.bezierCurveTo(112.51, 131.48, 114.35, 130.42, 115.34, 128.69);
        context.lineTo(149.92, 68.8);
        context.bezierCurveTo(150.91, 67.08, 150.91, 64.96, 149.92, 63.23);
        context.lineTo(115.34, 3.35);
        context.bezierCurveTo(114.35, 1.62, 112.51, 0.56, 110.52, 0.56);
        context.closePath();
        context.clip();



        //// Bezier 586 Drawing
        context.beginPath();
        context.moveTo(34.79, -8.36);
        context.lineTo(-10.43, 60.81);
        context.lineTo(36.57, 142.4);
        context.lineTo(122.58, 136.19);
        context.lineTo(158.94, 63.47);
        context.lineTo(120.81, -3.92);
        context.lineTo(34.79, -8.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Group 29
        //// Bezier 587 Drawing
        context.beginPath();
        context.moveTo(171.87, 36.31);
        context.bezierCurveTo(170.86, 36.39, 169.88, 36.57, 168.89, 36.84);
        context.bezierCurveTo(167.76, 37.14, 166.74, 37.59, 165.79, 38.24);
        context.bezierCurveTo(165.6, 38.38, 165.42, 38.57, 165.3, 38.78);
        context.bezierCurveTo(165.05, 39.24, 165.09, 39.42, 165.46, 39.8);
        context.bezierCurveTo(166.44, 40.79, 167.44, 41.75, 168.4, 42.76);
        context.bezierCurveTo(168.97, 43.35, 169.54, 43.97, 169.7, 44.83);
        context.bezierCurveTo(169.74, 45.03, 169.76, 45.27, 169.69, 45.45);
        context.bezierCurveTo(169.39, 46.25, 169, 47.02, 168.72, 47.82);
        context.bezierCurveTo(168.44, 48.66, 168.22, 49.53, 168.27, 50.43);
        context.bezierCurveTo(168.33, 51.49, 168.59, 52.49, 169.14, 53.4);
        context.bezierCurveTo(169.24, 53.56, 169.33, 53.73, 169.41, 53.91);
        context.bezierCurveTo(169.63, 54.34, 169.49, 54.58, 169.02, 54.54);
        context.bezierCurveTo(168.9, 54.53, 168.78, 54.5, 168.66, 54.45);
        context.bezierCurveTo(167.51, 53.99, 166.51, 53.36, 165.93, 52.2);
        context.bezierCurveTo(165.86, 52.06, 165.81, 51.91, 165.73, 51.77);
        context.bezierCurveTo(165.36, 51.17, 164.81, 51.01, 164.1, 51.2);
        context.bezierCurveTo(163.65, 51.31, 163.26, 51.49, 162.93, 51.81);
        context.bezierCurveTo(162.77, 51.95, 162.61, 52.11, 162.51, 52.29);
        context.bezierCurveTo(162.4, 52.5, 162.37, 52.73, 162.71, 52.8);
        context.bezierCurveTo(162.86, 52.83, 163, 52.92, 163.15, 52.96);
        context.bezierCurveTo(163.95, 53.17, 164.4, 53.72, 164.57, 54.48);
        context.bezierCurveTo(164.77, 55.32, 165.01, 56.13, 165.58, 56.8);
        context.bezierCurveTo(165.89, 57.16, 165.91, 57.62, 166.03, 58.04);
        context.bezierCurveTo(166.22, 58.68, 166.37, 59.32, 166.33, 60.01);
        context.bezierCurveTo(166.26, 61.69, 165.37, 62.63, 163.64, 62.7);
        context.bezierCurveTo(162.94, 62.73, 162.24, 62.8, 161.55, 62.9);
        context.bezierCurveTo(160.51, 63.06, 159.24, 64.46, 159.16, 65.51);
        context.bezierCurveTo(159.13, 65.97, 159.38, 66.29, 159.67, 66.61);
        context.bezierCurveTo(160.08, 67.06, 160.51, 67.5, 160.85, 67.99);
        context.bezierCurveTo(161.42, 68.81, 161.57, 69.74, 161.48, 70.73);
        context.bezierCurveTo(161.43, 71.32, 160.3, 72.5, 159.68, 72.57);
        context.bezierCurveTo(159.48, 72.59, 159.21, 72.53, 159.07, 72.39);
        context.bezierCurveTo(158.43, 71.76, 157.83, 71.1, 157.21, 70.44);
        context.bezierCurveTo(157.1, 70.33, 157.01, 70.19, 156.89, 70.09);
        context.bezierCurveTo(156.72, 69.96, 156.52, 69.85, 156.33, 69.73);
        context.bezierCurveTo(156.3, 69.95, 156.28, 70.16, 156.25, 70.38);
        context.bezierCurveTo(156.18, 70.72, 156.19, 71.11, 156.03, 71.39);
        context.bezierCurveTo(155.69, 71.96, 155.91, 72.48, 156.15, 72.92);
        context.bezierCurveTo(156.42, 73.41, 156.74, 73.89, 157.29, 74.19);
        context.bezierCurveTo(157.75, 74.44, 158.09, 74.86, 158.27, 75.43);
        context.bezierCurveTo(158.47, 76.02, 158.74, 76.63, 159.24, 77.07);
        context.bezierCurveTo(159.3, 77.11, 159.34, 77.26, 159.31, 77.32);
        context.bezierCurveTo(159.28, 77.38, 159.15, 77.43, 159.07, 77.43);
        context.bezierCurveTo(158.52, 77.45, 158.1, 77.14, 157.69, 76.84);
        context.bezierCurveTo(157.16, 76.45, 156.88, 75.87, 156.63, 75.27);
        context.bezierCurveTo(156.45, 74.84, 156.23, 74.41, 156, 74);
        context.bezierCurveTo(155.61, 73.3, 155.3, 72.6, 155.25, 71.77);
        context.bezierCurveTo(155.19, 70.53, 155.04, 69.3, 154.87, 68.07);
        context.bezierCurveTo(154.79, 67.42, 154.55, 67.25, 153.86, 67.16);
        context.bezierCurveTo(153.83, 67.16, 153.8, 67.16, 153.76, 67.16);
        context.bezierCurveTo(153.15, 67.19, 152.57, 67.16, 152.05, 66.73);
        context.bezierCurveTo(151.49, 66.27, 151.1, 65.7, 150.82, 65.04);
        context.bezierCurveTo(150.69, 64.72, 150.58, 64.37, 150.37, 64.11);
        context.bezierCurveTo(149.79, 63.4, 149.23, 63.31, 148.46, 63.81);
        context.bezierCurveTo(147.55, 64.4, 146.7, 65.06, 145.8, 65.67);
        context.bezierCurveTo(145.26, 66.03, 144.84, 66.49, 144.46, 67.01);
        context.bezierCurveTo(143.88, 67.8, 143.66, 68.69, 143.92, 69.62);
        context.bezierCurveTo(144.23, 70.75, 143.88, 71.7, 143.31, 72.61);
        context.bezierCurveTo(142.99, 73.12, 142.61, 73.15, 142.24, 72.62);
        context.bezierCurveTo(141.68, 71.81, 141.16, 70.96, 140.76, 70.07);
        context.bezierCurveTo(140.13, 68.66, 139.61, 67.19, 139.05, 65.75);
        context.bezierCurveTo(138.9, 65.37, 138.76, 64.98, 138.6, 64.6);
        context.bezierCurveTo(138.47, 64.28, 138.37, 63.94, 138.2, 63.64);
        context.bezierCurveTo(137.91, 63.13, 137.53, 62.8, 136.88, 62.88);
        context.bezierCurveTo(135.86, 63.01, 135.04, 62.59, 134.25, 61.97);
        context.bezierCurveTo(133.77, 61.6, 133.15, 61.36, 132.55, 61.17);
        context.bezierCurveTo(132.04, 61.01, 131.48, 61.05, 130.95, 60.94);
        context.bezierCurveTo(129.68, 60.7, 128.41, 60.48, 127.16, 60.15);
        context.bezierCurveTo(126.05, 59.85, 125.4, 58.9, 124.66, 58.11);
        context.bezierCurveTo(124.6, 58.04, 124.6, 57.91, 124.53, 57.85);
        context.bezierCurveTo(124.4, 57.73, 124.25, 57.64, 124.1, 57.53);
        context.bezierCurveTo(124.02, 57.7, 123.9, 57.85, 123.87, 58.03);
        context.bezierCurveTo(123.66, 59.08, 123.97, 60.02, 124.63, 60.82);
        context.bezierCurveTo(125.08, 61.37, 125.7, 61.45, 126.38, 61.25);
        context.bezierCurveTo(126.85, 61.11, 127.31, 60.7, 127.78, 60.91);
        context.bezierCurveTo(128.23, 61.12, 128.56, 61.56, 128.96, 61.87);
        context.bezierCurveTo(129.34, 62.17, 129.73, 62.43, 130.12, 62.71);
        context.bezierCurveTo(130.73, 63.13, 130.82, 63.54, 130.41, 64.17);
        context.bezierCurveTo(130.39, 64.2, 130.38, 64.22, 130.35, 64.25);
        context.bezierCurveTo(129.08, 65.48, 127.83, 66.73, 126.53, 67.93);
        context.bezierCurveTo(125.92, 68.5, 125.22, 68.94, 124.57, 69.46);
        context.bezierCurveTo(124.06, 69.88, 123.44, 70.03, 122.83, 70.19);
        context.bezierCurveTo(122.5, 70.28, 121.91, 69.98, 121.73, 69.67);
        context.bezierCurveTo(121.23, 68.81, 120.8, 67.91, 120.6, 66.95);
        context.bezierCurveTo(120.42, 66.09, 119.95, 65.44, 119.45, 64.78);
        context.bezierCurveTo(118.86, 64, 118.21, 63.25, 117.65, 62.44);
        context.bezierCurveTo(116.82, 61.23, 116.2, 59.9, 115.67, 58.53);
        context.bezierCurveTo(115.63, 58.43, 115.49, 58.38, 115.4, 58.3);
        context.bezierCurveTo(115.38, 58.39, 115.35, 58.48, 115.34, 58.58);
        context.bezierCurveTo(115.33, 58.64, 115.34, 58.71, 115.34, 58.77);
        context.bezierCurveTo(115.31, 58.96, 115.29, 59.14, 115.26, 59.33);
        context.bezierCurveTo(115.11, 59.22, 114.93, 59.12, 114.83, 58.98);
        context.bezierCurveTo(114.58, 58.61, 114.37, 58.21, 114.16, 57.82);
        context.bezierCurveTo(113.95, 57.4, 113.96, 57.4, 114.44, 57.26);
        context.bezierCurveTo(114.97, 57.1, 115.27, 56.69, 115.53, 56.24);
        context.bezierCurveTo(115.98, 55.49, 115.95, 54.65, 115.89, 53.82);
        context.bezierCurveTo(115.88, 53.66, 115.7, 53.5, 115.59, 53.35);
        context.bezierCurveTo(115.41, 53.09, 115.25, 53.36, 115.09, 53.39);
        context.bezierCurveTo(114.47, 53.49, 113.86, 53.67, 113.23, 53.75);
        context.bezierCurveTo(112.26, 53.86, 111.4, 53.52, 110.69, 52.88);
        context.bezierCurveTo(110.12, 52.37, 110.22, 51.64, 110.35, 50.98);
        context.bezierCurveTo(110.39, 50.78, 110.76, 50.58, 111.01, 50.5);
        context.bezierCurveTo(111.55, 50.31, 112.16, 50.29, 112.65, 50.04);
        context.bezierCurveTo(113.56, 49.59, 114.4, 49.72, 115.3, 50.05);
        context.bezierCurveTo(116.09, 50.35, 116.91, 50.42, 117.72, 50.08);
        context.bezierCurveTo(118.08, 49.94, 118.13, 49.87, 118.08, 49.4);
        context.bezierCurveTo(118.04, 48.98, 118.15, 48.82, 118.53, 48.93);
        context.bezierCurveTo(119.32, 49.15, 120.1, 49.4, 120.87, 49.67);
        context.bezierCurveTo(121.43, 49.88, 121.98, 50.14, 122.53, 50.39);
        context.bezierCurveTo(122.78, 50.51, 122.89, 50.42, 122.89, 50.17);
        context.bezierCurveTo(122.89, 49.91, 122.91, 49.64, 122.84, 49.4);
        context.bezierCurveTo(122.63, 48.68, 122.69, 47.98, 122.83, 47.24);
        context.bezierCurveTo(122.97, 46.5, 123.06, 45.7, 122.58, 45);
        context.bezierCurveTo(122.51, 44.9, 122.46, 44.77, 122.42, 44.66);
        context.bezierCurveTo(122.08, 43.82, 122.37, 43.42, 123.26, 43.49);
        context.bezierCurveTo(123.41, 43.5, 123.56, 43.57, 123.71, 43.61);
        context.bezierCurveTo(124.16, 43.72, 124.58, 43.72, 125.01, 43.5);
        context.bezierCurveTo(125.42, 43.28, 125.59, 42.98, 125.62, 42.51);
        context.bezierCurveTo(125.7, 41.28, 125.21, 40.2, 124.78, 39.12);
        context.bezierCurveTo(124.39, 38.13, 123.74, 37.3, 123.12, 36.44);
        context.bezierCurveTo(123.01, 36.29, 122.92, 36.12, 122.82, 35.96);
        context.bezierCurveTo(122.34, 35.23, 122.46, 34.71, 123.28, 34.39);
        context.bezierCurveTo(123.86, 34.17, 124.21, 33.77, 124.5, 33.26);
        context.bezierCurveTo(124.65, 33.02, 124.83, 32.79, 124.97, 32.54);
        context.bezierCurveTo(125.21, 32.11, 125.33, 31.69, 125.08, 31.18);
        context.bezierCurveTo(124.76, 30.5, 124.92, 30.28, 125.69, 30.3);
        context.bezierCurveTo(126.33, 30.31, 126.97, 30.21, 127.6, 30.46);
        context.bezierCurveTo(127.84, 30.55, 128.16, 30.45, 128.45, 30.42);
        context.bezierCurveTo(128.6, 30.4, 128.76, 30.28, 128.9, 30.3);
        context.bezierCurveTo(129.7, 30.42, 130.37, 30.04, 130.96, 29.64);
        context.bezierCurveTo(132.4, 28.64, 134.03, 28.12, 135.71, 27.81);
        context.bezierCurveTo(136.83, 27.6, 138, 27.42, 139.15, 27.59);
        context.bezierCurveTo(140.21, 27.74, 141.26, 27.97, 142.21, 28.53);
        context.bezierCurveTo(143.56, 29.32, 145.04, 29.75, 146.55, 30.08);
        context.bezierCurveTo(146.58, 30.09, 146.61, 30.09, 146.65, 30.09);
        context.bezierCurveTo(147.63, 30.16, 148.62, 30.24, 149.61, 30.28);
        context.bezierCurveTo(151.34, 30.36, 153.08, 30.32, 154.79, 30.52);
        context.bezierCurveTo(155.95, 30.66, 157.12, 30.49, 158.24, 30.72);
        context.bezierCurveTo(159.34, 30.95, 160.48, 30.64, 161.55, 31.06);
        context.bezierCurveTo(161.61, 31.08, 161.68, 31.06, 161.74, 31.06);
        context.bezierCurveTo(162.79, 31.13, 163.88, 31.06, 164.89, 31.3);
        context.bezierCurveTo(165.77, 31.51, 166.68, 31.26, 167.53, 31.63);
        context.bezierCurveTo(167.72, 31.71, 167.98, 31.65, 168.2, 31.65);
        context.bezierCurveTo(169.37, 31.65, 170.54, 31.55, 171.69, 31.67);
        context.bezierCurveTo(173.94, 31.91, 176.19, 31.77, 178.43, 31.86);
        context.bezierCurveTo(179.15, 31.88, 179.74, 32.23, 180.09, 32.95);
        context.bezierCurveTo(180.59, 33.98, 180.62, 34.94, 179.91, 35.89);
        context.bezierCurveTo(179.52, 36.42, 178.99, 36.74, 178.36, 36.89);
        context.bezierCurveTo(177.78, 37.03, 177.19, 37.15, 176.61, 37.28);
        context.bezierCurveTo(176.58, 37.29, 176.54, 37.27, 176.51, 37.28);
        context.bezierCurveTo(176.18, 37.38, 175.79, 37.45, 175.66, 37.82);
        context.bezierCurveTo(175.52, 38.21, 175.89, 38.38, 176.1, 38.61);
        context.bezierCurveTo(176.49, 39.07, 176.93, 39.5, 177.27, 40);
        context.bezierCurveTo(177.72, 40.67, 177.95, 41.44, 177.95, 42.25);
        context.bezierCurveTo(177.96, 42.83, 177.61, 43.06, 177.07, 42.85);
        context.bezierCurveTo(176.74, 42.72, 176.41, 42.56, 176.15, 42.34);
        context.bezierCurveTo(175.39, 41.69, 174.67, 41.01, 173.93, 40.34);
        context.bezierCurveTo(173.13, 39.61, 173.13, 38.7, 173.47, 37.78);
        context.bezierCurveTo(173.87, 36.71, 173.63, 36.33, 172.45, 36.31);
        context.bezierCurveTo(172.26, 36.3, 172.06, 36.31, 171.87, 36.31);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 588 Drawing
        context.beginPath();
        context.moveTo(99.87, 52.99);
        context.bezierCurveTo(100.15, 52.99, 100.35, 52.98, 100.54, 52.99);
        context.bezierCurveTo(101.29, 53, 101.51, 53.28, 101.42, 54.05);
        context.bezierCurveTo(101.38, 54.4, 101.37, 54.76, 101.37, 55.11);
        context.bezierCurveTo(101.38, 55.7, 101.52, 55.97, 102.09, 56.06);
        context.bezierCurveTo(103.38, 56.27, 104.49, 56.88, 105.62, 57.45);
        context.bezierCurveTo(106.25, 57.76, 106.6, 57.64, 106.93, 57.02);
        context.bezierCurveTo(107.03, 56.82, 107.1, 56.61, 107.18, 56.41);
        context.bezierCurveTo(107.43, 55.78, 107.94, 55.56, 108.55, 55.82);
        context.bezierCurveTo(108.94, 55.98, 109.31, 56.17, 109.67, 56.37);
        context.bezierCurveTo(110.6, 56.87, 111.58, 57.2, 112.65, 57.27);
        context.bezierCurveTo(113.17, 57.31, 113.6, 57.56, 113.79, 58.14);
        context.bezierCurveTo(114.06, 58.96, 114.52, 59.68, 115.15, 60.3);
        context.bezierCurveTo(115.64, 60.79, 116.03, 61.37, 116.35, 62.01);
        context.bezierCurveTo(117.19, 63.72, 117.95, 65.46, 118.65, 67.23);
        context.bezierCurveTo(118.86, 67.77, 119.26, 68.33, 119.72, 68.65);
        context.bezierCurveTo(120.56, 69.23, 121.01, 70.02, 121.37, 70.91);
        context.bezierCurveTo(121.44, 71.09, 121.53, 71.27, 121.64, 71.42);
        context.bezierCurveTo(121.96, 71.87, 122.37, 71.98, 122.95, 71.82);
        context.bezierCurveTo(123.75, 71.59, 124.49, 71.26, 125.19, 70.8);
        context.bezierCurveTo(125.44, 70.63, 125.77, 70.56, 126.05, 70.43);
        context.bezierCurveTo(126.2, 70.37, 126.34, 70.23, 126.45, 70.44);
        context.bezierCurveTo(126.52, 70.57, 126.6, 70.77, 126.55, 70.88);
        context.bezierCurveTo(126.4, 71.27, 126.22, 71.65, 125.99, 71.99);
        context.bezierCurveTo(124.85, 73.71, 123.68, 75.42, 122.53, 77.13);
        context.bezierCurveTo(121.91, 78.05, 121.31, 78.98, 120.69, 79.91);
        context.bezierCurveTo(120.17, 80.7, 119.61, 81.48, 119.12, 82.29);
        context.bezierCurveTo(118.75, 82.9, 118.59, 83.57, 118.62, 84.3);
        context.bezierCurveTo(118.66, 85.45, 118.7, 86.57, 118.96, 87.71);
        context.bezierCurveTo(119.24, 88.92, 119.08, 90.17, 117.94, 91.02);
        context.bezierCurveTo(117.59, 91.28, 117.22, 91.54, 116.82, 91.72);
        context.bezierCurveTo(116.33, 91.94, 116.06, 92.32, 115.93, 92.79);
        context.bezierCurveTo(115.82, 93.2, 115.69, 93.57, 115.85, 94.07);
        context.bezierCurveTo(116.09, 94.83, 115.8, 95.63, 115.55, 96.39);
        context.bezierCurveTo(115.31, 97.11, 114.82, 97.66, 114.35, 98.22);
        context.bezierCurveTo(113.54, 99.16, 112.88, 100.23, 111.97, 101.1);
        context.bezierCurveTo(111.02, 102, 109.96, 102.59, 108.67, 102.88);
        context.bezierCurveTo(107.84, 103.07, 107.05, 103.12, 106.24, 102.85);
        context.bezierCurveTo(105.58, 102.63, 105.18, 102.21, 105.05, 101.5);
        context.bezierCurveTo(104.82, 100.33, 104.52, 99.17, 104.3, 97.99);
        context.bezierCurveTo(104.18, 97.34, 104.18, 96.66, 104.08, 96);
        context.bezierCurveTo(103.85, 94.6, 103.66, 93.19, 102.97, 91.91);
        context.bezierCurveTo(102.82, 91.63, 102.67, 91.35, 102.55, 91.05);
        context.bezierCurveTo(102.01, 89.66, 102.13, 88.36, 103.01, 87.12);
        context.bezierCurveTo(103.7, 86.15, 103.59, 85.18, 102.84, 84.24);
        context.bezierCurveTo(102.02, 83.21, 101.42, 82.07, 100.98, 80.84);
        context.bezierCurveTo(100.6, 79.79, 100.75, 78.75, 101.04, 77.74);
        context.bezierCurveTo(101.18, 77.27, 101.04, 76.9, 100.8, 76.62);
        context.bezierCurveTo(100.46, 76.21, 100.03, 75.83, 99.56, 75.58);
        context.bezierCurveTo(98.34, 74.92, 97, 74.61, 95.61, 74.75);
        context.bezierCurveTo(94.71, 74.83, 93.79, 75.01, 92.98, 75.45);
        context.bezierCurveTo(92.05, 75.95, 91.05, 76.02, 90.08, 75.85);
        context.bezierCurveTo(89.16, 75.69, 88.28, 75.26, 87.57, 74.59);
        context.bezierCurveTo(86.74, 73.82, 85.96, 73.01, 85.41, 72.02);
        context.bezierCurveTo(84.88, 71.08, 84.47, 70.09, 84.49, 68.96);
        context.bezierCurveTo(84.5, 67.75, 84.6, 66.55, 84.87, 65.36);
        context.bezierCurveTo(85.06, 64.47, 85.08, 63.54, 85.29, 62.65);
        context.bezierCurveTo(85.54, 61.57, 85.92, 60.57, 86.96, 59.89);
        context.bezierCurveTo(88.38, 58.94, 89.38, 57.57, 90.23, 56.08);
        context.bezierCurveTo(90.61, 55.41, 90.96, 54.73, 91.79, 54.5);
        context.bezierCurveTo(91.91, 54.46, 92.02, 54.37, 92.14, 54.36);
        context.bezierCurveTo(92.77, 54.28, 93.41, 54.26, 94.03, 54.15);
        context.bezierCurveTo(94.74, 54.02, 95.44, 53.82, 96.14, 53.67);
        context.bezierCurveTo(97.41, 53.39, 98.63, 52.91, 99.87, 52.99);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 589 Drawing
        context.beginPath();
        context.moveTo(44.17, 69.76);
        context.bezierCurveTo(44.17, 70.05, 44.19, 70.35, 44.17, 70.64);
        context.bezierCurveTo(44.08, 71.8, 44.64, 71.92, 45.38, 72.17);
        context.bezierCurveTo(45.88, 72.34, 46.45, 72.34, 46.98, 72.38);
        context.bezierCurveTo(47.26, 72.4, 47.5, 72.4, 47.48, 72.78);
        context.bezierCurveTo(47.45, 73.2, 47.24, 73.41, 46.88, 73.35);
        context.bezierCurveTo(46.79, 73.34, 46.71, 73.24, 46.62, 73.23);
        context.bezierCurveTo(46.47, 73.22, 46.24, 73.17, 46.18, 73.25);
        context.bezierCurveTo(45.72, 73.77, 45.29, 73.41, 44.91, 73.21);
        context.bezierCurveTo(44.23, 72.85, 43.6, 72.41, 43.09, 71.81);
        context.bezierCurveTo(42.76, 71.42, 42.38, 71.06, 42, 70.71);
        context.bezierCurveTo(40.23, 69.1, 38.23, 67.92, 35.89, 67.33);
        context.bezierCurveTo(35.04, 67.12, 34.17, 66.93, 33.36, 66.54);
        context.bezierCurveTo(32.42, 66.08, 31.77, 65.4, 31.3, 64.49);
        context.bezierCurveTo(30.13, 62.18, 28.63, 60.08, 27.24, 57.92);
        context.bezierCurveTo(26.89, 57.38, 26.51, 56.87, 26.11, 56.37);
        context.bezierCurveTo(25.33, 55.4, 25.05, 54.22, 24.79, 53.05);
        context.bezierCurveTo(24.65, 52.4, 24.6, 51.72, 24.59, 51.05);
        context.bezierCurveTo(24.57, 49.52, 25.15, 48.19, 26.16, 47.07);
        context.bezierCurveTo(26.57, 46.62, 27.09, 46.29, 27.53, 45.86);
        context.bezierCurveTo(28.16, 45.25, 28.46, 44.48, 28.69, 43.61);
        context.bezierCurveTo(28.96, 42.63, 28.82, 41.71, 28.62, 40.79);
        context.bezierCurveTo(28.38, 39.68, 27.61, 38.9, 26.71, 38.24);
        context.bezierCurveTo(25.95, 37.69, 25.07, 37.49, 24.17, 37.26);
        context.bezierCurveTo(22.42, 36.81, 20.81, 37.24, 19.27, 37.93);
        context.bezierCurveTo(18.41, 38.31, 17.66, 38.95, 16.87, 39.48);
        context.bezierCurveTo(16.37, 39.82, 15.9, 40.21, 15.38, 40.53);
        context.bezierCurveTo(14.47, 41.1, 13.6, 40.67, 13.54, 39.61);
        context.bezierCurveTo(13.45, 38.12, 13.92, 36.88, 15.36, 36.25);
        context.bezierCurveTo(16.5, 35.74, 17.54, 35.04, 18.46, 34.18);
        context.bezierCurveTo(18.55, 34.1, 18.66, 34.02, 18.73, 33.91);
        context.bezierCurveTo(19.29, 32.92, 20.26, 32.41, 21.16, 31.81);
        context.bezierCurveTo(22.24, 31.09, 23.44, 30.71, 24.69, 30.49);
        context.bezierCurveTo(25.59, 30.32, 26.5, 30.16, 27.42, 30.12);
        context.bezierCurveTo(28.04, 30.1, 28.67, 30.28, 29.28, 30.42);
        context.bezierCurveTo(30.36, 30.67, 31.41, 31, 32.49, 31.23);
        context.bezierCurveTo(33.14, 31.37, 33.84, 31.31, 34.48, 31.47);
        context.bezierCurveTo(35.59, 31.75, 36.71, 31.62, 37.83, 31.63);
        context.bezierCurveTo(38.43, 31.63, 39.04, 31.44, 39.63, 31.47);
        context.bezierCurveTo(40.83, 31.54, 42.04, 31.69, 43.24, 31.79);
        context.bezierCurveTo(44.38, 31.89, 45.5, 32.13, 46.63, 32.26);
        context.bezierCurveTo(47.43, 32.36, 48.22, 32.39, 49.02, 32.43);
        context.bezierCurveTo(49.98, 32.47, 50.74, 32.04, 51.43, 31.45);
        context.bezierCurveTo(51.75, 31.18, 52.02, 30.84, 52.34, 30.57);
        context.bezierCurveTo(52.55, 30.38, 52.8, 30.2, 53.06, 30.11);
        context.bezierCurveTo(53.63, 29.92, 54.19, 30.12, 54.55, 30.71);
        context.bezierCurveTo(55.37, 32.07, 56.59, 32.19, 57.97, 31.92);
        context.bezierCurveTo(58.06, 31.9, 58.16, 31.83, 58.24, 31.86);
        context.bezierCurveTo(58.38, 31.9, 58.56, 31.97, 58.63, 32.08);
        context.bezierCurveTo(58.68, 32.18, 58.63, 32.41, 58.54, 32.48);
        context.bezierCurveTo(58.04, 32.93, 57.56, 33.41, 56.99, 33.77);
        context.bezierCurveTo(55.24, 34.87, 53.39, 35.81, 51.52, 36.7);
        context.bezierCurveTo(50.86, 37.01, 50.2, 37.33, 49.53, 37.61);
        context.bezierCurveTo(49, 37.83, 48.85, 38.25, 48.83, 38.76);
        context.bezierCurveTo(48.79, 39.51, 49.15, 40.13, 49.61, 40.65);
        context.bezierCurveTo(50.12, 41.22, 50.7, 41.74, 51.34, 42.17);
        context.bezierCurveTo(51.77, 42.45, 52.33, 42.63, 52.84, 42.68);
        context.bezierCurveTo(53.51, 42.73, 53.93, 42.24, 54.26, 41.7);
        context.bezierCurveTo(55.22, 40.16, 56.19, 38.62, 57.16, 37.09);
        context.bezierCurveTo(57.34, 36.8, 57.54, 36.5, 57.77, 36.24);
        context.bezierCurveTo(58.16, 35.8, 58.68, 35.79, 59.11, 36.21);
        context.bezierCurveTo(59.32, 36.41, 59.48, 36.65, 59.68, 36.86);
        context.bezierCurveTo(59.92, 37.1, 60.17, 37.35, 60.44, 37.55);
        context.bezierCurveTo(60.88, 37.87, 61.36, 37.95, 61.88, 37.68);
        context.bezierCurveTo(62.12, 37.54, 62.44, 37.55, 62.69, 37.42);
        context.bezierCurveTo(63.27, 37.09, 63.78, 37.34, 64.2, 37.65);
        context.bezierCurveTo(65.4, 38.55, 66.06, 39.82, 66.26, 41.29);
        context.bezierCurveTo(66.43, 42.51, 65.44, 43.58, 64.2, 43.69);
        context.bezierCurveTo(63.29, 43.78, 62.37, 43.87, 61.48, 44.09);
        context.bezierCurveTo(60.07, 44.44, 58.93, 45.2, 58.16, 46.49);
        context.bezierCurveTo(57.76, 47.17, 57.36, 47.87, 56.88, 48.49);
        context.bezierCurveTo(56.25, 49.29, 55.31, 49.68, 54.4, 50.08);
        context.bezierCurveTo(54.2, 50.17, 53.99, 50.23, 53.78, 50.3);
        context.bezierCurveTo(52.57, 50.74, 52.02, 51.69, 51.73, 52.88);
        context.bezierCurveTo(51.47, 53.89, 50.79, 54.61, 49.92, 55.15);
        context.bezierCurveTo(49.6, 55.35, 49.26, 55.55, 48.9, 55.67);
        context.bezierCurveTo(47.72, 56.06, 47.24, 56.95, 47.08, 58.09);
        context.bezierCurveTo(47, 58.72, 46.95, 59.36, 46.88, 59.99);
        context.bezierCurveTo(46.87, 60.05, 46.81, 60.14, 46.78, 60.14);
        context.bezierCurveTo(46.69, 60.13, 46.56, 60.11, 46.53, 60.05);
        context.bezierCurveTo(46.38, 59.81, 46.24, 59.55, 46.14, 59.28);
        context.bezierCurveTo(45.72, 58.15, 44.8, 57.61, 43.74, 57.28);
        context.bezierCurveTo(42.61, 56.94, 41.48, 57, 40.35, 57.33);
        context.bezierCurveTo(39.14, 57.68, 38.23, 58.42, 37.47, 59.41);
        context.bezierCurveTo(36.73, 60.38, 36.45, 61.51, 36.25, 62.67);
        context.bezierCurveTo(36.18, 63.08, 36.31, 63.56, 36.43, 63.98);
        context.bezierCurveTo(36.7, 64.9, 37.16, 65.15, 38.12, 65.23);
        context.bezierCurveTo(39.65, 65.36, 40.91, 64.77, 42.09, 63.9);
        context.bezierCurveTo(42.2, 63.81, 42.32, 63.6, 42.46, 63.82);
        context.bezierCurveTo(42.54, 63.92, 42.57, 64.11, 42.53, 64.23);
        context.bezierCurveTo(42.29, 64.82, 42.01, 65.39, 41.77, 65.98);
        context.bezierCurveTo(41.58, 66.41, 41.41, 66.86, 41.28, 67.31);
        context.bezierCurveTo(41.21, 67.55, 41.31, 67.77, 41.63, 67.73);
        context.bezierCurveTo(41.91, 67.7, 42.19, 67.66, 42.47, 67.61);
        context.bezierCurveTo(43.49, 67.43, 44.18, 67.37, 44.17, 68.89);
        context.bezierCurveTo(44.17, 69.18, 44.17, 69.47, 44.17, 69.76);
        context.lineTo(44.17, 69.76);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 590 Drawing
        context.beginPath();
        context.moveTo(51.07, 70.78);
        context.bezierCurveTo(51.43, 70.85, 51.68, 70.9, 51.93, 70.94);
        context.bezierCurveTo(52.96, 71.09, 53.96, 71.39, 55.01, 71.43);
        context.bezierCurveTo(55.74, 71.46, 56.41, 71.69, 57.05, 72.13);
        context.bezierCurveTo(58.46, 73.1, 59.94, 73.97, 61.37, 74.9);
        context.bezierCurveTo(62.48, 75.62, 63.43, 76.51, 64.23, 77.58);
        context.bezierCurveTo(64.93, 78.5, 65.88, 79.17, 66.88, 79.76);
        context.bezierCurveTo(67.97, 80.42, 69.18, 80.79, 70.4, 81.14);
        context.bezierCurveTo(71.21, 81.38, 72.06, 81.53, 72.77, 82.05);
        context.bezierCurveTo(73.6, 82.65, 73.82, 83.33, 73.34, 84.24);
        context.bezierCurveTo(72.55, 85.73, 71.97, 87.3, 71.59, 88.94);
        context.bezierCurveTo(71.48, 89.4, 71.37, 89.86, 71.33, 90.33);
        context.bezierCurveTo(71.19, 91.87, 70.39, 93.06, 69.22, 93.95);
        context.bezierCurveTo(68.41, 94.56, 67.83, 95.33, 67.22, 96.11);
        context.bezierCurveTo(66.45, 97.09, 65.96, 98.24, 65.27, 99.27);
        context.bezierCurveTo(64.28, 100.78, 63.35, 102.35, 62.28, 103.8);
        context.bezierCurveTo(61.6, 104.73, 60.71, 105.51, 59.73, 106.13);
        context.bezierCurveTo(59.04, 106.57, 58.6, 107.14, 58.41, 107.93);
        context.bezierCurveTo(58.38, 108.05, 58.32, 108.18, 58.34, 108.3);
        context.bezierCurveTo(58.44, 109.05, 58.61, 109.79, 58.67, 110.54);
        context.bezierCurveTo(58.74, 111.25, 58.7, 111.96, 58.72, 112.67);
        context.bezierCurveTo(58.73, 113.15, 58.73, 113.63, 58.82, 114.1);
        context.bezierCurveTo(59, 115.17, 59.87, 115.74, 60.58, 116.42);
        context.bezierCurveTo(60.73, 116.56, 61.02, 116.78, 60.99, 116.85);
        context.bezierCurveTo(60.87, 117.15, 60.53, 117.06, 60.32, 117);
        context.bezierCurveTo(59.43, 116.75, 58.57, 116.39, 57.83, 115.84);
        context.bezierCurveTo(55.92, 114.41, 54.82, 112.45, 54.24, 110.16);
        context.bezierCurveTo(53.9, 108.83, 53.75, 107.49, 53.62, 106.13);
        context.bezierCurveTo(53.5, 104.81, 53.66, 103.48, 53.43, 102.2);
        context.bezierCurveTo(53.24, 101.16, 53.5, 100.08, 53.1, 99.08);
        context.bezierCurveTo(53.08, 99.05, 53.09, 99.01, 53.09, 98.98);
        context.bezierCurveTo(53.02, 97.9, 53.01, 96.81, 52.88, 95.73);
        context.bezierCurveTo(52.73, 94.51, 52.63, 93.27, 52.27, 92.09);
        context.bezierCurveTo(51.98, 91.13, 51.52, 90.25, 50.86, 89.52);
        context.bezierCurveTo(50.15, 88.73, 49.34, 88.04, 48.56, 87.32);
        context.bezierCurveTo(47.5, 86.35, 46.44, 85.37, 46.04, 83.9);
        context.bezierCurveTo(45.77, 82.89, 45.51, 81.89, 45.5, 80.82);
        context.bezierCurveTo(45.5, 79.76, 45.89, 78.91, 46.51, 78.11);
        context.bezierCurveTo(46.54, 78.06, 46.59, 78.02, 46.64, 77.97);
        context.bezierCurveTo(47.48, 77.2, 47.67, 76.29, 47.32, 75.19);
        context.bezierCurveTo(47.09, 74.46, 47.23, 73.71, 47.8, 73.15);
        context.bezierCurveTo(48.53, 72.43, 49.29, 71.74, 50.09, 71.1);
        context.bezierCurveTo(50.36, 70.88, 50.79, 70.86, 51.07, 70.78);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 591 Drawing
        context.beginPath();
        context.moveTo(103.31, 37.66);
        context.bezierCurveTo(103.29, 38.13, 103.4, 38.75, 103.12, 39.32);
        context.bezierCurveTo(103.05, 39.46, 102.99, 39.61, 102.89, 39.73);
        context.bezierCurveTo(102.56, 40.12, 102.19, 40.11, 101.98, 39.65);
        context.bezierCurveTo(101.77, 39.2, 101.5, 38.83, 101.14, 38.5);
        context.bezierCurveTo(100.81, 38.2, 100.19, 38.13, 99.81, 38.37);
        context.bezierCurveTo(99.54, 38.54, 99.3, 38.76, 99.03, 38.92);
        context.bezierCurveTo(98.59, 39.18, 98.25, 39.04, 98.07, 38.55);
        context.bezierCurveTo(97.7, 37.53, 98.12, 36.42, 99.13, 35.9);
        context.bezierCurveTo(99.76, 35.57, 100.34, 35.19, 100.8, 34.64);
        context.bezierCurveTo(100.96, 34.45, 101.13, 34.24, 101.24, 34.02);
        context.bezierCurveTo(101.9, 32.67, 103.04, 31.88, 104.41, 31.47);
        context.bezierCurveTo(106.09, 30.96, 107.69, 31.1, 109.03, 32.42);
        context.bezierCurveTo(110.07, 33.44, 111.35, 33.87, 112.77, 33.76);
        context.bezierCurveTo(113.65, 33.69, 114.51, 33.46, 115.33, 33.1);
        context.bezierCurveTo(116.24, 32.7, 117.14, 32.42, 118.16, 32.39);
        context.bezierCurveTo(119.03, 32.37, 119.91, 32.13, 120.76, 31.93);
        context.bezierCurveTo(121.64, 31.72, 122.51, 31.62, 123.41, 31.64);
        context.bezierCurveTo(123.76, 31.64, 123.95, 31.81, 124.14, 32.04);
        context.bezierCurveTo(124.35, 32.28, 124.14, 32.5, 124.1, 32.74);
        context.bezierCurveTo(124.02, 33.22, 123.73, 33.43, 123.36, 33.66);
        context.bezierCurveTo(122.92, 33.92, 122.53, 34.26, 122.17, 34.62);
        context.bezierCurveTo(121.73, 35.06, 121.71, 35.41, 122.07, 35.96);
        context.bezierCurveTo(122.51, 36.62, 123.02, 37.24, 123.43, 37.92);
        context.bezierCurveTo(124.18, 39.18, 124.9, 40.45, 124.84, 42);
        context.bezierCurveTo(124.8, 42.75, 124.47, 43.09, 123.7, 43.09);
        context.bezierCurveTo(123.39, 43.09, 123.09, 42.97, 122.77, 42.94);
        context.bezierCurveTo(122.43, 42.9, 122.07, 42.81, 121.78, 43.13);
        context.bezierCurveTo(121.6, 43.34, 121.5, 43.51, 121.58, 43.81);
        context.bezierCurveTo(121.79, 44.57, 121.94, 45.35, 122.11, 46.11);
        context.bezierCurveTo(122.13, 46.21, 122.14, 46.31, 122.11, 46.4);
        context.bezierCurveTo(121.83, 47.35, 122.06, 48.27, 122.3, 49.18);
        context.bezierCurveTo(122.35, 49.36, 122.24, 49.52, 122.04, 49.49);
        context.bezierCurveTo(121.7, 49.44, 121.34, 49.39, 121.04, 49.25);
        context.bezierCurveTo(119.96, 48.71, 118.83, 48.49, 117.63, 48.51);
        context.bezierCurveTo(117.14, 48.51, 116.63, 48.34, 116.17, 48.15);
        context.bezierCurveTo(115.43, 47.83, 115.37, 47.54, 115.78, 46.81);
        context.bezierCurveTo(116.02, 46.38, 115.87, 46.06, 115.39, 46.01);
        context.bezierCurveTo(115.2, 46, 115, 45.99, 114.81, 46.02);
        context.bezierCurveTo(114.4, 46.09, 114.24, 46.39, 114.36, 46.83);
        context.bezierCurveTo(114.4, 46.98, 114.5, 47.12, 114.51, 47.27);
        context.bezierCurveTo(114.52, 47.38, 114.47, 47.53, 114.4, 47.6);
        context.bezierCurveTo(114.1, 47.89, 113.72, 47.77, 113.38, 47.73);
        context.bezierCurveTo(113.1, 47.7, 113, 47.45, 113, 47.15);
        context.bezierCurveTo(112.99, 46.74, 112.74, 46.51, 112.37, 46.41);
        context.bezierCurveTo(112, 46.31, 111.76, 46.53, 111.55, 46.8);
        context.bezierCurveTo(111.05, 47.44, 110.9, 48.22, 110.7, 48.98);
        context.bezierCurveTo(110.67, 49.08, 110.76, 49.22, 110.79, 49.34);
        context.bezierCurveTo(110.96, 50.01, 110.83, 50.26, 110.15, 50.47);
        context.bezierCurveTo(109.82, 50.57, 109.48, 50.61, 109.13, 50.66);
        context.bezierCurveTo(108.94, 50.69, 108.74, 50.65, 108.55, 50.68);
        context.bezierCurveTo(108.22, 50.74, 108.12, 51, 108.31, 51.3);
        context.bezierCurveTo(108.45, 51.51, 108.61, 51.72, 108.74, 51.93);
        context.bezierCurveTo(108.97, 52.28, 108.93, 52.69, 108.64, 52.95);
        context.bezierCurveTo(108.35, 53.21, 107.83, 53.3, 107.57, 53.07);
        context.bezierCurveTo(107.34, 52.86, 107.13, 52.6, 106.98, 52.33);
        context.bezierCurveTo(106.64, 51.72, 106.31, 51.09, 106.03, 50.45);
        context.bezierCurveTo(105.75, 49.83, 105.34, 49.37, 104.71, 49.13);
        context.bezierCurveTo(104.04, 48.88, 103.51, 48.42, 103.19, 47.85);
        context.bezierCurveTo(102.89, 47.31, 102.5, 47.37, 102.06, 47.37);
        context.bezierCurveTo(101.79, 47.37, 101.72, 47.55, 101.77, 47.75);
        context.bezierCurveTo(101.82, 47.96, 101.92, 48.18, 102.06, 48.33);
        context.bezierCurveTo(102.64, 48.96, 103.33, 49.48, 104.09, 49.9);
        context.bezierCurveTo(104.45, 50.09, 104.78, 50.35, 105.1, 50.62);
        context.bezierCurveTo(105.33, 50.82, 105.31, 51.02, 104.95, 51.05);
        context.bezierCurveTo(104.67, 51.07, 104.7, 51.26, 104.66, 51.46);
        context.bezierCurveTo(104.58, 51.85, 104.41, 51.92, 104.09, 51.73);
        context.bezierCurveTo(103.7, 51.49, 103.38, 51.17, 103.18, 50.78);
        context.bezierCurveTo(103.02, 50.45, 102.79, 50.28, 102.5, 50.16);
        context.bezierCurveTo(101.89, 49.9, 101.45, 49.51, 101.06, 48.95);
        context.bezierCurveTo(100.57, 48.26, 100.49, 48.2, 99.62, 48.5);
        context.bezierCurveTo(99, 48.72, 98.33, 48.86, 97.68, 48.89);
        context.bezierCurveTo(97.18, 48.91, 96.86, 49.15, 96.66, 49.5);
        context.bezierCurveTo(96.22, 50.26, 95.63, 50.88, 95.02, 51.5);
        context.bezierCurveTo(94.85, 51.68, 94.69, 51.91, 94.62, 52.14);
        context.bezierCurveTo(94.33, 53.17, 93.57, 53.52, 92.61, 53.57);
        context.bezierCurveTo(92.26, 53.59, 91.9, 53.58, 91.56, 53.52);
        context.bezierCurveTo(90.62, 53.34, 90.23, 52.6, 89.95, 51.8);
        context.bezierCurveTo(89.83, 51.48, 89.79, 51.13, 89.74, 50.78);
        context.bezierCurveTo(89.63, 49.96, 89.95, 48.8, 91.01, 48.47);
        context.bezierCurveTo(91.38, 48.35, 91.66, 48.3, 92, 48.51);
        context.bezierCurveTo(92.3, 48.7, 92.65, 48.72, 93.01, 48.74);
        context.bezierCurveTo(93.49, 48.75, 93.91, 48.69, 94.17, 48.23);
        context.bezierCurveTo(94.42, 47.76, 94.54, 47.25, 94.26, 46.77);
        context.bezierCurveTo(94.04, 46.39, 93.73, 46.05, 93.42, 45.73);
        context.bezierCurveTo(93.22, 45.52, 93.3, 45.4, 93.47, 45.28);
        context.bezierCurveTo(93.62, 45.18, 93.79, 45.09, 93.97, 45.03);
        context.bezierCurveTo(95.46, 44.57, 96.69, 43.69, 97.85, 42.66);
        context.bezierCurveTo(98.39, 42.18, 99, 41.77, 99.61, 41.36);
        context.bezierCurveTo(100, 41.1, 100.41, 41.14, 100.88, 41.28);
        context.bezierCurveTo(102.26, 41.72, 103.63, 41.74, 104.95, 41.01);
        context.bezierCurveTo(105.72, 40.58, 106.31, 39.98, 106.78, 39.25);
        context.bezierCurveTo(107.3, 38.45, 107.06, 37.72, 106.21, 37.28);
        context.bezierCurveTo(106.07, 37.2, 105.92, 37.15, 105.78, 37.07);
        context.bezierCurveTo(105.37, 36.82, 105.34, 36.6, 105.63, 36.21);
        context.bezierCurveTo(105.91, 35.83, 106.17, 35.43, 106.44, 35.04);
        context.bezierCurveTo(106.66, 34.73, 106.74, 34.33, 106.49, 34.07);
        context.bezierCurveTo(106.35, 33.92, 105.91, 33.91, 105.67, 34);
        context.bezierCurveTo(104.83, 34.36, 103.95, 34.71, 103.53, 35.64);
        context.bezierCurveTo(103.38, 35.96, 103.27, 36.29, 103.31, 36.65);
        context.bezierCurveTo(103.33, 36.94, 103.31, 37.23, 103.31, 37.66);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 592 Drawing
        context.beginPath();
        context.moveTo(177.82, 105.84);
        context.bezierCurveTo(177.25, 105.76, 176.69, 105.67, 176.12, 105.62);
        context.bezierCurveTo(175.44, 105.56, 175.33, 105.08, 175.25, 104.55);
        context.bezierCurveTo(175.22, 104.33, 175.24, 104.1, 175.17, 103.89);
        context.bezierCurveTo(175, 103.36, 174.61, 102.91, 174.1, 102.9);
        context.bezierCurveTo(173.24, 102.88, 173.09, 102.28, 172.95, 101.72);
        context.bezierCurveTo(172.78, 101.02, 172.31, 100.82, 171.71, 100.73);
        context.bezierCurveTo(171.4, 100.69, 171.06, 100.68, 170.75, 100.74);
        context.bezierCurveTo(169.86, 100.89, 169.03, 101.19, 168.31, 101.75);
        context.bezierCurveTo(167.61, 102.28, 166.91, 102.63, 165.94, 102.32);
        context.bezierCurveTo(165.11, 102.06, 164.34, 102.57, 163.61, 102.97);
        context.bezierCurveTo(163.44, 103.07, 163.29, 103.19, 163.13, 103.28);
        context.bezierCurveTo(162.52, 103.6, 162.16, 103.54, 162.08, 102.75);
        context.bezierCurveTo(161.98, 101.67, 162.01, 100.59, 162.35, 99.52);
        context.bezierCurveTo(162.7, 98.44, 162.94, 97.33, 163.2, 96.22);
        context.bezierCurveTo(163.27, 95.91, 163.22, 95.58, 163.22, 95.25);
        context.bezierCurveTo(163.22, 93.89, 163.89, 93.16, 165.26, 92.95);
        context.bezierCurveTo(165.78, 92.87, 166.3, 92.67, 166.83, 92.62);
        context.bezierCurveTo(167.68, 92.52, 168.27, 92.01, 168.88, 91.48);
        context.bezierCurveTo(169.73, 90.74, 170.31, 89.77, 170.98, 88.87);
        context.bezierCurveTo(171.39, 88.32, 172.01, 88.14, 172.56, 88.43);
        context.bezierCurveTo(173.32, 88.84, 173.66, 88.84, 174.09, 88.15);
        context.bezierCurveTo(174.47, 87.55, 175.02, 87.2, 175.52, 86.76);
        context.bezierCurveTo(176.02, 86.33, 177.36, 86.39, 177.8, 86.85);
        context.bezierCurveTo(178.4, 87.5, 178.45, 87.78, 177.89, 88.48);
        context.bezierCurveTo(177.45, 89.04, 177.44, 89.49, 178.02, 89.97);
        context.bezierCurveTo(178.5, 90.37, 179.06, 90.7, 179.64, 90.97);
        context.bezierCurveTo(180.1, 91.19, 180.53, 90.87, 180.67, 90.29);
        context.bezierCurveTo(180.94, 89.15, 181.19, 88.01, 181.63, 86.92);
        context.bezierCurveTo(181.65, 86.87, 181.63, 86.78, 181.66, 86.73);
        context.bezierCurveTo(181.77, 86.55, 181.9, 86.22, 182.01, 86.23);
        context.bezierCurveTo(182.35, 86.25, 182.42, 86.62, 182.4, 86.87);
        context.bezierCurveTo(182.34, 87.83, 182.67, 88.69, 183.04, 89.54);
        context.bezierCurveTo(183.2, 89.92, 183.4, 90.27, 183.43, 90.7);
        context.bezierCurveTo(183.44, 91.02, 183.58, 91.33, 183.57, 91.63);
        context.bezierCurveTo(183.55, 92.23, 183.76, 92.69, 184.19, 93.08);
        context.bezierCurveTo(184.36, 93.23, 184.5, 93.41, 184.68, 93.55);
        context.bezierCurveTo(185.43, 94.17, 185.84, 95, 186.18, 95.86);
        context.bezierCurveTo(186.54, 96.74, 186.55, 97.67, 186.21, 98.54);
        context.bezierCurveTo(185.89, 99.37, 185.44, 100.15, 184.79, 100.79);
        context.bezierCurveTo(184.02, 101.53, 183.26, 102.29, 182.52, 103.05);
        context.bezierCurveTo(182.1, 103.49, 181.72, 103.96, 181.33, 104.43);
        context.bezierCurveTo(180.62, 105.29, 179.76, 105.83, 178.6, 105.77);
        context.bezierCurveTo(178.34, 105.76, 178.08, 105.77, 177.83, 105.77);
        context.bezierCurveTo(177.82, 105.79, 177.82, 105.82, 177.82, 105.84);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 593 Drawing
        context.beginPath();
        context.moveTo(70.9, 34.29);
        context.bezierCurveTo(70.86, 34, 71.03, 33.57, 71.25, 33.12);
        context.bezierCurveTo(71.59, 32.41, 72.01, 31.74, 72.18, 30.94);
        context.bezierCurveTo(72.3, 30.36, 72.15, 29.94, 71.82, 29.55);
        context.bezierCurveTo(71.15, 28.75, 70.19, 28.45, 69.25, 28.17);
        context.bezierCurveTo(68.7, 28.01, 68.13, 27.93, 67.6, 27.75);
        context.bezierCurveTo(67.25, 27.63, 66.87, 27.47, 66.71, 27.07);
        context.bezierCurveTo(66.99, 26.86, 67.24, 26.6, 67.55, 26.46);
        context.bezierCurveTo(67.86, 26.32, 68.22, 26.27, 68.56, 26.22);
        context.bezierCurveTo(69.85, 26.04, 71.11, 25.75, 72.41, 25.59);
        context.bezierCurveTo(73.72, 25.43, 75.04, 25.23, 76.35, 25.02);
        context.bezierCurveTo(78.84, 24.62, 81.33, 24.69, 83.83, 24.65);
        context.bezierCurveTo(84.92, 24.63, 86.01, 24.73, 87.08, 24.88);
        context.bezierCurveTo(87.56, 24.95, 88.02, 25.23, 88.48, 25.44);
        context.bezierCurveTo(88.8, 25.59, 88.72, 25.86, 88.56, 26.08);
        context.bezierCurveTo(88.39, 26.31, 88.2, 26.55, 87.97, 26.71);
        context.bezierCurveTo(86.72, 27.52, 85.7, 28.59, 84.67, 29.65);
        context.bezierCurveTo(84.41, 29.93, 84.18, 30.24, 83.96, 30.55);
        context.bezierCurveTo(83, 31.91, 81.65, 32.57, 80.05, 32.83);
        context.bezierCurveTo(77.94, 33.17, 76.25, 34.22, 74.99, 35.96);
        context.bezierCurveTo(74.74, 36.3, 74.43, 36.59, 74.14, 36.88);
        context.bezierCurveTo(73.64, 37.37, 73.19, 37.46, 72.58, 37.11);
        context.bezierCurveTo(71.58, 36.54, 70.93, 35.72, 70.9, 34.29);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 594 Drawing
        context.beginPath();
        context.moveTo(186.35, 86.19);
        context.bezierCurveTo(185.45, 86.15, 184.87, 85.74, 184.41, 85.13);
        context.bezierCurveTo(184.36, 85.05, 184.34, 84.95, 184.3, 84.86);
        context.bezierCurveTo(183.91, 84.17, 183.91, 84.17, 183.24, 84.67);
        context.bezierCurveTo(182.74, 85.03, 182.2, 85.19, 181.62, 84.89);
        context.bezierCurveTo(180.91, 84.52, 180.23, 84.5, 179.53, 84.89);
        context.bezierCurveTo(179.44, 84.94, 179.25, 84.96, 179.2, 84.91);
        context.bezierCurveTo(179.14, 84.83, 179.15, 84.68, 179.14, 84.56);
        context.bezierCurveTo(179.14, 84.54, 179.17, 84.51, 179.19, 84.48);
        context.bezierCurveTo(179.44, 84.09, 179.66, 83.65, 179.48, 83.21);
        context.bezierCurveTo(179.25, 82.66, 178.67, 82.41, 178.18, 82.13);
        context.bezierCurveTo(177.8, 81.92, 177.35, 81.83, 176.92, 81.72);
        context.bezierCurveTo(175.93, 81.47, 175.36, 80.49, 175.65, 79.52);
        context.bezierCurveTo(175.75, 79.19, 176.08, 79.11, 176.31, 79.38);
        context.bezierCurveTo(176.54, 79.64, 176.73, 79.94, 176.94, 80.22);
        context.bezierCurveTo(177.39, 80.82, 177.85, 80.91, 178.48, 80.51);
        context.bezierCurveTo(178.58, 80.45, 178.7, 80.4, 178.81, 80.33);
        context.bezierCurveTo(179.86, 79.6, 180.93, 79.75, 182.07, 80.14);
        context.bezierCurveTo(183.09, 80.49, 183.8, 81.19, 184.49, 81.95);
        context.bezierCurveTo(184.71, 82.2, 184.84, 82.55, 184.95, 82.87);
        context.bezierCurveTo(185.29, 83.88, 185.77, 84.8, 186.55, 85.55);
        context.bezierCurveTo(186.66, 85.66, 186.94, 85.76, 186.75, 85.98);
        context.bezierCurveTo(186.65, 86.11, 186.43, 86.15, 186.35, 86.19);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 595 Drawing
        context.beginPath();
        context.moveTo(161.98, 78.47);
        context.bezierCurveTo(162.06, 77.78, 162.13, 77.7, 162.82, 77.43);
        context.bezierCurveTo(163.74, 77.06, 164.65, 76.68, 165.28, 75.86);
        context.bezierCurveTo(165.59, 75.46, 165.86, 75.02, 166.11, 74.58);
        context.bezierCurveTo(166.29, 74.27, 166.45, 73.91, 166.86, 73.96);
        context.bezierCurveTo(167.3, 74.01, 167.47, 74.41, 167.6, 74.77);
        context.bezierCurveTo(167.68, 75, 167.73, 75.3, 167.66, 75.52);
        context.bezierCurveTo(167.4, 76.31, 167.32, 77.12, 167.29, 77.95);
        context.bezierCurveTo(167.25, 78.77, 166.77, 79.44, 166.42, 80.15);
        context.bezierCurveTo(166.36, 80.26, 166.23, 80.33, 166.18, 80.45);
        context.bezierCurveTo(165.63, 81.6, 164.4, 81.5, 163.56, 80.95);
        context.bezierCurveTo(163.01, 80.59, 162.54, 80.1, 162.28, 79.46);
        context.bezierCurveTo(162.15, 79.14, 162.08, 78.8, 161.98, 78.47);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 596 Drawing
        context.beginPath();
        context.moveTo(125.41, 89.06);
        context.bezierCurveTo(125.57, 89.98, 125, 90.77, 124.7, 91.62);
        context.bezierCurveTo(124.38, 92.51, 124.06, 93.4, 123.87, 94.32);
        context.bezierCurveTo(123.7, 95.13, 123.36, 95.81, 122.72, 96.35);
        context.bezierCurveTo(122.12, 96.86, 121.52, 96.79, 121.16, 96.08);
        context.bezierCurveTo(120.7, 95.19, 120.62, 94.23, 121.16, 93.38);
        context.bezierCurveTo(121.64, 92.64, 121.81, 91.87, 121.72, 91.03);
        context.bezierCurveTo(121.65, 90.3, 122.01, 89.89, 122.62, 89.62);
        context.bezierCurveTo(123.54, 89.19, 124.29, 88.59, 124.69, 87.61);
        context.bezierCurveTo(124.81, 87.33, 125.01, 87.35, 125.18, 87.61);
        context.bezierCurveTo(125.46, 88.04, 125.42, 88.52, 125.41, 89.06);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 597 Drawing
        context.beginPath();
        context.moveTo(160.12, 81.62);
        context.bezierCurveTo(160.06, 81.73, 159.98, 82.11, 159.75, 82.24);
        context.bezierCurveTo(159.52, 82.38, 159.17, 82.32, 158.86, 82.11);
        context.bezierCurveTo(157.76, 81.41, 157.11, 80.35, 156.5, 79.28);
        context.bezierCurveTo(156.1, 78.58, 155.77, 77.91, 155.03, 77.45);
        context.bezierCurveTo(154.39, 77.05, 153.97, 76.43, 154.11, 75.57);
        context.bezierCurveTo(154.15, 75.3, 154.32, 75.27, 154.52, 75.32);
        context.bezierCurveTo(154.7, 75.37, 154.89, 75.45, 155.03, 75.57);
        context.bezierCurveTo(156.01, 76.4, 156.96, 77.25, 157.95, 78.06);
        context.bezierCurveTo(158.37, 78.41, 158.63, 78.84, 158.74, 79.34);
        context.bezierCurveTo(158.85, 79.88, 159.08, 80.28, 159.61, 80.49);
        context.bezierCurveTo(160.02, 80.66, 160.13, 81.01, 160.12, 81.62);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 598 Drawing
        context.beginPath();
        context.moveTo(169.04, 67.06);
        context.bezierCurveTo(169.04, 67.28, 169.03, 67.51, 169.05, 67.73);
        context.bezierCurveTo(169.08, 68.12, 169.23, 68.47, 169.57, 68.7);
        context.bezierCurveTo(169.76, 68.82, 169.94, 68.95, 170.1, 69.09);
        context.bezierCurveTo(170.94, 69.81, 171.64, 70.61, 171.78, 71.78);
        context.bezierCurveTo(171.82, 72.11, 171.96, 72.47, 172.15, 72.74);
        context.bezierCurveTo(172.51, 73.24, 172.2, 73.66, 172.06, 74.08);
        context.bezierCurveTo(171.94, 74.42, 171.49, 74.43, 171.27, 74.14);
        context.bezierCurveTo(171.22, 74.06, 171.2, 73.96, 171.16, 73.88);
        context.bezierCurveTo(171.01, 73.59, 170.86, 73.36, 170.48, 73.3);
        context.bezierCurveTo(170.17, 73.24, 169.93, 73.01, 170, 72.61);
        context.bezierCurveTo(170.07, 72.24, 170.12, 71.87, 170.17, 71.49);
        context.bezierCurveTo(170.25, 70.97, 170.26, 70.48, 169.77, 70.1);
        context.bezierCurveTo(169.26, 69.72, 168.8, 69.27, 168.27, 68.92);
        context.bezierCurveTo(167.99, 68.73, 167.87, 68.55, 167.87, 68.23);
        context.bezierCurveTo(167.88, 67.65, 167.85, 67.07, 167.88, 66.49);
        context.bezierCurveTo(167.91, 66.06, 168.56, 65.79, 168.92, 66.04);
        context.bezierCurveTo(168.99, 66.08, 169.03, 66.2, 169.03, 66.28);
        context.bezierCurveTo(169.05, 66.54, 169.04, 66.8, 169.04, 67.06);
        context.lineTo(169.04, 67.06);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 599 Drawing
        context.beginPath();
        context.moveTo(174.15, 50.27);
        context.bezierCurveTo(174.93, 50.27, 175.59, 50.88, 175.63, 51.66);
        context.bezierCurveTo(175.67, 52.34, 175.83, 52.97, 176.12, 53.58);
        context.bezierCurveTo(176.32, 54.02, 176.23, 54.19, 175.74, 54.35);
        context.bezierCurveTo(175.47, 54.44, 175.19, 54.52, 174.91, 54.53);
        context.bezierCurveTo(174.47, 54.55, 174.29, 54.74, 174.26, 55.18);
        context.bezierCurveTo(174.24, 55.56, 174.12, 55.57, 173.79, 55.32);
        context.bezierCurveTo(173.59, 55.17, 173.38, 55, 173.15, 54.94);
        context.bezierCurveTo(172.56, 54.81, 172.21, 55.24, 171.97, 55.72);
        context.bezierCurveTo(171.84, 56, 171.83, 56.33, 171.75, 56.63);
        context.bezierCurveTo(171.73, 56.71, 171.66, 56.82, 171.59, 56.83);
        context.bezierCurveTo(171.52, 56.85, 171.4, 56.8, 171.35, 56.74);
        context.bezierCurveTo(170.96, 56.29, 170.8, 55.71, 171.06, 55.22);
        context.bezierCurveTo(171.3, 54.78, 171.65, 54.32, 172.33, 54.33);
        context.bezierCurveTo(173.06, 54.35, 173.2, 54.17, 173.29, 53.36);
        context.bezierCurveTo(173.32, 53.06, 173.45, 53, 173.72, 52.98);
        context.bezierCurveTo(174.24, 52.94, 174.42, 52.74, 174.47, 52.23);
        context.bezierCurveTo(174.52, 51.64, 174.44, 51.08, 173.98, 50.64);
        context.bezierCurveTo(173.93, 50.59, 173.91, 50.44, 173.94, 50.38);
        context.bezierCurveTo(173.98, 50.32, 174.1, 50.3, 174.15, 50.27);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 600 Drawing
        context.beginPath();
        context.moveTo(93.12, 44.09);
        context.bezierCurveTo(93.03, 44.04, 92.91, 44.01, 92.88, 43.95);
        context.bezierCurveTo(92.85, 43.88, 92.89, 43.76, 92.94, 43.69);
        context.bezierCurveTo(93.08, 43.48, 93.24, 43.29, 93.4, 43.1);
        context.bezierCurveTo(93.19, 42.96, 92.99, 42.83, 92.79, 42.69);
        context.bezierCurveTo(92.62, 42.57, 92.63, 42.45, 92.81, 42.33);
        context.bezierCurveTo(92.94, 42.25, 93.08, 42.16, 93.2, 42.06);
        context.bezierCurveTo(93.45, 41.84, 93.51, 41.59, 93.28, 41.31);
        context.bezierCurveTo(93.03, 41.01, 92.79, 40.71, 92.58, 40.4);
        context.bezierCurveTo(92.31, 39.99, 92.06, 39.56, 92.43, 39.08);
        context.bezierCurveTo(92.59, 38.88, 92.75, 38.69, 92.91, 38.5);
        context.bezierCurveTo(93.14, 38.75, 93.39, 38.98, 93.6, 39.25);
        context.bezierCurveTo(93.66, 39.33, 93.63, 39.5, 93.61, 39.63);
        context.bezierCurveTo(93.56, 39.99, 93.74, 40.23, 93.99, 40.47);
        context.bezierCurveTo(94.36, 40.81, 94.69, 41.17, 94.82, 41.72);
        context.bezierCurveTo(94.87, 41.97, 95.23, 42.16, 95.47, 42.36);
        context.bezierCurveTo(95.83, 42.65, 95.86, 42.95, 95.5, 43.22);
        context.bezierCurveTo(94.8, 43.75, 93.96, 43.91, 93.12, 44.09);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 601 Drawing
        context.beginPath();
        context.moveTo(187.3, 111.2);
        context.bezierCurveTo(186.91, 111.25, 186.57, 111.1, 186.26, 110.86);
        context.bezierCurveTo(186.07, 110.71, 186.06, 110.53, 186.26, 110.38);
        context.bezierCurveTo(186.41, 110.26, 186.58, 110.18, 186.75, 110.09);
        context.bezierCurveTo(187.67, 109.6, 188.61, 109.14, 189.52, 108.61);
        context.bezierCurveTo(190.17, 108.23, 190.78, 107.77, 191.41, 107.34);
        context.bezierCurveTo(191.51, 107.27, 191.61, 107.17, 191.72, 107.13);
        context.bezierCurveTo(191.95, 107.04, 192.15, 106.82, 192.43, 106.99);
        context.bezierCurveTo(192.74, 107.17, 192.73, 107.47, 192.69, 107.75);
        context.bezierCurveTo(192.67, 107.88, 192.56, 108.05, 192.45, 108.08);
        context.bezierCurveTo(191.88, 108.25, 191.43, 108.53, 191.07, 109.02);
        context.bezierCurveTo(190.92, 109.23, 190.59, 109.44, 190.35, 109.43);
        context.bezierCurveTo(189.56, 109.37, 189.18, 109.87, 188.84, 110.41);
        context.bezierCurveTo(188.47, 110.99, 187.99, 111.24, 187.3, 111.2);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 602 Drawing
        context.beginPath();
        context.moveTo(195.82, 103.88);
        context.bezierCurveTo(195.79, 104.04, 195.8, 104.21, 195.73, 104.34);
        context.bezierCurveTo(195.59, 104.62, 195.78, 104.59, 195.94, 104.6);
        context.bezierCurveTo(196.13, 104.61, 196.33, 104.57, 196.52, 104.62);
        context.bezierCurveTo(196.66, 104.65, 196.84, 104.75, 196.89, 104.86);
        context.bezierCurveTo(196.94, 104.96, 196.87, 105.17, 196.78, 105.27);
        context.bezierCurveTo(196.44, 105.65, 196.12, 106.03, 195.5, 105.99);
        context.bezierCurveTo(195.13, 105.96, 194.91, 106.29, 194.82, 106.67);
        context.bezierCurveTo(194.72, 107.08, 194.55, 107.45, 194.08, 107.6);
        context.bezierCurveTo(193.69, 107.73, 193.31, 107.52, 193.27, 107.13);
        context.bezierCurveTo(193.23, 106.75, 193.39, 106.43, 193.59, 106.15);
        context.bezierCurveTo(193.76, 105.91, 193.96, 105.67, 194.2, 105.53);
        context.bezierCurveTo(195.1, 105.02, 195.12, 104.26, 194.9, 103.4);
        context.bezierCurveTo(194.88, 103.34, 194.83, 103.27, 194.84, 103.22);
        context.bezierCurveTo(194.87, 103.1, 194.89, 102.94, 194.97, 102.89);
        context.bezierCurveTo(195.05, 102.84, 195.23, 102.85, 195.33, 102.91);
        context.bezierCurveTo(195.69, 103.12, 195.89, 103.43, 195.82, 103.88);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 603 Drawing
        context.beginPath();
        context.moveTo(84.63, 35.46);
        context.bezierCurveTo(84.11, 35.46, 83.81, 34.98, 84.07, 34.51);
        context.bezierCurveTo(84.12, 34.41, 84.16, 34.31, 84.2, 34.21);
        context.bezierCurveTo(84, 34.13, 83.79, 34, 84.04, 33.83);
        context.bezierCurveTo(84.28, 33.67, 84.58, 33.41, 84.91, 33.72);
        context.bezierCurveTo(85.01, 33.81, 85.1, 33.9, 85.19, 33.99);
        context.bezierCurveTo(85.34, 34.14, 85.48, 34.18, 85.7, 34.07);
        context.bezierCurveTo(86.25, 33.79, 86.86, 33.72, 87.47, 33.79);
        context.bezierCurveTo(87.75, 33.82, 88.02, 33.91, 88.28, 34.02);
        context.bezierCurveTo(88.57, 34.15, 88.59, 34.37, 88.32, 34.5);
        context.bezierCurveTo(87.74, 34.77, 87.19, 35.07, 86.7, 35.5);
        context.bezierCurveTo(86.52, 35.64, 86.26, 35.84, 85.92, 35.57);
        context.bezierCurveTo(85.57, 35.29, 85.1, 35.22, 84.63, 35.46);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 604 Drawing
        context.beginPath();
        context.moveTo(45.56, 62.47);
        context.bezierCurveTo(46.65, 62.57, 47.47, 62.9, 48.23, 63.37);
        context.bezierCurveTo(48.77, 63.71, 49.23, 64.15, 49.88, 64.3);
        context.bezierCurveTo(50.01, 64.33, 50.15, 64.58, 50.18, 64.74);
        context.bezierCurveTo(50.22, 65, 49.97, 64.98, 49.79, 65.05);
        context.bezierCurveTo(48.99, 65.31, 48.18, 65.04, 47.8, 64.29);
        context.bezierCurveTo(47.58, 63.87, 47.35, 63.64, 46.85, 63.64);
        context.bezierCurveTo(46.55, 63.64, 46.24, 63.49, 45.95, 63.39);
        context.bezierCurveTo(45.44, 63.23, 44.96, 63.16, 44.52, 63.58);
        context.bezierCurveTo(44.35, 63.75, 44.14, 63.62, 44.17, 63.36);
        context.bezierCurveTo(44.19, 63.19, 44.27, 62.95, 44.39, 62.9);
        context.bezierCurveTo(44.81, 62.7, 45.27, 62.57, 45.56, 62.47);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 605 Drawing
        context.beginPath();
        context.moveTo(160.75, 83.07);
        context.bezierCurveTo(160.8, 83.08, 161.01, 83.13, 161.2, 83.24);
        context.bezierCurveTo(161.68, 83.51, 162.22, 83.57, 162.68, 83.4);
        context.bezierCurveTo(163.03, 83.28, 163.21, 83.31, 163.42, 83.55);
        context.bezierCurveTo(163.68, 83.83, 163.94, 84.1, 164.4, 84.06);
        context.bezierCurveTo(164.6, 84.05, 164.85, 84.25, 164.73, 84.53);
        context.bezierCurveTo(164.64, 84.73, 164.48, 84.81, 164.18, 84.73);
        context.bezierCurveTo(163.24, 84.47, 162.32, 84.12, 161.3, 84.2);
        context.bezierCurveTo(160.94, 84.23, 160.54, 83.96, 160.18, 83.79);
        context.bezierCurveTo(160.07, 83.73, 159.93, 83.48, 159.97, 83.41);
        context.bezierCurveTo(160.04, 83.26, 160.23, 83.17, 160.38, 83.07);
        context.bezierCurveTo(160.43, 83.04, 160.51, 83.07, 160.75, 83.07);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 606 Drawing
        context.beginPath();
        context.moveTo(177.24, 106.86);
        context.bezierCurveTo(177.54, 106.94, 177.8, 106.97, 178.02, 107.07);
        context.bezierCurveTo(178.38, 107.21, 178.5, 107.54, 178.28, 107.83);
        context.bezierCurveTo(177.8, 108.48, 177.18, 108.98, 176.4, 109.24);
        context.bezierCurveTo(176.2, 109.31, 175.98, 109.21, 176.03, 108.96);
        context.bezierCurveTo(176.16, 108.35, 176.29, 107.73, 176.52, 107.15);
        context.bezierCurveTo(176.59, 106.98, 177.01, 106.95, 177.24, 106.86);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 607 Drawing
        context.beginPath();
        context.moveTo(172.1, 86.96);
        context.bezierCurveTo(171.93, 87, 171.77, 87.09, 171.61, 87.06);
        context.bezierCurveTo(171.48, 87.05, 171.29, 86.81, 171.25, 86.84);
        context.bezierCurveTo(171.07, 86.96, 170.92, 87.13, 170.81, 87.32);
        context.bezierCurveTo(170.7, 87.5, 170.67, 87.73, 170.58, 87.93);
        context.bezierCurveTo(170.55, 88, 170.4, 88.09, 170.38, 88.07);
        context.bezierCurveTo(170.02, 87.82, 169.44, 88.07, 169.2, 87.58);
        context.bezierCurveTo(169.12, 87.42, 169.07, 87.23, 169.07, 87.06);
        context.bezierCurveTo(169.08, 86.86, 169.35, 86.78, 169.49, 86.9);
        context.bezierCurveTo(169.71, 87.09, 169.92, 87.28, 170.13, 87.47);
        context.bezierCurveTo(170.15, 87.15, 170.18, 86.84, 170.2, 86.52);
        context.bezierCurveTo(170.21, 86.27, 170.18, 86.01, 170.22, 85.76);
        context.bezierCurveTo(170.28, 85.46, 170.7, 85.26, 170.89, 85.45);
        context.bezierCurveTo(171.3, 85.85, 171.66, 86.3, 172.03, 86.73);
        context.bezierCurveTo(172.06, 86.77, 172.06, 86.83, 172.1, 86.96);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 608 Drawing
        context.beginPath();
        context.moveTo(173.77, 49.68);
        context.bezierCurveTo(173.36, 49.72, 173.21, 49.52, 173.32, 49.28);
        context.bezierCurveTo(173.57, 48.74, 173.42, 48.25, 173.22, 47.74);
        context.bezierCurveTo(173.2, 47.7, 173.21, 47.61, 173.23, 47.6);
        context.bezierCurveTo(173.28, 47.58, 173.38, 47.57, 173.41, 47.6);
        context.bezierCurveTo(173.87, 48.07, 174.44, 48.2, 175.08, 48.14);
        context.bezierCurveTo(175.11, 48.14, 175.14, 48.13, 175.17, 48.14);
        context.bezierCurveTo(175.49, 48.27, 175.94, 48.3, 175.99, 48.7);
        context.bezierCurveTo(176.04, 49.11, 175.57, 49.17, 175.31, 49.27);
        context.bezierCurveTo(174.79, 49.47, 174.22, 49.57, 173.77, 49.68);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();




        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawWorldBackground2(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Group 2
        context.save();

        //// Clip Bezier 35

        context.beginPath();
        context.moveTo(110.52, 0.56);
        context.lineTo(41.37, 0.56);
        context.bezierCurveTo(39.38, 0.56, 37.54, 1.62, 36.54, 3.35);
        context.lineTo(1.97, 63.23);
        context.bezierCurveTo(0.97, 64.96, 0.97, 67.08, 1.97, 68.8);
        context.lineTo(36.54, 128.69);
        context.bezierCurveTo(37.54, 130.42, 39.38, 131.48, 41.37, 131.48);
        context.lineTo(110.52, 131.48);
        context.bezierCurveTo(112.51, 131.48, 114.35, 130.42, 115.34, 128.69);
        context.lineTo(149.92, 68.8);
        context.bezierCurveTo(150.91, 67.08, 150.91, 64.96, 149.92, 63.23);
        context.lineTo(115.34, 3.35);
        context.bezierCurveTo(114.35, 1.62, 112.51, 0.56, 110.52, 0.56);
        context.closePath();
        context.clip();



        //// Bezier 586 Drawing
        context.beginPath();
        context.moveTo(34.79, -8.36);
        context.lineTo(-10.43, 60.81);
        context.lineTo(36.57, 142.4);
        context.lineTo(122.58, 136.19);
        context.lineTo(158.94, 63.47);
        context.lineTo(120.81, -3.92);
        context.lineTo(34.79, -8.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Group 29
        //// Bezier 587 Drawing
        context.beginPath();
        context.moveTo(101.87, 36.31);
        context.bezierCurveTo(100.86, 36.39, 99.88, 36.57, 98.89, 36.84);
        context.bezierCurveTo(97.76, 37.14, 96.74, 37.59, 95.79, 38.24);
        context.bezierCurveTo(95.6, 38.38, 95.42, 38.57, 95.3, 38.78);
        context.bezierCurveTo(95.05, 39.24, 95.09, 39.42, 95.46, 39.8);
        context.bezierCurveTo(96.44, 40.79, 97.44, 41.75, 98.4, 42.76);
        context.bezierCurveTo(98.97, 43.35, 99.54, 43.97, 99.7, 44.83);
        context.bezierCurveTo(99.74, 45.03, 99.76, 45.27, 99.69, 45.45);
        context.bezierCurveTo(99.39, 46.25, 99, 47.02, 98.72, 47.82);
        context.bezierCurveTo(98.44, 48.66, 98.22, 49.53, 98.27, 50.43);
        context.bezierCurveTo(98.33, 51.49, 98.59, 52.49, 99.14, 53.4);
        context.bezierCurveTo(99.24, 53.56, 99.33, 53.73, 99.41, 53.91);
        context.bezierCurveTo(99.63, 54.34, 99.49, 54.58, 99.02, 54.54);
        context.bezierCurveTo(98.9, 54.53, 98.78, 54.5, 98.66, 54.45);
        context.bezierCurveTo(97.51, 53.99, 96.51, 53.36, 95.93, 52.2);
        context.bezierCurveTo(95.86, 52.06, 95.81, 51.91, 95.73, 51.77);
        context.bezierCurveTo(95.36, 51.17, 94.81, 51.01, 94.1, 51.2);
        context.bezierCurveTo(93.65, 51.31, 93.26, 51.49, 92.93, 51.81);
        context.bezierCurveTo(92.77, 51.95, 92.61, 52.11, 92.51, 52.29);
        context.bezierCurveTo(92.4, 52.5, 92.37, 52.73, 92.71, 52.8);
        context.bezierCurveTo(92.86, 52.83, 93, 52.92, 93.15, 52.96);
        context.bezierCurveTo(93.95, 53.17, 94.4, 53.72, 94.57, 54.48);
        context.bezierCurveTo(94.77, 55.32, 95.01, 56.13, 95.58, 56.8);
        context.bezierCurveTo(95.89, 57.16, 95.91, 57.62, 96.03, 58.04);
        context.bezierCurveTo(96.22, 58.68, 96.37, 59.32, 96.33, 60.01);
        context.bezierCurveTo(96.26, 61.69, 95.37, 62.63, 93.64, 62.7);
        context.bezierCurveTo(92.94, 62.73, 92.24, 62.8, 91.55, 62.9);
        context.bezierCurveTo(90.51, 63.06, 89.24, 64.46, 89.16, 65.51);
        context.bezierCurveTo(89.13, 65.97, 89.38, 66.29, 89.67, 66.61);
        context.bezierCurveTo(90.08, 67.06, 90.51, 67.5, 90.85, 67.99);
        context.bezierCurveTo(91.42, 68.81, 91.57, 69.74, 91.48, 70.73);
        context.bezierCurveTo(91.43, 71.32, 90.3, 72.5, 89.68, 72.57);
        context.bezierCurveTo(89.48, 72.59, 89.21, 72.53, 89.07, 72.39);
        context.bezierCurveTo(88.43, 71.76, 87.83, 71.1, 87.21, 70.44);
        context.bezierCurveTo(87.1, 70.33, 87.01, 70.19, 86.89, 70.09);
        context.bezierCurveTo(86.72, 69.96, 86.52, 69.85, 86.33, 69.73);
        context.bezierCurveTo(86.3, 69.95, 86.28, 70.16, 86.25, 70.38);
        context.bezierCurveTo(86.18, 70.72, 86.19, 71.11, 86.03, 71.39);
        context.bezierCurveTo(85.69, 71.96, 85.91, 72.48, 86.15, 72.92);
        context.bezierCurveTo(86.42, 73.41, 86.74, 73.89, 87.29, 74.19);
        context.bezierCurveTo(87.75, 74.44, 88.09, 74.86, 88.27, 75.43);
        context.bezierCurveTo(88.47, 76.02, 88.74, 76.63, 89.24, 77.07);
        context.bezierCurveTo(89.3, 77.11, 89.34, 77.26, 89.31, 77.32);
        context.bezierCurveTo(89.28, 77.38, 89.15, 77.43, 89.07, 77.43);
        context.bezierCurveTo(88.52, 77.45, 88.1, 77.14, 87.69, 76.84);
        context.bezierCurveTo(87.16, 76.45, 86.88, 75.87, 86.63, 75.27);
        context.bezierCurveTo(86.45, 74.84, 86.23, 74.41, 86, 74);
        context.bezierCurveTo(85.61, 73.3, 85.3, 72.6, 85.25, 71.77);
        context.bezierCurveTo(85.19, 70.53, 85.04, 69.3, 84.87, 68.07);
        context.bezierCurveTo(84.79, 67.42, 84.55, 67.25, 83.86, 67.16);
        context.bezierCurveTo(83.83, 67.16, 83.8, 67.16, 83.76, 67.16);
        context.bezierCurveTo(83.15, 67.19, 82.57, 67.16, 82.05, 66.73);
        context.bezierCurveTo(81.49, 66.27, 81.1, 65.7, 80.82, 65.04);
        context.bezierCurveTo(80.69, 64.72, 80.58, 64.37, 80.37, 64.11);
        context.bezierCurveTo(79.79, 63.4, 79.23, 63.31, 78.46, 63.81);
        context.bezierCurveTo(77.55, 64.4, 76.7, 65.06, 75.8, 65.67);
        context.bezierCurveTo(75.26, 66.03, 74.84, 66.49, 74.46, 67.01);
        context.bezierCurveTo(73.88, 67.8, 73.66, 68.69, 73.92, 69.62);
        context.bezierCurveTo(74.23, 70.75, 73.88, 71.7, 73.31, 72.61);
        context.bezierCurveTo(72.99, 73.12, 72.61, 73.15, 72.24, 72.62);
        context.bezierCurveTo(71.68, 71.81, 71.16, 70.96, 70.76, 70.07);
        context.bezierCurveTo(70.13, 68.66, 69.61, 67.19, 69.05, 65.75);
        context.bezierCurveTo(68.9, 65.37, 68.76, 64.98, 68.6, 64.6);
        context.bezierCurveTo(68.47, 64.28, 68.37, 63.94, 68.2, 63.64);
        context.bezierCurveTo(67.91, 63.13, 67.53, 62.8, 66.88, 62.88);
        context.bezierCurveTo(65.86, 63.01, 65.04, 62.59, 64.25, 61.97);
        context.bezierCurveTo(63.77, 61.6, 63.15, 61.36, 62.55, 61.17);
        context.bezierCurveTo(62.04, 61.01, 61.48, 61.05, 60.95, 60.94);
        context.bezierCurveTo(59.68, 60.7, 58.41, 60.48, 57.16, 60.15);
        context.bezierCurveTo(56.05, 59.85, 55.4, 58.9, 54.66, 58.11);
        context.bezierCurveTo(54.6, 58.04, 54.6, 57.91, 54.53, 57.85);
        context.bezierCurveTo(54.4, 57.73, 54.25, 57.64, 54.1, 57.53);
        context.bezierCurveTo(54.02, 57.7, 53.9, 57.85, 53.87, 58.03);
        context.bezierCurveTo(53.66, 59.08, 53.97, 60.02, 54.63, 60.82);
        context.bezierCurveTo(55.08, 61.37, 55.7, 61.45, 56.38, 61.25);
        context.bezierCurveTo(56.85, 61.11, 57.31, 60.7, 57.78, 60.91);
        context.bezierCurveTo(58.23, 61.12, 58.56, 61.56, 58.96, 61.87);
        context.bezierCurveTo(59.34, 62.17, 59.73, 62.43, 60.12, 62.71);
        context.bezierCurveTo(60.73, 63.13, 60.82, 63.54, 60.41, 64.17);
        context.bezierCurveTo(60.39, 64.2, 60.38, 64.22, 60.35, 64.25);
        context.bezierCurveTo(59.08, 65.48, 57.83, 66.73, 56.53, 67.93);
        context.bezierCurveTo(55.92, 68.5, 55.22, 68.94, 54.57, 69.46);
        context.bezierCurveTo(54.06, 69.88, 53.44, 70.03, 52.83, 70.19);
        context.bezierCurveTo(52.5, 70.28, 51.91, 69.98, 51.73, 69.67);
        context.bezierCurveTo(51.23, 68.81, 50.8, 67.91, 50.6, 66.95);
        context.bezierCurveTo(50.42, 66.09, 49.95, 65.44, 49.45, 64.78);
        context.bezierCurveTo(48.86, 64, 48.21, 63.25, 47.65, 62.44);
        context.bezierCurveTo(46.82, 61.23, 46.2, 59.9, 45.67, 58.53);
        context.bezierCurveTo(45.63, 58.43, 45.49, 58.38, 45.4, 58.3);
        context.bezierCurveTo(45.38, 58.39, 45.35, 58.48, 45.34, 58.58);
        context.bezierCurveTo(45.33, 58.64, 45.34, 58.71, 45.34, 58.77);
        context.bezierCurveTo(45.31, 58.96, 45.29, 59.14, 45.26, 59.33);
        context.bezierCurveTo(45.11, 59.22, 44.93, 59.12, 44.83, 58.98);
        context.bezierCurveTo(44.58, 58.61, 44.37, 58.21, 44.16, 57.82);
        context.bezierCurveTo(43.95, 57.4, 43.96, 57.4, 44.44, 57.26);
        context.bezierCurveTo(44.97, 57.1, 45.27, 56.69, 45.53, 56.24);
        context.bezierCurveTo(45.98, 55.49, 45.95, 54.65, 45.89, 53.82);
        context.bezierCurveTo(45.88, 53.66, 45.7, 53.5, 45.59, 53.35);
        context.bezierCurveTo(45.41, 53.09, 45.25, 53.36, 45.09, 53.39);
        context.bezierCurveTo(44.47, 53.49, 43.86, 53.67, 43.23, 53.75);
        context.bezierCurveTo(42.26, 53.86, 41.4, 53.52, 40.69, 52.88);
        context.bezierCurveTo(40.12, 52.37, 40.22, 51.64, 40.35, 50.98);
        context.bezierCurveTo(40.39, 50.78, 40.76, 50.58, 41.01, 50.5);
        context.bezierCurveTo(41.55, 50.31, 42.16, 50.29, 42.65, 50.04);
        context.bezierCurveTo(43.56, 49.59, 44.4, 49.72, 45.3, 50.05);
        context.bezierCurveTo(46.09, 50.35, 46.91, 50.42, 47.72, 50.08);
        context.bezierCurveTo(48.08, 49.94, 48.13, 49.87, 48.08, 49.4);
        context.bezierCurveTo(48.04, 48.98, 48.15, 48.82, 48.53, 48.93);
        context.bezierCurveTo(49.32, 49.15, 50.1, 49.4, 50.87, 49.67);
        context.bezierCurveTo(51.43, 49.88, 51.98, 50.14, 52.53, 50.39);
        context.bezierCurveTo(52.78, 50.51, 52.89, 50.42, 52.89, 50.17);
        context.bezierCurveTo(52.89, 49.91, 52.91, 49.64, 52.84, 49.4);
        context.bezierCurveTo(52.63, 48.68, 52.69, 47.98, 52.83, 47.24);
        context.bezierCurveTo(52.97, 46.5, 53.06, 45.7, 52.58, 45);
        context.bezierCurveTo(52.51, 44.9, 52.46, 44.77, 52.42, 44.66);
        context.bezierCurveTo(52.08, 43.82, 52.37, 43.42, 53.26, 43.49);
        context.bezierCurveTo(53.41, 43.5, 53.56, 43.57, 53.71, 43.61);
        context.bezierCurveTo(54.16, 43.72, 54.58, 43.72, 55.01, 43.5);
        context.bezierCurveTo(55.42, 43.28, 55.59, 42.98, 55.62, 42.51);
        context.bezierCurveTo(55.7, 41.28, 55.21, 40.2, 54.78, 39.12);
        context.bezierCurveTo(54.39, 38.13, 53.74, 37.3, 53.12, 36.44);
        context.bezierCurveTo(53.01, 36.29, 52.92, 36.12, 52.82, 35.96);
        context.bezierCurveTo(52.34, 35.23, 52.46, 34.71, 53.28, 34.39);
        context.bezierCurveTo(53.86, 34.17, 54.21, 33.77, 54.5, 33.26);
        context.bezierCurveTo(54.65, 33.02, 54.83, 32.79, 54.97, 32.54);
        context.bezierCurveTo(55.21, 32.11, 55.33, 31.69, 55.08, 31.18);
        context.bezierCurveTo(54.76, 30.5, 54.92, 30.28, 55.69, 30.3);
        context.bezierCurveTo(56.33, 30.31, 56.97, 30.21, 57.6, 30.46);
        context.bezierCurveTo(57.84, 30.55, 58.16, 30.45, 58.45, 30.42);
        context.bezierCurveTo(58.6, 30.4, 58.76, 30.28, 58.9, 30.3);
        context.bezierCurveTo(59.7, 30.42, 60.37, 30.04, 60.96, 29.64);
        context.bezierCurveTo(62.4, 28.64, 64.03, 28.12, 65.71, 27.81);
        context.bezierCurveTo(66.83, 27.6, 68, 27.42, 69.15, 27.59);
        context.bezierCurveTo(70.21, 27.74, 71.26, 27.97, 72.21, 28.53);
        context.bezierCurveTo(73.56, 29.32, 75.04, 29.75, 76.55, 30.08);
        context.bezierCurveTo(76.58, 30.09, 76.61, 30.09, 76.65, 30.09);
        context.bezierCurveTo(77.63, 30.16, 78.62, 30.24, 79.61, 30.28);
        context.bezierCurveTo(81.34, 30.36, 83.08, 30.32, 84.79, 30.52);
        context.bezierCurveTo(85.95, 30.66, 87.12, 30.49, 88.24, 30.72);
        context.bezierCurveTo(89.34, 30.95, 90.48, 30.64, 91.55, 31.06);
        context.bezierCurveTo(91.61, 31.08, 91.68, 31.06, 91.74, 31.06);
        context.bezierCurveTo(92.79, 31.13, 93.88, 31.06, 94.89, 31.3);
        context.bezierCurveTo(95.77, 31.51, 96.68, 31.26, 97.53, 31.63);
        context.bezierCurveTo(97.72, 31.71, 97.98, 31.65, 98.2, 31.65);
        context.bezierCurveTo(99.37, 31.65, 100.54, 31.55, 101.69, 31.67);
        context.bezierCurveTo(103.94, 31.91, 106.19, 31.77, 108.43, 31.86);
        context.bezierCurveTo(109.15, 31.88, 109.74, 32.23, 110.09, 32.95);
        context.bezierCurveTo(110.59, 33.98, 110.62, 34.94, 109.91, 35.89);
        context.bezierCurveTo(109.52, 36.42, 108.99, 36.74, 108.36, 36.89);
        context.bezierCurveTo(107.78, 37.03, 107.19, 37.15, 106.61, 37.28);
        context.bezierCurveTo(106.58, 37.29, 106.54, 37.27, 106.51, 37.28);
        context.bezierCurveTo(106.18, 37.38, 105.79, 37.45, 105.66, 37.82);
        context.bezierCurveTo(105.52, 38.21, 105.89, 38.38, 106.1, 38.61);
        context.bezierCurveTo(106.49, 39.07, 106.93, 39.5, 107.27, 40);
        context.bezierCurveTo(107.72, 40.67, 107.95, 41.44, 107.95, 42.25);
        context.bezierCurveTo(107.96, 42.83, 107.61, 43.06, 107.07, 42.85);
        context.bezierCurveTo(106.74, 42.72, 106.41, 42.56, 106.15, 42.34);
        context.bezierCurveTo(105.39, 41.69, 104.67, 41.01, 103.93, 40.34);
        context.bezierCurveTo(103.13, 39.61, 103.13, 38.7, 103.47, 37.78);
        context.bezierCurveTo(103.87, 36.71, 103.63, 36.33, 102.45, 36.31);
        context.bezierCurveTo(102.26, 36.3, 102.06, 36.31, 101.87, 36.31);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 588 Drawing
        context.beginPath();
        context.moveTo(29.87, 52.99);
        context.bezierCurveTo(30.15, 52.99, 30.35, 52.98, 30.54, 52.99);
        context.bezierCurveTo(31.29, 53, 31.51, 53.28, 31.42, 54.05);
        context.bezierCurveTo(31.38, 54.4, 31.37, 54.76, 31.37, 55.11);
        context.bezierCurveTo(31.38, 55.7, 31.52, 55.97, 32.09, 56.06);
        context.bezierCurveTo(33.38, 56.27, 34.49, 56.88, 35.62, 57.45);
        context.bezierCurveTo(36.25, 57.76, 36.6, 57.64, 36.93, 57.02);
        context.bezierCurveTo(37.03, 56.82, 37.1, 56.61, 37.18, 56.41);
        context.bezierCurveTo(37.43, 55.78, 37.94, 55.56, 38.55, 55.82);
        context.bezierCurveTo(38.94, 55.98, 39.31, 56.17, 39.67, 56.37);
        context.bezierCurveTo(40.6, 56.87, 41.58, 57.2, 42.65, 57.27);
        context.bezierCurveTo(43.17, 57.31, 43.6, 57.56, 43.79, 58.14);
        context.bezierCurveTo(44.06, 58.96, 44.52, 59.68, 45.15, 60.3);
        context.bezierCurveTo(45.64, 60.79, 46.03, 61.37, 46.35, 62.01);
        context.bezierCurveTo(47.19, 63.72, 47.95, 65.46, 48.65, 67.23);
        context.bezierCurveTo(48.86, 67.77, 49.26, 68.33, 49.72, 68.65);
        context.bezierCurveTo(50.56, 69.23, 51.01, 70.02, 51.37, 70.91);
        context.bezierCurveTo(51.44, 71.09, 51.53, 71.27, 51.64, 71.42);
        context.bezierCurveTo(51.96, 71.87, 52.37, 71.98, 52.95, 71.82);
        context.bezierCurveTo(53.75, 71.59, 54.49, 71.26, 55.19, 70.8);
        context.bezierCurveTo(55.44, 70.63, 55.77, 70.56, 56.05, 70.43);
        context.bezierCurveTo(56.2, 70.37, 56.34, 70.23, 56.45, 70.44);
        context.bezierCurveTo(56.52, 70.57, 56.6, 70.77, 56.55, 70.88);
        context.bezierCurveTo(56.4, 71.27, 56.22, 71.65, 55.99, 71.99);
        context.bezierCurveTo(54.85, 73.71, 53.68, 75.42, 52.53, 77.13);
        context.bezierCurveTo(51.91, 78.05, 51.31, 78.98, 50.69, 79.91);
        context.bezierCurveTo(50.17, 80.7, 49.61, 81.48, 49.12, 82.29);
        context.bezierCurveTo(48.75, 82.9, 48.59, 83.57, 48.62, 84.3);
        context.bezierCurveTo(48.66, 85.45, 48.7, 86.57, 48.96, 87.71);
        context.bezierCurveTo(49.24, 88.92, 49.08, 90.17, 47.94, 91.02);
        context.bezierCurveTo(47.59, 91.28, 47.22, 91.54, 46.82, 91.72);
        context.bezierCurveTo(46.33, 91.94, 46.06, 92.32, 45.93, 92.79);
        context.bezierCurveTo(45.82, 93.2, 45.69, 93.57, 45.85, 94.07);
        context.bezierCurveTo(46.09, 94.83, 45.8, 95.63, 45.55, 96.39);
        context.bezierCurveTo(45.31, 97.11, 44.82, 97.66, 44.35, 98.22);
        context.bezierCurveTo(43.54, 99.16, 42.88, 100.23, 41.97, 101.1);
        context.bezierCurveTo(41.02, 102, 39.96, 102.59, 38.67, 102.88);
        context.bezierCurveTo(37.84, 103.07, 37.05, 103.12, 36.24, 102.85);
        context.bezierCurveTo(35.58, 102.63, 35.18, 102.21, 35.05, 101.5);
        context.bezierCurveTo(34.82, 100.33, 34.52, 99.17, 34.3, 97.99);
        context.bezierCurveTo(34.18, 97.34, 34.18, 96.66, 34.08, 96);
        context.bezierCurveTo(33.85, 94.6, 33.66, 93.19, 32.97, 91.91);
        context.bezierCurveTo(32.82, 91.63, 32.67, 91.35, 32.55, 91.05);
        context.bezierCurveTo(32.01, 89.66, 32.13, 88.36, 33.01, 87.12);
        context.bezierCurveTo(33.7, 86.15, 33.59, 85.18, 32.84, 84.24);
        context.bezierCurveTo(32.02, 83.21, 31.42, 82.07, 30.98, 80.84);
        context.bezierCurveTo(30.6, 79.79, 30.75, 78.75, 31.04, 77.74);
        context.bezierCurveTo(31.18, 77.27, 31.04, 76.9, 30.8, 76.62);
        context.bezierCurveTo(30.46, 76.21, 30.03, 75.83, 29.56, 75.58);
        context.bezierCurveTo(28.34, 74.92, 27, 74.61, 25.61, 74.75);
        context.bezierCurveTo(24.71, 74.83, 23.79, 75.01, 22.98, 75.45);
        context.bezierCurveTo(22.05, 75.95, 21.05, 76.02, 20.08, 75.85);
        context.bezierCurveTo(19.16, 75.69, 18.28, 75.26, 17.57, 74.59);
        context.bezierCurveTo(16.74, 73.82, 15.96, 73.01, 15.41, 72.02);
        context.bezierCurveTo(14.88, 71.08, 14.47, 70.09, 14.49, 68.96);
        context.bezierCurveTo(14.5, 67.75, 14.6, 66.55, 14.87, 65.36);
        context.bezierCurveTo(15.06, 64.47, 15.08, 63.54, 15.29, 62.65);
        context.bezierCurveTo(15.54, 61.57, 15.92, 60.57, 16.96, 59.89);
        context.bezierCurveTo(18.38, 58.94, 19.38, 57.57, 20.23, 56.08);
        context.bezierCurveTo(20.61, 55.41, 20.96, 54.73, 21.79, 54.5);
        context.bezierCurveTo(21.91, 54.46, 22.02, 54.37, 22.14, 54.36);
        context.bezierCurveTo(22.77, 54.28, 23.41, 54.26, 24.03, 54.15);
        context.bezierCurveTo(24.74, 54.02, 25.44, 53.82, 26.14, 53.67);
        context.bezierCurveTo(27.41, 53.39, 28.63, 52.91, 29.87, 52.99);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 589 Drawing
        context.beginPath();
        context.moveTo(-25.83, 69.76);
        context.bezierCurveTo(-25.83, 70.05, -25.81, 70.35, -25.83, 70.64);
        context.bezierCurveTo(-25.92, 71.8, -25.36, 71.92, -24.62, 72.17);
        context.bezierCurveTo(-24.12, 72.34, -23.55, 72.34, -23.02, 72.38);
        context.bezierCurveTo(-22.74, 72.4, -22.5, 72.4, -22.52, 72.78);
        context.bezierCurveTo(-22.55, 73.2, -22.76, 73.41, -23.12, 73.35);
        context.bezierCurveTo(-23.21, 73.34, -23.29, 73.24, -23.38, 73.23);
        context.bezierCurveTo(-23.53, 73.22, -23.76, 73.17, -23.82, 73.25);
        context.bezierCurveTo(-24.28, 73.77, -24.71, 73.41, -25.09, 73.21);
        context.bezierCurveTo(-25.77, 72.85, -26.4, 72.41, -26.91, 71.81);
        context.bezierCurveTo(-27.24, 71.42, -27.62, 71.06, -28, 70.71);
        context.bezierCurveTo(-29.77, 69.1, -31.77, 67.92, -34.11, 67.33);
        context.bezierCurveTo(-34.96, 67.12, -35.83, 66.93, -36.64, 66.54);
        context.bezierCurveTo(-37.58, 66.08, -38.23, 65.4, -38.7, 64.49);
        context.bezierCurveTo(-39.87, 62.18, -41.37, 60.08, -42.76, 57.92);
        context.bezierCurveTo(-43.11, 57.38, -43.49, 56.87, -43.89, 56.37);
        context.bezierCurveTo(-44.67, 55.4, -44.95, 54.22, -45.21, 53.05);
        context.bezierCurveTo(-45.35, 52.4, -45.4, 51.72, -45.41, 51.05);
        context.bezierCurveTo(-45.43, 49.52, -44.85, 48.19, -43.84, 47.07);
        context.bezierCurveTo(-43.43, 46.62, -42.91, 46.29, -42.47, 45.86);
        context.bezierCurveTo(-41.84, 45.25, -41.54, 44.48, -41.31, 43.61);
        context.bezierCurveTo(-41.04, 42.63, -41.18, 41.71, -41.38, 40.79);
        context.bezierCurveTo(-41.62, 39.68, -42.39, 38.9, -43.29, 38.24);
        context.bezierCurveTo(-44.05, 37.69, -44.93, 37.49, -45.83, 37.26);
        context.bezierCurveTo(-47.58, 36.81, -49.19, 37.24, -50.73, 37.93);
        context.bezierCurveTo(-51.59, 38.31, -52.34, 38.95, -53.13, 39.48);
        context.bezierCurveTo(-53.63, 39.82, -54.1, 40.21, -54.62, 40.53);
        context.bezierCurveTo(-55.53, 41.1, -56.4, 40.67, -56.46, 39.61);
        context.bezierCurveTo(-56.55, 38.12, -56.08, 36.88, -54.64, 36.25);
        context.bezierCurveTo(-53.5, 35.74, -52.46, 35.04, -51.54, 34.18);
        context.bezierCurveTo(-51.45, 34.1, -51.34, 34.02, -51.27, 33.91);
        context.bezierCurveTo(-50.71, 32.92, -49.74, 32.41, -48.84, 31.81);
        context.bezierCurveTo(-47.76, 31.09, -46.56, 30.71, -45.31, 30.49);
        context.bezierCurveTo(-44.41, 30.32, -43.5, 30.16, -42.58, 30.12);
        context.bezierCurveTo(-41.96, 30.1, -41.33, 30.28, -40.72, 30.42);
        context.bezierCurveTo(-39.64, 30.67, -38.59, 31, -37.51, 31.23);
        context.bezierCurveTo(-36.86, 31.37, -36.16, 31.31, -35.52, 31.47);
        context.bezierCurveTo(-34.41, 31.75, -33.29, 31.62, -32.17, 31.63);
        context.bezierCurveTo(-31.57, 31.63, -30.96, 31.44, -30.37, 31.47);
        context.bezierCurveTo(-29.17, 31.54, -27.96, 31.69, -26.76, 31.79);
        context.bezierCurveTo(-25.62, 31.89, -24.5, 32.13, -23.37, 32.26);
        context.bezierCurveTo(-22.57, 32.36, -21.78, 32.39, -20.98, 32.43);
        context.bezierCurveTo(-20.02, 32.47, -19.26, 32.04, -18.57, 31.45);
        context.bezierCurveTo(-18.25, 31.18, -17.98, 30.84, -17.66, 30.57);
        context.bezierCurveTo(-17.45, 30.38, -17.2, 30.2, -16.94, 30.11);
        context.bezierCurveTo(-16.37, 29.92, -15.81, 30.12, -15.45, 30.71);
        context.bezierCurveTo(-14.63, 32.07, -13.41, 32.19, -12.03, 31.92);
        context.bezierCurveTo(-11.94, 31.9, -11.84, 31.83, -11.76, 31.86);
        context.bezierCurveTo(-11.62, 31.9, -11.44, 31.97, -11.37, 32.08);
        context.bezierCurveTo(-11.32, 32.18, -11.37, 32.41, -11.46, 32.48);
        context.bezierCurveTo(-11.96, 32.93, -12.44, 33.41, -13.01, 33.77);
        context.bezierCurveTo(-14.76, 34.87, -16.61, 35.81, -18.48, 36.7);
        context.bezierCurveTo(-19.14, 37.01, -19.8, 37.33, -20.47, 37.61);
        context.bezierCurveTo(-21, 37.83, -21.15, 38.25, -21.17, 38.76);
        context.bezierCurveTo(-21.21, 39.51, -20.85, 40.13, -20.39, 40.65);
        context.bezierCurveTo(-19.88, 41.22, -19.3, 41.74, -18.66, 42.17);
        context.bezierCurveTo(-18.23, 42.45, -17.67, 42.63, -17.16, 42.68);
        context.bezierCurveTo(-16.49, 42.73, -16.07, 42.24, -15.74, 41.7);
        context.bezierCurveTo(-14.78, 40.16, -13.81, 38.62, -12.84, 37.09);
        context.bezierCurveTo(-12.66, 36.8, -12.46, 36.5, -12.23, 36.24);
        context.bezierCurveTo(-11.84, 35.8, -11.32, 35.79, -10.89, 36.21);
        context.bezierCurveTo(-10.68, 36.41, -10.52, 36.65, -10.32, 36.86);
        context.bezierCurveTo(-10.08, 37.1, -9.83, 37.35, -9.56, 37.55);
        context.bezierCurveTo(-9.12, 37.87, -8.64, 37.95, -8.12, 37.68);
        context.bezierCurveTo(-7.88, 37.54, -7.56, 37.55, -7.31, 37.42);
        context.bezierCurveTo(-6.73, 37.09, -6.22, 37.34, -5.8, 37.65);
        context.bezierCurveTo(-4.6, 38.55, -3.94, 39.82, -3.74, 41.29);
        context.bezierCurveTo(-3.57, 42.51, -4.56, 43.58, -5.8, 43.69);
        context.bezierCurveTo(-6.71, 43.78, -7.63, 43.87, -8.52, 44.09);
        context.bezierCurveTo(-9.93, 44.44, -11.07, 45.2, -11.84, 46.49);
        context.bezierCurveTo(-12.24, 47.17, -12.64, 47.87, -13.12, 48.49);
        context.bezierCurveTo(-13.75, 49.29, -14.69, 49.68, -15.6, 50.08);
        context.bezierCurveTo(-15.8, 50.17, -16.01, 50.23, -16.22, 50.3);
        context.bezierCurveTo(-17.43, 50.74, -17.98, 51.69, -18.27, 52.88);
        context.bezierCurveTo(-18.53, 53.89, -19.21, 54.61, -20.08, 55.15);
        context.bezierCurveTo(-20.4, 55.35, -20.74, 55.55, -21.1, 55.67);
        context.bezierCurveTo(-22.28, 56.06, -22.76, 56.95, -22.92, 58.09);
        context.bezierCurveTo(-23, 58.72, -23.05, 59.36, -23.12, 59.99);
        context.bezierCurveTo(-23.13, 60.05, -23.19, 60.14, -23.22, 60.14);
        context.bezierCurveTo(-23.31, 60.13, -23.44, 60.11, -23.47, 60.05);
        context.bezierCurveTo(-23.62, 59.81, -23.76, 59.55, -23.86, 59.28);
        context.bezierCurveTo(-24.28, 58.15, -25.2, 57.61, -26.26, 57.28);
        context.bezierCurveTo(-27.39, 56.94, -28.52, 57, -29.65, 57.33);
        context.bezierCurveTo(-30.86, 57.68, -31.77, 58.42, -32.53, 59.41);
        context.bezierCurveTo(-33.27, 60.38, -33.55, 61.51, -33.75, 62.67);
        context.bezierCurveTo(-33.82, 63.08, -33.69, 63.56, -33.57, 63.98);
        context.bezierCurveTo(-33.3, 64.9, -32.84, 65.15, -31.88, 65.23);
        context.bezierCurveTo(-30.35, 65.36, -29.09, 64.77, -27.91, 63.9);
        context.bezierCurveTo(-27.8, 63.81, -27.68, 63.6, -27.54, 63.82);
        context.bezierCurveTo(-27.46, 63.92, -27.43, 64.11, -27.47, 64.23);
        context.bezierCurveTo(-27.71, 64.82, -27.99, 65.39, -28.23, 65.98);
        context.bezierCurveTo(-28.42, 66.41, -28.59, 66.86, -28.72, 67.31);
        context.bezierCurveTo(-28.79, 67.55, -28.69, 67.77, -28.37, 67.73);
        context.bezierCurveTo(-28.09, 67.7, -27.81, 67.66, -27.53, 67.61);
        context.bezierCurveTo(-26.51, 67.43, -25.82, 67.37, -25.83, 68.89);
        context.bezierCurveTo(-25.83, 69.18, -25.83, 69.47, -25.83, 69.76);
        context.lineTo(-25.83, 69.76);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 590 Drawing
        context.beginPath();
        context.moveTo(-18.93, 70.78);
        context.bezierCurveTo(-18.57, 70.85, -18.32, 70.9, -18.07, 70.94);
        context.bezierCurveTo(-17.04, 71.09, -16.04, 71.39, -14.99, 71.43);
        context.bezierCurveTo(-14.26, 71.46, -13.59, 71.69, -12.95, 72.13);
        context.bezierCurveTo(-11.54, 73.1, -10.06, 73.97, -8.63, 74.9);
        context.bezierCurveTo(-7.52, 75.62, -6.57, 76.51, -5.77, 77.58);
        context.bezierCurveTo(-5.07, 78.5, -4.12, 79.17, -3.12, 79.76);
        context.bezierCurveTo(-2.03, 80.42, -0.82, 80.79, 0.4, 81.14);
        context.bezierCurveTo(1.21, 81.38, 2.06, 81.53, 2.77, 82.05);
        context.bezierCurveTo(3.6, 82.65, 3.82, 83.33, 3.34, 84.24);
        context.bezierCurveTo(2.55, 85.73, 1.97, 87.3, 1.59, 88.94);
        context.bezierCurveTo(1.48, 89.4, 1.37, 89.86, 1.33, 90.33);
        context.bezierCurveTo(1.19, 91.87, 0.39, 93.06, -0.78, 93.95);
        context.bezierCurveTo(-1.59, 94.56, -2.17, 95.33, -2.78, 96.11);
        context.bezierCurveTo(-3.55, 97.09, -4.04, 98.24, -4.73, 99.27);
        context.bezierCurveTo(-5.72, 100.78, -6.65, 102.35, -7.72, 103.8);
        context.bezierCurveTo(-8.4, 104.73, -9.29, 105.51, -10.27, 106.13);
        context.bezierCurveTo(-10.96, 106.57, -11.4, 107.14, -11.59, 107.93);
        context.bezierCurveTo(-11.62, 108.05, -11.68, 108.18, -11.66, 108.3);
        context.bezierCurveTo(-11.56, 109.05, -11.39, 109.79, -11.33, 110.54);
        context.bezierCurveTo(-11.26, 111.25, -11.3, 111.96, -11.28, 112.67);
        context.bezierCurveTo(-11.27, 113.15, -11.27, 113.63, -11.18, 114.1);
        context.bezierCurveTo(-11, 115.17, -10.13, 115.74, -9.42, 116.42);
        context.bezierCurveTo(-9.27, 116.56, -8.98, 116.78, -9.01, 116.85);
        context.bezierCurveTo(-9.13, 117.15, -9.47, 117.06, -9.68, 117);
        context.bezierCurveTo(-10.57, 116.75, -11.43, 116.39, -12.17, 115.84);
        context.bezierCurveTo(-14.08, 114.41, -15.18, 112.45, -15.76, 110.16);
        context.bezierCurveTo(-16.1, 108.83, -16.25, 107.49, -16.38, 106.13);
        context.bezierCurveTo(-16.5, 104.81, -16.34, 103.48, -16.57, 102.2);
        context.bezierCurveTo(-16.76, 101.16, -16.5, 100.08, -16.9, 99.08);
        context.bezierCurveTo(-16.92, 99.05, -16.91, 99.01, -16.91, 98.98);
        context.bezierCurveTo(-16.98, 97.9, -16.99, 96.81, -17.12, 95.73);
        context.bezierCurveTo(-17.27, 94.51, -17.37, 93.27, -17.73, 92.09);
        context.bezierCurveTo(-18.02, 91.13, -18.48, 90.25, -19.14, 89.52);
        context.bezierCurveTo(-19.85, 88.73, -20.66, 88.04, -21.44, 87.32);
        context.bezierCurveTo(-22.5, 86.35, -23.56, 85.37, -23.96, 83.9);
        context.bezierCurveTo(-24.23, 82.89, -24.49, 81.89, -24.5, 80.82);
        context.bezierCurveTo(-24.5, 79.76, -24.11, 78.91, -23.49, 78.11);
        context.bezierCurveTo(-23.46, 78.06, -23.41, 78.02, -23.36, 77.97);
        context.bezierCurveTo(-22.52, 77.2, -22.33, 76.29, -22.68, 75.19);
        context.bezierCurveTo(-22.91, 74.46, -22.77, 73.71, -22.2, 73.15);
        context.bezierCurveTo(-21.47, 72.43, -20.71, 71.74, -19.91, 71.1);
        context.bezierCurveTo(-19.64, 70.88, -19.21, 70.86, -18.93, 70.78);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 591 Drawing
        context.beginPath();
        context.moveTo(33.31, 37.66);
        context.bezierCurveTo(33.29, 38.13, 33.4, 38.75, 33.12, 39.32);
        context.bezierCurveTo(33.05, 39.46, 32.99, 39.61, 32.89, 39.73);
        context.bezierCurveTo(32.56, 40.12, 32.19, 40.11, 31.98, 39.65);
        context.bezierCurveTo(31.77, 39.2, 31.5, 38.83, 31.14, 38.5);
        context.bezierCurveTo(30.81, 38.2, 30.19, 38.13, 29.81, 38.37);
        context.bezierCurveTo(29.54, 38.54, 29.3, 38.76, 29.03, 38.92);
        context.bezierCurveTo(28.59, 39.18, 28.25, 39.04, 28.07, 38.55);
        context.bezierCurveTo(27.7, 37.53, 28.12, 36.42, 29.13, 35.9);
        context.bezierCurveTo(29.76, 35.57, 30.34, 35.19, 30.8, 34.64);
        context.bezierCurveTo(30.96, 34.45, 31.13, 34.24, 31.24, 34.02);
        context.bezierCurveTo(31.9, 32.67, 33.04, 31.88, 34.41, 31.47);
        context.bezierCurveTo(36.09, 30.96, 37.69, 31.1, 39.03, 32.42);
        context.bezierCurveTo(40.07, 33.44, 41.35, 33.87, 42.77, 33.76);
        context.bezierCurveTo(43.65, 33.69, 44.51, 33.46, 45.33, 33.1);
        context.bezierCurveTo(46.24, 32.7, 47.14, 32.42, 48.16, 32.39);
        context.bezierCurveTo(49.03, 32.37, 49.91, 32.13, 50.76, 31.93);
        context.bezierCurveTo(51.64, 31.72, 52.51, 31.62, 53.41, 31.64);
        context.bezierCurveTo(53.76, 31.64, 53.95, 31.81, 54.14, 32.04);
        context.bezierCurveTo(54.35, 32.28, 54.14, 32.5, 54.1, 32.74);
        context.bezierCurveTo(54.02, 33.22, 53.73, 33.43, 53.36, 33.66);
        context.bezierCurveTo(52.92, 33.92, 52.53, 34.26, 52.17, 34.62);
        context.bezierCurveTo(51.73, 35.06, 51.71, 35.41, 52.07, 35.96);
        context.bezierCurveTo(52.51, 36.62, 53.02, 37.24, 53.43, 37.92);
        context.bezierCurveTo(54.18, 39.18, 54.9, 40.45, 54.84, 42);
        context.bezierCurveTo(54.8, 42.75, 54.47, 43.09, 53.7, 43.09);
        context.bezierCurveTo(53.39, 43.09, 53.09, 42.97, 52.77, 42.94);
        context.bezierCurveTo(52.43, 42.9, 52.07, 42.81, 51.78, 43.13);
        context.bezierCurveTo(51.6, 43.34, 51.5, 43.51, 51.58, 43.81);
        context.bezierCurveTo(51.79, 44.57, 51.94, 45.35, 52.11, 46.11);
        context.bezierCurveTo(52.13, 46.21, 52.14, 46.31, 52.11, 46.4);
        context.bezierCurveTo(51.83, 47.35, 52.06, 48.27, 52.3, 49.18);
        context.bezierCurveTo(52.35, 49.36, 52.24, 49.52, 52.04, 49.49);
        context.bezierCurveTo(51.7, 49.44, 51.34, 49.39, 51.04, 49.25);
        context.bezierCurveTo(49.96, 48.71, 48.83, 48.49, 47.63, 48.51);
        context.bezierCurveTo(47.14, 48.51, 46.63, 48.34, 46.17, 48.15);
        context.bezierCurveTo(45.43, 47.83, 45.37, 47.54, 45.78, 46.81);
        context.bezierCurveTo(46.02, 46.38, 45.87, 46.06, 45.39, 46.01);
        context.bezierCurveTo(45.2, 46, 45, 45.99, 44.81, 46.02);
        context.bezierCurveTo(44.4, 46.09, 44.24, 46.39, 44.36, 46.83);
        context.bezierCurveTo(44.4, 46.98, 44.5, 47.12, 44.51, 47.27);
        context.bezierCurveTo(44.52, 47.38, 44.47, 47.53, 44.4, 47.6);
        context.bezierCurveTo(44.1, 47.89, 43.72, 47.77, 43.38, 47.73);
        context.bezierCurveTo(43.1, 47.7, 43, 47.45, 43, 47.15);
        context.bezierCurveTo(42.99, 46.74, 42.74, 46.51, 42.37, 46.41);
        context.bezierCurveTo(42, 46.31, 41.76, 46.53, 41.55, 46.8);
        context.bezierCurveTo(41.05, 47.44, 40.9, 48.22, 40.7, 48.98);
        context.bezierCurveTo(40.67, 49.08, 40.76, 49.22, 40.79, 49.34);
        context.bezierCurveTo(40.96, 50.01, 40.83, 50.26, 40.15, 50.47);
        context.bezierCurveTo(39.82, 50.57, 39.48, 50.61, 39.13, 50.66);
        context.bezierCurveTo(38.94, 50.69, 38.74, 50.65, 38.55, 50.68);
        context.bezierCurveTo(38.22, 50.74, 38.12, 51, 38.31, 51.3);
        context.bezierCurveTo(38.45, 51.51, 38.61, 51.72, 38.74, 51.93);
        context.bezierCurveTo(38.97, 52.28, 38.93, 52.69, 38.64, 52.95);
        context.bezierCurveTo(38.35, 53.21, 37.83, 53.3, 37.57, 53.07);
        context.bezierCurveTo(37.34, 52.86, 37.13, 52.6, 36.98, 52.33);
        context.bezierCurveTo(36.64, 51.72, 36.31, 51.09, 36.03, 50.45);
        context.bezierCurveTo(35.75, 49.83, 35.34, 49.37, 34.71, 49.13);
        context.bezierCurveTo(34.04, 48.88, 33.51, 48.42, 33.19, 47.85);
        context.bezierCurveTo(32.89, 47.31, 32.5, 47.37, 32.06, 47.37);
        context.bezierCurveTo(31.79, 47.37, 31.72, 47.55, 31.77, 47.75);
        context.bezierCurveTo(31.82, 47.96, 31.92, 48.18, 32.06, 48.33);
        context.bezierCurveTo(32.64, 48.96, 33.33, 49.48, 34.09, 49.9);
        context.bezierCurveTo(34.45, 50.09, 34.78, 50.35, 35.1, 50.62);
        context.bezierCurveTo(35.33, 50.82, 35.31, 51.02, 34.95, 51.05);
        context.bezierCurveTo(34.67, 51.07, 34.7, 51.26, 34.66, 51.46);
        context.bezierCurveTo(34.58, 51.85, 34.41, 51.92, 34.09, 51.73);
        context.bezierCurveTo(33.7, 51.49, 33.38, 51.17, 33.18, 50.78);
        context.bezierCurveTo(33.02, 50.45, 32.79, 50.28, 32.5, 50.16);
        context.bezierCurveTo(31.89, 49.9, 31.45, 49.51, 31.06, 48.95);
        context.bezierCurveTo(30.57, 48.26, 30.49, 48.2, 29.62, 48.5);
        context.bezierCurveTo(29, 48.72, 28.33, 48.86, 27.68, 48.89);
        context.bezierCurveTo(27.18, 48.91, 26.86, 49.15, 26.66, 49.5);
        context.bezierCurveTo(26.22, 50.26, 25.63, 50.88, 25.02, 51.5);
        context.bezierCurveTo(24.85, 51.68, 24.69, 51.91, 24.62, 52.14);
        context.bezierCurveTo(24.33, 53.17, 23.57, 53.52, 22.61, 53.57);
        context.bezierCurveTo(22.26, 53.59, 21.9, 53.58, 21.56, 53.52);
        context.bezierCurveTo(20.62, 53.34, 20.23, 52.6, 19.95, 51.8);
        context.bezierCurveTo(19.83, 51.48, 19.79, 51.13, 19.74, 50.78);
        context.bezierCurveTo(19.63, 49.96, 19.95, 48.8, 21.01, 48.47);
        context.bezierCurveTo(21.38, 48.35, 21.66, 48.3, 22, 48.51);
        context.bezierCurveTo(22.3, 48.7, 22.65, 48.72, 23.01, 48.74);
        context.bezierCurveTo(23.49, 48.75, 23.91, 48.69, 24.17, 48.23);
        context.bezierCurveTo(24.42, 47.76, 24.54, 47.25, 24.26, 46.77);
        context.bezierCurveTo(24.04, 46.39, 23.73, 46.05, 23.42, 45.73);
        context.bezierCurveTo(23.22, 45.52, 23.3, 45.4, 23.47, 45.28);
        context.bezierCurveTo(23.62, 45.18, 23.79, 45.09, 23.97, 45.03);
        context.bezierCurveTo(25.46, 44.57, 26.69, 43.69, 27.85, 42.66);
        context.bezierCurveTo(28.39, 42.18, 29, 41.77, 29.61, 41.36);
        context.bezierCurveTo(30, 41.1, 30.41, 41.14, 30.88, 41.28);
        context.bezierCurveTo(32.26, 41.72, 33.63, 41.74, 34.95, 41.01);
        context.bezierCurveTo(35.72, 40.58, 36.31, 39.98, 36.78, 39.25);
        context.bezierCurveTo(37.3, 38.45, 37.06, 37.72, 36.21, 37.28);
        context.bezierCurveTo(36.07, 37.2, 35.92, 37.15, 35.78, 37.07);
        context.bezierCurveTo(35.37, 36.82, 35.34, 36.6, 35.63, 36.21);
        context.bezierCurveTo(35.91, 35.83, 36.17, 35.43, 36.44, 35.04);
        context.bezierCurveTo(36.66, 34.73, 36.74, 34.33, 36.49, 34.07);
        context.bezierCurveTo(36.35, 33.92, 35.91, 33.91, 35.67, 34);
        context.bezierCurveTo(34.83, 34.36, 33.95, 34.71, 33.53, 35.64);
        context.bezierCurveTo(33.38, 35.96, 33.27, 36.29, 33.31, 36.65);
        context.bezierCurveTo(33.33, 36.94, 33.31, 37.23, 33.31, 37.66);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 592 Drawing
        context.beginPath();
        context.moveTo(107.82, 105.84);
        context.bezierCurveTo(107.25, 105.76, 106.69, 105.67, 106.12, 105.62);
        context.bezierCurveTo(105.44, 105.56, 105.33, 105.08, 105.25, 104.55);
        context.bezierCurveTo(105.22, 104.33, 105.24, 104.1, 105.17, 103.89);
        context.bezierCurveTo(105, 103.36, 104.61, 102.91, 104.1, 102.9);
        context.bezierCurveTo(103.24, 102.88, 103.09, 102.28, 102.95, 101.72);
        context.bezierCurveTo(102.78, 101.02, 102.31, 100.82, 101.71, 100.73);
        context.bezierCurveTo(101.4, 100.69, 101.06, 100.68, 100.75, 100.74);
        context.bezierCurveTo(99.86, 100.89, 99.03, 101.19, 98.31, 101.75);
        context.bezierCurveTo(97.61, 102.28, 96.91, 102.63, 95.94, 102.32);
        context.bezierCurveTo(95.11, 102.06, 94.34, 102.57, 93.61, 102.97);
        context.bezierCurveTo(93.44, 103.07, 93.29, 103.19, 93.13, 103.28);
        context.bezierCurveTo(92.52, 103.6, 92.16, 103.54, 92.08, 102.75);
        context.bezierCurveTo(91.98, 101.67, 92.01, 100.59, 92.35, 99.52);
        context.bezierCurveTo(92.7, 98.44, 92.94, 97.33, 93.2, 96.22);
        context.bezierCurveTo(93.27, 95.91, 93.22, 95.58, 93.22, 95.25);
        context.bezierCurveTo(93.22, 93.89, 93.89, 93.16, 95.26, 92.95);
        context.bezierCurveTo(95.78, 92.87, 96.3, 92.67, 96.83, 92.62);
        context.bezierCurveTo(97.68, 92.52, 98.27, 92.01, 98.88, 91.48);
        context.bezierCurveTo(99.73, 90.74, 100.31, 89.77, 100.98, 88.87);
        context.bezierCurveTo(101.39, 88.32, 102.01, 88.14, 102.56, 88.43);
        context.bezierCurveTo(103.32, 88.84, 103.66, 88.84, 104.09, 88.15);
        context.bezierCurveTo(104.47, 87.55, 105.02, 87.2, 105.52, 86.76);
        context.bezierCurveTo(106.02, 86.33, 107.36, 86.39, 107.8, 86.85);
        context.bezierCurveTo(108.4, 87.5, 108.45, 87.78, 107.89, 88.48);
        context.bezierCurveTo(107.45, 89.04, 107.44, 89.49, 108.02, 89.97);
        context.bezierCurveTo(108.5, 90.37, 109.06, 90.7, 109.64, 90.97);
        context.bezierCurveTo(110.1, 91.19, 110.53, 90.87, 110.67, 90.29);
        context.bezierCurveTo(110.94, 89.15, 111.19, 88.01, 111.63, 86.92);
        context.bezierCurveTo(111.65, 86.87, 111.63, 86.78, 111.66, 86.73);
        context.bezierCurveTo(111.77, 86.55, 111.9, 86.22, 112.01, 86.23);
        context.bezierCurveTo(112.35, 86.25, 112.42, 86.62, 112.4, 86.87);
        context.bezierCurveTo(112.34, 87.83, 112.67, 88.69, 113.04, 89.54);
        context.bezierCurveTo(113.2, 89.92, 113.4, 90.27, 113.43, 90.7);
        context.bezierCurveTo(113.44, 91.02, 113.58, 91.33, 113.57, 91.63);
        context.bezierCurveTo(113.55, 92.23, 113.76, 92.69, 114.19, 93.08);
        context.bezierCurveTo(114.36, 93.23, 114.5, 93.41, 114.68, 93.55);
        context.bezierCurveTo(115.43, 94.17, 115.84, 95, 116.18, 95.86);
        context.bezierCurveTo(116.54, 96.74, 116.55, 97.67, 116.21, 98.54);
        context.bezierCurveTo(115.89, 99.37, 115.44, 100.15, 114.79, 100.79);
        context.bezierCurveTo(114.02, 101.53, 113.26, 102.29, 112.52, 103.05);
        context.bezierCurveTo(112.1, 103.49, 111.72, 103.96, 111.33, 104.43);
        context.bezierCurveTo(110.62, 105.29, 109.76, 105.83, 108.6, 105.77);
        context.bezierCurveTo(108.34, 105.76, 108.08, 105.77, 107.83, 105.77);
        context.bezierCurveTo(107.82, 105.79, 107.82, 105.82, 107.82, 105.84);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 593 Drawing
        context.beginPath();
        context.moveTo(0.9, 34.29);
        context.bezierCurveTo(0.86, 34, 1.03, 33.57, 1.25, 33.12);
        context.bezierCurveTo(1.59, 32.41, 2.01, 31.74, 2.18, 30.94);
        context.bezierCurveTo(2.3, 30.36, 2.15, 29.94, 1.82, 29.55);
        context.bezierCurveTo(1.15, 28.75, 0.19, 28.45, -0.75, 28.17);
        context.bezierCurveTo(-1.3, 28.01, -1.87, 27.93, -2.4, 27.75);
        context.bezierCurveTo(-2.75, 27.63, -3.13, 27.47, -3.29, 27.07);
        context.bezierCurveTo(-3.01, 26.86, -2.76, 26.6, -2.45, 26.46);
        context.bezierCurveTo(-2.14, 26.32, -1.78, 26.27, -1.44, 26.22);
        context.bezierCurveTo(-0.15, 26.04, 1.11, 25.75, 2.41, 25.59);
        context.bezierCurveTo(3.72, 25.43, 5.04, 25.23, 6.35, 25.02);
        context.bezierCurveTo(8.84, 24.62, 11.33, 24.69, 13.83, 24.65);
        context.bezierCurveTo(14.92, 24.63, 16.01, 24.73, 17.08, 24.88);
        context.bezierCurveTo(17.56, 24.95, 18.02, 25.23, 18.48, 25.44);
        context.bezierCurveTo(18.8, 25.59, 18.72, 25.86, 18.56, 26.08);
        context.bezierCurveTo(18.39, 26.31, 18.2, 26.55, 17.97, 26.71);
        context.bezierCurveTo(16.72, 27.52, 15.7, 28.59, 14.67, 29.65);
        context.bezierCurveTo(14.41, 29.93, 14.18, 30.24, 13.96, 30.55);
        context.bezierCurveTo(13, 31.91, 11.65, 32.57, 10.05, 32.83);
        context.bezierCurveTo(7.94, 33.17, 6.25, 34.22, 4.99, 35.96);
        context.bezierCurveTo(4.74, 36.3, 4.43, 36.59, 4.14, 36.88);
        context.bezierCurveTo(3.64, 37.37, 3.19, 37.46, 2.58, 37.11);
        context.bezierCurveTo(1.58, 36.54, 0.93, 35.72, 0.9, 34.29);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 594 Drawing
        context.beginPath();
        context.moveTo(116.35, 86.19);
        context.bezierCurveTo(115.45, 86.15, 114.87, 85.74, 114.41, 85.13);
        context.bezierCurveTo(114.36, 85.05, 114.34, 84.95, 114.3, 84.86);
        context.bezierCurveTo(113.91, 84.17, 113.91, 84.17, 113.24, 84.67);
        context.bezierCurveTo(112.74, 85.03, 112.2, 85.19, 111.62, 84.89);
        context.bezierCurveTo(110.91, 84.52, 110.23, 84.5, 109.53, 84.89);
        context.bezierCurveTo(109.44, 84.94, 109.25, 84.96, 109.2, 84.91);
        context.bezierCurveTo(109.14, 84.83, 109.15, 84.68, 109.14, 84.56);
        context.bezierCurveTo(109.14, 84.54, 109.17, 84.51, 109.19, 84.48);
        context.bezierCurveTo(109.44, 84.09, 109.66, 83.65, 109.48, 83.21);
        context.bezierCurveTo(109.25, 82.66, 108.67, 82.41, 108.18, 82.13);
        context.bezierCurveTo(107.8, 81.92, 107.35, 81.83, 106.92, 81.72);
        context.bezierCurveTo(105.93, 81.47, 105.36, 80.49, 105.65, 79.52);
        context.bezierCurveTo(105.75, 79.19, 106.08, 79.11, 106.31, 79.38);
        context.bezierCurveTo(106.54, 79.64, 106.73, 79.94, 106.94, 80.22);
        context.bezierCurveTo(107.39, 80.82, 107.85, 80.91, 108.48, 80.51);
        context.bezierCurveTo(108.58, 80.45, 108.7, 80.4, 108.81, 80.33);
        context.bezierCurveTo(109.86, 79.6, 110.93, 79.75, 112.07, 80.14);
        context.bezierCurveTo(113.09, 80.49, 113.8, 81.19, 114.49, 81.95);
        context.bezierCurveTo(114.71, 82.2, 114.84, 82.55, 114.95, 82.87);
        context.bezierCurveTo(115.29, 83.88, 115.77, 84.8, 116.55, 85.55);
        context.bezierCurveTo(116.66, 85.66, 116.94, 85.76, 116.75, 85.98);
        context.bezierCurveTo(116.65, 86.11, 116.43, 86.15, 116.35, 86.19);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 595 Drawing
        context.beginPath();
        context.moveTo(91.98, 78.47);
        context.bezierCurveTo(92.06, 77.78, 92.13, 77.7, 92.82, 77.43);
        context.bezierCurveTo(93.74, 77.06, 94.65, 76.68, 95.28, 75.86);
        context.bezierCurveTo(95.59, 75.46, 95.86, 75.02, 96.11, 74.58);
        context.bezierCurveTo(96.29, 74.27, 96.45, 73.91, 96.86, 73.96);
        context.bezierCurveTo(97.3, 74.01, 97.47, 74.41, 97.6, 74.77);
        context.bezierCurveTo(97.68, 75, 97.73, 75.3, 97.66, 75.52);
        context.bezierCurveTo(97.4, 76.31, 97.32, 77.12, 97.29, 77.95);
        context.bezierCurveTo(97.25, 78.77, 96.77, 79.44, 96.42, 80.15);
        context.bezierCurveTo(96.36, 80.26, 96.23, 80.33, 96.18, 80.45);
        context.bezierCurveTo(95.63, 81.6, 94.4, 81.5, 93.56, 80.95);
        context.bezierCurveTo(93.01, 80.59, 92.54, 80.1, 92.28, 79.46);
        context.bezierCurveTo(92.15, 79.14, 92.08, 78.8, 91.98, 78.47);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 596 Drawing
        context.beginPath();
        context.moveTo(55.41, 89.06);
        context.bezierCurveTo(55.57, 89.98, 55, 90.77, 54.7, 91.62);
        context.bezierCurveTo(54.38, 92.51, 54.06, 93.4, 53.87, 94.32);
        context.bezierCurveTo(53.7, 95.13, 53.36, 95.81, 52.72, 96.35);
        context.bezierCurveTo(52.12, 96.86, 51.52, 96.79, 51.16, 96.08);
        context.bezierCurveTo(50.7, 95.19, 50.62, 94.23, 51.16, 93.38);
        context.bezierCurveTo(51.64, 92.64, 51.81, 91.87, 51.72, 91.03);
        context.bezierCurveTo(51.65, 90.3, 52.01, 89.89, 52.62, 89.62);
        context.bezierCurveTo(53.54, 89.19, 54.29, 88.59, 54.69, 87.61);
        context.bezierCurveTo(54.81, 87.33, 55.01, 87.35, 55.18, 87.61);
        context.bezierCurveTo(55.46, 88.04, 55.42, 88.52, 55.41, 89.06);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 597 Drawing
        context.beginPath();
        context.moveTo(90.12, 81.62);
        context.bezierCurveTo(90.06, 81.73, 89.98, 82.11, 89.75, 82.24);
        context.bezierCurveTo(89.52, 82.38, 89.17, 82.32, 88.86, 82.11);
        context.bezierCurveTo(87.76, 81.41, 87.11, 80.35, 86.5, 79.28);
        context.bezierCurveTo(86.1, 78.58, 85.77, 77.91, 85.03, 77.45);
        context.bezierCurveTo(84.39, 77.05, 83.97, 76.43, 84.11, 75.57);
        context.bezierCurveTo(84.15, 75.3, 84.32, 75.27, 84.52, 75.32);
        context.bezierCurveTo(84.7, 75.37, 84.89, 75.45, 85.03, 75.57);
        context.bezierCurveTo(86.01, 76.4, 86.96, 77.25, 87.95, 78.06);
        context.bezierCurveTo(88.37, 78.41, 88.63, 78.84, 88.74, 79.34);
        context.bezierCurveTo(88.85, 79.88, 89.08, 80.28, 89.61, 80.49);
        context.bezierCurveTo(90.02, 80.66, 90.13, 81.01, 90.12, 81.62);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 598 Drawing
        context.beginPath();
        context.moveTo(99.04, 67.06);
        context.bezierCurveTo(99.04, 67.28, 99.03, 67.51, 99.05, 67.73);
        context.bezierCurveTo(99.08, 68.12, 99.23, 68.47, 99.57, 68.7);
        context.bezierCurveTo(99.76, 68.82, 99.94, 68.95, 100.1, 69.09);
        context.bezierCurveTo(100.94, 69.81, 101.64, 70.61, 101.78, 71.78);
        context.bezierCurveTo(101.82, 72.11, 101.96, 72.47, 102.15, 72.74);
        context.bezierCurveTo(102.51, 73.24, 102.2, 73.66, 102.06, 74.08);
        context.bezierCurveTo(101.94, 74.42, 101.49, 74.43, 101.27, 74.14);
        context.bezierCurveTo(101.22, 74.06, 101.2, 73.96, 101.16, 73.88);
        context.bezierCurveTo(101.01, 73.59, 100.86, 73.36, 100.48, 73.3);
        context.bezierCurveTo(100.17, 73.24, 99.93, 73.01, 100, 72.61);
        context.bezierCurveTo(100.07, 72.24, 100.12, 71.87, 100.17, 71.49);
        context.bezierCurveTo(100.25, 70.97, 100.26, 70.48, 99.77, 70.1);
        context.bezierCurveTo(99.26, 69.72, 98.8, 69.27, 98.27, 68.92);
        context.bezierCurveTo(97.99, 68.73, 97.87, 68.55, 97.87, 68.23);
        context.bezierCurveTo(97.88, 67.65, 97.85, 67.07, 97.88, 66.49);
        context.bezierCurveTo(97.91, 66.06, 98.56, 65.79, 98.92, 66.04);
        context.bezierCurveTo(98.99, 66.08, 99.03, 66.2, 99.03, 66.28);
        context.bezierCurveTo(99.05, 66.54, 99.04, 66.8, 99.04, 67.06);
        context.lineTo(99.04, 67.06);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 599 Drawing
        context.beginPath();
        context.moveTo(104.15, 50.27);
        context.bezierCurveTo(104.93, 50.27, 105.59, 50.88, 105.63, 51.66);
        context.bezierCurveTo(105.67, 52.34, 105.83, 52.97, 106.12, 53.58);
        context.bezierCurveTo(106.32, 54.02, 106.23, 54.19, 105.74, 54.35);
        context.bezierCurveTo(105.47, 54.44, 105.19, 54.52, 104.91, 54.53);
        context.bezierCurveTo(104.47, 54.55, 104.29, 54.74, 104.26, 55.18);
        context.bezierCurveTo(104.24, 55.56, 104.12, 55.57, 103.79, 55.32);
        context.bezierCurveTo(103.59, 55.17, 103.38, 55, 103.15, 54.94);
        context.bezierCurveTo(102.56, 54.81, 102.21, 55.24, 101.97, 55.72);
        context.bezierCurveTo(101.84, 56, 101.83, 56.33, 101.75, 56.63);
        context.bezierCurveTo(101.73, 56.71, 101.66, 56.82, 101.59, 56.83);
        context.bezierCurveTo(101.52, 56.85, 101.4, 56.8, 101.35, 56.74);
        context.bezierCurveTo(100.96, 56.29, 100.8, 55.71, 101.06, 55.22);
        context.bezierCurveTo(101.3, 54.78, 101.65, 54.32, 102.33, 54.33);
        context.bezierCurveTo(103.06, 54.35, 103.2, 54.17, 103.29, 53.36);
        context.bezierCurveTo(103.32, 53.06, 103.45, 53, 103.72, 52.98);
        context.bezierCurveTo(104.24, 52.94, 104.42, 52.74, 104.47, 52.23);
        context.bezierCurveTo(104.52, 51.64, 104.44, 51.08, 103.98, 50.64);
        context.bezierCurveTo(103.93, 50.59, 103.91, 50.44, 103.94, 50.38);
        context.bezierCurveTo(103.98, 50.32, 104.1, 50.3, 104.15, 50.27);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 600 Drawing
        context.beginPath();
        context.moveTo(23.12, 44.09);
        context.bezierCurveTo(23.03, 44.04, 22.91, 44.01, 22.88, 43.95);
        context.bezierCurveTo(22.85, 43.88, 22.89, 43.76, 22.94, 43.69);
        context.bezierCurveTo(23.08, 43.48, 23.24, 43.29, 23.4, 43.1);
        context.bezierCurveTo(23.19, 42.96, 22.99, 42.83, 22.79, 42.69);
        context.bezierCurveTo(22.62, 42.57, 22.63, 42.45, 22.81, 42.33);
        context.bezierCurveTo(22.94, 42.25, 23.08, 42.16, 23.2, 42.06);
        context.bezierCurveTo(23.45, 41.84, 23.51, 41.59, 23.28, 41.31);
        context.bezierCurveTo(23.03, 41.01, 22.79, 40.71, 22.58, 40.4);
        context.bezierCurveTo(22.31, 39.99, 22.06, 39.56, 22.43, 39.08);
        context.bezierCurveTo(22.59, 38.88, 22.75, 38.69, 22.91, 38.5);
        context.bezierCurveTo(23.14, 38.75, 23.39, 38.98, 23.6, 39.25);
        context.bezierCurveTo(23.66, 39.33, 23.63, 39.5, 23.61, 39.63);
        context.bezierCurveTo(23.56, 39.99, 23.74, 40.23, 23.99, 40.47);
        context.bezierCurveTo(24.36, 40.81, 24.69, 41.17, 24.82, 41.72);
        context.bezierCurveTo(24.87, 41.97, 25.23, 42.16, 25.47, 42.36);
        context.bezierCurveTo(25.83, 42.65, 25.86, 42.95, 25.5, 43.22);
        context.bezierCurveTo(24.8, 43.75, 23.96, 43.91, 23.12, 44.09);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 601 Drawing
        context.beginPath();
        context.moveTo(117.3, 111.2);
        context.bezierCurveTo(116.91, 111.25, 116.57, 111.1, 116.26, 110.86);
        context.bezierCurveTo(116.07, 110.71, 116.06, 110.53, 116.26, 110.38);
        context.bezierCurveTo(116.41, 110.26, 116.58, 110.18, 116.75, 110.09);
        context.bezierCurveTo(117.67, 109.6, 118.61, 109.14, 119.52, 108.61);
        context.bezierCurveTo(120.17, 108.23, 120.78, 107.77, 121.41, 107.34);
        context.bezierCurveTo(121.51, 107.27, 121.61, 107.17, 121.72, 107.13);
        context.bezierCurveTo(121.95, 107.04, 122.15, 106.82, 122.43, 106.99);
        context.bezierCurveTo(122.74, 107.17, 122.73, 107.47, 122.69, 107.75);
        context.bezierCurveTo(122.67, 107.88, 122.56, 108.05, 122.45, 108.08);
        context.bezierCurveTo(121.88, 108.25, 121.43, 108.53, 121.07, 109.02);
        context.bezierCurveTo(120.92, 109.23, 120.59, 109.44, 120.35, 109.43);
        context.bezierCurveTo(119.56, 109.37, 119.18, 109.87, 118.84, 110.41);
        context.bezierCurveTo(118.47, 110.99, 117.99, 111.24, 117.3, 111.2);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 602 Drawing
        context.beginPath();
        context.moveTo(125.82, 103.88);
        context.bezierCurveTo(125.79, 104.04, 125.8, 104.21, 125.73, 104.34);
        context.bezierCurveTo(125.59, 104.62, 125.78, 104.59, 125.94, 104.6);
        context.bezierCurveTo(126.13, 104.61, 126.33, 104.57, 126.52, 104.62);
        context.bezierCurveTo(126.66, 104.65, 126.84, 104.75, 126.89, 104.86);
        context.bezierCurveTo(126.94, 104.96, 126.87, 105.17, 126.78, 105.27);
        context.bezierCurveTo(126.44, 105.65, 126.12, 106.03, 125.5, 105.99);
        context.bezierCurveTo(125.13, 105.96, 124.91, 106.29, 124.82, 106.67);
        context.bezierCurveTo(124.72, 107.08, 124.55, 107.45, 124.08, 107.6);
        context.bezierCurveTo(123.69, 107.73, 123.31, 107.52, 123.27, 107.13);
        context.bezierCurveTo(123.23, 106.75, 123.39, 106.43, 123.59, 106.15);
        context.bezierCurveTo(123.76, 105.91, 123.96, 105.67, 124.2, 105.53);
        context.bezierCurveTo(125.1, 105.02, 125.12, 104.26, 124.9, 103.4);
        context.bezierCurveTo(124.88, 103.34, 124.83, 103.27, 124.84, 103.22);
        context.bezierCurveTo(124.87, 103.1, 124.89, 102.94, 124.97, 102.89);
        context.bezierCurveTo(125.05, 102.84, 125.23, 102.85, 125.33, 102.91);
        context.bezierCurveTo(125.69, 103.12, 125.89, 103.43, 125.82, 103.88);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 603 Drawing
        context.beginPath();
        context.moveTo(14.63, 35.46);
        context.bezierCurveTo(14.11, 35.46, 13.81, 34.98, 14.07, 34.51);
        context.bezierCurveTo(14.12, 34.41, 14.16, 34.31, 14.2, 34.21);
        context.bezierCurveTo(14, 34.13, 13.79, 34, 14.04, 33.83);
        context.bezierCurveTo(14.28, 33.67, 14.58, 33.41, 14.91, 33.72);
        context.bezierCurveTo(15.01, 33.81, 15.1, 33.9, 15.19, 33.99);
        context.bezierCurveTo(15.34, 34.14, 15.48, 34.18, 15.7, 34.07);
        context.bezierCurveTo(16.25, 33.79, 16.86, 33.72, 17.47, 33.79);
        context.bezierCurveTo(17.75, 33.82, 18.02, 33.91, 18.28, 34.02);
        context.bezierCurveTo(18.57, 34.15, 18.59, 34.37, 18.32, 34.5);
        context.bezierCurveTo(17.74, 34.77, 17.19, 35.07, 16.7, 35.5);
        context.bezierCurveTo(16.52, 35.64, 16.26, 35.84, 15.92, 35.57);
        context.bezierCurveTo(15.57, 35.29, 15.1, 35.22, 14.63, 35.46);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 604 Drawing
        context.beginPath();
        context.moveTo(-24.44, 62.47);
        context.bezierCurveTo(-23.35, 62.57, -22.53, 62.9, -21.77, 63.37);
        context.bezierCurveTo(-21.23, 63.71, -20.77, 64.15, -20.12, 64.3);
        context.bezierCurveTo(-19.99, 64.33, -19.85, 64.58, -19.82, 64.74);
        context.bezierCurveTo(-19.78, 65, -20.03, 64.98, -20.21, 65.05);
        context.bezierCurveTo(-21.01, 65.31, -21.82, 65.04, -22.2, 64.29);
        context.bezierCurveTo(-22.42, 63.87, -22.65, 63.64, -23.15, 63.64);
        context.bezierCurveTo(-23.45, 63.64, -23.76, 63.49, -24.05, 63.39);
        context.bezierCurveTo(-24.56, 63.23, -25.04, 63.16, -25.48, 63.58);
        context.bezierCurveTo(-25.65, 63.75, -25.86, 63.62, -25.83, 63.36);
        context.bezierCurveTo(-25.81, 63.19, -25.73, 62.95, -25.61, 62.9);
        context.bezierCurveTo(-25.19, 62.7, -24.73, 62.57, -24.44, 62.47);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 605 Drawing
        context.beginPath();
        context.moveTo(90.75, 83.07);
        context.bezierCurveTo(90.8, 83.08, 91.01, 83.13, 91.2, 83.24);
        context.bezierCurveTo(91.68, 83.51, 92.22, 83.57, 92.68, 83.4);
        context.bezierCurveTo(93.03, 83.28, 93.21, 83.31, 93.42, 83.55);
        context.bezierCurveTo(93.68, 83.83, 93.94, 84.1, 94.4, 84.06);
        context.bezierCurveTo(94.6, 84.05, 94.85, 84.25, 94.73, 84.53);
        context.bezierCurveTo(94.64, 84.73, 94.48, 84.81, 94.18, 84.73);
        context.bezierCurveTo(93.24, 84.47, 92.32, 84.12, 91.3, 84.2);
        context.bezierCurveTo(90.94, 84.23, 90.54, 83.96, 90.18, 83.79);
        context.bezierCurveTo(90.07, 83.73, 89.93, 83.48, 89.97, 83.41);
        context.bezierCurveTo(90.04, 83.26, 90.23, 83.17, 90.38, 83.07);
        context.bezierCurveTo(90.43, 83.04, 90.51, 83.07, 90.75, 83.07);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 606 Drawing
        context.beginPath();
        context.moveTo(107.24, 106.86);
        context.bezierCurveTo(107.54, 106.94, 107.8, 106.97, 108.02, 107.07);
        context.bezierCurveTo(108.38, 107.21, 108.5, 107.54, 108.28, 107.83);
        context.bezierCurveTo(107.8, 108.48, 107.18, 108.98, 106.4, 109.24);
        context.bezierCurveTo(106.2, 109.31, 105.98, 109.21, 106.03, 108.96);
        context.bezierCurveTo(106.16, 108.35, 106.29, 107.73, 106.52, 107.15);
        context.bezierCurveTo(106.59, 106.98, 107.01, 106.95, 107.24, 106.86);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 607 Drawing
        context.beginPath();
        context.moveTo(102.1, 86.96);
        context.bezierCurveTo(101.93, 87, 101.77, 87.09, 101.61, 87.06);
        context.bezierCurveTo(101.48, 87.05, 101.29, 86.81, 101.25, 86.84);
        context.bezierCurveTo(101.07, 86.96, 100.92, 87.13, 100.81, 87.32);
        context.bezierCurveTo(100.7, 87.5, 100.67, 87.73, 100.58, 87.93);
        context.bezierCurveTo(100.55, 88, 100.4, 88.09, 100.38, 88.07);
        context.bezierCurveTo(100.02, 87.82, 99.44, 88.07, 99.2, 87.58);
        context.bezierCurveTo(99.12, 87.42, 99.07, 87.23, 99.07, 87.06);
        context.bezierCurveTo(99.08, 86.86, 99.35, 86.78, 99.49, 86.9);
        context.bezierCurveTo(99.71, 87.09, 99.92, 87.28, 100.13, 87.47);
        context.bezierCurveTo(100.15, 87.15, 100.18, 86.84, 100.2, 86.52);
        context.bezierCurveTo(100.21, 86.27, 100.18, 86.01, 100.22, 85.76);
        context.bezierCurveTo(100.28, 85.46, 100.7, 85.26, 100.89, 85.45);
        context.bezierCurveTo(101.3, 85.85, 101.66, 86.3, 102.03, 86.73);
        context.bezierCurveTo(102.06, 86.77, 102.06, 86.83, 102.1, 86.96);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 608 Drawing
        context.beginPath();
        context.moveTo(103.77, 49.68);
        context.bezierCurveTo(103.36, 49.72, 103.21, 49.52, 103.32, 49.28);
        context.bezierCurveTo(103.57, 48.74, 103.42, 48.25, 103.22, 47.74);
        context.bezierCurveTo(103.2, 47.7, 103.21, 47.61, 103.23, 47.6);
        context.bezierCurveTo(103.28, 47.58, 103.38, 47.57, 103.41, 47.6);
        context.bezierCurveTo(103.87, 48.07, 104.44, 48.2, 105.08, 48.14);
        context.bezierCurveTo(105.11, 48.14, 105.14, 48.13, 105.17, 48.14);
        context.bezierCurveTo(105.49, 48.27, 105.94, 48.3, 105.99, 48.7);
        context.bezierCurveTo(106.04, 49.11, 105.57, 49.17, 105.31, 49.27);
        context.bezierCurveTo(104.79, 49.47, 104.22, 49.57, 103.77, 49.68);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();




        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawTreasureMapBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor13 = 'rgba(248, 218, 37, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';
        var fillColor25 = 'rgba(185, 146, 111, 1)';

        //// Group 22
        context.save();

        //// Clip Clip 20

        context.beginPath();
        context.moveTo(42.01, 1.28);
        context.bezierCurveTo(40.02, 1.28, 38.18, 2.35, 37.18, 4.07);
        context.lineTo(37.18, 4.07);
        context.lineTo(2.61, 63.96);
        context.bezierCurveTo(1.61, 65.68, 1.61, 67.8, 2.61, 69.53);
        context.lineTo(2.61, 69.53);
        context.lineTo(37.18, 129.41);
        context.bezierCurveTo(38.18, 131.14, 40.02, 132.2, 42.01, 132.2);
        context.lineTo(42.01, 132.2);
        context.lineTo(111.16, 132.2);
        context.bezierCurveTo(113.15, 132.2, 114.99, 131.14, 115.98, 129.41);
        context.lineTo(115.98, 129.41);
        context.lineTo(150.56, 69.53);
        context.bezierCurveTo(151.56, 67.8, 151.56, 65.68, 150.56, 63.96);
        context.lineTo(150.56, 63.96);
        context.lineTo(115.98, 4.07);
        context.bezierCurveTo(114.99, 2.35, 113.15, 1.28, 111.16, 1.28);
        context.lineTo(111.16, 1.28);
        context.lineTo(42.01, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 542 Drawing
        context.beginPath();
        context.moveTo(39.87, -5.76);
        context.bezierCurveTo(39.43, -3.98, -3.36, 67.18, -3.36, 67.18);
        context.lineTo(36.1, 135.46);
        context.lineTo(116.14, 134.58);
        context.lineTo(155.6, 66.07);
        context.lineTo(115.69, -2.65);
        context.lineTo(39.87, -5.76);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Bezier 543 Drawing
        context.beginPath();
        context.moveTo(39.87, -5.76);
        context.bezierCurveTo(39.43, -3.98, -3.36, 67.18, -3.36, 67.18);
        context.lineTo(36.1, 135.46);
        context.lineTo(116.14, 134.58);
        context.lineTo(155.6, 66.07);
        context.lineTo(115.69, -2.65);
        context.lineTo(39.87, -5.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 544 Drawing
        context.beginPath();
        context.moveTo(59.22, 16.25);
        context.bezierCurveTo(59.22, 16.25, 60.33, 16.39, 62.18, 16.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 545 Drawing
        context.beginPath();
        context.moveTo(68.04, 17.81);
        context.bezierCurveTo(79.64, 20.29, 98.61, 25.98, 98.96, 36.7);
        context.bezierCurveTo(99.41, 50.66, 112.26, 59.64, 121.9, 59.64);
        context.bezierCurveTo(131.55, 59.64, 132.71, 48.83, 132.71, 48.83);
        context.bezierCurveTo(132.71, 48.83, 134.7, 35.2, 114.75, 34.54);
        context.bezierCurveTo(94.8, 33.87, 102.95, 58.98, 85.65, 64.96);
        context.bezierCurveTo(68.36, 70.95, 76.01, 47.67, 44.09, 47.34);
        context.bezierCurveTo(12.16, 47.01, 17.82, 67.29, 23.47, 76.6);
        context.bezierCurveTo(29.12, 85.91, 40.43, 107.53, 51.07, 96.55);
        context.bezierCurveTo(61.71, 85.58, 68.03, 72.28, 73.02, 83.25);
        context.bezierCurveTo(78.01, 94.23, 87.09, 122.95, 73.35, 120.5);
        context.bezierCurveTo(51.85, 116.66, 46.2, 108.77, 61.38, 101.21);
        context.bezierCurveTo(69.61, 97.11, 100.29, 81.92, 106.27, 79.26);
        context.bezierCurveTo(110.05, 77.58, 116.52, 75.96, 120.79, 76.41);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.save();
        context.setLineDash([5.96, 5.96]);
        context.lineDashOffset = 0;
        context.stroke();
        context.restore();


        //// Bezier 546 Drawing
        context.beginPath();
        context.moveTo(123.63, 77.26);
        context.bezierCurveTo(124.5, 77.8, 125.02, 78.61, 125.02, 79.76);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 547 Drawing
        context.beginPath();
        context.moveTo(33.11, 44.01);
        context.bezierCurveTo(33.11, 44.01, 28.45, 45.79, 27.79, 47.67);
        context.bezierCurveTo(27.13, 49.58, 24.8, 54.66, 29.45, 54.66);
        context.bezierCurveTo(29.83, 54.66, 30.18, 54.65, 30.51, 54.64);
        context.bezierCurveTo(32.98, 54.58, 35.35, 53.83, 37.52, 52.66);
        context.bezierCurveTo(38.6, 52.08, 40.1, 51.66, 41.76, 52.33);
        context.bezierCurveTo(45.08, 53.66, 45.75, 56.32, 47.74, 52.99);
        context.bezierCurveTo(49.74, 49.67, 51.24, 50.16, 49.91, 47.5);
        context.bezierCurveTo(48.58, 44.84, 49.62, 46.37, 47.08, 43.02);
        context.bezierCurveTo(45.25, 40.6, 44.75, 41.02, 41.43, 41.69);
        context.bezierCurveTo(38.1, 42.35, 33.11, 44.01, 33.11, 44.01);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 548 Drawing
        context.beginPath();
        context.moveTo(43.09, 91.79);
        context.bezierCurveTo(41.8, 92.36, 41.1, 93.78, 37.44, 95.78);
        context.bezierCurveTo(33.78, 97.77, 31.45, 94.78, 30.45, 97.77);
        context.bezierCurveTo(29.45, 100.77, 27.46, 104.09, 31.45, 105.76);
        context.bezierCurveTo(35.44, 107.42, 38.1, 105.09, 38.77, 108.42);
        context.bezierCurveTo(39.43, 111.74, 37.44, 115.73, 42.09, 115.07);
        context.bezierCurveTo(46.75, 114.4, 48.41, 111.41, 48.41, 111.41);
        context.bezierCurveTo(48.41, 111.41, 44.42, 108.08, 47.41, 105.76);
        context.bezierCurveTo(50.4, 103.43, 48.08, 105.42, 50.4, 103.43);
        context.bezierCurveTo(52.73, 101.43, 56.39, 99.77, 53.4, 97.77);
        context.bezierCurveTo(50.4, 95.78, 52.4, 99.1, 50.4, 95.78);
        context.bezierCurveTo(48.41, 92.45, 46.08, 90.46, 43.09, 91.79);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 549 Drawing
        context.beginPath();
        context.moveTo(158.29, 105.72);
        context.lineTo(155.65, 103.07);
        context.lineTo(150.42, 108.31);
        context.lineTo(145.18, 103.07);
        context.lineTo(142.54, 105.72);
        context.lineTo(147.77, 110.95);
        context.lineTo(142.54, 116.18);
        context.lineTo(145.18, 118.83);
        context.lineTo(150.42, 113.6);
        context.lineTo(155.65, 118.83);
        context.lineTo(158.29, 116.18);
        context.lineTo(153.06, 110.95);
        context.lineTo(158.29, 105.72);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 550 Drawing
        context.beginPath();
        context.moveTo(158.29, 105.72);
        context.lineTo(155.65, 103.07);
        context.lineTo(150.42, 108.31);
        context.lineTo(145.18, 103.07);
        context.lineTo(142.54, 105.72);
        context.lineTo(147.77, 110.95);
        context.lineTo(142.54, 116.18);
        context.lineTo(145.18, 118.83);
        context.lineTo(150.42, 113.6);
        context.lineTo(155.65, 118.83);
        context.lineTo(158.29, 116.18);
        context.lineTo(153.06, 110.95);
        context.lineTo(158.29, 105.72);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 551 Drawing
        context.beginPath();
        context.moveTo(125.56, 49.33);
        context.bezierCurveTo(125.56, 49.33, 114.25, 59.64, 119.91, 65.63);
        context.bezierCurveTo(125.56, 71.61, 122.57, 72.61, 128.22, 70.95);
        context.bezierCurveTo(135.67, 68.76, 132.21, 65.3, 134.87, 63.3);
        context.bezierCurveTo(137.53, 61.31, 139.19, 63.97, 139.53, 60.31);
        context.bezierCurveTo(139.69, 58.5, 138.56, 55.97, 137.37, 53.87);
        context.bezierCurveTo(135.66, 50.84, 132.67, 48.67, 129.21, 48.34);
        context.bezierCurveTo(127.76, 48.21, 126.37, 48.4, 125.56, 49.33);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 552 Drawing
        context.beginPath();
        context.moveTo(50.32, 15.32);
        context.bezierCurveTo(49.71, 15.32, 49.22, 14.82, 49.22, 14.22);
        context.bezierCurveTo(49.22, 13.61, 49.71, 13.12, 50.32, 13.12);
        context.bezierCurveTo(50.93, 13.12, 51.42, 13.61, 51.42, 14.22);
        context.bezierCurveTo(51.42, 14.82, 50.93, 15.32, 50.32, 15.32);
        context.closePath();
        context.moveTo(46.17, 15.32);
        context.bezierCurveTo(45.56, 15.32, 45.07, 14.82, 45.07, 14.22);
        context.bezierCurveTo(45.07, 13.61, 45.56, 13.12, 46.17, 13.12);
        context.bezierCurveTo(46.78, 13.12, 47.27, 13.61, 47.27, 14.22);
        context.bezierCurveTo(47.27, 14.82, 46.78, 15.32, 46.17, 15.32);
        context.closePath();
        context.moveTo(54.73, 16.62);
        context.bezierCurveTo(54.94, 8.95, 48.37, 9.21, 48.37, 9.21);
        context.bezierCurveTo(48.37, 9.21, 41.8, 8.95, 42.01, 16.62);
        context.bezierCurveTo(42.01, 16.62, 41.74, 18.98, 43.06, 18.93);
        context.lineTo(44.32, 18.93);
        context.lineTo(44.32, 21.19);
        context.bezierCurveTo(44.32, 21.19, 45.8, 22.08, 48.37, 21.98);
        context.bezierCurveTo(50.94, 22.08, 52.41, 21.19, 52.41, 21.19);
        context.lineTo(52.41, 18.93);
        context.lineTo(53.67, 18.93);
        context.bezierCurveTo(54.99, 18.98, 54.73, 16.62, 54.73, 16.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 553 Drawing
        context.beginPath();
        context.moveTo(50.32, 15.32);
        context.bezierCurveTo(49.71, 15.32, 49.22, 14.82, 49.22, 14.22);
        context.bezierCurveTo(49.22, 13.61, 49.71, 13.12, 50.32, 13.12);
        context.bezierCurveTo(50.93, 13.12, 51.42, 13.61, 51.42, 14.22);
        context.bezierCurveTo(51.42, 14.82, 50.93, 15.32, 50.32, 15.32);
        context.closePath();
        context.moveTo(46.17, 15.32);
        context.bezierCurveTo(45.56, 15.32, 45.07, 14.82, 45.07, 14.22);
        context.bezierCurveTo(45.07, 13.61, 45.56, 13.12, 46.17, 13.12);
        context.bezierCurveTo(46.78, 13.12, 47.27, 13.61, 47.27, 14.22);
        context.bezierCurveTo(47.27, 14.82, 46.78, 15.32, 46.17, 15.32);
        context.closePath();
        context.moveTo(54.73, 16.62);
        context.bezierCurveTo(54.94, 8.95, 48.37, 9.21, 48.37, 9.21);
        context.bezierCurveTo(48.37, 9.21, 41.8, 8.95, 42.01, 16.62);
        context.bezierCurveTo(42.01, 16.62, 41.74, 18.98, 43.06, 18.93);
        context.lineTo(44.32, 18.93);
        context.lineTo(44.32, 21.19);
        context.bezierCurveTo(44.32, 21.19, 45.8, 22.08, 48.37, 21.98);
        context.bezierCurveTo(50.94, 22.08, 52.41, 21.19, 52.41, 21.19);
        context.lineTo(52.41, 18.93);
        context.lineTo(53.67, 18.93);
        context.bezierCurveTo(54.99, 18.98, 54.73, 16.62, 54.73, 16.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 554 Drawing
        context.beginPath();
        context.moveTo(40.33, 30.07);
        context.lineTo(56.03, 23.66);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 555 Drawing
        context.beginPath();
        context.moveTo(56, 30.15);
        context.lineTo(40.36, 23.58);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 556 Drawing
        context.beginPath();
        context.moveTo(118.52, 79.35);
        context.lineTo(131.82, 92.65);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor13;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 557 Drawing
        context.beginPath();
        context.moveTo(131.82, 79.35);
        context.lineTo(118.52, 92.65);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor13;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawMusicBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor36 = 'rgba(235, 71, 59, 1)';

        //// Group 21
        context.save();

        //// Clip Clip 19

        context.beginPath();
        context.moveTo(42.08, 1.28);
        context.bezierCurveTo(40.09, 1.28, 38.25, 2.35, 37.25, 4.07);
        context.lineTo(37.25, 4.07);
        context.lineTo(2.68, 63.96);
        context.bezierCurveTo(1.68, 65.68, 1.68, 67.8, 2.68, 69.53);
        context.lineTo(2.68, 69.53);
        context.lineTo(37.25, 129.41);
        context.bezierCurveTo(38.25, 131.14, 40.09, 132.2, 42.08, 132.2);
        context.lineTo(42.08, 132.2);
        context.lineTo(111.23, 132.2);
        context.bezierCurveTo(113.22, 132.2, 115.06, 131.14, 116.05, 129.41);
        context.lineTo(116.05, 129.41);
        context.lineTo(150.63, 69.53);
        context.bezierCurveTo(151.62, 67.8, 151.62, 65.68, 150.63, 63.96);
        context.lineTo(150.63, 63.96);
        context.lineTo(116.05, 4.07);
        context.bezierCurveTo(115.06, 2.35, 113.22, 1.28, 111.23, 1.28);
        context.lineTo(111.23, 1.28);
        context.lineTo(42.08, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 514 Drawing
        context.beginPath();
        context.moveTo(32.42, -3.92);
        context.lineTo(-2.83, 69.24);
        context.lineTo(38.74, 136.08);
        context.lineTo(119.55, 135.41);
        context.lineTo(155.46, 67.58);
        context.lineTo(114.23, -2.26);
        context.lineTo(32.42, -3.92);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 515 Drawing
        context.beginPath();
        context.moveTo(32.42, -3.92);
        context.lineTo(-2.83, 69.24);
        context.lineTo(38.74, 136.08);
        context.lineTo(119.55, 135.41);
        context.lineTo(155.46, 67.58);
        context.lineTo(114.23, -2.26);
        context.lineTo(32.42, -3.92);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 516 Drawing
        context.beginPath();
        context.moveTo(1.71, 40.05);
        context.bezierCurveTo(1.71, 40.05, 41.55, 22.1, 56.95, 43.19);
        context.bezierCurveTo(73.03, 65.22, 94.01, 89.69, 156.58, 77.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 517 Drawing
        context.beginPath();
        context.moveTo(1.71, 47.4);
        context.bezierCurveTo(1.71, 47.4, 41.55, 29.46, 56.95, 50.55);
        context.bezierCurveTo(73.03, 72.58, 94.01, 97.05, 156.58, 85.16);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 518 Drawing
        context.beginPath();
        context.moveTo(1.71, 55.81);
        context.bezierCurveTo(1.71, 55.81, 41.55, 37.87, 56.95, 58.96);
        context.bezierCurveTo(73.03, 80.99, 94.01, 105.46, 156.58, 93.57);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 519 Drawing
        context.beginPath();
        context.moveTo(1.71, 63.17);
        context.bezierCurveTo(1.71, 63.17, 41.55, 45.23, 56.95, 66.32);
        context.bezierCurveTo(73.03, 88.34, 94.01, 112.82, 156.58, 100.93);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 520 Drawing
        context.beginPath();
        context.moveTo(1.71, 71.58);
        context.bezierCurveTo(1.71, 71.58, 41.55, 53.64, 56.95, 74.73);
        context.bezierCurveTo(73.03, 96.76, 94.01, 121.23, 156.58, 109.34);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 521 Drawing
        context.beginPath();
        context.moveTo(1.71, 33.05);
        context.bezierCurveTo(1.71, 33.05, 41.55, 15.1, 56.95, 36.19);
        context.bezierCurveTo(73.03, 58.22, 94.01, 82.69, 156.58, 70.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 77 Drawing
        oval(context, 21.4, 59.54, 6.1, 6.1);
        context.fillStyle = AvatarStyleKit.col16;
        context.fill();


        //// Oval 78 Drawing
        oval(context, 21.4, 59.54, 6.1, 6.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 79 Drawing
        oval(context, 37.2, 56.14, 6.1, 6.1);
        context.fillStyle = AvatarStyleKit.col16;
        context.fill();


        //// Oval 80 Drawing
        oval(context, 37.2, 56.14, 6.1, 6.1);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 522 Drawing
        context.beginPath();
        context.moveTo(27.46, 61.94);
        context.lineTo(24.46, 47.93);
        context.lineTo(40.21, 44.55);
        context.lineTo(43.22, 58.55);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 81 Drawing
        oval(context, 80.95, 63.89, 8, 8);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Oval 82 Drawing
        oval(context, 80.95, 63.89, 8, 8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 523 Drawing
        context.beginPath();
        context.moveTo(88.86, 68.77);
        context.lineTo(92.87, 50.58);
        context.lineTo(105.22, 53.59);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 524 Drawing
        context.beginPath();
        context.moveTo(123.62, 92.75);
        context.lineTo(118.62, 106.66);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 525 Drawing
        context.beginPath();
        context.moveTo(118.62, 106.66);
        context.bezierCurveTo(118.62, 106.66, 111.34, 104.1, 111.56, 107.4);
        context.bezierCurveTo(111.78, 110.7, 116.76, 111.46, 118.62, 106.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 526 Drawing
        context.beginPath();
        context.moveTo(124.69, 75.69);
        context.lineTo(121.95, 66.15);
        context.bezierCurveTo(121.95, 66.15, 124.96, 66.63, 126.18, 68.32);
        context.bezierCurveTo(127.41, 70.02, 128.44, 69.84, 128.44, 69.84);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 527 Drawing
        context.beginPath();
        context.moveTo(124.69, 75.69);
        context.bezierCurveTo(124.69, 75.69, 119.71, 77.16, 121.13, 78.88);
        context.bezierCurveTo(122.55, 80.59, 125.57, 79.04, 124.69, 75.69);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 83 Drawing
        oval(context, 46.25, 25.19, 5.2, 5.2);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Oval 84 Drawing
        oval(context, 46.25, 25.19, 5.2, 5.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 528 Drawing
        context.beginPath();
        context.moveTo(51.38, 28.34);
        context.lineTo(53.98, 16.55);
        context.lineTo(61.98, 18.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 529 Drawing
        context.beginPath();
        context.moveTo(58.04, 110.62);
        context.bezierCurveTo(55.2, 111.23, 52.28, 110.58, 52.61, 108.28);
        context.bezierCurveTo(53.06, 105.14, 60.3, 103.63, 61.75, 108.37);
        context.bezierCurveTo(62.65, 111.31, 61.9, 114.46, 57.8, 115.18);
        context.bezierCurveTo(51.07, 116.37, 46.16, 107.44, 51.89, 103.84);
        context.bezierCurveTo(52.2, 103.65, 52.5, 103.45, 52.78, 103.25);
        context.bezierCurveTo(56.67, 100.55, 58.97, 98.61, 57.57, 94.09);
        context.bezierCurveTo(57.08, 92.52, 56.3, 91.26, 55.05, 91.35);
        context.bezierCurveTo(51.86, 91.59, 51.92, 93.69, 54.36, 104.38);
        context.bezierCurveTo(56.8, 115.06, 57.13, 119.64, 54.8, 120.02);
        context.bezierCurveTo(54.8, 120.02, 53.28, 120.18, 52.84, 119.04);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 530 Drawing
        context.beginPath();
        context.moveTo(52.88, 119.22);
        context.bezierCurveTo(52.88, 119.22, 47.4, 119.11, 49.66, 115.84);
        context.bezierCurveTo(50.82, 114.15, 52.88, 119.22, 52.88, 119.22);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 531 Drawing
        context.beginPath();
        context.moveTo(52.88, 119.22);
        context.bezierCurveTo(52.88, 119.22, 47.4, 119.11, 49.66, 115.84);
        context.bezierCurveTo(50.82, 114.15, 52.88, 119.22, 52.88, 119.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 85 Drawing
        oval(context, 96.95, 22.59, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Oval 86 Drawing
        oval(context, 96.95, 22.59, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 87 Drawing
        oval(context, 106.95, 27.34, 4.2, 4.2);
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Oval 88 Drawing
        oval(context, 106.95, 27.34, 4.2, 4.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 532 Drawing
        context.beginPath();
        context.moveTo(100.94, 25.58);
        context.lineTo(105.14, 16.73);
        context.lineTo(115.1, 21.46);
        context.lineTo(110.9, 30.31);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 533 Drawing
        context.beginPath();
        context.moveTo(79.17, -6.87);
        context.lineTo(83, 7.41);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 534 Drawing
        context.beginPath();
        context.moveTo(83, 7.41);
        context.bezierCurveTo(83, 7.41, 75.56, 9.47, 77.63, 12.05);
        context.bezierCurveTo(79.69, 14.64, 84.22, 12.41, 83, 7.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 535 Drawing
        context.beginPath();
        context.moveTo(20.78, 94.23);
        context.lineTo(23.42, 84.66);
        context.bezierCurveTo(23.42, 84.66, 25.74, 86.64, 25.9, 88.72);
        context.bezierCurveTo(26.06, 90.81, 27.03, 91.2, 27.03, 91.2);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 536 Drawing
        context.beginPath();
        context.moveTo(20.78, 94.23);
        context.bezierCurveTo(20.78, 94.23, 15.77, 92.89, 16.09, 95.09);
        context.bezierCurveTo(16.4, 97.29, 19.78, 97.55, 20.78, 94.23);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 537 Drawing
        context.beginPath();
        context.moveTo(137.54, 48.63);
        context.bezierCurveTo(134.64, 48.91, 131.82, 47.94, 132.4, 45.69);
        context.bezierCurveTo(133.2, 42.62, 140.56, 41.93, 141.47, 46.8);
        context.bezierCurveTo(142.03, 49.82, 140.94, 52.87, 136.79, 53.13);
        context.bezierCurveTo(129.97, 53.56, 126.09, 44.13, 132.18, 41.2);
        context.bezierCurveTo(132.52, 41.04, 132.83, 40.88, 133.14, 40.71);
        context.bezierCurveTo(137.3, 38.46, 139.81, 36.79, 138.92, 32.14);
        context.bezierCurveTo(138.61, 30.53, 137.98, 29.19, 136.72, 29.15);
        context.bezierCurveTo(133.53, 29.02, 133.35, 31.11, 134.58, 42.01);
        context.bezierCurveTo(135.81, 52.9, 135.62, 57.49, 133.27, 57.6);
        context.bezierCurveTo(133.27, 57.6, 131.74, 57.59, 131.42, 56.41);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 538 Drawing
        context.beginPath();
        context.moveTo(131.44, 56.6);
        context.bezierCurveTo(131.44, 56.6, 126.01, 55.87, 128.62, 52.87);
        context.bezierCurveTo(129.96, 51.33, 131.44, 56.6, 131.44, 56.6);
        context.closePath();
        context.fillStyle = fillColor36;
        context.fill();


        //// Bezier 539 Drawing
        context.beginPath();
        context.moveTo(131.44, 56.6);
        context.bezierCurveTo(131.44, 56.6, 126.01, 55.87, 128.62, 52.87);
        context.bezierCurveTo(129.96, 51.33, 131.44, 56.6, 131.44, 56.6);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawRomanBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';
        var fillColor22 = 'rgba(199, 168, 140, 1)';
        var fillColor23 = 'rgba(211, 193, 179, 1)';
        var fillColor25 = 'rgba(185, 146, 111, 1)';
        var fillColor29 = 'rgba(51, 185, 245, 1)';

        //// Group
        context.save();

        //// Clip Bezier 650

        context.beginPath();
        context.moveTo(110.64, 1.28);
        context.lineTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.66, 2.35, 36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.66, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(110.64, 132.2);
        context.bezierCurveTo(112.63, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(150.04, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.04, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.63, 1.28, 110.64, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 609 Drawing
        context.beginPath();
        context.moveTo(110.64, 1.28);
        context.lineTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.66, 2.35, 36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.66, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(110.64, 132.2);
        context.bezierCurveTo(112.63, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(150.04, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.04, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.63, 1.28, 110.64, 1.28);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 610 Drawing
        context.beginPath();
        context.moveTo(110.64, 1.28);
        context.lineTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.66, 2.35, 36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.66, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(110.64, 132.2);
        context.bezierCurveTo(112.63, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(150.04, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.04, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.63, 1.28, 110.64, 1.28);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 611 Drawing
        context.beginPath();
        context.moveTo(37.66, -2.87);
        context.lineTo(-2.91, 67.96);
        context.lineTo(27.35, 124.82);
        context.lineTo(126.45, 124.16);
        context.lineTo(157.04, 73.61);
        context.lineTo(109.49, -10.52);
        context.lineTo(37.66, -2.87);
        context.closePath();
        context.fillStyle = fillColor29;
        context.fill();


        //// Bezier 612 Drawing
        context.beginPath();
        context.moveTo(37.66, -2.87);
        context.lineTo(-2.91, 67.96);
        context.lineTo(27.35, 124.82);
        context.lineTo(126.45, 124.16);
        context.lineTo(157.04, 73.61);
        context.lineTo(109.49, -10.52);
        context.lineTo(37.66, -2.87);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 613 Drawing
        context.beginPath();
        context.moveTo(27.02, 114.68);
        context.lineTo(126.34, 114.68);
        context.lineTo(114.59, 137.74);
        context.lineTo(28.13, 136.41);
        context.lineTo(21.92, 114.9);
        context.lineTo(27.02, 114.68);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 614 Drawing
        context.beginPath();
        context.moveTo(27.02, 114.68);
        context.lineTo(126.34, 114.68);
        context.lineTo(114.59, 137.74);
        context.lineTo(28.13, 136.41);
        context.lineTo(21.92, 114.9);
        context.lineTo(27.02, 114.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 615 Drawing
        context.beginPath();
        context.moveTo(162.7, 111.35);
        context.lineTo(62.63, 111.35);
        context.lineTo(62.63, 121.27);
        context.lineTo(162.7, 121.27);
        context.lineTo(162.7, 111.35);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 616 Drawing
        context.beginPath();
        context.moveTo(69.04, 102.96);
        context.lineTo(157.36, 102.96);
        context.lineTo(157.36, 111.2);
        context.lineTo(69.04, 111.2);
        context.lineTo(69.04, 102.96);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 617 Drawing
        context.beginPath();
        context.moveTo(83.38, 53.51);
        context.lineTo(85.66, 102.96);
        context.lineTo(73.16, 102.96);
        context.lineTo(74.99, 53.54);
        context.lineTo(83.38, 53.51);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 618 Drawing
        context.beginPath();
        context.moveTo(83.38, 53.51);
        context.lineTo(85.66, 102.96);
        context.lineTo(73.16, 102.96);
        context.lineTo(74.99, 53.54);
        context.lineTo(83.38, 53.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 619 Drawing
        context.beginPath();
        context.moveTo(105.85, 53.51);
        context.lineTo(108.14, 102.96);
        context.lineTo(95.63, 102.96);
        context.lineTo(97.46, 53.54);
        context.lineTo(105.85, 53.51);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 620 Drawing
        context.beginPath();
        context.moveTo(105.85, 53.51);
        context.lineTo(108.14, 102.96);
        context.lineTo(95.63, 102.96);
        context.lineTo(97.46, 53.54);
        context.lineTo(105.85, 53.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 621 Drawing
        context.beginPath();
        context.moveTo(127.87, 53.51);
        context.lineTo(130.16, 102.96);
        context.lineTo(117.65, 102.96);
        context.lineTo(119.48, 53.54);
        context.lineTo(127.87, 53.51);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 622 Drawing
        context.beginPath();
        context.moveTo(127.87, 53.51);
        context.lineTo(130.16, 102.96);
        context.lineTo(117.65, 102.96);
        context.lineTo(119.48, 53.54);
        context.lineTo(127.87, 53.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 623 Drawing
        context.beginPath();
        context.moveTo(150.35, 53.51);
        context.lineTo(152.64, 102.96);
        context.lineTo(140.13, 102.96);
        context.lineTo(141.96, 53.54);
        context.lineTo(150.35, 53.51);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();


        //// Bezier 624 Drawing
        context.beginPath();
        context.moveTo(150.35, 53.51);
        context.lineTo(152.64, 102.96);
        context.lineTo(140.13, 102.96);
        context.lineTo(141.96, 53.54);
        context.lineTo(150.35, 53.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 625 Drawing
        context.beginPath();
        context.moveTo(74.99, 53.54);
        context.lineTo(73.16, 48.81);
        context.lineTo(85.59, 48.81);
        context.lineTo(83.38, 53.51);
        context.lineTo(74.99, 53.54);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 626 Drawing
        context.beginPath();
        context.moveTo(74.99, 53.54);
        context.lineTo(73.16, 48.81);
        context.lineTo(85.59, 48.81);
        context.lineTo(83.38, 53.51);
        context.lineTo(74.99, 53.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 627 Drawing
        context.beginPath();
        context.moveTo(97.46, 53.54);
        context.lineTo(95.63, 48.81);
        context.lineTo(108.07, 48.81);
        context.lineTo(105.85, 53.51);
        context.bezierCurveTo(105.55, 53.77, 97.46, 53.54, 97.46, 53.54);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 628 Drawing
        context.beginPath();
        context.moveTo(97.46, 53.54);
        context.lineTo(95.63, 48.81);
        context.lineTo(108.07, 48.81);
        context.lineTo(105.85, 53.51);
        context.bezierCurveTo(105.55, 53.77, 97.46, 53.54, 97.46, 53.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 629 Drawing
        context.beginPath();
        context.moveTo(119.48, 53.54);
        context.lineTo(117.65, 48.81);
        context.lineTo(130.08, 48.81);
        context.lineTo(127.87, 53.51);
        context.lineTo(119.48, 53.54);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 630 Drawing
        context.beginPath();
        context.moveTo(119.48, 53.54);
        context.lineTo(117.65, 48.81);
        context.lineTo(130.08, 48.81);
        context.lineTo(127.87, 53.51);
        context.lineTo(119.48, 53.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 631 Drawing
        context.beginPath();
        context.moveTo(141.96, 53.54);
        context.lineTo(140.13, 48.81);
        context.lineTo(152.56, 48.81);
        context.lineTo(150.35, 53.51);
        context.lineTo(141.96, 53.54);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 632 Drawing
        context.beginPath();
        context.moveTo(141.96, 53.54);
        context.lineTo(140.13, 48.81);
        context.lineTo(152.56, 48.81);
        context.lineTo(150.35, 53.51);
        context.lineTo(141.96, 53.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 633 Drawing
        context.beginPath();
        context.moveTo(157.36, 48.76);
        context.lineTo(68.99, 48.76);
        context.lineTo(68.99, 40.62);
        context.lineTo(157.36, 40.62);
        context.lineTo(157.36, 48.76);
        context.closePath();
        context.fillStyle = fillColor23;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 634 Drawing
        context.beginPath();
        context.moveTo(63.6, 40.52);
        context.lineTo(162.14, 40.52);
        context.lineTo(112.92, 21.3);
        context.lineTo(63.6, 40.52);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 635 Drawing
        context.beginPath();
        context.moveTo(63.6, 40.52);
        context.lineTo(162.14, 40.52);
        context.lineTo(112.92, 21.3);
        context.lineTo(63.6, 40.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 636 Drawing
        context.beginPath();
        context.moveTo(51.63, 99.88);
        context.lineTo(51.63, 121.16);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 637 Drawing
        context.beginPath();
        context.moveTo(51.3, 66.63);
        context.bezierCurveTo(51.3, 66.63, 64.06, 99.88, 51.3, 99.88);
        context.bezierCurveTo(38.54, 99.88, 51.3, 66.63, 51.3, 66.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 638 Drawing
        context.beginPath();
        context.moveTo(51.3, 66.63);
        context.bezierCurveTo(51.3, 66.63, 64.06, 99.88, 51.3, 99.88);
        context.bezierCurveTo(38.54, 99.88, 51.3, 66.63, 51.3, 66.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 639 Drawing
        context.beginPath();
        context.moveTo(33.63, 99.88);
        context.lineTo(33.63, 121.16);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 640 Drawing
        context.beginPath();
        context.moveTo(33.3, 66.63);
        context.bezierCurveTo(33.3, 66.63, 46.06, 99.88, 33.3, 99.88);
        context.bezierCurveTo(20.54, 99.88, 33.3, 66.63, 33.3, 66.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 641 Drawing
        context.beginPath();
        context.moveTo(33.3, 66.63);
        context.bezierCurveTo(33.3, 66.63, 46.06, 99.88, 33.3, 99.88);
        context.bezierCurveTo(20.54, 99.88, 33.3, 66.63, 33.3, 66.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 642 Drawing
        context.beginPath();
        context.moveTo(13.63, 99.88);
        context.lineTo(13.63, 121.16);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 643 Drawing
        context.beginPath();
        context.moveTo(13.3, 66.63);
        context.bezierCurveTo(13.3, 66.63, 26.06, 99.88, 13.3, 99.88);
        context.bezierCurveTo(0.54, 99.88, 13.3, 66.63, 13.3, 66.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 644 Drawing
        context.beginPath();
        context.moveTo(13.3, 66.63);
        context.bezierCurveTo(13.3, 66.63, 26.06, 99.88, 13.3, 99.88);
        context.bezierCurveTo(0.54, 99.88, 13.3, 66.63, 13.3, 66.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawChemistryBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';
        var strokeColor16 = 'rgba(255, 255, 255, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';
        var fillColor13 = 'rgba(230, 209, 181, 1)';
        var fillColor14 = 'rgba(139, 98, 57, 1)';

        //// Group 17
        context.save();

        //// Clip Clip 15

        context.beginPath();
        context.moveTo(41.71, 0.62);
        context.bezierCurveTo(39.72, 0.62, 37.88, 1.68, 36.88, 3.4);
        context.lineTo(36.88, 3.4);
        context.lineTo(2.31, 63.29);
        context.bezierCurveTo(1.31, 65.02, 1.31, 67.14, 2.31, 68.86);
        context.lineTo(2.31, 68.86);
        context.lineTo(36.88, 128.75);
        context.bezierCurveTo(37.88, 130.47, 39.72, 131.53, 41.71, 131.53);
        context.lineTo(41.71, 131.53);
        context.lineTo(110.86, 131.53);
        context.bezierCurveTo(112.85, 131.53, 114.69, 130.47, 115.68, 128.75);
        context.lineTo(115.68, 128.75);
        context.lineTo(150.26, 68.86);
        context.bezierCurveTo(151.25, 67.14, 151.25, 65.02, 150.26, 63.29);
        context.lineTo(150.26, 63.29);
        context.lineTo(115.68, 3.4);
        context.bezierCurveTo(114.69, 1.68, 112.85, 0.62, 110.86, 0.62);
        context.lineTo(110.86, 0.62);
        context.lineTo(41.71, 0.62);
        context.closePath();
        context.clip();



        //// Bezier 371 Drawing
        context.beginPath();
        context.moveTo(36.44, -7.92);
        context.lineTo(-9.42, 61.83);
        context.lineTo(17.16, 88.94);
        context.lineTo(150.77, 81.66);
        context.lineTo(167.38, 58.19);
        context.lineTo(133.82, 1.34);
        context.lineTo(101.91, -8.91);
        context.lineTo(36.44, -7.92);
        context.closePath();
        context.fillStyle = fillColor13;
        context.fill();


        //// Bezier 372 Drawing
        context.beginPath();
        context.moveTo(36.44, -7.92);
        context.lineTo(-9.42, 61.83);
        context.lineTo(17.16, 88.94);
        context.lineTo(150.77, 81.66);
        context.lineTo(167.38, 58.19);
        context.lineTo(133.82, 1.34);
        context.lineTo(101.91, -8.91);
        context.lineTo(36.44, -7.92);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 373 Drawing
        context.beginPath();
        context.moveTo(1.22, 78.33);
        context.lineTo(150.64, 78.33);
        context.lineTo(122.26, 137.97);
        context.lineTo(30.48, 135.08);
        context.lineTo(1.22, 78.33);
        context.closePath();
        context.fillStyle = fillColor14;
        context.fill();


        //// Bezier 374 Drawing
        context.beginPath();
        context.moveTo(1.22, 78.33);
        context.lineTo(150.64, 78.33);
        context.lineTo(122.26, 137.97);
        context.lineTo(30.48, 135.08);
        context.lineTo(1.22, 78.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 375 Drawing
        context.beginPath();
        context.moveTo(90.01, 57.05);
        context.lineTo(138.23, 57.05);
        context.fillStyle = fillColor14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 376 Drawing
        context.beginPath();
        context.moveTo(90.01, 84.93);
        context.lineTo(138.23, 84.93);
        context.fillStyle = fillColor14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 377 Drawing
        context.beginPath();
        context.moveTo(93.21, 57.05);
        context.lineTo(93.21, 85.1);
        context.fillStyle = fillColor14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 378 Drawing
        context.beginPath();
        context.moveTo(135.03, 57.05);
        context.lineTo(135.03, 85.1);
        context.fillStyle = fillColor14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 379 Drawing
        context.beginPath();
        context.moveTo(100.2, 81.37);
        context.lineTo(100.2, 81.37);
        context.bezierCurveTo(98.15, 81.37, 96.49, 79.71, 96.49, 77.67);
        context.lineTo(96.49, 54.25);
        context.lineTo(103.91, 54.25);
        context.lineTo(103.91, 77.67);
        context.bezierCurveTo(103.91, 79.71, 102.25, 81.37, 100.2, 81.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 380 Drawing
        context.beginPath();
        context.moveTo(100.2, 81.37);
        context.lineTo(100.2, 81.37);
        context.bezierCurveTo(98.15, 81.37, 96.49, 79.71, 96.49, 77.67);
        context.lineTo(96.49, 54.25);
        context.lineTo(103.91, 54.25);
        context.lineTo(103.91, 77.67);
        context.bezierCurveTo(103.91, 79.71, 102.25, 81.37, 100.2, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 381 Drawing
        context.beginPath();
        context.moveTo(94.46, 54.22);
        context.lineTo(105.94, 54.22);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 382 Drawing
        context.beginPath();
        context.moveTo(128.04, 81.37);
        context.lineTo(128.04, 81.37);
        context.bezierCurveTo(125.99, 81.37, 124.33, 79.71, 124.33, 77.67);
        context.lineTo(124.33, 54.25);
        context.lineTo(131.75, 54.25);
        context.lineTo(131.75, 77.67);
        context.bezierCurveTo(131.75, 79.71, 130.09, 81.37, 128.04, 81.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 383 Drawing
        context.beginPath();
        context.moveTo(128.04, 81.37);
        context.lineTo(128.04, 81.37);
        context.bezierCurveTo(125.99, 81.37, 124.33, 79.71, 124.33, 77.67);
        context.lineTo(124.33, 54.25);
        context.lineTo(131.75, 54.25);
        context.lineTo(131.75, 77.67);
        context.bezierCurveTo(131.75, 79.71, 130.09, 81.37, 128.04, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 384 Drawing
        context.beginPath();
        context.moveTo(122.3, 54.22);
        context.lineTo(133.78, 54.22);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 385 Drawing
        context.beginPath();
        context.moveTo(114.12, 81.37);
        context.lineTo(114.12, 81.37);
        context.bezierCurveTo(112.07, 81.37, 110.41, 79.71, 110.41, 77.67);
        context.lineTo(110.41, 54.25);
        context.lineTo(117.83, 54.25);
        context.lineTo(117.83, 77.67);
        context.bezierCurveTo(117.83, 79.71, 116.17, 81.37, 114.12, 81.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 386 Drawing
        context.beginPath();
        context.moveTo(114.12, 81.37);
        context.lineTo(114.12, 81.37);
        context.bezierCurveTo(112.07, 81.37, 110.41, 79.71, 110.41, 77.67);
        context.lineTo(110.41, 54.25);
        context.lineTo(117.83, 54.25);
        context.lineTo(117.83, 77.67);
        context.bezierCurveTo(117.83, 79.71, 116.17, 81.37, 114.12, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 387 Drawing
        context.beginPath();
        context.moveTo(108.38, 54.22);
        context.lineTo(119.86, 54.22);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 388 Drawing
        context.beginPath();
        context.moveTo(100.2, 81.37);
        context.lineTo(100.2, 81.37);
        context.bezierCurveTo(98.15, 81.37, 96.49, 79.71, 96.49, 77.67);
        context.lineTo(96.49, 61.22);
        context.lineTo(103.91, 61.22);
        context.lineTo(103.91, 77.67);
        context.bezierCurveTo(103.91, 79.71, 102.25, 81.37, 100.2, 81.37);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 389 Drawing
        context.beginPath();
        context.moveTo(100.2, 81.37);
        context.lineTo(100.2, 81.37);
        context.bezierCurveTo(98.15, 81.37, 96.49, 79.71, 96.49, 77.67);
        context.lineTo(96.49, 61.22);
        context.lineTo(103.91, 61.22);
        context.lineTo(103.91, 77.67);
        context.bezierCurveTo(103.91, 79.71, 102.25, 81.37, 100.2, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 390 Drawing
        context.beginPath();
        context.moveTo(128.04, 81.37);
        context.lineTo(128.04, 81.37);
        context.bezierCurveTo(125.99, 81.37, 124.33, 79.71, 124.33, 77.67);
        context.lineTo(124.33, 61.22);
        context.lineTo(131.75, 61.22);
        context.lineTo(131.75, 77.67);
        context.bezierCurveTo(131.75, 79.71, 130.09, 81.37, 128.04, 81.37);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 391 Drawing
        context.beginPath();
        context.moveTo(128.04, 81.37);
        context.lineTo(128.04, 81.37);
        context.bezierCurveTo(125.99, 81.37, 124.33, 79.71, 124.33, 77.67);
        context.lineTo(124.33, 61.22);
        context.lineTo(131.75, 61.22);
        context.lineTo(131.75, 77.67);
        context.bezierCurveTo(131.75, 79.71, 130.09, 81.37, 128.04, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 392 Drawing
        context.beginPath();
        context.moveTo(114.12, 81.37);
        context.lineTo(114.12, 81.37);
        context.bezierCurveTo(112.07, 81.37, 110.41, 79.71, 110.41, 77.67);
        context.lineTo(110.41, 61.22);
        context.lineTo(117.83, 61.22);
        context.lineTo(117.83, 77.67);
        context.bezierCurveTo(117.83, 79.71, 116.17, 81.37, 114.12, 81.37);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 393 Drawing
        context.beginPath();
        context.moveTo(114.12, 81.37);
        context.lineTo(114.12, 81.37);
        context.bezierCurveTo(112.07, 81.37, 110.41, 79.71, 110.41, 77.67);
        context.lineTo(110.41, 61.22);
        context.lineTo(117.83, 61.22);
        context.lineTo(117.83, 77.67);
        context.bezierCurveTo(117.83, 79.71, 116.17, 81.37, 114.12, 81.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 394 Drawing
        context.beginPath();
        context.moveTo(102.03, 55.95);
        context.lineTo(104.04, 55.95);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 395 Drawing
        context.beginPath();
        context.moveTo(102.03, 57.35);
        context.lineTo(104.04, 57.35);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 396 Drawing
        context.beginPath();
        context.moveTo(102.03, 58.74);
        context.lineTo(104.04, 58.74);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 397 Drawing
        context.beginPath();
        context.moveTo(115.97, 55.95);
        context.lineTo(117.98, 55.95);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 398 Drawing
        context.beginPath();
        context.moveTo(115.97, 57.35);
        context.lineTo(117.98, 57.35);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 399 Drawing
        context.beginPath();
        context.moveTo(115.97, 58.74);
        context.lineTo(117.98, 58.74);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 400 Drawing
        context.beginPath();
        context.moveTo(129.91, 55.95);
        context.lineTo(131.92, 55.95);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 401 Drawing
        context.beginPath();
        context.moveTo(129.91, 57.35);
        context.lineTo(131.92, 57.35);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 402 Drawing
        context.beginPath();
        context.moveTo(129.91, 58.74);
        context.lineTo(131.92, 58.74);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 403 Drawing
        context.beginPath();
        context.moveTo(41.53, 52.19);
        context.lineTo(41.53, 43.74);
        context.lineTo(33.28, 43.74);
        context.lineTo(33.28, 52.19);
        context.bezierCurveTo(26.26, 54.01, 21.07, 60.4, 21.07, 68);
        context.bezierCurveTo(21.07, 77.02, 28.39, 84.33, 37.41, 84.33);
        context.bezierCurveTo(46.43, 84.33, 53.75, 77.02, 53.75, 68);
        context.bezierCurveTo(53.75, 60.4, 48.56, 54.01, 41.53, 52.19);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 404 Drawing
        context.beginPath();
        context.moveTo(41.53, 52.19);
        context.lineTo(41.53, 43.74);
        context.lineTo(33.28, 43.74);
        context.lineTo(33.28, 52.19);
        context.bezierCurveTo(26.26, 54.01, 21.07, 60.4, 21.07, 68);
        context.bezierCurveTo(21.07, 77.02, 28.39, 84.33, 37.41, 84.33);
        context.bezierCurveTo(46.43, 84.33, 53.75, 77.02, 53.75, 68);
        context.bezierCurveTo(53.75, 60.4, 48.56, 54.01, 41.53, 52.19);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 405 Drawing
        context.beginPath();
        context.moveTo(30.07, 43.74);
        context.lineTo(44.75, 43.74);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 406 Drawing
        context.beginPath();
        context.moveTo(35.01, 67.38);
        context.bezierCurveTo(31.48, 64.26, 25.14, 63.57, 21.73, 63.44);
        context.bezierCurveTo(21.31, 64.89, 21.07, 66.41, 21.07, 68);
        context.bezierCurveTo(21.07, 77.02, 28.39, 84.33, 37.41, 84.33);
        context.bezierCurveTo(46.25, 84.33, 53.45, 77.3, 53.73, 68.53);
        context.bezierCurveTo(48, 70.73, 38.53, 70.49, 35.01, 67.38);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Bezier 407 Drawing
        context.beginPath();
        context.moveTo(35.01, 67.38);
        context.bezierCurveTo(31.48, 64.26, 25.14, 63.57, 21.73, 63.44);
        context.bezierCurveTo(21.31, 64.89, 21.07, 66.41, 21.07, 68);
        context.bezierCurveTo(21.07, 77.02, 28.39, 84.33, 37.41, 84.33);
        context.bezierCurveTo(46.25, 84.33, 53.45, 77.3, 53.73, 68.53);
        context.bezierCurveTo(48, 70.73, 38.53, 70.49, 35.01, 67.38);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 68 Drawing
        oval(context, 42.1, 61.28, 4, 4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 69 Drawing
        oval(context, 33.85, 56.03, 6.6, 6.6);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();


        //// Oval 70 Drawing
        oval(context, 33.85, 56.03, 6.6, 6.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 71 Drawing
        oval(context, 27.8, 59.18, 2.6, 2.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 72 Drawing
        oval(context, 26.85, 71.63, 3.4, 3.4);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 73 Drawing
        oval(context, 32.7, 75.58, 5.5, 5.5);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 74 Drawing
        oval(context, 32.7, 75.58, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 75 Drawing
        oval(context, 38.75, 39.78, 2.6, 2.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 76 Drawing
        oval(context, 34.15, 34.38, 4.3, 4.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 10 Drawing
        context.beginPath();
        context.rect(81.1, 11.93, 59.5, 21.8);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 11 Drawing
        context.beginPath();
        context.rect(63.1, -6.07, 59.5, 21.8);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 12 Drawing
        context.beginPath();
        context.rect(82.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 13 Drawing
        context.beginPath();
        context.rect(91.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 14 Drawing
        context.beginPath();
        context.rect(82.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 15 Drawing
        context.beginPath();
        context.rect(91.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 16 Drawing
        context.beginPath();
        context.rect(100.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 17 Drawing
        context.beginPath();
        context.rect(109.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 18 Drawing
        context.beginPath();
        context.rect(118.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 19 Drawing
        context.beginPath();
        context.rect(127.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 20 Drawing
        context.beginPath();
        context.rect(136.55, 25.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 21 Drawing
        context.beginPath();
        context.rect(118.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 22 Drawing
        context.beginPath();
        context.rect(127.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 23 Drawing
        context.beginPath();
        context.rect(136.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 24 Drawing
        context.beginPath();
        context.rect(100.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 25 Drawing
        context.beginPath();
        context.rect(109.55, 16.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 26 Drawing
        context.beginPath();
        context.rect(64.55, 7.23, 7, 7);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 27 Drawing
        context.beginPath();
        context.rect(73.55, 7.23, 7, 7);
        context.fillStyle = fillColor6;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 28 Drawing
        context.beginPath();
        context.rect(82.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 29 Drawing
        context.beginPath();
        context.rect(91.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 30 Drawing
        context.beginPath();
        context.rect(118.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 31 Drawing
        context.beginPath();
        context.rect(127.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 32 Drawing
        context.beginPath();
        context.rect(136.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 33 Drawing
        context.beginPath();
        context.rect(100.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 34 Drawing
        context.beginPath();
        context.rect(109.55, 7.23, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 35 Drawing
        context.beginPath();
        context.rect(64.55, -1.77, 7, 7);
        context.fillStyle = fillColor40;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 36 Drawing
        context.beginPath();
        context.rect(73.55, -1.77, 7, 7);
        context.fillStyle = fillColor6;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 37 Drawing
        context.beginPath();
        context.rect(82.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 38 Drawing
        context.beginPath();
        context.rect(91.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 39 Drawing
        context.beginPath();
        context.rect(118.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 40 Drawing
        context.beginPath();
        context.rect(127.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 41 Drawing
        context.beginPath();
        context.rect(100.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 42 Drawing
        context.beginPath();
        context.rect(109.55, -1.77, 7, 7);
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var strokeColor16 = 'rgba(255, 255, 255, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Group 32
        //// Group 9
        context.save();

        //// Clip Clip 7

        context.beginPath();
        context.moveTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.67, 2.35, 36.67, 4.07);
        context.lineTo(36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.67, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(41.49, 132.2);
        context.lineTo(110.65, 132.2);
        context.bezierCurveTo(112.64, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(115.47, 129.41);
        context.lineTo(150.05, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.05, 63.96);
        context.lineTo(150.05, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.64, 1.28, 110.65, 1.28);
        context.lineTo(110.65, 1.28);
        context.lineTo(41.49, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 107 Drawing
        context.beginPath();
        context.moveTo(33.13, -7.8);
        context.lineTo(-6.11, 63.36);
        context.lineTo(-2.12, 74.23);
        context.lineTo(157.5, 72.45);
        context.lineTo(145.31, 39.64);
        context.lineTo(115.38, -6.25);
        context.lineTo(31.36, -8.91);
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 108 Drawing
        context.beginPath();
        context.moveTo(-8.33, 70.8);
        context.lineTo(156.61, 70.8);
        context.lineTo(129.79, 168.09);
        context.lineTo(45.77, 173.56);
        context.lineTo(-9.44, 109.02);
        context.lineTo(-8.33, 70.8);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 109 Drawing
        context.beginPath();
        context.moveTo(-8.33, 70.8);
        context.lineTo(156.61, 70.8);
        context.lineTo(129.79, 168.09);
        context.lineTo(45.77, 173.56);
        context.lineTo(-9.44, 109.02);
        context.lineTo(-8.33, 70.8);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.06;
        context.stroke();


        //// Bezier 110 Drawing
        context.beginPath();
        context.moveTo(119.82, 37.37);
        context.bezierCurveTo(107.05, 59.53, 109.5, 79.29, 109.5, 79.29);
        context.lineTo(76.07, 79.3);
        context.lineTo(109.5, 79.29);
        context.lineTo(122.61, 89.18);
        context.lineTo(119.82, 37.37);
        context.lineTo(76.17, 37.38);
        context.lineTo(75.96, 37.38);
        context.lineTo(32.32, 37.38);
        context.lineTo(29.53, 89.19);
        context.lineTo(42.64, 79.3);
        context.lineTo(76.07, 79.3);
        context.lineTo(42.64, 79.3);
        context.bezierCurveTo(42.64, 79.3, 45.09, 59.54, 32.32, 37.38);
        context.lineTo(119.82, 37.37);
        context.closePath();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 10
        context.save();

        //// Clip Clip 8

        context.beginPath();
        context.moveTo(76.17, 37.43);
        context.lineTo(75.96, 37.43);
        context.lineTo(32.32, 37.44);
        context.bezierCurveTo(45.09, 59.6, 42.64, 79.36, 42.64, 79.36);
        context.lineTo(42.64, 79.36);
        context.lineTo(109.5, 79.35);
        context.lineTo(76.07, 79.35);
        context.lineTo(109.5, 79.35);
        context.bezierCurveTo(109.5, 79.35, 107.05, 59.59, 119.81, 37.43);
        context.lineTo(119.81, 37.43);
        context.lineTo(76.17, 37.43);
        context.closePath();
        context.clip();



        //// Bezier 112 Drawing
        context.beginPath();
        context.moveTo(-19.76, 81.84);
        context.lineTo(84.21, 185.81);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 113 Drawing
        context.beginPath();
        context.moveTo(-16.44, 78.53);
        context.lineTo(87.52, 182.5);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 114 Drawing
        context.beginPath();
        context.moveTo(-13.13, 75.22);
        context.lineTo(90.84, 179.18);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 115 Drawing
        context.beginPath();
        context.moveTo(-9.82, 71.9);
        context.lineTo(94.15, 175.87);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 116 Drawing
        context.beginPath();
        context.moveTo(-6.5, 68.59);
        context.lineTo(97.46, 172.56);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 117 Drawing
        context.beginPath();
        context.moveTo(-3.19, 65.28);
        context.lineTo(100.78, 169.24);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 118 Drawing
        context.beginPath();
        context.moveTo(0.12, 61.96);
        context.lineTo(104.09, 165.93);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 119 Drawing
        context.beginPath();
        context.moveTo(3.44, 58.65);
        context.lineTo(107.41, 162.61);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 120 Drawing
        context.beginPath();
        context.moveTo(6.75, 55.33);
        context.lineTo(110.72, 159.3);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 121 Drawing
        context.beginPath();
        context.moveTo(10.07, 52.02);
        context.lineTo(114.03, 155.99);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 122 Drawing
        context.beginPath();
        context.moveTo(13.38, 48.71);
        context.lineTo(117.35, 152.67);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 123 Drawing
        context.beginPath();
        context.moveTo(16.69, 45.39);
        context.lineTo(120.66, 149.36);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 124 Drawing
        context.beginPath();
        context.moveTo(20.01, 42.08);
        context.lineTo(123.97, 146.05);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 125 Drawing
        context.beginPath();
        context.moveTo(23.32, 38.77);
        context.lineTo(127.29, 142.73);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 126 Drawing
        context.beginPath();
        context.moveTo(26.63, 35.45);
        context.lineTo(130.6, 139.42);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 127 Drawing
        context.beginPath();
        context.moveTo(29.95, 32.14);
        context.lineTo(133.91, 136.11);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 128 Drawing
        context.beginPath();
        context.moveTo(33.26, 28.83);
        context.lineTo(137.23, 132.79);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 129 Drawing
        context.beginPath();
        context.moveTo(36.58, 25.51);
        context.lineTo(140.54, 129.48);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 130 Drawing
        context.beginPath();
        context.moveTo(39.89, 22.2);
        context.lineTo(143.86, 126.16);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 131 Drawing
        context.beginPath();
        context.moveTo(43.2, 18.88);
        context.lineTo(147.17, 122.85);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 132 Drawing
        context.beginPath();
        context.moveTo(46.52, 15.57);
        context.lineTo(150.48, 119.54);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 133 Drawing
        context.beginPath();
        context.moveTo(49.83, 12.26);
        context.lineTo(153.8, 116.22);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 134 Drawing
        context.beginPath();
        context.moveTo(53.14, 8.94);
        context.lineTo(157.11, 112.91);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 135 Drawing
        context.beginPath();
        context.moveTo(56.46, 5.63);
        context.lineTo(160.42, 109.6);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 136 Drawing
        context.beginPath();
        context.moveTo(59.77, 2.32);
        context.lineTo(163.74, 106.28);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 137 Drawing
        context.beginPath();
        context.moveTo(63.08, -1);
        context.lineTo(167.05, 102.97);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 138 Drawing
        context.beginPath();
        context.moveTo(66.4, -4.31);
        context.lineTo(170.36, 99.66);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 139 Drawing
        context.beginPath();
        context.moveTo(69.71, -7.62);
        context.lineTo(173.68, 96.34);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 140 Drawing
        context.beginPath();
        context.moveTo(73.03, -10.94);
        context.lineTo(176.99, 93.03);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 141 Drawing
        context.beginPath();
        context.moveTo(76.34, -14.25);
        context.lineTo(180.31, 89.71);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 142 Drawing
        context.beginPath();
        context.moveTo(79.65, -17.57);
        context.lineTo(183.62, 86.4);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 143 Drawing
        context.beginPath();
        context.moveTo(82.97, -20.88);
        context.lineTo(186.93, 83.09);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 144 Drawing
        context.beginPath();
        context.moveTo(86.28, -24.19);
        context.lineTo(190.25, 79.77);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 145 Drawing
        context.beginPath();
        context.moveTo(89.59, -27.51);
        context.lineTo(193.56, 76.46);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 146 Drawing
        context.beginPath();
        context.moveTo(92.91, -30.82);
        context.lineTo(196.87, 73.15);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 147 Drawing
        context.beginPath();
        context.moveTo(96.22, -34.13);
        context.lineTo(200.19, 69.83);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 148 Drawing
        context.beginPath();
        context.moveTo(89.02, 193.01);
        context.lineTo(227.27, 54.76);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 149 Drawing
        context.beginPath();
        context.moveTo(85.71, 189.69);
        context.lineTo(223.95, 51.45);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 150 Drawing
        context.beginPath();
        context.moveTo(82.4, 186.38);
        context.lineTo(220.64, 48.14);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 151 Drawing
        context.beginPath();
        context.moveTo(79.08, 183.07);
        context.lineTo(217.33, 44.82);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 152 Drawing
        context.beginPath();
        context.moveTo(75.77, 179.75);
        context.lineTo(214.01, 41.51);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 153 Drawing
        context.beginPath();
        context.moveTo(72.46, 176.44);
        context.lineTo(210.7, 38.2);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 154 Drawing
        context.beginPath();
        context.moveTo(69.14, 173.13);
        context.lineTo(207.38, 34.88);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 155 Drawing
        context.beginPath();
        context.moveTo(65.83, 169.81);
        context.lineTo(204.07, 31.57);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 156 Drawing
        context.beginPath();
        context.moveTo(62.51, 166.5);
        context.lineTo(200.76, 28.26);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 157 Drawing
        context.beginPath();
        context.moveTo(59.2, 163.18);
        context.lineTo(197.44, 24.94);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 158 Drawing
        context.beginPath();
        context.moveTo(55.89, 159.87);
        context.lineTo(194.13, 21.63);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 159 Drawing
        context.beginPath();
        context.moveTo(52.57, 156.56);
        context.lineTo(190.82, 18.31);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 160 Drawing
        context.beginPath();
        context.moveTo(49.26, 153.24);
        context.lineTo(187.5, 15);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 161 Drawing
        context.beginPath();
        context.moveTo(45.95, 149.93);
        context.lineTo(184.19, 11.69);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 162 Drawing
        context.beginPath();
        context.moveTo(42.63, 146.62);
        context.lineTo(180.88, 8.37);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 163 Drawing
        context.beginPath();
        context.moveTo(39.32, 143.3);
        context.lineTo(177.56, 5.06);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 164 Drawing
        context.beginPath();
        context.moveTo(36, 139.99);
        context.lineTo(174.25, 1.75);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 165 Drawing
        context.beginPath();
        context.moveTo(32.69, 136.68);
        context.lineTo(170.93, -1.57);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 166 Drawing
        context.beginPath();
        context.moveTo(29.38, 133.36);
        context.lineTo(167.62, -4.88);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 167 Drawing
        context.beginPath();
        context.moveTo(26.06, 130.05);
        context.lineTo(164.31, -8.2);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 168 Drawing
        context.beginPath();
        context.moveTo(22.75, 126.73);
        context.lineTo(160.99, -11.51);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 169 Drawing
        context.beginPath();
        context.moveTo(19.44, 123.42);
        context.lineTo(157.68, -14.82);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 170 Drawing
        context.beginPath();
        context.moveTo(16.12, 120.11);
        context.lineTo(154.37, -18.14);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 171 Drawing
        context.beginPath();
        context.moveTo(12.81, 116.79);
        context.lineTo(151.05, -21.45);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 172 Drawing
        context.beginPath();
        context.moveTo(9.5, 113.48);
        context.lineTo(147.74, -24.76);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 173 Drawing
        context.beginPath();
        context.moveTo(6.18, 110.17);
        context.lineTo(144.43, -28.08);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 174 Drawing
        context.beginPath();
        context.moveTo(2.87, 106.85);
        context.lineTo(141.11, -31.39);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 175 Drawing
        context.beginPath();
        context.moveTo(-0.45, 103.54);
        context.lineTo(137.8, -34.7);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 176 Drawing
        context.beginPath();
        context.moveTo(-3.76, 100.23);
        context.lineTo(134.48, -38.02);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 177 Drawing
        context.beginPath();
        context.moveTo(-7.07, 96.91);
        context.lineTo(131.17, -41.33);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 178 Drawing
        context.beginPath();
        context.moveTo(-10.39, 93.6);
        context.lineTo(127.86, -44.65);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 179 Drawing
        context.beginPath();
        context.moveTo(-13.7, 90.28);
        context.lineTo(124.54, -47.96);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 180 Drawing
        context.beginPath();
        context.moveTo(-17.01, 86.97);
        context.lineTo(121.23, -51.27);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 181 Drawing
        context.beginPath();
        context.moveTo(-20.33, 83.66);
        context.lineTo(117.92, -54.59);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 182 Drawing
        context.beginPath();
        context.moveTo(-23.64, 80.34);
        context.lineTo(114.6, -57.9);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 183 Drawing
        context.beginPath();
        context.moveTo(-26.96, 77.03);
        context.lineTo(111.29, -61.21);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 184 Drawing
        context.beginPath();
        context.moveTo(99.53, -37.45);
        context.lineTo(203.5, 66.52);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 185 Drawing
        context.beginPath();
        context.moveTo(102.85, -40.76);
        context.lineTo(206.81, 63.21);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 186 Drawing
        context.beginPath();
        context.moveTo(106.16, -44.08);
        context.lineTo(210.13, 59.89);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 187 Drawing
        context.beginPath();
        context.moveTo(109.48, -47.39);
        context.lineTo(213.44, 56.58);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 188 Drawing
        context.beginPath();
        context.moveTo(112.79, -50.7);
        context.lineTo(216.76, 53.26);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 189 Drawing
        context.beginPath();
        context.moveTo(116.1, -54.02);
        context.lineTo(220.07, 49.95);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 11
        context.save();

        //// Clip Clip 9

        context.beginPath();
        context.moveTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.67, 2.35, 36.67, 4.07);
        context.lineTo(36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.67, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(41.49, 132.2);
        context.lineTo(110.65, 132.2);
        context.bezierCurveTo(112.64, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(115.47, 129.41);
        context.lineTo(150.05, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.05, 63.96);
        context.lineTo(150.05, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.64, 1.28, 110.65, 1.28);
        context.lineTo(110.65, 1.28);
        context.lineTo(41.49, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 191 Drawing
        context.beginPath();
        context.moveTo(32.32, 37.44);
        context.bezierCurveTo(45.09, 59.6, 42.64, 79.36, 42.64, 79.36);
        context.lineTo(76.07, 79.35);
        context.lineTo(42.64, 79.36);
        context.lineTo(109.5, 79.35);
        context.lineTo(76.07, 79.35);
        context.lineTo(109.5, 79.35);
        context.bezierCurveTo(109.5, 79.35, 107.05, 59.59, 119.82, 37.43);
        context.lineTo(76.17, 37.43);
        context.lineTo(75.96, 37.43);
        context.lineTo(32.32, 37.44);
        context.closePath();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 12
        context.save();

        //// Clip Clip 10

        context.beginPath();
        context.moveTo(29.56, 89.21);
        context.lineTo(42.67, 79.32);
        context.bezierCurveTo(42.67, 79.32, 45.13, 59.56, 32.35, 37.4);
        context.lineTo(32.35, 37.4);
        context.lineTo(29.56, 89.21);
        context.closePath();
        context.clip();



        //// Bezier 193 Drawing
        context.beginPath();
        context.moveTo(-77.96, 72.89);
        context.lineTo(-8.41, 176.86);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 194 Drawing
        context.beginPath();
        context.moveTo(-75.75, 69.58);
        context.lineTo(-6.19, 173.55);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 195 Drawing
        context.beginPath();
        context.moveTo(-73.53, 66.27);
        context.lineTo(-3.98, 170.23);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 196 Drawing
        context.beginPath();
        context.moveTo(-71.31, 62.95);
        context.lineTo(-1.76, 166.92);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 197 Drawing
        context.beginPath();
        context.moveTo(-69.1, 59.64);
        context.lineTo(0.46, 163.61);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 198 Drawing
        context.beginPath();
        context.moveTo(-66.88, 56.33);
        context.lineTo(2.67, 160.29);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 199 Drawing
        context.beginPath();
        context.moveTo(-64.66, 53.01);
        context.lineTo(4.89, 156.98);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 200 Drawing
        context.beginPath();
        context.moveTo(-62.45, 49.7);
        context.lineTo(7.11, 153.67);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 201 Drawing
        context.beginPath();
        context.moveTo(-60.23, 46.39);
        context.lineTo(9.32, 150.35);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 202 Drawing
        context.beginPath();
        context.moveTo(-58.01, 43.07);
        context.lineTo(11.54, 147.04);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 203 Drawing
        context.beginPath();
        context.moveTo(-55.8, 39.76);
        context.lineTo(13.76, 143.72);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 204 Drawing
        context.beginPath();
        context.moveTo(-53.58, 36.44);
        context.lineTo(15.97, 140.41);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 205 Drawing
        context.beginPath();
        context.moveTo(-51.36, 33.13);
        context.lineTo(18.19, 137.1);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 206 Drawing
        context.beginPath();
        context.moveTo(-49.15, 29.82);
        context.lineTo(20.41, 133.78);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 207 Drawing
        context.beginPath();
        context.moveTo(-46.93, 26.5);
        context.lineTo(22.63, 130.47);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 208 Drawing
        context.beginPath();
        context.moveTo(-44.71, 23.19);
        context.lineTo(24.84, 127.16);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 209 Drawing
        context.beginPath();
        context.moveTo(-42.5, 19.88);
        context.lineTo(27.06, 123.84);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 210 Drawing
        context.beginPath();
        context.moveTo(-40.28, 16.56);
        context.lineTo(29.28, 120.53);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 211 Drawing
        context.beginPath();
        context.moveTo(-38.06, 13.25);
        context.lineTo(31.49, 117.22);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 212 Drawing
        context.beginPath();
        context.moveTo(-35.84, 9.94);
        context.lineTo(33.71, 113.9);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 213 Drawing
        context.beginPath();
        context.moveTo(-33.63, 6.62);
        context.lineTo(35.93, 110.59);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 214 Drawing
        context.beginPath();
        context.moveTo(-31.41, 3.31);
        context.lineTo(38.14, 107.27);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 215 Drawing
        context.beginPath();
        context.moveTo(-29.19, -0.01);
        context.lineTo(40.36, 103.96);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 216 Drawing
        context.beginPath();
        context.moveTo(-26.98, -3.32);
        context.lineTo(42.58, 100.65);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 217 Drawing
        context.beginPath();
        context.moveTo(-24.76, -6.63);
        context.lineTo(44.79, 97.33);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 218 Drawing
        context.beginPath();
        context.moveTo(-22.54, -9.95);
        context.lineTo(47.01, 94.02);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 219 Drawing
        context.beginPath();
        context.moveTo(-20.33, -13.26);
        context.lineTo(49.23, 90.71);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 220 Drawing
        context.beginPath();
        context.moveTo(-18.11, -16.57);
        context.lineTo(51.44, 87.39);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 221 Drawing
        context.beginPath();
        context.moveTo(-15.89, -19.89);
        context.lineTo(53.66, 84.08);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 222 Drawing
        context.beginPath();
        context.moveTo(-13.68, -23.2);
        context.lineTo(55.88, 80.77);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 223 Drawing
        context.beginPath();
        context.moveTo(-11.46, -26.51);
        context.lineTo(58.09, 77.45);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 224 Drawing
        context.beginPath();
        context.moveTo(-9.24, -29.83);
        context.lineTo(60.31, 74.14);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 225 Drawing
        context.beginPath();
        context.moveTo(-7.03, -33.14);
        context.lineTo(62.53, 70.82);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 226 Drawing
        context.beginPath();
        context.moveTo(-4.81, -36.46);
        context.lineTo(64.75, 67.51);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 227 Drawing
        context.beginPath();
        context.moveTo(-2.59, -39.77);
        context.lineTo(66.96, 64.2);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 228 Drawing
        context.beginPath();
        context.moveTo(-0.38, -43.08);
        context.lineTo(69.18, 60.88);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 229 Drawing
        context.beginPath();
        context.moveTo(-5.19, 184.06);
        context.lineTo(87.29, 45.82);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 230 Drawing
        context.beginPath();
        context.moveTo(-7.41, 180.75);
        context.lineTo(85.08, 42.5);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 231 Drawing
        context.beginPath();
        context.moveTo(-9.63, 177.43);
        context.lineTo(82.86, 39.19);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 232 Drawing
        context.beginPath();
        context.moveTo(-11.84, 174.12);
        context.lineTo(80.64, 35.87);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 233 Drawing
        context.beginPath();
        context.moveTo(-14.06, 170.8);
        context.lineTo(78.43, 32.56);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 234 Drawing
        context.beginPath();
        context.moveTo(-16.28, 167.49);
        context.lineTo(76.21, 29.25);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 235 Drawing
        context.beginPath();
        context.moveTo(-18.49, 164.18);
        context.lineTo(73.99, 25.93);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 236 Drawing
        context.beginPath();
        context.moveTo(-20.71, 160.86);
        context.lineTo(71.78, 22.62);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 237 Drawing
        context.beginPath();
        context.moveTo(-22.93, 157.55);
        context.lineTo(69.56, 19.31);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 238 Drawing
        context.beginPath();
        context.moveTo(-25.14, 154.24);
        context.lineTo(67.34, 15.99);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 239 Drawing
        context.beginPath();
        context.moveTo(-27.36, 150.92);
        context.lineTo(65.13, 12.68);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 240 Drawing
        context.beginPath();
        context.moveTo(-29.58, 147.61);
        context.lineTo(62.91, 9.37);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 241 Drawing
        context.beginPath();
        context.moveTo(-31.79, 144.29);
        context.lineTo(60.69, 6.05);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 242 Drawing
        context.beginPath();
        context.moveTo(-34.01, 140.98);
        context.lineTo(58.48, 2.74);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 243 Drawing
        context.beginPath();
        context.moveTo(-36.23, 137.67);
        context.lineTo(56.26, -0.58);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 244 Drawing
        context.beginPath();
        context.moveTo(-38.44, 134.35);
        context.lineTo(54.04, -3.89);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 245 Drawing
        context.beginPath();
        context.moveTo(-40.66, 131.04);
        context.lineTo(51.82, -7.2);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 246 Drawing
        context.beginPath();
        context.moveTo(-42.88, 127.73);
        context.lineTo(49.61, -10.52);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 247 Drawing
        context.beginPath();
        context.moveTo(-45.09, 124.41);
        context.lineTo(47.39, -13.83);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 248 Drawing
        context.beginPath();
        context.moveTo(-47.31, 121.1);
        context.lineTo(45.17, -17.14);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 249 Drawing
        context.beginPath();
        context.moveTo(-49.53, 117.79);
        context.lineTo(42.96, -20.46);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 250 Drawing
        context.beginPath();
        context.moveTo(-51.75, 114.47);
        context.lineTo(40.74, -23.77);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 251 Drawing
        context.beginPath();
        context.moveTo(-53.96, 111.16);
        context.lineTo(38.52, -27.08);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 252 Drawing
        context.beginPath();
        context.moveTo(-56.18, 107.84);
        context.lineTo(36.31, -30.4);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 253 Drawing
        context.beginPath();
        context.moveTo(-58.4, 104.53);
        context.lineTo(34.09, -33.71);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 254 Drawing
        context.beginPath();
        context.moveTo(-60.61, 101.22);
        context.lineTo(31.87, -37.03);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 255 Drawing
        context.beginPath();
        context.moveTo(-62.83, 97.9);
        context.lineTo(29.66, -40.34);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 256 Drawing
        context.beginPath();
        context.moveTo(-65.05, 94.59);
        context.lineTo(27.44, -43.65);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 257 Drawing
        context.beginPath();
        context.moveTo(-67.26, 91.28);
        context.lineTo(25.22, -46.97);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 258 Drawing
        context.beginPath();
        context.moveTo(-69.48, 87.96);
        context.lineTo(23.01, -50.28);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 259 Drawing
        context.beginPath();
        context.moveTo(-71.7, 84.65);
        context.lineTo(20.79, -53.59);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 260 Drawing
        context.beginPath();
        context.moveTo(-73.91, 81.34);
        context.lineTo(18.57, -56.91);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 261 Drawing
        context.beginPath();
        context.moveTo(-76.13, 78.02);
        context.lineTo(16.36, -60.22);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 262 Drawing
        context.beginPath();
        context.moveTo(-78.35, 74.71);
        context.lineTo(14.14, -63.53);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 263 Drawing
        context.beginPath();
        context.moveTo(-80.56, 71.39);
        context.lineTo(11.92, -66.85);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 264 Drawing
        context.beginPath();
        context.moveTo(-82.78, 68.08);
        context.lineTo(9.7, -70.16);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 265 Drawing
        context.beginPath();
        context.moveTo(1.84, -46.4);
        context.lineTo(71.4, 57.57);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 266 Drawing
        context.beginPath();
        context.moveTo(4.06, -49.71);
        context.lineTo(73.61, 54.26);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 267 Drawing
        context.beginPath();
        context.moveTo(6.27, -53.02);
        context.lineTo(75.83, 50.94);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 268 Drawing
        context.beginPath();
        context.moveTo(8.49, -56.34);
        context.lineTo(78.05, 47.63);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 269 Drawing
        context.beginPath();
        context.moveTo(10.71, -59.65);
        context.lineTo(80.26, 44.32);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 270 Drawing
        context.beginPath();
        context.moveTo(12.93, -62.96);
        context.lineTo(82.48, 41);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 13
        context.save();

        //// Clip Clip 11

        context.beginPath();
        context.moveTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.67, 2.35, 36.67, 4.07);
        context.lineTo(36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.67, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(41.49, 132.2);
        context.lineTo(110.65, 132.2);
        context.bezierCurveTo(112.64, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(115.47, 129.41);
        context.lineTo(150.05, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.05, 63.96);
        context.lineTo(150.05, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.64, 1.28, 110.65, 1.28);
        context.lineTo(110.65, 1.28);
        context.lineTo(41.49, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 272 Drawing
        context.beginPath();
        context.moveTo(42.67, 79.32);
        context.lineTo(29.56, 89.21);
        context.lineTo(32.35, 37.4);
        context.bezierCurveTo(45.13, 59.56, 42.67, 79.32, 42.67, 79.32);
        context.closePath();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 14
        context.save();

        //// Clip Clip 12

        context.beginPath();
        context.moveTo(109.54, 79.32);
        context.lineTo(122.65, 89.2);
        context.lineTo(119.85, 37.39);
        context.bezierCurveTo(107.08, 59.55, 109.54, 79.32, 109.54, 79.32);
        context.closePath();
        context.clip();



        //// Bezier 274 Drawing
        context.beginPath();
        context.moveTo(45.21, 39.37);
        context.lineTo(114.77, 143.34);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 275 Drawing
        context.beginPath();
        context.moveTo(47.43, 36.06);
        context.lineTo(116.99, 140.02);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 276 Drawing
        context.beginPath();
        context.moveTo(49.65, 32.74);
        context.lineTo(119.2, 136.71);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 277 Drawing
        context.beginPath();
        context.moveTo(51.87, 29.43);
        context.lineTo(121.42, 133.4);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 278 Drawing
        context.beginPath();
        context.moveTo(54.08, 26.12);
        context.lineTo(123.64, 130.08);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 279 Drawing
        context.beginPath();
        context.moveTo(56.3, 22.8);
        context.lineTo(125.85, 126.77);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 280 Drawing
        context.beginPath();
        context.moveTo(58.52, 19.49);
        context.lineTo(128.07, 123.46);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 281 Drawing
        context.beginPath();
        context.moveTo(60.73, 16.17);
        context.lineTo(130.29, 120.14);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 282 Drawing
        context.beginPath();
        context.moveTo(62.95, 12.86);
        context.lineTo(132.5, 116.83);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 283 Drawing
        context.beginPath();
        context.moveTo(65.17, 9.55);
        context.lineTo(134.72, 113.51);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 284 Drawing
        context.beginPath();
        context.moveTo(67.38, 6.23);
        context.lineTo(136.94, 110.2);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 285 Drawing
        context.beginPath();
        context.moveTo(69.6, 2.92);
        context.lineTo(139.15, 106.89);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 286 Drawing
        context.beginPath();
        context.moveTo(71.82, -0.39);
        context.lineTo(141.37, 103.57);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 287 Drawing
        context.beginPath();
        context.moveTo(74.03, -3.71);
        context.lineTo(143.59, 100.26);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 288 Drawing
        context.beginPath();
        context.moveTo(76.25, -7.02);
        context.lineTo(145.8, 96.95);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 289 Drawing
        context.beginPath();
        context.moveTo(78.47, -10.33);
        context.lineTo(148.02, 93.63);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 290 Drawing
        context.beginPath();
        context.moveTo(80.68, -13.65);
        context.lineTo(150.24, 90.32);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 291 Drawing
        context.beginPath();
        context.moveTo(82.9, -16.96);
        context.lineTo(152.46, 87.01);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 292 Drawing
        context.beginPath();
        context.moveTo(85.12, -20.28);
        context.lineTo(154.67, 83.69);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 293 Drawing
        context.beginPath();
        context.moveTo(87.33, -23.59);
        context.lineTo(156.89, 80.38);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 294 Drawing
        context.beginPath();
        context.moveTo(89.55, -26.9);
        context.lineTo(159.11, 77.06);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 295 Drawing
        context.beginPath();
        context.moveTo(91.77, -30.22);
        context.lineTo(161.32, 73.75);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 296 Drawing
        context.beginPath();
        context.moveTo(93.98, -33.53);
        context.lineTo(163.54, 70.44);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 297 Drawing
        context.beginPath();
        context.moveTo(96.2, -36.84);
        context.lineTo(165.76, 67.12);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 298 Drawing
        context.beginPath();
        context.moveTo(98.42, -40.16);
        context.lineTo(167.97, 63.81);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 299 Drawing
        context.beginPath();
        context.moveTo(100.64, -43.47);
        context.lineTo(170.19, 60.5);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 300 Drawing
        context.beginPath();
        context.moveTo(102.85, -46.79);
        context.lineTo(172.41, 57.18);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 301 Drawing
        context.beginPath();
        context.moveTo(105.07, -50.1);
        context.lineTo(174.62, 53.87);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 302 Drawing
        context.beginPath();
        context.moveTo(107.29, -53.41);
        context.lineTo(176.84, 50.55);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 303 Drawing
        context.beginPath();
        context.moveTo(109.5, -56.73);
        context.lineTo(179.06, 47.24);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 304 Drawing
        context.beginPath();
        context.moveTo(111.72, -60.04);
        context.lineTo(181.27, 43.93);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 305 Drawing
        context.beginPath();
        context.moveTo(113.94, -63.35);
        context.lineTo(183.49, 40.61);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 306 Drawing
        context.beginPath();
        context.moveTo(116.15, -66.67);
        context.lineTo(185.71, 37.3);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 307 Drawing
        context.beginPath();
        context.moveTo(118.37, -69.98);
        context.lineTo(187.92, 33.99);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 308 Drawing
        context.beginPath();
        context.moveTo(120.59, -73.29);
        context.lineTo(190.14, 30.67);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 309 Drawing
        context.beginPath();
        context.moveTo(122.8, -76.61);
        context.lineTo(192.36, 27.36);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 310 Drawing
        context.beginPath();
        context.moveTo(117.99, 150.53);
        context.lineTo(210.47, 12.29);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 311 Drawing
        context.beginPath();
        context.moveTo(115.77, 147.22);
        context.lineTo(208.26, 8.98);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 312 Drawing
        context.beginPath();
        context.moveTo(113.55, 143.91);
        context.lineTo(206.04, 5.66);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 313 Drawing
        context.beginPath();
        context.moveTo(111.34, 140.59);
        context.lineTo(203.82, 2.35);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 314 Drawing
        context.beginPath();
        context.moveTo(109.12, 137.28);
        context.lineTo(201.61, -0.96);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 315 Drawing
        context.beginPath();
        context.moveTo(106.9, 133.97);
        context.lineTo(199.39, -4.28);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 316 Drawing
        context.beginPath();
        context.moveTo(104.69, 130.65);
        context.lineTo(197.17, -7.59);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 317 Drawing
        context.beginPath();
        context.moveTo(102.47, 127.34);
        context.lineTo(194.96, -10.91);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 318 Drawing
        context.beginPath();
        context.moveTo(100.25, 124.03);
        context.lineTo(192.74, -14.22);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 319 Drawing
        context.beginPath();
        context.moveTo(98.04, 120.71);
        context.lineTo(190.52, -17.53);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 320 Drawing
        context.beginPath();
        context.moveTo(95.82, 117.4);
        context.lineTo(188.31, -20.85);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 321 Drawing
        context.beginPath();
        context.moveTo(93.6, 114.08);
        context.lineTo(186.09, -24.16);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 322 Drawing
        context.beginPath();
        context.moveTo(91.39, 110.77);
        context.lineTo(183.87, -27.47);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 323 Drawing
        context.beginPath();
        context.moveTo(89.17, 107.46);
        context.lineTo(181.65, -30.79);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 324 Drawing
        context.beginPath();
        context.moveTo(86.95, 104.14);
        context.lineTo(179.44, -34.1);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 325 Drawing
        context.beginPath();
        context.moveTo(84.74, 100.83);
        context.lineTo(177.22, -37.41);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 326 Drawing
        context.beginPath();
        context.moveTo(82.52, 97.52);
        context.lineTo(175, -40.73);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 327 Drawing
        context.beginPath();
        context.moveTo(80.3, 94.2);
        context.lineTo(172.79, -44.04);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 328 Drawing
        context.beginPath();
        context.moveTo(78.08, 90.89);
        context.lineTo(170.57, -47.36);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 329 Drawing
        context.beginPath();
        context.moveTo(75.87, 87.58);
        context.lineTo(168.35, -50.67);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 330 Drawing
        context.beginPath();
        context.moveTo(73.65, 84.26);
        context.lineTo(166.14, -53.98);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 331 Drawing
        context.beginPath();
        context.moveTo(71.43, 80.95);
        context.lineTo(163.92, -57.3);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 332 Drawing
        context.beginPath();
        context.moveTo(69.22, 77.63);
        context.lineTo(161.7, -60.61);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 333 Drawing
        context.beginPath();
        context.moveTo(67, 74.32);
        context.lineTo(159.49, -63.92);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 334 Drawing
        context.beginPath();
        context.moveTo(64.78, 71.01);
        context.lineTo(157.27, -67.24);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 335 Drawing
        context.beginPath();
        context.moveTo(62.57, 67.69);
        context.lineTo(155.05, -70.55);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 336 Drawing
        context.beginPath();
        context.moveTo(60.35, 64.38);
        context.lineTo(152.84, -73.86);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 337 Drawing
        context.beginPath();
        context.moveTo(58.13, 61.07);
        context.lineTo(150.62, -77.18);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 338 Drawing
        context.beginPath();
        context.moveTo(55.92, 57.75);
        context.lineTo(148.4, -80.49);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 339 Drawing
        context.beginPath();
        context.moveTo(53.7, 54.44);
        context.lineTo(146.19, -83.81);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 340 Drawing
        context.beginPath();
        context.moveTo(51.48, 51.13);
        context.lineTo(143.97, -87.12);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 341 Drawing
        context.beginPath();
        context.moveTo(49.27, 47.81);
        context.lineTo(141.75, -90.43);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 342 Drawing
        context.beginPath();
        context.moveTo(47.05, 44.5);
        context.lineTo(139.53, -93.75);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 343 Drawing
        context.beginPath();
        context.moveTo(44.83, 41.18);
        context.lineTo(137.32, -97.06);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 344 Drawing
        context.beginPath();
        context.moveTo(42.62, 37.87);
        context.lineTo(135.1, -100.37);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 345 Drawing
        context.beginPath();
        context.moveTo(40.4, 34.56);
        context.lineTo(132.88, -103.69);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 346 Drawing
        context.beginPath();
        context.moveTo(125.02, -79.92);
        context.lineTo(194.58, 24.05);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 347 Drawing
        context.beginPath();
        context.moveTo(127.24, -83.24);
        context.lineTo(196.79, 20.73);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 348 Drawing
        context.beginPath();
        context.moveTo(129.45, -86.55);
        context.lineTo(199.01, 17.42);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 349 Drawing
        context.beginPath();
        context.moveTo(131.67, -89.86);
        context.lineTo(201.23, 14.1);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 350 Drawing
        context.beginPath();
        context.moveTo(133.89, -93.18);
        context.lineTo(203.44, 10.79);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 351 Drawing
        context.beginPath();
        context.moveTo(136.1, -96.49);
        context.lineTo(205.66, 7.48);
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 15
        context.save();

        //// Clip Clip 13

        context.beginPath();
        context.moveTo(41.49, 1.28);
        context.bezierCurveTo(39.5, 1.28, 37.67, 2.35, 36.67, 4.07);
        context.lineTo(36.67, 4.07);
        context.lineTo(2.09, 63.96);
        context.bezierCurveTo(1.1, 65.68, 1.1, 67.8, 2.09, 69.53);
        context.lineTo(2.09, 69.53);
        context.lineTo(36.67, 129.41);
        context.bezierCurveTo(37.67, 131.14, 39.5, 132.2, 41.49, 132.2);
        context.lineTo(41.49, 132.2);
        context.lineTo(110.65, 132.2);
        context.bezierCurveTo(112.64, 132.2, 114.47, 131.14, 115.47, 129.41);
        context.lineTo(115.47, 129.41);
        context.lineTo(150.05, 69.53);
        context.bezierCurveTo(151.04, 67.8, 151.04, 65.68, 150.05, 63.96);
        context.lineTo(150.05, 63.96);
        context.lineTo(115.47, 4.07);
        context.bezierCurveTo(114.47, 2.35, 112.64, 1.28, 110.65, 1.28);
        context.lineTo(110.65, 1.28);
        context.lineTo(41.49, 1.28);
        context.closePath();
        context.clip();



        //// Bezier 353 Drawing
        context.beginPath();
        context.moveTo(109.54, 79.31);
        context.bezierCurveTo(109.54, 79.31, 107.08, 59.55, 119.85, 37.39);
        context.lineTo(122.65, 89.2);
        context.lineTo(109.54, 79.31);
        context.closePath();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 0.5;
        context.lineJoin = 'round';
        context.stroke();


        context.restore();



        //// Group 16
        context.save();

        //// Clip Clip 14

        oval(context, 109.6, 77.59, 17.6, 17.6);
        context.clip();



        //// Oval 65 Drawing
        oval(context, 109.6, 77.64, 17.6, 17.6);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 356 Drawing
        context.beginPath();
        context.moveTo(116.1, 82.54);
        context.lineTo(119.59, 81.95);
        context.lineTo(121.34, 85.47);
        context.lineTo(118.51, 87.93);
        context.lineTo(115.47, 86.18);
        context.lineTo(116.1, 82.54);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 357 Drawing
        context.beginPath();
        context.moveTo(127.07, 84.89);
        context.lineTo(125.79, 83.95);
        context.lineTo(124.4, 86.24);
        context.lineTo(124.81, 89.94);
        context.lineTo(126.56, 89.74);
        context.lineTo(128.47, 88.19);
        context.lineTo(129.93, 84.61);
        context.lineTo(127.07, 84.89);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 358 Drawing
        context.beginPath();
        context.moveTo(121.25, 94.74);
        context.bezierCurveTo(121.79, 94.4, 122.22, 92.18, 122.22, 92.18);
        context.lineTo(119.08, 91.32);
        context.lineTo(116.44, 93.18);
        context.bezierCurveTo(116.44, 93.18, 117.48, 95.1, 117.77, 95.17);
        context.lineTo(119.01, 95.79);
        context.lineTo(121.25, 94.74);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 359 Drawing
        context.beginPath();
        context.moveTo(111.6, 91.95);
        context.lineTo(113.16, 91.32);
        context.lineTo(112.84, 87.7);
        context.lineTo(110.75, 86.09);
        context.lineTo(109.67, 87.38);
        context.lineTo(108.45, 90.08);
        context.lineTo(111.6, 91.95);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 360 Drawing
        context.beginPath();
        context.moveTo(115.47, 78.08);
        context.lineTo(114.34, 80.68);
        context.bezierCurveTo(113.73, 80.81, 111.17, 82.45, 111.17, 82.45);
        context.lineTo(110.52, 82.4);
        context.lineTo(110.23, 79.86);
        context.lineTo(112.68, 78.41);
        context.lineTo(115.47, 78.08);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 361 Drawing
        context.beginPath();
        context.moveTo(124.58, 80.12);
        context.lineTo(124.03, 80.43);
        context.lineTo(120.94, 79.89);
        context.lineTo(119.23, 77.65);
        context.lineTo(121.9, 76.87);
        context.lineTo(124.19, 78.76);
        context.lineTo(124.58, 80.12);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 362 Drawing
        context.beginPath();
        context.moveTo(114.14, 80.45);
        context.lineTo(116.31, 82.79);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 363 Drawing
        context.beginPath();
        context.moveTo(110.72, 86.41);
        context.lineTo(111.24, 81.99);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 364 Drawing
        context.beginPath();
        context.moveTo(112.72, 87.79);
        context.lineTo(115.68, 86.08);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 365 Drawing
        context.beginPath();
        context.moveTo(120.75, 85.31);
        context.lineTo(124.67, 86.32);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 366 Drawing
        context.beginPath();
        context.moveTo(121.24, 79.42);
        context.lineTo(119.38, 82.3);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 367 Drawing
        context.beginPath();
        context.moveTo(123.82, 80);
        context.lineTo(125.99, 84.35);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 368 Drawing
        context.beginPath();
        context.moveTo(121.92, 92.44);
        context.lineTo(125.35, 89.46);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 369 Drawing
        context.beginPath();
        context.moveTo(118.47, 87.65);
        context.lineTo(119.14, 91.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Bezier 370 Drawing
        context.beginPath();
        context.moveTo(116.71, 93.33);
        context.lineTo(112.9, 91.15);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        context.restore();



        //// Oval 67 Drawing
        oval(context, 109.6, 77.64, 17.6, 17.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();




        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBalloonsBackground(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 152, 134), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 152, resizedFrame.h / 134);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor11 = 'rgba(255, 147, 3, 1)';

        //// Group 55
        context.save();

        //// Clip Bezier 32

        context.beginPath();
        context.moveTo(110.23, 1.62);
        context.lineTo(41.08, 1.62);
        context.bezierCurveTo(39.08, 1.62, 37.25, 2.68, 36.25, 4.41);
        context.lineTo(1.67, 64.29);
        context.bezierCurveTo(0.68, 66.02, 0.68, 68.14, 1.67, 69.86);
        context.lineTo(36.25, 129.75);
        context.bezierCurveTo(37.25, 131.47, 39.08, 132.54, 41.08, 132.54);
        context.lineTo(110.23, 132.54);
        context.bezierCurveTo(112.22, 132.54, 114.06, 131.47, 115.05, 129.75);
        context.lineTo(149.63, 69.86);
        context.bezierCurveTo(150.62, 68.14, 150.62, 66.02, 149.63, 64.29);
        context.lineTo(115.05, 4.41);
        context.bezierCurveTo(114.06, 2.68, 112.22, 1.62, 110.23, 1.62);
        context.closePath();
        context.clip();



        //// Bezier 651 Drawing
        context.beginPath();
        context.moveTo(38.67, -4.66);
        context.lineTo(-4.12, 67.61);
        context.lineTo(37.34, 137.22);
        context.lineTo(120.25, 136.11);
        context.lineTo(156.61, 66.06);
        context.lineTo(115.37, -3.33);
        context.lineTo(38.67, -4.66);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();


        //// Bezier 653 Drawing
        context.beginPath();
        context.moveTo(49.3, 103.77);
        context.bezierCurveTo(49.3, 103.77, 52.63, 110.57, 48.68, 114.28);
        context.bezierCurveTo(48.52, 114.43, 48.35, 114.58, 48.2, 114.74);
        context.bezierCurveTo(44.16, 119.08, 48.95, 127.44, 48.95, 127.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 654 Drawing
        context.beginPath();
        context.moveTo(60.08, 76.68);
        context.bezierCurveTo(58.16, 72.14, 53.83, 69.47, 49.38, 69.47);
        context.bezierCurveTo(44.03, 69.47, 38.78, 72.99, 37.49, 79.48);
        context.bezierCurveTo(36.81, 82.87, 37.22, 86.29, 38.15, 89.62);
        context.bezierCurveTo(40.01, 96.25, 42.83, 101.37, 48.07, 102.09);
        context.lineTo(47.29, 104);
        context.lineTo(51.47, 104);
        context.lineTo(50.69, 102.09);
        context.bezierCurveTo(55.89, 101.39, 58.72, 96.41, 60.72, 89.81);
        context.bezierCurveTo(62.04, 85.44, 61.85, 80.88, 60.08, 76.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 655 Drawing
        context.beginPath();
        context.moveTo(60.08, 76.68);
        context.bezierCurveTo(58.16, 72.14, 53.83, 69.47, 49.38, 69.47);
        context.bezierCurveTo(44.03, 69.47, 38.78, 72.99, 37.49, 79.48);
        context.bezierCurveTo(36.81, 82.87, 37.22, 86.29, 38.15, 89.62);
        context.bezierCurveTo(40.01, 96.25, 42.83, 101.37, 48.07, 102.09);
        context.lineTo(47.29, 104);
        context.lineTo(51.47, 104);
        context.lineTo(50.69, 102.09);
        context.bezierCurveTo(55.89, 101.39, 58.72, 96.41, 60.72, 89.81);
        context.bezierCurveTo(62.04, 85.44, 61.85, 80.88, 60.08, 76.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 656 Drawing
        context.beginPath();
        context.moveTo(79.3, 72.77);
        context.bezierCurveTo(79.3, 72.77, 82.63, 79.57, 78.68, 83.28);
        context.bezierCurveTo(78.52, 83.43, 78.35, 83.58, 78.2, 83.74);
        context.bezierCurveTo(74.16, 88.08, 78.95, 96.44, 78.95, 96.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 657 Drawing
        context.beginPath();
        context.moveTo(90.08, 45.68);
        context.bezierCurveTo(88.16, 41.14, 83.83, 38.47, 79.38, 38.47);
        context.bezierCurveTo(74.03, 38.47, 68.78, 41.99, 67.49, 48.48);
        context.bezierCurveTo(66.81, 51.87, 67.22, 55.29, 68.15, 58.62);
        context.bezierCurveTo(70.01, 65.25, 72.83, 70.37, 78.07, 71.09);
        context.lineTo(77.29, 73);
        context.lineTo(81.47, 73);
        context.lineTo(80.69, 71.09);
        context.bezierCurveTo(85.89, 70.39, 88.72, 65.41, 90.72, 58.81);
        context.bezierCurveTo(92.04, 54.44, 91.85, 49.88, 90.08, 45.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 658 Drawing
        context.beginPath();
        context.moveTo(90.08, 45.68);
        context.bezierCurveTo(88.16, 41.14, 83.83, 38.47, 79.38, 38.47);
        context.bezierCurveTo(74.03, 38.47, 68.78, 41.99, 67.49, 48.48);
        context.bezierCurveTo(66.81, 51.87, 67.22, 55.29, 68.15, 58.62);
        context.bezierCurveTo(70.01, 65.25, 72.83, 70.37, 78.07, 71.09);
        context.lineTo(77.29, 73);
        context.lineTo(81.47, 73);
        context.lineTo(80.69, 71.09);
        context.bezierCurveTo(85.89, 70.39, 88.72, 65.41, 90.72, 58.81);
        context.bezierCurveTo(92.04, 54.44, 91.85, 49.88, 90.08, 45.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 659 Drawing
        context.beginPath();
        context.moveTo(49.3, 33.77);
        context.bezierCurveTo(49.3, 33.77, 52.63, 40.57, 48.68, 44.28);
        context.bezierCurveTo(48.52, 44.43, 48.35, 44.58, 48.2, 44.74);
        context.bezierCurveTo(44.16, 49.08, 48.95, 57.44, 48.95, 57.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 660 Drawing
        context.beginPath();
        context.moveTo(60.08, 6.68);
        context.bezierCurveTo(58.16, 2.14, 53.83, -0.53, 49.38, -0.53);
        context.bezierCurveTo(44.03, -0.53, 38.78, 2.99, 37.49, 9.48);
        context.bezierCurveTo(36.81, 12.87, 37.22, 16.29, 38.15, 19.62);
        context.bezierCurveTo(40.01, 26.25, 42.83, 31.37, 48.07, 32.09);
        context.lineTo(47.29, 34);
        context.lineTo(51.47, 34);
        context.lineTo(50.69, 32.09);
        context.bezierCurveTo(55.89, 31.39, 58.72, 26.41, 60.72, 19.81);
        context.bezierCurveTo(62.04, 15.44, 61.85, 10.88, 60.08, 6.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 661 Drawing
        context.beginPath();
        context.moveTo(60.08, 6.68);
        context.bezierCurveTo(58.16, 2.14, 53.83, -0.53, 49.38, -0.53);
        context.bezierCurveTo(44.03, -0.53, 38.78, 2.99, 37.49, 9.48);
        context.bezierCurveTo(36.81, 12.87, 37.22, 16.29, 38.15, 19.62);
        context.bezierCurveTo(40.01, 26.25, 42.83, 31.37, 48.07, 32.09);
        context.lineTo(47.29, 34);
        context.lineTo(51.47, 34);
        context.lineTo(50.69, 32.09);
        context.bezierCurveTo(55.89, 31.39, 58.72, 26.41, 60.72, 19.81);
        context.bezierCurveTo(62.04, 15.44, 61.85, 10.88, 60.08, 6.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 662 Drawing
        context.beginPath();
        context.moveTo(109.3, 33.77);
        context.bezierCurveTo(109.3, 33.77, 112.63, 40.57, 108.68, 44.28);
        context.bezierCurveTo(108.52, 44.43, 108.35, 44.58, 108.2, 44.74);
        context.bezierCurveTo(104.16, 49.08, 108.95, 57.44, 108.95, 57.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 663 Drawing
        context.beginPath();
        context.moveTo(120.08, 6.68);
        context.bezierCurveTo(118.16, 2.14, 113.83, -0.53, 109.38, -0.53);
        context.bezierCurveTo(104.03, -0.53, 98.78, 2.99, 97.49, 9.48);
        context.bezierCurveTo(96.81, 12.87, 97.22, 16.29, 98.15, 19.62);
        context.bezierCurveTo(100.01, 26.25, 102.83, 31.37, 108.07, 32.09);
        context.lineTo(107.29, 34);
        context.lineTo(111.47, 34);
        context.lineTo(110.69, 32.09);
        context.bezierCurveTo(115.89, 31.39, 118.72, 26.41, 120.72, 19.81);
        context.bezierCurveTo(122.04, 15.44, 121.85, 10.88, 120.08, 6.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 664 Drawing
        context.beginPath();
        context.moveTo(120.08, 6.68);
        context.bezierCurveTo(118.16, 2.14, 113.83, -0.53, 109.38, -0.53);
        context.bezierCurveTo(104.03, -0.53, 98.78, 2.99, 97.49, 9.48);
        context.bezierCurveTo(96.81, 12.87, 97.22, 16.29, 98.15, 19.62);
        context.bezierCurveTo(100.01, 26.25, 102.83, 31.37, 108.07, 32.09);
        context.lineTo(107.29, 34);
        context.lineTo(111.47, 34);
        context.lineTo(110.69, 32.09);
        context.bezierCurveTo(115.89, 31.39, 118.72, 26.41, 120.72, 19.81);
        context.bezierCurveTo(122.04, 15.44, 121.85, 10.88, 120.08, 6.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 665 Drawing
        context.beginPath();
        context.moveTo(109.3, 103.77);
        context.bezierCurveTo(109.3, 103.77, 112.63, 110.57, 108.68, 114.28);
        context.bezierCurveTo(108.52, 114.43, 108.35, 114.58, 108.2, 114.74);
        context.bezierCurveTo(104.16, 119.08, 108.95, 127.44, 108.95, 127.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 666 Drawing
        context.beginPath();
        context.moveTo(120.08, 76.68);
        context.bezierCurveTo(118.16, 72.14, 113.83, 69.47, 109.38, 69.47);
        context.bezierCurveTo(104.03, 69.47, 98.78, 72.99, 97.49, 79.48);
        context.bezierCurveTo(96.81, 82.87, 97.22, 86.29, 98.15, 89.62);
        context.bezierCurveTo(100.01, 96.25, 102.83, 101.37, 108.07, 102.09);
        context.lineTo(107.29, 104);
        context.lineTo(111.47, 104);
        context.lineTo(110.69, 102.09);
        context.bezierCurveTo(115.89, 101.39, 118.72, 96.41, 120.72, 89.81);
        context.bezierCurveTo(122.04, 85.44, 121.85, 80.88, 120.08, 76.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 667 Drawing
        context.beginPath();
        context.moveTo(120.08, 76.68);
        context.bezierCurveTo(118.16, 72.14, 113.83, 69.47, 109.38, 69.47);
        context.bezierCurveTo(104.03, 69.47, 98.78, 72.99, 97.49, 79.48);
        context.bezierCurveTo(96.81, 82.87, 97.22, 86.29, 98.15, 89.62);
        context.bezierCurveTo(100.01, 96.25, 102.83, 101.37, 108.07, 102.09);
        context.lineTo(107.29, 104);
        context.lineTo(111.47, 104);
        context.lineTo(110.69, 102.09);
        context.bezierCurveTo(115.89, 101.39, 118.72, 96.41, 120.72, 89.81);
        context.bezierCurveTo(122.04, 85.44, 121.85, 80.88, 120.08, 76.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 668 Drawing
        context.beginPath();
        context.moveTo(79.3, 143.77);
        context.bezierCurveTo(79.3, 143.77, 82.63, 150.57, 78.68, 154.28);
        context.bezierCurveTo(78.52, 154.43, 78.35, 154.58, 78.2, 154.74);
        context.bezierCurveTo(74.16, 159.08, 78.95, 167.44, 78.95, 167.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 669 Drawing
        context.beginPath();
        context.moveTo(90.08, 116.68);
        context.bezierCurveTo(88.16, 112.14, 83.83, 109.47, 79.38, 109.47);
        context.bezierCurveTo(74.03, 109.47, 68.78, 112.99, 67.49, 119.48);
        context.bezierCurveTo(66.81, 122.87, 67.22, 126.29, 68.15, 129.62);
        context.bezierCurveTo(70.01, 136.25, 72.83, 141.37, 78.07, 142.09);
        context.lineTo(77.29, 144);
        context.lineTo(81.47, 144);
        context.lineTo(80.69, 142.09);
        context.bezierCurveTo(85.89, 141.39, 88.72, 136.41, 90.72, 129.81);
        context.bezierCurveTo(92.04, 125.44, 91.85, 120.88, 90.08, 116.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Bezier 670 Drawing
        context.beginPath();
        context.moveTo(90.08, 116.68);
        context.bezierCurveTo(88.16, 112.14, 83.83, 109.47, 79.38, 109.47);
        context.bezierCurveTo(74.03, 109.47, 68.78, 112.99, 67.49, 119.48);
        context.bezierCurveTo(66.81, 122.87, 67.22, 126.29, 68.15, 129.62);
        context.bezierCurveTo(70.01, 136.25, 72.83, 141.37, 78.07, 142.09);
        context.lineTo(77.29, 144);
        context.lineTo(81.47, 144);
        context.lineTo(80.69, 142.09);
        context.bezierCurveTo(85.89, 141.39, 88.72, 136.41, 90.72, 129.81);
        context.bezierCurveTo(92.04, 125.44, 91.85, 120.88, 90.08, 116.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 671 Drawing
        context.beginPath();
        context.moveTo(139.3, 72.77);
        context.bezierCurveTo(139.3, 72.77, 142.63, 79.57, 138.68, 83.28);
        context.bezierCurveTo(138.52, 83.43, 138.35, 83.58, 138.2, 83.74);
        context.bezierCurveTo(134.16, 88.08, 138.95, 96.44, 138.95, 96.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 672 Drawing
        context.beginPath();
        context.moveTo(150.08, 45.68);
        context.bezierCurveTo(148.16, 41.14, 143.83, 38.47, 139.38, 38.47);
        context.bezierCurveTo(134.03, 38.47, 128.78, 41.99, 127.49, 48.48);
        context.bezierCurveTo(126.81, 51.87, 127.22, 55.29, 128.15, 58.62);
        context.bezierCurveTo(130.01, 65.25, 132.83, 70.37, 138.07, 71.09);
        context.lineTo(137.29, 73);
        context.lineTo(141.47, 73);
        context.lineTo(140.69, 71.09);
        context.bezierCurveTo(145.89, 70.39, 148.72, 65.41, 150.72, 58.81);
        context.bezierCurveTo(152.04, 54.44, 151.85, 49.88, 150.08, 45.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 673 Drawing
        context.beginPath();
        context.moveTo(150.08, 45.68);
        context.bezierCurveTo(148.16, 41.14, 143.83, 38.47, 139.38, 38.47);
        context.bezierCurveTo(134.03, 38.47, 128.78, 41.99, 127.49, 48.48);
        context.bezierCurveTo(126.81, 51.87, 127.22, 55.29, 128.15, 58.62);
        context.bezierCurveTo(130.01, 65.25, 132.83, 70.37, 138.07, 71.09);
        context.lineTo(137.29, 73);
        context.lineTo(141.47, 73);
        context.lineTo(140.69, 71.09);
        context.bezierCurveTo(145.89, 70.39, 148.72, 65.41, 150.72, 58.81);
        context.bezierCurveTo(152.04, 54.44, 151.85, 49.88, 150.08, 45.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 674 Drawing
        context.beginPath();
        context.moveTo(139.3, 143.77);
        context.bezierCurveTo(139.3, 143.77, 142.63, 150.57, 138.68, 154.28);
        context.bezierCurveTo(138.52, 154.43, 138.35, 154.58, 138.2, 154.74);
        context.bezierCurveTo(134.16, 159.08, 138.95, 167.44, 138.95, 167.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 675 Drawing
        context.beginPath();
        context.moveTo(150.08, 116.68);
        context.bezierCurveTo(148.16, 112.14, 143.83, 109.47, 139.38, 109.47);
        context.bezierCurveTo(134.03, 109.47, 128.78, 112.99, 127.49, 119.48);
        context.bezierCurveTo(126.81, 122.87, 127.22, 126.29, 128.15, 129.62);
        context.bezierCurveTo(130.01, 136.25, 132.83, 141.37, 138.07, 142.09);
        context.lineTo(137.29, 144);
        context.lineTo(141.47, 144);
        context.lineTo(140.69, 142.09);
        context.bezierCurveTo(145.89, 141.39, 148.72, 136.41, 150.72, 129.81);
        context.bezierCurveTo(152.04, 125.44, 151.85, 120.88, 150.08, 116.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 676 Drawing
        context.beginPath();
        context.moveTo(150.08, 116.68);
        context.bezierCurveTo(148.16, 112.14, 143.83, 109.47, 139.38, 109.47);
        context.bezierCurveTo(134.03, 109.47, 128.78, 112.99, 127.49, 119.48);
        context.bezierCurveTo(126.81, 122.87, 127.22, 126.29, 128.15, 129.62);
        context.bezierCurveTo(130.01, 136.25, 132.83, 141.37, 138.07, 142.09);
        context.lineTo(137.29, 144);
        context.lineTo(141.47, 144);
        context.lineTo(140.69, 142.09);
        context.bezierCurveTo(145.89, 141.39, 148.72, 136.41, 150.72, 129.81);
        context.bezierCurveTo(152.04, 125.44, 151.85, 120.88, 150.08, 116.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 677 Drawing
        context.beginPath();
        context.moveTo(19.3, 72.77);
        context.bezierCurveTo(19.3, 72.77, 22.63, 79.57, 18.68, 83.28);
        context.bezierCurveTo(18.52, 83.43, 18.35, 83.58, 18.2, 83.74);
        context.bezierCurveTo(14.16, 88.08, 18.95, 96.44, 18.95, 96.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 678 Drawing
        context.beginPath();
        context.moveTo(30.08, 45.68);
        context.bezierCurveTo(28.16, 41.14, 23.83, 38.47, 19.38, 38.47);
        context.bezierCurveTo(14.03, 38.47, 8.78, 41.99, 7.49, 48.48);
        context.bezierCurveTo(6.81, 51.87, 7.22, 55.29, 8.15, 58.62);
        context.bezierCurveTo(10.01, 65.25, 12.83, 70.37, 18.07, 71.09);
        context.lineTo(17.29, 73);
        context.lineTo(21.47, 73);
        context.lineTo(20.69, 71.09);
        context.bezierCurveTo(25.89, 70.39, 28.72, 65.41, 30.72, 58.81);
        context.bezierCurveTo(32.04, 54.44, 31.85, 49.88, 30.08, 45.68);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 679 Drawing
        context.beginPath();
        context.moveTo(30.08, 45.68);
        context.bezierCurveTo(28.16, 41.14, 23.83, 38.47, 19.38, 38.47);
        context.bezierCurveTo(14.03, 38.47, 8.78, 41.99, 7.49, 48.48);
        context.bezierCurveTo(6.81, 51.87, 7.22, 55.29, 8.15, 58.62);
        context.bezierCurveTo(10.01, 65.25, 12.83, 70.37, 18.07, 71.09);
        context.lineTo(17.29, 73);
        context.lineTo(21.47, 73);
        context.lineTo(20.69, 71.09);
        context.bezierCurveTo(25.89, 70.39, 28.72, 65.41, 30.72, 58.81);
        context.bezierCurveTo(32.04, 54.44, 31.85, 49.88, 30.08, 45.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 680 Drawing
        context.beginPath();
        context.moveTo(19.3, 143.77);
        context.bezierCurveTo(19.3, 143.77, 22.63, 150.57, 18.68, 154.28);
        context.bezierCurveTo(18.52, 154.43, 18.35, 154.58, 18.2, 154.74);
        context.bezierCurveTo(14.16, 159.08, 18.95, 167.44, 18.95, 167.44);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 681 Drawing
        context.beginPath();
        context.moveTo(30.08, 116.68);
        context.bezierCurveTo(28.16, 112.14, 23.83, 109.47, 19.38, 109.47);
        context.bezierCurveTo(14.03, 109.47, 8.78, 112.99, 7.49, 119.48);
        context.bezierCurveTo(6.81, 122.87, 7.22, 126.29, 8.15, 129.62);
        context.bezierCurveTo(10.01, 136.25, 12.83, 141.37, 18.07, 142.09);
        context.lineTo(17.29, 144);
        context.lineTo(21.47, 144);
        context.lineTo(20.69, 142.09);
        context.bezierCurveTo(25.89, 141.39, 28.72, 136.41, 30.72, 129.81);
        context.bezierCurveTo(32.04, 125.44, 31.85, 120.88, 30.08, 116.68);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 682 Drawing
        context.beginPath();
        context.moveTo(30.08, 116.68);
        context.bezierCurveTo(28.16, 112.14, 23.83, 109.47, 19.38, 109.47);
        context.bezierCurveTo(14.03, 109.47, 8.78, 112.99, 7.49, 119.48);
        context.bezierCurveTo(6.81, 122.87, 7.22, 126.29, 8.15, 129.62);
        context.bezierCurveTo(10.01, 136.25, 12.83, 141.37, 18.07, 142.09);
        context.lineTo(17.29, 144);
        context.lineTo(21.47, 144);
        context.lineTo(20.69, 142.09);
        context.bezierCurveTo(25.89, 141.39, 28.72, 136.41, 30.72, 129.81);
        context.bezierCurveTo(32.04, 125.44, 31.85, 120.88, 30.08, 116.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 1062 Drawing
        context.beginPath();
        context.moveTo(110.82, 1.08);
        context.lineTo(41.67, 1.08);
        context.bezierCurveTo(39.68, 1.08, 37.84, 2.14, 36.85, 3.87);
        context.lineTo(2.27, 63.75);
        context.bezierCurveTo(1.27, 65.48, 1.27, 67.6, 2.27, 69.32);
        context.lineTo(36.85, 129.21);
        context.bezierCurveTo(37.84, 130.94, 39.68, 132, 41.67, 132);
        context.lineTo(110.82, 132);
        context.bezierCurveTo(112.81, 132, 114.65, 130.94, 115.64, 129.21);
        context.lineTo(150.22, 69.32);
        context.bezierCurveTo(151.22, 67.6, 151.22, 65.48, 150.22, 63.75);
        context.lineTo(115.64, 3.87);
        context.bezierCurveTo(114.65, 2.14, 112.81, 1.08, 110.82, 1.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair11(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 101, 84), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 101, resizedFrame.h / 84);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1999 Drawing
        context.beginPath();
        context.moveTo(52.47, 73.11);
        context.bezierCurveTo(37.19, 73.08, 24.89, 59.42, 24.89, 43.29);
        context.bezierCurveTo(24.89, 40.67, 25.22, 38.11, 25.83, 35.66);
        context.lineTo(26.04, 35.71);
        context.bezierCurveTo(48.21, 41.15, 57.43, 30.74, 57.43, 30.74);
        context.lineTo(64.06, 22.74);
        context.bezierCurveTo(64.06, 22.74, 65.72, 29.73, 62.29, 38.26);
        context.bezierCurveTo(62.29, 38.26, 71.45, 40.01, 76.22, 32.58);
        context.lineTo(77.43, 30.74);
        context.bezierCurveTo(79.1, 34.58, 80.04, 38.84, 80.04, 43.29);
        context.bezierCurveTo(80.05, 59.42, 67.75, 73.08, 52.47, 73.11);
        context.closePath();
        context.moveTo(84.89, 40.54);
        context.bezierCurveTo(84.89, 40.54, 95.53, 1.63, 50.89, 1.63);
        context.bezierCurveTo(7.08, 1.63, 17.05, 40.54, 17.05, 40.54);
        context.bezierCurveTo(17.05, 40.54, 7.61, 41.92, 3.29, 50.34);
        context.bezierCurveTo(0.04, 56.67, 1.88, 64.63, 8.05, 68.17);
        context.bezierCurveTo(9.75, 69.14, 11.82, 69.84, 14.31, 69.97);
        context.bezierCurveTo(14.31, 69.97, 14.31, 79.28, 22.79, 81.11);
        context.bezierCurveTo(25.09, 81.6, 27.33, 81.81, 29.46, 81.95);
        context.bezierCurveTo(34.05, 77.51, 40.3, 76.42, 43.06, 75.96);
        context.bezierCurveTo(44.78, 79.35, 48.3, 81.68, 52.37, 81.68);
        context.bezierCurveTo(56.44, 81.68, 59.96, 79.35, 61.69, 75.96);
        context.bezierCurveTo(64.39, 76.41, 70.45, 77.46, 75.02, 81.69);
        context.bezierCurveTo(76.21, 81.55, 77.43, 81.37, 78.66, 81.11);
        context.bezierCurveTo(87.14, 79.28, 87.14, 69.97, 87.14, 69.97);
        context.bezierCurveTo(89.63, 69.84, 91.7, 69.14, 93.4, 68.17);
        context.bezierCurveTo(99.3, 64.79, 101.32, 57.29, 98.57, 51.07);
        context.bezierCurveTo(94.57, 42, 84.89, 40.54, 84.89, 40.54);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair12(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 74), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 74);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1993 Drawing
        context.beginPath();
        context.moveTo(72.24, 44.19);
        context.bezierCurveTo(70.16, 4.9, 52.19, 0.85, 42.83, 1.29);
        context.lineTo(33.19, 1.3);
        context.bezierCurveTo(24.19, 0.61, 4.56, 3.84, 2.26, 44.43);
        context.bezierCurveTo(2.26, 44.43, 13.63, 28.65, 36.58, 28.65);
        context.bezierCurveTo(59.52, 28.65, 72.24, 44.19, 72.24, 44.19);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1995 Drawing
        context.beginPath();
        context.moveTo(72.14, 43.56);
        context.lineTo(64.29, 37.49);
        context.bezierCurveTo(64.69, 39.49, 64.56, 40.7, 64.56, 42.81);
        context.bezierCurveTo(64.56, 55.69, 56.71, 66.99, 45.77, 71.03);
        context.bezierCurveTo(45.38, 71.48, 45.15, 71.71, 45.15, 71.71);
        context.bezierCurveTo(77.05, 71.71, 72.14, 43.56, 72.14, 43.56);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1997 Drawing
        context.beginPath();
        context.moveTo(9.41, 42.81);
        context.bezierCurveTo(9.41, 40.95, 9.58, 39.12, 9.89, 37.34);
        context.lineTo(2.44, 43.56);
        context.bezierCurveTo(2.44, 43.56, -2.47, 71.71, 29.43, 71.71);
        context.bezierCurveTo(29.43, 71.71, 29.28, 71.58, 29.02, 71.32);
        context.bezierCurveTo(17.65, 67.55, 9.4, 56.01, 9.41, 42.81);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair13(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 96, 79), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 96, resizedFrame.h / 79);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2003 Drawing
        context.beginPath();
        context.moveTo(94.38, 44.24);
        context.bezierCurveTo(94.55, 37.44, 88.2, 36.61, 88.2, 36.61);
        context.bezierCurveTo(88.2, 36.61, 93.21, 34.95, 90.88, 27.66);
        context.bezierCurveTo(88.54, 20.36, 80.69, 22.51, 80.69, 22.51);
        context.bezierCurveTo(80.69, 22.51, 85.86, 18.7, 80.69, 13.56);
        context.bezierCurveTo(75.51, 8.42, 70.5, 12.4, 70.5, 12.4);
        context.bezierCurveTo(70.5, 12.4, 72.5, 8.42, 66.32, 4.77);
        context.bezierCurveTo(60.15, 1.13, 55.97, 6.27, 55.97, 6.27);
        context.bezierCurveTo(55.97, 6.27, 54.49, 0.74, 48.12, 0.8);
        context.bezierCurveTo(41.76, 0.74, 40.27, 6.27, 40.27, 6.27);
        context.bezierCurveTo(40.27, 6.27, 36.1, 1.13, 29.92, 4.77);
        context.bezierCurveTo(23.74, 8.42, 25.74, 12.4, 25.74, 12.4);
        context.bezierCurveTo(25.74, 12.4, 20.73, 8.42, 15.56, 13.56);
        context.bezierCurveTo(10.38, 18.7, 15.56, 22.51, 15.56, 22.51);
        context.bezierCurveTo(15.56, 22.51, 7.71, 20.36, 5.37, 27.66);
        context.bezierCurveTo(3.03, 34.95, 8.04, 36.61, 8.04, 36.61);
        context.bezierCurveTo(8.04, 36.61, 1.69, 37.44, 1.86, 44.24);
        context.bezierCurveTo(2.03, 51.03, 8.04, 51.2, 8.04, 51.2);
        context.bezierCurveTo(8.04, 51.2, 1.36, 55.51, 6.2, 62.14);
        context.bezierCurveTo(11.05, 68.78, 14.22, 64.96, 14.22, 64.96);
        context.bezierCurveTo(14.22, 64.96, 10.21, 70.44, 16.89, 75.58);
        context.bezierCurveTo(19.87, 77.87, 22.12, 77.95, 23.7, 77.34);
        context.bezierCurveTo(21.46, 73.02, 20.18, 68.09, 20.18, 62.89);
        context.bezierCurveTo(19.09, 59.05, 36.35, 57.54, 52.98, 44.11);
        context.bezierCurveTo(58.33, 49.59, 75.33, 54.49, 75.33, 62.89);
        context.bezierCurveTo(75.33, 67.97, 74.1, 72.81, 71.95, 77.06);
        context.bezierCurveTo(73.54, 77.93, 75.97, 78.17, 79.35, 75.58);
        context.bezierCurveTo(86.03, 70.44, 82.02, 64.96, 82.02, 64.96);
        context.bezierCurveTo(82.02, 64.96, 85.2, 68.78, 90.04, 62.14);
        context.bezierCurveTo(94.88, 55.51, 88.2, 51.2, 88.2, 51.2);
        context.bezierCurveTo(88.2, 51.2, 94.22, 51.03, 94.38, 44.24);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair14(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 56), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 56);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Oval 526 Drawing
        oval(context, 4.25, 2.08, 28.3, 28.3);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1978 Drawing
        context.beginPath();
        context.moveTo(39.52, 13.7);
        context.bezierCurveTo(41.04, 6.03, 48.49, 1.04, 56.16, 2.57);
        context.bezierCurveTo(63.83, 4.09, 68.82, 11.54, 67.3, 19.21);
        context.bezierCurveTo(65.78, 26.88, 58.33, 31.87, 50.65, 30.35);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1979 Drawing
        context.beginPath();
        context.moveTo(32.22, 9.73);
        context.bezierCurveTo(23.22, 9.04, 3.59, 12.27, 1.29, 52.86);
        context.bezierCurveTo(1.29, 52.86, 50.36, 37.14, 48.41, 24.51);
        context.bezierCurveTo(48.38, 36.28, 72.27, 52.63, 72.27, 52.63);
        context.bezierCurveTo(70.19, 13.33, 51.22, 9.29, 41.86, 9.72);
        context.lineTo(32.22, 9.73);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair15(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 80, 74), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 80, resizedFrame.h / 74);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2413 Drawing
        context.beginPath();
        context.moveTo(7.13, 16.08);
        context.bezierCurveTo(7.13, 16.08, -2.44, 30.53, 2.66, 48.66);
        context.bezierCurveTo(7.76, 66.78, 4.77, 71.44, 4.77, 71.44);
        context.bezierCurveTo(4.77, 71.44, 13.91, 64.58, 12.92, 55.82);
        context.bezierCurveTo(12.92, 55.82, 5.77, 42.23, 15.3, 25.33);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2418 Drawing
        context.beginPath();
        context.moveTo(72.66, 50.79);
        context.bezierCurveTo(71.17, 47.69, 68.22, 45.79, 65.04, 45.47);
        context.bezierCurveTo(64.48, 51.84, 62.01, 57.7, 58.23, 62.33);
        context.bezierCurveTo(60.99, 64.51, 64.85, 65.08, 68.23, 63.45);
        context.bezierCurveTo(72.95, 61.17, 74.94, 55.51, 72.66, 50.79);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2434 Drawing
        context.beginPath();
        context.moveTo(7.13, 16.08);
        context.bezierCurveTo(5.18, 28.7, 72.24, 44.43, 72.24, 44.43);
        context.bezierCurveTo(69.94, 3.84, 50.32, 0.61, 41.32, 1.3);
        context.lineTo(31.67, 1.29);
        context.bezierCurveTo(31.67, 1.29, 7.13, 0.18, 7.13, 16.08);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2569 Drawing
        context.beginPath();
        context.moveTo(76.54, 42.65);
        context.bezierCurveTo(73.97, 38.8, 69.07, 37.44, 64.96, 39.19);
        context.bezierCurveTo(65.09, 40.34, 65.17, 41.51, 65.16, 42.69);
        context.bezierCurveTo(65.17, 47.12, 64.23, 51.36, 62.57, 55.19);
        context.bezierCurveTo(65.72, 57.83, 70.35, 58.2, 73.93, 55.8);
        context.bezierCurveTo(78.29, 52.89, 79.46, 47, 76.54, 42.65);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2653 Drawing
        context.beginPath();
        context.moveTo(7.13, 16.08);
        context.bezierCurveTo(7.13, 16.08, -2.44, 30.53, 2.66, 48.66);
        context.bezierCurveTo(7.76, 66.78, 4.77, 71.44, 4.77, 71.44);
        context.bezierCurveTo(4.77, 71.44, 13.91, 64.58, 12.92, 55.82);
        context.bezierCurveTo(12.92, 55.82, 5.77, 42.23, 15.3, 25.33);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2665 Drawing
        context.beginPath();
        context.moveTo(76.54, 42.65);
        context.bezierCurveTo(73.97, 38.8, 69.07, 37.44, 64.96, 39.19);
        context.bezierCurveTo(65.09, 40.34, 65.17, 41.51, 65.16, 42.69);
        context.bezierCurveTo(65.17, 47.12, 64.23, 51.36, 62.57, 55.19);
        context.bezierCurveTo(65.72, 57.83, 70.35, 58.2, 73.93, 55.8);
        context.bezierCurveTo(78.29, 52.89, 79.46, 47, 76.54, 42.65);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2745 Drawing
        context.beginPath();
        context.moveTo(72.66, 50.79);
        context.bezierCurveTo(71.17, 47.69, 68.22, 45.79, 65.04, 45.47);
        context.bezierCurveTo(64.48, 51.84, 62.01, 57.7, 58.23, 62.33);
        context.bezierCurveTo(60.99, 64.51, 64.85, 65.08, 68.23, 63.45);
        context.bezierCurveTo(72.95, 61.17, 74.94, 55.51, 72.66, 50.79);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2780 Drawing
        context.beginPath();
        context.moveTo(7.13, 16.08);
        context.bezierCurveTo(5.18, 28.7, 72.24, 44.43, 72.24, 44.43);
        context.bezierCurveTo(69.94, 3.84, 50.32, 0.61, 41.32, 1.3);
        context.lineTo(31.67, 1.29);
        context.bezierCurveTo(31.67, 1.29, 7.13, 0.18, 7.13, 16.08);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawGirlHair16(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 93, 95), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 93, resizedFrame.h / 95);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2932 Drawing
        context.beginPath();
        context.moveTo(7.58, 60.91);
        context.bezierCurveTo(7.58, 60.91, 2.26, 63.79, 2.26, 73.1);
        context.bezierCurveTo(2.26, 74.96, 2.51, 76.49, 2.92, 77.76);
        context.bezierCurveTo(4.69, 83.18, 11.7, 84.49, 15.33, 80.09);
        context.bezierCurveTo(16.69, 78.44, 17.64, 76.01, 17.41, 72.44);
        context.bezierCurveTo(16.75, 62.02, 7.58, 60.91, 7.58, 60.91);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2934 Drawing
        context.beginPath();
        context.moveTo(19.02, 42.69);
        context.bezierCurveTo(19.01, 38.88, 19.71, 35.22, 20.95, 31.83);
        context.lineTo(12, 35.91);
        context.lineTo(6.69, 48.66);
        context.bezierCurveTo(3.28, 57.99, 10.48, 64.34, 14.29, 67.24);
        context.bezierCurveTo(15.65, 68.27, 17.56, 68.19, 18.8, 67.01);
        context.bezierCurveTo(21.86, 64.11, 22.43, 57.99, 22.43, 57.99);
        context.lineTo(22.62, 57.29);
        context.bezierCurveTo(20.33, 52.95, 19.01, 47.95, 19.02, 42.69);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 693 Drawing
        oval(context, 3.15, 82.64, 11.1, 11.1);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2936 Drawing
        context.beginPath();
        context.moveTo(85.58, 60.91);
        context.bezierCurveTo(85.58, 60.91, 90.9, 63.79, 90.9, 73.1);
        context.bezierCurveTo(90.9, 74.96, 90.65, 76.49, 90.24, 77.76);
        context.bezierCurveTo(88.47, 83.18, 81.45, 84.49, 77.83, 80.09);
        context.bezierCurveTo(76.47, 78.44, 75.52, 76.01, 75.74, 72.44);
        context.bezierCurveTo(76.41, 62.02, 85.58, 60.91, 85.58, 60.91);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2938 Drawing
        context.beginPath();
        context.moveTo(74.14, 42.69);
        context.bezierCurveTo(74.14, 38.88, 73.45, 35.22, 72.2, 31.83);
        context.lineTo(81.16, 35.91);
        context.lineTo(86.47, 48.66);
        context.bezierCurveTo(89.88, 57.99, 82.68, 64.34, 78.86, 67.24);
        context.bezierCurveTo(77.51, 68.27, 75.6, 68.19, 74.36, 67.01);
        context.bezierCurveTo(71.3, 64.11, 70.73, 57.99, 70.73, 57.99);
        context.lineTo(70.53, 57.29);
        context.bezierCurveTo(72.83, 52.95, 74.14, 47.95, 74.14, 42.69);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 695 Drawing
        oval(context, 78.9, 82.64, 11.1, 11.1);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2940 Drawing
        context.beginPath();
        context.moveTo(81.29, 36.72);
        context.bezierCurveTo(76.93, 6.07, 60.33, 0.89, 51.77, 1.29);
        context.lineTo(46.22, 2.47);
        context.lineTo(42.13, 1.3);
        context.bezierCurveTo(34.31, 0.7, 18.46, 3.06, 12.93, 30.33);
        context.bezierCurveTo(12.58, 32.08, 12.26, 33.94, 12, 35.91);
        context.bezierCurveTo(12, 35.91, 44.57, 30.86, 46.23, 13.01);
        context.bezierCurveTo(46.23, 13.01, 52.18, 32.34, 78.68, 36.35);
        context.lineTo(81.29, 36.72);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair11(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 48), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 48);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2010 Drawing
        context.beginPath();
        context.moveTo(70.45, 24.35);
        context.bezierCurveTo(67.77, 16.63, 63.55, 20.49, 63.55, 20.49);
        context.bezierCurveTo(63.55, 20.49, 66.82, 16.33, 62.96, 10.38);
        context.bezierCurveTo(59.09, 4.44, 47.95, 9.19, 47.95, 9.19);
        context.bezierCurveTo(47.24, 2.4, 38.87, 2.08, 38.07, 2.06);
        context.bezierCurveTo(37.27, 2.08, 28.9, 2.4, 28.19, 9.19);
        context.bezierCurveTo(28.19, 9.19, 17.04, 4.44, 13.18, 10.38);
        context.bezierCurveTo(9.31, 16.33, 12.58, 20.49, 12.58, 20.49);
        context.bezierCurveTo(12.58, 20.49, 7.5, 17.51, 4.83, 25.24);
        context.bezierCurveTo(2.15, 32.97, 3.05, 40.54, 3.05, 40.54);
        context.bezierCurveTo(3.05, 40.54, 3.5, 44.97, 10.35, 43.68);
        context.lineTo(10.93, 43.68);
        context.bezierCurveTo(12.23, 28.78, 23.98, 16.65, 38.38, 16.62);
        context.bezierCurveTo(52.77, 16.65, 64.51, 28.75, 65.83, 43.63);
        context.bezierCurveTo(65.87, 43.63, 65.92, 43.63, 65.96, 43.64);
        context.bezierCurveTo(69.61, 43.79, 72.59, 40.71, 72.49, 37.05);
        context.bezierCurveTo(72.39, 33.59, 71.95, 28.69, 70.45, 24.35);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair12(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 59), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 59);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1965 Drawing
        context.beginPath();
        context.moveTo(37.05, 28.46);
        context.lineTo(68.26, 55.84);
        context.bezierCurveTo(68.26, 55.84, 78.18, 25.36, 60.07, 10.5);
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1966 Drawing
        context.beginPath();
        context.moveTo(8.37, 55.51);
        context.lineTo(60.11, 10.5);
        context.bezierCurveTo(60.11, 10.5, 38.05, -7.68, 15.44, 12.94);
        context.bezierCurveTo(-6.6, 33.04, 8.37, 55.51, 8.37, 55.51);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair13(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 67, 55), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 67, resizedFrame.h / 55);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1968 Drawing
        context.beginPath();
        context.moveTo(61.61, 36.03);
        context.bezierCurveTo(61.14, 34.66, 60.36, 33.42, 59.6, 32.19);
        context.bezierCurveTo(58.51, 30.42, 56.79, 27.18, 56.04, 23.54);
        context.bezierCurveTo(55.56, 18.79, 54.42, 13.4, 51.73, 12.32);
        context.bezierCurveTo(46.74, 10.33, 30.78, 9.99, 28.95, 2.34);
        context.bezierCurveTo(28.95, 2.34, 21.23, 9.75, 18.01, 14.21);
        context.bezierCurveTo(17.46, 14.98, 16.25, 14.7, 16.07, 13.77);
        context.lineTo(15.32, 9.83);
        context.bezierCurveTo(13.3, 13.33, 12.37, 17.42, 11.99, 21.04);
        context.bezierCurveTo(11.1, 26.14, 8.67, 30.36, 8.67, 30.36);
        context.bezierCurveTo(8.67, 30.36, 3.1, 39.76, 3.3, 50.51);
        context.bezierCurveTo(3.35, 52.7, 5.08, 46.54, 7.27, 46.54);
        context.bezierCurveTo(7.75, 46.54, 8.21, 46.64, 8.64, 46.8);
        context.bezierCurveTo(9.03, 42.23, 9.89, 37.68, 11.66, 35.21);
        context.bezierCurveTo(11.66, 35.21, 13.82, 31.86, 18.64, 32.44);
        context.bezierCurveTo(24.4, 33.14, 26.96, 34.99, 33.38, 34.99);
        context.bezierCurveTo(39.81, 34.99, 44.25, 31.22, 50.01, 32.55);
        context.bezierCurveTo(54.16, 33.51, 57.85, 37.34, 59.14, 46.85);
        context.bezierCurveTo(59.61, 46.65, 60.12, 46.54, 60.66, 46.54);
        context.bezierCurveTo(62.85, 46.54, 64.63, 48.32, 64.63, 50.51);
        context.bezierCurveTo(64.63, 50.51, 65.01, 45.78, 61.61, 36.03);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair14(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 56, 29), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 56, resizedFrame.h / 29);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 1970 Drawing
        context.beginPath();
        context.moveTo(54.63, 24.24);
        context.bezierCurveTo(51.27, 11.99, 40.73, 2.76, 28.17, 2.73);
        context.bezierCurveTo(16.02, 2.76, 5.76, 11.4, 2.07, 23.04);
        context.lineTo(1.76, 24.42);
        context.bezierCurveTo(1.76, 24.42, 11.74, 9.79, 28.04, 9.79);
        context.bezierCurveTo(43.72, 9.79, 52.81, 21.63, 54.63, 24.24);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair15(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 29, 43), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 29, resizedFrame.h / 43);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2006 Drawing
        context.beginPath();
        context.moveTo(14.49, 39.01);
        context.bezierCurveTo(14.49, 39.01, -0.81, 42.01, 14.49, 2.77);
        context.bezierCurveTo(14.49, 2.77, 30.12, 39.01, 14.49, 39.01);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair16(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 49), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 49);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2005 Drawing
        context.beginPath();
        context.moveTo(21.45, 20.78);
        context.bezierCurveTo(21.45, 20.78, 20.63, 27.09, 31.77, 32.08);
        context.bezierCurveTo(31.77, 32.08, 29.78, 26.68, 29.19, 20.86);
        context.bezierCurveTo(29.19, 20.86, 31.85, 30.91, 39.09, 32.99);
        context.bezierCurveTo(39.09, 32.99, 36.84, 27.59, 37.92, 22.35);
        context.bezierCurveTo(37.92, 22.35, 37.42, 31.25, 46.74, 33.57);
        context.bezierCurveTo(46.74, 33.57, 45.99, 30.91, 46.49, 25.18);
        context.bezierCurveTo(46.49, 25.18, 46.59, 29.52, 54.56, 31.01);
        context.bezierCurveTo(62.78, 32.54, 71.1, 45.41, 71.1, 45.41);
        context.bezierCurveTo(76.28, 6.22, 53.11, 1.43, 42.99, 1.63);
        context.lineTo(32.9, 1.62);
        context.bezierCurveTo(23.51, 0.95, 0.76, 4.04, 2.1, 44.82);
        context.bezierCurveTo(8.24, 42.25, 18.93, 35.12, 21.45, 20.78);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawBoyHair17(canvas, hairColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 59), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 59);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _hairColor = hairColor === 'hair1' ? AvatarStyleKit.hair1 : (hairColor === 'hair2' ? AvatarStyleKit.hair2 : (hairColor === 'hair3' ? AvatarStyleKit.hair3 : (hairColor === 'hair4' ? AvatarStyleKit.hair4 : (hairColor === 'hair5' ? AvatarStyleKit.hair5 : (hairColor === 'hair6' ? AvatarStyleKit.hair6 : (hairColor === 'hair7' ? AvatarStyleKit.hair7 : (hairColor === 'hair8' ? AvatarStyleKit.hair8 : (hairColor === 'hair9' ? AvatarStyleKit.hair9 : (hairColor === 'hair10' ? AvatarStyleKit.hair10 : (hairColor === 'hair11' ? AvatarStyleKit.hair11 : (hairColor === 'hair12' ? AvatarStyleKit.hair12 : (hairColor === 'hair13' ? AvatarStyleKit.hair13 : (hairColor === 'hair14' ? AvatarStyleKit.hair14 : (hairColor === 'hair15' ? AvatarStyleKit.hair15 : (hairColor === 'hair16' ? AvatarStyleKit.hair16 : (hairColor === 'hair170' ? AvatarStyleKit.hair17 : (hairColor === 'hair18' ? AvatarStyleKit.hair18 : (hairColor === 'hair19' ? AvatarStyleKit.hair19 : (hairColor === 'hair20' ? AvatarStyleKit.hair20 : (hairColor === 'hair21' ? AvatarStyleKit.hair21 : AvatarStyleKit.hair22))))))))))))))))))));

        //// Bezier 2912 Drawing
        context.beginPath();
        context.moveTo(36.4, 33.89);
        context.bezierCurveTo(36.4, 33.89, 43.05, 33.22, 46.63, 30.81);
        context.bezierCurveTo(50.2, 28.4, 56.02, 28.9, 57.93, 33.31);
        context.bezierCurveTo(59.85, 37.71, 63.76, 43.95, 63.42, 55.67);
        context.bezierCurveTo(63.42, 55.67, 68.08, 48.35, 67, 34.64);
        context.bezierCurveTo(67, 34.64, 70.3, 35.11, 71.19, 36.82);
        context.bezierCurveTo(71.19, 36.82, 74.73, 9.94, 46.21, 7.7);
        context.bezierCurveTo(46.21, 7.7, 50.7, 5.7, 55.11, 7.37);
        context.bezierCurveTo(55.11, 7.37, 42.14, -0.36, 31.66, 6.12);
        context.bezierCurveTo(31.66, 6.12, 30.25, 4.37, 32.99, 3.13);
        context.bezierCurveTo(32.99, 3.13, 18.19, 3.21, 17.11, 13.44);
        context.bezierCurveTo(17.11, 13.44, 0.74, 13.94, 2.07, 34.05);
        context.lineTo(4.06, 32.72);
        context.bezierCurveTo(4.06, 32.72, 6.8, 45.61, 10.46, 56.25);
        context.bezierCurveTo(10.46, 56.25, 8.63, 34.39, 19.19, 28.9);
        context.bezierCurveTo(19.19, 28.9, 26.42, 34.64, 36.4, 33.89);
        context.closePath();
        context.fillStyle = _hairColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawPigMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 66, 36), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 66, resizedFrame.h / 36);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor64 = 'rgba(239, 163, 194, 1)';

        //// Bezier 2830 Drawing
        context.beginPath();
        context.moveTo(20.43, 7.02);
        context.bezierCurveTo(20.43, 7.02, 19.55, 1.43, 11.02, 1.43);
        context.bezierCurveTo(2.49, 1.43, 1.63, 7.41, 1.63, 7.41);
        context.bezierCurveTo(1.63, 7.41, 8.68, 8.45, 11.9, 13.34);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2831 Drawing
        context.beginPath();
        context.moveTo(12.63, 19.81);
        context.bezierCurveTo(12.63, 20.29, 12.23, 20.68, 11.75, 20.68);
        context.lineTo(3.85, 20.68);
        context.bezierCurveTo(3.37, 20.68, 2.98, 20.29, 2.98, 19.81);
        context.lineTo(2.98, 19.55);
        context.bezierCurveTo(2.98, 19.07, 3.37, 18.68, 3.85, 18.68);
        context.lineTo(11.75, 18.68);
        context.bezierCurveTo(12.23, 18.68, 12.63, 19.07, 12.63, 19.55);
        context.lineTo(12.63, 19.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2832 Drawing
        context.beginPath();
        context.moveTo(12.63, 19.81);
        context.bezierCurveTo(12.63, 20.29, 12.23, 20.68, 11.75, 20.68);
        context.lineTo(3.85, 20.68);
        context.bezierCurveTo(3.37, 20.68, 2.98, 20.29, 2.98, 19.81);
        context.lineTo(2.98, 19.55);
        context.bezierCurveTo(2.98, 19.07, 3.37, 18.68, 3.85, 18.68);
        context.lineTo(11.75, 18.68);
        context.bezierCurveTo(12.23, 18.68, 12.63, 19.07, 12.63, 19.55);
        context.lineTo(12.63, 19.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2833 Drawing
        context.beginPath();
        context.moveTo(53.83, 19.81);
        context.bezierCurveTo(53.83, 20.29, 54.23, 20.68, 54.71, 20.68);
        context.lineTo(62.61, 20.68);
        context.bezierCurveTo(63.09, 20.68, 63.48, 20.29, 63.48, 19.81);
        context.lineTo(63.48, 19.55);
        context.bezierCurveTo(63.48, 19.07, 63.09, 18.68, 62.61, 18.68);
        context.lineTo(54.71, 18.68);
        context.bezierCurveTo(54.23, 18.68, 53.83, 19.07, 53.83, 19.55);
        context.lineTo(53.83, 19.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2834 Drawing
        context.beginPath();
        context.moveTo(53.83, 19.81);
        context.bezierCurveTo(53.83, 20.29, 54.23, 20.68, 54.71, 20.68);
        context.lineTo(62.61, 20.68);
        context.bezierCurveTo(63.09, 20.68, 63.48, 20.29, 63.48, 19.81);
        context.lineTo(63.48, 19.55);
        context.bezierCurveTo(63.48, 19.07, 63.09, 18.68, 62.61, 18.68);
        context.lineTo(54.71, 18.68);
        context.bezierCurveTo(54.23, 18.68, 53.83, 19.07, 53.83, 19.55);
        context.lineTo(53.83, 19.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2835 Drawing
        context.beginPath();
        context.moveTo(16.48, 6.64);
        context.bezierCurveTo(16.48, 6.64, 16.01, 3.68, 11.51, 3.68);
        context.bezierCurveTo(7, 3.68, 6.55, 6.85, 6.55, 6.85);
        context.bezierCurveTo(6.55, 6.85, 10.27, 7.4, 11.97, 9.98);
        context.fillStyle = fillColor64;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2836 Drawing
        context.beginPath();
        context.moveTo(46.63, 7.02);
        context.bezierCurveTo(46.63, 7.02, 47.51, 1.43, 56.04, 1.43);
        context.bezierCurveTo(64.57, 1.43, 65.43, 7.41, 65.43, 7.41);
        context.bezierCurveTo(65.43, 7.41, 58.38, 8.45, 55.16, 13.34);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2837 Drawing
        context.beginPath();
        context.moveTo(50.58, 6.64);
        context.bezierCurveTo(50.58, 6.64, 51.05, 3.68, 55.55, 3.68);
        context.bezierCurveTo(60.06, 3.68, 60.52, 6.85, 60.52, 6.85);
        context.bezierCurveTo(60.52, 6.85, 56.79, 7.4, 55.09, 9.98);
        context.fillStyle = fillColor64;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2838 Drawing
        context.beginPath();
        context.moveTo(33.24, 29.22);
        context.bezierCurveTo(35.4, 29.22, 37.56, 30.99, 37.56, 30.99);
        context.bezierCurveTo(44.28, 37.4, 51.69, 34.23, 51.69, 34.23);
        context.bezierCurveTo(51.69, 34.23, 61.8, 29.91, 59.87, 16.56);
        context.bezierCurveTo(57.94, 3.2, 33.24, 5.36, 33.24, 5.36);
        context.lineTo(33.23, 5.36);
        context.bezierCurveTo(33.23, 5.36, 8.53, 3.2, 6.6, 16.56);
        context.bezierCurveTo(4.67, 29.91, 14.78, 34.23, 14.78, 34.23);
        context.bezierCurveTo(14.78, 34.23, 22.19, 37.4, 28.91, 30.99);
        context.bezierCurveTo(28.91, 30.99, 31.07, 29.22, 33.23, 29.22);
        context.lineTo(33.24, 29.22);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2839 Drawing
        context.beginPath();
        context.moveTo(33.24, 29.22);
        context.bezierCurveTo(35.4, 29.22, 37.56, 30.99, 37.56, 30.99);
        context.bezierCurveTo(44.28, 37.4, 51.69, 34.23, 51.69, 34.23);
        context.bezierCurveTo(51.69, 34.23, 61.8, 29.91, 59.87, 16.56);
        context.bezierCurveTo(57.94, 3.2, 33.24, 5.36, 33.24, 5.36);
        context.lineTo(33.23, 5.36);
        context.bezierCurveTo(33.23, 5.36, 8.53, 3.2, 6.6, 16.56);
        context.bezierCurveTo(4.67, 29.91, 14.78, 34.23, 14.78, 34.23);
        context.bezierCurveTo(14.78, 34.23, 22.19, 37.4, 28.91, 30.99);
        context.bezierCurveTo(28.91, 30.99, 31.07, 29.22, 33.23, 29.22);
        context.lineTo(33.24, 29.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 638 Drawing
        oval(context, 16.5, 16.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 639 Drawing
        oval(context, 17.75, 17.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 640 Drawing
        oval(context, 43.4, 16.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 641 Drawing
        oval(context, 44.65, 17.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 642 Drawing
        oval(context, 25.8, 19.88, 14.5, 14.5);
        context.fillStyle = fillColor64;
        context.fill();


        //// Oval 643 Drawing
        oval(context, 25.8, 19.88, 14.5, 14.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 644 Drawing
        oval(context, 29.9, 24.63, 2.3, 6);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Oval 645 Drawing
        oval(context, 29.9, 24.63, 2.3, 6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.08;
        context.stroke();


        //// Oval 646 Drawing
        oval(context, 34.25, 24.63, 2.3, 6);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Oval 647 Drawing
        oval(context, 34.25, 24.63, 2.3, 6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.08;
        context.stroke();
        
        context.restore();

    }

    function drawElephantMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 104, 66), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 104, resizedFrame.h / 66);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor = 'rgba(198, 198, 198, 1)';

        //// Bezier 2807 Drawing
        context.beginPath();
        context.moveTo(48.15, 22.64);
        context.bezierCurveTo(48.15, 22.64, 42.91, 3.94, 31.85, 2.94);
        context.bezierCurveTo(21.26, 1.98, 13.23, 0.86, 6.91, 4.52);
        context.bezierCurveTo(0.59, 8.18, 0.26, 21.39, 5.33, 30.04);
        context.bezierCurveTo(10.4, 38.69, 13.98, 46.83, 23.54, 46.83);
        context.bezierCurveTo(33.1, 46.83, 40.92, 39.52, 40.92, 39.52);
        context.lineTo(48.15, 22.64);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2808 Drawing
        context.beginPath();
        context.moveTo(48.15, 22.64);
        context.bezierCurveTo(48.15, 22.64, 42.91, 3.94, 31.85, 2.94);
        context.bezierCurveTo(21.26, 1.98, 13.23, 0.86, 6.91, 4.52);
        context.bezierCurveTo(0.59, 8.18, 0.26, 21.39, 5.33, 30.04);
        context.bezierCurveTo(10.4, 38.69, 13.98, 46.83, 23.54, 46.83);
        context.bezierCurveTo(33.1, 46.83, 40.92, 39.52, 40.92, 39.52);
        context.lineTo(48.15, 22.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2809 Drawing
        context.beginPath();
        context.moveTo(32.77, 26.81);
        context.bezierCurveTo(32.77, 27.29, 32.37, 27.68, 31.89, 27.68);
        context.lineTo(23.99, 27.68);
        context.bezierCurveTo(23.51, 27.68, 23.12, 27.29, 23.12, 26.81);
        context.lineTo(23.12, 26.55);
        context.bezierCurveTo(23.12, 26.07, 23.51, 25.68, 23.99, 25.68);
        context.lineTo(31.89, 25.68);
        context.bezierCurveTo(32.37, 25.68, 32.77, 26.07, 32.77, 26.55);
        context.lineTo(32.77, 26.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2810 Drawing
        context.beginPath();
        context.moveTo(32.77, 26.81);
        context.bezierCurveTo(32.77, 27.29, 32.37, 27.68, 31.89, 27.68);
        context.lineTo(23.99, 27.68);
        context.bezierCurveTo(23.51, 27.68, 23.12, 27.29, 23.12, 26.81);
        context.lineTo(23.12, 26.55);
        context.bezierCurveTo(23.12, 26.07, 23.51, 25.68, 23.99, 25.68);
        context.lineTo(31.89, 25.68);
        context.bezierCurveTo(32.37, 25.68, 32.77, 26.07, 32.77, 26.55);
        context.lineTo(32.77, 26.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2811 Drawing
        context.beginPath();
        context.moveTo(42.49, 23.08);
        context.bezierCurveTo(42.49, 23.08, 38.53, 8.94, 30.18, 8.18);
        context.bezierCurveTo(22.18, 7.46, 16.11, 6.61, 11.33, 9.38);
        context.bezierCurveTo(6.56, 12.14, 6.31, 22.13, 10.14, 28.67);
        context.bezierCurveTo(13.97, 35.2, 16.67, 41.36, 23.9, 41.36);
        context.bezierCurveTo(31.12, 41.36, 37.03, 35.83, 37.03, 35.83);
        context.lineTo(42.49, 23.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2812 Drawing
        context.beginPath();
        context.moveTo(42.49, 23.08);
        context.bezierCurveTo(42.49, 23.08, 38.53, 8.94, 30.18, 8.18);
        context.bezierCurveTo(22.18, 7.46, 16.11, 6.61, 11.33, 9.38);
        context.bezierCurveTo(6.56, 12.14, 6.31, 22.13, 10.14, 28.67);
        context.bezierCurveTo(13.97, 35.2, 16.67, 41.36, 23.9, 41.36);
        context.bezierCurveTo(31.12, 41.36, 37.03, 35.83, 37.03, 35.83);
        context.lineTo(42.49, 23.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2813 Drawing
        context.beginPath();
        context.moveTo(56.82, 22.64);
        context.bezierCurveTo(56.82, 22.64, 62.06, 3.94, 73.12, 2.94);
        context.bezierCurveTo(83.7, 1.98, 91.74, 0.86, 98.06, 4.52);
        context.bezierCurveTo(104.38, 8.18, 104.71, 21.39, 99.64, 30.04);
        context.bezierCurveTo(94.56, 38.69, 90.99, 46.83, 81.43, 46.83);
        context.bezierCurveTo(71.87, 46.83, 64.05, 39.52, 64.05, 39.52);
        context.lineTo(56.82, 22.64);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2814 Drawing
        context.beginPath();
        context.moveTo(56.82, 22.64);
        context.bezierCurveTo(56.82, 22.64, 62.06, 3.94, 73.12, 2.94);
        context.bezierCurveTo(83.7, 1.98, 91.74, 0.86, 98.06, 4.52);
        context.bezierCurveTo(104.38, 8.18, 104.71, 21.39, 99.64, 30.04);
        context.bezierCurveTo(94.56, 38.69, 90.99, 46.83, 81.43, 46.83);
        context.bezierCurveTo(71.87, 46.83, 64.05, 39.52, 64.05, 39.52);
        context.lineTo(56.82, 22.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2815 Drawing
        context.beginPath();
        context.moveTo(62.48, 23.08);
        context.bezierCurveTo(62.48, 23.08, 66.43, 8.94, 74.79, 8.18);
        context.bezierCurveTo(82.79, 7.46, 88.86, 6.61, 93.64, 9.38);
        context.bezierCurveTo(98.41, 12.14, 98.66, 22.13, 94.83, 28.67);
        context.bezierCurveTo(91, 35.2, 88.3, 41.36, 81.07, 41.36);
        context.bezierCurveTo(73.85, 41.36, 67.94, 35.83, 67.94, 35.83);
        context.lineTo(62.48, 23.08);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2816 Drawing
        context.beginPath();
        context.moveTo(62.48, 23.08);
        context.bezierCurveTo(62.48, 23.08, 66.43, 8.94, 74.79, 8.18);
        context.bezierCurveTo(82.79, 7.46, 88.86, 6.61, 93.64, 9.38);
        context.bezierCurveTo(98.41, 12.14, 98.66, 22.13, 94.83, 28.67);
        context.bezierCurveTo(91, 35.2, 88.3, 41.36, 81.07, 41.36);
        context.bezierCurveTo(73.85, 41.36, 67.94, 35.83, 67.94, 35.83);
        context.lineTo(62.48, 23.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2817 Drawing
        context.beginPath();
        context.moveTo(73.97, 26.81);
        context.bezierCurveTo(73.97, 27.29, 74.36, 27.68, 74.85, 27.68);
        context.lineTo(82.74, 27.68);
        context.bezierCurveTo(83.23, 27.68, 83.62, 27.29, 83.62, 26.81);
        context.lineTo(83.62, 26.55);
        context.bezierCurveTo(83.62, 26.07, 83.23, 25.68, 82.74, 25.68);
        context.lineTo(74.85, 25.68);
        context.bezierCurveTo(74.36, 25.68, 73.97, 26.07, 73.97, 26.55);
        context.lineTo(73.97, 26.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2818 Drawing
        context.beginPath();
        context.moveTo(73.97, 26.81);
        context.bezierCurveTo(73.97, 27.29, 74.36, 27.68, 74.85, 27.68);
        context.lineTo(82.74, 27.68);
        context.bezierCurveTo(83.23, 27.68, 83.62, 27.29, 83.62, 26.81);
        context.lineTo(83.62, 26.55);
        context.bezierCurveTo(83.62, 26.07, 83.23, 25.68, 82.74, 25.68);
        context.lineTo(74.85, 25.68);
        context.bezierCurveTo(74.36, 25.68, 73.97, 26.07, 73.97, 26.55);
        context.lineTo(73.97, 26.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2819 Drawing
        context.beginPath();
        context.moveTo(53.38, 36.22);
        context.bezierCurveTo(55.54, 36.22, 57.7, 37.99, 57.7, 37.99);
        context.bezierCurveTo(64.42, 44.4, 71.83, 41.23, 71.83, 41.23);
        context.bezierCurveTo(71.83, 41.23, 81.94, 36.91, 80.01, 23.56);
        context.bezierCurveTo(78.08, 10.2, 53.38, 12.36, 53.38, 12.36);
        context.lineTo(53.37, 12.36);
        context.bezierCurveTo(53.37, 12.36, 28.67, 10.2, 26.74, 23.56);
        context.bezierCurveTo(24.81, 36.91, 34.92, 41.23, 34.92, 41.23);
        context.bezierCurveTo(34.92, 41.23, 42.33, 44.4, 49.05, 37.99);
        context.bezierCurveTo(49.05, 37.99, 51.21, 36.22, 53.37, 36.22);
        context.lineTo(53.38, 36.22);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2820 Drawing
        context.beginPath();
        context.moveTo(53.38, 36.22);
        context.bezierCurveTo(55.54, 36.22, 57.7, 37.99, 57.7, 37.99);
        context.bezierCurveTo(64.42, 44.4, 71.83, 41.23, 71.83, 41.23);
        context.bezierCurveTo(71.83, 41.23, 81.94, 36.91, 80.01, 23.56);
        context.bezierCurveTo(78.08, 10.2, 53.38, 12.36, 53.38, 12.36);
        context.lineTo(53.37, 12.36);
        context.bezierCurveTo(53.37, 12.36, 28.67, 10.2, 26.74, 23.56);
        context.bezierCurveTo(24.81, 36.91, 34.92, 41.23, 34.92, 41.23);
        context.bezierCurveTo(34.92, 41.23, 42.33, 44.4, 49.05, 37.99);
        context.bezierCurveTo(49.05, 37.99, 51.21, 36.22, 53.37, 36.22);
        context.lineTo(53.38, 36.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2821 Drawing
        context.beginPath();
        context.moveTo(41.22, 21.28);
        context.bezierCurveTo(43.91, 22.23, 44.36, 27.82, 44.38, 27.86);
        context.bezierCurveTo(44.43, 27.94, 40.48, 31.95, 37.78, 31);
        context.bezierCurveTo(35.09, 30.04, 34.61, 24.47, 34.62, 24.41);
        context.bezierCurveTo(34.64, 24.29, 38.52, 20.32, 41.22, 21.28);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 2822 Drawing
        context.beginPath();
        context.moveTo(64.53, 21.28);
        context.bezierCurveTo(61.75, 22.26, 61.25, 27.87, 61.22, 27.92);
        context.bezierCurveTo(61.16, 28, 65.19, 31.98, 67.97, 31);
        context.bezierCurveTo(70.75, 30.01, 71.3, 24.42, 71.29, 24.36);
        context.bezierCurveTo(71.26, 24.23, 67.31, 20.29, 64.53, 21.28);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 634 Drawing
        oval(context, 36.65, 23.44, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 635 Drawing
        oval(context, 37.9, 24.69, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 636 Drawing
        oval(context, 63.55, 23.44, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 637 Drawing
        oval(context, 64.8, 24.69, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2823 Drawing
        context.beginPath();
        context.moveTo(48.83, 42.43);
        context.bezierCurveTo(48.64, 40.65, 48.28, 38.9, 47.89, 37.16);
        context.bezierCurveTo(47.42, 35.12, 47.58, 32.34, 52.22, 32.2);
        context.bezierCurveTo(57.79, 32.04, 56.9, 36.68, 56.46, 39.44);
        context.bezierCurveTo(56.15, 41.38, 54.38, 56.68, 62.65, 56.95);
        context.bezierCurveTo(63.59, 56.99, 67.05, 56.3, 67.93, 55.98);
        context.bezierCurveTo(70.09, 55.21, 72.75, 53.85, 74.83, 53.07);
        context.lineTo(77.58, 58.72);
        context.bezierCurveTo(77.58, 58.72, 55.8, 72.28, 50.56, 55.48);
        context.bezierCurveTo(48.56, 49.06, 49.09, 44.82, 48.83, 42.43);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2824 Drawing
        context.beginPath();
        context.moveTo(48.83, 42.43);
        context.bezierCurveTo(48.64, 40.65, 48.28, 38.9, 47.89, 37.16);
        context.bezierCurveTo(47.42, 35.12, 47.58, 32.34, 52.22, 32.2);
        context.bezierCurveTo(57.79, 32.04, 56.9, 36.68, 56.46, 39.44);
        context.bezierCurveTo(56.15, 41.38, 54.38, 56.68, 62.65, 56.95);
        context.bezierCurveTo(63.59, 56.99, 67.05, 56.3, 67.93, 55.98);
        context.bezierCurveTo(70.09, 55.21, 72.75, 53.85, 74.83, 53.07);
        context.lineTo(77.58, 58.72);
        context.bezierCurveTo(77.58, 58.72, 55.8, 72.28, 50.56, 55.48);
        context.bezierCurveTo(48.56, 49.06, 49.09, 44.82, 48.83, 42.43);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2825 Drawing
        context.beginPath();
        context.moveTo(55.96, 42.26);
        context.lineTo(52.05, 42.26);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();


        //// Bezier 2826 Drawing
        context.beginPath();
        context.moveTo(56.21, 47.92);
        context.lineTo(52.89, 48.33);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();


        //// Bezier 2827 Drawing
        context.beginPath();
        context.moveTo(57.04, 52.24);
        context.lineTo(53.8, 53.74);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();


        //// Bezier 2828 Drawing
        context.beginPath();
        context.moveTo(59.37, 55.9);
        context.lineTo(57.63, 58.47);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();


        //// Bezier 2829 Drawing
        context.beginPath();
        context.moveTo(63.69, 56.98);
        context.lineTo(63.94, 59.97);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.stroke();
        
        context.restore();

    }

    function drawGiraffeMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 66, 45), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 66, resizedFrame.h / 45);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';
        var fillColor11 = 'rgba(255, 147, 3, 1)';

        //// Bezier 2776 Drawing
        context.beginPath();
        context.moveTo(12.54, 28.81);
        context.bezierCurveTo(12.54, 29.29, 12.14, 29.68, 11.66, 29.68);
        context.lineTo(3.76, 29.68);
        context.bezierCurveTo(3.28, 29.68, 2.89, 29.29, 2.89, 28.81);
        context.lineTo(2.89, 28.55);
        context.bezierCurveTo(2.89, 28.07, 3.28, 27.68, 3.76, 27.68);
        context.lineTo(11.66, 27.68);
        context.bezierCurveTo(12.14, 27.68, 12.54, 28.07, 12.54, 28.55);
        context.lineTo(12.54, 28.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2777 Drawing
        context.beginPath();
        context.moveTo(12.54, 28.81);
        context.bezierCurveTo(12.54, 29.29, 12.14, 29.68, 11.66, 29.68);
        context.lineTo(3.76, 29.68);
        context.bezierCurveTo(3.28, 29.68, 2.89, 29.29, 2.89, 28.81);
        context.lineTo(2.89, 28.55);
        context.bezierCurveTo(2.89, 28.07, 3.28, 27.68, 3.76, 27.68);
        context.lineTo(11.66, 27.68);
        context.bezierCurveTo(12.14, 27.68, 12.54, 28.07, 12.54, 28.55);
        context.lineTo(12.54, 28.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2778 Drawing
        context.beginPath();
        context.moveTo(8.2, 23.54);
        context.bezierCurveTo(8.2, 23.54, 4.07, 9.48, 10.28, 5.97);
        context.bezierCurveTo(16.49, 2.46, 22.59, 15.58, 22.59, 15.58);
        context.fillStyle = fillColor7;
        context.fill();


        //// Group 63
        context.save();

        //// Clip Clip 61

        context.beginPath();
        context.moveTo(10.28, 5.97);
        context.bezierCurveTo(4.07, 9.48, 8.2, 23.54, 8.2, 23.54);
        context.lineTo(8.2, 23.54);
        context.lineTo(22.59, 15.58);
        context.bezierCurveTo(22.59, 15.58, 17.84, 5.37, 12.46, 5.37);
        context.lineTo(12.46, 5.37);
        context.bezierCurveTo(11.74, 5.37, 11.01, 5.56, 10.28, 5.97);
        context.closePath();
        context.clip();



        //// Bezier 2779 Drawing
        context.beginPath();
        context.moveTo(3.53, 5.32);
        context.bezierCurveTo(3.53, 5.32, 1.28, 6.74, 1.28, 8.16);
        context.bezierCurveTo(1.28, 9.58, 1.52, 10.17, 3.06, 11);
        context.bezierCurveTo(4.6, 11.83, 5.54, 12.89, 7.67, 12.06);
        context.bezierCurveTo(9.8, 11.24, 10.75, 11.83, 11.22, 9.93);
        context.bezierCurveTo(11.7, 8.04, 12.05, 8.52, 12.4, 7.69);
        context.bezierCurveTo(12.76, 6.86, 14.18, 6.39, 12.52, 5.44);
        context.bezierCurveTo(10.87, 4.49, 10.16, 4.37, 9.68, 4.49);
        context.bezierCurveTo(9.21, 4.61, 4.24, 5.44, 3.53, 5.32);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        context.restore();



        //// Bezier 2781 Drawing
        context.beginPath();
        context.moveTo(8.2, 23.54);
        context.bezierCurveTo(8.2, 23.54, 4.07, 9.48, 10.28, 5.97);
        context.bezierCurveTo(16.49, 2.46, 22.59, 15.58, 22.59, 15.58);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2782 Drawing
        context.beginPath();
        context.moveTo(59.24, 23.54);
        context.bezierCurveTo(59.24, 23.54, 63.37, 9.48, 57.16, 5.97);
        context.bezierCurveTo(50.95, 2.46, 44.85, 15.58, 44.85, 15.58);
        context.fillStyle = fillColor7;
        context.fill();


        //// Group 64
        context.save();

        //// Clip Clip 62

        context.beginPath();
        context.moveTo(44.85, 15.58);
        context.lineTo(59.24, 23.54);
        context.bezierCurveTo(59.24, 23.54, 63.37, 9.48, 57.16, 5.97);
        context.lineTo(57.16, 5.97);
        context.bezierCurveTo(56.43, 5.56, 55.7, 5.37, 54.98, 5.37);
        context.lineTo(54.98, 5.37);
        context.bezierCurveTo(49.6, 5.37, 44.85, 15.58, 44.85, 15.58);
        context.closePath();
        context.clip();



        //// Bezier 2783 Drawing
        context.beginPath();
        context.moveTo(62.22, 18.29);
        context.bezierCurveTo(62.22, 18.29, 60.44, 20.27, 59.04, 20.03);
        context.bezierCurveTo(57.65, 19.79, 57.1, 19.46, 56.54, 17.8);
        context.bezierCurveTo(55.99, 16.15, 55.1, 15.04, 56.27, 13.08);
        context.bezierCurveTo(57.45, 11.12, 57.02, 10.08, 58.97, 9.94);
        context.bezierCurveTo(60.91, 9.79, 60.51, 9.36, 61.38, 9.15);
        context.bezierCurveTo(62.26, 8.94, 62.96, 7.62, 63.62, 9.41);
        context.bezierCurveTo(64.27, 11.2, 64.27, 11.92, 64.07, 12.37);
        context.bezierCurveTo(63.88, 12.81, 62.22, 17.57, 62.22, 18.29);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        context.restore();



        //// Bezier 2785 Drawing
        context.beginPath();
        context.moveTo(59.24, 23.54);
        context.bezierCurveTo(59.24, 23.54, 63.37, 9.48, 57.16, 5.97);
        context.bezierCurveTo(50.95, 2.46, 44.85, 15.58, 44.85, 15.58);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2786 Drawing
        context.beginPath();
        context.moveTo(10.78, 19.76);
        context.bezierCurveTo(10.78, 19.76, 9.56, 12.32, 12.37, 11.69);
        context.bezierCurveTo(15.18, 11.06, 17.72, 15.81, 17.72, 15.81);
        context.lineTo(10.78, 19.76);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2787 Drawing
        context.beginPath();
        context.moveTo(10.78, 19.76);
        context.bezierCurveTo(10.78, 19.76, 9.56, 12.32, 12.37, 11.69);
        context.bezierCurveTo(15.18, 11.06, 17.72, 15.81, 17.72, 15.81);
        context.lineTo(10.78, 19.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();


        //// Bezier 2788 Drawing
        context.beginPath();
        context.moveTo(28.78, 22);
        context.lineTo(25.57, 6.35);
        context.bezierCurveTo(26.3, 5.73, 26.69, 4.74, 26.48, 3.73);
        context.bezierCurveTo(26.18, 2.24, 24.72, 1.29, 23.24, 1.59);
        context.bezierCurveTo(21.76, 1.9, 20.8, 3.35, 21.1, 4.83);
        context.bezierCurveTo(21.32, 5.87, 22.1, 6.64, 23.05, 6.91);
        context.lineTo(26.26, 22.52);
        context.lineTo(28.78, 22);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2789 Drawing
        context.beginPath();
        context.moveTo(28.78, 22);
        context.lineTo(25.57, 6.35);
        context.bezierCurveTo(26.3, 5.73, 26.69, 4.74, 26.48, 3.73);
        context.bezierCurveTo(26.18, 2.24, 24.72, 1.29, 23.24, 1.59);
        context.bezierCurveTo(21.76, 1.9, 20.8, 3.35, 21.1, 4.83);
        context.bezierCurveTo(21.32, 5.87, 22.1, 6.64, 23.05, 6.91);
        context.lineTo(26.26, 22.52);
        context.lineTo(28.78, 22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2790 Drawing
        context.beginPath();
        context.moveTo(43.1, 1.55);
        context.bezierCurveTo(41.61, 1.25, 40.16, 2.2, 39.86, 3.69);
        context.bezierCurveTo(39.64, 4.72, 40.05, 5.72, 40.81, 6.35);
        context.lineTo(37.63, 21.84);
        context.lineTo(40.15, 22.36);
        context.lineTo(43.34, 6.85);
        context.bezierCurveTo(44.27, 6.57, 45.02, 5.81, 45.23, 4.79);
        context.bezierCurveTo(45.54, 3.31, 44.58, 1.86, 43.1, 1.55);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2791 Drawing
        context.beginPath();
        context.moveTo(43.1, 1.55);
        context.bezierCurveTo(41.61, 1.25, 40.16, 2.2, 39.86, 3.69);
        context.bezierCurveTo(39.64, 4.72, 40.05, 5.72, 40.81, 6.35);
        context.lineTo(37.63, 21.84);
        context.lineTo(40.15, 22.36);
        context.lineTo(43.34, 6.85);
        context.bezierCurveTo(44.27, 6.57, 45.02, 5.81, 45.23, 4.79);
        context.bezierCurveTo(45.54, 3.31, 44.58, 1.86, 43.1, 1.55);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2792 Drawing
        context.beginPath();
        context.moveTo(55.94, 19.76);
        context.bezierCurveTo(55.94, 19.76, 57.16, 12.32, 54.35, 11.69);
        context.bezierCurveTo(51.54, 11.06, 48.99, 15.81, 48.99, 15.81);
        context.lineTo(55.94, 19.76);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2793 Drawing
        context.beginPath();
        context.moveTo(55.94, 19.76);
        context.bezierCurveTo(55.94, 19.76, 57.16, 12.32, 54.35, 11.69);
        context.bezierCurveTo(51.54, 11.06, 48.99, 15.81, 48.99, 15.81);
        context.lineTo(55.94, 19.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.17;
        context.stroke();


        //// Bezier 2794 Drawing
        context.beginPath();
        context.moveTo(53.74, 28.81);
        context.bezierCurveTo(53.74, 29.29, 54.13, 29.68, 54.62, 29.68);
        context.lineTo(62.51, 29.68);
        context.bezierCurveTo(63, 29.68, 63.39, 29.29, 63.39, 28.81);
        context.lineTo(63.39, 28.55);
        context.bezierCurveTo(63.39, 28.07, 63, 27.68, 62.51, 27.68);
        context.lineTo(54.62, 27.68);
        context.bezierCurveTo(54.13, 27.68, 53.74, 28.07, 53.74, 28.55);
        context.lineTo(53.74, 28.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2795 Drawing
        context.beginPath();
        context.moveTo(53.74, 28.81);
        context.bezierCurveTo(53.74, 29.29, 54.13, 29.68, 54.62, 29.68);
        context.lineTo(62.51, 29.68);
        context.bezierCurveTo(63, 29.68, 63.39, 29.29, 63.39, 28.81);
        context.lineTo(63.39, 28.55);
        context.bezierCurveTo(63.39, 28.07, 63, 27.68, 62.51, 27.68);
        context.lineTo(54.62, 27.68);
        context.bezierCurveTo(54.13, 27.68, 53.74, 28.07, 53.74, 28.55);
        context.lineTo(53.74, 28.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2796 Drawing
        context.beginPath();
        context.moveTo(33.15, 38.22);
        context.bezierCurveTo(35.31, 38.22, 37.47, 39.99, 37.47, 39.99);
        context.bezierCurveTo(44.19, 46.4, 51.6, 43.23, 51.6, 43.23);
        context.bezierCurveTo(51.6, 43.23, 61.71, 38.91, 59.78, 25.56);
        context.bezierCurveTo(57.85, 12.2, 33.15, 14.36, 33.15, 14.36);
        context.lineTo(33.14, 14.36);
        context.bezierCurveTo(33.14, 14.36, 8.43, 12.2, 6.5, 25.56);
        context.bezierCurveTo(4.57, 38.91, 14.69, 43.23, 14.69, 43.23);
        context.bezierCurveTo(14.69, 43.23, 22.1, 46.4, 28.82, 39.99);
        context.bezierCurveTo(28.82, 39.99, 30.98, 38.22, 33.14, 38.22);
        context.lineTo(33.15, 38.22);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Group 65
        context.save();

        //// Clip Clip 63

        context.beginPath();
        context.moveTo(33.15, 14.36);
        context.lineTo(33.14, 14.36);
        context.bezierCurveTo(33.14, 14.36, 8.43, 12.2, 6.5, 25.56);
        context.lineTo(6.5, 25.56);
        context.bezierCurveTo(4.57, 38.91, 14.69, 43.23, 14.69, 43.23);
        context.lineTo(14.69, 43.23);
        context.bezierCurveTo(14.69, 43.23, 22.1, 46.4, 28.82, 39.99);
        context.lineTo(28.82, 39.99);
        context.bezierCurveTo(28.82, 39.99, 30.98, 38.21, 33.14, 38.21);
        context.lineTo(33.14, 38.21);
        context.lineTo(33.15, 38.21);
        context.bezierCurveTo(35.31, 38.21, 37.47, 39.99, 37.47, 39.99);
        context.lineTo(37.47, 39.99);
        context.bezierCurveTo(44.18, 46.4, 51.6, 43.23, 51.6, 43.23);
        context.lineTo(51.6, 43.23);
        context.bezierCurveTo(51.6, 43.23, 61.71, 38.91, 59.78, 25.56);
        context.lineTo(59.78, 25.56);
        context.bezierCurveTo(58.32, 15.47, 43.86, 14.23, 36.88, 14.23);
        context.lineTo(36.88, 14.23);
        context.bezierCurveTo(34.62, 14.23, 33.15, 14.36, 33.15, 14.36);
        context.closePath();
        context.clip();



        //// Bezier 2797 Drawing
        context.beginPath();
        context.moveTo(3.71, 31.36);
        context.bezierCurveTo(3.71, 31.36, 7.15, 24.74, 11.64, 30.3);
        context.bezierCurveTo(16.14, 35.86, 9.28, 36.45, 6.79, 37.04);
        context.bezierCurveTo(5.06, 37.45, 2.4, 39.75, 2.7, 34.91);
        context.bezierCurveTo(3.01, 30.06, 3.71, 31.36, 3.71, 31.36);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2798 Drawing
        context.beginPath();
        context.moveTo(9.41, 16.18);
        context.bezierCurveTo(9.41, 16.18, 7.16, 17.6, 7.16, 19.02);
        context.bezierCurveTo(7.16, 20.44, 7.4, 21.03, 8.93, 21.86);
        context.bezierCurveTo(10.47, 22.69, 11.42, 23.76, 13.55, 22.93);
        context.bezierCurveTo(15.68, 22.1, 16.62, 22.69, 17.1, 20.8);
        context.bezierCurveTo(17.57, 18.91, 17.93, 19.38, 18.28, 18.55);
        context.bezierCurveTo(18.64, 17.72, 20.05, 17.25, 18.4, 16.3);
        context.bezierCurveTo(16.74, 15.36, 16.03, 15.24, 15.56, 15.36);
        context.bezierCurveTo(15.09, 15.47, 10.12, 16.3, 9.41, 16.18);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2799 Drawing
        context.beginPath();
        context.moveTo(28.09, 15.06);
        context.bezierCurveTo(27.38, 15.65, 26.44, 16.12, 26.32, 17.19);
        context.bezierCurveTo(26.2, 18.25, 26.55, 20.74, 27.38, 21.21);
        context.bezierCurveTo(28.21, 21.68, 29.39, 22.63, 30.81, 22.28);
        context.bezierCurveTo(32.23, 21.92, 32.94, 21.45, 33.41, 20.86);
        context.bezierCurveTo(33.89, 20.26, 33.41, 19.44, 34.72, 19.32);
        context.bezierCurveTo(36.02, 19.2, 36.96, 19.67, 37.79, 19.32);
        context.bezierCurveTo(38.62, 18.96, 39.21, 18.61, 39.21, 17.54);
        context.bezierCurveTo(39.21, 16.48, 38.98, 15.77, 38.98, 15.77);
        context.lineTo(37.56, 13.88);
        context.lineTo(30.34, 13.05);
        context.lineTo(28.09, 15.06);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2800 Drawing
        context.beginPath();
        context.moveTo(48.29, 17.73);
        context.bezierCurveTo(48.29, 17.73, 47.46, 19.03, 47.46, 19.74);
        context.bezierCurveTo(47.46, 20.45, 47.11, 21.04, 48.29, 21.75);
        context.bezierCurveTo(49.48, 22.46, 50.42, 23.05, 52.2, 22.82);
        context.bezierCurveTo(53.97, 22.58, 55.63, 21.75, 55.63, 21.75);
        context.lineTo(57.4, 20.21);
        context.bezierCurveTo(57.4, 20.21, 59.65, 20.21, 58.58, 16.55);
        context.lineTo(57.52, 12.88);
        context.lineTo(53.97, 11.34);
        context.lineTo(50.89, 11.34);
        context.lineTo(48.65, 11.81);
        context.bezierCurveTo(48.65, 11.81, 46.99, 13.47, 47.23, 14.42);
        context.bezierCurveTo(47.46, 15.36, 47.58, 15.48, 47.94, 16.07);
        context.bezierCurveTo(48.29, 16.67, 48.29, 17.26, 48.29, 17.73);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2801 Drawing
        context.beginPath();
        context.moveTo(54.34, 31.41);
        context.bezierCurveTo(54.34, 31.41, 52.09, 32.83, 52.09, 34.25);
        context.bezierCurveTo(52.09, 35.67, 52.33, 36.26, 53.86, 37.08);
        context.bezierCurveTo(55.4, 37.91, 56.35, 38.98, 58.48, 38.15);
        context.bezierCurveTo(60.61, 37.32, 61.55, 37.91, 62.03, 36.02);
        context.bezierCurveTo(62.5, 34.13, 62.86, 34.6, 63.21, 33.77);
        context.bezierCurveTo(63.57, 32.94, 64.98, 32.47, 63.33, 31.52);
        context.bezierCurveTo(61.67, 30.58, 60.96, 30.46, 60.49, 30.58);
        context.bezierCurveTo(60.02, 30.7, 55.05, 31.52, 54.34, 31.41);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        context.restore();



        //// Bezier 2803 Drawing
        context.beginPath();
        context.moveTo(33.15, 38.22);
        context.bezierCurveTo(35.31, 38.22, 37.47, 39.99, 37.47, 39.99);
        context.bezierCurveTo(44.19, 46.4, 51.6, 43.23, 51.6, 43.23);
        context.bezierCurveTo(51.6, 43.23, 61.71, 38.91, 59.78, 25.56);
        context.bezierCurveTo(57.85, 12.2, 33.15, 14.36, 33.15, 14.36);
        context.lineTo(33.14, 14.36);
        context.bezierCurveTo(33.14, 14.36, 8.43, 12.2, 6.5, 25.56);
        context.bezierCurveTo(4.57, 38.91, 14.69, 43.23, 14.69, 43.23);
        context.bezierCurveTo(14.69, 43.23, 22.1, 46.4, 28.82, 39.99);
        context.bezierCurveTo(28.82, 39.99, 30.98, 38.22, 33.14, 38.22);
        context.lineTo(33.15, 38.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2804 Drawing
        context.beginPath();
        context.moveTo(20.99, 23.28);
        context.bezierCurveTo(24.7, 24.59, 25.95, 30.46, 25.99, 30.51);
        context.bezierCurveTo(26.07, 30.6, 21.26, 34.31, 17.55, 33);
        context.bezierCurveTo(13.84, 31.68, 12.55, 25.82, 12.55, 25.76);
        context.bezierCurveTo(12.57, 25.64, 17.28, 21.96, 20.99, 23.28);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2805 Drawing
        context.beginPath();
        context.moveTo(44.3, 23.28);
        context.bezierCurveTo(40.59, 24.59, 39.34, 30.46, 39.3, 30.51);
        context.bezierCurveTo(39.22, 30.6, 44.03, 34.31, 47.74, 33);
        context.bezierCurveTo(51.45, 31.68, 52.75, 25.82, 52.74, 25.76);
        context.bezierCurveTo(52.72, 25.64, 48.01, 21.96, 44.3, 23.28);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Oval 630 Drawing
        oval(context, 16.45, 25.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 631 Drawing
        oval(context, 17.7, 26.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 632 Drawing
        oval(context, 43.3, 25.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 633 Drawing
        oval(context, 44.55, 26.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2806 Drawing
        context.beginPath();
        context.moveTo(36.51, 31.67);
        context.bezierCurveTo(35.59, 29.89, 32.42, 30.2, 32.42, 30.2);
        context.bezierCurveTo(32.42, 30.2, 29.26, 29.89, 28.33, 31.67);
        context.bezierCurveTo(27.4, 33.44, 30.03, 33.29, 30.03, 33.29);
        context.bezierCurveTo(30.9, 33.35, 30.95, 32.98, 30.88, 34.47);
        context.bezierCurveTo(30.8, 35.95, 32.42, 35.52, 32.42, 35.52);
        context.bezierCurveTo(32.42, 35.52, 34.04, 35.95, 33.97, 34.47);
        context.bezierCurveTo(33.89, 32.98, 33.94, 33.35, 34.81, 33.29);
        context.bezierCurveTo(34.81, 33.29, 37.44, 33.44, 36.51, 31.67);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawLionMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 80), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 80);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';

        //// Bezier 2666 Drawing
        context.beginPath();
        context.moveTo(57.23, 57.76);
        context.bezierCurveTo(54.52, 56.41, 55.8, 56.67, 56.4, 55.6);
        context.bezierCurveTo(54, 56.36, 55.87, 47.45, 53.24, 47.46);
        context.bezierCurveTo(53.21, 47.46, 41.13, 46.13, 41.1, 46.12);
        context.bezierCurveTo(37.16, 46.11, 34.41, 51.78, 33.45, 47.95);
        context.bezierCurveTo(32.14, 42.69, 30.79, 57.27, 29.13, 58.1);
        context.lineTo(29.3, 58.1);
        context.bezierCurveTo(26.77, 58.53, 22.82, 73.39, 18.49, 76.89);
        context.bezierCurveTo(15.16, 69.74, 11.72, 65.42, 11.72, 65.42);
        context.lineTo(8.91, 70.16);
        context.bezierCurveTo(2.44, 57.11, 7.6, 48.88, 7.6, 48.88);
        context.lineTo(2.72, 52.2);
        context.bezierCurveTo(0.19, 33.41, 7.88, 30.34, 7.88, 30.34);
        context.bezierCurveTo(2.82, 30.09, 2.16, 28.93, 2.16, 28.93);
        context.bezierCurveTo(6.85, 16.62, 16.78, 16.46, 16.78, 16.46);
        context.bezierCurveTo(13.5, 14.71, 8.91, 16.21, 8.91, 16.21);
        context.bezierCurveTo(17.26, 1.47, 35.4, 9.04, 37.62, 10.03);
        context.bezierCurveTo(37.37, 9.36, 35.69, 5.25, 31.47, 3.57);
        context.bezierCurveTo(31.47, 3.57, 45.06, -3.41, 54.06, 9.8);
        context.bezierCurveTo(54.06, 9.8, 68.4, 0.49, 77.31, 16.21);
        context.bezierCurveTo(77.31, 16.21, 72.71, 14.71, 69.43, 16.46);
        context.bezierCurveTo(69.43, 16.46, 79.37, 16.62, 84.06, 28.93);
        context.bezierCurveTo(84.06, 28.93, 83.4, 30.09, 78.34, 30.34);
        context.bezierCurveTo(78.34, 30.34, 86.02, 33.41, 83.49, 52.2);
        context.lineTo(78.62, 48.88);
        context.bezierCurveTo(78.62, 48.88, 83.77, 57.11, 77.31, 70.16);
        context.lineTo(74.5, 65.42);
        context.bezierCurveTo(74.5, 65.42, 71.53, 66.41, 65.04, 77.22);
        context.lineTo(57.23, 57.76);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2667 Drawing
        context.beginPath();
        context.moveTo(57.23, 57.76);
        context.bezierCurveTo(54.52, 56.41, 55.8, 56.67, 56.4, 55.6);
        context.bezierCurveTo(54, 56.36, 55.87, 47.45, 53.24, 47.46);
        context.bezierCurveTo(53.21, 47.46, 41.13, 46.13, 41.1, 46.12);
        context.bezierCurveTo(37.16, 46.11, 34.41, 51.78, 33.45, 47.95);
        context.bezierCurveTo(32.14, 42.69, 30.79, 57.27, 29.13, 58.1);
        context.lineTo(29.3, 58.1);
        context.bezierCurveTo(26.77, 58.53, 22.82, 73.39, 18.49, 76.89);
        context.bezierCurveTo(15.16, 69.74, 11.72, 65.42, 11.72, 65.42);
        context.lineTo(8.91, 70.16);
        context.bezierCurveTo(2.44, 57.11, 7.6, 48.88, 7.6, 48.88);
        context.lineTo(2.72, 52.2);
        context.bezierCurveTo(0.19, 33.41, 7.88, 30.34, 7.88, 30.34);
        context.bezierCurveTo(2.82, 30.09, 2.16, 28.93, 2.16, 28.93);
        context.bezierCurveTo(6.85, 16.62, 16.78, 16.46, 16.78, 16.46);
        context.bezierCurveTo(13.5, 14.71, 8.91, 16.21, 8.91, 16.21);
        context.bezierCurveTo(17.26, 1.47, 35.4, 9.04, 37.62, 10.03);
        context.bezierCurveTo(37.37, 9.36, 35.69, 5.25, 31.47, 3.57);
        context.bezierCurveTo(31.47, 3.57, 45.06, -3.41, 54.06, 9.8);
        context.bezierCurveTo(54.06, 9.8, 68.4, 0.49, 77.31, 16.21);
        context.bezierCurveTo(77.31, 16.21, 72.71, 14.71, 69.43, 16.46);
        context.bezierCurveTo(69.43, 16.46, 79.37, 16.62, 84.06, 28.93);
        context.bezierCurveTo(84.06, 28.93, 83.4, 30.09, 78.34, 30.34);
        context.bezierCurveTo(78.34, 30.34, 86.02, 33.41, 83.49, 52.2);
        context.lineTo(78.62, 48.88);
        context.bezierCurveTo(78.62, 48.88, 83.77, 57.11, 77.31, 70.16);
        context.lineTo(74.5, 65.42);
        context.bezierCurveTo(74.5, 65.42, 71.53, 66.41, 65.04, 77.22);
        context.lineTo(57.23, 57.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.06;
        context.stroke();


        //// Bezier 2668 Drawing
        context.beginPath();
        context.moveTo(22.26, 42.81);
        context.bezierCurveTo(22.26, 43.29, 21.86, 43.68, 21.38, 43.68);
        context.lineTo(13.48, 43.68);
        context.bezierCurveTo(13, 43.68, 12.61, 43.29, 12.61, 42.81);
        context.lineTo(12.61, 42.55);
        context.bezierCurveTo(12.61, 42.07, 13, 41.68, 13.48, 41.68);
        context.lineTo(21.38, 41.68);
        context.bezierCurveTo(21.86, 41.68, 22.26, 42.07, 22.26, 42.55);
        context.lineTo(22.26, 42.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2669 Drawing
        context.beginPath();
        context.moveTo(22.26, 42.81);
        context.bezierCurveTo(22.26, 43.29, 21.86, 43.68, 21.38, 43.68);
        context.lineTo(13.48, 43.68);
        context.bezierCurveTo(13, 43.68, 12.61, 43.29, 12.61, 42.81);
        context.lineTo(12.61, 42.55);
        context.bezierCurveTo(12.61, 42.07, 13, 41.68, 13.48, 41.68);
        context.lineTo(21.38, 41.68);
        context.bezierCurveTo(21.86, 41.68, 22.26, 42.07, 22.26, 42.55);
        context.lineTo(22.26, 42.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2670 Drawing
        context.beginPath();
        context.moveTo(63.46, 42.81);
        context.bezierCurveTo(63.46, 43.29, 63.85, 43.68, 64.34, 43.68);
        context.lineTo(72.23, 43.68);
        context.bezierCurveTo(72.72, 43.68, 73.11, 43.29, 73.11, 42.81);
        context.lineTo(73.11, 42.55);
        context.bezierCurveTo(73.11, 42.07, 72.72, 41.68, 72.23, 41.68);
        context.lineTo(64.34, 41.68);
        context.bezierCurveTo(63.85, 41.68, 63.46, 42.07, 63.46, 42.55);
        context.lineTo(63.46, 42.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2671 Drawing
        context.beginPath();
        context.moveTo(63.46, 42.81);
        context.bezierCurveTo(63.46, 43.29, 63.85, 43.68, 64.34, 43.68);
        context.lineTo(72.23, 43.68);
        context.bezierCurveTo(72.72, 43.68, 73.11, 43.29, 73.11, 42.81);
        context.lineTo(73.11, 42.55);
        context.bezierCurveTo(73.11, 42.07, 72.72, 41.68, 72.23, 41.68);
        context.lineTo(64.34, 41.68);
        context.bezierCurveTo(63.85, 41.68, 63.46, 42.07, 63.46, 42.55);
        context.lineTo(63.46, 42.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2672 Drawing
        context.beginPath();
        context.moveTo(19.82, 35.98);
        context.bezierCurveTo(19.82, 35.98, 9.9, 27.27, 19.61, 22.67);
        context.bezierCurveTo(28.55, 18.44, 28.47, 31.08, 28.47, 31.08);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2673 Drawing
        context.beginPath();
        context.moveTo(20.11, 32.82);
        context.bezierCurveTo(20.11, 32.82, 18.89, 27.34, 21.7, 26.88);
        context.bezierCurveTo(24.51, 26.42, 27.06, 28.92, 27.06, 28.92);
        context.lineTo(20.11, 32.82);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2674 Drawing
        context.beginPath();
        context.moveTo(20.11, 32.82);
        context.bezierCurveTo(20.11, 32.82, 18.89, 27.34, 21.7, 26.88);
        context.bezierCurveTo(24.51, 26.42, 27.06, 28.92, 27.06, 28.92);
        context.lineTo(20.11, 32.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2675 Drawing
        context.beginPath();
        context.moveTo(64.71, 34.57);
        context.bezierCurveTo(64.71, 34.57, 74.27, 27.34, 64.77, 22.67);
        context.bezierCurveTo(54.61, 17.68, 55.23, 31.41, 55.23, 31.41);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2676 Drawing
        context.beginPath();
        context.moveTo(64.27, 32.82);
        context.bezierCurveTo(64.27, 32.82, 65.49, 27.34, 62.68, 26.88);
        context.bezierCurveTo(59.87, 26.42, 57.33, 28.92, 57.33, 28.92);
        context.lineTo(64.27, 32.82);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2677 Drawing
        context.beginPath();
        context.moveTo(64.27, 32.82);
        context.bezierCurveTo(64.27, 32.82, 65.49, 27.34, 62.68, 26.88);
        context.bezierCurveTo(59.87, 26.42, 57.33, 28.92, 57.33, 28.92);
        context.lineTo(64.27, 32.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2678 Drawing
        context.beginPath();
        context.moveTo(42.87, 52.22);
        context.bezierCurveTo(45.03, 52.22, 47.19, 53.99, 47.19, 53.99);
        context.bezierCurveTo(53.91, 60.4, 61.32, 57.23, 61.32, 57.23);
        context.bezierCurveTo(61.32, 57.23, 71.43, 52.91, 69.5, 39.56);
        context.bezierCurveTo(67.57, 26.2, 42.87, 28.36, 42.87, 28.36);
        context.lineTo(42.86, 28.36);
        context.bezierCurveTo(42.86, 28.36, 18.15, 26.2, 16.22, 39.56);
        context.bezierCurveTo(14.29, 52.91, 24.41, 57.23, 24.41, 57.23);
        context.bezierCurveTo(24.41, 57.23, 31.82, 60.4, 38.54, 53.99);
        context.bezierCurveTo(38.54, 53.99, 40.7, 52.22, 42.86, 52.22);
        context.lineTo(42.87, 52.22);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2679 Drawing
        context.beginPath();
        context.moveTo(42.87, 52.22);
        context.bezierCurveTo(45.03, 52.22, 47.19, 53.99, 47.19, 53.99);
        context.bezierCurveTo(53.91, 60.4, 61.32, 57.23, 61.32, 57.23);
        context.bezierCurveTo(61.32, 57.23, 71.43, 52.91, 69.5, 39.56);
        context.bezierCurveTo(67.57, 26.2, 42.87, 28.36, 42.87, 28.36);
        context.lineTo(42.86, 28.36);
        context.bezierCurveTo(42.86, 28.36, 18.15, 26.2, 16.22, 39.56);
        context.bezierCurveTo(14.29, 52.91, 24.41, 57.23, 24.41, 57.23);
        context.bezierCurveTo(24.41, 57.23, 31.82, 60.4, 38.54, 53.99);
        context.bezierCurveTo(38.54, 53.99, 40.7, 52.22, 42.86, 52.22);
        context.lineTo(42.87, 52.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 614 Drawing
        oval(context, 26.15, 39.44, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 615 Drawing
        oval(context, 27.4, 40.69, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 616 Drawing
        oval(context, 53.05, 39.44, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 617 Drawing
        oval(context, 54.3, 40.69, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2680 Drawing
        context.beginPath();
        context.moveTo(46.63, 45.67);
        context.bezierCurveTo(45.7, 43.89, 42.53, 44.2, 42.53, 44.2);
        context.bezierCurveTo(42.53, 44.2, 39.37, 43.89, 38.44, 45.67);
        context.bezierCurveTo(37.51, 47.44, 40.14, 47.29, 40.14, 47.29);
        context.bezierCurveTo(41.02, 47.35, 41.07, 46.98, 40.99, 48.47);
        context.bezierCurveTo(40.91, 49.95, 42.53, 49.53, 42.53, 49.53);
        context.bezierCurveTo(42.53, 49.53, 44.15, 49.95, 44.08, 48.47);
        context.bezierCurveTo(44, 46.98, 44.05, 47.35, 44.93, 47.29);
        context.bezierCurveTo(44.93, 47.29, 47.56, 47.44, 46.63, 45.67);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2681 Drawing
        context.beginPath();
        context.moveTo(25.74, 46.8);
        context.bezierCurveTo(25.74, 46.8, 19.22, 43.96, 13.84, 47.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2682 Drawing
        context.beginPath();
        context.moveTo(26.17, 49.21);
        context.bezierCurveTo(26.17, 49.21, 19.26, 48.72, 15.35, 53.65);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2683 Drawing
        context.beginPath();
        context.moveTo(26.9, 51.09);
        context.bezierCurveTo(26.9, 51.09, 21.37, 53.24, 20.03, 59.39);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2684 Drawing
        context.beginPath();
        context.moveTo(62.99, 46.8);
        context.bezierCurveTo(62.99, 46.8, 69.51, 43.96, 74.9, 47.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2685 Drawing
        context.beginPath();
        context.moveTo(62.56, 49.21);
        context.bezierCurveTo(62.56, 49.21, 69.47, 48.72, 73.38, 53.65);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2686 Drawing
        context.beginPath();
        context.moveTo(61.84, 51.09);
        context.bezierCurveTo(61.84, 51.09, 67.36, 53.24, 68.7, 59.39);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawTigerMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 64, 39), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 64, resizedFrame.h / 39);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor11 = 'rgba(255, 147, 3, 1)';
        var fillColor63 = 'rgba(25, 26, 26, 1)';

        //// Bezier 2687 Drawing
        context.beginPath();
        context.moveTo(57.04, 16.64);
        context.bezierCurveTo(57.04, 16.64, 60.38, 5.6, 57.04, 2.2);
        context.bezierCurveTo(57.04, 2.2, 45.63, 3.51, 44.19, 8.76);
        context.lineTo(57.04, 16.64);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2688 Drawing
        context.beginPath();
        context.moveTo(57.04, 16.64);
        context.bezierCurveTo(57.04, 16.64, 60.38, 5.6, 57.04, 2.2);
        context.bezierCurveTo(57.04, 2.2, 45.63, 3.51, 44.19, 8.76);
        context.lineTo(57.04, 16.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2689 Drawing
        context.beginPath();
        context.moveTo(6.21, 16.64);
        context.bezierCurveTo(6.21, 16.64, 2.88, 5.6, 6.21, 2.2);
        context.bezierCurveTo(6.21, 2.2, 17.63, 3.51, 19.07, 8.76);
        context.lineTo(6.21, 16.64);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2690 Drawing
        context.beginPath();
        context.moveTo(6.21, 16.64);
        context.bezierCurveTo(6.21, 16.64, 2.88, 5.6, 6.21, 2.2);
        context.bezierCurveTo(6.21, 2.2, 17.63, 3.51, 19.07, 8.76);
        context.lineTo(6.21, 16.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2691 Drawing
        context.beginPath();
        context.moveTo(8.02, 12.71);
        context.lineTo(8.85, 6.52);
        context.lineTo(15.33, 9.62);
        context.lineTo(8.02, 12.71);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2692 Drawing
        context.beginPath();
        context.moveTo(8.02, 12.71);
        context.lineTo(8.85, 6.52);
        context.lineTo(15.33, 9.62);
        context.lineTo(8.02, 12.71);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2693 Drawing
        context.beginPath();
        context.moveTo(11.04, 21.81);
        context.bezierCurveTo(11.04, 22.29, 10.64, 22.68, 10.16, 22.68);
        context.lineTo(2.26, 22.68);
        context.bezierCurveTo(1.78, 22.68, 1.39, 22.29, 1.39, 21.81);
        context.lineTo(1.39, 21.55);
        context.bezierCurveTo(1.39, 21.07, 1.78, 20.68, 2.26, 20.68);
        context.lineTo(10.16, 20.68);
        context.bezierCurveTo(10.64, 20.68, 11.04, 21.07, 11.04, 21.55);
        context.lineTo(11.04, 21.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2694 Drawing
        context.beginPath();
        context.moveTo(11.04, 21.81);
        context.bezierCurveTo(11.04, 22.29, 10.64, 22.68, 10.16, 22.68);
        context.lineTo(2.26, 22.68);
        context.bezierCurveTo(1.78, 22.68, 1.39, 22.29, 1.39, 21.81);
        context.lineTo(1.39, 21.55);
        context.bezierCurveTo(1.39, 21.07, 1.78, 20.68, 2.26, 20.68);
        context.lineTo(10.16, 20.68);
        context.bezierCurveTo(10.64, 20.68, 11.04, 21.07, 11.04, 21.55);
        context.lineTo(11.04, 21.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2695 Drawing
        context.beginPath();
        context.moveTo(55.24, 12.71);
        context.lineTo(54.41, 6.52);
        context.lineTo(47.92, 9.62);
        context.lineTo(55.24, 12.71);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2696 Drawing
        context.beginPath();
        context.moveTo(55.24, 12.71);
        context.lineTo(54.41, 6.52);
        context.lineTo(47.92, 9.62);
        context.lineTo(55.24, 12.71);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2697 Drawing
        context.beginPath();
        context.moveTo(52.24, 21.81);
        context.bezierCurveTo(52.24, 22.29, 52.64, 22.68, 53.12, 22.68);
        context.lineTo(61.02, 22.68);
        context.bezierCurveTo(61.5, 22.68, 61.89, 22.29, 61.89, 21.81);
        context.lineTo(61.89, 21.55);
        context.bezierCurveTo(61.89, 21.07, 61.5, 20.68, 61.02, 20.68);
        context.lineTo(53.12, 20.68);
        context.bezierCurveTo(52.64, 20.68, 52.24, 21.07, 52.24, 21.55);
        context.lineTo(52.24, 21.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2698 Drawing
        context.beginPath();
        context.moveTo(52.24, 21.81);
        context.bezierCurveTo(52.24, 22.29, 52.64, 22.68, 53.12, 22.68);
        context.lineTo(61.02, 22.68);
        context.bezierCurveTo(61.5, 22.68, 61.89, 22.29, 61.89, 21.81);
        context.lineTo(61.89, 21.55);
        context.bezierCurveTo(61.89, 21.07, 61.5, 20.68, 61.02, 20.68);
        context.lineTo(53.12, 20.68);
        context.bezierCurveTo(52.64, 20.68, 52.24, 21.07, 52.24, 21.55);
        context.lineTo(52.24, 21.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2699 Drawing
        context.beginPath();
        context.moveTo(31.65, 31.22);
        context.bezierCurveTo(33.81, 31.22, 35.97, 32.99, 35.97, 32.99);
        context.bezierCurveTo(42.69, 39.4, 50.1, 36.23, 50.1, 36.23);
        context.bezierCurveTo(50.1, 36.23, 60.21, 31.91, 58.28, 18.56);
        context.bezierCurveTo(56.35, 5.2, 31.65, 7.36, 31.65, 7.36);
        context.lineTo(31.64, 7.36);
        context.bezierCurveTo(31.64, 7.36, 6.94, 5.2, 5.01, 18.56);
        context.bezierCurveTo(3.08, 31.91, 13.19, 36.23, 13.19, 36.23);
        context.bezierCurveTo(13.19, 36.23, 20.6, 39.4, 27.32, 32.99);
        context.bezierCurveTo(27.32, 32.99, 29.48, 31.22, 31.64, 31.22);
        context.lineTo(31.65, 31.22);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Bezier 2700 Drawing
        context.beginPath();
        context.moveTo(31.65, 31.22);
        context.bezierCurveTo(33.81, 31.22, 35.97, 32.99, 35.97, 32.99);
        context.bezierCurveTo(42.69, 39.4, 50.1, 36.23, 50.1, 36.23);
        context.bezierCurveTo(50.1, 36.23, 60.21, 31.91, 58.28, 18.56);
        context.bezierCurveTo(56.35, 5.2, 31.65, 7.36, 31.65, 7.36);
        context.lineTo(31.64, 7.36);
        context.bezierCurveTo(31.64, 7.36, 6.94, 5.2, 5.01, 18.56);
        context.bezierCurveTo(3.08, 31.91, 13.19, 36.23, 13.19, 36.23);
        context.bezierCurveTo(13.19, 36.23, 20.6, 39.4, 27.32, 32.99);
        context.bezierCurveTo(27.32, 32.99, 29.48, 31.22, 31.64, 31.22);
        context.lineTo(31.65, 31.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 618 Drawing
        oval(context, 14.95, 18.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 619 Drawing
        oval(context, 16.2, 19.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 620 Drawing
        oval(context, 41.8, 18.43, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 621 Drawing
        oval(context, 43.05, 19.68, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Group 60
        context.save();

        //// Clip Clip 58

        context.beginPath();
        context.moveTo(31.65, 7.36);
        context.lineTo(31.64, 7.36);
        context.bezierCurveTo(31.64, 7.36, 6.94, 5.2, 5.01, 18.56);
        context.lineTo(5.01, 18.56);
        context.bezierCurveTo(3.08, 31.91, 13.19, 36.23, 13.19, 36.23);
        context.lineTo(13.19, 36.23);
        context.bezierCurveTo(13.19, 36.23, 20.6, 39.4, 27.32, 32.99);
        context.lineTo(27.32, 32.99);
        context.bezierCurveTo(27.32, 32.99, 29.48, 31.21, 31.64, 31.21);
        context.lineTo(31.64, 31.21);
        context.lineTo(31.65, 31.21);
        context.bezierCurveTo(33.81, 31.21, 35.97, 32.99, 35.97, 32.99);
        context.lineTo(35.97, 32.99);
        context.bezierCurveTo(42.69, 39.4, 50.1, 36.23, 50.1, 36.23);
        context.lineTo(50.1, 36.23);
        context.bezierCurveTo(50.1, 36.23, 60.21, 31.91, 58.28, 18.56);
        context.lineTo(58.28, 18.56);
        context.bezierCurveTo(56.82, 8.47, 42.36, 7.23, 35.38, 7.23);
        context.lineTo(35.38, 7.23);
        context.bezierCurveTo(33.12, 7.23, 31.65, 7.36, 31.65, 7.36);
        context.closePath();
        context.clip();



        //// Bezier 2701 Drawing
        context.beginPath();
        context.moveTo(31.52, 25.91);
        context.lineTo(30.91, 25.91);
        context.bezierCurveTo(28.59, 25.91, 26.71, 24.03, 26.71, 21.71);
        context.lineTo(26.71, 3.95);
        context.bezierCurveTo(26.71, 1.63, 28.59, -0.25, 30.91, -0.25);
        context.lineTo(31.52, -0.25);
        context.bezierCurveTo(33.84, -0.25, 35.73, 1.63, 35.73, 3.95);
        context.lineTo(35.73, 21.71);
        context.bezierCurveTo(35.73, 24.03, 33.84, 25.91, 31.52, 25.91);
        context.closePath();
        context.fillStyle = fillColor11;
        context.fill();


        //// Bezier 2702 Drawing
        context.beginPath();
        context.moveTo(31.52, 25.91);
        context.lineTo(30.91, 25.91);
        context.bezierCurveTo(28.59, 25.91, 26.71, 24.03, 26.71, 21.71);
        context.lineTo(26.71, 3.95);
        context.bezierCurveTo(26.71, 1.63, 28.59, -0.25, 30.91, -0.25);
        context.lineTo(31.52, -0.25);
        context.bezierCurveTo(33.84, -0.25, 35.73, 1.63, 35.73, 3.95);
        context.lineTo(35.73, 21.71);
        context.bezierCurveTo(35.73, 24.03, 33.84, 25.91, 31.52, 25.91);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2703 Drawing
        context.beginPath();
        context.moveTo(20.4, 4.42);
        context.bezierCurveTo(20.4, 4.42, 19.9, 11.57, 21.4, 15.98);
        context.bezierCurveTo(21.4, 15.98, 14.5, 11.98, 13.34, 4.92);
        context.lineTo(20.4, 4.42);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2704 Drawing
        context.beginPath();
        context.moveTo(20.4, 4.42);
        context.bezierCurveTo(20.4, 4.42, 19.9, 11.57, 21.4, 15.98);
        context.bezierCurveTo(21.4, 15.98, 14.5, 11.98, 13.34, 4.92);
        context.lineTo(20.4, 4.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2705 Drawing
        context.beginPath();
        context.moveTo(4.28, 17.32);
        context.lineTo(11.4, 22.73);
        context.lineTo(3.93, 23.01);
        context.lineTo(4.28, 17.32);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2706 Drawing
        context.beginPath();
        context.moveTo(4.28, 17.32);
        context.lineTo(11.4, 22.73);
        context.lineTo(3.93, 23.01);
        context.lineTo(4.28, 17.32);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2707 Drawing
        context.beginPath();
        context.moveTo(6.3, 32.43);
        context.lineTo(5.5, 27.23);
        context.lineTo(12.23, 27.87);
        context.lineTo(6.3, 32.43);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2708 Drawing
        context.beginPath();
        context.moveTo(6.3, 32.43);
        context.lineTo(5.5, 27.23);
        context.lineTo(12.23, 27.87);
        context.lineTo(6.3, 32.43);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2709 Drawing
        context.beginPath();
        context.moveTo(42.92, 4.42);
        context.bezierCurveTo(42.92, 4.42, 43.42, 11.57, 41.92, 15.98);
        context.bezierCurveTo(41.92, 15.98, 48.82, 11.98, 49.99, 4.92);
        context.lineTo(42.92, 4.42);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2710 Drawing
        context.beginPath();
        context.moveTo(42.92, 4.42);
        context.bezierCurveTo(42.92, 4.42, 43.42, 11.57, 41.92, 15.98);
        context.bezierCurveTo(41.92, 15.98, 48.82, 11.98, 49.99, 4.92);
        context.lineTo(42.92, 4.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2711 Drawing
        context.beginPath();
        context.moveTo(59.04, 17.32);
        context.lineTo(51.93, 22.73);
        context.lineTo(59.4, 23.01);
        context.lineTo(59.04, 17.32);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2712 Drawing
        context.beginPath();
        context.moveTo(59.04, 17.32);
        context.lineTo(51.93, 22.73);
        context.lineTo(59.4, 23.01);
        context.lineTo(59.04, 17.32);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2713 Drawing
        context.beginPath();
        context.moveTo(57.03, 32.43);
        context.lineTo(57.82, 27.23);
        context.lineTo(51.1, 27.87);
        context.lineTo(57.03, 32.43);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2714 Drawing
        context.beginPath();
        context.moveTo(57.03, 32.43);
        context.lineTo(57.82, 27.23);
        context.lineTo(51.1, 27.87);
        context.lineTo(57.03, 32.43);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        context.restore();



        //// Bezier 2716 Drawing
        context.beginPath();
        context.moveTo(31.65, 31.22);
        context.bezierCurveTo(33.81, 31.22, 35.97, 32.99, 35.97, 32.99);
        context.bezierCurveTo(42.69, 39.4, 50.1, 36.23, 50.1, 36.23);
        context.bezierCurveTo(50.1, 36.23, 60.21, 31.91, 58.28, 18.56);
        context.bezierCurveTo(56.35, 5.2, 31.65, 7.36, 31.65, 7.36);
        context.lineTo(31.64, 7.36);
        context.bezierCurveTo(31.64, 7.36, 6.94, 5.2, 5.01, 18.56);
        context.bezierCurveTo(3.08, 31.91, 13.19, 36.23, 13.19, 36.23);
        context.bezierCurveTo(13.19, 36.23, 20.6, 39.4, 27.32, 32.99);
        context.bezierCurveTo(27.32, 32.99, 29.48, 31.22, 31.64, 31.22);
        context.lineTo(31.65, 31.22);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2717 Drawing
        context.beginPath();
        context.moveTo(35.41, 24.67);
        context.bezierCurveTo(34.48, 22.89, 31.31, 23.2, 31.31, 23.2);
        context.bezierCurveTo(31.31, 23.2, 28.15, 22.89, 27.22, 24.67);
        context.bezierCurveTo(26.29, 26.44, 28.92, 26.29, 28.92, 26.29);
        context.bezierCurveTo(29.8, 26.35, 29.85, 25.98, 29.77, 27.47);
        context.bezierCurveTo(29.69, 28.95, 31.31, 28.52, 31.31, 28.52);
        context.bezierCurveTo(31.31, 28.52, 32.94, 28.95, 32.86, 27.47);
        context.bezierCurveTo(32.78, 25.98, 32.83, 26.35, 33.71, 26.29);
        context.bezierCurveTo(33.71, 26.29, 36.34, 26.44, 35.41, 24.67);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawReindeerMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 69, 51), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 69, resizedFrame.h / 51);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor34 = 'rgba(75, 57, 50, 1)';
        var fillColor52 = 'rgba(146, 100, 64, 1)';
        var fillColor65 = 'rgba(223, 197, 163, 1)';
        var fillColor66 = 'rgba(242, 216, 183, 1)';

        //// Bezier 2892 Drawing
        context.beginPath();
        context.moveTo(7.24, 29.24);
        context.bezierCurveTo(7.24, 29.24, 0.3, 20.98, 1.71, 16.86);
        context.bezierCurveTo(1.71, 16.86, 11.94, 13.61, 17.66, 20.82);
        context.lineTo(7.24, 29.24);
        context.closePath();
        context.fillStyle = fillColor52;
        context.fill();


        //// Bezier 2893 Drawing
        context.beginPath();
        context.moveTo(7.24, 29.24);
        context.bezierCurveTo(7.24, 29.24, 0.3, 20.98, 1.71, 16.86);
        context.bezierCurveTo(1.71, 16.86, 11.94, 13.61, 17.66, 20.82);
        context.lineTo(7.24, 29.24);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.02;
        context.stroke();


        //// Bezier 2894 Drawing
        context.beginPath();
        context.moveTo(8.2, 26.21);
        context.lineTo(5.5, 19.61);
        context.lineTo(12.96, 20.91);
        context.lineTo(8.2, 26.21);
        context.closePath();
        context.fillStyle = fillColor65;
        context.fill();


        //// Bezier 2895 Drawing
        context.beginPath();
        context.moveTo(8.2, 26.21);
        context.lineTo(5.5, 19.61);
        context.lineTo(12.96, 20.91);
        context.lineTo(8.2, 26.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.91;
        context.stroke();


        //// Bezier 2896 Drawing
        context.beginPath();
        context.moveTo(60.95, 29.24);
        context.bezierCurveTo(60.95, 29.24, 67.88, 20.98, 66.47, 16.86);
        context.bezierCurveTo(66.47, 16.86, 56.24, 13.61, 50.52, 20.82);
        context.lineTo(60.95, 29.24);
        context.closePath();
        context.fillStyle = fillColor52;
        context.fill();


        //// Bezier 2897 Drawing
        context.beginPath();
        context.moveTo(60.95, 29.24);
        context.bezierCurveTo(60.95, 29.24, 67.88, 20.98, 66.47, 16.86);
        context.bezierCurveTo(66.47, 16.86, 56.24, 13.61, 50.52, 20.82);
        context.lineTo(60.95, 29.24);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.02;
        context.stroke();


        //// Bezier 2898 Drawing
        context.beginPath();
        context.moveTo(59.98, 26.21);
        context.lineTo(62.68, 19.61);
        context.lineTo(55.22, 20.91);
        context.lineTo(59.98, 26.21);
        context.closePath();
        context.fillStyle = fillColor65;
        context.fill();


        //// Bezier 2899 Drawing
        context.beginPath();
        context.moveTo(59.98, 26.21);
        context.lineTo(62.68, 19.61);
        context.lineTo(55.22, 20.91);
        context.lineTo(59.98, 26.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.91;
        context.stroke();


        //// Bezier 2900 Drawing
        context.beginPath();
        context.moveTo(13.65, 32.53);
        context.bezierCurveTo(13.65, 33.01, 13.25, 33.41, 12.77, 33.41);
        context.lineTo(4.87, 33.41);
        context.bezierCurveTo(4.39, 33.41, 4, 33.01, 4, 32.53);
        context.lineTo(4, 32.28);
        context.bezierCurveTo(4, 31.79, 4.39, 31.4, 4.87, 31.4);
        context.lineTo(12.77, 31.4);
        context.bezierCurveTo(13.25, 31.4, 13.65, 31.79, 13.65, 32.28);
        context.lineTo(13.65, 32.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2901 Drawing
        context.beginPath();
        context.moveTo(13.65, 32.53);
        context.bezierCurveTo(13.65, 33.01, 13.25, 33.41, 12.77, 33.41);
        context.lineTo(4.87, 33.41);
        context.bezierCurveTo(4.39, 33.41, 4, 33.01, 4, 32.53);
        context.lineTo(4, 32.28);
        context.bezierCurveTo(4, 31.79, 4.39, 31.4, 4.87, 31.4);
        context.lineTo(12.77, 31.4);
        context.bezierCurveTo(13.25, 31.4, 13.65, 31.79, 13.65, 32.28);
        context.lineTo(13.65, 32.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2902 Drawing
        context.beginPath();
        context.moveTo(54.85, 32.53);
        context.bezierCurveTo(54.85, 33.01, 55.24, 33.41, 55.73, 33.41);
        context.lineTo(63.62, 33.41);
        context.bezierCurveTo(64.11, 33.41, 64.5, 33.01, 64.5, 32.53);
        context.lineTo(64.5, 32.28);
        context.bezierCurveTo(64.5, 31.79, 64.11, 31.4, 63.62, 31.4);
        context.lineTo(55.73, 31.4);
        context.bezierCurveTo(55.24, 31.4, 54.85, 31.79, 54.85, 32.28);
        context.lineTo(54.85, 32.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2903 Drawing
        context.beginPath();
        context.moveTo(54.85, 32.53);
        context.bezierCurveTo(54.85, 33.01, 55.24, 33.41, 55.73, 33.41);
        context.lineTo(63.62, 33.41);
        context.bezierCurveTo(64.11, 33.41, 64.5, 33.01, 64.5, 32.53);
        context.lineTo(64.5, 32.28);
        context.bezierCurveTo(64.5, 31.79, 64.11, 31.4, 63.62, 31.4);
        context.lineTo(55.73, 31.4);
        context.bezierCurveTo(55.24, 31.4, 54.85, 31.79, 54.85, 32.28);
        context.lineTo(54.85, 32.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2904 Drawing
        context.beginPath();
        context.moveTo(34.26, 41.94);
        context.bezierCurveTo(36.42, 41.94, 38.58, 43.71, 38.58, 43.71);
        context.bezierCurveTo(45.3, 50.12, 52.71, 46.96, 52.71, 46.96);
        context.bezierCurveTo(52.71, 46.96, 62.82, 42.63, 60.89, 29.28);
        context.bezierCurveTo(58.96, 15.92, 34.26, 18.08, 34.26, 18.08);
        context.lineTo(34.25, 18.08);
        context.bezierCurveTo(34.25, 18.08, 9.55, 15.92, 7.61, 29.28);
        context.bezierCurveTo(5.69, 42.63, 15.8, 46.96, 15.8, 46.96);
        context.bezierCurveTo(15.8, 46.96, 23.21, 50.12, 29.93, 43.71);
        context.bezierCurveTo(29.93, 43.71, 32.09, 41.94, 34.25, 41.94);
        context.lineTo(34.26, 41.94);
        context.closePath();
        context.fillStyle = fillColor66;
        context.fill();


        //// Bezier 2905 Drawing
        context.beginPath();
        context.moveTo(34.26, 41.94);
        context.bezierCurveTo(36.42, 41.94, 38.58, 43.71, 38.58, 43.71);
        context.bezierCurveTo(45.3, 50.12, 52.71, 46.96, 52.71, 46.96);
        context.bezierCurveTo(52.71, 46.96, 62.82, 42.63, 60.89, 29.28);
        context.bezierCurveTo(58.96, 15.92, 34.26, 18.08, 34.26, 18.08);
        context.lineTo(34.25, 18.08);
        context.bezierCurveTo(34.25, 18.08, 9.55, 15.92, 7.61, 29.28);
        context.bezierCurveTo(5.69, 42.63, 15.8, 46.96, 15.8, 46.96);
        context.bezierCurveTo(15.8, 46.96, 23.21, 50.12, 29.93, 43.71);
        context.bezierCurveTo(29.93, 43.71, 32.09, 41.94, 34.25, 41.94);
        context.lineTo(34.26, 41.94);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 664 Drawing
        oval(context, 17.55, 29.14, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 665 Drawing
        oval(context, 18.8, 30.39, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 666 Drawing
        oval(context, 44.4, 29.14, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 667 Drawing
        oval(context, 45.65, 30.39, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2906 Drawing
        context.beginPath();
        context.moveTo(27.64, 5.64);
        context.bezierCurveTo(26.83, 5.5, 26.05, 6.06, 25.92, 6.88);
        context.bezierCurveTo(25.91, 6.92, 25.33, 10.3, 22.9, 11.41);
        context.bezierCurveTo(21.77, 6.39, 22.38, 2.83, 22.39, 2.74);
        context.bezierCurveTo(22.54, 1.93, 22.01, 1.15, 21.2, 0.99);
        context.bezierCurveTo(20.39, 0.83, 19.6, 1.37, 19.44, 2.18);
        context.bezierCurveTo(19.4, 2.42, 18.72, 6.24, 19.87, 11.66);
        context.bezierCurveTo(16.86, 11.14, 15.76, 9.01, 15.7, 8.89);
        context.bezierCurveTo(15.35, 8.15, 14.46, 7.83, 13.72, 8.17);
        context.bezierCurveTo(12.96, 8.52, 12.64, 9.42, 12.99, 10.17);
        context.bezierCurveTo(13.08, 10.35, 15.09, 14.49, 20.7, 14.75);
        context.bezierCurveTo(21.33, 16.72, 22.21, 18.82, 23.44, 21);
        context.bezierCurveTo(23.71, 21.48, 24.22, 21.76, 24.74, 21.76);
        context.bezierCurveTo(24.99, 21.76, 25.25, 21.7, 25.48, 21.56);
        context.bezierCurveTo(26.2, 21.16, 26.45, 20.24, 26.05, 19.52);
        context.bezierCurveTo(25.03, 17.73, 24.29, 15.98, 23.73, 14.33);
        context.bezierCurveTo(27.05, 13.09, 28.54, 9.46, 28.88, 7.36);
        context.bezierCurveTo(29.01, 6.54, 28.46, 5.77, 27.64, 5.64);
        context.closePath();
        context.fillStyle = fillColor34;
        context.fill();


        //// Bezier 2907 Drawing
        context.beginPath();
        context.moveTo(27.64, 5.64);
        context.bezierCurveTo(26.83, 5.5, 26.05, 6.06, 25.92, 6.88);
        context.bezierCurveTo(25.91, 6.92, 25.33, 10.3, 22.9, 11.41);
        context.bezierCurveTo(21.77, 6.39, 22.38, 2.83, 22.39, 2.74);
        context.bezierCurveTo(22.54, 1.93, 22.01, 1.15, 21.2, 0.99);
        context.bezierCurveTo(20.39, 0.83, 19.6, 1.37, 19.44, 2.18);
        context.bezierCurveTo(19.4, 2.42, 18.72, 6.24, 19.87, 11.66);
        context.bezierCurveTo(16.86, 11.14, 15.76, 9.01, 15.7, 8.89);
        context.bezierCurveTo(15.35, 8.15, 14.46, 7.83, 13.72, 8.17);
        context.bezierCurveTo(12.96, 8.52, 12.64, 9.42, 12.99, 10.17);
        context.bezierCurveTo(13.08, 10.35, 15.09, 14.49, 20.7, 14.75);
        context.bezierCurveTo(21.33, 16.72, 22.21, 18.82, 23.44, 21);
        context.bezierCurveTo(23.71, 21.48, 24.22, 21.76, 24.74, 21.76);
        context.bezierCurveTo(24.99, 21.76, 25.25, 21.7, 25.48, 21.56);
        context.bezierCurveTo(26.2, 21.16, 26.45, 20.24, 26.05, 19.52);
        context.bezierCurveTo(25.03, 17.73, 24.29, 15.98, 23.73, 14.33);
        context.bezierCurveTo(27.05, 13.09, 28.54, 9.46, 28.88, 7.36);
        context.bezierCurveTo(29.01, 6.54, 28.46, 5.77, 27.64, 5.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2908 Drawing
        context.beginPath();
        context.moveTo(37.11, 5.64);
        context.bezierCurveTo(37.92, 5.5, 38.7, 6.06, 38.83, 6.88);
        context.bezierCurveTo(38.84, 6.92, 39.42, 10.3, 41.85, 11.41);
        context.bezierCurveTo(42.98, 6.39, 42.38, 2.83, 42.36, 2.74);
        context.bezierCurveTo(42.21, 1.93, 42.74, 1.15, 43.55, 0.99);
        context.bezierCurveTo(44.36, 0.83, 45.15, 1.37, 45.31, 2.18);
        context.bezierCurveTo(45.35, 2.42, 46.03, 6.24, 44.88, 11.66);
        context.bezierCurveTo(47.89, 11.14, 48.99, 9.01, 49.05, 8.89);
        context.bezierCurveTo(49.4, 8.15, 50.29, 7.83, 51.04, 8.17);
        context.bezierCurveTo(51.79, 8.52, 52.11, 9.42, 51.76, 10.17);
        context.bezierCurveTo(51.68, 10.35, 49.66, 14.49, 44.05, 14.75);
        context.bezierCurveTo(43.42, 16.72, 42.55, 18.82, 41.32, 21);
        context.bezierCurveTo(41.04, 21.48, 40.53, 21.76, 40.01, 21.76);
        context.bezierCurveTo(39.76, 21.76, 39.51, 21.7, 39.27, 21.56);
        context.bezierCurveTo(38.55, 21.16, 38.3, 20.24, 38.7, 19.52);
        context.bezierCurveTo(39.72, 17.73, 40.46, 15.98, 41.02, 14.33);
        context.bezierCurveTo(37.7, 13.09, 36.21, 9.46, 35.87, 7.36);
        context.bezierCurveTo(35.74, 6.54, 36.29, 5.77, 37.11, 5.64);
        context.closePath();
        context.fillStyle = fillColor34;
        context.fill();


        //// Bezier 2909 Drawing
        context.beginPath();
        context.moveTo(37.11, 5.64);
        context.bezierCurveTo(37.92, 5.5, 38.7, 6.06, 38.83, 6.88);
        context.bezierCurveTo(38.84, 6.92, 39.42, 10.3, 41.85, 11.41);
        context.bezierCurveTo(42.98, 6.39, 42.38, 2.83, 42.36, 2.74);
        context.bezierCurveTo(42.21, 1.93, 42.74, 1.15, 43.55, 0.99);
        context.bezierCurveTo(44.36, 0.83, 45.15, 1.37, 45.31, 2.18);
        context.bezierCurveTo(45.35, 2.42, 46.03, 6.24, 44.88, 11.66);
        context.bezierCurveTo(47.89, 11.14, 48.99, 9.01, 49.05, 8.89);
        context.bezierCurveTo(49.4, 8.15, 50.29, 7.83, 51.04, 8.17);
        context.bezierCurveTo(51.79, 8.52, 52.11, 9.42, 51.76, 10.17);
        context.bezierCurveTo(51.68, 10.35, 49.66, 14.49, 44.05, 14.75);
        context.bezierCurveTo(43.42, 16.72, 42.55, 18.82, 41.32, 21);
        context.bezierCurveTo(41.04, 21.48, 40.53, 21.76, 40.01, 21.76);
        context.bezierCurveTo(39.76, 21.76, 39.51, 21.7, 39.27, 21.56);
        context.bezierCurveTo(38.55, 21.16, 38.3, 20.24, 38.7, 19.52);
        context.bezierCurveTo(39.72, 17.73, 40.46, 15.98, 41.02, 14.33);
        context.bezierCurveTo(37.7, 13.09, 36.21, 9.46, 35.87, 7.36);
        context.bezierCurveTo(35.74, 6.54, 36.29, 5.77, 37.11, 5.64);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 68
        context.save();

        //// Clip Clip 66

        context.beginPath();
        context.moveTo(34.26, 18.08);
        context.lineTo(34.25, 18.08);
        context.bezierCurveTo(34.25, 18.08, 9.55, 15.92, 7.61, 29.28);
        context.lineTo(7.61, 29.28);
        context.bezierCurveTo(5.68, 42.63, 15.8, 46.96, 15.8, 46.96);
        context.lineTo(15.8, 46.96);
        context.bezierCurveTo(15.8, 46.96, 23.21, 50.12, 29.93, 43.72);
        context.lineTo(29.93, 43.72);
        context.bezierCurveTo(29.93, 43.72, 32.09, 41.94, 34.25, 41.94);
        context.lineTo(34.25, 41.94);
        context.lineTo(34.26, 41.94);
        context.bezierCurveTo(36.42, 41.94, 38.58, 43.72, 38.58, 43.72);
        context.lineTo(38.58, 43.72);
        context.bezierCurveTo(45.3, 50.12, 52.71, 46.96, 52.71, 46.96);
        context.lineTo(52.71, 46.96);
        context.bezierCurveTo(52.71, 46.96, 62.82, 42.63, 60.89, 29.28);
        context.lineTo(60.89, 29.28);
        context.bezierCurveTo(59.43, 19.19, 44.97, 17.96, 37.99, 17.96);
        context.lineTo(37.99, 17.96);
        context.bezierCurveTo(35.73, 17.96, 34.26, 18.08, 34.26, 18.08);
        context.closePath();
        context.clip();



        //// Bezier 2910 Drawing
        context.beginPath();
        context.moveTo(56.15, 14.44);
        context.lineTo(34.14, 14.44);
        context.lineTo(34.12, 14.44);
        context.lineTo(12.1, 14.44);
        context.lineTo(6.53, 20.01);
        context.lineTo(9.28, 25.25);
        context.bezierCurveTo(9.28, 25.25, 31.89, 23.83, 31.89, 36.64);
        context.lineTo(34.12, 36.64);
        context.lineTo(34.14, 36.64);
        context.lineTo(36.37, 36.64);
        context.bezierCurveTo(36.37, 23.83, 58.98, 25.25, 58.98, 25.25);
        context.lineTo(61.72, 20.01);
        context.lineTo(56.15, 14.44);
        context.closePath();
        context.fillStyle = fillColor52;
        context.fill();


        //// Bezier 2911 Drawing
        context.beginPath();
        context.moveTo(56.15, 14.44);
        context.lineTo(34.14, 14.44);
        context.lineTo(34.12, 14.44);
        context.lineTo(12.1, 14.44);
        context.lineTo(6.53, 20.01);
        context.lineTo(9.28, 25.25);
        context.bezierCurveTo(9.28, 25.25, 31.89, 23.83, 31.89, 36.64);
        context.lineTo(34.12, 36.64);
        context.lineTo(34.14, 36.64);
        context.lineTo(36.37, 36.64);
        context.bezierCurveTo(36.37, 23.83, 58.98, 25.25, 58.98, 25.25);
        context.lineTo(61.72, 20.01);
        context.lineTo(56.15, 14.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        context.restore();



        //// Bezier 2913 Drawing
        context.beginPath();
        context.moveTo(34.13, 41.94);
        context.bezierCurveTo(36.3, 41.94, 38.46, 43.71, 38.46, 43.71);
        context.bezierCurveTo(45.17, 50.12, 52.58, 46.96, 52.58, 46.96);
        context.bezierCurveTo(52.58, 46.96, 62.7, 42.63, 60.77, 29.28);
        context.bezierCurveTo(58.84, 15.92, 34.13, 18.08, 34.13, 18.08);
        context.lineTo(34.13, 18.08);
        context.bezierCurveTo(34.13, 18.08, 9.42, 15.92, 7.49, 29.28);
        context.bezierCurveTo(5.56, 42.63, 15.68, 46.96, 15.68, 46.96);
        context.bezierCurveTo(15.68, 46.96, 23.09, 50.12, 29.8, 43.71);
        context.bezierCurveTo(29.8, 43.71, 31.97, 41.94, 34.13, 41.94);
        context.lineTo(34.13, 41.94);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2914 Drawing
        context.beginPath();
        context.moveTo(38.17, 36.64);
        context.bezierCurveTo(37.24, 34.86, 34.07, 35.17, 34.07, 35.17);
        context.bezierCurveTo(34.07, 35.17, 30.91, 34.86, 29.98, 36.64);
        context.bezierCurveTo(29.05, 38.41, 31.68, 38.26, 31.68, 38.26);
        context.bezierCurveTo(32.55, 38.32, 32.6, 37.95, 32.53, 39.44);
        context.bezierCurveTo(32.45, 40.93, 34.07, 40.5, 34.07, 40.5);
        context.bezierCurveTo(34.07, 40.5, 35.69, 40.93, 35.62, 39.44);
        context.bezierCurveTo(35.54, 37.95, 35.59, 38.32, 36.47, 38.26);
        context.bezierCurveTo(36.47, 38.26, 39.09, 38.41, 38.17, 36.64);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawZebraMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 44), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 44);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor = 'rgba(198, 198, 198, 1)';
        var fillColor63 = 'rgba(25, 26, 26, 1)';

        //// Bezier 2842 Drawing
        context.beginPath();
        context.moveTo(56.83, 21.13);
        context.bezierCurveTo(56.83, 21.13, 60.16, 10.09, 56.83, 6.7);
        context.bezierCurveTo(56.83, 6.7, 45.41, 8.01, 43.97, 13.26);
        context.lineTo(56.83, 21.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2843 Drawing
        context.beginPath();
        context.moveTo(56.83, 21.13);
        context.bezierCurveTo(56.83, 21.13, 60.16, 10.09, 56.83, 6.7);
        context.bezierCurveTo(56.83, 6.7, 45.41, 8.01, 43.97, 13.26);
        context.lineTo(56.83, 21.13);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2844 Drawing
        context.beginPath();
        context.moveTo(5.99, 21.13);
        context.bezierCurveTo(5.99, 21.13, 2.66, 10.09, 5.99, 6.7);
        context.bezierCurveTo(5.99, 6.7, 17.41, 8.01, 18.85, 13.26);
        context.lineTo(5.99, 21.13);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2845 Drawing
        context.beginPath();
        context.moveTo(5.99, 21.13);
        context.bezierCurveTo(5.99, 21.13, 2.66, 10.09, 5.99, 6.7);
        context.bezierCurveTo(5.99, 6.7, 17.41, 8.01, 18.85, 13.26);
        context.lineTo(5.99, 21.13);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2846 Drawing
        context.beginPath();
        context.moveTo(7.8, 17.21);
        context.lineTo(8.63, 11.02);
        context.lineTo(15.12, 14.11);
        context.lineTo(7.8, 17.21);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2847 Drawing
        context.beginPath();
        context.moveTo(7.8, 17.21);
        context.lineTo(8.63, 11.02);
        context.lineTo(15.12, 14.11);
        context.lineTo(7.8, 17.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2848 Drawing
        context.beginPath();
        context.moveTo(10.82, 26.31);
        context.bezierCurveTo(10.82, 26.79, 10.42, 27.18, 9.94, 27.18);
        context.lineTo(2.05, 27.18);
        context.bezierCurveTo(1.56, 27.18, 1.17, 26.79, 1.17, 26.31);
        context.lineTo(1.17, 26.05);
        context.bezierCurveTo(1.17, 25.57, 1.56, 25.17, 2.05, 25.17);
        context.lineTo(9.94, 25.17);
        context.bezierCurveTo(10.42, 25.17, 10.82, 25.57, 10.82, 26.05);
        context.lineTo(10.82, 26.31);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2849 Drawing
        context.beginPath();
        context.moveTo(10.82, 26.31);
        context.bezierCurveTo(10.82, 26.79, 10.42, 27.18, 9.94, 27.18);
        context.lineTo(2.05, 27.18);
        context.bezierCurveTo(1.56, 27.18, 1.17, 26.79, 1.17, 26.31);
        context.lineTo(1.17, 26.05);
        context.bezierCurveTo(1.17, 25.57, 1.56, 25.17, 2.05, 25.17);
        context.lineTo(9.94, 25.17);
        context.bezierCurveTo(10.42, 25.17, 10.82, 25.57, 10.82, 26.05);
        context.lineTo(10.82, 26.31);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2850 Drawing
        context.beginPath();
        context.moveTo(55.02, 17.21);
        context.lineTo(54.19, 11.02);
        context.lineTo(47.7, 14.11);
        context.lineTo(55.02, 17.21);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2851 Drawing
        context.beginPath();
        context.moveTo(55.02, 17.21);
        context.lineTo(54.19, 11.02);
        context.lineTo(47.7, 14.11);
        context.lineTo(55.02, 17.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2852 Drawing
        context.beginPath();
        context.moveTo(52.02, 26.31);
        context.bezierCurveTo(52.02, 26.79, 52.42, 27.18, 52.9, 27.18);
        context.lineTo(60.8, 27.18);
        context.bezierCurveTo(61.28, 27.18, 61.67, 26.79, 61.67, 26.31);
        context.lineTo(61.67, 26.05);
        context.bezierCurveTo(61.67, 25.57, 61.28, 25.17, 60.8, 25.17);
        context.lineTo(52.9, 25.17);
        context.bezierCurveTo(52.42, 25.17, 52.02, 25.57, 52.02, 26.05);
        context.lineTo(52.02, 26.31);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2853 Drawing
        context.beginPath();
        context.moveTo(52.02, 26.31);
        context.bezierCurveTo(52.02, 26.79, 52.42, 27.18, 52.9, 27.18);
        context.lineTo(60.8, 27.18);
        context.bezierCurveTo(61.28, 27.18, 61.67, 26.79, 61.67, 26.31);
        context.lineTo(61.67, 26.05);
        context.bezierCurveTo(61.67, 25.57, 61.28, 25.17, 60.8, 25.17);
        context.lineTo(52.9, 25.17);
        context.bezierCurveTo(52.42, 25.17, 52.02, 25.57, 52.02, 26.05);
        context.lineTo(52.02, 26.31);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2854 Drawing
        context.beginPath();
        context.moveTo(31.43, 35.71);
        context.bezierCurveTo(33.59, 35.71, 35.75, 37.49, 35.75, 37.49);
        context.bezierCurveTo(42.47, 43.9, 49.88, 40.73, 49.88, 40.73);
        context.bezierCurveTo(49.88, 40.73, 59.99, 36.41, 58.06, 23.05);
        context.bezierCurveTo(56.13, 9.7, 31.43, 11.86, 31.43, 11.86);
        context.lineTo(31.42, 11.86);
        context.bezierCurveTo(31.42, 11.86, 6.72, 9.7, 4.79, 23.05);
        context.bezierCurveTo(2.86, 36.41, 12.97, 40.73, 12.97, 40.73);
        context.bezierCurveTo(12.97, 40.73, 20.38, 43.9, 27.1, 37.49);
        context.bezierCurveTo(27.1, 37.49, 29.26, 35.71, 31.42, 35.71);
        context.lineTo(31.43, 35.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2855 Drawing
        context.beginPath();
        context.moveTo(31.43, 35.71);
        context.bezierCurveTo(33.59, 35.71, 35.75, 37.49, 35.75, 37.49);
        context.bezierCurveTo(42.47, 43.9, 49.88, 40.73, 49.88, 40.73);
        context.bezierCurveTo(49.88, 40.73, 59.99, 36.41, 58.06, 23.05);
        context.bezierCurveTo(56.13, 9.7, 31.43, 11.86, 31.43, 11.86);
        context.lineTo(31.42, 11.86);
        context.bezierCurveTo(31.42, 11.86, 6.72, 9.7, 4.79, 23.05);
        context.bezierCurveTo(2.86, 36.41, 12.97, 40.73, 12.97, 40.73);
        context.bezierCurveTo(12.97, 40.73, 20.38, 43.9, 27.1, 37.49);
        context.bezierCurveTo(27.1, 37.49, 29.26, 35.71, 31.42, 35.71);
        context.lineTo(31.43, 35.71);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 648 Drawing
        oval(context, 13.15, 21.38, 8.5, 8.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 649 Drawing
        oval(context, 14.7, 22.93, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 650 Drawing
        oval(context, 15.95, 24.18, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2856 Drawing
        context.beginPath();
        context.moveTo(24.66, 11.87);
        context.bezierCurveTo(26.49, 8.46, 22.83, 5.3, 22.83, 5.3);
        context.bezierCurveTo(24.58, 4.39, 28.9, 7.71, 28.9, 7.71);
        context.bezierCurveTo(27.57, 6.22, 24.99, 2.97, 24.99, 2.97);
        context.bezierCurveTo(29.81, 3.31, 32.22, 6.55, 32.22, 6.55);
        context.bezierCurveTo(31.14, 4.64, 29.65, 1.56, 29.65, 1.56);
        context.bezierCurveTo(29.65, 1.56, 38.29, 2.64, 39.04, 11.87);
        context.fillStyle = fillColor63;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 651 Drawing
        oval(context, 40.15, 21.38, 8.5, 8.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 652 Drawing
        oval(context, 41.6, 22.93, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 653 Drawing
        oval(context, 42.85, 24.18, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Group 66
        context.save();

        //// Clip Clip 64

        context.beginPath();
        context.moveTo(31.43, 11.86);
        context.lineTo(31.42, 11.86);
        context.bezierCurveTo(31.42, 11.86, 6.72, 9.7, 4.79, 23.05);
        context.lineTo(4.79, 23.05);
        context.bezierCurveTo(2.86, 36.41, 12.97, 40.73, 12.97, 40.73);
        context.lineTo(12.97, 40.73);
        context.bezierCurveTo(12.97, 40.73, 20.38, 43.9, 27.1, 37.49);
        context.lineTo(27.1, 37.49);
        context.bezierCurveTo(27.1, 37.49, 29.26, 35.71, 31.42, 35.71);
        context.lineTo(31.42, 35.71);
        context.lineTo(31.43, 35.71);
        context.bezierCurveTo(33.59, 35.71, 35.75, 37.49, 35.75, 37.49);
        context.lineTo(35.75, 37.49);
        context.bezierCurveTo(42.47, 43.9, 49.88, 40.73, 49.88, 40.73);
        context.lineTo(49.88, 40.73);
        context.bezierCurveTo(49.88, 40.73, 59.99, 36.41, 58.06, 23.05);
        context.lineTo(58.06, 23.05);
        context.bezierCurveTo(56.6, 12.96, 42.15, 11.73, 35.16, 11.73);
        context.lineTo(35.16, 11.73);
        context.bezierCurveTo(32.9, 11.73, 31.43, 11.86, 31.43, 11.86);
        context.closePath();
        context.clip();



        //// Bezier 2857 Drawing
        context.beginPath();
        context.moveTo(28.23, 10.54);
        context.lineTo(31.48, 21.26);
        context.lineTo(34.47, 10.21);
        context.lineTo(28.23, 10.54);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2858 Drawing
        context.beginPath();
        context.moveTo(28.23, 10.54);
        context.lineTo(31.48, 21.26);
        context.lineTo(34.47, 10.21);
        context.lineTo(28.23, 10.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2859 Drawing
        context.beginPath();
        context.moveTo(18.23, 7.54);
        context.lineTo(21.48, 18.26);
        context.lineTo(24.47, 7.21);
        context.lineTo(18.23, 7.54);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2860 Drawing
        context.beginPath();
        context.moveTo(18.23, 7.54);
        context.lineTo(21.48, 18.26);
        context.lineTo(24.47, 7.21);
        context.lineTo(18.23, 7.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2861 Drawing
        context.beginPath();
        context.moveTo(38.23, 7.54);
        context.lineTo(41.48, 18.26);
        context.lineTo(44.47, 7.21);
        context.lineTo(38.23, 7.54);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2862 Drawing
        context.beginPath();
        context.moveTo(38.23, 7.54);
        context.lineTo(41.48, 18.26);
        context.lineTo(44.47, 7.21);
        context.lineTo(38.23, 7.54);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.95;
        context.stroke();


        //// Bezier 2863 Drawing
        context.beginPath();
        context.moveTo(5.05, 29.77);
        context.lineTo(10.6, 27.99);
        context.lineTo(4.87, 26.35);
        context.lineTo(5.05, 29.77);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2864 Drawing
        context.beginPath();
        context.moveTo(5.05, 29.77);
        context.lineTo(10.6, 27.99);
        context.lineTo(4.87, 26.35);
        context.lineTo(5.05, 29.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        //// Bezier 2865 Drawing
        context.beginPath();
        context.moveTo(3.49, 35.26);
        context.lineTo(9.05, 33.48);
        context.lineTo(3.32, 31.84);
        context.lineTo(3.49, 35.26);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2866 Drawing
        context.beginPath();
        context.moveTo(3.49, 35.26);
        context.lineTo(9.05, 33.48);
        context.lineTo(3.32, 31.84);
        context.lineTo(3.49, 35.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        //// Bezier 2867 Drawing
        context.beginPath();
        context.moveTo(3.49, 24.28);
        context.lineTo(9.05, 22.51);
        context.lineTo(3.32, 20.86);
        context.lineTo(3.49, 24.28);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2868 Drawing
        context.beginPath();
        context.moveTo(3.49, 24.28);
        context.lineTo(9.05, 22.51);
        context.lineTo(3.32, 20.86);
        context.lineTo(3.49, 24.28);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        //// Bezier 2869 Drawing
        context.beginPath();
        context.moveTo(57.87, 29.77);
        context.lineTo(52.32, 27.99);
        context.lineTo(58.05, 26.35);
        context.lineTo(57.87, 29.77);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2870 Drawing
        context.beginPath();
        context.moveTo(57.87, 29.77);
        context.lineTo(52.32, 27.99);
        context.lineTo(58.05, 26.35);
        context.lineTo(57.87, 29.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        //// Bezier 2871 Drawing
        context.beginPath();
        context.moveTo(59.43, 35.26);
        context.lineTo(53.87, 33.48);
        context.lineTo(59.6, 31.84);
        context.lineTo(59.43, 35.26);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2872 Drawing
        context.beginPath();
        context.moveTo(59.43, 35.26);
        context.lineTo(53.87, 33.48);
        context.lineTo(59.6, 31.84);
        context.lineTo(59.43, 35.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        //// Bezier 2873 Drawing
        context.beginPath();
        context.moveTo(59.43, 24.28);
        context.lineTo(53.87, 22.51);
        context.lineTo(59.6, 20.86);
        context.lineTo(59.43, 24.28);
        context.closePath();
        context.fillStyle = fillColor63;
        context.fill();


        //// Bezier 2874 Drawing
        context.beginPath();
        context.moveTo(59.43, 24.28);
        context.lineTo(53.87, 22.51);
        context.lineTo(59.6, 20.86);
        context.lineTo(59.43, 24.28);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.51;
        context.stroke();


        context.restore();



        //// Bezier 2876 Drawing
        context.beginPath();
        context.moveTo(31.31, 35.71);
        context.bezierCurveTo(33.47, 35.71, 35.63, 37.49, 35.63, 37.49);
        context.bezierCurveTo(42.34, 43.9, 49.76, 40.73, 49.76, 40.73);
        context.bezierCurveTo(49.76, 40.73, 59.87, 36.41, 57.94, 23.05);
        context.bezierCurveTo(56.01, 9.7, 31.31, 11.86, 31.31, 11.86);
        context.lineTo(31.3, 11.86);
        context.bezierCurveTo(31.3, 11.86, 6.59, 9.7, 4.66, 23.05);
        context.bezierCurveTo(2.73, 36.41, 12.85, 40.73, 12.85, 40.73);
        context.bezierCurveTo(12.85, 40.73, 20.26, 43.9, 26.98, 37.49);
        context.bezierCurveTo(26.98, 37.49, 29.14, 35.71, 31.3, 35.71);
        context.lineTo(31.31, 35.71);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 654 Drawing
        oval(context, 18.85, 28.98, 24.9, 12.8);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 655 Drawing
        oval(context, 18.85, 28.98, 24.9, 12.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 656 Drawing
        oval(context, 24.15, 32.53, 5.8, 5.8);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 657 Drawing
        oval(context, 24.15, 32.53, 5.8, 5.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 658 Drawing
        oval(context, 32.45, 32.53, 5.8, 5.8);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 659 Drawing
        oval(context, 32.45, 32.53, 5.8, 5.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawDogMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 74, 44), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 74, resizedFrame.h / 44);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor25 = 'rgba(185, 146, 111, 1)';
        var fillColor50 = 'rgba(110, 75, 48, 1)';
        var fillColor52 = 'rgba(146, 100, 64, 1)';

        //// Bezier 2877 Drawing
        context.beginPath();
        context.moveTo(47.83, 12.4);
        context.bezierCurveTo(47.83, 12.4, 47.04, 2.59, 56.29, 2.59);
        context.bezierCurveTo(65.53, 2.59, 74.99, 16.96, 71.19, 21.42);
        context.bezierCurveTo(67.83, 25.37, 64.16, 22.99, 62.21, 20.05);
        context.bezierCurveTo(60.92, 18.12, 60.04, 16.82, 59.36, 15.91);
        context.bezierCurveTo(57.91, 13.97, 55.19, 14.32, 54.14, 16.56);
        context.lineTo(47.83, 12.4);
        context.closePath();
        context.fillStyle = fillColor50;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.94;
        context.stroke();


        //// Bezier 2878 Drawing
        context.beginPath();
        context.moveTo(11.64, 25.59);
        context.bezierCurveTo(11.64, 26.07, 11.24, 26.47, 10.76, 26.47);
        context.lineTo(2.86, 26.47);
        context.bezierCurveTo(2.38, 26.47, 1.99, 26.07, 1.99, 25.59);
        context.lineTo(1.99, 25.33);
        context.bezierCurveTo(1.99, 24.85, 2.38, 24.46, 2.86, 24.46);
        context.lineTo(10.76, 24.46);
        context.bezierCurveTo(11.24, 24.46, 11.64, 24.85, 11.64, 25.33);
        context.lineTo(11.64, 25.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2879 Drawing
        context.beginPath();
        context.moveTo(11.64, 25.59);
        context.bezierCurveTo(11.64, 26.07, 11.24, 26.47, 10.76, 26.47);
        context.lineTo(2.86, 26.47);
        context.bezierCurveTo(2.38, 26.47, 1.99, 26.07, 1.99, 25.59);
        context.lineTo(1.99, 25.33);
        context.bezierCurveTo(1.99, 24.85, 2.38, 24.46, 2.86, 24.46);
        context.lineTo(10.76, 24.46);
        context.bezierCurveTo(11.24, 24.46, 11.64, 24.85, 11.64, 25.33);
        context.lineTo(11.64, 25.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2880 Drawing
        context.beginPath();
        context.moveTo(52.84, 25.59);
        context.bezierCurveTo(52.84, 26.07, 53.24, 26.47, 53.72, 26.47);
        context.lineTo(61.62, 26.47);
        context.bezierCurveTo(62.1, 26.47, 62.49, 26.07, 62.49, 25.59);
        context.lineTo(62.49, 25.33);
        context.bezierCurveTo(62.49, 24.85, 62.1, 24.46, 61.62, 24.46);
        context.lineTo(53.72, 24.46);
        context.bezierCurveTo(53.24, 24.46, 52.84, 24.85, 52.84, 25.33);
        context.lineTo(52.84, 25.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2881 Drawing
        context.beginPath();
        context.moveTo(52.84, 25.59);
        context.bezierCurveTo(52.84, 26.07, 53.24, 26.47, 53.72, 26.47);
        context.lineTo(61.62, 26.47);
        context.bezierCurveTo(62.1, 26.47, 62.49, 26.07, 62.49, 25.59);
        context.lineTo(62.49, 25.33);
        context.bezierCurveTo(62.49, 24.85, 62.1, 24.46, 61.62, 24.46);
        context.lineTo(53.72, 24.46);
        context.bezierCurveTo(53.24, 24.46, 52.84, 24.85, 52.84, 25.33);
        context.lineTo(52.84, 25.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2882 Drawing
        context.beginPath();
        context.moveTo(32.25, 35);
        context.bezierCurveTo(34.41, 35, 36.57, 36.77, 36.57, 36.77);
        context.bezierCurveTo(43.29, 43.18, 50.7, 40.02, 50.7, 40.02);
        context.bezierCurveTo(50.7, 40.02, 60.81, 35.69, 58.88, 22.34);
        context.bezierCurveTo(56.95, 8.98, 32.25, 11.14, 32.25, 11.14);
        context.lineTo(32.24, 11.14);
        context.bezierCurveTo(32.24, 11.14, 7.54, 8.98, 5.61, 22.34);
        context.bezierCurveTo(3.68, 35.69, 13.79, 40.02, 13.79, 40.02);
        context.bezierCurveTo(13.79, 40.02, 21.2, 43.18, 27.92, 36.77);
        context.bezierCurveTo(27.92, 36.77, 30.08, 35, 32.24, 35);
        context.lineTo(32.25, 35);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Group 67
        context.save();

        //// Clip Clip 65

        context.beginPath();
        context.moveTo(32.25, 11.14);
        context.lineTo(32.24, 11.14);
        context.bezierCurveTo(32.24, 11.14, 7.54, 8.98, 5.61, 22.34);
        context.lineTo(5.61, 22.34);
        context.bezierCurveTo(3.68, 35.69, 13.79, 40.02, 13.79, 40.02);
        context.lineTo(13.79, 40.02);
        context.bezierCurveTo(13.79, 40.02, 21.2, 43.18, 27.92, 36.77);
        context.lineTo(27.92, 36.77);
        context.bezierCurveTo(27.92, 36.77, 30.08, 35, 32.24, 35);
        context.lineTo(32.24, 35);
        context.lineTo(32.25, 35);
        context.bezierCurveTo(34.41, 35, 36.57, 36.77, 36.57, 36.77);
        context.lineTo(36.57, 36.77);
        context.bezierCurveTo(43.29, 43.18, 50.7, 40.02, 50.7, 40.02);
        context.lineTo(50.7, 40.02);
        context.bezierCurveTo(50.7, 40.02, 60.81, 35.69, 58.88, 22.34);
        context.lineTo(58.88, 22.34);
        context.bezierCurveTo(57.42, 12.25, 42.97, 11.01, 35.99, 11.01);
        context.lineTo(35.99, 11.01);
        context.bezierCurveTo(33.72, 11.01, 32.25, 11.14, 32.25, 11.14);
        context.closePath();
        context.clip();



        //// Bezier 2883 Drawing
        context.beginPath();
        context.moveTo(39.08, 6.5);
        context.bezierCurveTo(39.08, 6.5, 33.34, 14.81, 39.99, 16.89);
        context.bezierCurveTo(46.64, 18.97, 48.72, 15.98, 51.88, 21.46);
        context.bezierCurveTo(55.04, 26.95, 52.21, 29.28, 59.03, 28.94);
        context.bezierCurveTo(65.85, 28.61, 67.01, 33.27, 68.09, 26.03);
        context.bezierCurveTo(69.17, 18.8, 72.25, 16.64, 66.01, 12.9);
        context.bezierCurveTo(59.78, 9.16, 56.03, 5.5, 51.88, 4.92);
        context.bezierCurveTo(47.72, 4.34, 43.15, 5.17, 43.15, 5.17);
        context.lineTo(39.08, 6.5);
        context.closePath();
        context.fillStyle = fillColor52;
        context.fill();


        //// Bezier 2884 Drawing
        context.beginPath();
        context.moveTo(39.08, 6.5);
        context.bezierCurveTo(39.08, 6.5, 33.34, 14.81, 39.99, 16.89);
        context.bezierCurveTo(46.64, 18.97, 48.72, 15.98, 51.88, 21.46);
        context.bezierCurveTo(55.04, 26.95, 52.21, 29.28, 59.03, 28.94);
        context.bezierCurveTo(65.85, 28.61, 67.01, 33.27, 68.09, 26.03);
        context.bezierCurveTo(69.17, 18.8, 72.25, 16.64, 66.01, 12.9);
        context.bezierCurveTo(59.78, 9.16, 56.03, 5.5, 51.88, 4.92);
        context.bezierCurveTo(47.72, 4.34, 43.15, 5.17, 43.15, 5.17);
        context.lineTo(39.08, 6.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2886 Drawing
        context.beginPath();
        context.moveTo(32.25, 35);
        context.bezierCurveTo(34.41, 35, 36.57, 36.77, 36.57, 36.77);
        context.bezierCurveTo(43.29, 43.18, 50.7, 40.02, 50.7, 40.02);
        context.bezierCurveTo(50.7, 40.02, 60.81, 35.69, 58.88, 22.34);
        context.bezierCurveTo(56.95, 8.98, 32.25, 11.14, 32.25, 11.14);
        context.lineTo(32.24, 11.14);
        context.bezierCurveTo(32.24, 11.14, 7.54, 8.98, 5.61, 22.34);
        context.bezierCurveTo(3.68, 35.69, 13.79, 40.02, 13.79, 40.02);
        context.bezierCurveTo(13.79, 40.02, 21.2, 43.18, 27.92, 36.77);
        context.bezierCurveTo(27.92, 36.77, 30.08, 35, 32.24, 35);
        context.lineTo(32.25, 35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2887 Drawing
        context.beginPath();
        context.moveTo(13.33, 21.42);
        context.bezierCurveTo(12.67, 22.48, 10.32, 25.21, 11.17, 28.06);
        context.bezierCurveTo(12.01, 30.92, 14.48, 32.77, 17.8, 32.23);
        context.bezierCurveTo(21.12, 31.69, 25.37, 26.75, 25.68, 25.75);
        context.bezierCurveTo(25.99, 24.74, 27.99, 19.8, 24.98, 19.65);
        context.bezierCurveTo(21.97, 19.49, 14.86, 18.95, 13.33, 21.42);
        context.closePath();
        context.fillStyle = fillColor52;
        context.fill();


        //// Bezier 2888 Drawing
        context.beginPath();
        context.moveTo(13.33, 21.42);
        context.bezierCurveTo(12.67, 22.48, 10.32, 25.21, 11.17, 28.06);
        context.bezierCurveTo(12.01, 30.92, 14.48, 32.77, 17.8, 32.23);
        context.bezierCurveTo(21.12, 31.69, 25.37, 26.75, 25.68, 25.75);
        context.bezierCurveTo(25.99, 24.74, 27.99, 19.8, 24.98, 19.65);
        context.bezierCurveTo(21.97, 19.49, 14.86, 18.95, 13.33, 21.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 660 Drawing
        oval(context, 15.55, 22.18, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 661 Drawing
        oval(context, 16.8, 23.43, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 662 Drawing
        oval(context, 42.4, 22.18, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 663 Drawing
        oval(context, 43.65, 23.43, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2889 Drawing
        context.beginPath();
        context.moveTo(36.01, 28.45);
        context.bezierCurveTo(35.08, 26.67, 31.91, 26.98, 31.91, 26.98);
        context.bezierCurveTo(31.91, 26.98, 28.75, 26.67, 27.82, 28.45);
        context.bezierCurveTo(26.89, 30.22, 29.52, 30.07, 29.52, 30.07);
        context.bezierCurveTo(30.4, 30.13, 30.45, 29.76, 30.37, 31.25);
        context.bezierCurveTo(30.29, 32.74, 31.91, 32.31, 31.91, 32.31);
        context.bezierCurveTo(31.91, 32.31, 33.54, 32.74, 33.46, 31.25);
        context.bezierCurveTo(33.38, 29.76, 33.43, 30.13, 34.31, 30.07);
        context.bezierCurveTo(34.31, 30.07, 36.94, 30.22, 36.01, 28.45);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2890 Drawing
        context.beginPath();
        context.moveTo(5.65, 6.5);
        context.bezierCurveTo(5.65, 6.5, -4.9, 15.06, 8.65, 20.96);
        context.bezierCurveTo(22.2, 26.87, 18.62, 11.4, 18.62, 11.4);
        context.bezierCurveTo(18.62, 11.4, 16.13, -2.81, 5.65, 6.5);
        context.closePath();
        context.fillStyle = fillColor50;
        context.fill();


        //// Bezier 2891 Drawing
        context.beginPath();
        context.moveTo(5.65, 6.5);
        context.bezierCurveTo(5.65, 6.5, -4.9, 15.06, 8.65, 20.96);
        context.bezierCurveTo(22.2, 26.87, 18.62, 11.4, 18.62, 11.4);
        context.bezierCurveTo(18.62, 11.4, 16.13, -2.81, 5.65, 6.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawUnicornMaskBk(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 65, 54), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 65, resizedFrame.h / 54);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor33 = 'rgba(248, 248, 117, 1)';

        //// Bezier 2718 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2719 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2720 Drawing
        context.beginPath();
        context.moveTo(6.97, 29.39);
        context.bezierCurveTo(6.97, 29.39, 2.84, 19.04, 9.05, 16.45);
        context.bezierCurveTo(15.26, 13.87, 21.36, 23.53, 21.36, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2721 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2722 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2723 Drawing
        context.beginPath();
        context.moveTo(57.29, 29.39);
        context.bezierCurveTo(57.29, 29.39, 61.42, 19.04, 55.21, 16.45);
        context.bezierCurveTo(49, 13.87, 42.9, 23.53, 42.9, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2724 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2725 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2726 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2727 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2728 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();


        //// Bezier 2729 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2730 Drawing
        context.beginPath();
        context.moveTo(18.57, 33.32);
        context.bezierCurveTo(18.57, 33.32, 15.72, 32.02, 14.64, 30.27);
        context.bezierCurveTo(14.64, 30.27, 14.72, 32.52, 15.55, 33.02);
        context.bezierCurveTo(15.55, 33.02, 13.62, 32.63, 13.31, 32.02);
        context.bezierCurveTo(13.31, 32.02, 13.98, 34.46, 15.4, 35.46);
        context.bezierCurveTo(15.4, 35.46, 16.41, 33.24, 18.57, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2731 Drawing
        context.beginPath();
        context.moveTo(44.31, 33.32);
        context.bezierCurveTo(44.31, 33.32, 47.16, 32.02, 48.24, 30.27);
        context.bezierCurveTo(48.24, 30.27, 48.16, 32.52, 47.33, 33.02);
        context.bezierCurveTo(47.33, 33.02, 49.25, 32.63, 49.57, 32.02);
        context.bezierCurveTo(49.57, 32.02, 48.89, 34.46, 47.48, 35.46);
        context.bezierCurveTo(47.48, 35.46, 46.47, 33.24, 44.31, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 622 Drawing
        oval(context, 15.2, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 623 Drawing
        oval(context, 16.45, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 624 Drawing
        oval(context, 42.1, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 625 Drawing
        oval(context, 43.35, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2732 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.lineTo(26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Group 61
        context.save();

        //// Clip Clip 59

        context.beginPath();
        context.moveTo(31.7, 1.25);
        context.lineTo(26.53, 27.62);
        context.lineTo(38.07, 27.62);
        context.lineTo(32.5, 1.25);
        context.bezierCurveTo(32.45, 1.03, 32.27, 0.92, 32.1, 0.92);
        context.lineTo(32.1, 0.92);
        context.bezierCurveTo(31.92, 0.92, 31.74, 1.03, 31.7, 1.25);
        context.closePath();
        context.clip();



        //// Bezier 2733 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2734 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2735 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2736 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2737 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2738 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2739 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 2740 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2741 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2742 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2743 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2744 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        context.restore();



        //// Group 3
        //// Bezier 5 Drawing
        context.beginPath();
        context.moveTo(41.5, 24.5);
        context.bezierCurveTo(41.5, 24.5, 33.5, 22.5, 36.5, 19.5);
        context.bezierCurveTo(39.5, 16.5, 41.5, 13.5, 43.5, 17.5);
        context.bezierCurveTo(45.5, 21.5, 49.5, 24.5, 45.5, 24.5);
        context.bezierCurveTo(41.5, 24.5, 41.5, 24.5, 41.5, 24.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 3 Drawing
        context.beginPath();
        context.moveTo(23.5, 17.5);
        context.bezierCurveTo(23.5, 19.5, 25.5, 24.5, 27.5, 24.5);
        context.bezierCurveTo(29.5, 24.5, 37.5, 27.5, 36.5, 24.5);
        context.bezierCurveTo(35.5, 21.5, 28.5, 14.5, 28.5, 14.5);
        context.bezierCurveTo(28.5, 14.5, 23.5, 15.5, 23.5, 17.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2 Drawing
        context.beginPath();
        context.moveTo(22.5, 24.5);
        context.bezierCurveTo(22.5, 24.5, 14.5, 22.5, 17.5, 19.5);
        context.bezierCurveTo(20.5, 16.5, 22.5, 13.5, 24.5, 17.5);
        context.bezierCurveTo(26.5, 21.5, 30.5, 24.5, 26.5, 24.5);
        context.bezierCurveTo(22.5, 24.5, 22.5, 24.5, 22.5, 24.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 4 Drawing
        context.beginPath();
        context.moveTo(32.5, 17.5);
        context.bezierCurveTo(31.5, 20.5, 33.5, 24.5, 35.5, 24.5);
        context.bezierCurveTo(37.5, 24.5, 43.5, 26.5, 42.5, 23.5);
        context.bezierCurveTo(41.5, 20.5, 39.5, 16.5, 38.5, 15.5);
        context.bezierCurveTo(37.5, 14.5, 33.5, 14.5, 32.5, 17.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();




        //// Bezier 2747 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2748 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2749 Drawing
        context.beginPath();
        context.moveTo(6.97, 29.39);
        context.bezierCurveTo(6.97, 29.39, 2.84, 19.04, 9.05, 16.45);
        context.bezierCurveTo(15.26, 13.87, 21.36, 23.53, 21.36, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2750 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2751 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2752 Drawing
        context.beginPath();
        context.moveTo(57.29, 29.39);
        context.bezierCurveTo(57.29, 29.39, 61.42, 19.04, 55.21, 16.45);
        context.bezierCurveTo(49, 13.87, 42.9, 23.53, 42.9, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2753 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2754 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2755 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2756 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2757 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();


        //// Bezier 2758 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group
        context.save();

        //// Clip Bezier

        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.clip();



        //// Oval 654 Drawing
        oval(context, 17.85, 39.98, 28.15, 17.02);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 2
        //// Oval 656 Drawing
        oval(context, 23.5, 44.53, 2, 2);
        context.fillStyle = strokeColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval Drawing
        oval(context, 38.35, 45, 2, 2);
        context.fillStyle = strokeColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();




        //// Star Drawing
        context.beginPath();
        context.moveTo(48, 42);
        context.lineTo(48.71, 43.03);
        context.lineTo(49.9, 43.38);
        context.lineTo(49.14, 44.37);
        context.lineTo(49.18, 45.62);
        context.lineTo(48, 45.2);
        context.lineTo(46.82, 45.62);
        context.lineTo(46.86, 44.37);
        context.lineTo(46.1, 43.38);
        context.lineTo(47.29, 43.03);
        context.closePath();
        context.fillStyle = fillColor33;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Star 2 Drawing
        context.beginPath();
        context.moveTo(11.25, 37.75);
        context.lineTo(11.96, 38.78);
        context.lineTo(13.15, 39.13);
        context.lineTo(12.39, 40.12);
        context.lineTo(12.43, 41.37);
        context.lineTo(11.25, 40.95);
        context.lineTo(10.07, 41.37);
        context.lineTo(10.11, 40.12);
        context.lineTo(9.35, 39.13);
        context.lineTo(10.54, 38.78);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        context.restore();



        //// Bezier 2759 Drawing
        context.beginPath();
        context.moveTo(18.57, 33.32);
        context.bezierCurveTo(18.57, 33.32, 15.72, 32.02, 14.64, 30.27);
        context.bezierCurveTo(14.64, 30.27, 14.72, 32.52, 15.55, 33.02);
        context.bezierCurveTo(15.55, 33.02, 13.62, 32.63, 13.31, 32.02);
        context.bezierCurveTo(13.31, 32.02, 13.98, 34.46, 15.4, 35.46);
        context.bezierCurveTo(15.4, 35.46, 16.41, 33.24, 18.57, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2760 Drawing
        context.beginPath();
        context.moveTo(44.31, 33.32);
        context.bezierCurveTo(44.31, 33.32, 47.16, 32.02, 48.24, 30.27);
        context.bezierCurveTo(48.24, 30.27, 48.16, 32.52, 47.33, 33.02);
        context.bezierCurveTo(47.33, 33.02, 49.25, 32.63, 49.57, 32.02);
        context.bezierCurveTo(49.57, 32.02, 48.89, 34.46, 47.48, 35.46);
        context.bezierCurveTo(47.48, 35.46, 46.47, 33.24, 44.31, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 626 Drawing
        oval(context, 15.2, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 627 Drawing
        oval(context, 16.45, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 628 Drawing
        oval(context, 42.1, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 629 Drawing
        oval(context, 43.35, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2761 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.lineTo(26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Group 62
        context.save();

        //// Clip Clip 60

        context.beginPath();
        context.moveTo(31.7, 1.25);
        context.lineTo(26.53, 27.62);
        context.bezierCurveTo(26.53, 27.62, 29.53, 28.62, 32.41, 28.62);
        context.bezierCurveTo(35.29, 28.62, 38.07, 27.62, 38.07, 27.62);
        context.lineTo(32.5, 1.25);
        context.bezierCurveTo(32.45, 1.03, 32.27, 0.92, 32.1, 0.92);
        context.lineTo(32.1, 0.92);
        context.bezierCurveTo(31.92, 0.92, 31.74, 1.03, 31.7, 1.25);
        context.closePath();
        context.clip();



        //// Bezier 2762 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2763 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2764 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2765 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2766 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2767 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2768 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 2769 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2770 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2771 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2772 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2773 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        context.restore();



        //// Bezier 2775 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.bezierCurveTo(38.07, 27.62, 35.27, 28.62, 32.39, 28.62);
        context.bezierCurveTo(29.5, 28.62, 26.53, 27.62, 26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();
        
        context.restore();

    }

    function drawMonkeyMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 41), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 41);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';
        var fillColor25 = 'rgba(185, 146, 111, 1)';
        var fillColor50 = 'rgba(110, 75, 48, 1)';

        //// Bezier 2840 Drawing
        context.beginPath();
        context.moveTo(13.84, 15.56);
        context.bezierCurveTo(13.84, 15.56, 10.02, 8.8, 4.75, 11.74);
        context.bezierCurveTo(-0.52, 14.67, 0.96, 19.96, 2.59, 21.77);
        context.bezierCurveTo(4.08, 23.43, 11.13, 26.96, 15.62, 22.46);
        context.lineTo(13.84, 15.56);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Bezier 2841 Drawing
        context.beginPath();
        context.moveTo(13.84, 15.56);
        context.bezierCurveTo(13.84, 15.56, 10.02, 8.8, 4.75, 11.74);
        context.bezierCurveTo(-0.52, 14.67, 0.96, 19.96, 2.59, 21.77);
        context.bezierCurveTo(4.08, 23.43, 11.13, 26.96, 15.62, 22.46);
        context.lineTo(13.84, 15.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2915 Drawing
        context.beginPath();
        context.moveTo(13.62, 24.59);
        context.bezierCurveTo(13.62, 25.07, 13.23, 25.47, 12.75, 25.47);
        context.lineTo(4.85, 25.47);
        context.bezierCurveTo(4.37, 25.47, 3.97, 25.07, 3.97, 24.59);
        context.lineTo(3.97, 24.33);
        context.bezierCurveTo(3.97, 23.85, 4.37, 23.46, 4.85, 23.46);
        context.lineTo(12.75, 23.46);
        context.bezierCurveTo(13.23, 23.46, 13.62, 23.85, 13.62, 24.33);
        context.lineTo(13.62, 24.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2916 Drawing
        context.beginPath();
        context.moveTo(13.62, 24.59);
        context.bezierCurveTo(13.62, 25.07, 13.23, 25.47, 12.75, 25.47);
        context.lineTo(4.85, 25.47);
        context.bezierCurveTo(4.37, 25.47, 3.97, 25.07, 3.97, 24.59);
        context.lineTo(3.97, 24.33);
        context.bezierCurveTo(3.97, 23.85, 4.37, 23.46, 4.85, 23.46);
        context.lineTo(12.75, 23.46);
        context.bezierCurveTo(13.23, 23.46, 13.62, 23.85, 13.62, 24.33);
        context.lineTo(13.62, 24.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2917 Drawing
        context.beginPath();
        context.moveTo(11.76, 16.42);
        context.bezierCurveTo(11.76, 16.42, 9.39, 12.24, 6.13, 14.06);
        context.bezierCurveTo(2.87, 15.87, 3.79, 19.14, 4.8, 20.27);
        context.bezierCurveTo(5.72, 21.3, 10.08, 23.48, 12.86, 20.69);
        context.lineTo(11.76, 16.42);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2918 Drawing
        context.beginPath();
        context.moveTo(11.76, 16.42);
        context.bezierCurveTo(11.76, 16.42, 9.39, 12.24, 6.13, 14.06);
        context.bezierCurveTo(2.87, 15.87, 3.79, 19.14, 4.8, 20.27);
        context.bezierCurveTo(5.72, 21.3, 10.08, 23.48, 12.86, 20.69);
        context.lineTo(11.76, 16.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2919 Drawing
        context.beginPath();
        context.moveTo(55.87, 15.56);
        context.bezierCurveTo(55.87, 15.56, 59.69, 8.8, 64.95, 11.74);
        context.bezierCurveTo(70.22, 14.67, 68.74, 19.96, 67.12, 21.77);
        context.bezierCurveTo(65.62, 23.43, 58.58, 26.96, 54.08, 22.46);
        context.lineTo(55.87, 15.56);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Bezier 2920 Drawing
        context.beginPath();
        context.moveTo(55.87, 15.56);
        context.bezierCurveTo(55.87, 15.56, 59.69, 8.8, 64.95, 11.74);
        context.bezierCurveTo(70.22, 14.67, 68.74, 19.96, 67.12, 21.77);
        context.bezierCurveTo(65.62, 23.43, 58.58, 26.96, 54.08, 22.46);
        context.lineTo(55.87, 15.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2921 Drawing
        context.beginPath();
        context.moveTo(57.95, 16.42);
        context.bezierCurveTo(57.95, 16.42, 60.31, 12.24, 63.57, 14.06);
        context.bezierCurveTo(66.83, 15.87, 65.91, 19.14, 64.91, 20.27);
        context.bezierCurveTo(63.99, 21.3, 59.62, 23.48, 56.84, 20.69);
        context.lineTo(57.95, 16.42);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2922 Drawing
        context.beginPath();
        context.moveTo(57.95, 16.42);
        context.bezierCurveTo(57.95, 16.42, 60.31, 12.24, 63.57, 14.06);
        context.bezierCurveTo(66.83, 15.87, 65.91, 19.14, 64.91, 20.27);
        context.bezierCurveTo(63.99, 21.3, 59.62, 23.48, 56.84, 20.69);
        context.lineTo(57.95, 16.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2923 Drawing
        context.beginPath();
        context.moveTo(54.83, 24.59);
        context.bezierCurveTo(54.83, 25.07, 55.22, 25.47, 55.7, 25.47);
        context.lineTo(63.6, 25.47);
        context.bezierCurveTo(64.08, 25.47, 64.48, 25.07, 64.48, 24.59);
        context.lineTo(64.48, 24.33);
        context.bezierCurveTo(64.48, 23.85, 64.08, 23.46, 63.6, 23.46);
        context.lineTo(55.7, 23.46);
        context.bezierCurveTo(55.22, 23.46, 54.83, 23.85, 54.83, 24.33);
        context.lineTo(54.83, 24.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2924 Drawing
        context.beginPath();
        context.moveTo(54.83, 24.59);
        context.bezierCurveTo(54.83, 25.07, 55.22, 25.47, 55.7, 25.47);
        context.lineTo(63.6, 25.47);
        context.bezierCurveTo(64.08, 25.47, 64.48, 25.07, 64.48, 24.59);
        context.lineTo(64.48, 24.33);
        context.bezierCurveTo(64.48, 23.85, 64.08, 23.46, 63.6, 23.46);
        context.lineTo(55.7, 23.46);
        context.bezierCurveTo(55.22, 23.46, 54.83, 23.85, 54.83, 24.33);
        context.lineTo(54.83, 24.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2925 Drawing
        context.beginPath();
        context.moveTo(60.87, 21.34);
        context.bezierCurveTo(59.64, 12.82, 49.14, 10.62, 41.7, 10.14);
        context.bezierCurveTo(40.47, 1.76, 32.43, 0.72, 32.43, 0.72);
        context.bezierCurveTo(32.43, 0.72, 33.93, 3.8, 35.01, 5.71);
        context.bezierCurveTo(35.01, 5.71, 32.6, 2.47, 27.77, 2.14);
        context.bezierCurveTo(27.77, 2.14, 30.35, 5.38, 31.68, 6.88);
        context.bezierCurveTo(31.68, 6.88, 27.36, 3.55, 25.61, 4.47);
        context.bezierCurveTo(25.61, 4.47, 28.6, 7.06, 27.81, 10.08);
        context.bezierCurveTo(20.35, 10.42, 8.88, 12.41, 7.59, 21.34);
        context.bezierCurveTo(5.66, 34.69, 15.77, 39.02, 15.77, 39.02);
        context.bezierCurveTo(15.77, 39.02, 23.19, 42.18, 29.9, 35.77);
        context.bezierCurveTo(29.9, 35.77, 32.06, 34, 34.23, 34);
        context.lineTo(34.23, 34);
        context.bezierCurveTo(36.39, 34, 38.56, 35.77, 38.56, 35.77);
        context.bezierCurveTo(45.27, 42.18, 52.68, 39.02, 52.68, 39.02);
        context.bezierCurveTo(52.68, 39.02, 62.8, 34.69, 60.87, 21.34);
        context.closePath();
        context.fillStyle = fillColor25;
        context.fill();


        //// Group 69
        context.save();

        //// Clip Clip 67

        context.beginPath();
        context.moveTo(35.01, 5.71);
        context.bezierCurveTo(35.01, 5.71, 32.59, 2.47, 27.77, 2.14);
        context.lineTo(27.77, 2.14);
        context.bezierCurveTo(27.77, 2.14, 30.35, 5.38, 31.68, 6.88);
        context.lineTo(31.68, 6.88);
        context.bezierCurveTo(31.68, 6.88, 27.36, 3.55, 25.61, 4.46);
        context.lineTo(25.61, 4.46);
        context.bezierCurveTo(25.61, 4.46, 28.6, 7.06, 27.81, 10.08);
        context.lineTo(27.81, 10.08);
        context.bezierCurveTo(20.34, 10.42, 8.88, 12.41, 7.59, 21.34);
        context.lineTo(7.59, 21.34);
        context.bezierCurveTo(5.66, 34.69, 15.77, 39.02, 15.77, 39.02);
        context.lineTo(15.77, 39.02);
        context.bezierCurveTo(15.77, 39.02, 23.19, 42.18, 29.9, 35.77);
        context.lineTo(29.9, 35.77);
        context.bezierCurveTo(29.9, 35.77, 32.06, 34, 34.22, 34);
        context.lineTo(34.22, 34);
        context.lineTo(34.23, 34);
        context.bezierCurveTo(36.39, 34, 38.55, 35.77, 38.55, 35.77);
        context.lineTo(38.55, 35.77);
        context.bezierCurveTo(45.27, 42.18, 52.68, 39.02, 52.68, 39.02);
        context.lineTo(52.68, 39.02);
        context.bezierCurveTo(52.68, 39.02, 62.8, 34.69, 60.87, 21.34);
        context.lineTo(60.87, 21.34);
        context.bezierCurveTo(59.63, 12.82, 49.14, 10.62, 41.7, 10.14);
        context.lineTo(41.7, 10.14);
        context.bezierCurveTo(40.47, 1.76, 32.43, 0.72, 32.43, 0.72);
        context.lineTo(32.43, 0.72);
        context.bezierCurveTo(32.43, 0.72, 33.92, 3.8, 35.01, 5.71);
        context.closePath();
        context.clip();



        //// Bezier 2926 Drawing
        context.beginPath();
        context.moveTo(9.32, 33.14);
        context.bezierCurveTo(9.33, 33.21, 9.33, 33.24, 9.33, 33.24);
        context.bezierCurveTo(9.34, 33.23, 9.34, 33.2, 9.32, 33.14);
        context.closePath();
        context.fillStyle = fillColor50;
        context.fill();


        //// Bezier 2927 Drawing
        context.beginPath();
        context.moveTo(34.38, 18.21);
        context.bezierCurveTo(37.59, 13.03, 48.5, 14.84, 48.5, 14.84);
        context.bezierCurveTo(58.41, 17.06, 55.65, 24.21, 55.65, 24.21);
        context.bezierCurveTo(60.22, 24.36, 59.57, 32.18, 59.47, 33.14);
        context.bezierCurveTo(59.76, 32.04, 64.97, 21.55, 64.97, 21.55);
        context.lineTo(64.97, 9.47);
        context.lineTo(59.58, 9.47);
        context.lineTo(34.42, -0.53);
        context.lineTo(34.38, -0.53);
        context.lineTo(9.21, 9.47);
        context.lineTo(3.82, 9.47);
        context.lineTo(3.82, 21.55);
        context.bezierCurveTo(3.82, 21.55, 9.04, 32.04, 9.32, 33.14);
        context.bezierCurveTo(9.22, 32.18, 8.58, 24.36, 13.14, 24.21);
        context.bezierCurveTo(13.14, 24.21, 10.39, 17.06, 20.29, 14.84);
        context.bezierCurveTo(20.29, 14.84, 31.16, 13.03, 34.38, 18.21);
        context.closePath();
        context.fillStyle = fillColor50;
        context.fill();


        //// Bezier 2928 Drawing
        context.beginPath();
        context.moveTo(34.38, 18.21);
        context.bezierCurveTo(37.59, 13.03, 48.5, 14.84, 48.5, 14.84);
        context.bezierCurveTo(58.41, 17.06, 55.65, 24.21, 55.65, 24.21);
        context.bezierCurveTo(60.22, 24.36, 59.57, 32.18, 59.47, 33.14);
        context.bezierCurveTo(59.76, 32.04, 64.97, 21.55, 64.97, 21.55);
        context.lineTo(64.97, 9.47);
        context.lineTo(59.58, 9.47);
        context.lineTo(34.42, -0.53);
        context.lineTo(34.38, -0.53);
        context.lineTo(9.21, 9.47);
        context.lineTo(3.82, 9.47);
        context.lineTo(3.82, 21.55);
        context.bezierCurveTo(3.82, 21.55, 9.04, 32.04, 9.32, 33.14);
        context.bezierCurveTo(9.22, 32.18, 8.58, 24.36, 13.14, 24.21);
        context.bezierCurveTo(13.14, 24.21, 10.39, 17.06, 20.29, 14.84);
        context.bezierCurveTo(20.29, 14.84, 31.16, 13.03, 34.38, 18.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.03;
        context.stroke();


        //// Bezier 2929 Drawing
        context.beginPath();
        context.moveTo(60.58, 33.24);
        context.bezierCurveTo(60.58, 33.24, 60.58, 33.21, 60.59, 33.14);
        context.bezierCurveTo(60.58, 33.2, 60.57, 33.23, 60.58, 33.24);
        context.closePath();
        context.fillStyle = fillColor50;
        context.fill();


        context.restore();



        //// Bezier 2931 Drawing
        context.beginPath();
        context.moveTo(60.87, 21.34);
        context.bezierCurveTo(59.64, 12.82, 49.14, 10.62, 41.7, 10.14);
        context.bezierCurveTo(40.47, 1.76, 32.43, 0.72, 32.43, 0.72);
        context.bezierCurveTo(32.43, 0.72, 33.93, 3.8, 35.01, 5.71);
        context.bezierCurveTo(35.01, 5.71, 32.6, 2.47, 27.77, 2.14);
        context.bezierCurveTo(27.77, 2.14, 30.35, 5.38, 31.68, 6.88);
        context.bezierCurveTo(31.68, 6.88, 27.36, 3.55, 25.61, 4.47);
        context.bezierCurveTo(25.61, 4.47, 28.6, 7.06, 27.81, 10.08);
        context.bezierCurveTo(20.35, 10.42, 8.88, 12.41, 7.59, 21.34);
        context.bezierCurveTo(5.66, 34.69, 15.77, 39.02, 15.77, 39.02);
        context.bezierCurveTo(15.77, 39.02, 23.19, 42.18, 29.9, 35.77);
        context.bezierCurveTo(29.9, 35.77, 32.06, 34, 34.23, 34);
        context.lineTo(34.23, 34);
        context.bezierCurveTo(36.39, 34, 38.56, 35.77, 38.56, 35.77);
        context.bezierCurveTo(45.27, 42.18, 52.68, 39.02, 52.68, 39.02);
        context.bezierCurveTo(52.68, 39.02, 62.8, 34.69, 60.87, 21.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 668 Drawing
        oval(context, 17.5, 21.18, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 669 Drawing
        oval(context, 18.75, 22.43, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 670 Drawing
        oval(context, 44.4, 21.18, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 671 Drawing
        oval(context, 45.65, 22.43, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 672 Drawing
        oval(context, 29.95, 29.08, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 673 Drawing
        oval(context, 35.55, 29.08, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawUnicornMask(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 65, 54), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 65, resizedFrame.h / 54);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';
        var fillColor33 = 'rgba(248, 248, 117, 1)';

        //// Bezier 2718 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2719 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2720 Drawing
        context.beginPath();
        context.moveTo(6.97, 29.39);
        context.bezierCurveTo(6.97, 29.39, 2.84, 19.04, 9.05, 16.45);
        context.bezierCurveTo(15.26, 13.87, 21.36, 23.53, 21.36, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2721 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2722 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2723 Drawing
        context.beginPath();
        context.moveTo(57.29, 29.39);
        context.bezierCurveTo(57.29, 29.39, 61.42, 19.04, 55.21, 16.45);
        context.bezierCurveTo(49, 13.87, 42.9, 23.53, 42.9, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2724 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2725 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2726 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2727 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2728 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();


        //// Bezier 2729 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2730 Drawing
        context.beginPath();
        context.moveTo(18.57, 33.32);
        context.bezierCurveTo(18.57, 33.32, 15.72, 32.02, 14.64, 30.27);
        context.bezierCurveTo(14.64, 30.27, 14.72, 32.52, 15.55, 33.02);
        context.bezierCurveTo(15.55, 33.02, 13.62, 32.63, 13.31, 32.02);
        context.bezierCurveTo(13.31, 32.02, 13.98, 34.46, 15.4, 35.46);
        context.bezierCurveTo(15.4, 35.46, 16.41, 33.24, 18.57, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2731 Drawing
        context.beginPath();
        context.moveTo(44.31, 33.32);
        context.bezierCurveTo(44.31, 33.32, 47.16, 32.02, 48.24, 30.27);
        context.bezierCurveTo(48.24, 30.27, 48.16, 32.52, 47.33, 33.02);
        context.bezierCurveTo(47.33, 33.02, 49.25, 32.63, 49.57, 32.02);
        context.bezierCurveTo(49.57, 32.02, 48.89, 34.46, 47.48, 35.46);
        context.bezierCurveTo(47.48, 35.46, 46.47, 33.24, 44.31, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 622 Drawing
        oval(context, 15.2, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 623 Drawing
        oval(context, 16.45, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 624 Drawing
        oval(context, 42.1, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 625 Drawing
        oval(context, 43.35, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2732 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.lineTo(26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Group 61
        context.save();

        //// Clip Clip 59

        context.beginPath();
        context.moveTo(31.7, 1.25);
        context.lineTo(26.53, 27.62);
        context.lineTo(38.07, 27.62);
        context.lineTo(32.5, 1.25);
        context.bezierCurveTo(32.45, 1.03, 32.27, 0.92, 32.1, 0.92);
        context.lineTo(32.1, 0.92);
        context.bezierCurveTo(31.92, 0.92, 31.74, 1.03, 31.7, 1.25);
        context.closePath();
        context.clip();



        //// Bezier 2733 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2734 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2735 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2736 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2737 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2738 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2739 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 2740 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2741 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2742 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2743 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2744 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        context.restore();



        //// Bezier 2747 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2748 Drawing
        context.beginPath();
        context.moveTo(11.31, 36.59);
        context.bezierCurveTo(11.31, 37.07, 10.91, 37.47, 10.43, 37.47);
        context.lineTo(2.53, 37.47);
        context.bezierCurveTo(2.05, 37.47, 1.66, 37.07, 1.66, 36.59);
        context.lineTo(1.66, 36.33);
        context.bezierCurveTo(1.66, 35.85, 2.05, 35.46, 2.53, 35.46);
        context.lineTo(10.43, 35.46);
        context.bezierCurveTo(10.91, 35.46, 11.31, 35.85, 11.31, 36.33);
        context.lineTo(11.31, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2749 Drawing
        context.beginPath();
        context.moveTo(6.97, 29.39);
        context.bezierCurveTo(6.97, 29.39, 2.84, 19.04, 9.05, 16.45);
        context.bezierCurveTo(15.26, 13.87, 21.36, 23.53, 21.36, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2750 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2751 Drawing
        context.beginPath();
        context.moveTo(9.55, 26.61);
        context.bezierCurveTo(9.55, 26.61, 8.33, 21.13, 11.14, 20.66);
        context.bezierCurveTo(13.95, 20.2, 16.49, 22.7, 16.49, 22.7);
        context.lineTo(9.55, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2752 Drawing
        context.beginPath();
        context.moveTo(57.29, 29.39);
        context.bezierCurveTo(57.29, 29.39, 61.42, 19.04, 55.21, 16.45);
        context.bezierCurveTo(49, 13.87, 42.9, 23.53, 42.9, 23.53);
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2753 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2754 Drawing
        context.beginPath();
        context.moveTo(54.71, 26.61);
        context.bezierCurveTo(54.71, 26.61, 55.93, 21.13, 53.12, 20.66);
        context.bezierCurveTo(50.31, 20.2, 47.76, 22.7, 47.76, 22.7);
        context.lineTo(54.71, 26.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2755 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2756 Drawing
        context.beginPath();
        context.moveTo(52.51, 36.59);
        context.bezierCurveTo(52.51, 37.07, 52.91, 37.47, 53.39, 37.47);
        context.lineTo(61.28, 37.47);
        context.bezierCurveTo(61.77, 37.47, 62.16, 37.07, 62.16, 36.59);
        context.lineTo(62.16, 36.33);
        context.bezierCurveTo(62.16, 35.85, 61.77, 35.46, 61.28, 35.46);
        context.lineTo(53.39, 35.46);
        context.bezierCurveTo(52.91, 35.46, 52.51, 35.85, 52.51, 36.33);
        context.lineTo(52.51, 36.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2757 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair19;
        context.fill();


        //// Bezier 2758 Drawing
        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 4
        //// Bezier 2761 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.lineTo(26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Group 62
        context.save();

        //// Clip Clip 60

        context.beginPath();
        context.moveTo(31.7, 1.25);
        context.lineTo(26.53, 27.62);
        context.bezierCurveTo(26.53, 27.62, 29.53, 28.62, 32.41, 28.62);
        context.bezierCurveTo(35.29, 28.62, 38.07, 27.62, 38.07, 27.62);
        context.lineTo(32.5, 1.25);
        context.bezierCurveTo(32.45, 1.03, 32.27, 0.92, 32.1, 0.92);
        context.lineTo(32.1, 0.92);
        context.bezierCurveTo(31.92, 0.92, 31.74, 1.03, 31.7, 1.25);
        context.closePath();
        context.clip();



        //// Bezier 2762 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2763 Drawing
        context.beginPath();
        context.moveTo(24.87, 7.97);
        context.bezierCurveTo(24.87, 7.97, 37.38, 6.17, 37.38, 2.07);
        context.lineTo(39.04, 2.62);
        context.lineTo(41.16, 7.05);
        context.lineTo(41.3, 12.64);
        context.lineTo(25.98, 12.13);
        context.lineTo(24.87, 7.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2764 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2765 Drawing
        context.beginPath();
        context.moveTo(24.87, 12.53);
        context.bezierCurveTo(24.87, 12.53, 37.38, 10.73, 37.38, 6.62);
        context.lineTo(39.04, 7.17);
        context.lineTo(41.16, 11.6);
        context.lineTo(41.3, 17.19);
        context.lineTo(25.98, 16.68);
        context.lineTo(24.87, 12.53);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2766 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2767 Drawing
        context.beginPath();
        context.moveTo(24.87, 16.97);
        context.bezierCurveTo(24.87, 16.97, 37.38, 15.17, 37.38, 11.06);
        context.lineTo(39.04, 11.61);
        context.lineTo(41.16, 16.04);
        context.lineTo(41.3, 21.63);
        context.lineTo(25.98, 21.12);
        context.lineTo(24.87, 16.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2768 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Bezier 2769 Drawing
        context.beginPath();
        context.moveTo(24.87, 21.41);
        context.bezierCurveTo(24.87, 21.41, 37.38, 19.61, 37.38, 15.5);
        context.lineTo(39.04, 16.05);
        context.lineTo(41.16, 20.48);
        context.lineTo(41.3, 26.07);
        context.lineTo(25.98, 25.56);
        context.lineTo(24.87, 21.41);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2770 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2771 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        //// Bezier 2772 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2773 Drawing
        context.beginPath();
        context.moveTo(24.87, 25.85);
        context.bezierCurveTo(24.87, 25.85, 37.38, 24.05, 37.38, 19.94);
        context.lineTo(39.04, 20.49);
        context.lineTo(41.16, 24.93);
        context.lineTo(41.3, 30.51);
        context.lineTo(25.98, 30);
        context.lineTo(24.87, 25.85);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();


        context.restore();



        //// Bezier 2775 Drawing
        context.beginPath();
        context.moveTo(38.07, 27.62);
        context.bezierCurveTo(38.07, 27.62, 35.27, 28.62, 32.39, 28.62);
        context.bezierCurveTo(29.5, 28.62, 26.53, 27.62, 26.53, 27.62);
        context.lineTo(31.7, 1.25);
        context.bezierCurveTo(31.78, 0.82, 32.41, 0.81, 32.5, 1.25);
        context.lineTo(38.07, 27.62);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.11;
        context.stroke();




        //// Group 3
        //// Bezier 5 Drawing
        context.beginPath();
        context.moveTo(43.5, 29.5);
        context.bezierCurveTo(43.5, 29.5, 37.5, 20.5, 40.5, 17.5);
        context.bezierCurveTo(43.5, 14.5, 47.5, 17.5, 47.5, 17.5);
        context.bezierCurveTo(47.5, 17.5, 43.5, 20.5, 44.5, 23.5);
        context.bezierCurveTo(45.5, 26.5, 43.5, 29.5, 43.5, 29.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 3 Drawing
        context.beginPath();
        context.moveTo(23.5, 17.5);
        context.bezierCurveTo(23.5, 18.61, 24.95, 22.71, 25.5, 26.5);
        context.bezierCurveTo(25.94, 29.52, 29.5, 32.5, 29.5, 32.5);
        context.bezierCurveTo(29.5, 32.5, 30.5, 27.5, 29.5, 24.5);
        context.bezierCurveTo(29.18, 23.53, 28.65, 23.79, 28.5, 22.5);
        context.bezierCurveTo(28.19, 19.79, 28.5, 15.5, 28.5, 15.5);
        context.bezierCurveTo(28.5, 15.5, 23.5, 15.5, 23.5, 17.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair4;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2 Drawing
        context.beginPath();
        context.moveTo(22.5, 32.5);
        context.bezierCurveTo(22.5, 32.5, 21.5, 24.5, 20.5, 22.5);
        context.bezierCurveTo(19.5, 20.5, 18.5, 19.5, 18.5, 19.5);
        context.bezierCurveTo(18.5, 19.5, 22.5, 15.5, 24.5, 19.5);
        context.bezierCurveTo(26.5, 23.5, 26.5, 21.5, 25.5, 25.5);
        context.bezierCurveTo(24.5, 29.5, 22.5, 32.5, 22.5, 32.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col9;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 6 Drawing
        context.beginPath();
        context.moveTo(37.5, 22.5);
        context.bezierCurveTo(37.5, 22.5, 33.5, 25.5, 31.5, 27.5);
        context.bezierCurveTo(29.5, 29.5, 31.5, 32.5, 31.5, 32.5);
        context.bezierCurveTo(31.5, 32.5, 36.5, 29.5, 37.5, 26.5);
        context.bezierCurveTo(38.5, 23.5, 37.5, 22.5, 37.5, 22.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 4 Drawing
        context.beginPath();
        context.moveTo(35.5, 15.5);
        context.bezierCurveTo(35.5, 15.5, 36.5, 18.5, 37.5, 22.5);
        context.bezierCurveTo(38.5, 26.5, 35.5, 31.5, 35.5, 31.5);
        context.bezierCurveTo(35.5, 31.5, 43.5, 26.5, 42.5, 23.5);
        context.bezierCurveTo(41.5, 20.5, 39.5, 16.5, 38.5, 15.5);
        context.bezierCurveTo(37.5, 14.5, 35.5, 15.5, 35.5, 15.5);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col7;
        context.fill();
        context.strokeStyle = 'rgb(0, 0, 0)';
        context.lineWidth = 1;
        context.stroke();




        //// Group
        context.save();

        //// Clip Bezier

        context.beginPath();
        context.moveTo(31.92, 46);
        context.bezierCurveTo(34.08, 46, 36.24, 47.77, 36.24, 47.77);
        context.bezierCurveTo(42.96, 54.18, 50.37, 51.02, 50.37, 51.02);
        context.bezierCurveTo(50.37, 51.02, 60.48, 46.69, 58.55, 33.34);
        context.bezierCurveTo(56.62, 19.98, 31.92, 22.15, 31.92, 22.15);
        context.lineTo(31.91, 22.15);
        context.bezierCurveTo(31.91, 22.15, 7.21, 19.98, 5.27, 33.34);
        context.bezierCurveTo(3.35, 46.69, 13.46, 51.02, 13.46, 51.02);
        context.bezierCurveTo(13.46, 51.02, 20.87, 54.18, 27.59, 47.77);
        context.bezierCurveTo(27.59, 47.77, 29.75, 46, 31.91, 46);
        context.lineTo(31.92, 46);
        context.closePath();
        context.clip();



        //// Oval 654 Drawing
        oval(context, 17.85, 39.98, 28.15, 17.02);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 2
        //// Oval 656 Drawing
        oval(context, 23.5, 44.53, 2, 2);
        context.fillStyle = strokeColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval Drawing
        oval(context, 38.35, 45, 2, 2);
        context.fillStyle = strokeColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();




        //// Star Drawing
        context.beginPath();
        context.moveTo(48, 42);
        context.lineTo(48.71, 43.03);
        context.lineTo(49.9, 43.38);
        context.lineTo(49.14, 44.37);
        context.lineTo(49.18, 45.62);
        context.lineTo(48, 45.2);
        context.lineTo(46.82, 45.62);
        context.lineTo(46.86, 44.37);
        context.lineTo(46.1, 43.38);
        context.lineTo(47.29, 43.03);
        context.closePath();
        context.fillStyle = fillColor33;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        //// Star 2 Drawing
        context.beginPath();
        context.moveTo(11.25, 37.75);
        context.lineTo(11.96, 38.78);
        context.lineTo(13.15, 39.13);
        context.lineTo(12.39, 40.12);
        context.lineTo(12.43, 41.37);
        context.lineTo(11.25, 40.95);
        context.lineTo(10.07, 41.37);
        context.lineTo(10.11, 40.12);
        context.lineTo(9.35, 39.13);
        context.lineTo(10.54, 38.78);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.5;
        context.stroke();


        context.restore();



        //// Bezier 2759 Drawing
        context.beginPath();
        context.moveTo(18.57, 33.32);
        context.bezierCurveTo(18.57, 33.32, 15.72, 32.02, 14.64, 30.27);
        context.bezierCurveTo(14.64, 30.27, 14.72, 32.52, 15.55, 33.02);
        context.bezierCurveTo(15.55, 33.02, 13.62, 32.63, 13.31, 32.02);
        context.bezierCurveTo(13.31, 32.02, 13.98, 34.46, 15.4, 35.46);
        context.bezierCurveTo(15.4, 35.46, 16.41, 33.24, 18.57, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2760 Drawing
        context.beginPath();
        context.moveTo(44.31, 33.32);
        context.bezierCurveTo(44.31, 33.32, 47.16, 32.02, 48.24, 30.27);
        context.bezierCurveTo(48.24, 30.27, 48.16, 32.52, 47.33, 33.02);
        context.bezierCurveTo(47.33, 33.02, 49.25, 32.63, 49.57, 32.02);
        context.bezierCurveTo(49.57, 32.02, 48.89, 34.46, 47.48, 35.46);
        context.bezierCurveTo(47.48, 35.46, 46.47, 33.24, 44.31, 33.32);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 626 Drawing
        oval(context, 15.2, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 627 Drawing
        oval(context, 16.45, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();


        //// Oval 628 Drawing
        oval(context, 42.1, 33.19, 5.4, 5.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 629 Drawing
        oval(context, 43.35, 34.44, 2.9, 2.9);
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawFlowerShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1774 Drawing
        context.beginPath();
        context.moveTo(38.96, 4.96);
        context.bezierCurveTo(37.23, 8.35, 33.72, 10.68, 29.65, 10.68);
        context.bezierCurveTo(25.58, 10.68, 22.06, 8.35, 20.33, 4.96);
        context.bezierCurveTo(15.8, 5.72, 1.84, 8.14, 1.27, 24.44);
        context.lineTo(29.65, 24.44);
        context.lineTo(58.02, 24.44);
        context.bezierCurveTo(57.46, 8.14, 43.5, 5.72, 38.96, 4.96);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1775 Drawing
        context.beginPath();
        context.moveTo(38.96, 4.96);
        context.bezierCurveTo(37.23, 8.35, 33.72, 10.68, 29.65, 10.68);
        context.bezierCurveTo(25.58, 10.68, 22.06, 8.35, 20.33, 4.96);
        context.bezierCurveTo(15.8, 5.72, 1.84, 8.14, 1.27, 24.44);
        context.lineTo(29.65, 24.44);
        context.lineTo(58.02, 24.44);
        context.bezierCurveTo(57.46, 8.14, 43.5, 5.72, 38.96, 4.96);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2198 Drawing
        context.beginPath();
        context.moveTo(29.65, 10.68);
        context.bezierCurveTo(33.68, 10.68, 37.16, 8.39, 38.91, 5.05);
        context.lineTo(38.91, 4.92);
        context.bezierCurveTo(36.2, 3.56, 36.62, 2.4, 37.21, 1.33);
        context.bezierCurveTo(34.81, 2.08, 32.04, 2.41, 29.41, 2.41);
        context.bezierCurveTo(26.79, 2.41, 24.5, 2.08, 22.1, 1.34);
        context.bezierCurveTo(22.7, 2.41, 23.11, 3.57, 20.41, 4.92);
        context.lineTo(20.42, 5.1);
        context.bezierCurveTo(22.17, 8.42, 25.64, 10.68, 29.65, 10.68);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2199 Drawing
        context.beginPath();
        context.moveTo(29.65, 10.68);
        context.bezierCurveTo(33.68, 10.68, 37.16, 8.39, 38.91, 5.05);
        context.lineTo(38.91, 4.92);
        context.bezierCurveTo(36.2, 3.56, 36.62, 2.4, 37.21, 1.33);
        context.bezierCurveTo(34.81, 2.08, 32.04, 2.41, 29.41, 2.41);
        context.bezierCurveTo(26.79, 2.41, 24.5, 2.08, 22.1, 1.34);
        context.bezierCurveTo(22.7, 2.41, 23.11, 3.57, 20.41, 4.92);
        context.lineTo(20.42, 5.1);
        context.bezierCurveTo(22.17, 8.42, 25.64, 10.68, 29.65, 10.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2293 Drawing
        context.beginPath();
        context.moveTo(46.74, 12.14);
        context.bezierCurveTo(47.68, 12.12, 48.39, 12.45, 48.75, 13.32);
        context.bezierCurveTo(49.1, 14.18, 48.82, 14.9, 48.17, 15.54);
        context.bezierCurveTo(48.85, 16.21, 49.12, 16.97, 48.71, 17.86);
        context.bezierCurveTo(48.39, 18.57, 47.86, 18.87, 46.76, 18.97);
        context.bezierCurveTo(46.77, 19.84, 46.46, 20.57, 45.59, 20.94);
        context.bezierCurveTo(44.72, 21.31, 43.99, 21.02, 43.35, 20.37);
        context.bezierCurveTo(42.7, 21.03, 41.96, 21.31, 41.1, 20.94);
        context.bezierCurveTo(40.25, 20.58, 39.92, 19.88, 39.94, 18.95);
        context.bezierCurveTo(39.03, 18.97, 38.31, 18.65, 37.95, 17.78);
        context.bezierCurveTo(37.59, 16.93, 37.85, 16.2, 38.52, 15.55);
        context.bezierCurveTo(37.89, 14.92, 37.58, 14.2, 37.94, 13.32);
        context.bezierCurveTo(38.3, 12.46, 39, 12.11, 39.94, 12.14);
        context.bezierCurveTo(39.92, 11.18, 40.28, 10.45, 41.2, 10.11);
        context.bezierCurveTo(42.02, 9.8, 42.72, 10.08, 43.34, 10.71);
        context.bezierCurveTo(43.97, 10.08, 44.7, 9.78, 45.57, 10.14);
        context.bezierCurveTo(46.42, 10.5, 46.77, 11.18, 46.74, 12.14);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Oval 544 Drawing
        oval(context, 41.75, 13.93, 3.2, 3.2);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2294 Drawing
        context.beginPath();
        context.moveTo(44.64, 15.56);
        context.bezierCurveTo(44.65, 14.86, 44.06, 14.26, 43.36, 14.25);
        context.bezierCurveTo(42.66, 14.24, 42.06, 14.83, 42.05, 15.53);
        context.bezierCurveTo(42.04, 16.23, 42.63, 16.83, 43.33, 16.84);
        context.bezierCurveTo(44.03, 16.85, 44.63, 16.26, 44.64, 15.56);
        context.closePath();
        context.moveTo(43.09, 12.51);
        context.bezierCurveTo(43.09, 12.26, 43.05, 12.01, 43.09, 11.77);
        context.bezierCurveTo(43.25, 10.86, 42.21, 10.39, 41.58, 10.52);
        context.bezierCurveTo(41.04, 10.64, 40.67, 10.95, 40.51, 11.48);
        context.bezierCurveTo(40.37, 11.96, 40.45, 12.37, 40.87, 12.72);
        context.bezierCurveTo(41.29, 13.08, 41.67, 13.5, 42.06, 13.91);
        context.bezierCurveTo(42.22, 14.08, 42.34, 14.07, 42.58, 14.01);
        context.bezierCurveTo(43.06, 13.87, 43.15, 13.58, 43.09, 13.16);
        context.bezierCurveTo(43.07, 12.94, 43.09, 12.72, 43.09, 12.51);
        context.closePath();
        context.moveTo(45.09, 15.8);
        context.bezierCurveTo(45.05, 15.94, 45.02, 16.01, 44.96, 16.18);
        context.bezierCurveTo(44.84, 16.58, 44.86, 16.73, 45.12, 16.97);
        context.bezierCurveTo(45.54, 17.36, 45.93, 17.79, 46.35, 18.18);
        context.bezierCurveTo(46.48, 18.3, 46.67, 18.39, 46.84, 18.41);
        context.bezierCurveTo(47.53, 18.51, 48.16, 18.08, 48.35, 17.41);
        context.bezierCurveTo(48.53, 16.73, 48.06, 15.83, 47.48, 15.81);
        context.bezierCurveTo(46.69, 15.78, 45.9, 15.8, 45.09, 15.8);
        context.closePath();
        context.moveTo(41.5, 15.8);
        context.lineTo(39.54, 15.8);
        context.bezierCurveTo(38.89, 15.8, 38.49, 16.11, 38.34, 16.74);
        context.bezierCurveTo(38.19, 17.32, 38.46, 17.94, 38.99, 18.25);
        context.bezierCurveTo(39.54, 18.57, 40.04, 18.49, 40.53, 18);
        context.bezierCurveTo(40.8, 17.73, 41.04, 17.44, 41.33, 17.21);
        context.bezierCurveTo(41.81, 16.82, 42.07, 16.42, 41.5, 15.8);
        context.closePath();
        context.moveTo(46.35, 15.29);
        context.bezierCurveTo(46.69, 15.29, 47.04, 15.3, 47.38, 15.28);
        context.bezierCurveTo(47.55, 15.27, 47.73, 15.21, 47.85, 15.12);
        context.bezierCurveTo(48.29, 14.78, 48.46, 14.31, 48.36, 13.77);
        context.bezierCurveTo(48.27, 13.25, 47.96, 12.84, 47.44, 12.74);
        context.bezierCurveTo(47.12, 12.67, 46.67, 12.68, 46.44, 12.86);
        context.bezierCurveTo(45.88, 13.28, 45.41, 13.83, 44.92, 14.33);
        context.bezierCurveTo(44.86, 14.4, 44.81, 14.54, 44.83, 14.62);
        context.bezierCurveTo(45.02, 15.29, 45.02, 15.29, 45.74, 15.29);
        context.bezierCurveTo(45.94, 15.29, 46.14, 15.29, 46.35, 15.29);
        context.closePath();
        context.moveTo(40.34, 15.29);
        context.bezierCurveTo(40.68, 15.29, 41.01, 15.27, 41.34, 15.29);
        context.bezierCurveTo(41.82, 15.32, 41.8, 14.92, 41.84, 14.65);
        context.bezierCurveTo(41.85, 14.6, 41.81, 14.39, 41.73, 14.31);
        context.bezierCurveTo(41.31, 13.86, 40.85, 13.44, 40.43, 12.98);
        context.bezierCurveTo(40.16, 12.69, 39.86, 12.62, 39.5, 12.66);
        context.bezierCurveTo(38.78, 12.75, 38.27, 13.38, 38.31, 14.12);
        context.bezierCurveTo(38.35, 14.86, 38.82, 15.29, 39.61, 15.29);
        context.bezierCurveTo(39.85, 15.29, 40.1, 15.29, 40.34, 15.29);
        context.closePath();
        context.moveTo(43.6, 13.79);
        context.bezierCurveTo(43.76, 13.85, 43.88, 13.91, 44.02, 13.95);
        context.bezierCurveTo(44.46, 14.05, 44.49, 14.07, 44.71, 13.83);
        context.bezierCurveTo(45.12, 13.38, 45.57, 12.97, 45.99, 12.54);
        context.bezierCurveTo(46.09, 12.43, 46.18, 12.28, 46.21, 12.14);
        context.bezierCurveTo(46.36, 11.41, 45.89, 10.7, 45.17, 10.53);
        context.bezierCurveTo(44.5, 10.37, 43.63, 10.84, 43.61, 11.41);
        context.bezierCurveTo(43.58, 12.2, 43.6, 12.99, 43.6, 13.79);
        context.closePath();
        context.moveTo(43.09, 18.62);
        context.bezierCurveTo(43.09, 18.27, 43.07, 17.93, 43.1, 17.58);
        context.bezierCurveTo(43.14, 17.06, 42.7, 17.09, 42.48, 17.07);
        context.bezierCurveTo(42.4, 17.06, 42.19, 17.08, 42.1, 17.16);
        context.bezierCurveTo(41.66, 17.58, 41.25, 18.03, 40.8, 18.43);
        context.bezierCurveTo(40.46, 18.73, 40.4, 19.08, 40.48, 19.49);
        context.bezierCurveTo(40.6, 20.17, 41.26, 20.64, 41.97, 20.58);
        context.bezierCurveTo(42.68, 20.51, 43.09, 20.04, 43.09, 19.31);
        context.lineTo(43.09, 18.62);
        context.closePath();
        context.moveTo(43.6, 18.55);
        context.bezierCurveTo(43.6, 18.88, 43.58, 19.21, 43.61, 19.54);
        context.bezierCurveTo(43.63, 19.73, 43.69, 19.94, 43.8, 20.09);
        context.bezierCurveTo(44.14, 20.5, 44.6, 20.66, 45.12, 20.56);
        context.bezierCurveTo(45.64, 20.46, 46.05, 20.15, 46.15, 19.64);
        context.bezierCurveTo(46.22, 19.32, 46.21, 18.87, 46.03, 18.63);
        context.bezierCurveTo(45.6, 18.08, 45.06, 17.61, 44.55, 17.12);
        context.bezierCurveTo(44.49, 17.05, 44.35, 17.01, 44.26, 17.03);
        context.bezierCurveTo(43.6, 17.22, 43.6, 17.23, 43.6, 17.94);
        context.lineTo(43.6, 18.55);
        context.closePath();
        context.moveTo(46.74, 12.14);
        context.bezierCurveTo(47.68, 12.12, 48.39, 12.45, 48.75, 13.33);
        context.bezierCurveTo(49.1, 14.18, 48.82, 14.9, 48.17, 15.54);
        context.bezierCurveTo(48.85, 16.21, 49.12, 16.97, 48.71, 17.86);
        context.bezierCurveTo(48.39, 18.57, 47.86, 18.87, 46.76, 18.97);
        context.bezierCurveTo(46.77, 19.84, 46.46, 20.57, 45.59, 20.94);
        context.bezierCurveTo(44.72, 21.31, 43.99, 21.02, 43.34, 20.37);
        context.bezierCurveTo(42.7, 21.03, 41.96, 21.31, 41.1, 20.94);
        context.bezierCurveTo(40.25, 20.58, 39.92, 19.88, 39.94, 18.95);
        context.bezierCurveTo(39.03, 18.97, 38.31, 18.65, 37.95, 17.78);
        context.bezierCurveTo(37.59, 16.93, 37.85, 16.2, 38.52, 15.56);
        context.bezierCurveTo(37.89, 14.92, 37.58, 14.2, 37.94, 13.33);
        context.bezierCurveTo(38.3, 12.46, 39, 12.11, 39.94, 12.14);
        context.bezierCurveTo(39.92, 11.18, 40.28, 10.45, 41.2, 10.11);
        context.bezierCurveTo(42.02, 9.81, 42.72, 10.08, 43.34, 10.71);
        context.bezierCurveTo(43.97, 10.08, 44.7, 9.78, 45.57, 10.14);
        context.bezierCurveTo(46.42, 10.5, 46.77, 11.18, 46.74, 12.14);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawPearlsShirt(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1734 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.34);
        context.bezierCurveTo(37.54, 8.73, 34.02, 11.06, 29.95, 11.06);
        context.bezierCurveTo(25.88, 11.06, 22.36, 8.73, 20.63, 5.34);
        context.bezierCurveTo(16.1, 6.1, 2.14, 8.52, 1.58, 24.81);
        context.lineTo(29.95, 24.81);
        context.lineTo(58.32, 24.81);
        context.bezierCurveTo(57.76, 8.52, 43.8, 6.1, 39.26, 5.34);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1735 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.34);
        context.bezierCurveTo(37.54, 8.73, 34.02, 11.06, 29.95, 11.06);
        context.bezierCurveTo(25.88, 11.06, 22.36, 8.73, 20.63, 5.34);
        context.bezierCurveTo(16.1, 6.1, 2.14, 8.52, 1.58, 24.81);
        context.lineTo(29.95, 24.81);
        context.lineTo(58.32, 24.81);
        context.bezierCurveTo(57.76, 8.52, 43.8, 6.1, 39.26, 5.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2158 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.06);
        context.bezierCurveTo(33.98, 11.06, 37.47, 8.77, 39.21, 5.43);
        context.lineTo(39.21, 5.3);
        context.bezierCurveTo(36.5, 3.94, 36.92, 2.78, 37.52, 1.71);
        context.bezierCurveTo(35.11, 2.46, 32.34, 2.79, 29.71, 2.79);
        context.bezierCurveTo(27.09, 2.79, 24.8, 2.46, 22.41, 1.71);
        context.bezierCurveTo(23, 2.78, 23.42, 3.94, 20.71, 5.3);
        context.lineTo(20.72, 5.48);
        context.bezierCurveTo(22.47, 8.79, 25.94, 11.06, 29.95, 11.06);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2159 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.06);
        context.bezierCurveTo(33.98, 11.06, 37.47, 8.77, 39.21, 5.43);
        context.lineTo(39.21, 5.3);
        context.bezierCurveTo(36.5, 3.94, 36.92, 2.78, 37.52, 1.71);
        context.bezierCurveTo(35.11, 2.46, 32.34, 2.79, 29.71, 2.79);
        context.bezierCurveTo(27.09, 2.79, 24.8, 2.46, 22.41, 1.71);
        context.bezierCurveTo(23, 2.78, 23.42, 3.94, 20.71, 5.3);
        context.lineTo(20.72, 5.48);
        context.bezierCurveTo(22.47, 8.79, 25.94, 11.06, 29.95, 11.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 552 Drawing
        context.save();
        context.translate(20.8, 5.98);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 553 Drawing
        context.save();
        context.translate(20.8, 5.98);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 554 Drawing
        context.save();
        context.translate(22.35, 8.13);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 555 Drawing
        context.save();
        context.translate(22.35, 8.13);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 556 Drawing
        context.save();
        context.translate(24.05, 10.03);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 557 Drawing
        context.save();
        context.translate(24.05, 10.03);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 558 Drawing
        context.save();
        context.translate(26.2, 11.18);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 559 Drawing
        context.save();
        context.translate(26.2, 11.18);
        context.rotate(0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 560 Drawing
        context.save();
        context.translate(28.45, 11.83);
        context.rotate(-0.9 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 561 Drawing
        context.save();
        context.translate(28.45, 11.83);
        context.rotate(-0.9 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 562 Drawing
        context.save();
        context.translate(38.5, 5.98);
        context.rotate(0.65 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 563 Drawing
        context.save();
        context.translate(38.5, 5.98);
        context.rotate(0.65 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 564 Drawing
        context.save();
        context.translate(36.95, 8.13);
        context.rotate(0.9 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 565 Drawing
        context.save();
        context.translate(36.95, 8.13);
        context.rotate(0.9 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 566 Drawing
        context.save();
        context.translate(35.25, 10.03);
        context.rotate(0.65 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 567 Drawing
        context.save();
        context.translate(35.25, 10.03);
        context.rotate(0.65 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 568 Drawing
        context.save();
        context.translate(33.1, 11.18);
        context.rotate(0.5 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 569 Drawing
        context.save();
        context.translate(33.1, 11.18);
        context.rotate(0.5 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();


        //// Oval 570 Drawing
        context.save();
        context.translate(30.85, 11.83);
        context.rotate(-0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();

        context.restore();


        //// Oval 571 Drawing
        context.save();
        context.translate(30.85, 11.83);
        context.rotate(-0.55 * Math.PI / 180);

        oval(context, -1.2, -1.2, 2.4, 2.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.16;
        context.stroke();

        context.restore();
        
        context.restore();

    }

    function drawMidStripeShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1758 Drawing
        context.beginPath();
        context.moveTo(38.44, 5.34);
        context.bezierCurveTo(36.72, 8.73, 33.2, 11.06, 29.13, 11.06);
        context.bezierCurveTo(25.06, 11.06, 21.54, 8.73, 19.82, 5.34);
        context.bezierCurveTo(15.28, 6.1, 1.32, 8.52, 0.76, 24.81);
        context.lineTo(29.13, 24.81);
        context.lineTo(57.5, 24.81);
        context.bezierCurveTo(56.94, 8.52, 42.98, 6.1, 38.44, 5.34);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1759 Drawing
        context.beginPath();
        context.moveTo(38.44, 5.34);
        context.bezierCurveTo(36.72, 8.73, 33.2, 11.06, 29.13, 11.06);
        context.bezierCurveTo(25.06, 11.06, 21.54, 8.73, 19.82, 5.34);
        context.bezierCurveTo(15.28, 6.1, 1.32, 8.52, 0.76, 24.81);
        context.lineTo(29.13, 24.81);
        context.lineTo(57.5, 24.81);
        context.bezierCurveTo(56.94, 8.52, 42.98, 6.1, 38.44, 5.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2182 Drawing
        context.beginPath();
        context.moveTo(29.13, 11.06);
        context.bezierCurveTo(33.16, 11.06, 36.65, 8.77, 38.39, 5.43);
        context.lineTo(38.39, 5.3);
        context.bezierCurveTo(35.68, 3.94, 36.1, 2.78, 36.7, 1.71);
        context.bezierCurveTo(34.29, 2.46, 31.52, 2.79, 28.89, 2.79);
        context.bezierCurveTo(26.27, 2.79, 23.98, 2.46, 21.59, 1.71);
        context.bezierCurveTo(22.18, 2.78, 22.6, 3.94, 19.89, 5.3);
        context.lineTo(19.9, 5.48);
        context.bezierCurveTo(21.65, 8.79, 25.12, 11.06, 29.13, 11.06);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2183 Drawing
        context.beginPath();
        context.moveTo(29.13, 11.06);
        context.bezierCurveTo(33.16, 11.06, 36.65, 8.77, 38.39, 5.43);
        context.lineTo(38.39, 5.3);
        context.bezierCurveTo(35.68, 3.94, 36.1, 2.78, 36.7, 1.71);
        context.bezierCurveTo(34.29, 2.46, 31.52, 2.79, 28.89, 2.79);
        context.bezierCurveTo(26.27, 2.79, 23.98, 2.46, 21.59, 1.71);
        context.bezierCurveTo(22.18, 2.78, 22.6, 3.94, 19.89, 5.3);
        context.lineTo(19.9, 5.48);
        context.bezierCurveTo(21.65, 8.79, 25.12, 11.06, 29.13, 11.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 29
        context.save();

        //// Clip Clip 27

        context.beginPath();
        context.moveTo(29.13, 11.06);
        context.bezierCurveTo(25.06, 11.06, 21.54, 8.73, 19.82, 5.34);
        context.lineTo(19.82, 5.34);
        context.bezierCurveTo(15.28, 6.1, 1.32, 8.52, 0.76, 24.81);
        context.lineTo(0.76, 24.81);
        context.lineTo(29.13, 24.81);
        context.lineTo(57.5, 24.81);
        context.bezierCurveTo(56.94, 8.52, 42.98, 6.1, 38.45, 5.34);
        context.lineTo(38.45, 5.34);
        context.bezierCurveTo(36.72, 8.73, 33.2, 11.06, 29.13, 11.06);
        context.closePath();
        context.clip();



        //// Rectangle 45 Drawing
        context.beginPath();
        context.rect(-3.52, 19.86, 65.85, 6.85);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 46 Drawing
        context.beginPath();
        context.rect(-3.52, 12.81, 65.85, 6.85);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 47 Drawing
        context.beginPath();
        context.rect(-3.52, 5.91, 65.85, 6.85);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2286 Drawing
        context.beginPath();
        context.moveTo(38.44, 5.34);
        context.bezierCurveTo(36.72, 8.73, 33.2, 11.06, 29.13, 11.06);
        context.bezierCurveTo(25.06, 11.06, 21.54, 8.73, 19.82, 5.34);
        context.bezierCurveTo(15.28, 6.1, 1.32, 8.52, 0.76, 24.81);
        context.lineTo(29.13, 24.81);
        context.lineTo(57.5, 24.81);
        context.bezierCurveTo(56.94, 8.52, 42.98, 6.1, 38.44, 5.34);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawVFrillCollarShirt(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1766 Drawing
        context.beginPath();
        context.moveTo(39.27, 4.97);
        context.bezierCurveTo(37.54, 8.36, 34.03, 10.7, 29.96, 10.7);
        context.bezierCurveTo(25.89, 10.7, 22.37, 8.36, 20.64, 4.97);
        context.bezierCurveTo(16.11, 5.73, 2.15, 8.15, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.77, 8.15, 43.81, 5.74, 39.27, 4.97);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1767 Drawing
        context.beginPath();
        context.moveTo(39.27, 4.97);
        context.bezierCurveTo(37.54, 8.36, 34.03, 10.7, 29.96, 10.7);
        context.bezierCurveTo(25.89, 10.7, 22.37, 8.36, 20.64, 4.97);
        context.bezierCurveTo(16.11, 5.73, 2.15, 8.15, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.77, 8.15, 43.81, 5.74, 39.27, 4.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2200 Drawing
        context.beginPath();
        context.moveTo(39.22, 5.06);
        context.lineTo(39.22, 4.93);
        context.bezierCurveTo(36.5, 3.57, 36.93, 2.41, 37.53, 1.34);
        context.bezierCurveTo(35.12, 2.09, 32.35, 2.42, 29.72, 2.43);
        context.bezierCurveTo(27.1, 2.42, 24.81, 2.1, 22.41, 1.35);
        context.bezierCurveTo(23.01, 2.42, 20.73, 5.11, 20.73, 5.11);
        context.lineTo(29.96, 18.74);
        context.lineTo(39.22, 5.06);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2201 Drawing
        context.beginPath();
        context.moveTo(39.22, 5.06);
        context.lineTo(39.22, 4.93);
        context.bezierCurveTo(36.5, 3.57, 36.93, 2.41, 37.53, 1.34);
        context.bezierCurveTo(35.12, 2.09, 32.35, 2.42, 29.72, 2.43);
        context.bezierCurveTo(27.1, 2.42, 24.81, 2.1, 22.41, 1.35);
        context.bezierCurveTo(23.01, 2.42, 20.73, 5.11, 20.73, 5.11);
        context.lineTo(29.96, 18.74);
        context.lineTo(39.22, 5.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2287 Drawing
        context.beginPath();
        context.moveTo(39.27, 4.97);
        context.bezierCurveTo(39.26, 5, 39.23, 5.03, 39.22, 5.06);
        context.lineTo(39.22, 5.06);
        context.bezierCurveTo(38.87, 5.73, 38.43, 6.36, 37.95, 6.93);
        context.lineTo(29.96, 18.74);
        context.lineTo(21.94, 6.9);
        context.bezierCurveTo(21.48, 6.35, 21.07, 5.76, 20.73, 5.11);
        context.lineTo(20.73, 5.11);
        context.lineTo(20.73, 5.11);
        context.bezierCurveTo(20.7, 5.06, 20.67, 5.02, 20.64, 4.97);
        context.bezierCurveTo(16.11, 5.73, 2.15, 8.15, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.77, 8.15, 43.81, 5.74, 39.27, 4.97);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2288 Drawing
        context.beginPath();
        context.moveTo(39.27, 4.97);
        context.bezierCurveTo(39.26, 5, 39.23, 5.03, 39.22, 5.06);
        context.lineTo(39.22, 5.06);
        context.bezierCurveTo(38.87, 5.73, 38.43, 6.36, 37.95, 6.93);
        context.lineTo(29.96, 18.74);
        context.lineTo(21.94, 6.9);
        context.bezierCurveTo(21.48, 6.35, 21.07, 5.76, 20.73, 5.11);
        context.lineTo(20.73, 5.11);
        context.lineTo(20.73, 5.11);
        context.bezierCurveTo(20.7, 5.06, 20.67, 5.02, 20.64, 4.97);
        context.bezierCurveTo(16.11, 5.73, 2.15, 8.15, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.77, 8.15, 43.81, 5.74, 39.27, 4.97);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2289 Drawing
        context.beginPath();
        context.moveTo(43.25, 5.81);
        context.bezierCurveTo(42.76, 7, 41.58, 7.84, 40.21, 7.84);
        context.bezierCurveTo(39.33, 7.84, 38.54, 7.49, 37.95, 6.93);
        context.lineTo(37.9, 7.01);
        context.bezierCurveTo(38.86, 7.58, 39.52, 8.63, 39.52, 9.83);
        context.bezierCurveTo(39.52, 11.65, 38.04, 13.13, 36.22, 13.13);
        context.bezierCurveTo(35.47, 13.13, 34.78, 12.86, 34.22, 12.44);
        context.lineTo(34.19, 12.48);
        context.bezierCurveTo(34.88, 13, 35.37, 13.78, 35.49, 14.7);
        context.bezierCurveTo(35.72, 16.51, 34.44, 18.16, 32.64, 18.38);
        context.bezierCurveTo(31.87, 18.48, 31.13, 18.5, 30.51, 18.12);
        context.lineTo(30.07, 18.07);
        context.lineTo(29.54, 18.12);
        context.bezierCurveTo(29.01, 18.35, 28.41, 18.46, 27.79, 18.38);
        context.bezierCurveTo(25.99, 18.16, 24.71, 16.51, 24.94, 14.7);
        context.bezierCurveTo(25.04, 13.93, 25.4, 13.27, 25.91, 12.77);
        context.lineTo(25.85, 12.68);
        context.bezierCurveTo(25.36, 12.95, 24.81, 13.13, 24.21, 13.13);
        context.bezierCurveTo(22.39, 13.13, 20.92, 11.65, 20.92, 9.83);
        context.bezierCurveTo(20.92, 8.78, 21.42, 7.86, 22.18, 7.26);
        context.lineTo(22.15, 7.21);
        context.bezierCurveTo(21.61, 7.6, 20.94, 7.84, 20.22, 7.84);
        context.bezierCurveTo(18.8, 7.84, 17.6, 6.94, 17.14, 5.68);
        context.bezierCurveTo(11.23, 7.18, 2.04, 11.28, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.89, 11.64, 49.17, 7.4, 43.25, 5.81);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 2290 Drawing
        context.beginPath();
        context.moveTo(43.25, 5.81);
        context.bezierCurveTo(42.76, 7, 41.58, 7.84, 40.21, 7.84);
        context.bezierCurveTo(39.33, 7.84, 38.54, 7.49, 37.95, 6.93);
        context.lineTo(37.9, 7.01);
        context.bezierCurveTo(38.86, 7.58, 39.52, 8.63, 39.52, 9.83);
        context.bezierCurveTo(39.52, 11.65, 38.04, 13.13, 36.22, 13.13);
        context.bezierCurveTo(35.47, 13.13, 34.78, 12.86, 34.22, 12.44);
        context.lineTo(34.19, 12.48);
        context.bezierCurveTo(34.88, 13, 35.37, 13.78, 35.49, 14.7);
        context.bezierCurveTo(35.72, 16.51, 34.44, 18.16, 32.64, 18.38);
        context.bezierCurveTo(31.87, 18.48, 31.13, 18.5, 30.51, 18.12);
        context.lineTo(30.07, 18.07);
        context.lineTo(29.54, 18.12);
        context.bezierCurveTo(29.01, 18.35, 28.41, 18.46, 27.79, 18.38);
        context.bezierCurveTo(25.99, 18.16, 24.71, 16.51, 24.94, 14.7);
        context.bezierCurveTo(25.04, 13.93, 25.4, 13.27, 25.91, 12.77);
        context.lineTo(25.85, 12.68);
        context.bezierCurveTo(25.36, 12.95, 24.81, 13.13, 24.21, 13.13);
        context.bezierCurveTo(22.39, 13.13, 20.92, 11.65, 20.92, 9.83);
        context.bezierCurveTo(20.92, 8.78, 21.42, 7.86, 22.18, 7.26);
        context.lineTo(22.15, 7.21);
        context.bezierCurveTo(21.61, 7.6, 20.94, 7.84, 20.22, 7.84);
        context.bezierCurveTo(18.8, 7.84, 17.6, 6.94, 17.14, 5.68);
        context.bezierCurveTo(11.23, 7.18, 2.04, 11.28, 1.58, 24.45);
        context.lineTo(29.96, 24.45);
        context.lineTo(58.33, 24.45);
        context.bezierCurveTo(57.89, 11.64, 49.17, 7.4, 43.25, 5.81);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawHZigZagShirt(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1768 Drawing
        context.beginPath();
        context.moveTo(38.42, 4.84);
        context.bezierCurveTo(36.69, 8.23, 33.18, 10.56, 29.11, 10.56);
        context.bezierCurveTo(25.04, 10.56, 21.52, 8.23, 19.79, 4.84);
        context.bezierCurveTo(15.26, 5.6, 1.3, 8.02, 0.73, 24.31);
        context.lineTo(29.11, 24.31);
        context.lineTo(57.48, 24.31);
        context.bezierCurveTo(56.92, 8.02, 42.96, 5.6, 38.42, 4.84);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1769 Drawing
        context.beginPath();
        context.moveTo(38.42, 4.84);
        context.bezierCurveTo(36.69, 8.23, 33.18, 10.56, 29.11, 10.56);
        context.bezierCurveTo(25.04, 10.56, 21.52, 8.23, 19.79, 4.84);
        context.bezierCurveTo(15.26, 5.6, 1.3, 8.02, 0.73, 24.31);
        context.lineTo(29.11, 24.31);
        context.lineTo(57.48, 24.31);
        context.bezierCurveTo(56.92, 8.02, 42.96, 5.6, 38.42, 4.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2188 Drawing
        context.beginPath();
        context.moveTo(29.11, 10.56);
        context.bezierCurveTo(33.14, 10.56, 36.62, 8.27, 38.37, 4.93);
        context.lineTo(38.37, 4.8);
        context.bezierCurveTo(35.66, 3.44, 36.08, 2.28, 36.68, 1.21);
        context.bezierCurveTo(34.27, 1.96, 31.5, 2.29, 28.87, 2.29);
        context.bezierCurveTo(26.25, 2.29, 23.96, 1.96, 21.56, 1.21);
        context.bezierCurveTo(22.16, 2.28, 22.57, 3.44, 19.87, 4.8);
        context.lineTo(19.88, 4.98);
        context.bezierCurveTo(21.63, 8.29, 25.1, 10.56, 29.11, 10.56);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2189 Drawing
        context.beginPath();
        context.moveTo(29.11, 10.56);
        context.bezierCurveTo(33.14, 10.56, 36.62, 8.27, 38.37, 4.93);
        context.lineTo(38.37, 4.8);
        context.bezierCurveTo(35.66, 3.44, 36.08, 2.28, 36.68, 1.21);
        context.bezierCurveTo(34.27, 1.96, 31.5, 2.29, 28.87, 2.29);
        context.bezierCurveTo(26.25, 2.29, 23.96, 1.96, 21.56, 1.21);
        context.bezierCurveTo(22.16, 2.28, 22.57, 3.44, 19.87, 4.8);
        context.lineTo(19.88, 4.98);
        context.bezierCurveTo(21.63, 8.29, 25.1, 10.56, 29.11, 10.56);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2319 Drawing
        context.beginPath();
        context.moveTo(38.42, 4.84);
        context.bezierCurveTo(36.69, 8.23, 33.18, 10.56, 29.11, 10.56);
        context.bezierCurveTo(25.04, 10.56, 21.52, 8.23, 19.79, 4.84);
        context.bezierCurveTo(15.26, 5.6, 1.3, 8.02, 0.73, 24.31);
        context.lineTo(29.11, 24.31);
        context.lineTo(57.48, 24.31);
        context.bezierCurveTo(56.92, 8.02, 42.96, 5.6, 38.42, 4.84);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 2320 Drawing
        context.beginPath();
        context.moveTo(38.42, 4.84);
        context.bezierCurveTo(36.69, 8.23, 33.18, 10.56, 29.11, 10.56);
        context.bezierCurveTo(25.04, 10.56, 21.52, 8.23, 19.79, 4.84);
        context.bezierCurveTo(15.26, 5.6, 1.3, 8.02, 0.73, 24.31);
        context.lineTo(29.11, 24.31);
        context.lineTo(57.48, 24.31);
        context.bezierCurveTo(56.92, 8.02, 42.96, 5.6, 38.42, 4.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 32
        context.save();

        //// Clip Clip 30

        context.beginPath();
        context.moveTo(29.1, 10.71);
        context.bezierCurveTo(25.03, 10.71, 21.51, 8.38, 19.78, 4.99);
        context.lineTo(19.78, 4.99);
        context.bezierCurveTo(15.25, 5.75, 1.29, 8.17, 0.73, 24.47);
        context.lineTo(0.73, 24.47);
        context.lineTo(29.1, 24.47);
        context.lineTo(57.47, 24.47);
        context.bezierCurveTo(56.91, 8.17, 42.95, 5.76, 38.41, 4.99);
        context.lineTo(38.41, 4.99);
        context.bezierCurveTo(36.69, 8.38, 33.17, 10.71, 29.1, 10.71);
        context.closePath();
        context.clip();



        //// Bezier 2321 Drawing
        context.beginPath();
        context.moveTo(0.9, 9.63);
        context.lineTo(7.97, 11.62);
        context.lineTo(15.05, 9.63);
        context.lineTo(22.12, 11.62);
        context.lineTo(29.2, 9.63);
        context.lineTo(36.27, 11.62);
        context.lineTo(43.35, 9.63);
        context.lineTo(50.43, 11.62);
        context.lineTo(57.51, 9.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2322 Drawing
        context.beginPath();
        context.moveTo(0.9, 12.63);
        context.lineTo(7.97, 14.62);
        context.lineTo(15.05, 12.63);
        context.lineTo(22.12, 14.62);
        context.lineTo(29.2, 12.63);
        context.lineTo(36.27, 14.62);
        context.lineTo(43.35, 12.63);
        context.lineTo(50.43, 14.62);
        context.lineTo(57.51, 12.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2323 Drawing
        context.beginPath();
        context.moveTo(0.9, 15.63);
        context.lineTo(7.97, 17.62);
        context.lineTo(15.05, 15.63);
        context.lineTo(22.12, 17.62);
        context.lineTo(29.2, 15.63);
        context.lineTo(36.27, 17.62);
        context.lineTo(43.35, 15.63);
        context.lineTo(50.43, 17.62);
        context.lineTo(57.51, 15.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2324 Drawing
        context.beginPath();
        context.moveTo(0.9, 18.63);
        context.lineTo(7.97, 20.62);
        context.lineTo(15.05, 18.63);
        context.lineTo(22.12, 20.62);
        context.lineTo(29.2, 18.63);
        context.lineTo(36.27, 20.62);
        context.lineTo(43.35, 18.63);
        context.lineTo(50.43, 20.62);
        context.lineTo(57.51, 18.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2325 Drawing
        context.beginPath();
        context.moveTo(0.9, 21.63);
        context.lineTo(7.97, 23.62);
        context.lineTo(15.05, 21.63);
        context.lineTo(22.12, 23.62);
        context.lineTo(29.2, 21.63);
        context.lineTo(36.27, 23.62);
        context.lineTo(43.35, 21.63);
        context.lineTo(50.43, 23.62);
        context.lineTo(57.51, 21.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2326 Drawing
        context.beginPath();
        context.moveTo(0.9, 24.63);
        context.lineTo(7.97, 26.62);
        context.lineTo(15.05, 24.63);
        context.lineTo(22.12, 26.62);
        context.lineTo(29.2, 24.63);
        context.lineTo(36.27, 26.62);
        context.lineTo(43.35, 24.63);
        context.lineTo(50.43, 26.62);
        context.lineTo(57.51, 24.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2327 Drawing
        context.beginPath();
        context.moveTo(0.9, 6.63);
        context.lineTo(7.97, 8.62);
        context.lineTo(15.05, 6.63);
        context.lineTo(22.12, 8.62);
        context.lineTo(29.2, 6.63);
        context.lineTo(36.27, 8.62);
        context.lineTo(43.35, 6.63);
        context.lineTo(50.43, 8.62);
        context.lineTo(57.51, 6.63);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();
        
        context.restore();

    }

    function drawHijabShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 77, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 77, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1770 Drawing
        context.beginPath();
        context.moveTo(43.56, 5.33);
        context.bezierCurveTo(41.83, 8.73, 38.32, 11.06, 34.25, 11.06);
        context.bezierCurveTo(30.18, 11.06, 26.66, 8.73, 24.93, 5.33);
        context.bezierCurveTo(20.4, 6.1, 6.44, 8.51, 5.87, 24.81);
        context.lineTo(34.25, 24.81);
        context.lineTo(62.62, 24.81);
        context.bezierCurveTo(62.06, 8.52, 48.1, 6.1, 43.56, 5.33);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1771 Drawing
        context.beginPath();
        context.moveTo(43.56, 5.33);
        context.bezierCurveTo(41.83, 8.73, 38.32, 11.06, 34.25, 11.06);
        context.bezierCurveTo(30.18, 11.06, 26.66, 8.73, 24.93, 5.33);
        context.bezierCurveTo(20.4, 6.1, 6.44, 8.51, 5.87, 24.81);
        context.lineTo(34.25, 24.81);
        context.lineTo(62.62, 24.81);
        context.bezierCurveTo(62.06, 8.52, 48.1, 6.1, 43.56, 5.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1845 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(66.56, 9.06, 74.45, 15.02, 74.45, 24.77);
        context.lineTo(30.31, 24.69);
        context.lineTo(18.45, 17.07);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1846 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(65.98, 5.57, 55.41, 0.78, 55.41, 0.78);
        context.lineTo(46.05, 0.79);
        context.lineTo(42.94, 1.41);
        context.bezierCurveTo(38.73, 3.02, 31.61, 3.57, 25.57, 1.25);
        context.lineTo(22.49, 0.82);
        context.lineTo(14.76, 0.33);
        context.bezierCurveTo(14.76, 0.33, 3.54, 5.57, 2.95, 9.06);
        context.bezierCurveTo(2.47, 11.98, 12, 16.86, 23.28, 19.03);
        context.bezierCurveTo(26.7, 19.68, 30.18, 19.95, 33.67, 19.95);
        context.bezierCurveTo(39.52, 19.95, 45.36, 19.2, 50.99, 17.59);
        context.bezierCurveTo(59.57, 15.14, 66.96, 11.42, 66.56, 9.06);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 1847 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(65.98, 5.57, 55.41, 0.78, 55.41, 0.78);
        context.lineTo(46.05, 0.79);
        context.lineTo(42.94, 1.41);
        context.bezierCurveTo(38.73, 3.02, 31.61, 3.57, 25.57, 1.25);
        context.lineTo(22.49, 0.82);
        context.lineTo(14.76, 0.33);
        context.bezierCurveTo(14.76, 0.33, 3.54, 5.57, 2.95, 9.06);
        context.bezierCurveTo(2.47, 11.98, 12, 16.86, 23.28, 19.03);
        context.bezierCurveTo(26.7, 19.68, 30.18, 19.95, 33.67, 19.95);
        context.bezierCurveTo(39.52, 19.95, 45.36, 19.2, 50.99, 17.59);
        context.bezierCurveTo(59.57, 15.14, 66.96, 11.42, 66.56, 9.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1894 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(66.56, 9.06, 74.45, 15.02, 74.45, 24.77);
        context.lineTo(30.31, 24.69);
        context.lineTo(18.45, 17.07);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 1895 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(65.98, 5.57, 55.41, 0.78, 55.41, 0.78);
        context.lineTo(46.05, 0.79);
        context.lineTo(42.94, 1.41);
        context.bezierCurveTo(38.73, 3.02, 31.61, 3.57, 25.57, 1.25);
        context.lineTo(22.49, 0.82);
        context.lineTo(14.76, 0.33);
        context.bezierCurveTo(14.76, 0.33, 3.54, 5.57, 2.95, 9.06);
        context.bezierCurveTo(2.47, 11.98, 12, 16.86, 23.28, 19.03);
        context.bezierCurveTo(26.7, 19.68, 30.18, 19.95, 33.67, 19.95);
        context.bezierCurveTo(39.52, 19.95, 45.36, 19.2, 50.99, 17.59);
        context.bezierCurveTo(59.57, 15.14, 66.96, 11.42, 66.56, 9.06);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 1896 Drawing
        context.beginPath();
        context.moveTo(66.56, 9.06);
        context.bezierCurveTo(65.98, 5.57, 55.41, 0.78, 55.41, 0.78);
        context.lineTo(46.05, 0.79);
        context.lineTo(42.94, 1.41);
        context.bezierCurveTo(38.73, 3.02, 31.61, 3.57, 25.57, 1.25);
        context.lineTo(22.49, 0.82);
        context.lineTo(14.76, 0.33);
        context.bezierCurveTo(14.76, 0.33, 3.54, 5.57, 2.95, 9.06);
        context.bezierCurveTo(2.47, 11.98, 12, 16.86, 23.28, 19.03);
        context.bezierCurveTo(26.7, 19.68, 30.18, 19.95, 33.67, 19.95);
        context.bezierCurveTo(39.52, 19.95, 45.36, 19.2, 50.99, 17.59);
        context.bezierCurveTo(59.57, 15.14, 66.96, 11.42, 66.56, 9.06);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2208 Drawing
        context.beginPath();
        context.moveTo(34.12, 10.98);
        context.bezierCurveTo(38.15, 10.98, 41.63, 8.68, 43.38, 5.34);
        context.lineTo(43.38, 5.21);
        context.bezierCurveTo(40.66, 3.86, 41.08, 2.69, 41.68, 1.62);
        context.bezierCurveTo(39.28, 2.37, 36.5, 2.7, 33.88, 2.71);
        context.bezierCurveTo(31.26, 2.7, 28.97, 2.38, 26.57, 1.63);
        context.bezierCurveTo(27.17, 2.7, 27.58, 3.86, 24.88, 5.21);
        context.lineTo(24.89, 5.39);
        context.bezierCurveTo(26.64, 8.71, 30.11, 10.98, 34.12, 10.98);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2209 Drawing
        context.beginPath();
        context.moveTo(34.12, 10.98);
        context.bezierCurveTo(38.15, 10.98, 41.63, 8.68, 43.38, 5.34);
        context.lineTo(43.38, 5.21);
        context.bezierCurveTo(40.66, 3.86, 41.08, 2.69, 41.68, 1.62);
        context.bezierCurveTo(39.28, 2.37, 36.5, 2.7, 33.88, 2.71);
        context.bezierCurveTo(31.26, 2.7, 28.97, 2.38, 26.57, 1.63);
        context.bezierCurveTo(27.17, 2.7, 27.58, 3.86, 24.88, 5.21);
        context.lineTo(24.89, 5.39);
        context.bezierCurveTo(26.64, 8.71, 30.11, 10.98, 34.12, 10.98);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawHalloweenShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 2184 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.08);
        context.bezierCurveTo(33.98, 11.08, 37.47, 8.79, 39.21, 5.44);
        context.lineTo(39.21, 5.31);
        context.bezierCurveTo(36.5, 3.96, 36.92, 2.79, 37.52, 1.72);
        context.bezierCurveTo(35.11, 2.48, 32.34, 2.8, 29.71, 2.81);
        context.bezierCurveTo(27.09, 2.8, 24.8, 2.48, 22.41, 1.73);
        context.bezierCurveTo(23, 2.8, 23.42, 3.96, 20.71, 5.31);
        context.lineTo(20.72, 5.5);
        context.bezierCurveTo(22.47, 8.81, 25.94, 11.08, 29.95, 11.08);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2185 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.08);
        context.bezierCurveTo(33.98, 11.08, 37.47, 8.79, 39.21, 5.44);
        context.lineTo(39.21, 5.31);
        context.bezierCurveTo(36.5, 3.96, 36.92, 2.79, 37.52, 1.72);
        context.bezierCurveTo(35.11, 2.48, 32.34, 2.8, 29.71, 2.81);
        context.bezierCurveTo(27.09, 2.8, 24.8, 2.48, 22.41, 1.73);
        context.bezierCurveTo(23, 2.8, 23.42, 3.96, 20.71, 5.31);
        context.lineTo(20.72, 5.5);
        context.bezierCurveTo(22.47, 8.81, 25.94, 11.08, 29.95, 11.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2297 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.35);
        context.bezierCurveTo(37.54, 8.75, 34.02, 11.08, 29.95, 11.08);
        context.bezierCurveTo(25.88, 11.08, 22.36, 8.75, 20.63, 5.35);
        context.bezierCurveTo(16.1, 6.12, 2.14, 8.53, 1.58, 24.83);
        context.lineTo(29.95, 24.83);
        context.lineTo(58.32, 24.83);
        context.bezierCurveTo(57.76, 8.54, 43.8, 6.12, 39.26, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2298 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.35);
        context.bezierCurveTo(37.54, 8.75, 34.02, 11.08, 29.95, 11.08);
        context.bezierCurveTo(25.88, 11.08, 22.36, 8.75, 20.63, 5.35);
        context.bezierCurveTo(16.1, 6.12, 2.14, 8.53, 1.58, 24.83);
        context.lineTo(29.95, 24.83);
        context.lineTo(58.32, 24.83);
        context.bezierCurveTo(57.76, 8.54, 43.8, 6.12, 39.26, 5.35);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 2299 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.35);
        context.bezierCurveTo(37.54, 8.75, 34.02, 11.08, 29.95, 11.08);
        context.bezierCurveTo(25.88, 11.08, 22.36, 8.75, 20.63, 5.35);
        context.bezierCurveTo(16.1, 6.12, 2.14, 8.53, 1.58, 24.83);
        context.lineTo(29.95, 24.83);
        context.lineTo(58.32, 24.83);
        context.bezierCurveTo(57.76, 8.54, 43.8, 6.12, 39.26, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2300 Drawing
        context.beginPath();
        context.moveTo(10.09, 8.66);
        context.lineTo(29.95, 24.83);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = _shirtColor2;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2301 Drawing
        context.beginPath();
        context.moveTo(15.86, 10.42);
        context.lineTo(13.03, 13.74);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = _shirtColor2;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2302 Drawing
        context.beginPath();
        context.moveTo(21.13, 14.75);
        context.lineTo(18.31, 18.08);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = _shirtColor2;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2303 Drawing
        context.beginPath();
        context.moveTo(26.47, 19.08);
        context.lineTo(23.64, 22.41);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = _shirtColor2;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2304 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.08);
        context.bezierCurveTo(31.3, 11.08, 32.59, 10.8, 33.78, 10.33);
        context.bezierCurveTo(33.89, 10.16, 33.95, 10.06, 33.95, 10.06);
        context.lineTo(21.64, 2.08);
        context.lineTo(20.64, 5.35);
        context.bezierCurveTo(22.36, 8.75, 25.88, 11.08, 29.95, 11.08);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 2305 Drawing
        context.beginPath();
        context.moveTo(29.95, 11.08);
        context.bezierCurveTo(31.3, 11.08, 32.59, 10.8, 33.78, 10.33);
        context.bezierCurveTo(33.89, 10.16, 33.95, 10.06, 33.95, 10.06);
        context.lineTo(21.64, 2.08);
        context.lineTo(20.64, 5.35);
        context.bezierCurveTo(22.36, 8.75, 25.88, 11.08, 29.95, 11.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2306 Drawing
        context.beginPath();
        context.moveTo(34.13, 10.2);
        context.bezierCurveTo(36.35, 9.23, 38.17, 7.51, 39.26, 5.35);
        context.lineTo(38.26, 1.49);
        context.lineTo(29.71, 7.23);
        context.lineTo(33.95, 10.06);
        context.bezierCurveTo(33.95, 10.06, 34.02, 10.11, 34.13, 10.2);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 2307 Drawing
        context.beginPath();
        context.moveTo(34.13, 10.2);
        context.bezierCurveTo(36.35, 9.23, 38.17, 7.51, 39.26, 5.35);
        context.lineTo(38.26, 1.49);
        context.lineTo(29.71, 7.23);
        context.lineTo(33.95, 10.06);
        context.bezierCurveTo(33.95, 10.06, 34.02, 10.11, 34.13, 10.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2308 Drawing
        context.beginPath();
        context.moveTo(39.26, 5.35);
        context.bezierCurveTo(37.54, 8.75, 34.02, 11.08, 29.95, 11.08);
        context.bezierCurveTo(25.88, 11.08, 22.36, 8.75, 20.63, 5.35);
        context.bezierCurveTo(16.1, 6.12, 2.14, 8.53, 1.58, 24.83);
        context.lineTo(29.95, 24.83);
        context.lineTo(58.32, 24.83);
        context.bezierCurveTo(57.76, 8.54, 43.8, 6.12, 39.26, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBowtieShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1738 Drawing
        context.beginPath();
        context.moveTo(38.62, 4.77);
        context.bezierCurveTo(36.9, 8.16, 33.38, 10.5, 29.31, 10.5);
        context.bezierCurveTo(25.24, 10.5, 21.72, 8.16, 19.99, 4.77);
        context.bezierCurveTo(15.46, 5.54, 1.5, 7.95, 0.94, 24.25);
        context.lineTo(29.31, 24.25);
        context.lineTo(57.68, 24.25);
        context.bezierCurveTo(57.12, 7.95, 43.16, 5.54, 38.62, 4.77);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1739 Drawing
        context.beginPath();
        context.moveTo(38.62, 4.77);
        context.bezierCurveTo(36.9, 8.16, 33.38, 10.5, 29.31, 10.5);
        context.bezierCurveTo(25.24, 10.5, 21.72, 8.16, 19.99, 4.77);
        context.bezierCurveTo(15.46, 5.54, 1.5, 7.95, 0.94, 24.25);
        context.lineTo(29.31, 24.25);
        context.lineTo(57.68, 24.25);
        context.bezierCurveTo(57.12, 7.95, 43.16, 5.54, 38.62, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2162 Drawing
        context.beginPath();
        context.moveTo(29.31, 10.5);
        context.bezierCurveTo(33.34, 10.5, 36.83, 8.2, 38.57, 4.86);
        context.lineTo(38.57, 4.73);
        context.bezierCurveTo(35.86, 3.37, 36.28, 2.21, 36.88, 1.14);
        context.bezierCurveTo(34.47, 1.89, 31.7, 2.22, 29.07, 2.23);
        context.bezierCurveTo(26.45, 2.22, 24.16, 1.9, 21.77, 1.15);
        context.bezierCurveTo(22.36, 2.22, 22.77, 3.38, 20.07, 4.73);
        context.lineTo(20.08, 4.91);
        context.bezierCurveTo(21.83, 8.23, 25.3, 10.5, 29.31, 10.5);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2163 Drawing
        context.beginPath();
        context.moveTo(29.31, 10.5);
        context.bezierCurveTo(33.34, 10.5, 36.83, 8.2, 38.57, 4.86);
        context.lineTo(38.57, 4.73);
        context.bezierCurveTo(35.86, 3.37, 36.28, 2.21, 36.88, 1.14);
        context.bezierCurveTo(34.47, 1.89, 31.7, 2.22, 29.07, 2.23);
        context.bezierCurveTo(26.45, 2.22, 24.16, 1.9, 21.77, 1.15);
        context.bezierCurveTo(22.36, 2.22, 22.77, 3.38, 20.07, 4.73);
        context.lineTo(20.08, 4.91);
        context.bezierCurveTo(21.83, 8.23, 25.3, 10.5, 29.31, 10.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2613 Drawing
        context.beginPath();
        context.moveTo(30.97, 7.89);
        context.bezierCurveTo(30.97, 7.89, 38.71, -0.5, 41.49, 2.28);
        context.bezierCurveTo(44.27, 5.06, 43.53, 14.5, 41.71, 17.25);
        context.bezierCurveTo(39.89, 20.01, 33.45, 14.34, 30.97, 11.86);
        context.lineTo(30.97, 7.89);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2614 Drawing
        context.beginPath();
        context.moveTo(30.97, 7.89);
        context.bezierCurveTo(30.97, 7.89, 38.71, -0.5, 41.49, 2.28);
        context.bezierCurveTo(44.27, 5.06, 43.53, 14.5, 41.71, 17.25);
        context.bezierCurveTo(39.89, 20.01, 33.45, 14.34, 30.97, 11.86);
        context.lineTo(30.97, 7.89);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.1;
        context.stroke();


        //// Bezier 2615 Drawing
        context.beginPath();
        context.moveTo(27.32, 7.84);
        context.bezierCurveTo(27.32, 7.84, 19.58, -0.56, 16.8, 2.22);
        context.bezierCurveTo(14.02, 5, 14.76, 14.44, 16.58, 17.2);
        context.bezierCurveTo(18.4, 19.95, 24.82, 14.16, 27.3, 11.68);
        context.lineTo(27.32, 7.84);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2616 Drawing
        context.beginPath();
        context.moveTo(27.32, 7.84);
        context.bezierCurveTo(27.32, 7.84, 19.58, -0.56, 16.8, 2.22);
        context.bezierCurveTo(14.02, 5, 14.76, 14.44, 16.58, 17.2);
        context.bezierCurveTo(18.4, 19.95, 24.82, 14.16, 27.3, 11.68);
        context.lineTo(27.32, 7.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.1;
        context.stroke();


        //// Bezier 2617 Drawing
        context.beginPath();
        context.moveTo(30.01, 12.5);
        context.lineTo(28.26, 12.5);
        context.bezierCurveTo(27.62, 12.5, 27.11, 11.98, 27.11, 11.35);
        context.lineTo(27.11, 8.24);
        context.bezierCurveTo(27.11, 7.6, 27.62, 7.09, 28.26, 7.09);
        context.lineTo(30.01, 7.09);
        context.bezierCurveTo(30.65, 7.09, 31.16, 7.6, 31.16, 8.24);
        context.lineTo(31.16, 11.35);
        context.bezierCurveTo(31.16, 11.98, 30.65, 12.5, 30.01, 12.5);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2618 Drawing
        context.beginPath();
        context.moveTo(30.01, 12.5);
        context.lineTo(28.26, 12.5);
        context.bezierCurveTo(27.62, 12.5, 27.11, 11.98, 27.11, 11.35);
        context.lineTo(27.11, 8.24);
        context.bezierCurveTo(27.11, 7.6, 27.62, 7.09, 28.26, 7.09);
        context.lineTo(30.01, 7.09);
        context.bezierCurveTo(30.65, 7.09, 31.16, 7.6, 31.16, 8.24);
        context.lineTo(31.16, 11.35);
        context.bezierCurveTo(31.16, 11.98, 30.65, 12.5, 30.01, 12.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.1;
        context.stroke();
        
        context.restore();

    }

    function drawTieShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 39), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 39);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1746 Drawing
        context.beginPath();
        context.moveTo(38.49, 4.77);
        context.bezierCurveTo(36.76, 8.16, 33.24, 10.5, 29.17, 10.5);
        context.bezierCurveTo(25.1, 10.5, 21.58, 8.16, 19.86, 4.77);
        context.bezierCurveTo(15.32, 5.54, 1.36, 7.95, 0.8, 24.25);
        context.lineTo(29.17, 24.25);
        context.lineTo(57.54, 24.25);
        context.bezierCurveTo(56.98, 7.95, 43.03, 5.54, 38.49, 4.77);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1747 Drawing
        context.beginPath();
        context.moveTo(38.49, 4.77);
        context.bezierCurveTo(36.76, 8.16, 33.24, 10.5, 29.17, 10.5);
        context.bezierCurveTo(25.1, 10.5, 21.58, 8.16, 19.86, 4.77);
        context.bezierCurveTo(15.32, 5.54, 1.36, 7.95, 0.8, 24.25);
        context.lineTo(29.17, 24.25);
        context.lineTo(57.54, 24.25);
        context.bezierCurveTo(56.98, 7.95, 43.03, 5.54, 38.49, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2170 Drawing
        context.beginPath();
        context.moveTo(29.17, 10.44);
        context.bezierCurveTo(33.2, 10.44, 36.69, 8.15, 38.43, 4.8);
        context.lineTo(38.43, 4.67);
        context.bezierCurveTo(35.72, 3.32, 36.14, 2.15, 36.74, 1.08);
        context.bezierCurveTo(34.33, 1.84, 31.56, 2.16, 28.93, 2.17);
        context.bezierCurveTo(26.31, 2.16, 24.02, 1.84, 21.63, 1.09);
        context.bezierCurveTo(22.23, 2.16, 22.64, 3.32, 19.93, 4.67);
        context.lineTo(19.94, 4.86);
        context.bezierCurveTo(21.7, 8.17, 25.16, 10.44, 29.17, 10.44);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2171 Drawing
        context.beginPath();
        context.moveTo(29.17, 10.44);
        context.bezierCurveTo(33.2, 10.44, 36.69, 8.15, 38.43, 4.8);
        context.lineTo(38.43, 4.67);
        context.bezierCurveTo(35.72, 3.32, 36.14, 2.15, 36.74, 1.08);
        context.bezierCurveTo(34.33, 1.84, 31.56, 2.16, 28.93, 2.17);
        context.bezierCurveTo(26.31, 2.16, 24.02, 1.84, 21.63, 1.09);
        context.bezierCurveTo(22.23, 2.16, 22.64, 3.32, 19.93, 4.67);
        context.lineTo(19.94, 4.86);
        context.bezierCurveTo(21.7, 8.17, 25.16, 10.44, 29.17, 10.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2274 Drawing
        context.beginPath();
        context.moveTo(38.49, 4.77);
        context.bezierCurveTo(36.76, 8.16, 33.24, 10.5, 29.17, 10.5);
        context.bezierCurveTo(25.1, 10.5, 21.58, 8.16, 19.86, 4.77);
        context.bezierCurveTo(15.32, 5.54, 1.36, 7.95, 0.8, 24.25);
        context.lineTo(29.17, 24.25);
        context.lineTo(57.54, 24.25);
        context.bezierCurveTo(56.98, 7.95, 43.03, 5.54, 38.49, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2275 Drawing
        context.beginPath();
        context.moveTo(30.7, 10.43);
        context.lineTo(27.88, 10.4);
        context.lineTo(25.82, 13.04);
        context.lineTo(27.52, 14.99);
        context.lineTo(30.91, 14.99);
        context.lineTo(32.53, 12.82);
        context.lineTo(30.7, 10.43);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2276 Drawing
        context.beginPath();
        context.moveTo(30.7, 10.43);
        context.lineTo(27.88, 10.4);
        context.lineTo(25.82, 13.04);
        context.lineTo(27.52, 14.99);
        context.lineTo(30.91, 14.99);
        context.lineTo(32.53, 12.82);
        context.lineTo(30.7, 10.43);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 28
        context.save();

        //// Clip Clip 26

        context.beginPath();
        context.moveTo(29.1, 10.5);
        context.bezierCurveTo(25.03, 10.5, 21.51, 8.17, 19.78, 4.78);
        context.lineTo(19.78, 4.78);
        context.bezierCurveTo(15.25, 5.54, 1.29, 7.96, 0.73, 24.26);
        context.lineTo(0.73, 24.26);
        context.lineTo(29.1, 24.26);
        context.lineTo(57.47, 24.26);
        context.bezierCurveTo(56.91, 7.96, 42.95, 5.54, 38.41, 4.78);
        context.lineTo(38.41, 4.78);
        context.bezierCurveTo(36.69, 8.17, 33.17, 10.5, 29.1, 10.5);
        context.closePath();
        context.clip();



        //// Bezier 2277 Drawing
        context.beginPath();
        context.moveTo(12.12, 1.21);
        context.bezierCurveTo(12.46, 1.56, 19.62, 20.46, 19.62, 20.46);
        context.lineTo(27.88, 10.4);
        context.bezierCurveTo(27.88, 10.4, 19.17, 5.24, 18.92, 0.59);
        context.lineTo(12.12, 1.21);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2278 Drawing
        context.beginPath();
        context.moveTo(12.12, 1.21);
        context.bezierCurveTo(12.46, 1.56, 19.62, 20.46, 19.62, 20.46);
        context.lineTo(27.88, 10.4);
        context.bezierCurveTo(27.88, 10.4, 19.17, 5.24, 18.92, 0.59);
        context.lineTo(12.12, 1.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2279 Drawing
        context.beginPath();
        context.moveTo(45.9, 0.71);
        context.bezierCurveTo(45.56, 1.06, 38.4, 19.96, 38.4, 19.96);
        context.lineTo(30.15, 9.9);
        context.bezierCurveTo(30.15, 9.9, 38.85, 4.74, 39.1, 0.09);
        context.lineTo(45.9, 0.71);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2280 Drawing
        context.beginPath();
        context.moveTo(45.9, 0.71);
        context.bezierCurveTo(45.56, 1.06, 38.4, 19.96, 38.4, 19.96);
        context.lineTo(30.15, 9.9);
        context.bezierCurveTo(30.15, 9.9, 38.85, 4.74, 39.1, 0.09);
        context.lineTo(45.9, 0.71);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2282 Drawing
        context.beginPath();
        context.moveTo(38.41, 4.78);
        context.bezierCurveTo(36.69, 8.17, 33.17, 10.5, 29.1, 10.5);
        context.bezierCurveTo(25.03, 10.5, 21.51, 8.17, 19.78, 4.78);
        context.bezierCurveTo(15.25, 5.54, 1.29, 7.96, 0.73, 24.26);
        context.lineTo(29.1, 24.26);
        context.lineTo(57.47, 24.26);
        context.bezierCurveTo(56.91, 7.96, 42.95, 5.55, 38.41, 4.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2283 Drawing
        context.beginPath();
        context.moveTo(27.52, 15.42);
        context.lineTo(31.05, 15.42);
        context.lineTo(34.35, 32.65);
        context.lineTo(29.33, 37.07);
        context.lineTo(24.18, 32.68);
        context.lineTo(27.52, 15.42);
        context.closePath();
        context.fillStyle = _shirtColor2;
        context.fill();


        //// Bezier 2284 Drawing
        context.beginPath();
        context.moveTo(27.52, 15.42);
        context.lineTo(31.05, 15.42);
        context.lineTo(34.35, 32.65);
        context.lineTo(29.33, 37.07);
        context.lineTo(24.18, 32.68);
        context.lineTo(27.52, 15.42);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.87;
        context.stroke();
        
        context.restore();

    }

    function drawCheckShirt(canvas, skinColor, shirtColor, shirtColor2, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));
        var _shirtColor2 = shirtColor2 === 'col1' ? AvatarStyleKit.col1 : (shirtColor2 === 'col2' ? AvatarStyleKit.col2 : (shirtColor2 === 'col3' ? AvatarStyleKit.col3 : (shirtColor2 === 'col4' ? AvatarStyleKit.col4 : (shirtColor2 === 'col5' ? AvatarStyleKit.col5 : (shirtColor2 === 'col6' ? AvatarStyleKit.col6 : (shirtColor2 === 'col7' ? AvatarStyleKit.col7 : (shirtColor2 === 'col8' ? AvatarStyleKit.col8 : (shirtColor2 === 'col9' ? AvatarStyleKit.col9 : (shirtColor2 === 'col10' ? AvatarStyleKit.col10 : (shirtColor2 === 'col11' ? AvatarStyleKit.col11 : (shirtColor2 === 'col12' ? AvatarStyleKit.col12 : (shirtColor2 === 'col13' ? AvatarStyleKit.col13 : (shirtColor2 === 'col14' ? AvatarStyleKit.col14 : (shirtColor2 === 'col15' ? AvatarStyleKit.col15 : (shirtColor2 === 'col16' ? AvatarStyleKit.col16 : (shirtColor2 === 'col17' ? AvatarStyleKit.col17 : (shirtColor2 === 'col18' ? AvatarStyleKit.col18 : (shirtColor2 === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 2172 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.37);
        context.bezierCurveTo(33.4, 10.37, 36.88, 8.07, 38.63, 4.73);
        context.lineTo(38.63, 4.6);
        context.bezierCurveTo(35.91, 3.25, 36.34, 2.08, 36.93, 1.01);
        context.bezierCurveTo(34.53, 1.76, 31.76, 2.09, 29.13, 2.1);
        context.bezierCurveTo(26.51, 2.09, 24.22, 1.77, 21.82, 1.02);
        context.bezierCurveTo(22.42, 2.09, 22.83, 3.25, 20.13, 4.6);
        context.lineTo(20.14, 4.78);
        context.bezierCurveTo(21.89, 8.1, 25.36, 10.37, 29.37, 10.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2173 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.37);
        context.bezierCurveTo(33.4, 10.37, 36.88, 8.07, 38.63, 4.73);
        context.lineTo(38.63, 4.6);
        context.bezierCurveTo(35.91, 3.25, 36.34, 2.08, 36.93, 1.01);
        context.bezierCurveTo(34.53, 1.76, 31.76, 2.09, 29.13, 2.1);
        context.bezierCurveTo(26.51, 2.09, 24.22, 1.77, 21.82, 1.02);
        context.bezierCurveTo(22.42, 2.09, 22.83, 3.25, 20.13, 4.6);
        context.lineTo(20.14, 4.78);
        context.bezierCurveTo(21.89, 8.1, 25.36, 10.37, 29.37, 10.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 30
        context.save();

        //// Clip Clip 28

        context.beginPath();
        context.moveTo(29.36, 10.5);
        context.bezierCurveTo(25.3, 10.5, 21.78, 8.17, 20.05, 4.77);
        context.lineTo(20.05, 4.77);
        context.bezierCurveTo(15.52, 5.54, 1.55, 7.95, 0.99, 24.25);
        context.lineTo(0.99, 24.25);
        context.lineTo(29.36, 24.25);
        context.lineTo(57.74, 24.25);
        context.bezierCurveTo(57.18, 7.95, 43.22, 5.54, 38.68, 4.77);
        context.lineTo(38.68, 4.77);
        context.bezierCurveTo(36.95, 8.17, 33.44, 10.5, 29.36, 10.5);
        context.closePath();
        context.clip();



        //// Rectangle 48 Drawing
        context.beginPath();
        context.rect(-1.58, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 49 Drawing
        context.beginPath();
        context.rect(5.97, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 50 Drawing
        context.beginPath();
        context.rect(13.57, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 51 Drawing
        context.beginPath();
        context.rect(21.12, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 52 Drawing
        context.beginPath();
        context.rect(28.67, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 53 Drawing
        context.beginPath();
        context.rect(36.22, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 54 Drawing
        context.beginPath();
        context.rect(-1.13, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 55 Drawing
        context.beginPath();
        context.rect(6.42, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 56 Drawing
        context.beginPath();
        context.rect(-1.13, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 57 Drawing
        context.beginPath();
        context.rect(6.42, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 58 Drawing
        context.beginPath();
        context.rect(-1.13, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 59 Drawing
        context.beginPath();
        context.rect(6.42, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 60 Drawing
        context.beginPath();
        context.rect(14.02, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 61 Drawing
        context.beginPath();
        context.rect(21.57, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 62 Drawing
        context.beginPath();
        context.rect(14.02, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 63 Drawing
        context.beginPath();
        context.rect(21.57, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 64 Drawing
        context.beginPath();
        context.rect(14.02, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 65 Drawing
        context.beginPath();
        context.rect(21.57, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 66 Drawing
        context.beginPath();
        context.rect(29.12, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 67 Drawing
        context.beginPath();
        context.rect(36.67, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 68 Drawing
        context.beginPath();
        context.rect(29.12, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 69 Drawing
        context.beginPath();
        context.rect(36.67, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 70 Drawing
        context.beginPath();
        context.rect(29.12, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 71 Drawing
        context.beginPath();
        context.rect(36.67, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 72 Drawing
        context.beginPath();
        context.rect(44.27, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 73 Drawing
        context.beginPath();
        context.rect(51.82, 2.71, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 74 Drawing
        context.beginPath();
        context.rect(44.27, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 75 Drawing
        context.beginPath();
        context.rect(51.82, 10.26, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 76 Drawing
        context.beginPath();
        context.rect(44.27, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 77 Drawing
        context.beginPath();
        context.rect(51.82, 17.81, 7.55, 7.55);
        context.fillStyle = _shirtColor2;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 78 Drawing
        context.beginPath();
        context.rect(43.82, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 79 Drawing
        context.beginPath();
        context.rect(51.37, 25.36, 7.55, 7.55);
        context.fillStyle = _shirtColor;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2296 Drawing
        context.beginPath();
        context.moveTo(38.68, 4.77);
        context.bezierCurveTo(36.95, 8.16, 33.44, 10.5, 29.37, 10.5);
        context.bezierCurveTo(25.3, 10.5, 21.78, 8.16, 20.05, 4.77);
        context.bezierCurveTo(15.52, 5.54, 1.56, 7.95, 0.99, 24.25);
        context.lineTo(29.37, 24.25);
        context.lineTo(57.74, 24.25);
        context.bezierCurveTo(57.18, 7.95, 43.22, 5.54, 38.68, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawWandShirt(canvas, skinColor, shirtColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 37), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 37);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _shirtColor = shirtColor === 'col1' ? AvatarStyleKit.col1 : (shirtColor === 'col2' ? AvatarStyleKit.col2 : (shirtColor === 'col3' ? AvatarStyleKit.col3 : (shirtColor === 'col4' ? AvatarStyleKit.col4 : (shirtColor === 'col5' ? AvatarStyleKit.col5 : (shirtColor === 'col6' ? AvatarStyleKit.col6 : (shirtColor === 'col7' ? AvatarStyleKit.col7 : (shirtColor === 'col8' ? AvatarStyleKit.col8 : (shirtColor === 'col9' ? AvatarStyleKit.col9 : (shirtColor === 'col10' ? AvatarStyleKit.col10 : (shirtColor === 'col11' ? AvatarStyleKit.col11 : (shirtColor === 'col12' ? AvatarStyleKit.col12 : (shirtColor === 'col13' ? AvatarStyleKit.col13 : (shirtColor === 'col14' ? AvatarStyleKit.col14 : (shirtColor === 'col15' ? AvatarStyleKit.col15 : (shirtColor === 'col16' ? AvatarStyleKit.col16 : (shirtColor === 'col17' ? AvatarStyleKit.col17 : (shirtColor === 'col18' ? AvatarStyleKit.col18 : (shirtColor === 'col19' ? AvatarStyleKit.col19 : AvatarStyleKit.col20))))))))))))))))));

        //// Bezier 1772 Drawing
        context.beginPath();
        context.moveTo(39.85, 15.77);
        context.bezierCurveTo(38.12, 19.16, 34.61, 21.5, 30.54, 21.5);
        context.bezierCurveTo(26.47, 21.5, 22.95, 19.16, 21.22, 15.77);
        context.bezierCurveTo(16.69, 16.54, 2.73, 18.95, 2.16, 35.25);
        context.lineTo(30.54, 35.25);
        context.lineTo(58.91, 35.25);
        context.bezierCurveTo(58.35, 18.95, 44.39, 16.54, 39.85, 15.77);
        context.closePath();
        context.fillStyle = _shirtColor;
        context.fill();


        //// Bezier 1773 Drawing
        context.beginPath();
        context.moveTo(39.85, 15.77);
        context.bezierCurveTo(38.12, 19.16, 34.61, 21.5, 30.54, 21.5);
        context.bezierCurveTo(26.47, 21.5, 22.95, 19.16, 21.22, 15.77);
        context.bezierCurveTo(16.69, 16.54, 2.73, 18.95, 2.16, 35.25);
        context.lineTo(30.54, 35.25);
        context.lineTo(58.91, 35.25);
        context.bezierCurveTo(58.35, 18.95, 44.39, 16.54, 39.85, 15.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2192 Drawing
        context.beginPath();
        context.moveTo(30.54, 21.37);
        context.bezierCurveTo(34.57, 21.37, 38.06, 19.07, 39.8, 15.73);
        context.lineTo(39.8, 15.6);
        context.bezierCurveTo(37.09, 14.25, 37.51, 13.08, 38.11, 12.01);
        context.bezierCurveTo(35.7, 12.76, 32.93, 13.09, 30.3, 13.1);
        context.bezierCurveTo(27.68, 13.09, 25.39, 12.77, 23, 12.02);
        context.bezierCurveTo(23.59, 13.09, 24, 14.25, 21.3, 15.6);
        context.lineTo(21.31, 15.78);
        context.bezierCurveTo(23.06, 19.1, 26.53, 21.37, 30.54, 21.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2193 Drawing
        context.beginPath();
        context.moveTo(30.54, 21.37);
        context.bezierCurveTo(34.57, 21.37, 38.06, 19.07, 39.8, 15.73);
        context.lineTo(39.8, 15.6);
        context.bezierCurveTo(37.09, 14.25, 37.51, 13.08, 38.11, 12.01);
        context.bezierCurveTo(35.7, 12.76, 32.93, 13.09, 30.3, 13.1);
        context.bezierCurveTo(27.68, 13.09, 25.39, 12.77, 23, 12.02);
        context.bezierCurveTo(23.59, 13.09, 24, 14.25, 21.3, 15.6);
        context.lineTo(21.31, 15.78);
        context.bezierCurveTo(23.06, 19.1, 26.53, 21.37, 30.54, 21.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2634 Drawing
        context.beginPath();
        context.moveTo(49.6, 35.25);
        context.lineTo(51.21, 35.25);
        context.lineTo(60.15, 12.05);
        context.lineTo(58.76, 11.51);
        context.lineTo(49.6, 35.25);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2635 Drawing
        context.beginPath();
        context.moveTo(49.6, 35.25);
        context.lineTo(51.21, 35.25);
        context.lineTo(60.15, 12.05);
        context.lineTo(58.76, 11.51);
        context.lineTo(49.6, 35.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2636 Drawing
        context.beginPath();
        context.moveTo(59.29, 12.26);
        context.lineTo(53.65, 13.5);
        context.lineTo(56.86, 8.64);
        context.lineTo(54.17, 3.89);
        context.lineTo(59.36, 5.4);
        context.lineTo(63.47, 1.41);
        context.lineTo(63.85, 7.13);
        context.lineTo(68.71, 9.49);
        context.lineTo(63.4, 11.51);
        context.lineTo(62.64, 16.96);
        context.lineTo(59.29, 12.26);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2637 Drawing
        context.beginPath();
        context.moveTo(59.29, 12.26);
        context.lineTo(53.65, 13.5);
        context.lineTo(56.86, 8.64);
        context.lineTo(54.17, 3.89);
        context.lineTo(59.36, 5.4);
        context.lineTo(63.47, 1.41);
        context.lineTo(63.85, 7.13);
        context.lineTo(68.71, 9.49);
        context.lineTo(63.4, 11.51);
        context.lineTo(62.64, 16.96);
        context.lineTo(59.29, 12.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt1(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor16 = 'rgba(255, 255, 255, 1)';
        var fillColor57 = 'rgba(3, 70, 148, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2258 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(33.4, 10.68, 36.89, 8.39, 38.63, 5.05);
        context.lineTo(38.63, 4.92);
        context.bezierCurveTo(35.92, 3.56, 36.34, 2.4, 36.94, 1.33);
        context.bezierCurveTo(34.54, 2.08, 31.76, 2.41, 29.13, 2.41);
        context.bezierCurveTo(26.51, 2.41, 24.23, 2.08, 21.83, 1.34);
        context.bezierCurveTo(22.43, 2.41, 22.84, 3.57, 20.13, 4.92);
        context.lineTo(20.14, 5.1);
        context.bezierCurveTo(21.9, 8.42, 25.36, 10.68, 29.37, 10.68);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2259 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(33.4, 10.68, 36.89, 8.39, 38.63, 5.05);
        context.lineTo(38.63, 4.92);
        context.bezierCurveTo(35.92, 3.56, 36.34, 2.4, 36.94, 1.33);
        context.bezierCurveTo(34.54, 2.08, 31.76, 2.41, 29.13, 2.41);
        context.bezierCurveTo(26.51, 2.41, 24.23, 2.08, 21.83, 1.34);
        context.bezierCurveTo(22.43, 2.41, 22.84, 3.57, 20.13, 4.92);
        context.lineTo(20.14, 5.1);
        context.bezierCurveTo(21.9, 8.42, 25.36, 10.68, 29.37, 10.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2380 Drawing
        context.beginPath();
        context.moveTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.23, 5.72, 38.69, 4.96);
        context.closePath();
        context.fillStyle = fillColor57;
        context.fill();


        //// Group 43
        context.save();

        //// Clip Clip 41

        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.lineTo(20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.23, 5.72, 38.69, 4.96);
        context.lineTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.closePath();
        context.clip();



        //// Bezier 2381 Drawing
        context.beginPath();
        context.moveTo(23.84, 7.36);
        context.bezierCurveTo(23.84, 7.36, 5.21, 10.51, 5.71, 24.4);
        context.fillStyle = fillColor57;
        context.fill();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 3;
        context.stroke();


        //// Bezier 2382 Drawing
        context.beginPath();
        context.moveTo(35.77, 7.28);
        context.bezierCurveTo(35.77, 7.28, 54.37, 10.59, 53.74, 24.48);
        context.fillStyle = fillColor57;
        context.fill();
        context.strokeStyle = strokeColor16;
        context.lineWidth = 3;
        context.stroke();


        context.restore();



        //// Bezier 2384 Drawing
        context.beginPath();
        context.moveTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.23, 5.72, 38.69, 4.96);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt2(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2246 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(33.4, 10.68, 36.89, 8.39, 38.63, 5.05);
        context.lineTo(38.63, 4.92);
        context.bezierCurveTo(35.92, 3.56, 36.34, 2.4, 36.94, 1.33);
        context.bezierCurveTo(34.54, 2.08, 31.76, 2.41, 29.13, 2.41);
        context.bezierCurveTo(26.51, 2.41, 24.23, 2.08, 21.83, 1.34);
        context.bezierCurveTo(22.43, 2.41, 22.84, 3.57, 20.13, 4.92);
        context.lineTo(20.14, 5.1);
        context.bezierCurveTo(21.9, 8.42, 25.36, 10.68, 29.37, 10.68);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2247 Drawing
        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(33.4, 10.68, 36.89, 8.39, 38.63, 5.05);
        context.lineTo(38.63, 4.92);
        context.bezierCurveTo(35.92, 3.56, 36.34, 2.4, 36.94, 1.33);
        context.bezierCurveTo(34.54, 2.08, 31.76, 2.41, 29.13, 2.41);
        context.bezierCurveTo(26.51, 2.41, 24.23, 2.08, 21.83, 1.34);
        context.bezierCurveTo(22.43, 2.41, 22.84, 3.57, 20.13, 4.92);
        context.lineTo(20.14, 5.1);
        context.bezierCurveTo(21.9, 8.42, 25.36, 10.68, 29.37, 10.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2385 Drawing
        context.beginPath();
        context.moveTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.23, 5.72, 38.69, 4.96);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Group 44
        context.save();

        //// Clip Clip 42

        context.beginPath();
        context.moveTo(29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.lineTo(20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.22, 5.72, 38.69, 4.96);
        context.lineTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.closePath();
        context.clip();



        //// Bezier 2386 Drawing
        context.beginPath();
        context.moveTo(29.15, 13.62);
        context.bezierCurveTo(23.82, 13.62, 19, 10.53, 16.57, 5.55);
        context.lineTo(22.18, 2.57);
        context.bezierCurveTo(23.53, 5.33, 26.2, 7.05, 29.15, 7.05);
        context.bezierCurveTo(32.1, 7.05, 34.77, 5.33, 36.12, 2.57);
        context.lineTo(41.73, 5.55);
        context.bezierCurveTo(39.3, 10.53, 34.48, 13.62, 29.15, 13.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2387 Drawing
        context.beginPath();
        context.moveTo(16.94, 19.77);
        context.lineTo(16.04, 19.77);
        context.lineTo(16.04, 56.34);
        context.lineTo(16.94, 56.34);
        context.lineTo(16.94, 19.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2388 Drawing
        context.beginPath();
        context.moveTo(23.74, 19.77);
        context.lineTo(22.84, 19.77);
        context.lineTo(22.84, 56.34);
        context.lineTo(23.74, 56.34);
        context.lineTo(23.74, 19.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2389 Drawing
        context.beginPath();
        context.moveTo(29.94, 19.77);
        context.lineTo(29.04, 19.77);
        context.lineTo(29.04, 56.34);
        context.lineTo(29.94, 56.34);
        context.lineTo(29.94, 19.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2390 Drawing
        context.beginPath();
        context.moveTo(36.74, 19.77);
        context.lineTo(35.84, 19.77);
        context.lineTo(35.84, 56.34);
        context.lineTo(36.74, 56.34);
        context.lineTo(36.74, 19.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2391 Drawing
        context.beginPath();
        context.moveTo(43.74, 19.77);
        context.lineTo(42.84, 19.77);
        context.lineTo(42.84, 56.34);
        context.lineTo(43.74, 56.34);
        context.lineTo(43.74, 19.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2393 Drawing
        context.beginPath();
        context.moveTo(38.69, 4.96);
        context.bezierCurveTo(36.96, 8.35, 33.44, 10.68, 29.37, 10.68);
        context.bezierCurveTo(25.3, 10.68, 21.78, 8.35, 20.06, 4.96);
        context.bezierCurveTo(15.52, 5.72, 1.56, 8.14, 1, 24.44);
        context.lineTo(29.37, 24.44);
        context.lineTo(57.74, 24.44);
        context.bezierCurveTo(57.18, 8.14, 43.23, 5.72, 38.69, 4.96);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt3(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2264 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.5);
        context.bezierCurveTo(33.87, 10.5, 37.36, 8.2, 39.1, 4.86);
        context.lineTo(39.1, 4.73);
        context.bezierCurveTo(36.39, 3.37, 36.81, 2.21, 37.41, 1.14);
        context.bezierCurveTo(35, 1.89, 32.23, 2.22, 29.6, 2.23);
        context.bezierCurveTo(26.98, 2.22, 24.69, 1.9, 22.3, 1.15);
        context.bezierCurveTo(22.89, 2.22, 23.31, 3.38, 20.6, 4.73);
        context.lineTo(20.61, 4.91);
        context.bezierCurveTo(22.36, 8.23, 25.83, 10.5, 29.84, 10.5);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2265 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.5);
        context.bezierCurveTo(33.87, 10.5, 37.36, 8.2, 39.1, 4.86);
        context.lineTo(39.1, 4.73);
        context.bezierCurveTo(36.39, 3.37, 36.81, 2.21, 37.41, 1.14);
        context.bezierCurveTo(35, 1.89, 32.23, 2.22, 29.6, 2.23);
        context.bezierCurveTo(26.98, 2.22, 24.69, 1.9, 22.3, 1.15);
        context.bezierCurveTo(22.89, 2.22, 23.31, 3.38, 20.6, 4.73);
        context.lineTo(20.61, 4.91);
        context.bezierCurveTo(22.36, 8.23, 25.83, 10.5, 29.84, 10.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2346 Drawing
        context.beginPath();
        context.moveTo(39.16, 4.77);
        context.bezierCurveTo(37.43, 8.16, 33.91, 10.5, 29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.16, 20.53, 4.77);
        context.bezierCurveTo(15.99, 5.53, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.16, 4.77);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Group 37
        context.save();

        //// Clip Clip 35

        context.beginPath();
        context.moveTo(29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.17, 20.52, 4.77);
        context.lineTo(20.52, 4.77);
        context.bezierCurveTo(15.99, 5.54, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.15, 4.77);
        context.lineTo(39.15, 4.77);
        context.bezierCurveTo(37.43, 8.17, 33.91, 10.5, 29.84, 10.5);
        context.closePath();
        context.clip();



        //// Bezier 2347 Drawing
        context.beginPath();
        context.moveTo(8.65, 24.71);
        context.bezierCurveTo(8.65, 24.71, 8.04, 13.46, 23.34, 7.98);
        context.bezierCurveTo(29.4, 5.8, -2.6, -1.5, -2.6, -1.5);
        context.lineTo(1.01, 25.15);
        context.lineTo(8.65, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2348 Drawing
        context.beginPath();
        context.moveTo(51.85, 24.71);
        context.bezierCurveTo(51.85, 24.71, 52.46, 13.46, 37.16, 7.98);
        context.bezierCurveTo(31.1, 5.8, 63.1, -1.5, 63.1, -1.5);
        context.lineTo(59.49, 25.15);
        context.lineTo(51.85, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2350 Drawing
        context.beginPath();
        context.moveTo(39.16, 4.77);
        context.bezierCurveTo(37.43, 8.16, 33.91, 10.5, 29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.16, 20.53, 4.77);
        context.bezierCurveTo(15.99, 5.53, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.16, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt4(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';
        var strokeColor19 = 'rgba(242, 229, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2236 Drawing
        context.beginPath();
        context.moveTo(28.92, 10.5);
        context.bezierCurveTo(32.96, 10.5, 36.44, 8.2, 38.18, 4.86);
        context.lineTo(38.18, 4.73);
        context.bezierCurveTo(35.47, 3.37, 35.89, 2.21, 36.49, 1.14);
        context.bezierCurveTo(34.09, 1.89, 31.31, 2.22, 28.68, 2.23);
        context.bezierCurveTo(26.07, 2.22, 23.78, 1.9, 21.38, 1.15);
        context.bezierCurveTo(21.98, 2.22, 22.39, 3.38, 19.68, 4.73);
        context.lineTo(19.69, 4.91);
        context.bezierCurveTo(21.45, 8.23, 24.91, 10.5, 28.92, 10.5);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2237 Drawing
        context.beginPath();
        context.moveTo(28.92, 10.5);
        context.bezierCurveTo(32.96, 10.5, 36.44, 8.2, 38.18, 4.86);
        context.lineTo(38.18, 4.73);
        context.bezierCurveTo(35.47, 3.37, 35.89, 2.21, 36.49, 1.14);
        context.bezierCurveTo(34.09, 1.89, 31.31, 2.22, 28.68, 2.23);
        context.bezierCurveTo(26.07, 2.22, 23.78, 1.9, 21.38, 1.15);
        context.bezierCurveTo(21.98, 2.22, 22.39, 3.38, 19.68, 4.73);
        context.lineTo(19.69, 4.91);
        context.bezierCurveTo(21.45, 8.23, 24.91, 10.5, 28.92, 10.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2337 Drawing
        context.beginPath();
        context.moveTo(38.3, 4.78);
        context.bezierCurveTo(36.59, 8.22, 33.09, 10.59, 29.04, 10.59);
        context.bezierCurveTo(25, 10.59, 21.5, 8.22, 19.78, 4.78);
        context.bezierCurveTo(15.28, 5.56, 1.4, 8.01, 0.84, 24.55);
        context.lineTo(29.04, 24.55);
        context.lineTo(57.25, 24.55);
        context.bezierCurveTo(56.69, 8.01, 42.82, 5.56, 38.3, 4.78);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Group 35
        context.save();

        //// Clip Clip 33

        context.beginPath();
        context.moveTo(29.04, 10.59);
        context.bezierCurveTo(25, 10.59, 21.5, 8.23, 19.79, 4.78);
        context.lineTo(19.79, 4.78);
        context.bezierCurveTo(15.28, 5.56, 1.4, 8.01, 0.84, 24.55);
        context.lineTo(0.84, 24.55);
        context.lineTo(29.04, 24.55);
        context.lineTo(57.25, 24.55);
        context.bezierCurveTo(56.69, 8.01, 42.82, 5.56, 38.3, 4.78);
        context.lineTo(38.3, 4.78);
        context.bezierCurveTo(36.59, 8.23, 33.09, 10.59, 29.04, 10.59);
        context.closePath();
        context.clip();



        //// Rectangle 83 Drawing
        context.beginPath();
        context.rect(10.98, -0.39, 7.35, 36.55);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 84 Drawing
        context.beginPath();
        context.rect(18.33, -0.39, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 85 Drawing
        context.beginPath();
        context.rect(25.73, -0.39, 7.35, 36.55);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 86 Drawing
        context.beginPath();
        context.rect(33.08, -0.39, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 87 Drawing
        context.beginPath();
        context.rect(40.48, -0.39, 7.35, 36.55);
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 2338 Drawing
        context.beginPath();
        context.moveTo(29.18, 14.41);
        context.bezierCurveTo(23.07, 14.41, 17.56, 11.09, 14.79, 5.76);
        context.lineTo(21.2, 2.56);
        context.bezierCurveTo(22.74, 5.52, 25.8, 7.36, 29.18, 7.36);
        context.bezierCurveTo(32.55, 7.36, 35.61, 5.52, 37.15, 2.56);
        context.lineTo(43.56, 5.76);
        context.bezierCurveTo(40.79, 11.09, 35.28, 14.41, 29.18, 14.41);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2339 Drawing
        context.beginPath();
        context.moveTo(15.7, 5.75);
        context.bezierCurveTo(16.9, 9.33, 19.9, 12.38, 23.92, 12.94);
        context.strokeStyle = strokeColor19;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2340 Drawing
        context.beginPath();
        context.moveTo(42.92, 5.75);
        context.bezierCurveTo(42.37, 9.31, 38.77, 12.47, 34.7, 12.94);
        context.strokeStyle = strokeColor19;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2341 Drawing
        context.beginPath();
        context.moveTo(-161.85, 12.62);
        context.bezierCurveTo(-167.18, 12.62, -172, 9.53, -174.43, 4.55);
        context.lineTo(-168.82, 1.57);
        context.bezierCurveTo(-167.47, 4.33, -164.8, 6.05, -161.85, 6.05);
        context.bezierCurveTo(-158.9, 6.05, -156.23, 4.33, -154.88, 1.57);
        context.lineTo(-149.27, 4.55);
        context.bezierCurveTo(-151.7, 9.53, -156.52, 12.62, -161.85, 12.62);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2343 Drawing
        context.beginPath();
        context.moveTo(38.3, 4.78);
        context.bezierCurveTo(36.59, 8.22, 33.09, 10.59, 29.04, 10.59);
        context.bezierCurveTo(25, 10.59, 21.5, 8.22, 19.78, 4.78);
        context.bezierCurveTo(15.28, 5.56, 1.4, 8.01, 0.84, 24.55);
        context.lineTo(29.04, 24.55);
        context.lineTo(57.25, 24.55);
        context.bezierCurveTo(56.69, 8.01, 42.82, 5.56, 38.3, 4.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt5(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor56 = 'rgba(1, 92, 170, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2240 Drawing
        context.beginPath();
        context.moveTo(29.92, 11.16);
        context.bezierCurveTo(33.96, 11.16, 37.44, 8.87, 39.18, 5.52);
        context.lineTo(39.18, 5.4);
        context.bezierCurveTo(36.47, 4.04, 36.89, 2.87, 37.49, 1.8);
        context.bezierCurveTo(35.09, 2.56, 32.31, 2.89, 29.68, 2.89);
        context.bezierCurveTo(27.07, 2.89, 24.78, 2.56, 22.38, 1.81);
        context.bezierCurveTo(22.98, 2.88, 23.39, 4.04, 20.68, 5.4);
        context.lineTo(20.69, 5.58);
        context.bezierCurveTo(22.45, 8.89, 25.91, 11.16, 29.92, 11.16);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2241 Drawing
        context.beginPath();
        context.moveTo(29.92, 11.16);
        context.bezierCurveTo(33.96, 11.16, 37.44, 8.87, 39.18, 5.52);
        context.lineTo(39.18, 5.4);
        context.bezierCurveTo(36.47, 4.04, 36.89, 2.87, 37.49, 1.8);
        context.bezierCurveTo(35.09, 2.56, 32.31, 2.89, 29.68, 2.89);
        context.bezierCurveTo(27.07, 2.89, 24.78, 2.56, 22.38, 1.81);
        context.bezierCurveTo(22.98, 2.88, 23.39, 4.04, 20.68, 5.4);
        context.lineTo(20.69, 5.58);
        context.bezierCurveTo(22.45, 8.89, 25.91, 11.16, 29.92, 11.16);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Group 36
        context.save();

        //// Clip Clip 34

        context.beginPath();
        context.moveTo(29.75, 11.13);
        context.bezierCurveTo(25.68, 11.13, 22.16, 8.8, 20.44, 5.4);
        context.lineTo(20.44, 5.4);
        context.bezierCurveTo(15.9, 6.17, 1.94, 8.58, 1.38, 24.88);
        context.lineTo(1.38, 24.88);
        context.lineTo(29.75, 24.88);
        context.lineTo(58.12, 24.88);
        context.bezierCurveTo(57.56, 8.59, 43.6, 6.17, 39.07, 5.4);
        context.lineTo(39.07, 5.4);
        context.bezierCurveTo(37.34, 8.8, 33.82, 11.13, 29.75, 11.13);
        context.closePath();
        context.clip();



        //// Rectangle 2 Drawing
        context.beginPath();
        context.rect(1, 6.86, 10, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 88 Drawing
        context.beginPath();
        context.rect(11.33, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor56;
        context.fill();


        //// Rectangle 89 Drawing
        context.beginPath();
        context.rect(18.68, -3.14, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 90 Drawing
        context.beginPath();
        context.rect(26.08, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor56;
        context.fill();


        //// Rectangle 91 Drawing
        context.beginPath();
        context.rect(33.43, -3.14, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 92 Drawing
        context.beginPath();
        context.rect(40.83, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor56;
        context.fill();


        //// Rectangle Drawing
        context.beginPath();
        context.rect(48.43, 6.86, 10.57, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2345 Drawing
        context.beginPath();
        context.moveTo(39.07, 5.4);
        context.bezierCurveTo(37.34, 8.8, 33.82, 11.13, 29.75, 11.13);
        context.bezierCurveTo(25.68, 11.13, 22.16, 8.8, 20.44, 5.4);
        context.bezierCurveTo(15.9, 6.17, 1.94, 8.58, 1.38, 24.88);
        context.lineTo(29.75, 24.88);
        context.lineTo(58.12, 24.88);
        context.bezierCurveTo(57.56, 8.59, 43.6, 6.17, 39.07, 5.4);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt6(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor55 = 'rgba(116, 35, 58, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2238 Drawing
        context.beginPath();
        context.moveTo(29.9, 10.99);
        context.bezierCurveTo(33.93, 10.99, 37.42, 8.7, 39.16, 5.36);
        context.lineTo(39.16, 5.23);
        context.bezierCurveTo(36.45, 3.87, 36.87, 2.71, 37.47, 1.64);
        context.bezierCurveTo(35.07, 2.39, 32.29, 2.72, 29.66, 2.72);
        context.bezierCurveTo(27.04, 2.72, 24.76, 2.4, 22.36, 1.65);
        context.bezierCurveTo(22.96, 2.72, 23.37, 3.88, 20.66, 5.23);
        context.lineTo(20.67, 5.41);
        context.bezierCurveTo(22.43, 8.73, 25.89, 10.99, 29.9, 10.99);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2239 Drawing
        context.beginPath();
        context.moveTo(29.9, 10.99);
        context.bezierCurveTo(33.93, 10.99, 37.42, 8.7, 39.16, 5.36);
        context.lineTo(39.16, 5.23);
        context.bezierCurveTo(36.45, 3.87, 36.87, 2.71, 37.47, 1.64);
        context.bezierCurveTo(35.07, 2.39, 32.29, 2.72, 29.66, 2.72);
        context.bezierCurveTo(27.04, 2.72, 24.76, 2.4, 22.36, 1.65);
        context.bezierCurveTo(22.96, 2.72, 23.37, 3.88, 20.66, 5.23);
        context.lineTo(20.67, 5.41);
        context.bezierCurveTo(22.43, 8.73, 25.89, 10.99, 29.9, 10.99);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2331 Drawing
        context.beginPath();
        context.moveTo(39.22, 5.27);
        context.bezierCurveTo(37.49, 8.66, 33.97, 10.99, 29.9, 10.99);
        context.bezierCurveTo(25.83, 10.99, 22.31, 8.66, 20.59, 5.27);
        context.bezierCurveTo(16.05, 6.03, 2.09, 8.45, 1.53, 24.75);
        context.lineTo(29.9, 24.75);
        context.lineTo(58.27, 24.75);
        context.bezierCurveTo(57.71, 8.45, 43.75, 6.03, 39.22, 5.27);
        context.closePath();
        context.fillStyle = fillColor55;
        context.fill();


        //// Group 34
        context.save();

        //// Clip Clip 32

        context.beginPath();
        context.moveTo(29.9, 10.99);
        context.bezierCurveTo(25.83, 10.99, 22.31, 8.66, 20.59, 5.27);
        context.lineTo(20.59, 5.27);
        context.bezierCurveTo(16.05, 6.03, 2.09, 8.45, 1.53, 24.75);
        context.lineTo(1.53, 24.75);
        context.lineTo(29.9, 24.75);
        context.lineTo(58.27, 24.75);
        context.bezierCurveTo(57.71, 8.45, 43.75, 6.03, 39.22, 5.27);
        context.lineTo(39.22, 5.27);
        context.bezierCurveTo(37.49, 8.66, 33.97, 10.99, 29.9, 10.99);
        context.closePath();
        context.clip();



        //// Bezier 2332 Drawing
        context.beginPath();
        context.moveTo(29.9, 13.48);
        context.bezierCurveTo(24.57, 13.48, 19.75, 10.38, 17.33, 5.4);
        context.lineTo(22.93, 2.42);
        context.bezierCurveTo(24.28, 5.19, 26.95, 6.9, 29.9, 6.9);
        context.bezierCurveTo(32.85, 6.9, 35.52, 5.19, 36.87, 2.42);
        context.lineTo(42.48, 5.4);
        context.bezierCurveTo(40.05, 10.38, 35.23, 13.48, 29.9, 13.48);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2333 Drawing
        context.beginPath();
        context.moveTo(9.56, 24.71);
        context.lineTo(9.56, 5.42);
        context.lineTo(-4.85, 19.05);
        context.lineTo(-0.09, 26.15);
        context.lineTo(9.56, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Bezier 2334 Drawing
        context.beginPath();
        context.moveTo(49.37, 24.71);
        context.lineTo(49.37, 5.42);
        context.lineTo(63.78, 19.05);
        context.lineTo(59.01, 26.15);
        context.lineTo(49.37, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        context.restore();



        //// Bezier 2336 Drawing
        context.beginPath();
        context.moveTo(39.22, 5.27);
        context.bezierCurveTo(37.49, 8.66, 33.97, 10.99, 29.9, 10.99);
        context.bezierCurveTo(25.83, 10.99, 22.31, 8.66, 20.59, 5.27);
        context.bezierCurveTo(16.05, 6.03, 2.09, 8.45, 1.53, 24.75);
        context.lineTo(29.9, 24.75);
        context.lineTo(58.27, 24.75);
        context.bezierCurveTo(57.71, 8.45, 43.75, 6.03, 39.22, 5.27);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt7(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2260 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.98);
        context.bezierCurveTo(33.87, 10.98, 37.36, 8.68, 39.1, 5.34);
        context.lineTo(39.1, 5.21);
        context.bezierCurveTo(36.39, 3.86, 36.81, 2.69, 37.41, 1.62);
        context.bezierCurveTo(35, 2.37, 32.23, 2.7, 29.6, 2.71);
        context.bezierCurveTo(26.98, 2.7, 24.69, 2.38, 22.3, 1.63);
        context.bezierCurveTo(22.89, 2.7, 23.31, 3.86, 20.6, 5.21);
        context.lineTo(20.61, 5.39);
        context.bezierCurveTo(22.36, 8.71, 25.83, 10.98, 29.84, 10.98);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2261 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.98);
        context.bezierCurveTo(33.87, 10.98, 37.36, 8.68, 39.1, 5.34);
        context.lineTo(39.1, 5.21);
        context.bezierCurveTo(36.39, 3.86, 36.81, 2.69, 37.41, 1.62);
        context.bezierCurveTo(35, 2.37, 32.23, 2.7, 29.6, 2.71);
        context.bezierCurveTo(26.98, 2.7, 24.69, 2.38, 22.3, 1.63);
        context.bezierCurveTo(22.89, 2.7, 23.31, 3.86, 20.6, 5.21);
        context.lineTo(20.61, 5.39);
        context.bezierCurveTo(22.36, 8.71, 25.83, 10.98, 29.84, 10.98);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2394 Drawing
        context.beginPath();
        context.moveTo(39.29, 5.33);
        context.bezierCurveTo(37.56, 8.73, 34.04, 11.06, 29.97, 11.06);
        context.bezierCurveTo(25.9, 11.06, 22.38, 8.73, 20.66, 5.33);
        context.bezierCurveTo(16.12, 6.1, 2.16, 8.51, 1.6, 24.81);
        context.lineTo(29.97, 24.81);
        context.lineTo(58.34, 24.81);
        context.bezierCurveTo(57.78, 8.52, 43.83, 6.1, 39.29, 5.33);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair17;
        context.fill();


        //// Group 45
        context.save();

        //// Clip Clip 43

        context.beginPath();
        context.moveTo(29.97, 11.06);
        context.bezierCurveTo(25.9, 11.06, 22.38, 8.73, 20.66, 5.33);
        context.lineTo(20.66, 5.33);
        context.bezierCurveTo(16.12, 6.1, 2.16, 8.51, 1.6, 24.81);
        context.lineTo(1.6, 24.81);
        context.lineTo(29.97, 24.81);
        context.lineTo(58.34, 24.81);
        context.bezierCurveTo(57.78, 8.52, 43.83, 6.1, 39.29, 5.33);
        context.lineTo(39.29, 5.33);
        context.bezierCurveTo(37.56, 8.73, 34.04, 11.06, 29.97, 11.06);
        context.closePath();
        context.clip();



        //// Bezier 2395 Drawing
        context.beginPath();
        context.moveTo(10.56, 24.71);
        context.lineTo(10.56, 5.42);
        context.lineTo(-3.85, 19.05);
        context.lineTo(0.91, 26.15);
        context.lineTo(10.56, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 2396 Drawing
        context.beginPath();
        context.moveTo(50.37, 24.71);
        context.lineTo(50.37, 5.42);
        context.lineTo(64.78, 19.05);
        context.lineTo(60.01, 26.15);
        context.lineTo(50.37, 24.71);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 2397 Drawing
        context.beginPath();
        context.moveTo(30.05, 9.75);
        context.lineTo(30.05, 13.08);
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 2;
        context.stroke();


        context.restore();



        //// Bezier 2399 Drawing
        context.beginPath();
        context.moveTo(39.29, 5.33);
        context.bezierCurveTo(37.56, 8.73, 34.04, 11.06, 29.97, 11.06);
        context.bezierCurveTo(25.9, 11.06, 22.38, 8.73, 20.66, 5.33);
        context.bezierCurveTo(16.12, 6.1, 2.16, 8.51, 1.6, 24.81);
        context.lineTo(29.97, 24.81);
        context.lineTo(58.34, 24.81);
        context.bezierCurveTo(57.78, 8.52, 43.83, 6.1, 39.29, 5.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt8(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2252 Drawing
        context.beginPath();
        context.moveTo(29.67, 11.08);
        context.bezierCurveTo(33.71, 11.08, 37.19, 8.79, 38.93, 5.44);
        context.lineTo(38.93, 5.31);
        context.bezierCurveTo(36.22, 3.96, 36.64, 2.79, 37.24, 1.72);
        context.bezierCurveTo(34.84, 2.48, 32.06, 2.8, 29.43, 2.81);
        context.bezierCurveTo(26.82, 2.8, 24.53, 2.48, 22.13, 1.73);
        context.bezierCurveTo(22.73, 2.8, 23.14, 3.96, 20.43, 5.31);
        context.lineTo(20.45, 5.5);
        context.bezierCurveTo(22.2, 8.81, 25.66, 11.08, 29.67, 11.08);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2253 Drawing
        context.beginPath();
        context.moveTo(29.67, 11.08);
        context.bezierCurveTo(33.71, 11.08, 37.19, 8.79, 38.93, 5.44);
        context.lineTo(38.93, 5.31);
        context.bezierCurveTo(36.22, 3.96, 36.64, 2.79, 37.24, 1.72);
        context.bezierCurveTo(34.84, 2.48, 32.06, 2.8, 29.43, 2.81);
        context.bezierCurveTo(26.82, 2.8, 24.53, 2.48, 22.13, 1.73);
        context.bezierCurveTo(22.73, 2.8, 23.14, 3.96, 20.43, 5.31);
        context.lineTo(20.45, 5.5);
        context.bezierCurveTo(22.2, 8.81, 25.66, 11.08, 29.67, 11.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2400 Drawing
        context.beginPath();
        context.moveTo(38.99, 5.35);
        context.bezierCurveTo(37.26, 8.75, 33.74, 11.08, 29.68, 11.08);
        context.bezierCurveTo(25.6, 11.08, 22.09, 8.75, 20.36, 5.35);
        context.bezierCurveTo(15.83, 6.12, 1.86, 8.53, 1.3, 24.83);
        context.lineTo(29.68, 24.83);
        context.lineTo(58.05, 24.83);
        context.bezierCurveTo(57.48, 8.54, 43.53, 6.12, 38.99, 5.35);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Group 46
        context.save();

        //// Clip Clip 44

        context.beginPath();
        context.moveTo(29.68, 11.08);
        context.bezierCurveTo(25.6, 11.08, 22.09, 8.75, 20.36, 5.35);
        context.lineTo(20.36, 5.35);
        context.bezierCurveTo(15.83, 6.12, 1.86, 8.53, 1.3, 24.83);
        context.lineTo(1.3, 24.83);
        context.lineTo(29.68, 24.83);
        context.lineTo(58.05, 24.83);
        context.bezierCurveTo(57.48, 8.54, 43.53, 6.12, 38.99, 5.35);
        context.lineTo(38.99, 5.35);
        context.bezierCurveTo(37.26, 8.75, 33.74, 11.08, 29.68, 11.08);
        context.closePath();
        context.clip();



        //// Bezier 2401 Drawing
        context.beginPath();
        context.moveTo(29.31, 14.43);
        context.bezierCurveTo(23.97, 14.43, 19.16, 11.34, 16.73, 6.36);
        context.lineTo(22.34, 3.38);
        context.bezierCurveTo(23.69, 6.14, 26.36, 7.85, 29.31, 7.85);
        context.bezierCurveTo(32.26, 7.85, 34.93, 6.14, 36.27, 3.38);
        context.lineTo(41.88, 6.36);
        context.bezierCurveTo(39.46, 11.34, 34.64, 14.43, 29.31, 14.43);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2402 Drawing
        context.beginPath();
        context.moveTo(29.31, 14.43);
        context.bezierCurveTo(23.97, 14.43, 19.16, 11.34, 16.73, 6.36);
        context.lineTo(22.34, 3.38);
        context.bezierCurveTo(23.69, 6.14, 26.36, 7.85, 29.31, 7.85);
        context.bezierCurveTo(32.26, 7.85, 34.93, 6.14, 36.27, 3.38);
        context.lineTo(41.88, 6.36);
        context.bezierCurveTo(39.46, 11.34, 34.64, 14.43, 29.31, 14.43);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2403 Drawing
        context.beginPath();
        context.moveTo(20.99, 8.65);
        context.bezierCurveTo(20.99, 8.65, 5.36, 11.64, 5.86, 24.86);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        //// Bezier 2404 Drawing
        context.beginPath();
        context.moveTo(37.85, 8.65);
        context.bezierCurveTo(37.85, 8.65, 53.48, 11.64, 52.98, 24.86);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        context.restore();



        //// Bezier 2406 Drawing
        context.beginPath();
        context.moveTo(38.99, 5.35);
        context.bezierCurveTo(37.26, 8.75, 33.74, 11.08, 29.68, 11.08);
        context.bezierCurveTo(25.6, 11.08, 22.09, 8.75, 20.36, 5.35);
        context.bezierCurveTo(15.83, 6.12, 1.86, 8.53, 1.3, 24.83);
        context.lineTo(29.68, 24.83);
        context.lineTo(58.05, 24.83);
        context.bezierCurveTo(57.48, 8.54, 43.53, 6.12, 38.99, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt9(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor57 = 'rgba(3, 70, 148, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2244 Drawing
        context.beginPath();
        context.moveTo(29.78, 10.51);
        context.bezierCurveTo(33.81, 10.51, 37.3, 8.22, 39.04, 4.88);
        context.lineTo(39.04, 4.75);
        context.bezierCurveTo(36.33, 3.39, 36.75, 2.23, 37.35, 1.16);
        context.bezierCurveTo(34.95, 1.91, 32.17, 2.24, 29.54, 2.24);
        context.bezierCurveTo(26.92, 2.24, 24.64, 1.91, 22.24, 1.16);
        context.bezierCurveTo(22.84, 2.23, 23.25, 3.39, 20.54, 4.75);
        context.lineTo(20.55, 4.93);
        context.bezierCurveTo(22.31, 8.24, 25.77, 10.51, 29.78, 10.51);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2245 Drawing
        context.beginPath();
        context.moveTo(29.78, 10.51);
        context.bezierCurveTo(33.81, 10.51, 37.3, 8.22, 39.04, 4.88);
        context.lineTo(39.04, 4.75);
        context.bezierCurveTo(36.33, 3.39, 36.75, 2.23, 37.35, 1.16);
        context.bezierCurveTo(34.95, 1.91, 32.17, 2.24, 29.54, 2.24);
        context.bezierCurveTo(26.92, 2.24, 24.64, 1.91, 22.24, 1.16);
        context.bezierCurveTo(22.84, 2.23, 23.25, 3.39, 20.54, 4.75);
        context.lineTo(20.55, 4.93);
        context.bezierCurveTo(22.31, 8.24, 25.77, 10.51, 29.78, 10.51);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2436 Drawing
        context.beginPath();
        context.moveTo(39.1, 4.79);
        context.bezierCurveTo(37.37, 8.18, 33.85, 10.51, 29.78, 10.51);
        context.bezierCurveTo(25.71, 10.51, 22.19, 8.18, 20.47, 4.79);
        context.bezierCurveTo(15.93, 5.55, 1.97, 7.97, 1.41, 24.26);
        context.lineTo(29.78, 24.26);
        context.lineTo(58.15, 24.26);
        context.bezierCurveTo(57.59, 7.97, 43.64, 5.55, 39.1, 4.79);
        context.closePath();
        context.fillStyle = fillColor57;
        context.fill();


        //// Group 52
        context.save();

        //// Clip Clip 50

        context.beginPath();
        context.moveTo(29.78, 10.51);
        context.bezierCurveTo(25.71, 10.51, 22.19, 8.18, 20.47, 4.79);
        context.lineTo(20.47, 4.79);
        context.bezierCurveTo(15.93, 5.55, 1.97, 7.96, 1.41, 24.26);
        context.lineTo(1.41, 24.26);
        context.lineTo(29.78, 24.26);
        context.lineTo(58.15, 24.26);
        context.bezierCurveTo(57.59, 7.97, 43.64, 5.55, 39.1, 4.79);
        context.lineTo(39.1, 4.79);
        context.bezierCurveTo(37.37, 8.18, 33.85, 10.51, 29.78, 10.51);
        context.closePath();
        context.clip();



        //// Bezier 2437 Drawing
        context.beginPath();
        context.moveTo(30.06, 13.99);
        context.bezierCurveTo(24.73, 13.99, 19.91, 10.89, 17.48, 5.91);
        context.lineTo(23.09, 2.93);
        context.bezierCurveTo(24.44, 5.69, 27.11, 7.41, 30.06, 7.41);
        context.bezierCurveTo(33.01, 7.41, 35.68, 5.69, 37.03, 2.93);
        context.lineTo(42.64, 5.91);
        context.bezierCurveTo(40.21, 10.89, 35.39, 13.99, 30.06, 13.99);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2439 Drawing
        context.beginPath();
        context.moveTo(39.1, 4.79);
        context.bezierCurveTo(37.37, 8.18, 33.85, 10.51, 29.78, 10.51);
        context.bezierCurveTo(25.71, 10.51, 22.19, 8.18, 20.47, 4.79);
        context.bezierCurveTo(15.93, 5.55, 1.97, 7.97, 1.41, 24.26);
        context.lineTo(29.78, 24.26);
        context.lineTo(58.15, 24.26);
        context.bezierCurveTo(57.59, 7.97, 43.64, 5.55, 39.1, 4.79);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt10(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2254 Drawing
        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(33.68, 11.08, 37.17, 8.79, 38.91, 5.44);
        context.lineTo(38.91, 5.31);
        context.bezierCurveTo(36.2, 3.96, 36.62, 2.79, 37.22, 1.72);
        context.bezierCurveTo(34.82, 2.48, 32.04, 2.8, 29.41, 2.81);
        context.bezierCurveTo(26.79, 2.8, 24.51, 2.48, 22.11, 1.73);
        context.bezierCurveTo(22.71, 2.8, 23.12, 3.96, 20.41, 5.31);
        context.lineTo(20.42, 5.5);
        context.bezierCurveTo(22.18, 8.81, 25.64, 11.08, 29.65, 11.08);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2255 Drawing
        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(33.68, 11.08, 37.17, 8.79, 38.91, 5.44);
        context.lineTo(38.91, 5.31);
        context.bezierCurveTo(36.2, 3.96, 36.62, 2.79, 37.22, 1.72);
        context.bezierCurveTo(34.82, 2.48, 32.04, 2.8, 29.41, 2.81);
        context.bezierCurveTo(26.79, 2.8, 24.51, 2.48, 22.11, 1.73);
        context.bezierCurveTo(22.71, 2.8, 23.12, 3.96, 20.41, 5.31);
        context.lineTo(20.42, 5.5);
        context.bezierCurveTo(22.18, 8.81, 25.64, 11.08, 29.65, 11.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 47
        context.save();

        //// Clip Clip 45

        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(25.58, 11.08, 22.06, 8.75, 20.34, 5.35);
        context.lineTo(20.34, 5.35);
        context.bezierCurveTo(15.8, 6.12, 1.84, 8.53, 1.28, 24.83);
        context.lineTo(1.28, 24.83);
        context.lineTo(29.65, 24.83);
        context.lineTo(58.02, 24.83);
        context.bezierCurveTo(57.46, 8.54, 43.51, 6.12, 38.97, 5.35);
        context.lineTo(38.97, 5.35);
        context.bezierCurveTo(37.24, 8.75, 33.72, 11.08, 29.65, 11.08);
        context.closePath();
        context.clip();



        //// Rectangle 2 Drawing
        context.beginPath();
        context.rect(-0.67, 6.86, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 101 Drawing
        context.beginPath();
        context.rect(3.98, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 102 Drawing
        context.beginPath();
        context.rect(11.33, -3.14, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 103 Drawing
        context.beginPath();
        context.rect(18.73, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 104 Drawing
        context.beginPath();
        context.rect(26.08, -3.14, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 105 Drawing
        context.beginPath();
        context.rect(33.48, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 106 Drawing
        context.beginPath();
        context.rect(40.78, -3.14, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle Drawing
        context.beginPath();
        context.rect(50.78, 6.86, 7.35, 36.55);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 107 Drawing
        context.beginPath();
        context.rect(48.13, -3.14, 7.35, 36.55);
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2407 Drawing
        context.beginPath();
        context.moveTo(29.81, 14.27);
        context.bezierCurveTo(24.47, 14.27, 19.65, 11.17, 17.23, 6.19);
        context.lineTo(22.84, 3.21);
        context.bezierCurveTo(24.18, 5.97, 26.85, 7.69, 29.81, 7.69);
        context.bezierCurveTo(32.76, 7.69, 35.43, 5.97, 36.77, 3.21);
        context.lineTo(42.38, 6.19);
        context.bezierCurveTo(39.96, 11.17, 35.14, 14.27, 29.81, 14.27);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2409 Drawing
        context.beginPath();
        context.moveTo(38.97, 5.35);
        context.bezierCurveTo(37.24, 8.75, 33.72, 11.08, 29.65, 11.08);
        context.bezierCurveTo(25.58, 11.08, 22.06, 8.75, 20.34, 5.35);
        context.bezierCurveTo(15.8, 6.12, 1.84, 8.53, 1.28, 24.83);
        context.lineTo(29.65, 24.83);
        context.lineTo(58.02, 24.83);
        context.bezierCurveTo(57.46, 8.54, 43.51, 6.12, 38.97, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt11(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2270 Drawing
        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(33.68, 11.08, 37.17, 8.79, 38.91, 5.44);
        context.lineTo(38.91, 5.31);
        context.bezierCurveTo(36.2, 3.96, 36.62, 2.79, 37.22, 1.72);
        context.bezierCurveTo(34.82, 2.48, 32.04, 2.8, 29.41, 2.81);
        context.bezierCurveTo(26.79, 2.8, 24.51, 2.48, 22.11, 1.73);
        context.bezierCurveTo(22.71, 2.8, 23.12, 3.96, 20.41, 5.31);
        context.lineTo(20.42, 5.5);
        context.bezierCurveTo(22.18, 8.81, 25.64, 11.08, 29.65, 11.08);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2271 Drawing
        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(33.68, 11.08, 37.17, 8.79, 38.91, 5.44);
        context.lineTo(38.91, 5.31);
        context.bezierCurveTo(36.2, 3.96, 36.62, 2.79, 37.22, 1.72);
        context.bezierCurveTo(34.82, 2.48, 32.04, 2.8, 29.41, 2.81);
        context.bezierCurveTo(26.79, 2.8, 24.51, 2.48, 22.11, 1.73);
        context.bezierCurveTo(22.71, 2.8, 23.12, 3.96, 20.41, 5.31);
        context.lineTo(20.42, 5.5);
        context.bezierCurveTo(22.18, 8.81, 25.64, 11.08, 29.65, 11.08);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2445 Drawing
        context.beginPath();
        context.moveTo(38.97, 5.35);
        context.bezierCurveTo(37.24, 8.75, 33.72, 11.08, 29.65, 11.08);
        context.bezierCurveTo(25.58, 11.08, 22.06, 8.75, 20.34, 5.35);
        context.bezierCurveTo(15.8, 6.12, 1.84, 8.53, 1.28, 24.83);
        context.lineTo(29.65, 24.83);
        context.lineTo(58.02, 24.83);
        context.bezierCurveTo(57.46, 8.54, 43.51, 6.12, 38.97, 5.35);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Group 54
        context.save();

        //// Clip Clip 52

        context.beginPath();
        context.moveTo(29.65, 11.08);
        context.bezierCurveTo(25.58, 11.08, 22.06, 8.75, 20.34, 5.35);
        context.lineTo(20.34, 5.35);
        context.bezierCurveTo(15.8, 6.12, 1.84, 8.53, 1.28, 24.83);
        context.lineTo(1.28, 24.83);
        context.lineTo(29.65, 24.83);
        context.lineTo(58.02, 24.83);
        context.bezierCurveTo(57.46, 8.54, 43.51, 6.12, 38.97, 5.35);
        context.lineTo(38.97, 5.35);
        context.bezierCurveTo(37.24, 8.75, 33.72, 11.08, 29.65, 11.08);
        context.closePath();
        context.clip();



        //// Rectangle 121 Drawing
        context.beginPath();
        context.rect(-1.27, 1.88, 64.75, 15.3);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2446 Drawing
        context.beginPath();
        context.moveTo(29.81, 14.27);
        context.bezierCurveTo(24.47, 14.27, 19.65, 11.17, 17.23, 6.19);
        context.lineTo(22.84, 3.21);
        context.bezierCurveTo(24.18, 5.97, 26.85, 7.69, 29.81, 7.69);
        context.bezierCurveTo(32.76, 7.69, 35.43, 5.97, 36.77, 3.21);
        context.lineTo(42.38, 6.19);
        context.bezierCurveTo(39.96, 11.17, 35.14, 14.27, 29.81, 14.27);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 2447 Drawing
        context.beginPath();
        context.moveTo(63.48, 21.81);
        context.lineTo(-1.25, 21.81);
        context.lineTo(-1.25, 18.88);
        context.lineTo(63.48, 18.88);
        context.lineTo(63.48, 21.81);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2449 Drawing
        context.beginPath();
        context.moveTo(38.97, 5.35);
        context.bezierCurveTo(37.24, 8.75, 33.72, 11.08, 29.65, 11.08);
        context.bezierCurveTo(25.58, 11.08, 22.06, 8.75, 20.34, 5.35);
        context.bezierCurveTo(15.8, 6.12, 1.84, 8.53, 1.28, 24.83);
        context.lineTo(29.65, 24.83);
        context.lineTo(58.02, 24.83);
        context.bezierCurveTo(57.46, 8.54, 43.51, 6.12, 38.97, 5.35);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt12(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2268 Drawing
        context.beginPath();
        context.moveTo(30.37, 10.68);
        context.bezierCurveTo(34.4, 10.68, 37.89, 8.39, 39.63, 5.05);
        context.lineTo(39.63, 4.92);
        context.bezierCurveTo(36.92, 3.56, 37.34, 2.4, 37.94, 1.33);
        context.bezierCurveTo(35.54, 2.08, 32.76, 2.41, 30.13, 2.41);
        context.bezierCurveTo(27.51, 2.41, 25.23, 2.08, 22.83, 1.34);
        context.bezierCurveTo(23.43, 2.41, 23.84, 3.57, 21.13, 4.92);
        context.lineTo(21.14, 5.1);
        context.bezierCurveTo(22.9, 8.42, 26.36, 10.68, 30.37, 10.68);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2269 Drawing
        context.beginPath();
        context.moveTo(30.37, 10.68);
        context.bezierCurveTo(34.4, 10.68, 37.89, 8.39, 39.63, 5.05);
        context.lineTo(39.63, 4.92);
        context.bezierCurveTo(36.92, 3.56, 37.34, 2.4, 37.94, 1.33);
        context.bezierCurveTo(35.54, 2.08, 32.76, 2.41, 30.13, 2.41);
        context.bezierCurveTo(27.51, 2.41, 25.23, 2.08, 22.83, 1.34);
        context.bezierCurveTo(23.43, 2.41, 23.84, 3.57, 21.13, 4.92);
        context.lineTo(21.14, 5.1);
        context.bezierCurveTo(22.9, 8.42, 26.36, 10.68, 30.37, 10.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2410 Drawing
        context.beginPath();
        context.moveTo(39.35, 4.77);
        context.bezierCurveTo(37.62, 8.16, 34.1, 10.5, 30.03, 10.5);
        context.bezierCurveTo(25.96, 10.5, 22.45, 8.16, 20.72, 4.77);
        context.bezierCurveTo(16.18, 5.54, 2.22, 7.95, 1.66, 24.25);
        context.lineTo(30.03, 24.25);
        context.lineTo(58.41, 24.25);
        context.bezierCurveTo(57.84, 7.95, 43.89, 5.54, 39.35, 4.77);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Group 48
        context.save();

        //// Clip Clip 46

        context.beginPath();
        context.moveTo(30.03, 10.5);
        context.bezierCurveTo(25.96, 10.5, 22.45, 8.17, 20.72, 4.77);
        context.lineTo(20.72, 4.77);
        context.bezierCurveTo(16.18, 5.54, 2.22, 7.95, 1.66, 24.25);
        context.lineTo(1.66, 24.25);
        context.lineTo(30.03, 24.25);
        context.lineTo(58.41, 24.25);
        context.bezierCurveTo(57.84, 7.95, 43.89, 5.54, 39.35, 4.77);
        context.lineTo(39.35, 4.77);
        context.bezierCurveTo(37.62, 8.17, 34.1, 10.5, 30.03, 10.5);
        context.closePath();
        context.clip();



        //// Rectangle 108 Drawing
        context.beginPath();
        context.rect(9.22, 17.69, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 109 Drawing
        context.beginPath();
        context.rect(15.17, 17.69, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 110 Drawing
        context.beginPath();
        context.rect(21.12, 17.69, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 111 Drawing
        context.beginPath();
        context.rect(27.07, 17.69, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 112 Drawing
        context.beginPath();
        context.rect(32.97, 17.69, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 113 Drawing
        context.beginPath();
        context.rect(38.97, 17.69, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 114 Drawing
        context.beginPath();
        context.rect(44.87, 17.69, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2411 Drawing
        context.beginPath();
        context.moveTo(30.02, 12.74);
        context.bezierCurveTo(24.69, 12.74, 19.87, 9.65, 17.45, 4.67);
        context.lineTo(23.06, 1.69);
        context.bezierCurveTo(24.4, 4.45, 27.07, 6.17, 30.02, 6.17);
        context.bezierCurveTo(32.98, 6.17, 35.64, 4.45, 36.99, 1.69);
        context.lineTo(42.6, 4.67);
        context.bezierCurveTo(40.18, 9.65, 35.36, 12.74, 30.02, 12.74);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2412 Drawing
        context.beginPath();
        context.moveTo(30.02, 9.5);
        context.lineTo(30.02, 15.31);
        context.fillStyle = fillColor5;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 2;
        context.stroke();


        context.restore();



        //// Bezier 2414 Drawing
        context.beginPath();
        context.moveTo(39.35, 4.77);
        context.bezierCurveTo(37.62, 8.16, 34.1, 10.5, 30.03, 10.5);
        context.bezierCurveTo(25.96, 10.5, 22.45, 8.16, 20.72, 4.77);
        context.bezierCurveTo(16.18, 5.54, 2.22, 7.95, 1.66, 24.25);
        context.lineTo(30.03, 24.25);
        context.lineTo(58.41, 24.25);
        context.bezierCurveTo(57.84, 7.95, 43.89, 5.54, 39.35, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt13(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor59 = 'rgba(35, 46, 91, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2266 Drawing
        context.beginPath();
        context.moveTo(30.03, 10.5);
        context.bezierCurveTo(34.07, 10.5, 37.55, 8.2, 39.29, 4.86);
        context.lineTo(39.29, 4.73);
        context.bezierCurveTo(36.58, 3.37, 37, 2.21, 37.6, 1.14);
        context.bezierCurveTo(35.2, 1.89, 32.42, 2.22, 29.79, 2.23);
        context.bezierCurveTo(27.18, 2.22, 24.89, 1.9, 22.49, 1.15);
        context.bezierCurveTo(23.09, 2.22, 23.5, 3.38, 20.79, 4.73);
        context.lineTo(20.8, 4.91);
        context.bezierCurveTo(22.56, 8.23, 26.02, 10.5, 30.03, 10.5);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2267 Drawing
        context.beginPath();
        context.moveTo(30.03, 10.5);
        context.bezierCurveTo(34.07, 10.5, 37.55, 8.2, 39.29, 4.86);
        context.lineTo(39.29, 4.73);
        context.bezierCurveTo(36.58, 3.37, 37, 2.21, 37.6, 1.14);
        context.bezierCurveTo(35.2, 1.89, 32.42, 2.22, 29.79, 2.23);
        context.bezierCurveTo(27.18, 2.22, 24.89, 1.9, 22.49, 1.15);
        context.bezierCurveTo(23.09, 2.22, 23.5, 3.38, 20.79, 4.73);
        context.lineTo(20.8, 4.91);
        context.bezierCurveTo(22.56, 8.23, 26.02, 10.5, 30.03, 10.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2415 Drawing
        context.beginPath();
        context.moveTo(39.36, 4.77);
        context.bezierCurveTo(37.64, 8.16, 34.12, 10.5, 30.05, 10.5);
        context.bezierCurveTo(25.98, 10.5, 22.46, 8.16, 20.73, 4.77);
        context.bezierCurveTo(16.2, 5.54, 2.24, 7.95, 1.68, 24.25);
        context.lineTo(30.05, 24.25);
        context.lineTo(58.42, 24.25);
        context.bezierCurveTo(57.86, 7.95, 43.9, 5.54, 39.36, 4.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Group 49
        context.save();

        //// Clip Clip 47

        context.beginPath();
        context.moveTo(30.05, 10.49);
        context.bezierCurveTo(25.98, 10.49, 22.46, 8.16, 20.73, 4.77);
        context.lineTo(20.73, 4.77);
        context.bezierCurveTo(16.2, 5.53, 2.24, 7.95, 1.68, 24.25);
        context.lineTo(1.68, 24.25);
        context.lineTo(30.05, 24.25);
        context.lineTo(58.42, 24.25);
        context.bezierCurveTo(57.86, 7.95, 43.9, 5.53, 39.36, 4.77);
        context.lineTo(39.36, 4.77);
        context.bezierCurveTo(37.64, 8.16, 34.12, 10.49, 30.05, 10.49);
        context.closePath();
        context.clip();



        //// Bezier 2416 Drawing
        context.beginPath();
        context.moveTo(27.47, 6.79);
        context.bezierCurveTo(25.6, 6.11, 24.02, 4.71, 23.08, 2.79);
        context.lineTo(17.47, 5.77);
        context.bezierCurveTo(19.5, 9.93, 23.2, 12.77, 27.47, 13.59);
        context.lineTo(27.47, 6.79);
        context.closePath();
        context.fillStyle = fillColor59;
        context.fill();


        //// Bezier 2417 Drawing
        context.beginPath();
        context.moveTo(37.01, 2.79);
        context.bezierCurveTo(36.08, 4.71, 34.49, 6.11, 32.62, 6.79);
        context.lineTo(32.62, 13.59);
        context.bezierCurveTo(36.9, 12.77, 40.6, 9.93, 42.62, 5.77);
        context.lineTo(37.01, 2.79);
        context.closePath();
        context.fillStyle = fillColor59;
        context.fill();


        context.restore();



        //// Bezier 2419 Drawing
        context.beginPath();
        context.moveTo(39.36, 4.77);
        context.bezierCurveTo(37.64, 8.16, 34.12, 10.5, 30.05, 10.5);
        context.bezierCurveTo(25.98, 10.5, 22.46, 8.16, 20.73, 4.77);
        context.bezierCurveTo(16.2, 5.54, 2.24, 7.95, 1.68, 24.25);
        context.lineTo(30.05, 24.25);
        context.lineTo(58.42, 24.25);
        context.bezierCurveTo(57.86, 7.95, 43.9, 5.54, 39.36, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt14(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';
        var fillColor57 = 'rgba(3, 70, 148, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2262 Drawing
        context.beginPath();
        context.moveTo(29.81, 10.93);
        context.bezierCurveTo(33.84, 10.93, 37.32, 8.64, 39.07, 5.29);
        context.lineTo(39.07, 5.16);
        context.bezierCurveTo(36.35, 3.81, 36.77, 2.64, 37.37, 1.57);
        context.bezierCurveTo(34.97, 2.33, 32.19, 2.65, 29.57, 2.66);
        context.bezierCurveTo(26.95, 2.65, 24.66, 2.33, 22.26, 1.58);
        context.bezierCurveTo(22.86, 2.65, 23.27, 3.81, 20.57, 5.16);
        context.lineTo(20.58, 5.35);
        context.bezierCurveTo(22.33, 8.66, 25.8, 10.93, 29.81, 10.93);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2263 Drawing
        context.beginPath();
        context.moveTo(29.81, 10.93);
        context.bezierCurveTo(33.84, 10.93, 37.32, 8.64, 39.07, 5.29);
        context.lineTo(39.07, 5.16);
        context.bezierCurveTo(36.35, 3.81, 36.77, 2.64, 37.37, 1.57);
        context.bezierCurveTo(34.97, 2.33, 32.19, 2.65, 29.57, 2.66);
        context.bezierCurveTo(26.95, 2.65, 24.66, 2.33, 22.26, 1.58);
        context.bezierCurveTo(22.86, 2.65, 23.27, 3.81, 20.57, 5.16);
        context.lineTo(20.58, 5.35);
        context.bezierCurveTo(22.33, 8.66, 25.8, 10.93, 29.81, 10.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2351 Drawing
        context.beginPath();
        context.moveTo(39.12, 5.2);
        context.bezierCurveTo(37.39, 8.6, 33.88, 10.93, 29.81, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.43, 24.68);
        context.lineTo(29.81, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.closePath();
        context.fillStyle = fillColor57;
        context.fill();


        //// Group 38
        context.save();

        //// Clip Clip 36

        context.beginPath();
        context.moveTo(29.8, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.lineTo(20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.43, 24.68);
        context.lineTo(1.43, 24.68);
        context.lineTo(29.8, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.lineTo(39.12, 5.2);
        context.bezierCurveTo(37.39, 8.6, 33.87, 10.93, 29.8, 10.93);
        context.closePath();
        context.clip();



        //// Bezier 2352 Drawing
        context.beginPath();
        context.moveTo(1.89, 16.61);
        context.lineTo(1.89, 14.01);
        context.lineTo(26.15, 15.43);
        context.lineTo(1.89, 16.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2353 Drawing
        context.beginPath();
        context.moveTo(55.56, 14.61);
        context.lineTo(55.56, 12.01);
        context.lineTo(39.89, 13.43);
        context.lineTo(55.56, 14.61);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 2354 Drawing
        context.beginPath();
        context.moveTo(64.56, 20.61);
        context.lineTo(64.56, 18.01);
        context.lineTo(45.94, 19.43);
        context.lineTo(64.56, 20.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2355 Drawing
        context.beginPath();
        context.moveTo(1.89, 22.61);
        context.lineTo(1.89, 20.01);
        context.lineTo(17.56, 21.43);
        context.lineTo(1.89, 22.61);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        context.restore();



        //// Bezier 2357 Drawing
        context.beginPath();
        context.moveTo(39.12, 5.2);
        context.bezierCurveTo(37.39, 8.6, 33.88, 10.93, 29.81, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.43, 24.68);
        context.lineTo(29.81, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt15(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor40 = 'rgba(246, 230, 82, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2230 Drawing
        context.beginPath();
        context.moveTo(29.81, 10.8);
        context.bezierCurveTo(33.84, 10.8, 37.33, 8.51, 39.07, 5.16);
        context.lineTo(39.07, 5.03);
        context.bezierCurveTo(36.36, 3.68, 36.78, 2.51, 37.38, 1.44);
        context.bezierCurveTo(34.97, 2.2, 32.2, 2.52, 29.57, 2.53);
        context.bezierCurveTo(26.95, 2.52, 24.66, 2.2, 22.27, 1.45);
        context.bezierCurveTo(22.86, 2.52, 23.28, 3.68, 20.57, 5.03);
        context.lineTo(20.58, 5.22);
        context.bezierCurveTo(22.33, 8.53, 25.8, 10.8, 29.81, 10.8);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2231 Drawing
        context.beginPath();
        context.moveTo(29.81, 10.8);
        context.bezierCurveTo(33.84, 10.8, 37.33, 8.51, 39.07, 5.16);
        context.lineTo(39.07, 5.03);
        context.bezierCurveTo(36.36, 3.68, 36.78, 2.51, 37.38, 1.44);
        context.bezierCurveTo(34.97, 2.2, 32.2, 2.52, 29.57, 2.53);
        context.bezierCurveTo(26.95, 2.52, 24.66, 2.2, 22.27, 1.45);
        context.bezierCurveTo(22.86, 2.52, 23.28, 3.68, 20.57, 5.03);
        context.lineTo(20.58, 5.22);
        context.bezierCurveTo(22.33, 8.53, 25.8, 10.8, 29.81, 10.8);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2358 Drawing
        context.beginPath();
        context.moveTo(39.12, 5.2);
        context.bezierCurveTo(37.4, 8.6, 33.88, 10.93, 29.81, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.44, 24.68);
        context.lineTo(29.81, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Group 39
        context.save();

        //// Clip Clip 37

        context.beginPath();
        context.moveTo(29.81, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.lineTo(20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.44, 24.68);
        context.lineTo(1.44, 24.68);
        context.lineTo(29.81, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.lineTo(39.12, 5.2);
        context.bezierCurveTo(37.4, 8.6, 33.88, 10.93, 29.81, 10.93);
        context.closePath();
        context.clip();



        //// Rectangle 93 Drawing
        context.beginPath();
        context.rect(3.02, -9.47, 10.75, 53.3);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Rectangle 94 Drawing
        context.beginPath();
        context.rect(13.82, -9.47, 10.75, 53.3);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 95 Drawing
        context.beginPath();
        context.rect(24.57, -9.47, 10.75, 53.3);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Rectangle 96 Drawing
        context.beginPath();
        context.rect(35.32, -9.47, 10.75, 53.3);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 97 Drawing
        context.beginPath();
        context.rect(46.07, -9.47, 10.75, 53.3);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 2359 Drawing
        context.beginPath();
        context.moveTo(30.11, 14.3);
        context.bezierCurveTo(24.78, 14.3, 19.96, 11.2, 17.53, 6.22);
        context.lineTo(23.14, 3.24);
        context.bezierCurveTo(24.49, 6.01, 27.16, 7.72, 30.11, 7.72);
        context.bezierCurveTo(33.06, 7.72, 35.73, 6.01, 37.08, 3.24);
        context.lineTo(42.68, 6.22);
        context.bezierCurveTo(40.26, 11.2, 35.44, 14.3, 30.11, 14.3);
        context.closePath();
        context.fillStyle = fillColor40;
        context.fill();


        context.restore();



        //// Bezier 2361 Drawing
        context.beginPath();
        context.moveTo(39.12, 5.2);
        context.bezierCurveTo(37.4, 8.6, 33.88, 10.93, 29.81, 10.93);
        context.bezierCurveTo(25.74, 10.93, 22.22, 8.6, 20.49, 5.2);
        context.bezierCurveTo(15.96, 5.97, 2, 8.38, 1.44, 24.68);
        context.lineTo(29.81, 24.68);
        context.lineTo(58.18, 24.68);
        context.bezierCurveTo(57.62, 8.39, 43.66, 5.97, 39.12, 5.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt16(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor58 = 'rgba(2, 77, 158, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2232 Drawing
        context.beginPath();
        context.moveTo(29.96, 10.87);
        context.bezierCurveTo(34, 10.87, 37.48, 8.58, 39.23, 5.24);
        context.lineTo(39.23, 5.11);
        context.bezierCurveTo(36.51, 3.75, 36.93, 2.58, 37.53, 1.51);
        context.bezierCurveTo(35.13, 2.27, 32.35, 2.6, 29.73, 2.6);
        context.bezierCurveTo(27.11, 2.6, 24.82, 2.27, 22.42, 1.52);
        context.bezierCurveTo(23.02, 2.59, 23.43, 3.75, 20.73, 5.11);
        context.lineTo(20.74, 5.29);
        context.bezierCurveTo(22.49, 8.6, 25.95, 10.87, 29.96, 10.87);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2233 Drawing
        context.beginPath();
        context.moveTo(29.96, 10.87);
        context.bezierCurveTo(34, 10.87, 37.48, 8.58, 39.23, 5.24);
        context.lineTo(39.23, 5.11);
        context.bezierCurveTo(36.51, 3.75, 36.93, 2.58, 37.53, 1.51);
        context.bezierCurveTo(35.13, 2.27, 32.35, 2.6, 29.73, 2.6);
        context.bezierCurveTo(27.11, 2.6, 24.82, 2.27, 22.42, 1.52);
        context.bezierCurveTo(23.02, 2.59, 23.43, 3.75, 20.73, 5.11);
        context.lineTo(20.74, 5.29);
        context.bezierCurveTo(22.49, 8.6, 25.95, 10.87, 29.96, 10.87);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2362 Drawing
        context.beginPath();
        context.moveTo(39.28, 5.2);
        context.bezierCurveTo(37.55, 8.6, 34.04, 10.93, 29.97, 10.93);
        context.bezierCurveTo(25.9, 10.93, 22.38, 8.6, 20.65, 5.2);
        context.bezierCurveTo(16.12, 5.97, 2.16, 8.38, 1.59, 24.68);
        context.lineTo(29.97, 24.68);
        context.lineTo(58.34, 24.68);
        context.bezierCurveTo(57.78, 8.39, 43.82, 5.97, 39.28, 5.2);
        context.closePath();
        context.fillStyle = fillColor58;
        context.fill();


        //// Group 40
        context.save();

        //// Clip Clip 38

        context.beginPath();
        context.moveTo(29.96, 10.93);
        context.bezierCurveTo(25.89, 10.93, 22.38, 8.6, 20.65, 5.2);
        context.lineTo(20.65, 5.2);
        context.bezierCurveTo(16.12, 5.97, 2.15, 8.38, 1.59, 24.68);
        context.lineTo(1.59, 24.68);
        context.lineTo(29.96, 24.68);
        context.lineTo(58.34, 24.68);
        context.bezierCurveTo(57.77, 8.39, 43.82, 5.97, 39.28, 5.2);
        context.lineTo(39.28, 5.2);
        context.bezierCurveTo(37.55, 8.6, 34.03, 10.93, 29.96, 10.93);
        context.closePath();
        context.clip();



        //// Bezier 2363 Drawing
        context.beginPath();
        context.moveTo(27.25, 6.92);
        context.bezierCurveTo(25.38, 6.23, 23.79, 4.84, 22.86, 2.91);
        context.lineTo(17.25, 5.89);
        context.bezierCurveTo(19.27, 10.06, 22.97, 9.89, 27.25, 10.71);
        context.lineTo(27.25, 6.92);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2364 Drawing
        context.beginPath();
        context.moveTo(36.79, 2.91);
        context.bezierCurveTo(35.85, 4.84, 34.27, 6.24, 32.4, 6.92);
        context.lineTo(32.4, 10.71);
        context.bezierCurveTo(36.67, 9.89, 40.37, 10.06, 42.4, 5.89);
        context.lineTo(36.79, 2.91);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 2366 Drawing
        context.beginPath();
        context.moveTo(39.28, 5.2);
        context.bezierCurveTo(37.55, 8.6, 34.04, 10.93, 29.97, 10.93);
        context.bezierCurveTo(25.9, 10.93, 22.38, 8.6, 20.65, 5.2);
        context.bezierCurveTo(16.12, 5.97, 2.16, 8.38, 1.59, 24.68);
        context.lineTo(29.97, 24.68);
        context.lineTo(58.34, 24.68);
        context.bezierCurveTo(57.78, 8.39, 43.82, 5.97, 39.28, 5.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt17(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2234 Drawing
        context.beginPath();
        context.moveTo(29.16, 10.8);
        context.bezierCurveTo(33.19, 10.8, 36.68, 8.51, 38.42, 5.16);
        context.lineTo(38.42, 5.03);
        context.bezierCurveTo(35.71, 3.68, 36.13, 2.51, 36.73, 1.44);
        context.bezierCurveTo(34.32, 2.2, 31.55, 2.52, 28.92, 2.53);
        context.bezierCurveTo(26.3, 2.52, 24.01, 2.2, 21.62, 1.45);
        context.bezierCurveTo(22.22, 2.52, 22.63, 3.68, 19.92, 5.03);
        context.lineTo(19.93, 5.22);
        context.bezierCurveTo(21.68, 8.53, 25.15, 10.8, 29.16, 10.8);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2235 Drawing
        context.beginPath();
        context.moveTo(29.16, 10.8);
        context.bezierCurveTo(33.19, 10.8, 36.68, 8.51, 38.42, 5.16);
        context.lineTo(38.42, 5.03);
        context.bezierCurveTo(35.71, 3.68, 36.13, 2.51, 36.73, 1.44);
        context.bezierCurveTo(34.32, 2.2, 31.55, 2.52, 28.92, 2.53);
        context.bezierCurveTo(26.3, 2.52, 24.01, 2.2, 21.62, 1.45);
        context.bezierCurveTo(22.22, 2.52, 22.63, 3.68, 19.92, 5.03);
        context.lineTo(19.93, 5.22);
        context.bezierCurveTo(21.68, 8.53, 25.15, 10.8, 29.16, 10.8);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 41
        context.save();

        //// Clip Clip 39

        context.beginPath();
        context.moveTo(29.16, 10.93);
        context.bezierCurveTo(25.09, 10.93, 21.57, 8.6, 19.84, 5.2);
        context.lineTo(19.84, 5.2);
        context.bezierCurveTo(15.31, 5.97, 1.35, 8.38, 0.79, 24.68);
        context.lineTo(0.79, 24.68);
        context.lineTo(29.16, 24.68);
        context.lineTo(57.53, 24.68);
        context.bezierCurveTo(56.97, 8.39, 43.01, 5.97, 38.47, 5.2);
        context.lineTo(38.47, 5.2);
        context.bezierCurveTo(36.75, 8.6, 33.23, 10.93, 29.16, 10.93);
        context.closePath();
        context.clip();



        //// Bezier 2371 Drawing
        context.beginPath();
        context.moveTo(38.47, 5.2);
        context.bezierCurveTo(36.75, 8.6, 33.23, 10.93, 29.16, 10.93);
        context.bezierCurveTo(25.09, 10.93, 21.57, 8.6, 19.85, 5.2);
        context.bezierCurveTo(15.31, 5.97, 1.35, 8.38, 0.79, 24.68);
        context.lineTo(29.16, 24.68);
        context.lineTo(57.53, 24.68);
        context.bezierCurveTo(56.97, 8.39, 43.01, 5.97, 38.47, 5.2);
        context.closePath();
        context.fillStyle = 'rgb(255, 255, 255)';
        context.fill();


        //// Bezier 2367 Drawing
        context.beginPath();
        context.moveTo(29.18, 14.35);
        context.bezierCurveTo(23.85, 14.35, 19.03, 11.25, 16.61, 6.27);
        context.lineTo(22.22, 3.29);
        context.bezierCurveTo(23.56, 6.05, 26.23, 7.77, 29.18, 7.77);
        context.bezierCurveTo(32.13, 7.77, 34.8, 6.05, 36.15, 3.29);
        context.lineTo(41.76, 6.27);
        context.bezierCurveTo(39.33, 11.25, 34.52, 14.35, 29.18, 14.35);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2368 Drawing
        context.beginPath();
        context.moveTo(19.86, 8.57);
        context.bezierCurveTo(19.86, 8.57, 4.24, 11.56, 4.73, 24.78);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        //// Bezier 2369 Drawing
        context.beginPath();
        context.moveTo(37.72, 8.57);
        context.bezierCurveTo(37.72, 8.57, 53.35, 11.56, 52.85, 24.78);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        context.restore();



        //// Bezier Drawing
        context.beginPath();
        context.moveTo(38.47, 5.2);
        context.bezierCurveTo(36.75, 8.6, 33.23, 10.93, 29.16, 10.93);
        context.bezierCurveTo(25.09, 10.93, 21.57, 8.6, 19.85, 5.2);
        context.bezierCurveTo(15.31, 5.97, 1.35, 8.38, 0.79, 24.68);
        context.lineTo(29.16, 24.68);
        context.lineTo(57.53, 24.68);
        context.bezierCurveTo(56.97, 8.39, 43.01, 5.97, 38.47, 5.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt18(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2248 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.37);
        context.bezierCurveTo(33.87, 10.37, 37.36, 8.07, 39.1, 4.73);
        context.lineTo(39.1, 4.6);
        context.bezierCurveTo(36.39, 3.25, 36.81, 2.08, 37.41, 1.01);
        context.bezierCurveTo(35, 1.76, 32.23, 2.09, 29.6, 2.1);
        context.bezierCurveTo(26.98, 2.09, 24.69, 1.77, 22.3, 1.02);
        context.bezierCurveTo(22.89, 2.09, 23.31, 3.25, 20.6, 4.6);
        context.lineTo(20.61, 4.78);
        context.bezierCurveTo(22.36, 8.1, 25.83, 10.37, 29.84, 10.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2249 Drawing
        context.beginPath();
        context.moveTo(29.84, 10.37);
        context.bezierCurveTo(33.87, 10.37, 37.36, 8.07, 39.1, 4.73);
        context.lineTo(39.1, 4.6);
        context.bezierCurveTo(36.39, 3.25, 36.81, 2.08, 37.41, 1.01);
        context.bezierCurveTo(35, 1.76, 32.23, 2.09, 29.6, 2.1);
        context.bezierCurveTo(26.98, 2.09, 24.69, 1.77, 22.3, 1.02);
        context.bezierCurveTo(22.89, 2.09, 23.31, 3.25, 20.6, 4.6);
        context.lineTo(20.61, 4.78);
        context.bezierCurveTo(22.36, 8.1, 25.83, 10.37, 29.84, 10.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2372 Drawing
        context.beginPath();
        context.moveTo(39.16, 4.77);
        context.bezierCurveTo(37.43, 8.16, 33.91, 10.5, 29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.16, 20.53, 4.77);
        context.bezierCurveTo(15.99, 5.54, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.16, 4.77);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Group 42
        context.save();

        //// Clip Clip 40

        context.beginPath();
        context.moveTo(29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.17, 20.52, 4.77);
        context.lineTo(20.52, 4.77);
        context.bezierCurveTo(15.99, 5.54, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.15, 4.77);
        context.lineTo(39.15, 4.77);
        context.bezierCurveTo(37.43, 8.17, 33.91, 10.5, 29.84, 10.5);
        context.closePath();
        context.clip();



        //// Bezier 2373 Drawing
        context.beginPath();
        context.moveTo(20.52, 12.39);
        context.lineTo(14.34, 9.39);
        context.lineTo(14.34, 36.34);
        context.lineTo(20.52, 36.34);
        context.lineTo(20.52, 12.39);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 98 Drawing
        context.beginPath();
        context.rect(20.5, 9.41, 6.2, 26.95);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Rectangle 99 Drawing
        context.beginPath();
        context.rect(26.72, 9.41, 6.15, 26.95);
        context.fillStyle = fillColor5;
        context.fill();


        //// Rectangle 100 Drawing
        context.beginPath();
        context.rect(32.9, 9.41, 6.2, 26.95);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2374 Drawing
        context.beginPath();
        context.moveTo(45.27, 9.39);
        context.lineTo(39.1, 13.39);
        context.lineTo(39.1, 36.34);
        context.lineTo(45.27, 36.34);
        context.lineTo(45.27, 9.39);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2375 Drawing
        context.beginPath();
        context.moveTo(29.99, 13.96);
        context.bezierCurveTo(24.65, 13.96, 19.83, 10.86, 17.41, 5.89);
        context.lineTo(23.02, 2.9);
        context.bezierCurveTo(24.36, 5.67, 27.03, 7.38, 29.99, 7.38);
        context.bezierCurveTo(32.94, 7.38, 35.61, 5.67, 36.95, 2.9);
        context.lineTo(42.56, 5.89);
        context.bezierCurveTo(40.14, 10.86, 35.32, 13.96, 29.99, 13.96);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2376 Drawing
        context.beginPath();
        context.moveTo(10.15, 26.5);
        context.lineTo(10.15, 7.22);
        context.lineTo(-4.26, 20.85);
        context.lineTo(0.51, 27.94);
        context.lineTo(10.15, 26.5);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2377 Drawing
        context.beginPath();
        context.moveTo(48.57, 26.5);
        context.lineTo(48.57, 7.22);
        context.lineTo(62.98, 20.85);
        context.lineTo(58.21, 27.94);
        context.lineTo(48.57, 26.5);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        context.restore();



        //// Bezier 2379 Drawing
        context.beginPath();
        context.moveTo(39.16, 4.77);
        context.bezierCurveTo(37.43, 8.16, 33.91, 10.5, 29.84, 10.5);
        context.bezierCurveTo(25.77, 10.5, 22.25, 8.16, 20.53, 4.77);
        context.bezierCurveTo(15.99, 5.54, 2.03, 7.95, 1.47, 24.25);
        context.lineTo(29.84, 24.25);
        context.lineTo(58.21, 24.25);
        context.bezierCurveTo(57.65, 7.95, 43.69, 5.54, 39.16, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt19(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor55 = 'rgba(116, 35, 58, 1)';
        var fillColor60 = 'rgba(91, 29, 49, 1)';
        var strokeColor20 = 'rgba(112, 189, 234, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2250 Drawing
        context.beginPath();
        context.moveTo(29.4, 10.37);
        context.bezierCurveTo(33.43, 10.37, 36.91, 8.07, 38.66, 4.73);
        context.lineTo(38.66, 4.6);
        context.bezierCurveTo(35.94, 3.25, 36.36, 2.08, 36.96, 1.01);
        context.bezierCurveTo(34.56, 1.76, 31.78, 2.09, 29.16, 2.1);
        context.bezierCurveTo(26.54, 2.09, 24.25, 1.77, 21.85, 1.02);
        context.bezierCurveTo(22.45, 2.09, 22.86, 3.25, 20.16, 4.6);
        context.lineTo(20.17, 4.78);
        context.bezierCurveTo(21.92, 8.1, 25.39, 10.37, 29.4, 10.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2251 Drawing
        context.beginPath();
        context.moveTo(29.4, 10.37);
        context.bezierCurveTo(33.43, 10.37, 36.91, 8.07, 38.66, 4.73);
        context.lineTo(38.66, 4.6);
        context.bezierCurveTo(35.94, 3.25, 36.36, 2.08, 36.96, 1.01);
        context.bezierCurveTo(34.56, 1.76, 31.78, 2.09, 29.16, 2.1);
        context.bezierCurveTo(26.54, 2.09, 24.25, 1.77, 21.85, 1.02);
        context.bezierCurveTo(22.45, 2.09, 22.86, 3.25, 20.16, 4.6);
        context.lineTo(20.17, 4.78);
        context.bezierCurveTo(21.92, 8.1, 25.39, 10.37, 29.4, 10.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2420 Drawing
        context.beginPath();
        context.moveTo(38.71, 4.77);
        context.bezierCurveTo(36.98, 8.16, 33.47, 10.5, 29.4, 10.5);
        context.bezierCurveTo(25.33, 10.5, 21.81, 8.16, 20.08, 4.77);
        context.bezierCurveTo(15.55, 5.54, 1.59, 7.95, 1.02, 24.25);
        context.lineTo(29.4, 24.25);
        context.lineTo(57.77, 24.25);
        context.bezierCurveTo(57.21, 7.95, 43.25, 5.54, 38.71, 4.77);
        context.closePath();
        context.fillStyle = fillColor55;
        context.fill();


        //// Group 50
        context.save();

        //// Clip Clip 48

        context.beginPath();
        context.moveTo(29.39, 10.5);
        context.bezierCurveTo(25.33, 10.5, 21.81, 8.17, 20.08, 4.77);
        context.lineTo(20.08, 4.77);
        context.bezierCurveTo(15.55, 5.54, 1.59, 7.95, 1.02, 24.25);
        context.lineTo(1.02, 24.25);
        context.lineTo(29.39, 24.25);
        context.lineTo(57.77, 24.25);
        context.bezierCurveTo(57.21, 7.95, 43.25, 5.54, 38.71, 4.77);
        context.lineTo(38.71, 4.77);
        context.bezierCurveTo(36.98, 8.17, 33.47, 10.5, 29.39, 10.5);
        context.closePath();
        context.clip();



        //// Bezier 2421 Drawing
        context.beginPath();
        context.moveTo(58.89, 12.37);
        context.lineTo(0.83, 12.37);
        context.lineTo(0.83, 13.25);
        context.lineTo(58.89, 13.25);
        context.lineTo(58.89, 12.37);
        context.closePath();
        context.fillStyle = fillColor60;
        context.fill();


        //// Bezier 2422 Drawing
        context.beginPath();
        context.moveTo(58.89, 16.63);
        context.lineTo(0.83, 16.63);
        context.lineTo(0.83, 17.51);
        context.lineTo(58.89, 17.51);
        context.lineTo(58.89, 16.63);
        context.closePath();
        context.fillStyle = fillColor60;
        context.fill();


        //// Bezier 2423 Drawing
        context.beginPath();
        context.moveTo(58.89, 20.89);
        context.lineTo(0.83, 20.89);
        context.lineTo(0.83, 21.77);
        context.lineTo(58.89, 21.77);
        context.lineTo(58.89, 20.89);
        context.closePath();
        context.fillStyle = fillColor60;
        context.fill();


        //// Bezier 2424 Drawing
        context.beginPath();
        context.moveTo(58.89, 7.37);
        context.lineTo(0.83, 7.37);
        context.lineTo(0.83, 8.25);
        context.lineTo(58.89, 8.25);
        context.lineTo(58.89, 7.37);
        context.closePath();
        context.fillStyle = fillColor60;
        context.fill();


        //// Bezier 2425 Drawing
        context.beginPath();
        context.moveTo(15.7, 4.75);
        context.bezierCurveTo(16.49, 8.27, 19.93, 11.43, 23.92, 11.94);
        context.strokeStyle = strokeColor20;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2426 Drawing
        context.beginPath();
        context.moveTo(42.92, 4.75);
        context.bezierCurveTo(42.35, 7.91, 38.74, 11.51, 34.7, 11.94);
        context.strokeStyle = strokeColor20;
        context.lineWidth = 1.12;
        context.stroke();


        context.restore();



        //// Bezier 2428 Drawing
        context.beginPath();
        context.moveTo(38.71, 4.77);
        context.bezierCurveTo(36.98, 8.16, 33.47, 10.5, 29.4, 10.5);
        context.bezierCurveTo(25.33, 10.5, 21.81, 8.16, 20.08, 4.77);
        context.bezierCurveTo(15.55, 5.54, 1.59, 7.95, 1.02, 24.25);
        context.lineTo(29.4, 24.25);
        context.lineTo(57.77, 24.25);
        context.bezierCurveTo(57.21, 7.95, 43.25, 5.54, 38.71, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt20(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2242 Drawing
        context.beginPath();
        context.moveTo(29.66, 10.8);
        context.bezierCurveTo(33.69, 10.8, 37.17, 8.51, 38.92, 5.16);
        context.lineTo(38.92, 5.03);
        context.bezierCurveTo(36.2, 3.68, 36.63, 2.51, 37.22, 1.44);
        context.bezierCurveTo(34.82, 2.2, 32.04, 2.52, 29.42, 2.53);
        context.bezierCurveTo(26.8, 2.52, 24.51, 2.2, 22.11, 1.45);
        context.bezierCurveTo(22.71, 2.52, 23.12, 3.68, 20.42, 5.03);
        context.lineTo(20.43, 5.22);
        context.bezierCurveTo(22.18, 8.53, 25.65, 10.8, 29.66, 10.8);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2243 Drawing
        context.beginPath();
        context.moveTo(29.66, 10.8);
        context.bezierCurveTo(33.69, 10.8, 37.17, 8.51, 38.92, 5.16);
        context.lineTo(38.92, 5.03);
        context.bezierCurveTo(36.2, 3.68, 36.63, 2.51, 37.22, 1.44);
        context.bezierCurveTo(34.82, 2.2, 32.04, 2.52, 29.42, 2.53);
        context.bezierCurveTo(26.8, 2.52, 24.51, 2.2, 22.11, 1.45);
        context.bezierCurveTo(22.71, 2.52, 23.12, 3.68, 20.42, 5.03);
        context.lineTo(20.43, 5.22);
        context.bezierCurveTo(22.18, 8.53, 25.65, 10.8, 29.66, 10.8);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Rectangle 115 Drawing
        context.beginPath();
        context.rect(23.5, 8.36, 12.3, 5.65);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2440 Drawing
        context.beginPath();
        context.moveTo(38.97, 5.2);
        context.bezierCurveTo(37.24, 8.6, 33.73, 10.93, 29.66, 10.93);
        context.bezierCurveTo(25.59, 10.93, 22.07, 8.6, 20.34, 5.2);
        context.bezierCurveTo(15.81, 5.97, 1.85, 8.38, 1.28, 24.68);
        context.lineTo(29.66, 24.68);
        context.lineTo(58.03, 24.68);
        context.bezierCurveTo(57.47, 8.39, 43.51, 5.97, 38.97, 5.2);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Group 53
        context.save();

        //// Clip Clip 51

        context.beginPath();
        context.moveTo(29.66, 10.93);
        context.bezierCurveTo(25.59, 10.93, 22.07, 8.6, 20.34, 5.2);
        context.lineTo(20.34, 5.2);
        context.bezierCurveTo(15.81, 5.97, 1.85, 8.38, 1.28, 24.68);
        context.lineTo(1.28, 24.68);
        context.lineTo(29.66, 24.68);
        context.lineTo(58.03, 24.68);
        context.bezierCurveTo(57.47, 8.39, 43.51, 5.97, 38.97, 5.2);
        context.lineTo(38.97, 5.2);
        context.bezierCurveTo(37.24, 8.6, 33.73, 10.93, 29.66, 10.93);
        context.closePath();
        context.clip();



        //// Rectangle 116 Drawing
        context.beginPath();
        context.rect(16.37, 3.73, 5.25, 26);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Rectangle 117 Drawing
        context.beginPath();
        context.rect(21.62, 3.73, 5.25, 26);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 118 Drawing
        context.beginPath();
        context.rect(26.87, 3.73, 5.25, 26);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Rectangle 119 Drawing
        context.beginPath();
        context.rect(32.12, 3.73, 5.25, 26);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 120 Drawing
        context.beginPath();
        context.rect(37.37, 3.73, 5.25, 26);
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 2441 Drawing
        context.beginPath();
        context.moveTo(9.26, 27.94);
        context.lineTo(9.26, 8.65);
        context.lineTo(-5.15, 22.28);
        context.lineTo(-0.38, 29.38);
        context.lineTo(9.26, 27.94);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        //// Bezier 2442 Drawing
        context.beginPath();
        context.moveTo(50.08, 27.94);
        context.lineTo(50.08, 8.65);
        context.lineTo(64.49, 22.28);
        context.lineTo(59.72, 29.38);
        context.lineTo(50.08, 27.94);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair22;
        context.fill();


        context.restore();



        //// Bezier 2444 Drawing
        context.beginPath();
        context.moveTo(38.97, 5.2);
        context.bezierCurveTo(37.24, 8.6, 33.73, 10.93, 29.66, 10.93);
        context.bezierCurveTo(25.59, 10.93, 22.07, 8.6, 20.34, 5.2);
        context.bezierCurveTo(15.81, 5.97, 1.85, 8.38, 1.28, 24.68);
        context.lineTo(29.66, 24.68);
        context.lineTo(58.03, 24.68);
        context.bezierCurveTo(57.47, 8.39, 43.51, 5.97, 38.97, 5.2);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt21(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor5 = 'rgba(0, 0, 0, 1)';
        var fillColor6 = 'rgba(242, 171, 48, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2256 Drawing
        context.beginPath();
        context.moveTo(29.26, 10.37);
        context.bezierCurveTo(33.29, 10.37, 36.78, 8.07, 38.52, 4.73);
        context.lineTo(38.52, 4.6);
        context.bezierCurveTo(35.81, 3.25, 36.23, 2.08, 36.83, 1.01);
        context.bezierCurveTo(34.43, 1.76, 31.65, 2.09, 29.02, 2.1);
        context.bezierCurveTo(26.4, 2.09, 24.12, 1.77, 21.72, 1.02);
        context.bezierCurveTo(22.32, 2.09, 22.73, 3.25, 20.02, 4.6);
        context.lineTo(20.03, 4.78);
        context.bezierCurveTo(21.79, 8.1, 25.25, 10.37, 29.26, 10.37);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2257 Drawing
        context.beginPath();
        context.moveTo(29.26, 10.37);
        context.bezierCurveTo(33.29, 10.37, 36.78, 8.07, 38.52, 4.73);
        context.lineTo(38.52, 4.6);
        context.bezierCurveTo(35.81, 3.25, 36.23, 2.08, 36.83, 1.01);
        context.bezierCurveTo(34.43, 1.76, 31.65, 2.09, 29.02, 2.1);
        context.bezierCurveTo(26.4, 2.09, 24.12, 1.77, 21.72, 1.02);
        context.bezierCurveTo(22.32, 2.09, 22.73, 3.25, 20.02, 4.6);
        context.lineTo(20.03, 4.78);
        context.bezierCurveTo(21.79, 8.1, 25.25, 10.37, 29.26, 10.37);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2429 Drawing
        context.beginPath();
        context.moveTo(38.58, 4.77);
        context.bezierCurveTo(36.85, 8.16, 33.33, 10.5, 29.26, 10.5);
        context.bezierCurveTo(25.19, 10.5, 21.67, 8.16, 19.95, 4.77);
        context.bezierCurveTo(15.41, 5.54, 1.45, 7.95, 0.89, 24.25);
        context.lineTo(29.26, 24.25);
        context.lineTo(57.63, 24.25);
        context.bezierCurveTo(57.07, 7.95, 43.12, 5.54, 38.58, 4.77);
        context.closePath();
        context.fillStyle = fillColor6;
        context.fill();


        //// Group 51
        context.save();

        //// Clip Clip 49

        context.beginPath();
        context.moveTo(29.26, 10.49);
        context.bezierCurveTo(25.19, 10.49, 21.67, 8.16, 19.95, 4.77);
        context.lineTo(19.95, 4.77);
        context.bezierCurveTo(15.41, 5.53, 1.45, 7.95, 0.89, 24.25);
        context.lineTo(0.89, 24.25);
        context.lineTo(29.26, 24.25);
        context.lineTo(57.63, 24.25);
        context.bezierCurveTo(57.07, 7.95, 43.12, 5.53, 38.58, 4.77);
        context.lineTo(38.58, 4.77);
        context.bezierCurveTo(36.85, 8.16, 33.33, 10.49, 29.26, 10.49);
        context.closePath();
        context.clip();



        //// Bezier 2430 Drawing
        context.beginPath();
        context.moveTo(29.21, 14);
        context.bezierCurveTo(23.88, 14, 19.06, 10.91, 16.64, 5.93);
        context.lineTo(22.25, 2.95);
        context.bezierCurveTo(23.59, 5.71, 26.26, 7.43, 29.21, 7.43);
        context.bezierCurveTo(32.16, 7.43, 34.83, 5.71, 36.18, 2.95);
        context.lineTo(41.79, 5.93);
        context.bezierCurveTo(39.36, 10.91, 34.55, 14, 29.21, 14);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();


        //// Bezier 2431 Drawing
        context.beginPath();
        context.moveTo(29.21, 14);
        context.bezierCurveTo(23.88, 14, 19.06, 10.91, 16.64, 5.93);
        context.lineTo(22.25, 2.95);
        context.bezierCurveTo(23.59, 5.71, 26.26, 7.43, 29.21, 7.43);
        context.bezierCurveTo(32.16, 7.43, 34.83, 5.71, 36.18, 2.95);
        context.lineTo(41.79, 5.93);
        context.bezierCurveTo(39.36, 10.91, 34.55, 14, 29.21, 14);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2432 Drawing
        context.beginPath();
        context.moveTo(20.89, 8.22);
        context.bezierCurveTo(20.89, 8.22, 5.27, 11.22, 5.76, 24.43);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        //// Bezier 2433 Drawing
        context.beginPath();
        context.moveTo(37.75, 8.22);
        context.bezierCurveTo(37.75, 8.22, 53.38, 11.22, 52.88, 24.43);
        context.strokeStyle = strokeColor;
        context.lineWidth = 3;
        context.stroke();


        context.restore();



        //// Bezier 2435 Drawing
        context.beginPath();
        context.moveTo(38.58, 4.77);
        context.bezierCurveTo(36.85, 8.16, 33.33, 10.5, 29.26, 10.5);
        context.bezierCurveTo(25.19, 10.5, 21.67, 8.16, 19.95, 4.77);
        context.bezierCurveTo(15.41, 5.54, 1.45, 7.95, 0.89, 24.25);
        context.lineTo(29.26, 24.25);
        context.lineTo(57.63, 24.25);
        context.bezierCurveTo(57.07, 7.95, 43.12, 5.54, 38.58, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt22(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 2960 Drawing
        context.beginPath();
        context.moveTo(28.91, 10.93);
        context.bezierCurveTo(32.94, 10.93, 36.42, 8.63, 38.17, 5.29);
        context.lineTo(38.17, 5.16);
        context.bezierCurveTo(35.45, 3.8, 35.87, 2.1, 36.47, 1.03);
        context.bezierCurveTo(34.07, 1.78, 31.29, 2.11, 28.67, 2.11);
        context.bezierCurveTo(26.05, 2.11, 23.76, 1.78, 21.36, 1.03);
        context.bezierCurveTo(21.96, 2.11, 22.37, 3.81, 19.67, 5.16);
        context.lineTo(19.68, 5.34);
        context.bezierCurveTo(21.43, 8.66, 24.9, 10.93, 28.91, 10.93);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 2961 Drawing
        context.beginPath();
        context.moveTo(28.91, 10.93);
        context.bezierCurveTo(32.94, 10.93, 36.42, 8.63, 38.17, 5.29);
        context.lineTo(38.17, 5.16);
        context.bezierCurveTo(35.45, 3.8, 35.87, 2.1, 36.47, 1.03);
        context.bezierCurveTo(34.07, 1.78, 31.29, 2.11, 28.67, 2.11);
        context.bezierCurveTo(26.05, 2.11, 23.76, 1.78, 21.36, 1.03);
        context.bezierCurveTo(21.96, 2.11, 22.37, 3.81, 19.67, 5.16);
        context.lineTo(19.68, 5.34);
        context.bezierCurveTo(21.43, 8.66, 24.9, 10.93, 28.91, 10.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Group 2
        context.save();

        //// Clip Clip 69

        context.beginPath();
        context.moveTo(29, 11.03);
        context.bezierCurveTo(24.94, 11.03, 21.42, 8.7, 19.69, 5.3);
        context.lineTo(19.69, 5.3);
        context.bezierCurveTo(15.16, 6.07, 1.2, 8.48, 0.63, 24.78);
        context.lineTo(0.63, 24.78);
        context.lineTo(29, 24.78);
        context.lineTo(57.38, 24.78);
        context.bezierCurveTo(56.82, 8.49, 42.86, 6.07, 38.32, 5.3);
        context.lineTo(38.32, 5.3);
        context.bezierCurveTo(36.59, 8.7, 33.08, 11.03, 29, 11.03);
        context.closePath();
        context.clip();



        //// Rectangle 139 Drawing
        context.beginPath();
        context.rect(-3.63, 19.86, 65.85, 6.85);
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 140 Drawing
        context.beginPath();
        context.rect(-3.63, 12.81, 65.85, 6.85);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 141 Drawing
        context.beginPath();
        context.rect(-3.63, 5.86, 65.85, 6.85);
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Bezier 2971 Drawing
        context.beginPath();
        context.moveTo(38.32, 5.3);
        context.bezierCurveTo(36.59, 8.7, 33.08, 11.03, 29.01, 11.03);
        context.bezierCurveTo(24.94, 11.03, 21.42, 8.7, 19.69, 5.3);
        context.bezierCurveTo(15.16, 6.07, 1.2, 8.48, 0.63, 24.78);
        context.lineTo(29.01, 24.78);
        context.lineTo(57.38, 24.78);
        context.bezierCurveTo(56.82, 8.49, 42.86, 6.07, 38.32, 5.3);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt23(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 17 Drawing
        context.beginPath();
        context.moveTo(30.37, 11.18);
        context.bezierCurveTo(34.4, 11.18, 37.89, 8.89, 39.63, 5.55);
        context.lineTo(39.63, 5.42);
        context.bezierCurveTo(36.92, 4.06, 37.34, 2.9, 37.94, 1.83);
        context.bezierCurveTo(35.54, 2.58, 32.76, 2.91, 30.13, 2.91);
        context.bezierCurveTo(27.51, 2.91, 25.23, 2.58, 22.83, 1.84);
        context.bezierCurveTo(23.43, 2.91, 23.84, 4.07, 21.13, 5.42);
        context.lineTo(21.14, 5.6);
        context.bezierCurveTo(22.9, 8.92, 26.36, 11.18, 30.37, 11.18);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 18 Drawing
        context.beginPath();
        context.moveTo(30.37, 11.18);
        context.bezierCurveTo(34.4, 11.18, 37.89, 8.89, 39.63, 5.55);
        context.lineTo(39.63, 5.42);
        context.bezierCurveTo(36.92, 4.06, 37.34, 2.9, 37.94, 1.83);
        context.bezierCurveTo(35.54, 2.58, 32.76, 2.91, 30.13, 2.91);
        context.bezierCurveTo(27.51, 2.91, 25.23, 2.58, 22.83, 1.84);
        context.bezierCurveTo(23.43, 2.91, 23.84, 4.07, 21.13, 5.42);
        context.lineTo(21.14, 5.6);
        context.bezierCurveTo(22.9, 8.92, 26.36, 11.18, 30.37, 11.18);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 19 Drawing
        context.beginPath();
        context.moveTo(39.35, 5.27);
        context.bezierCurveTo(37.62, 8.66, 34.1, 11, 30.03, 11);
        context.bezierCurveTo(25.96, 11, 22.45, 8.66, 20.72, 5.27);
        context.bezierCurveTo(16.18, 6.04, 2.22, 8.45, 1.66, 24.75);
        context.lineTo(30.03, 24.75);
        context.lineTo(58.41, 24.75);
        context.bezierCurveTo(57.84, 8.45, 43.89, 6.04, 39.35, 5.27);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Group 28
        context.save();

        //// Clip Clip

        context.beginPath();
        context.moveTo(30.03, 10.99);
        context.bezierCurveTo(25.97, 10.99, 22.45, 8.66, 20.72, 5.27);
        context.lineTo(20.72, 5.27);
        context.bezierCurveTo(16.18, 6.03, 2.23, 8.45, 1.66, 24.75);
        context.lineTo(1.66, 24.75);
        context.lineTo(30.03, 24.75);
        context.lineTo(58.41, 24.75);
        context.bezierCurveTo(57.85, 8.45, 43.89, 6.03, 39.35, 5.27);
        context.lineTo(39.35, 5.27);
        context.bezierCurveTo(37.62, 8.66, 34.1, 10.99, 30.03, 10.99);
        context.closePath();
        context.clip();



        //// Rectangle Drawing
        context.beginPath();
        context.rect(9.23, 5.18, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 2 Drawing
        context.beginPath();
        context.rect(15.18, 18.18, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 3 Drawing
        context.beginPath();
        context.rect(21.13, 5.18, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 4 Drawing
        context.beginPath();
        context.rect(27.08, 18.18, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 5 Drawing
        context.beginPath();
        context.rect(32.98, 5.18, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 6 Drawing
        context.beginPath();
        context.rect(38.98, 18.18, 5.95, 21.4);
        context.fillStyle = fillColor28;
        context.fill();


        //// Rectangle 7 Drawing
        context.beginPath();
        context.rect(44.88, 5.18, 5.95, 21.4);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 20 Drawing
        context.beginPath();
        context.moveTo(30.02, 13.24);
        context.bezierCurveTo(24.69, 13.24, 19.87, 10.15, 17.45, 5.17);
        context.lineTo(23.06, 2.19);
        context.bezierCurveTo(24.4, 4.95, 27.07, 6.67, 30.02, 6.67);
        context.bezierCurveTo(32.98, 6.67, 35.64, 4.95, 36.99, 2.19);
        context.lineTo(42.6, 5.17);
        context.bezierCurveTo(40.18, 10.15, 35.36, 13.24, 30.02, 13.24);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        context.restore();



        //// Bezier 22 Drawing
        context.beginPath();
        context.moveTo(39.35, 5.27);
        context.bezierCurveTo(37.62, 8.66, 34.1, 11, 30.03, 11);
        context.bezierCurveTo(25.96, 11, 22.45, 8.66, 20.72, 5.27);
        context.bezierCurveTo(16.18, 6.04, 2.22, 8.45, 1.66, 24.75);
        context.lineTo(30.03, 24.75);
        context.lineTo(58.41, 24.75);
        context.bezierCurveTo(57.84, 8.45, 43.89, 6.04, 39.35, 5.27);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFootballShirt24(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 26), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 26);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var strokeColor13 = 'rgba(248, 218, 37, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));

        //// Bezier 41 Drawing
        context.beginPath();
        context.moveTo(29.34, 10.5);
        context.bezierCurveTo(33.37, 10.5, 36.86, 8.2, 38.6, 4.86);
        context.lineTo(38.6, 4.73);
        context.bezierCurveTo(35.89, 3.37, 36.31, 2.21, 36.91, 1.14);
        context.bezierCurveTo(34.5, 1.89, 31.73, 2.22, 29.1, 2.23);
        context.bezierCurveTo(26.48, 2.22, 24.19, 1.9, 21.8, 1.15);
        context.bezierCurveTo(22.39, 2.22, 22.81, 3.38, 20.1, 4.73);
        context.lineTo(20.11, 4.91);
        context.bezierCurveTo(21.86, 8.23, 25.33, 10.5, 29.34, 10.5);
        context.closePath();
        context.fillStyle = _skinColor;
        context.fill();


        //// Bezier 42 Drawing
        context.beginPath();
        context.moveTo(29.34, 10.5);
        context.bezierCurveTo(33.37, 10.5, 36.86, 8.2, 38.6, 4.86);
        context.lineTo(38.6, 4.73);
        context.bezierCurveTo(35.89, 3.37, 36.31, 2.21, 36.91, 1.14);
        context.bezierCurveTo(34.5, 1.89, 31.73, 2.22, 29.1, 2.23);
        context.bezierCurveTo(26.48, 2.22, 24.19, 1.9, 21.8, 1.15);
        context.bezierCurveTo(22.39, 2.22, 22.81, 3.38, 20.1, 4.73);
        context.lineTo(20.11, 4.91);
        context.bezierCurveTo(21.86, 8.23, 25.33, 10.5, 29.34, 10.5);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 43 Drawing
        context.beginPath();
        context.moveTo(38.66, 4.77);
        context.bezierCurveTo(36.93, 8.16, 33.41, 10.5, 29.34, 10.5);
        context.bezierCurveTo(25.27, 10.5, 21.75, 8.16, 20.03, 4.77);
        context.bezierCurveTo(15.49, 5.53, 1.53, 7.95, 0.97, 24.25);
        context.lineTo(29.34, 24.25);
        context.lineTo(57.71, 24.25);
        context.bezierCurveTo(57.15, 7.95, 43.19, 5.54, 38.66, 4.77);
        context.closePath();
        context.fillStyle = strokeColor13;
        context.fill();


        //// Group 30
        context.save();

        //// Clip Clip 2

        context.beginPath();
        context.moveTo(29.34, 10.5);
        context.bezierCurveTo(25.27, 10.5, 21.75, 8.17, 20.02, 4.77);
        context.lineTo(20.02, 4.77);
        context.bezierCurveTo(15.49, 5.54, 1.53, 7.95, 0.97, 24.25);
        context.lineTo(0.97, 24.25);
        context.lineTo(29.34, 24.25);
        context.lineTo(57.71, 24.25);
        context.bezierCurveTo(57.15, 7.95, 43.19, 5.54, 38.65, 4.77);
        context.lineTo(38.65, 4.77);
        context.bezierCurveTo(36.93, 8.17, 33.41, 10.5, 29.34, 10.5);
        context.closePath();
        context.clip();



        //// Bezier 44 Drawing
        context.beginPath();
        context.moveTo(9.15, 18.85);
        context.bezierCurveTo(9.15, 18.85, 8.54, 11.19, 23.84, 7.45);
        context.bezierCurveTo(29.9, 5.97, -2.1, 1, -2.1, 1);
        context.lineTo(1.51, 19.15);
        context.lineTo(9.15, 18.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        //// Bezier 45 Drawing
        context.beginPath();
        context.moveTo(49.35, 18.85);
        context.bezierCurveTo(49.35, 18.85, 49.96, 11.19, 34.66, 7.45);
        context.bezierCurveTo(28.6, 5.97, 60.6, 1, 60.6, 1);
        context.lineTo(56.99, 19.15);
        context.lineTo(49.35, 18.85);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col6;
        context.fill();


        context.restore();



        //// Bezier 46 Drawing
        context.beginPath();
        context.moveTo(38.66, 4.77);
        context.bezierCurveTo(36.93, 8.16, 33.41, 10.5, 29.34, 10.5);
        context.bezierCurveTo(25.27, 10.5, 21.75, 8.16, 20.03, 4.77);
        context.bezierCurveTo(15.49, 5.53, 1.53, 7.95, 0.97, 24.25);
        context.lineTo(29.34, 24.25);
        context.lineTo(57.71, 24.25);
        context.bezierCurveTo(57.15, 7.95, 43.19, 5.54, 38.66, 4.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawRedFlower(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 31, 30), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 31, resizedFrame.h / 30);


        //// Color Declarations
        var fillColor5 = 'rgba(0, 0, 0, 1)';

        //// Bezier 2454 Drawing
        context.beginPath();
        context.moveTo(23.85, 6.36);
        context.bezierCurveTo(26.27, 6.32, 28.09, 7.18, 29, 9.41);
        context.bezierCurveTo(29.9, 11.6, 29.18, 13.45, 27.53, 15.09);
        context.bezierCurveTo(29.25, 16.81, 29.94, 18.76, 28.9, 21.05);
        context.bezierCurveTo(28.08, 22.86, 26.72, 23.62, 23.9, 23.87);
        context.bezierCurveTo(23.91, 26.13, 23.13, 27.99, 20.91, 28.93);
        context.bezierCurveTo(18.68, 29.88, 16.8, 29.15, 15.14, 27.48);
        context.bezierCurveTo(13.49, 29.16, 11.6, 29.88, 9.38, 28.93);
        context.bezierCurveTo(7.2, 28, 6.37, 26.21, 6.41, 23.85);
        context.bezierCurveTo(4.08, 23.88, 2.23, 23.07, 1.3, 20.84);
        context.bezierCurveTo(0.39, 18.65, 1.05, 16.79, 2.76, 15.13);
        context.bezierCurveTo(1.16, 13.51, 0.35, 11.64, 1.28, 9.41);
        context.bezierCurveTo(2.2, 7.19, 4, 6.3, 6.4, 6.38);
        context.bezierCurveTo(6.36, 3.9, 7.29, 2.03, 9.63, 1.17);
        context.bezierCurveTo(11.75, 0.38, 13.55, 1.09, 15.12, 2.71);
        context.bezierCurveTo(16.76, 1.1, 18.63, 0.31, 20.86, 1.25);
        context.bezierCurveTo(23.03, 2.17, 23.93, 3.92, 23.85, 6.36);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair15;
        context.fill();


        //// Oval 545 Drawing
        oval(context, 11.1, 11.04, 8.1, 8.1);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Bezier 2455 Drawing
        context.beginPath();
        context.moveTo(18.46, 15.13);
        context.bezierCurveTo(18.48, 13.33, 16.98, 11.8, 15.17, 11.78);
        context.bezierCurveTo(13.37, 11.76, 11.84, 13.26, 11.82, 15.07);
        context.bezierCurveTo(11.8, 16.87, 13.3, 18.4, 15.11, 18.42);
        context.bezierCurveTo(16.91, 18.44, 18.44, 16.94, 18.46, 15.13);
        context.closePath();
        context.moveTo(14.48, 7.31);
        context.bezierCurveTo(14.48, 6.68, 14.39, 6.04, 14.5, 5.43);
        context.bezierCurveTo(14.91, 3.09, 12.23, 1.87, 10.61, 2.22);
        context.bezierCurveTo(9.24, 2.51, 8.27, 3.33, 7.87, 4.68);
        context.bezierCurveTo(7.5, 5.9, 7.73, 6.96, 8.78, 7.86);
        context.bezierCurveTo(9.88, 8.79, 10.86, 9.86, 11.85, 10.9);
        context.bezierCurveTo(12.27, 11.34, 12.57, 11.32, 13.17, 11.16);
        context.bezierCurveTo(14.42, 10.81, 14.63, 10.07, 14.5, 8.97);
        context.bezierCurveTo(14.43, 8.43, 14.48, 7.86, 14.48, 7.31);
        context.closePath();
        context.moveTo(19.62, 15.76);
        context.bezierCurveTo(19.5, 16.13, 19.43, 16.29, 19.29, 16.74);
        context.bezierCurveTo(18.97, 17.75, 19.02, 18.14, 19.69, 18.76);
        context.bezierCurveTo(20.78, 19.75, 21.77, 20.86, 22.86, 21.86);
        context.bezierCurveTo(23.18, 22.16, 23.66, 22.39, 24.1, 22.45);
        context.bezierCurveTo(25.88, 22.71, 27.49, 21.6, 27.97, 19.87);
        context.bezierCurveTo(28.45, 18.14, 27.25, 15.84, 25.74, 15.78);
        context.bezierCurveTo(23.71, 15.7, 21.68, 15.76, 19.62, 15.76);
        context.closePath();
        context.moveTo(10.42, 15.76);
        context.bezierCurveTo(8.86, 15.76, 7.13, 15.76, 5.39, 15.76);
        context.bezierCurveTo(3.73, 15.76, 2.7, 16.55, 2.3, 18.16);
        context.bezierCurveTo(1.93, 19.66, 2.61, 21.25, 3.98, 22.05);
        context.bezierCurveTo(5.39, 22.87, 6.66, 22.66, 7.93, 21.39);
        context.bezierCurveTo(8.61, 20.71, 9.24, 19.96, 9.98, 19.36);
        context.bezierCurveTo(11.21, 18.37, 11.88, 17.34, 10.42, 15.76);
        context.closePath();
        context.moveTo(22.84, 14.44);
        context.bezierCurveTo(23.73, 14.44, 24.62, 14.49, 25.5, 14.42);
        context.bezierCurveTo(25.91, 14.39, 26.38, 14.25, 26.71, 14);
        context.bezierCurveTo(27.82, 13.13, 28.26, 11.93, 28.01, 10.56);
        context.bezierCurveTo(27.76, 9.22, 26.97, 8.18, 25.64, 7.9);
        context.bezierCurveTo(24.82, 7.72, 23.67, 7.75, 23.07, 8.21);
        context.bezierCurveTo(21.64, 9.3, 20.45, 10.7, 19.18, 12);
        context.bezierCurveTo(19.02, 12.17, 18.89, 12.52, 18.95, 12.74);
        context.bezierCurveTo(19.43, 14.45, 19.44, 14.44, 21.28, 14.44);
        context.lineTo(22.84, 14.44);
        context.closePath();
        context.moveTo(7.45, 14.44);
        context.bezierCurveTo(8.3, 14.44, 9.15, 14.4, 10, 14.45);
        context.bezierCurveTo(11.23, 14.53, 11.17, 13.5, 11.28, 12.81);
        context.bezierCurveTo(11.3, 12.67, 11.21, 12.13, 11.01, 11.92);
        context.bezierCurveTo(9.92, 10.77, 8.74, 9.7, 7.67, 8.52);
        context.bezierCurveTo(6.99, 7.77, 6.21, 7.59, 5.28, 7.71);
        context.bezierCurveTo(3.44, 7.94, 2.12, 9.55, 2.23, 11.44);
        context.bezierCurveTo(2.33, 13.35, 3.55, 14.44, 5.56, 14.44);
        context.lineTo(7.45, 14.44);
        context.closePath();
        context.moveTo(15.8, 10.61);
        context.bezierCurveTo(16.2, 10.75, 16.52, 10.9, 16.88, 11.01);
        context.bezierCurveTo(18.01, 11.26, 18.08, 11.32, 18.64, 10.7);
        context.bezierCurveTo(19.69, 9.56, 20.84, 8.51, 21.91, 7.38);
        context.bezierCurveTo(22.18, 7.11, 22.42, 6.73, 22.49, 6.36);
        context.bezierCurveTo(22.87, 4.49, 21.67, 2.68, 19.82, 2.24);
        context.bezierCurveTo(18.09, 1.84, 15.88, 3.03, 15.82, 4.5);
        context.bezierCurveTo(15.74, 6.52, 15.8, 8.56, 15.8, 10.61);
        context.closePath();
        context.moveTo(14.48, 22.99);
        context.bezierCurveTo(14.48, 22.1, 14.43, 21.21, 14.5, 20.33);
        context.bezierCurveTo(14.61, 18.99, 13.48, 19.07, 12.92, 19.01);
        context.bezierCurveTo(12.71, 18.99, 12.17, 19.04, 11.94, 19.25);
        context.bezierCurveTo(10.81, 20.31, 9.78, 21.48, 8.62, 22.51);
        context.bezierCurveTo(7.75, 23.28, 7.6, 24.17, 7.79, 25.21);
        context.bezierCurveTo(8.11, 26.96, 9.78, 28.18, 11.62, 28.01);
        context.bezierCurveTo(13.43, 27.84, 14.48, 26.64, 14.48, 24.76);
        context.bezierCurveTo(14.48, 24.17, 14.48, 23.58, 14.48, 22.99);
        context.closePath();
        context.moveTo(15.8, 22.8);
        context.bezierCurveTo(15.8, 23.65, 15.74, 24.51, 15.82, 25.35);
        context.bezierCurveTo(15.87, 25.84, 16.02, 26.38, 16.32, 26.75);
        context.bezierCurveTo(17.17, 27.81, 18.36, 28.22, 19.69, 27.97);
        context.bezierCurveTo(21.02, 27.72, 22.07, 26.92, 22.35, 25.59);
        context.bezierCurveTo(22.52, 24.77, 22.49, 23.62, 22.03, 23.02);
        context.bezierCurveTo(20.94, 21.59, 19.54, 20.4, 18.24, 19.14);
        context.bezierCurveTo(18.07, 18.97, 17.71, 18.85, 17.5, 18.91);
        context.bezierCurveTo(15.79, 19.4, 15.8, 19.41, 15.8, 21.25);
        context.lineTo(15.8, 22.8);
        context.closePath();
        context.moveTo(23.85, 6.36);
        context.bezierCurveTo(26.27, 6.32, 28.09, 7.18, 29, 9.41);
        context.bezierCurveTo(29.9, 11.6, 29.18, 13.45, 27.53, 15.09);
        context.bezierCurveTo(29.25, 16.81, 29.94, 18.76, 28.9, 21.05);
        context.bezierCurveTo(28.07, 22.86, 26.72, 23.62, 23.9, 23.87);
        context.bezierCurveTo(23.91, 26.13, 23.13, 27.99, 20.91, 28.93);
        context.bezierCurveTo(18.68, 29.88, 16.8, 29.15, 15.14, 27.48);
        context.bezierCurveTo(13.48, 29.16, 11.6, 29.88, 9.37, 28.93);
        context.bezierCurveTo(7.2, 28, 6.37, 26.21, 6.41, 23.85);
        context.bezierCurveTo(4.08, 23.88, 2.23, 23.07, 1.3, 20.84);
        context.bezierCurveTo(0.39, 18.65, 1.05, 16.79, 2.76, 15.13);
        context.bezierCurveTo(1.16, 13.51, 0.35, 11.64, 1.28, 9.41);
        context.bezierCurveTo(2.2, 7.19, 4, 6.3, 6.4, 6.38);
        context.bezierCurveTo(6.36, 3.9, 7.28, 2.03, 9.63, 1.17);
        context.bezierCurveTo(11.75, 0.38, 13.55, 1.09, 15.12, 2.71);
        context.bezierCurveTo(16.76, 1.1, 18.63, 0.31, 20.86, 1.25);
        context.bezierCurveTo(23.03, 2.17, 23.93, 3.92, 23.85, 6.36);
        context.closePath();
        context.fillStyle = fillColor5;
        context.fill();
        
        context.restore();

    }

    function drawLaurel(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 43), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 43);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Bezier 2587 Drawing
        context.beginPath();
        context.moveTo(21.94, 13.42);
        context.bezierCurveTo(21.94, 13.42, 13.17, 23.29, 5.27, 37.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2588 Drawing
        context.beginPath();
        context.moveTo(49.27, 13.42);
        context.bezierCurveTo(49.27, 13.42, 58.04, 23.29, 65.94, 37.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2589 Drawing
        context.beginPath();
        context.moveTo(15.18, 13.84);
        context.bezierCurveTo(15.18, 13.84, 7.7, 25.15, 13.72, 25.88);
        context.bezierCurveTo(19.74, 26.61, 15.18, 13.84, 15.18, 13.84);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2590 Drawing
        context.beginPath();
        context.moveTo(15.18, 13.84);
        context.bezierCurveTo(15.18, 13.84, 7.7, 25.15, 13.72, 25.88);
        context.bezierCurveTo(19.74, 26.61, 15.18, 13.84, 15.18, 13.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2591 Drawing
        context.beginPath();
        context.moveTo(22.68, 18.21);
        context.bezierCurveTo(22.68, 18.21, 16.54, 30.3, 12.94, 25.42);
        context.bezierCurveTo(9.33, 20.55, 22.68, 18.21, 22.68, 18.21);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2592 Drawing
        context.beginPath();
        context.moveTo(22.68, 18.21);
        context.bezierCurveTo(22.68, 18.21, 16.54, 30.3, 12.94, 25.42);
        context.bezierCurveTo(9.33, 20.55, 22.68, 18.21, 22.68, 18.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2593 Drawing
        context.beginPath();
        context.moveTo(7.18, 25.84);
        context.bezierCurveTo(7.18, 25.84, -0.3, 37.15, 5.72, 37.88);
        context.bezierCurveTo(11.74, 38.61, 7.18, 25.84, 7.18, 25.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2594 Drawing
        context.beginPath();
        context.moveTo(7.18, 25.84);
        context.bezierCurveTo(7.18, 25.84, -0.3, 37.15, 5.72, 37.88);
        context.bezierCurveTo(11.74, 38.61, 7.18, 25.84, 7.18, 25.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2595 Drawing
        context.beginPath();
        context.moveTo(14.68, 30.21);
        context.bezierCurveTo(14.68, 30.21, 8.54, 42.3, 4.94, 37.42);
        context.bezierCurveTo(1.33, 32.55, 14.68, 30.21, 14.68, 30.21);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2596 Drawing
        context.beginPath();
        context.moveTo(14.68, 30.21);
        context.bezierCurveTo(14.68, 30.21, 8.54, 42.3, 4.94, 37.42);
        context.bezierCurveTo(1.33, 32.55, 14.68, 30.21, 14.68, 30.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2597 Drawing
        context.beginPath();
        context.moveTo(24.18, 1.84);
        context.bezierCurveTo(24.18, 1.84, 16.7, 13.15, 22.72, 13.88);
        context.bezierCurveTo(28.74, 14.61, 24.18, 1.84, 24.18, 1.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2598 Drawing
        context.beginPath();
        context.moveTo(24.18, 1.84);
        context.bezierCurveTo(24.18, 1.84, 16.7, 13.15, 22.72, 13.88);
        context.bezierCurveTo(28.74, 14.61, 24.18, 1.84, 24.18, 1.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2599 Drawing
        context.beginPath();
        context.moveTo(31.68, 6.21);
        context.bezierCurveTo(31.68, 6.21, 25.54, 18.3, 21.94, 13.42);
        context.bezierCurveTo(18.33, 8.55, 31.68, 6.21, 31.68, 6.21);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2600 Drawing
        context.beginPath();
        context.moveTo(31.68, 6.21);
        context.bezierCurveTo(31.68, 6.21, 25.54, 18.3, 21.94, 13.42);
        context.bezierCurveTo(18.33, 8.55, 31.68, 6.21, 31.68, 6.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2601 Drawing
        context.beginPath();
        context.moveTo(55.88, 13.84);
        context.bezierCurveTo(55.88, 13.84, 63.36, 25.15, 57.34, 25.88);
        context.bezierCurveTo(51.32, 26.61, 55.88, 13.84, 55.88, 13.84);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2602 Drawing
        context.beginPath();
        context.moveTo(55.88, 13.84);
        context.bezierCurveTo(55.88, 13.84, 63.36, 25.15, 57.34, 25.88);
        context.bezierCurveTo(51.32, 26.61, 55.88, 13.84, 55.88, 13.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2603 Drawing
        context.beginPath();
        context.moveTo(48.38, 18.21);
        context.bezierCurveTo(48.38, 18.21, 54.51, 30.3, 58.12, 25.42);
        context.bezierCurveTo(61.73, 20.55, 48.38, 18.21, 48.38, 18.21);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2604 Drawing
        context.beginPath();
        context.moveTo(48.38, 18.21);
        context.bezierCurveTo(48.38, 18.21, 54.51, 30.3, 58.12, 25.42);
        context.bezierCurveTo(61.73, 20.55, 48.38, 18.21, 48.38, 18.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2605 Drawing
        context.beginPath();
        context.moveTo(63.88, 25.84);
        context.bezierCurveTo(63.88, 25.84, 71.36, 37.15, 65.34, 37.88);
        context.bezierCurveTo(59.32, 38.61, 63.88, 25.84, 63.88, 25.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2606 Drawing
        context.beginPath();
        context.moveTo(63.88, 25.84);
        context.bezierCurveTo(63.88, 25.84, 71.36, 37.15, 65.34, 37.88);
        context.bezierCurveTo(59.32, 38.61, 63.88, 25.84, 63.88, 25.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2607 Drawing
        context.beginPath();
        context.moveTo(56.38, 30.21);
        context.bezierCurveTo(56.38, 30.21, 62.51, 42.3, 66.12, 37.42);
        context.bezierCurveTo(69.73, 32.55, 56.38, 30.21, 56.38, 30.21);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2608 Drawing
        context.beginPath();
        context.moveTo(56.38, 30.21);
        context.bezierCurveTo(56.38, 30.21, 62.51, 42.3, 66.12, 37.42);
        context.bezierCurveTo(69.73, 32.55, 56.38, 30.21, 56.38, 30.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2609 Drawing
        context.beginPath();
        context.moveTo(46.88, 1.84);
        context.bezierCurveTo(46.88, 1.84, 54.36, 13.15, 48.34, 13.88);
        context.bezierCurveTo(42.32, 14.61, 46.88, 1.84, 46.88, 1.84);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();


        //// Bezier 2610 Drawing
        context.beginPath();
        context.moveTo(46.88, 1.84);
        context.bezierCurveTo(46.88, 1.84, 54.36, 13.15, 48.34, 13.88);
        context.bezierCurveTo(42.32, 14.61, 46.88, 1.84, 46.88, 1.84);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2611 Drawing
        context.beginPath();
        context.moveTo(39.38, 6.21);
        context.bezierCurveTo(39.38, 6.21, 45.51, 18.3, 49.12, 13.42);
        context.bezierCurveTo(52.73, 8.55, 39.38, 6.21, 39.38, 6.21);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2612 Drawing
        context.beginPath();
        context.moveTo(39.38, 6.21);
        context.bezierCurveTo(39.38, 6.21, 45.51, 18.3, 49.12, 13.42);
        context.bezierCurveTo(52.73, 8.55, 39.38, 6.21, 39.38, 6.21);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFaceLightning(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 31, 49), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 31, resizedFrame.h / 49);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2463 Drawing
        context.beginPath();
        context.moveTo(29, 3);
        context.lineTo(17.04, 25.46);
        context.lineTo(20.59, 25.46);
        context.lineTo(5.96, 44.53);
        context.lineTo(5.96, 30.48);
        context.lineTo(1.3, 30.48);
        context.lineTo(8, 3.5);
        context.bezierCurveTo(8, 3.5, 13.75, 1, 19, 1);
        context.bezierCurveTo(24.25, 1, 29, 3, 29, 3);
        context.lineTo(29, 3);
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 0.85;
        context.stroke();
        
        context.restore();

    }

    function drawWizardHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 68), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 68);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';

        //// Oval 548 Drawing
        oval(context, 5.2, 40.68, 77.5, 23.4);
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();


        //// Oval 549 Drawing
        oval(context, 5.2, 40.68, 77.5, 23.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2476 Drawing
        context.beginPath();
        context.moveTo(20.87, 52.61);
        context.bezierCurveTo(20.64, 52.55, 32.86, 24.62, 39.69, 9.07);
        context.bezierCurveTo(42, 3.8, 48.54, 1.93, 53.3, 5.19);
        context.lineTo(59.07, 9.14);
        context.bezierCurveTo(59.56, 9.47, 59.42, 10.22, 58.85, 10.37);
        context.lineTo(51.54, 12.19);
        context.bezierCurveTo(50.74, 12.39, 50.31, 13.26, 50.64, 14.02);
        context.lineTo(67.05, 52.24);
        context.bezierCurveTo(67.05, 52.24, 45.45, 58.66, 20.87, 52.61);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair11;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Group
        context.save();

        //// Clip Bezier 485

        context.beginPath();
        context.moveTo(21.87, 52.43);
        context.bezierCurveTo(21.65, 52.38, 33.34, 24.79, 39.87, 9.43);
        context.bezierCurveTo(42.09, 4.22, 48.34, 2.38, 52.89, 5.59);
        context.lineTo(58.41, 9.5);
        context.bezierCurveTo(58.88, 9.82, 58.75, 10.57, 58.2, 10.71);
        context.lineTo(51.21, 12.51);
        context.bezierCurveTo(50.45, 12.71, 50.03, 13.57, 50.35, 14.32);
        context.lineTo(66.05, 52.07);
        context.bezierCurveTo(66.05, 52.07, 45.39, 58.41, 21.87, 52.43);
        context.closePath();
        context.clip();



        //// Bezier 354 Drawing
        context.beginPath();
        context.moveTo(47.5, 19.41);
        context.lineTo(39.16, 25);
        context.lineTo(41.32, 15.02);
        context.lineTo(34, 8.93);
        context.lineTo(43.33, 8.13);
        context.lineTo(47.5, -1);
        context.lineTo(51.68, 8.13);
        context.lineTo(61, 8.93);
        context.lineTo(53.68, 15.6);
        context.lineTo(55.85, 25);
        context.lineTo(47.5, 19.41);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 408 Drawing
        context.beginPath();
        context.moveTo(27.5, 56.41);
        context.lineTo(19.16, 62);
        context.lineTo(21.32, 52.02);
        context.lineTo(14, 45.93);
        context.lineTo(23.33, 45.13);
        context.lineTo(27.5, 36);
        context.lineTo(31.68, 45.13);
        context.lineTo(41, 45.93);
        context.lineTo(33.68, 52.6);
        context.lineTo(35.85, 62);
        context.lineTo(27.5, 56.41);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 441 Drawing
        context.beginPath();
        context.moveTo(60.5, 55.41);
        context.lineTo(52.16, 61);
        context.lineTo(54.32, 51.02);
        context.lineTo(47, 44.93);
        context.lineTo(56.33, 44.13);
        context.lineTo(60.5, 35);
        context.lineTo(64.68, 44.13);
        context.lineTo(74, 44.93);
        context.lineTo(66.68, 51.6);
        context.lineTo(68.85, 61);
        context.lineTo(60.5, 55.41);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        context.restore();
        
        context.restore();

    }

    function drawGreenHairBow(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 56, 31), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 56, resizedFrame.h / 31);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Rectangle 127 Drawing
        context.beginPath();
        context.rect(24.67, 11.41, 6.75, 9.55);
        context.fillStyle = fillColor12;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2490 Drawing
        context.beginPath();
        context.moveTo(50.35, 1.77);
        context.bezierCurveTo(50.35, 1.77, 48.19, -2.72, 30.9, 11.97);
        context.lineTo(30.9, 14.96);
        context.bezierCurveTo(30.9, 14.96, 51.13, 4.76, 50.35, 1.77);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2491 Drawing
        context.beginPath();
        context.moveTo(50.35, 1.77);
        context.bezierCurveTo(50.35, 1.77, 48.19, -2.72, 30.9, 11.97);
        context.lineTo(30.9, 14.96);
        context.bezierCurveTo(30.9, 14.96, 51.13, 4.76, 50.35, 1.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2492 Drawing
        context.beginPath();
        context.moveTo(30.9, 14.96);
        context.lineTo(30.95, 20.45);
        context.bezierCurveTo(30.95, 20.45, 35.22, 22.78, 42.42, 27.49);
        context.bezierCurveTo(49.63, 32.2, 52.84, 27.65, 52.84, 17.07);
        context.bezierCurveTo(52.84, 6.48, 50.35, 1.77, 50.35, 1.77);
        context.bezierCurveTo(50.79, 4.98, 30.9, 14.96, 30.9, 14.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2493 Drawing
        context.beginPath();
        context.moveTo(5.12, 1.77);
        context.bezierCurveTo(5.12, 1.77, 7.28, -2.72, 24.58, 11.97);
        context.lineTo(24.58, 14.96);
        context.bezierCurveTo(24.58, 14.96, 4.35, 4.76, 5.12, 1.77);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2494 Drawing
        context.beginPath();
        context.moveTo(5.12, 1.77);
        context.bezierCurveTo(5.12, 1.77, 7.28, -2.72, 24.58, 11.97);
        context.lineTo(24.58, 14.96);
        context.bezierCurveTo(24.58, 14.96, 4.35, 4.76, 5.12, 1.77);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2495 Drawing
        context.beginPath();
        context.moveTo(24.58, 14.96);
        context.lineTo(24.52, 20.45);
        context.bezierCurveTo(24.52, 20.45, 20.26, 22.78, 13.05, 27.49);
        context.bezierCurveTo(5.85, 32.2, 2.63, 27.65, 2.63, 17.07);
        context.bezierCurveTo(2.63, 6.48, 5.12, 1.77, 5.12, 1.77);
        context.bezierCurveTo(4.68, 4.98, 24.58, 14.96, 24.58, 14.96);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();
        
        context.restore();

    }

    function drawCowboyHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 99, 59), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 99, resizedFrame.h / 59);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor22 = 'rgba(199, 168, 140, 1)';
        var fillColor49 = 'rgba(139, 111, 89, 1)';

        //// Bezier 2464 Drawing
        context.beginPath();
        context.moveTo(49.9, 56.17);
        context.bezierCurveTo(42.98, 56.51, 25.82, 55.15, 13.69, 46.42);
        context.bezierCurveTo(1.56, 37.69, 0.42, 32.36, 3.83, 28.45);
        context.bezierCurveTo(7.23, 24.53, 27.52, 27.6, 27.52, 27.6);
        context.bezierCurveTo(31.38, 10.7, 35.01, 4.35, 37.05, 2.42);
        context.bezierCurveTo(39.09, 0.5, 42.83, 2.65, 43.74, 3.56);
        context.bezierCurveTo(44.65, 4.46, 50.01, 4.69, 50.01, 4.69);
        context.bezierCurveTo(50.01, 4.69, 54.89, 4.46, 55.8, 3.56);
        context.bezierCurveTo(56.7, 2.65, 60.45, 0.5, 62.49, 2.42);
        context.bezierCurveTo(64.53, 4.35, 68.16, 10.7, 72.01, 27.6);
        context.bezierCurveTo(72.01, 27.6, 92.31, 24.53, 95.71, 28.45);
        context.bezierCurveTo(99.12, 32.36, 97.98, 37.69, 85.85, 46.42);
        context.bezierCurveTo(73.71, 55.15, 56.82, 56.51, 49.9, 56.17);
        context.closePath();
        context.fillStyle = fillColor22;
        context.fill();


        //// Bezier 2465 Drawing
        context.beginPath();
        context.moveTo(49.9, 56.17);
        context.bezierCurveTo(42.98, 56.51, 25.82, 55.15, 13.69, 46.42);
        context.bezierCurveTo(1.56, 37.69, 0.42, 32.36, 3.83, 28.45);
        context.bezierCurveTo(7.23, 24.53, 27.52, 27.6, 27.52, 27.6);
        context.bezierCurveTo(31.38, 10.7, 35.01, 4.35, 37.05, 2.42);
        context.bezierCurveTo(39.09, 0.5, 42.83, 2.65, 43.74, 3.56);
        context.bezierCurveTo(44.65, 4.46, 50.01, 4.69, 50.01, 4.69);
        context.bezierCurveTo(50.01, 4.69, 54.89, 4.46, 55.8, 3.56);
        context.bezierCurveTo(56.7, 2.65, 60.45, 0.5, 62.49, 2.42);
        context.bezierCurveTo(64.53, 4.35, 68.16, 10.7, 72.01, 27.6);
        context.bezierCurveTo(72.01, 27.6, 92.31, 24.53, 95.71, 28.45);
        context.bezierCurveTo(99.12, 32.36, 97.98, 37.69, 85.85, 46.42);
        context.bezierCurveTo(73.71, 55.15, 56.82, 56.51, 49.9, 56.17);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2466 Drawing
        context.beginPath();
        context.moveTo(27.34, 27.48);
        context.bezierCurveTo(27.34, 27.48, 22.8, 34.74, 32.32, 39.58);
        context.bezierCurveTo(41.85, 44.42, 63.47, 44.27, 70.58, 36.86);
        context.bezierCurveTo(70.58, 36.86, 73.71, 33.69, 72.01, 27.6);
        context.bezierCurveTo(72.01, 27.6, 69.67, 36.25, 49.71, 36.25);
        context.bezierCurveTo(29.75, 36.25, 27.34, 27.48, 27.34, 27.48);
        context.closePath();
        context.fillStyle = fillColor49;
        context.fill();


        //// Bezier 2467 Drawing
        context.beginPath();
        context.moveTo(27.34, 27.48);
        context.bezierCurveTo(27.34, 27.48, 22.8, 34.74, 32.32, 39.58);
        context.bezierCurveTo(41.85, 44.42, 63.47, 44.27, 70.58, 36.86);
        context.bezierCurveTo(70.58, 36.86, 73.71, 33.69, 72.01, 27.6);
        context.bezierCurveTo(72.01, 27.6, 69.67, 36.25, 49.71, 36.25);
        context.bezierCurveTo(29.75, 36.25, 27.34, 27.48, 27.34, 27.48);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();
        
        context.restore();

    }

    function drawWitchHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 68), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 68);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor = 'rgba(198, 198, 198, 1)';
        var fillColor22 = 'rgba(199, 168, 140, 1)';
        var fillColor62 = 'rgba(177, 177, 176, 1)';

        //// Oval 546 Drawing
        oval(context, 4.75, 42.83, 77.5, 23.4);
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Oval 547 Drawing
        oval(context, 4.75, 42.83, 77.5, 23.4);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2468 Drawing
        context.beginPath();
        context.moveTo(20.4, 54.8);
        context.bezierCurveTo(20.07, 54.72, 43.68, 1.12, 43.68, 1.12);
        context.lineTo(66.58, 54.43);
        context.bezierCurveTo(66.58, 54.43, 44.98, 60.85, 20.4, 54.8);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2469 Drawing
        context.beginPath();
        context.moveTo(20.4, 54.8);
        context.bezierCurveTo(20.07, 54.72, 43.68, 1.12, 43.68, 1.12);
        context.lineTo(66.58, 54.43);
        context.bezierCurveTo(66.58, 54.43, 44.98, 60.85, 20.4, 54.8);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2470 Drawing
        context.beginPath();
        context.moveTo(43.4, 46.33);
        context.bezierCurveTo(36.35, 46.33, 30.78, 45.54, 25.22, 44.2);
        context.bezierCurveTo(22.05, 50.52, 20.41, 54.78, 20.49, 54.8);
        context.bezierCurveTo(45.07, 60.85, 66.67, 54.43, 66.67, 54.43);
        context.lineTo(62.08, 44.28);
        context.bezierCurveTo(56.45, 45.68, 50.6, 46.33, 43.4, 46.33);
        context.closePath();
        context.fillStyle = fillColor62;
        context.fill();


        //// Bezier 2471 Drawing
        context.beginPath();
        context.moveTo(43.4, 46.33);
        context.bezierCurveTo(36.35, 46.33, 30.78, 45.54, 25.22, 44.2);
        context.bezierCurveTo(22.05, 50.52, 20.41, 54.78, 20.49, 54.8);
        context.bezierCurveTo(45.07, 60.85, 66.67, 54.43, 66.67, 54.43);
        context.lineTo(62.08, 44.28);
        context.bezierCurveTo(56.45, 45.68, 50.6, 46.33, 43.4, 46.33);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2472 Drawing
        context.beginPath();
        context.moveTo(51.82, 46.07);
        context.bezierCurveTo(49.19, 46.31, 46.42, 46.33, 43.61, 46.33);
        context.bezierCurveTo(40.91, 46.33, 38.31, 46.33, 35.79, 46.12);
        context.lineTo(35.76, 57.14);
        context.bezierCurveTo(41.7, 57.57, 47.16, 57.4, 51.79, 57);
        context.lineTo(51.82, 46.07);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 2473 Drawing
        context.beginPath();
        context.moveTo(51.82, 46.07);
        context.bezierCurveTo(49.19, 46.31, 46.42, 46.33, 43.61, 46.33);
        context.bezierCurveTo(40.91, 46.33, 38.31, 46.33, 35.79, 46.12);
        context.lineTo(35.76, 57.14);
        context.bezierCurveTo(41.7, 57.57, 47.16, 57.4, 51.79, 57);
        context.lineTo(51.82, 46.07);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();


        //// Bezier 2474 Drawing
        context.beginPath();
        context.moveTo(51.68, 51.38);
        context.lineTo(46.93, 51.38);
        context.fillStyle = fillColor22;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.2;
        context.stroke();
        
        context.restore();

    }

    function drawMouseNose(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 37, 13), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 37, resizedFrame.h / 13);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor52 = 'rgba(146, 100, 64, 1)';

        //// Bezier 2530 Drawing
        context.beginPath();
        context.moveTo(24.65, 5.05);
        context.lineTo(35.46, 6.63);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2531 Drawing
        context.beginPath();
        context.moveTo(23.73, 6.87);
        context.lineTo(32.21, 10.86);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2532 Drawing
        context.beginPath();
        context.moveTo(24.73, 3.13);
        context.lineTo(34.21, 2.22);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2533 Drawing
        context.beginPath();
        context.moveTo(12.54, 5.05);
        context.lineTo(1.73, 6.63);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2534 Drawing
        context.beginPath();
        context.moveTo(13.46, 6.87);
        context.lineTo(4.98, 10.86);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2535 Drawing
        context.beginPath();
        context.moveTo(12.46, 3.13);
        context.lineTo(2.98, 2.22);
        context.fillStyle = fillColor52;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2536 Drawing
        context.beginPath();
        context.moveTo(18.38, 8.83);
        context.bezierCurveTo(26.25, 0.97, 18.38, 3.07, 18.38, 3.07);
        context.bezierCurveTo(18.38, 3.07, 10.52, 0.97, 18.38, 8.83);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2537 Drawing
        context.beginPath();
        context.moveTo(18.38, 8.83);
        context.bezierCurveTo(26.25, 0.97, 18.38, 3.07, 18.38, 3.07);
        context.bezierCurveTo(18.38, 3.07, 10.52, 0.97, 18.38, 8.83);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.44;
        context.stroke();
        
        context.restore();

    }

    function drawRabbitEars(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 86), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 86);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2480 Drawing
        context.beginPath();
        context.moveTo(44.03, 55.93);
        context.lineTo(44.03, 55.9);
        context.bezierCurveTo(30.42, 55.9, 19.96, 61.04, 9.58, 71.31);
        context.bezierCurveTo(9.1, 77.43, 9.26, 76.68, 9.27, 83.04);
        context.lineTo(9.27, 83.04);
        context.bezierCurveTo(19.78, 72.53, 30.32, 67.28, 44.09, 67.28);
        context.lineTo(44.09, 67.3);
        context.bezierCurveTo(57.86, 67.3, 67.86, 72.56, 78.37, 83.06);
        context.lineTo(78.38, 83.07);
        context.bezierCurveTo(78.38, 76.82, 78.46, 77.59, 78.08, 71.46);
        context.bezierCurveTo(67.66, 61.11, 57.7, 55.93, 44.03, 55.93);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2481 Drawing
        context.beginPath();
        context.moveTo(44.03, 55.93);
        context.lineTo(44.03, 55.9);
        context.bezierCurveTo(30.42, 55.9, 19.96, 61.04, 9.58, 71.31);
        context.bezierCurveTo(9.1, 77.43, 9.26, 76.68, 9.27, 83.04);
        context.lineTo(9.27, 83.04);
        context.bezierCurveTo(19.78, 72.53, 30.32, 67.28, 44.09, 67.28);
        context.lineTo(44.09, 67.3);
        context.bezierCurveTo(57.86, 67.3, 67.86, 72.56, 78.37, 83.06);
        context.lineTo(78.38, 83.07);
        context.bezierCurveTo(78.38, 76.82, 78.46, 77.59, 78.08, 71.46);
        context.bezierCurveTo(67.66, 61.11, 57.7, 55.93, 44.03, 55.93);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2482 Drawing
        context.beginPath();
        context.moveTo(29.14, 65.52);
        context.bezierCurveTo(29.14, 65.52, 21.46, 52.72, 17.36, 48.36);
        context.bezierCurveTo(13.27, 44.01, -1.72, 22.24, 9.94, 3.42);
        context.bezierCurveTo(9.94, 3.42, 12.97, -2.14, 19.41, 6.36);
        context.bezierCurveTo(26.42, 15.61, 36.95, 32.5, 36.7, 63.36);
        context.bezierCurveTo(36.7, 63.36, 32.43, 63.81, 29.14, 65.52);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2483 Drawing
        context.beginPath();
        context.moveTo(29.14, 65.52);
        context.bezierCurveTo(29.14, 65.52, 21.46, 52.72, 17.36, 48.36);
        context.bezierCurveTo(13.27, 44.01, -1.72, 22.24, 9.94, 3.42);
        context.bezierCurveTo(9.94, 3.42, 12.97, -2.14, 19.41, 6.36);
        context.bezierCurveTo(26.42, 15.61, 36.95, 32.5, 36.7, 63.36);
        context.bezierCurveTo(36.7, 63.36, 32.43, 63.81, 29.14, 65.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2484 Drawing
        context.beginPath();
        context.moveTo(58.06, 65.63);
        context.bezierCurveTo(58.06, 65.63, 65.74, 52.83, 69.84, 48.47);
        context.bezierCurveTo(73.93, 44.12, 88.91, 22.35, 77.26, 3.53);
        context.bezierCurveTo(77.26, 3.53, 73.87, -2.61, 67.79, 6.48);
        context.bezierCurveTo(61.34, 16.12, 50.25, 32.62, 50.5, 63.47);
        context.bezierCurveTo(50.5, 63.47, 55.01, 63.81, 58.06, 65.63);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair13;
        context.fill();


        //// Bezier 2485 Drawing
        context.beginPath();
        context.moveTo(58.06, 65.63);
        context.bezierCurveTo(58.06, 65.63, 65.74, 52.83, 69.84, 48.47);
        context.bezierCurveTo(73.93, 44.12, 88.91, 22.35, 77.26, 3.53);
        context.bezierCurveTo(77.26, 3.53, 73.87, -2.61, 67.79, 6.48);
        context.bezierCurveTo(61.34, 16.12, 50.25, 32.62, 50.5, 63.47);
        context.bezierCurveTo(50.5, 63.47, 55.01, 63.81, 58.06, 65.63);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2486 Drawing
        context.beginPath();
        context.moveTo(29.14, 65.52);
        context.bezierCurveTo(29.14, 65.52, 25.31, 56.59, 19.88, 47.61);
        context.bezierCurveTo(14.18, 38.19, 6.36, 22.23, 14.23, 16.25);
        context.bezierCurveTo(22.1, 10.26, 31.85, 40.96, 31.85, 40.96);
        context.lineTo(36.7, 63.36);
        context.bezierCurveTo(36.7, 63.36, 33.31, 62.92, 29.14, 65.52);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2487 Drawing
        context.beginPath();
        context.moveTo(29.14, 65.52);
        context.bezierCurveTo(29.14, 65.52, 25.31, 56.59, 19.88, 47.61);
        context.bezierCurveTo(14.18, 38.19, 6.36, 22.23, 14.23, 16.25);
        context.bezierCurveTo(22.1, 10.26, 31.85, 40.96, 31.85, 40.96);
        context.lineTo(36.7, 63.36);
        context.bezierCurveTo(36.7, 63.36, 33.31, 62.92, 29.14, 65.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2488 Drawing
        context.beginPath();
        context.moveTo(58.02, 65.52);
        context.bezierCurveTo(58.02, 65.52, 61.86, 56.59, 67.29, 47.61);
        context.bezierCurveTo(72.99, 38.19, 80.81, 22.23, 72.94, 16.25);
        context.bezierCurveTo(65.07, 10.26, 55.32, 40.96, 55.32, 40.96);
        context.lineTo(50.47, 63.36);
        context.bezierCurveTo(50.47, 63.36, 53.86, 62.92, 58.02, 65.52);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Bezier 2489 Drawing
        context.beginPath();
        context.moveTo(58.02, 65.52);
        context.bezierCurveTo(58.02, 65.52, 61.86, 56.59, 67.29, 47.61);
        context.bezierCurveTo(72.99, 38.19, 80.81, 22.23, 72.94, 16.25);
        context.bezierCurveTo(65.07, 10.26, 55.32, 40.96, 55.32, 40.96);
        context.lineTo(50.47, 63.36);
        context.bezierCurveTo(50.47, 63.36, 53.86, 62.92, 58.02, 65.52);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSmallGreenBows(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 59, 21), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 59, resizedFrame.h / 21);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor12 = 'rgba(0, 120, 0, 1)';

        //// Rectangle 135 Drawing
        context.save();
        context.translate(49.05, 10.99);
        context.rotate(45 * Math.PI / 180);

        context.beginPath();
        context.rect(-1.05, -1.48, 2.1, 2.95);
        context.fillStyle = fillColor12;
        context.fill();

        context.restore();


        //// Rectangle 136 Drawing
        context.save();
        context.translate(49.05, 10.99);
        context.rotate(45 * Math.PI / 180);

        context.beginPath();
        context.rect(-1.05, -1.48, 2.1, 2.95);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();


        //// Bezier 2622 Drawing
        context.beginPath();
        context.moveTo(57.07, 12.68);
        context.bezierCurveTo(57.07, 12.68, 57.58, 11.23, 50.6, 10.66);
        context.lineTo(49.95, 11.32);
        context.bezierCurveTo(49.95, 11.32, 56.59, 13.51, 57.07, 12.68);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2623 Drawing
        context.beginPath();
        context.moveTo(57.07, 12.68);
        context.bezierCurveTo(57.07, 12.68, 57.58, 11.23, 50.6, 10.66);
        context.lineTo(49.95, 11.32);
        context.bezierCurveTo(49.95, 11.32, 56.59, 13.51, 57.07, 12.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2624 Drawing
        context.beginPath();
        context.moveTo(49.95, 11.32);
        context.lineTo(48.76, 12.53);
        context.bezierCurveTo(48.76, 12.53, 49.19, 13.96, 49.73, 16.57);
        context.bezierCurveTo(50.27, 19.16, 51.97, 18.87, 54.28, 16.57);
        context.bezierCurveTo(56.59, 14.25, 57.07, 12.68, 57.07, 12.68);
        context.bezierCurveTo(56.47, 13.48, 49.95, 11.32, 49.95, 11.32);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2625 Drawing
        context.beginPath();
        context.moveTo(47.2, 2.82);
        context.bezierCurveTo(47.2, 2.82, 48.65, 2.31, 49.22, 9.28);
        context.lineTo(48.57, 9.94);
        context.bezierCurveTo(48.57, 9.94, 46.38, 3.3, 47.2, 2.82);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2626 Drawing
        context.beginPath();
        context.moveTo(47.2, 2.82);
        context.bezierCurveTo(47.2, 2.82, 48.65, 2.31, 49.22, 9.28);
        context.lineTo(48.57, 9.94);
        context.bezierCurveTo(48.57, 9.94, 46.38, 3.3, 47.2, 2.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2627 Drawing
        context.beginPath();
        context.moveTo(48.57, 9.94);
        context.lineTo(47.36, 11.12);
        context.bezierCurveTo(47.36, 11.12, 45.92, 10.7, 43.32, 10.16);
        context.bezierCurveTo(40.72, 9.61, 41.01, 7.92, 43.32, 5.61);
        context.bezierCurveTo(45.63, 3.3, 47.2, 2.82, 47.2, 2.82);
        context.bezierCurveTo(46.4, 3.42, 48.57, 9.94, 48.57, 9.94);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Rectangle 137 Drawing
        context.save();
        context.translate(9.65, 10.84);
        context.rotate(-45 * Math.PI / 180);

        context.beginPath();
        context.rect(-1.05, -1.48, 2.1, 2.95);
        context.fillStyle = fillColor12;
        context.fill();

        context.restore();


        //// Rectangle 138 Drawing
        context.save();
        context.translate(9.65, 10.84);
        context.rotate(-45 * Math.PI / 180);

        context.beginPath();
        context.rect(-1.05, -1.48, 2.1, 2.95);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();

        context.restore();


        //// Bezier 2628 Drawing
        context.beginPath();
        context.moveTo(11.35, 2.82);
        context.bezierCurveTo(11.35, 2.82, 9.9, 2.31, 9.33, 9.28);
        context.lineTo(9.99, 9.94);
        context.bezierCurveTo(9.99, 9.94, 12.18, 3.3, 11.35, 2.82);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2629 Drawing
        context.beginPath();
        context.moveTo(11.35, 2.82);
        context.bezierCurveTo(11.35, 2.82, 9.9, 2.31, 9.33, 9.28);
        context.lineTo(9.99, 9.94);
        context.bezierCurveTo(9.99, 9.94, 12.18, 3.3, 11.35, 2.82);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2630 Drawing
        context.beginPath();
        context.moveTo(9.99, 9.94);
        context.lineTo(11.2, 11.12);
        context.bezierCurveTo(11.2, 11.12, 12.64, 10.7, 15.24, 10.16);
        context.bezierCurveTo(17.84, 9.61, 17.55, 7.92, 15.24, 5.61);
        context.bezierCurveTo(12.93, 3.3, 11.35, 2.82, 11.35, 2.82);
        context.bezierCurveTo(12.15, 3.42, 9.99, 9.94, 9.99, 9.94);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2631 Drawing
        context.beginPath();
        context.moveTo(1.49, 12.68);
        context.bezierCurveTo(1.49, 12.68, 0.98, 11.23, 7.96, 10.66);
        context.lineTo(8.61, 11.32);
        context.bezierCurveTo(8.61, 11.32, 1.97, 13.51, 1.49, 12.68);
        context.closePath();
        context.fillStyle = fillColor12;
        context.fill();


        //// Bezier 2632 Drawing
        context.beginPath();
        context.moveTo(1.49, 12.68);
        context.bezierCurveTo(1.49, 12.68, 0.98, 11.23, 7.96, 10.66);
        context.lineTo(8.61, 11.32);
        context.bezierCurveTo(8.61, 11.32, 1.97, 13.51, 1.49, 12.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();


        //// Bezier 2633 Drawing
        context.beginPath();
        context.moveTo(8.61, 11.32);
        context.lineTo(9.79, 12.53);
        context.bezierCurveTo(9.79, 12.53, 9.37, 13.96, 8.83, 16.57);
        context.bezierCurveTo(8.28, 19.16, 6.59, 18.87, 4.28, 16.57);
        context.bezierCurveTo(1.97, 14.25, 1.49, 12.68, 1.49, 12.68);
        context.bezierCurveTo(2.09, 13.48, 8.61, 11.32, 8.61, 11.32);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.stroke();
        
        context.restore();

    }

    function drawGoldHoopEarrings(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 67, 21), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 67, resizedFrame.h / 21);


        //// Color Declarations
        var strokeColor19 = 'rgba(242, 229, 0, 1)';

        //// Bezier 2496 Drawing
        context.beginPath();
        context.moveTo(5.75, 3.37);
        context.bezierCurveTo(6.36, 4.41, 6.8, 6.9, 6.8, 9.81);
        context.bezierCurveTo(6.8, 13.65, 6.04, 16.77, 5.11, 16.77);
        context.bezierCurveTo(4.18, 16.77, 3.42, 13.65, 3.42, 9.81);
        context.bezierCurveTo(3.42, 7, 3.15, 4.5, 4.22, 3.86);
        context.strokeStyle = strokeColor19;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2497 Drawing
        context.beginPath();
        context.moveTo(63.01, 3.37);
        context.bezierCurveTo(62.39, 4.41, 61.96, 6.9, 61.96, 9.81);
        context.bezierCurveTo(61.96, 13.65, 62.72, 16.77, 63.65, 16.77);
        context.bezierCurveTo(64.58, 16.77, 65.34, 13.65, 65.34, 9.81);
        context.bezierCurveTo(65.34, 7, 65.61, 4.5, 64.54, 3.86);
        context.strokeStyle = strokeColor19;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawHairPearls(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 31), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 31);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Oval 584 Drawing
        oval(context, 1.95, 23.39, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 585 Drawing
        oval(context, 1.95, 23.39, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 586 Drawing
        oval(context, 5.35, 18.24, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 587 Drawing
        oval(context, 5.35, 18.24, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 588 Drawing
        oval(context, 8.7, 13.59, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 589 Drawing
        oval(context, 8.7, 13.59, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 590 Drawing
        oval(context, 12.65, 9.39, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 591 Drawing
        oval(context, 12.65, 9.39, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 592 Drawing
        oval(context, 17.3, 5.69, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 593 Drawing
        oval(context, 17.3, 5.69, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 594 Drawing
        oval(context, 22.7, 2.74, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 595 Drawing
        oval(context, 22.7, 2.74, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 596 Drawing
        oval(context, 28.8, 1.49, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 597 Drawing
        oval(context, 28.8, 1.49, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 598 Drawing
        oval(context, 55.8, 23.39, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 599 Drawing
        oval(context, 55.8, 23.39, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 600 Drawing
        oval(context, 52.4, 18.24, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 601 Drawing
        oval(context, 52.4, 18.24, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 602 Drawing
        oval(context, 49.05, 13.59, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 603 Drawing
        oval(context, 49.05, 13.59, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 604 Drawing
        oval(context, 45.1, 9.39, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 605 Drawing
        oval(context, 45.1, 9.39, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 606 Drawing
        oval(context, 40.45, 5.69, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 607 Drawing
        oval(context, 40.45, 5.69, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 608 Drawing
        oval(context, 35.05, 2.74, 5.7, 5.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 609 Drawing
        oval(context, 35.05, 2.74, 5.7, 5.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSquareGlasses(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 37, 13), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 37, resizedFrame.h / 13);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Rectangle 131 Drawing
        context.beginPath();
        context.rect(0.48, 1.29, 15.65, 10.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 132 Drawing
        context.beginPath();
        context.rect(20.48, 1.29, 15.65, 10.7);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Rectangle 133 Drawing
        context.beginPath();
        context.rect(0.48, 1.29, 15.65, 10.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Rectangle 134 Drawing
        context.beginPath();
        context.rect(20.48, 1.29, 15.65, 10.7);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2621 Drawing
        context.beginPath();
        context.moveTo(15.93, 5.74);
        context.lineTo(20.09, 5.74);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawVikingHelmet(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 68), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 68);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor = 'rgba(198, 198, 198, 1)';
        var fillColor34 = 'rgba(75, 57, 50, 1)';

        //// Bezier 2538 Drawing
        context.beginPath();
        context.moveTo(78.23, 62.04);
        context.lineTo(10.18, 62.04);
        context.lineTo(9.18, 62.04);
        context.lineTo(9.17, 61.03);
        context.bezierCurveTo(9.16, 40.7, 7.63, 23.41, 27.4, 10.47);
        context.bezierCurveTo(35.04, 5.47, 44.62, 4.05, 53.2, 7.16);
        context.bezierCurveTo(53.59, 7.3, 53.97, 7.45, 54.36, 7.61);
        context.bezierCurveTo(81.05, 18.58, 79.25, 39.52, 79.24, 62.07);
        context.lineTo(78.23, 62.04);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Bezier 2539 Drawing
        context.beginPath();
        context.moveTo(78.23, 62.04);
        context.lineTo(10.18, 62.04);
        context.lineTo(9.18, 62.04);
        context.lineTo(9.17, 61.03);
        context.bezierCurveTo(9.16, 40.7, 7.63, 23.41, 27.4, 10.47);
        context.bezierCurveTo(35.04, 5.47, 44.62, 4.05, 53.2, 7.16);
        context.bezierCurveTo(53.59, 7.3, 53.97, 7.45, 54.36, 7.61);
        context.bezierCurveTo(81.05, 18.58, 79.25, 39.52, 79.24, 62.07);
        context.lineTo(78.23, 62.04);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2540 Drawing
        context.beginPath();
        context.moveTo(68.51, 39.78);
        context.lineTo(69.19, 41.12);
        context.lineTo(66.6, 42.36);
        context.lineTo(60.31, 29.72);
        context.lineTo(62.5, 28.65);
        context.lineTo(63.12, 29.66);
        context.lineTo(68.51, 39.78);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 2541 Drawing
        context.beginPath();
        context.moveTo(68.51, 39.78);
        context.lineTo(69.19, 41.12);
        context.lineTo(66.6, 42.36);
        context.lineTo(60.31, 29.72);
        context.lineTo(62.5, 28.65);
        context.lineTo(63.12, 29.66);
        context.lineTo(68.51, 39.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2542 Drawing
        context.beginPath();
        context.moveTo(19.22, 39.78);
        context.lineTo(18.54, 41.12);
        context.lineTo(21.13, 42.36);
        context.lineTo(27.42, 29.72);
        context.lineTo(25.23, 28.65);
        context.lineTo(24.61, 29.66);
        context.lineTo(19.22, 39.78);
        context.closePath();
        context.fillStyle = fillColor;
        context.fill();


        //// Bezier 2543 Drawing
        context.beginPath();
        context.moveTo(19.22, 39.78);
        context.lineTo(18.54, 41.12);
        context.lineTo(21.13, 42.36);
        context.lineTo(27.42, 29.72);
        context.lineTo(25.23, 28.65);
        context.lineTo(24.61, 29.66);
        context.lineTo(19.22, 39.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2544 Drawing
        context.beginPath();
        context.moveTo(51.41, 6.59);
        context.bezierCurveTo(46.6, 5.21, 41.54, 5.2, 36.74, 6.39);
        context.lineTo(36.74, 62.01);
        context.lineTo(51.41, 62.01);
        context.lineTo(51.41, 6.59);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2545 Drawing
        context.beginPath();
        context.moveTo(51.41, 6.59);
        context.bezierCurveTo(46.6, 5.21, 41.54, 5.2, 36.74, 6.39);
        context.lineTo(36.74, 62.01);
        context.lineTo(51.41, 62.01);
        context.lineTo(51.41, 6.59);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Rectangle 128 Drawing
        context.beginPath();
        context.rect(9.2, 52.24, 70.3, 9.8);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 572 Drawing
        oval(context, 15, 54.44, 5.5, 5.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 573 Drawing
        oval(context, 15, 54.44, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 574 Drawing
        oval(context, 26.2, 54.44, 5.5, 5.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 575 Drawing
        oval(context, 26.2, 54.44, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 576 Drawing
        oval(context, 56.6, 54.44, 5.5, 5.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 577 Drawing
        oval(context, 56.6, 54.44, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 578 Drawing
        oval(context, 67.75, 54.44, 5.5, 5.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 579 Drawing
        oval(context, 67.75, 54.44, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 580 Drawing
        oval(context, 41.6, 54.44, 5.5, 5.5);
        context.fillStyle = fillColor;
        context.fill();


        //// Oval 581 Drawing
        oval(context, 41.6, 54.44, 5.5, 5.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2546 Drawing
        context.beginPath();
        context.moveTo(63.12, 29.66);
        context.bezierCurveTo(63.12, 29.66, 76.78, 25.95, 82.17, 5.72);
        context.bezierCurveTo(82.17, 5.72, 88.74, 29.32, 68.51, 39.78);
        context.lineTo(63.12, 29.66);
        context.closePath();
        context.fillStyle = fillColor34;
        context.fill();


        //// Bezier 2547 Drawing
        context.beginPath();
        context.moveTo(63.12, 29.66);
        context.bezierCurveTo(63.12, 29.66, 76.78, 25.95, 82.17, 5.72);
        context.bezierCurveTo(82.17, 5.72, 88.74, 29.32, 68.51, 39.78);
        context.lineTo(63.12, 29.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2548 Drawing
        context.beginPath();
        context.moveTo(24.61, 29.66);
        context.bezierCurveTo(24.61, 29.66, 10.96, 25.95, 5.56, 5.72);
        context.bezierCurveTo(5.56, 5.72, -1.01, 29.32, 19.22, 39.78);
        context.lineTo(24.61, 29.66);
        context.closePath();
        context.fillStyle = fillColor34;
        context.fill();


        //// Bezier 2549 Drawing
        context.beginPath();
        context.moveTo(24.61, 29.66);
        context.bezierCurveTo(24.61, 29.66, 10.96, 25.95, 5.56, 5.72);
        context.bezierCurveTo(5.56, 5.72, -1.01, 29.32, 19.22, 39.78);
        context.lineTo(24.61, 29.66);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSmallbeard(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 63, 33), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 63, resizedFrame.h / 33);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor14 = 'rgba(139, 98, 57, 1)';

        //// Bezier 1922 Drawing
        context.beginPath();
        context.moveTo(42.32, 22.59);
        context.bezierCurveTo(41.52, 23.98, 39.3, 25.01, 35.1, 24.57);
        context.bezierCurveTo(34.35, 24.49, 33.62, 24.28, 32.91, 24.04);
        context.bezierCurveTo(32.55, 23.92, 32.04, 23.81, 31.35, 23.81);
        context.bezierCurveTo(30.66, 23.81, 30.15, 23.92, 29.79, 24.04);
        context.bezierCurveTo(29.08, 24.28, 28.35, 24.49, 27.6, 24.57);
        context.bezierCurveTo(23.4, 25.01, 21.18, 23.98, 20.38, 22.59);
        context.bezierCurveTo(19.5, 21.03, 19.27, 17.27, 23.15, 16.93);
        context.bezierCurveTo(27.03, 16.6, 30.69, 15.82, 30.69, 15.82);
        context.bezierCurveTo(31.66, 15.71, 32.01, 15.82, 32.01, 15.82);
        context.bezierCurveTo(32.01, 15.82, 35.67, 16.6, 39.55, 16.93);
        context.bezierCurveTo(43.42, 17.27, 43.2, 21.03, 42.32, 22.59);
        context.closePath();
        context.moveTo(47.37, 17.76);
        context.bezierCurveTo(29.42, 7.62, 15.78, 17.76, 15.78, 17.76);
        context.bezierCurveTo(9.96, 17.4, 5.88, 8.01, 4.1, 2.96);
        context.bezierCurveTo(4.35, 18.85, 16.55, 32.22, 31.66, 32.25);
        context.bezierCurveTo(46.53, 32.22, 58.58, 19.27, 59.2, 3.71);
        context.bezierCurveTo(56.66, 11.93, 47.37, 17.76, 47.37, 17.76);
        context.closePath();
        context.fillStyle = fillColor14;
        context.fill();


        //// Bezier 1923 Drawing
        context.beginPath();
        context.moveTo(42.32, 22.59);
        context.bezierCurveTo(41.52, 23.98, 39.3, 25.01, 35.1, 24.57);
        context.bezierCurveTo(34.35, 24.49, 33.62, 24.28, 32.91, 24.04);
        context.bezierCurveTo(32.55, 23.92, 32.04, 23.81, 31.35, 23.81);
        context.bezierCurveTo(30.66, 23.81, 30.15, 23.92, 29.79, 24.04);
        context.bezierCurveTo(29.08, 24.28, 28.35, 24.49, 27.6, 24.57);
        context.bezierCurveTo(23.4, 25.01, 21.18, 23.98, 20.38, 22.59);
        context.bezierCurveTo(19.5, 21.03, 19.27, 17.27, 23.15, 16.93);
        context.bezierCurveTo(27.03, 16.6, 30.69, 15.82, 30.69, 15.82);
        context.bezierCurveTo(31.66, 15.71, 32.01, 15.82, 32.01, 15.82);
        context.bezierCurveTo(32.01, 15.82, 35.67, 16.6, 39.55, 16.93);
        context.bezierCurveTo(43.42, 17.27, 43.2, 21.03, 42.32, 22.59);
        context.closePath();
        context.moveTo(47.37, 17.76);
        context.bezierCurveTo(29.42, 7.62, 15.78, 17.76, 15.78, 17.76);
        context.bezierCurveTo(9.96, 17.4, 5.88, 8.01, 4.1, 2.96);
        context.bezierCurveTo(4.35, 18.85, 16.55, 32.22, 31.66, 32.25);
        context.bezierCurveTo(46.53, 32.22, 58.58, 19.27, 59.2, 3.71);
        context.bezierCurveTo(56.66, 11.93, 47.37, 17.76, 47.37, 17.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawEgyptHelmet(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 87, 103), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 87, resizedFrame.h / 103);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';
        var fillColor9 = 'rgba(26, 84, 250, 1)';

        //// Group 56
        context.save();

        //// Clip Clip 54

        context.beginPath();
        context.moveTo(43.39, 2.55);
        context.bezierCurveTo(40.87, 2.53, 3.04, 2.88, 3.04, 43.04);
        context.lineTo(3.04, 43.04);
        context.lineTo(3.04, 71.83);
        context.lineTo(11.82, 71.83);
        context.bezierCurveTo(11.82, 71.83, 10.49, 98.76, 20.47, 98.76);
        context.lineTo(20.47, 98.76);
        context.lineTo(20.47, 43.56);
        context.lineTo(41.04, 43.56);
        context.lineTo(45.74, 43.56);
        context.lineTo(66.31, 43.56);
        context.lineTo(66.31, 98.76);
        context.bezierCurveTo(76.29, 98.76, 74.96, 71.83, 74.96, 71.83);
        context.lineTo(74.96, 71.83);
        context.lineTo(83.74, 71.83);
        context.lineTo(83.74, 43.04);
        context.bezierCurveTo(83.74, 3.51, 47.09, 2.55, 43.54, 2.55);
        context.lineTo(43.54, 2.55);
        context.bezierCurveTo(43.48, 2.55, 43.43, 2.55, 43.39, 2.55);
        context.closePath();
        context.clip();



        //// Bezier 2555 Drawing
        context.beginPath();
        context.moveTo(77.66, 99.56);
        context.lineTo(9.71, 99.56);
        context.lineTo(9.71, 93.42);
        context.lineTo(77.66, 93.42);
        context.lineTo(77.66, 99.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2556 Drawing
        context.beginPath();
        context.moveTo(77.66, 93.56);
        context.lineTo(9.71, 93.56);
        context.lineTo(9.71, 87.42);
        context.lineTo(77.66, 87.42);
        context.lineTo(77.66, 93.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2557 Drawing
        context.beginPath();
        context.moveTo(77.66, 87.56);
        context.lineTo(9.71, 87.56);
        context.lineTo(9.71, 81.42);
        context.lineTo(77.66, 81.42);
        context.lineTo(77.66, 87.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2558 Drawing
        context.beginPath();
        context.moveTo(77.66, 81.56);
        context.lineTo(9.71, 81.56);
        context.lineTo(9.71, 75.42);
        context.lineTo(77.66, 75.42);
        context.lineTo(77.66, 81.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2559 Drawing
        context.beginPath();
        context.moveTo(85.64, 75.56);
        context.lineTo(1.73, 75.56);
        context.lineTo(1.73, 69.42);
        context.lineTo(85.64, 69.42);
        context.lineTo(85.64, 75.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2560 Drawing
        context.beginPath();
        context.moveTo(85.64, 69.56);
        context.lineTo(1.73, 69.56);
        context.lineTo(1.73, 63.42);
        context.lineTo(85.64, 63.42);
        context.lineTo(85.64, 69.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2561 Drawing
        context.beginPath();
        context.moveTo(85.64, 63.56);
        context.lineTo(1.73, 63.56);
        context.lineTo(1.73, 57.42);
        context.lineTo(85.64, 57.42);
        context.lineTo(85.64, 63.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2562 Drawing
        context.beginPath();
        context.moveTo(85.64, 57.56);
        context.lineTo(1.73, 57.56);
        context.lineTo(1.73, 51.42);
        context.lineTo(85.64, 51.42);
        context.lineTo(85.64, 57.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2563 Drawing
        context.beginPath();
        context.moveTo(85.64, 51.56);
        context.lineTo(1.73, 51.56);
        context.lineTo(1.73, 45.42);
        context.lineTo(85.64, 45.42);
        context.lineTo(85.64, 51.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2564 Drawing
        context.beginPath();
        context.moveTo(85.64, 45.56);
        context.lineTo(1.73, 45.56);
        context.lineTo(1.73, 39.42);
        context.lineTo(85.64, 39.42);
        context.lineTo(85.64, 45.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2565 Drawing
        context.beginPath();
        context.moveTo(85.64, 39.56);
        context.lineTo(1.73, 39.56);
        context.lineTo(1.73, 33.42);
        context.lineTo(85.64, 33.42);
        context.lineTo(85.64, 39.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2566 Drawing
        context.beginPath();
        context.moveTo(85.64, 33.56);
        context.lineTo(1.73, 33.56);
        context.lineTo(1.73, 27.42);
        context.lineTo(85.64, 27.42);
        context.lineTo(85.64, 33.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2567 Drawing
        context.beginPath();
        context.moveTo(85.64, 27.56);
        context.lineTo(1.73, 27.56);
        context.lineTo(1.73, 21.42);
        context.lineTo(85.64, 21.42);
        context.lineTo(85.64, 27.56);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2568 Drawing
        context.beginPath();
        context.moveTo(85.64, 21.56);
        context.lineTo(1.73, 21.56);
        context.lineTo(1.73, 15.42);
        context.lineTo(85.64, 15.42);
        context.lineTo(85.64, 21.56);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        context.restore();



        //// Rectangle 129 Drawing
        context.beginPath();
        context.rect(20.48, 36.51, 45.85, 7.05);
        context.fillStyle = fillColor7;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Bezier 2570 Drawing
        context.beginPath();
        context.moveTo(66.26, 36.52);
        context.lineTo(76.03, 17.74);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Group 57
        context.save();

        //// Clip Clip 55

        context.beginPath();
        context.moveTo(43.39, 2.55);
        context.bezierCurveTo(40.87, 2.53, 3.04, 2.88, 3.04, 43.04);
        context.lineTo(3.04, 43.04);
        context.lineTo(3.04, 71.83);
        context.lineTo(11.82, 71.83);
        context.bezierCurveTo(11.82, 71.83, 10.49, 98.76, 20.47, 98.76);
        context.lineTo(20.47, 98.76);
        context.lineTo(20.47, 43.56);
        context.lineTo(41.04, 43.56);
        context.lineTo(45.74, 43.56);
        context.lineTo(66.31, 43.56);
        context.lineTo(66.31, 98.76);
        context.bezierCurveTo(76.29, 98.76, 74.96, 71.83, 74.96, 71.83);
        context.lineTo(74.96, 71.83);
        context.lineTo(83.74, 71.83);
        context.lineTo(83.74, 43.04);
        context.bezierCurveTo(83.74, 3.51, 47.09, 2.55, 43.54, 2.55);
        context.lineTo(43.54, 2.55);
        context.bezierCurveTo(43.48, 2.55, 43.43, 2.55, 43.39, 2.55);
        context.closePath();
        context.clip();



        //// Bezier 2571 Drawing
        context.beginPath();
        context.moveTo(8.72, 13.74);
        context.lineTo(15.92, 11.86);
        context.lineTo(27.95, 36.47);
        context.lineTo(20.65, 36.47);
        context.lineTo(8.72, 13.74);
        context.lineTo(20.65, 36.53);
        context.lineTo(10.75, 17.74);
        context.lineTo(8.72, 13.74);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 2572 Drawing
        context.beginPath();
        context.moveTo(8.72, 13.74);
        context.lineTo(15.92, 11.86);
        context.lineTo(27.95, 36.47);
        context.lineTo(20.65, 36.47);
        context.lineTo(8.72, 13.74);
        context.lineTo(20.65, 36.53);
        context.lineTo(10.75, 17.74);
        context.lineTo(8.72, 13.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Bezier 2573 Drawing
        context.beginPath();
        context.moveTo(26.93, 36.47);
        context.lineTo(13.78, 8.2);
        context.lineTo(22.67, 3.99);
        context.lineTo(34.08, 36.44);
        context.lineTo(26.93, 36.47);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2574 Drawing
        context.beginPath();
        context.moveTo(26.93, 36.47);
        context.lineTo(13.78, 8.2);
        context.lineTo(22.67, 3.99);
        context.lineTo(34.08, 36.44);
        context.lineTo(26.93, 36.47);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Bezier 2575 Drawing
        context.beginPath();
        context.moveTo(33.06, 36.44);
        context.lineTo(20.42, -1.05);
        context.lineTo(33.24, -3.68);
        context.lineTo(38.29, 36.52);
        context.lineTo(33.06, 36.44);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 2576 Drawing
        context.beginPath();
        context.moveTo(33.06, 36.44);
        context.lineTo(20.42, -1.05);
        context.lineTo(33.24, -3.68);
        context.lineTo(38.29, 36.52);
        context.lineTo(33.06, 36.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.15;
        context.stroke();


        //// Bezier 2577 Drawing
        context.beginPath();
        context.moveTo(78.02, 13.74);
        context.lineTo(70.82, 11.86);
        context.lineTo(58.79, 36.47);
        context.lineTo(66.09, 36.47);
        context.lineTo(78.02, 13.74);
        context.lineTo(66.09, 36.53);
        context.lineTo(75.99, 17.74);
        context.lineTo(78.02, 13.74);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 2578 Drawing
        context.beginPath();
        context.moveTo(78.02, 13.74);
        context.lineTo(70.82, 11.86);
        context.lineTo(58.79, 36.47);
        context.lineTo(66.09, 36.47);
        context.lineTo(78.02, 13.74);
        context.lineTo(66.09, 36.53);
        context.lineTo(75.99, 17.74);
        context.lineTo(78.02, 13.74);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Bezier 2579 Drawing
        context.beginPath();
        context.moveTo(59.8, 36.47);
        context.lineTo(72.95, 8.2);
        context.lineTo(64.07, 3.99);
        context.lineTo(52.66, 36.44);
        context.lineTo(59.8, 36.47);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2580 Drawing
        context.beginPath();
        context.moveTo(59.8, 36.47);
        context.lineTo(72.95, 8.2);
        context.lineTo(64.07, 3.99);
        context.lineTo(52.66, 36.44);
        context.lineTo(59.8, 36.47);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Bezier 2581 Drawing
        context.beginPath();
        context.moveTo(53.68, 36.44);
        context.lineTo(66.32, -1.05);
        context.lineTo(53.5, -3.68);
        context.lineTo(48.44, 36.52);
        context.lineTo(53.68, 36.44);
        context.closePath();
        context.fillStyle = fillColor9;
        context.fill();


        //// Bezier 2582 Drawing
        context.beginPath();
        context.moveTo(53.68, 36.44);
        context.lineTo(66.32, -1.05);
        context.lineTo(53.5, -3.68);
        context.lineTo(48.44, 36.52);
        context.lineTo(53.68, 36.44);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.15;
        context.stroke();


        //// Bezier 2583 Drawing
        context.beginPath();
        context.moveTo(53.5, -3.68);
        context.lineTo(43.53, -3.68);
        context.lineTo(43.21, -3.68);
        context.lineTo(33.24, -3.68);
        context.lineTo(38.29, 36.52);
        context.lineTo(43.37, 36.49);
        context.lineTo(48.44, 36.52);
        context.lineTo(53.5, -3.68);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2584 Drawing
        context.beginPath();
        context.moveTo(53.5, -3.68);
        context.lineTo(43.53, -3.68);
        context.lineTo(43.21, -3.68);
        context.lineTo(33.24, -3.68);
        context.lineTo(38.29, 36.52);
        context.lineTo(43.37, 36.49);
        context.lineTo(48.44, 36.52);
        context.lineTo(53.5, -3.68);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.15;
        context.stroke();


        context.restore();



        //// Bezier 2586 Drawing
        context.beginPath();
        context.moveTo(83.74, 43.03);
        context.bezierCurveTo(83.74, 2.88, 45.91, 2.53, 43.39, 2.55);
        context.bezierCurveTo(40.87, 2.53, 3.04, 2.88, 3.04, 43.03);
        context.lineTo(3.04, 71.83);
        context.lineTo(11.82, 71.83);
        context.bezierCurveTo(11.82, 71.83, 10.49, 98.76, 20.47, 98.76);
        context.lineTo(20.47, 43.56);
        context.lineTo(41.04, 43.56);
        context.lineTo(45.74, 43.56);
        context.lineTo(66.31, 43.56);
        context.lineTo(66.31, 98.76);
        context.bezierCurveTo(76.29, 98.76, 74.96, 71.83, 74.96, 71.83);
        context.lineTo(83.74, 71.83);
        context.lineTo(83.74, 43.03);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();
        
        context.restore();

    }

    function drawRedBeret(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 67, 60), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 67, resizedFrame.h / 60);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor36 = 'rgba(235, 71, 59, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';

        //// Bezier 2550 Drawing
        context.beginPath();
        context.moveTo(63.05, 20.02);
        context.bezierCurveTo(63.05, 20.02, 61.41, 15.24, 63.32, 9.6);
        context.bezierCurveTo(65.23, 3.95, 63.19, 0.54, 54.75, 1);
        context.bezierCurveTo(46.3, 1.47, 25.92, 6.07, 18.75, 12.21);
        context.bezierCurveTo(11.57, 18.35, 7.92, 41.73, 3.95, 47.67);
        context.bezierCurveTo(-0.01, 53.61, 0.43, 58.75, 3.62, 58.78);
        context.bezierCurveTo(6.81, 58.81, 12.15, 50.2, 12.15, 50.2);
        context.bezierCurveTo(12.15, 50.2, 42.36, 28.31, 63.05, 20.02);
        context.closePath();
        context.fillStyle = fillColor36;
        context.fill();


        //// Bezier 2551 Drawing
        context.beginPath();
        context.moveTo(63.05, 20.02);
        context.bezierCurveTo(63.05, 20.02, 61.41, 15.24, 63.32, 9.6);
        context.bezierCurveTo(65.23, 3.95, 63.19, 0.54, 54.75, 1);
        context.bezierCurveTo(46.3, 1.47, 25.92, 6.07, 18.75, 12.21);
        context.bezierCurveTo(11.57, 18.35, 7.92, 41.73, 3.95, 47.67);
        context.bezierCurveTo(-0.01, 53.61, 0.43, 58.75, 3.62, 58.78);
        context.bezierCurveTo(6.81, 58.81, 12.15, 50.2, 12.15, 50.2);
        context.bezierCurveTo(12.15, 50.2, 42.36, 28.31, 63.05, 20.02);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2552 Drawing
        context.beginPath();
        context.moveTo(65.97, 26.76);
        context.lineTo(63.05, 20.02);
        context.bezierCurveTo(22.76, 26.93, 12.15, 50.2, 12.15, 50.2);
        context.bezierCurveTo(12.15, 50.2, 49.16, 33.74, 65.97, 26.76);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 2553 Drawing
        context.beginPath();
        context.moveTo(65.97, 26.76);
        context.lineTo(63.05, 20.02);
        context.bezierCurveTo(22.76, 26.93, 12.15, 50.2, 12.15, 50.2);
        context.bezierCurveTo(12.15, 50.2, 49.16, 33.74, 65.97, 26.76);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2554 Drawing
        context.beginPath();
        context.moveTo(20.86, 14.7);
        context.bezierCurveTo(20.86, 14.7, 16.11, 9.9, 13.47, 11.54);
        context.bezierCurveTo(10.84, 13.19, 17.89, 15.62, 17.89, 15.62);
        context.fillStyle = fillColor28;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawWhiteGlasses(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 49, 23), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 49, resizedFrame.h / 23);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2478 Drawing
        context.beginPath();
        context.moveTo(24.54, 6.49);
        context.bezierCurveTo(25.41, 6.49, 27.23, 6.99, 26.22, 8.37);
        context.bezierCurveTo(25.21, 9.75, 26.08, 10.68, 26.5, 11.32);
        context.bezierCurveTo(26.91, 11.96, 27.51, 14.17, 27.51, 14.17);
        context.bezierCurveTo(30.73, 21.59, 38.01, 20.16, 38.01, 20.16);
        context.bezierCurveTo(45.88, 18.55, 45.83, 10.91, 45.83, 8.93);
        context.bezierCurveTo(45.83, 6.95, 47.17, 6.12, 47.17, 6.12);
        context.lineTo(47.17, 4.09);
        context.bezierCurveTo(34.42, -1.62, 28.43, 5.06, 28.43, 5.06);
        context.bezierCurveTo(28.43, 5.06, 26.89, 3.68, 24.55, 3.72);
        context.bezierCurveTo(22.2, 3.68, 20.65, 5.06, 20.65, 5.06);
        context.bezierCurveTo(20.65, 5.06, 14.66, -1.62, 1.91, 4.09);
        context.lineTo(1.91, 6.12);
        context.bezierCurveTo(1.91, 6.12, 3.25, 6.95, 3.25, 8.93);
        context.bezierCurveTo(3.25, 10.91, 3.2, 18.55, 11.07, 20.16);
        context.bezierCurveTo(11.07, 20.16, 18.35, 21.59, 21.57, 14.17);
        context.bezierCurveTo(21.57, 14.17, 22.17, 11.96, 22.58, 11.32);
        context.bezierCurveTo(23, 10.68, 23.87, 9.75, 22.86, 8.37);
        context.bezierCurveTo(21.85, 6.99, 23.67, 6.49, 24.54, 6.49);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2479 Drawing
        context.beginPath();
        context.moveTo(24.54, 6.49);
        context.bezierCurveTo(25.41, 6.49, 27.23, 6.99, 26.22, 8.37);
        context.bezierCurveTo(25.21, 9.75, 26.08, 10.68, 26.5, 11.32);
        context.bezierCurveTo(26.91, 11.96, 27.51, 14.17, 27.51, 14.17);
        context.bezierCurveTo(30.73, 21.59, 38.01, 20.16, 38.01, 20.16);
        context.bezierCurveTo(45.88, 18.55, 45.83, 10.91, 45.83, 8.93);
        context.bezierCurveTo(45.83, 6.95, 47.17, 6.12, 47.17, 6.12);
        context.lineTo(47.17, 4.09);
        context.bezierCurveTo(34.42, -1.62, 28.43, 5.06, 28.43, 5.06);
        context.bezierCurveTo(28.43, 5.06, 26.89, 3.68, 24.55, 3.72);
        context.bezierCurveTo(22.2, 3.68, 20.65, 5.06, 20.65, 5.06);
        context.bezierCurveTo(20.65, 5.06, 14.66, -1.62, 1.91, 4.09);
        context.lineTo(1.91, 6.12);
        context.bezierCurveTo(1.91, 6.12, 3.25, 6.95, 3.25, 8.93);
        context.bezierCurveTo(3.25, 10.91, 3.2, 18.55, 11.07, 20.16);
        context.bezierCurveTo(11.07, 20.16, 18.35, 21.59, 21.57, 14.17);
        context.bezierCurveTo(21.57, 14.17, 22.17, 11.96, 22.58, 11.32);
        context.bezierCurveTo(23, 10.68, 23.87, 9.75, 22.86, 8.37);
        context.bezierCurveTo(21.85, 6.99, 23.67, 6.49, 24.54, 6.49);
        context.closePath();
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBackwardsCap(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 70, 50), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 70, resizedFrame.h / 50);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Rectangle 130 Drawing
        context.beginPath();
        context.rect(17.78, 38.78, 37.05, 3.9);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2619 Drawing
        context.beginPath();
        context.moveTo(21.7, 44.84);
        context.lineTo(21.28, 44.84);
        context.lineTo(21.28, 44.49);
        context.bezierCurveTo(21.28, 37.35, 20.64, 31.28, 28.83, 26.73);
        context.bezierCurveTo(32, 24.98, 35.97, 24.48, 39.52, 25.57);
        context.bezierCurveTo(39.68, 25.62, 39.84, 25.67, 40, 25.73);
        context.bezierCurveTo(51.06, 29.58, 50.32, 36.94, 50.31, 44.85);
        context.lineTo(49.89, 44.84);
        context.lineTo(65.24, 44.91);
        context.lineTo(66.12, 44.93);
        context.bezierCurveTo(66.13, 28.39, 67.68, 13.02, 44.58, 4.97);
        context.bezierCurveTo(44.25, 4.86, 43.91, 4.75, 43.58, 4.65);
        context.bezierCurveTo(36.15, 2.36, 27.87, 3.4, 21.25, 7.07);
        context.bezierCurveTo(4.14, 16.57, 5.47, 29.25, 5.47, 44.17);
        context.lineTo(5.48, 44.91);
        context.lineTo(6.35, 44.91);
        context.lineTo(21.28, 44.91);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2620 Drawing
        context.beginPath();
        context.moveTo(12.59, 45.15);
        context.bezierCurveTo(12.59, 8.22, 35.23, 4.65, 35.23, 4.65);
        context.bezierCurveTo(35.23, 4.65, 58.83, 7.9, 58.83, 44.99);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Oval 582 Drawing
        oval(context, 31.05, 2.48, 8.4, 2.9);
        context.fillStyle = AvatarStyleKit.col18;
        context.fill();


        //// Oval 583 Drawing
        oval(context, 31.05, 2.48, 8.4, 2.9);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawFreckles(canvas, skinColor, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 49, 23), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 49, resizedFrame.h / 23);


        //// Color Declarations
        var skin5Secondary = 'rgba(185, 146, 111, 1)';

        //// Variable Declarations
        var _skinColor = skinColor === 'skin2' ? AvatarStyleKit.skin2 : (skinColor === 'skin3' ? AvatarStyleKit.skin3 : (skinColor === 'skin4' ? AvatarStyleKit.skin4 : (skinColor === 'skin5' ? AvatarStyleKit.skin5 : (skinColor === 'skin6' ? AvatarStyleKit.skin6 : (skinColor === 'skin7' ? AvatarStyleKit.skin7 : (skinColor === 'skin8' ? AvatarStyleKit.skin8 : (skinColor === 'skin9' ? AvatarStyleKit.skin9 : AvatarStyleKit.skin1)))))));
        var _noseColor = _skinColor === AvatarStyleKit.skin1 ? AvatarStyleKit.skin1Secondary : (_skinColor === AvatarStyleKit.skin2 ? AvatarStyleKit.skin2Secondary : (_skinColor === AvatarStyleKit.skin3 ? AvatarStyleKit.skin3Secondary : (_skinColor === AvatarStyleKit.skin4 ? AvatarStyleKit.skin4Secondary : (_skinColor === AvatarStyleKit.skin5 ? skin5Secondary : (_skinColor === AvatarStyleKit.skin6 ? AvatarStyleKit.skin6Secondary : (_skinColor === AvatarStyleKit.skin7 ? AvatarStyleKit.skin7Secondary : (_skinColor === AvatarStyleKit.skin8 ? AvatarStyleKit.skin8Secondary : AvatarStyleKit.skin9Secondary)))))));

        //// Bezier 2514 Drawing
        context.beginPath();
        context.moveTo(35.77, 10.83);
        context.bezierCurveTo(35.77, 11.06, 35.58, 11.25, 35.34, 11.25);
        context.bezierCurveTo(35.11, 11.25, 34.92, 11.06, 34.92, 10.83);
        context.bezierCurveTo(34.92, 10.59, 35.11, 10.4, 35.34, 10.4);
        context.bezierCurveTo(35.58, 10.4, 35.77, 10.59, 35.77, 10.83);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2515 Drawing
        context.beginPath();
        context.moveTo(38.66, 8.4);
        context.bezierCurveTo(38.66, 8.63, 38.47, 8.82, 38.23, 8.82);
        context.bezierCurveTo(38, 8.82, 37.81, 8.63, 37.81, 8.4);
        context.bezierCurveTo(37.81, 8.16, 38, 7.97, 38.23, 7.97);
        context.bezierCurveTo(38.47, 7.97, 38.66, 8.16, 38.66, 8.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2516 Drawing
        context.beginPath();
        context.moveTo(37.66, 13.4);
        context.bezierCurveTo(37.66, 13.63, 37.47, 13.82, 37.23, 13.82);
        context.bezierCurveTo(37, 13.82, 36.81, 13.63, 36.81, 13.4);
        context.bezierCurveTo(36.81, 13.16, 37, 12.97, 37.23, 12.97);
        context.bezierCurveTo(37.47, 12.97, 37.66, 13.16, 37.66, 13.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2517 Drawing
        context.beginPath();
        context.moveTo(32.66, 13.25);
        context.bezierCurveTo(32.66, 13.49, 32.47, 13.68, 32.23, 13.68);
        context.bezierCurveTo(32, 13.68, 31.81, 13.49, 31.81, 13.25);
        context.bezierCurveTo(31.81, 13.02, 32, 12.82, 32.23, 12.82);
        context.bezierCurveTo(32.47, 12.82, 32.66, 13.02, 32.66, 13.25);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2518 Drawing
        context.beginPath();
        context.moveTo(31.89, 9.72);
        context.bezierCurveTo(31.89, 9.95, 31.7, 10.14, 31.46, 10.14);
        context.bezierCurveTo(31.23, 10.14, 31.04, 9.95, 31.04, 9.72);
        context.bezierCurveTo(31.04, 9.48, 31.23, 9.29, 31.46, 9.29);
        context.bezierCurveTo(31.7, 9.29, 31.89, 9.48, 31.89, 9.72);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2519 Drawing
        context.beginPath();
        context.moveTo(28.66, 8.4);
        context.bezierCurveTo(28.66, 8.63, 28.47, 8.82, 28.23, 8.82);
        context.bezierCurveTo(28, 8.82, 27.81, 8.63, 27.81, 8.4);
        context.bezierCurveTo(27.81, 8.16, 28, 7.97, 28.23, 7.97);
        context.bezierCurveTo(28.47, 7.97, 28.66, 8.16, 28.66, 8.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2520 Drawing
        context.beginPath();
        context.moveTo(35.23, 15.25);
        context.bezierCurveTo(35.23, 15.49, 35.04, 15.68, 34.8, 15.68);
        context.bezierCurveTo(34.57, 15.68, 34.38, 15.49, 34.38, 15.25);
        context.bezierCurveTo(34.38, 15.02, 34.57, 14.82, 34.8, 14.82);
        context.bezierCurveTo(35.04, 14.82, 35.23, 15.02, 35.23, 15.25);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2521 Drawing
        context.beginPath();
        context.moveTo(40.55, 11.95);
        context.bezierCurveTo(40.55, 12.19, 40.36, 12.38, 40.13, 12.38);
        context.bezierCurveTo(39.89, 12.38, 39.7, 12.19, 39.7, 11.95);
        context.bezierCurveTo(39.7, 11.72, 39.89, 11.53, 40.13, 11.53);
        context.bezierCurveTo(40.36, 11.53, 40.55, 11.72, 40.55, 11.95);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2522 Drawing
        context.beginPath();
        context.moveTo(13.59, 10.83);
        context.bezierCurveTo(13.59, 11.06, 13.78, 11.25, 14.02, 11.25);
        context.bezierCurveTo(14.25, 11.25, 14.44, 11.06, 14.44, 10.83);
        context.bezierCurveTo(14.44, 10.59, 14.25, 10.4, 14.02, 10.4);
        context.bezierCurveTo(13.78, 10.4, 13.59, 10.59, 13.59, 10.83);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2523 Drawing
        context.beginPath();
        context.moveTo(10.7, 8.4);
        context.bezierCurveTo(10.7, 8.63, 10.89, 8.82, 11.13, 8.82);
        context.bezierCurveTo(11.36, 8.82, 11.55, 8.63, 11.55, 8.4);
        context.bezierCurveTo(11.55, 8.16, 11.36, 7.97, 11.13, 7.97);
        context.bezierCurveTo(10.89, 7.97, 10.7, 8.16, 10.7, 8.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2524 Drawing
        context.beginPath();
        context.moveTo(11.7, 13.4);
        context.bezierCurveTo(11.7, 13.63, 11.89, 13.82, 12.13, 13.82);
        context.bezierCurveTo(12.36, 13.82, 12.55, 13.63, 12.55, 13.4);
        context.bezierCurveTo(12.55, 13.16, 12.36, 12.97, 12.13, 12.97);
        context.bezierCurveTo(11.89, 12.97, 11.7, 13.16, 11.7, 13.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2525 Drawing
        context.beginPath();
        context.moveTo(16.7, 13.25);
        context.bezierCurveTo(16.7, 13.49, 16.89, 13.68, 17.13, 13.68);
        context.bezierCurveTo(17.36, 13.68, 17.55, 13.49, 17.55, 13.25);
        context.bezierCurveTo(17.55, 13.02, 17.36, 12.82, 17.13, 12.82);
        context.bezierCurveTo(16.89, 12.82, 16.7, 13.02, 16.7, 13.25);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2526 Drawing
        context.beginPath();
        context.moveTo(17.47, 9.72);
        context.bezierCurveTo(17.47, 9.95, 17.66, 10.14, 17.89, 10.14);
        context.bezierCurveTo(18.13, 10.14, 18.32, 9.95, 18.32, 9.72);
        context.bezierCurveTo(18.32, 9.48, 18.13, 9.29, 17.89, 9.29);
        context.bezierCurveTo(17.66, 9.29, 17.47, 9.48, 17.47, 9.72);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2527 Drawing
        context.beginPath();
        context.moveTo(20.7, 8.4);
        context.bezierCurveTo(20.7, 8.63, 20.89, 8.82, 21.13, 8.82);
        context.bezierCurveTo(21.36, 8.82, 21.55, 8.63, 21.55, 8.4);
        context.bezierCurveTo(21.55, 8.16, 21.36, 7.97, 21.13, 7.97);
        context.bezierCurveTo(20.89, 7.97, 20.7, 8.16, 20.7, 8.4);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2528 Drawing
        context.beginPath();
        context.moveTo(14.13, 15.25);
        context.bezierCurveTo(14.13, 15.49, 14.32, 15.68, 14.55, 15.68);
        context.bezierCurveTo(14.79, 15.68, 14.98, 15.49, 14.98, 15.25);
        context.bezierCurveTo(14.98, 15.02, 14.79, 14.82, 14.55, 14.82);
        context.bezierCurveTo(14.32, 14.82, 14.13, 15.02, 14.13, 15.25);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();


        //// Bezier 2529 Drawing
        context.beginPath();
        context.moveTo(8.81, 11.95);
        context.bezierCurveTo(8.81, 12.19, 9, 12.38, 9.23, 12.38);
        context.bezierCurveTo(9.47, 12.38, 9.66, 12.19, 9.66, 11.95);
        context.bezierCurveTo(9.66, 11.72, 9.47, 11.53, 9.23, 11.53);
        context.bezierCurveTo(9, 11.53, 8.81, 11.72, 8.81, 11.95);
        context.closePath();
        context.fillStyle = _noseColor;
        context.fill();
        
        context.restore();

    }

    function drawLongMustache(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 67, 11), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 67, resizedFrame.h / 11);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor53 = 'rgba(95, 56, 19, 1)';

        //// Bezier 1924 Drawing
        context.beginPath();
        context.moveTo(32.79, 3.58);
        context.bezierCurveTo(32.79, 3.58, 31.2, 0.48, 21.33, 2.66);
        context.bezierCurveTo(11.46, 4.84, 9.42, 8.06, 3.03, 4.69);
        context.bezierCurveTo(3.03, 4.69, 6.23, 10.07, 17.84, 7.31);
        context.bezierCurveTo(17.84, 7.31, 22.05, 5.56, 25.97, 5.56);
        context.bezierCurveTo(29.89, 5.56, 33.08, 5.83, 32.79, 3.58);
        context.closePath();
        context.fillStyle = fillColor53;
        context.fill();


        //// Bezier 1925 Drawing
        context.beginPath();
        context.moveTo(32.79, 3.58);
        context.bezierCurveTo(32.79, 3.58, 31.2, 0.48, 21.33, 2.66);
        context.bezierCurveTo(11.46, 4.84, 9.42, 8.06, 3.03, 4.69);
        context.bezierCurveTo(3.03, 4.69, 6.23, 10.07, 17.84, 7.31);
        context.bezierCurveTo(17.84, 7.31, 22.05, 5.56, 25.97, 5.56);
        context.bezierCurveTo(29.89, 5.56, 33.08, 5.83, 32.79, 3.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 1926 Drawing
        context.beginPath();
        context.moveTo(35.05, 3.58);
        context.bezierCurveTo(35.05, 3.58, 36.65, 0.48, 46.52, 2.66);
        context.bezierCurveTo(56.39, 4.84, 58.42, 8.06, 64.81, 4.69);
        context.bezierCurveTo(64.81, 4.69, 61.62, 10.07, 50.01, 7.31);
        context.bezierCurveTo(50.01, 7.31, 45.8, 5.56, 41.88, 5.56);
        context.bezierCurveTo(37.96, 5.56, 34.76, 5.83, 35.05, 3.58);
        context.closePath();
        context.fillStyle = fillColor53;
        context.fill();


        //// Bezier 1927 Drawing
        context.beginPath();
        context.moveTo(35.05, 3.58);
        context.bezierCurveTo(35.05, 3.58, 36.65, 0.48, 46.52, 2.66);
        context.bezierCurveTo(56.39, 4.84, 58.42, 8.06, 64.81, 4.69);
        context.bezierCurveTo(64.81, 4.69, 61.62, 10.07, 50.01, 7.31);
        context.bezierCurveTo(50.01, 7.31, 45.8, 5.56, 41.88, 5.56);
        context.bezierCurveTo(37.96, 5.56, 34.76, 5.83, 35.05, 3.58);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawKippah(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 32, 13), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 32, resizedFrame.h / 13);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2513 Drawing
        context.beginPath();
        context.moveTo(16.2, 1.9);
        context.bezierCurveTo(16.2, 1.9, 27.98, 2.08, 29.28, 9.16);
        context.bezierCurveTo(29.28, 9.16, 20.92, 7.9, 16.32, 7.84);
        context.bezierCurveTo(11.72, 7.9, 3.2, 9.16, 3.2, 9.16);
        context.bezierCurveTo(5.32, 1.94, 16.28, 1.9, 16.28, 1.9);
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawLinesGlasses(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 58, 23), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 58, resizedFrame.h / 23);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2498 Drawing
        context.beginPath();
        context.moveTo(31.2, 3.31);
        context.bezierCurveTo(31.2, 3.31, 31.09, 20.25, 43.5, 20.25);
        context.bezierCurveTo(55.92, 20.25, 56.36, 3.31, 56.36, 3.31);
        context.lineTo(1.6, 3.31);
        context.bezierCurveTo(1.6, 3.31, -0.19, 20.2, 14.29, 20.2);
        context.bezierCurveTo(28.77, 20.2, 26.65, 3.42, 26.65, 3.42);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2499 Drawing
        context.beginPath();
        context.moveTo(1.27, 6.06);
        context.lineTo(26.43, 6.06);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2500 Drawing
        context.beginPath();
        context.moveTo(31.27, 6.11);
        context.lineTo(56.43, 6.11);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2501 Drawing
        context.beginPath();
        context.moveTo(1.27, 9.06);
        context.lineTo(26.43, 9.06);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2502 Drawing
        context.beginPath();
        context.moveTo(31.96, 9.11);
        context.lineTo(55.43, 9.11);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2503 Drawing
        context.beginPath();
        context.moveTo(2.74, 12.06);
        context.lineTo(25.56, 12.06);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2504 Drawing
        context.beginPath();
        context.moveTo(32.83, 12.11);
        context.lineTo(54.32, 12.11);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2505 Drawing
        context.beginPath();
        context.moveTo(4.15, 15.06);
        context.lineTo(24.48, 15.06);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2506 Drawing
        context.beginPath();
        context.moveTo(34.41, 15.11);
        context.lineTo(52.62, 15.11);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2507 Drawing
        context.beginPath();
        context.moveTo(6.89, 18.06);
        context.lineTo(21.65, 18.06);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2508 Drawing
        context.beginPath();
        context.moveTo(36.95, 18.11);
        context.lineTo(50.08, 18.11);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawSantaHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 73, 73), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 73, resizedFrame.h / 73);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor28 = 'rgba(235, 46, 49, 1)';
        var fillColor61 = 'rgba(230, 230, 230, 1)';

        //// Bezier 2509 Drawing
        context.beginPath();
        context.moveTo(7.08, 51.61);
        context.bezierCurveTo(6.78, 51.55, 22.66, 23.62, 31.53, 8.07);
        context.bezierCurveTo(34.54, 2.79, 43.04, 0.93, 49.22, 4.19);
        context.lineTo(56.72, 8.14);
        context.bezierCurveTo(57.35, 8.47, 57.17, 9.22, 56.43, 9.36);
        context.lineTo(46.94, 11.19);
        context.bezierCurveTo(45.89, 11.39, 45.34, 12.26, 45.76, 13.02);
        context.lineTo(67.09, 51.23);
        context.bezierCurveTo(67.09, 51.23, 39.02, 57.66, 7.08, 51.61);
        context.closePath();
        context.fillStyle = fillColor28;
        context.fill();


        //// Bezier 2510 Drawing
        context.beginPath();
        context.moveTo(7.08, 51.61);
        context.bezierCurveTo(6.78, 51.55, 22.66, 23.62, 31.53, 8.07);
        context.bezierCurveTo(34.54, 2.79, 43.04, 0.93, 49.22, 4.19);
        context.lineTo(56.72, 8.14);
        context.bezierCurveTo(57.35, 8.47, 57.17, 9.22, 56.43, 9.36);
        context.lineTo(46.94, 11.19);
        context.bezierCurveTo(45.89, 11.39, 45.34, 12.26, 45.76, 13.02);
        context.lineTo(67.09, 51.23);
        context.bezierCurveTo(67.09, 51.23, 39.02, 57.66, 7.08, 51.61);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.37;
        context.stroke();


        //// Bezier 2511 Drawing
        context.beginPath();
        context.moveTo(7.04, 51.45);
        context.lineTo(7.04, 68.9);
        context.bezierCurveTo(7.04, 68.9, 33.8, 58.74, 67.41, 68.9);
        context.lineTo(67.41, 56.83);
        context.lineTo(67.41, 51.45);
        context.bezierCurveTo(67.41, 51.45, 39.85, 41.4, 7.04, 51.45);
        context.closePath();
        context.fillStyle = fillColor61;
        context.fill();


        //// Bezier 2512 Drawing
        context.beginPath();
        context.moveTo(7.04, 51.45);
        context.lineTo(7.04, 68.9);
        context.bezierCurveTo(7.04, 68.9, 33.8, 58.74, 67.41, 68.9);
        context.lineTo(67.41, 56.83);
        context.lineTo(67.41, 51.45);
        context.bezierCurveTo(67.41, 51.45, 39.85, 41.4, 7.04, 51.45);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.14;
        context.stroke();


        //// Oval 550 Drawing
        oval(context, 50.15, 2.84, 14.2, 12.6);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();


        //// Oval 551 Drawing
        oval(context, 50.15, 2.84, 14.2, 12.6);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.27;
        context.stroke();
        
        context.restore();

    }

    function drawChefHat(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 101, 73), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 101, resizedFrame.h / 73);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor61 = 'rgba(230, 230, 230, 1)';

        //// Bezier 2456 Drawing
        context.beginPath();
        context.moveTo(84.51, 55.68);
        context.bezierCurveTo(84.51, 55.68, 96.01, 56.26, 98.18, 45.01);
        context.bezierCurveTo(100.35, 33.76, 88.59, 30.91, 88.59, 30.91);
        context.bezierCurveTo(88.59, 30.91, 90.09, 14.42, 71.67, 10.17);
        context.bezierCurveTo(71.67, 10.17, 60.67, 8.01, 50.17, 15.26);
        context.bezierCurveTo(50.17, 15.26, 39.92, -1.16, 23.75, 6.59);
        context.bezierCurveTo(23.75, 6.59, 12.08, 10.42, 11, 27.59);
        context.bezierCurveTo(11, 27.59, 0.83, 30.34, 2.42, 44.93);
        context.bezierCurveTo(2.42, 44.93, 4.33, 55.75, 15.67, 56.35);
        context.fillStyle = AvatarStyleKit.col14;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2457 Drawing
        context.beginPath();
        context.moveTo(15.5, 50.78);
        context.lineTo(15.5, 68.24);
        context.bezierCurveTo(15.5, 68.24, 46.09, 58.07, 84.51, 68.24);
        context.lineTo(84.51, 56.16);
        context.lineTo(84.51, 50.78);
        context.bezierCurveTo(84.51, 50.78, 53.01, 40.73, 15.5, 50.78);
        context.closePath();
        context.fillStyle = fillColor61;
        context.fill();


        //// Bezier 2458 Drawing
        context.beginPath();
        context.moveTo(15.5, 50.78);
        context.lineTo(15.5, 68.24);
        context.bezierCurveTo(15.5, 68.24, 46.09, 58.07, 84.51, 68.24);
        context.lineTo(84.51, 56.16);
        context.lineTo(84.51, 50.78);
        context.bezierCurveTo(84.51, 50.78, 53.01, 40.73, 15.5, 50.78);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2459 Drawing
        context.beginPath();
        context.moveTo(30.33, 29.55);
        context.lineTo(36.1, 46.84);
        context.fillStyle = fillColor61;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2460 Drawing
        context.beginPath();
        context.moveTo(51.39, 29.99);
        context.lineTo(51.39, 46.4);
        context.fillStyle = fillColor61;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2461 Drawing
        context.beginPath();
        context.moveTo(64.47, 46.84);
        context.bezierCurveTo(64.92, 46.18, 71.79, 29.99, 71.79, 29.99);
        context.fillStyle = fillColor61;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    function drawBeeWings(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 101, 73), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 101, resizedFrame.h / 73);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2642 Drawing
        context.beginPath();
        context.moveTo(56.13, 36.23);
        context.bezierCurveTo(65.59, 33.98, 73.21, 26.43, 76.33, 16.78);
        context.bezierCurveTo(82.54, 16.28, 88.27, 18.61, 91.07, 23.46);
        context.bezierCurveTo(93.59, 27.83, 93.17, 33.23, 90.48, 38);
        context.bezierCurveTo(92.38, 39.9, 93.53, 42.3, 93.53, 44.91);
        context.bezierCurveTo(93.53, 51.03, 87.3, 55.98, 79.63, 55.98);
        context.bezierCurveTo(79.12, 55.98, 78.63, 55.96, 78.14, 55.91);
        context.bezierCurveTo(75.71, 43.06, 63.69, 40.96, 59.52, 40.25);
        context.lineTo(59.53, 40.11);
        context.bezierCurveTo(56.82, 38.76, 57.23, 37.05, 57.83, 35.98);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 58
        context.save();

        //// Clip Clip 56

        context.beginPath();
        context.moveTo(76.33, 16.78);
        context.bezierCurveTo(73.21, 26.43, 65.59, 33.98, 56.12, 36.23);
        context.lineTo(56.12, 36.23);
        context.lineTo(57.83, 35.98);
        context.bezierCurveTo(57.24, 37.05, 56.82, 38.76, 59.53, 40.11);
        context.lineTo(59.53, 40.11);
        context.lineTo(59.52, 40.25);
        context.bezierCurveTo(63.69, 40.96, 75.71, 43.06, 78.14, 55.92);
        context.lineTo(78.14, 55.92);
        context.bezierCurveTo(78.63, 55.96, 79.12, 55.99, 79.63, 55.99);
        context.lineTo(79.63, 55.99);
        context.bezierCurveTo(87.3, 55.99, 93.53, 51.03, 93.53, 44.92);
        context.lineTo(93.53, 44.92);
        context.bezierCurveTo(93.53, 42.3, 92.38, 39.9, 90.47, 38);
        context.lineTo(90.47, 38);
        context.bezierCurveTo(93.17, 33.23, 93.59, 27.83, 91.07, 23.46);
        context.lineTo(91.07, 23.46);
        context.bezierCurveTo(88.52, 19.05, 83.55, 16.72, 77.99, 16.72);
        context.lineTo(77.99, 16.72);
        context.bezierCurveTo(77.44, 16.72, 76.89, 16.74, 76.33, 16.78);
        context.closePath();
        context.clip();



        //// Bezier 2643 Drawing
        context.beginPath();
        context.moveTo(52.84, 0.42);
        context.lineTo(95.4, 34.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2644 Drawing
        context.beginPath();
        context.moveTo(52.84, 10.42);
        context.lineTo(95.4, 44.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2645 Drawing
        context.beginPath();
        context.moveTo(52.84, 20.42);
        context.lineTo(95.4, 54.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2646 Drawing
        context.beginPath();
        context.moveTo(52.84, 30.42);
        context.lineTo(95.4, 64.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2647 Drawing
        context.beginPath();
        context.moveTo(52.84, 40.42);
        context.lineTo(95.4, 74.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2648 Drawing
        context.beginPath();
        context.moveTo(95.4, 0.42);
        context.lineTo(52.84, 34.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2649 Drawing
        context.beginPath();
        context.moveTo(95.4, 10.42);
        context.lineTo(52.84, 44.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2650 Drawing
        context.beginPath();
        context.moveTo(95.4, 20.42);
        context.lineTo(52.84, 54.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2651 Drawing
        context.beginPath();
        context.moveTo(95.4, 30.42);
        context.lineTo(52.84, 64.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2652 Drawing
        context.beginPath();
        context.moveTo(95.4, 40.42);
        context.lineTo(52.84, 74.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        context.restore();



        //// Bezier 2654 Drawing
        context.beginPath();
        context.moveTo(44.53, 36.23);
        context.bezierCurveTo(35.07, 33.98, 27.44, 26.43, 24.32, 16.78);
        context.bezierCurveTo(18.11, 16.28, 12.39, 18.61, 9.59, 23.46);
        context.bezierCurveTo(7.07, 27.83, 7.48, 33.23, 10.18, 38);
        context.bezierCurveTo(8.27, 39.9, 7.13, 42.3, 7.13, 44.91);
        context.bezierCurveTo(7.13, 51.03, 13.35, 55.98, 21.02, 55.98);
        context.bezierCurveTo(21.53, 55.98, 22.02, 55.96, 22.51, 55.91);
        context.bezierCurveTo(24.94, 43.06, 36.96, 40.96, 41.13, 40.25);
        context.lineTo(41.12, 40.11);
        context.bezierCurveTo(43.83, 38.76, 43.42, 37.05, 42.82, 35.98);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Group 59
        context.save();

        //// Clip Clip 57

        context.beginPath();
        context.moveTo(9.59, 23.46);
        context.bezierCurveTo(7.07, 27.83, 7.48, 33.23, 10.18, 38);
        context.lineTo(10.18, 38);
        context.bezierCurveTo(8.27, 39.9, 7.12, 42.3, 7.12, 44.92);
        context.lineTo(7.12, 44.92);
        context.bezierCurveTo(7.12, 51.03, 13.35, 55.99, 21.03, 55.99);
        context.lineTo(21.03, 55.99);
        context.bezierCurveTo(21.53, 55.99, 22.02, 55.96, 22.51, 55.92);
        context.lineTo(22.51, 55.92);
        context.bezierCurveTo(24.94, 43.06, 36.96, 40.96, 41.13, 40.25);
        context.lineTo(41.13, 40.25);
        context.lineTo(41.12, 40.11);
        context.bezierCurveTo(43.83, 38.76, 43.42, 37.05, 42.82, 35.98);
        context.lineTo(42.82, 35.98);
        context.lineTo(44.53, 36.23);
        context.bezierCurveTo(35.07, 33.98, 27.44, 26.43, 24.32, 16.78);
        context.lineTo(24.32, 16.78);
        context.bezierCurveTo(23.77, 16.74, 23.21, 16.72, 22.66, 16.72);
        context.lineTo(22.66, 16.72);
        context.bezierCurveTo(17.11, 16.72, 12.14, 19.05, 9.59, 23.46);
        context.closePath();
        context.clip();



        //// Bezier 2655 Drawing
        context.beginPath();
        context.moveTo(47.81, 0.42);
        context.lineTo(5.25, 34.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2656 Drawing
        context.beginPath();
        context.moveTo(47.81, 10.42);
        context.lineTo(5.25, 44.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2657 Drawing
        context.beginPath();
        context.moveTo(47.81, 20.42);
        context.lineTo(5.25, 54.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2658 Drawing
        context.beginPath();
        context.moveTo(47.81, 30.42);
        context.lineTo(5.25, 64.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2659 Drawing
        context.beginPath();
        context.moveTo(47.81, 40.42);
        context.lineTo(5.25, 74.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2660 Drawing
        context.beginPath();
        context.moveTo(5.25, 0.42);
        context.lineTo(47.81, 34.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2661 Drawing
        context.beginPath();
        context.moveTo(5.25, 10.42);
        context.lineTo(47.81, 44.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2662 Drawing
        context.beginPath();
        context.moveTo(5.25, 20.42);
        context.lineTo(47.81, 54.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2663 Drawing
        context.beginPath();
        context.moveTo(5.25, 30.42);
        context.lineTo(47.81, 64.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        //// Bezier 2664 Drawing
        context.beginPath();
        context.moveTo(5.25, 40.42);
        context.lineTo(47.81, 74.68);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.28;
        context.stroke();


        context.restore();
        
        context.restore();

    }

    function drawBeeHeadband(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 101, 73), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 101, resizedFrame.h / 73);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';

        //// Bezier 2638 Drawing
        context.beginPath();
        context.moveTo(50.31, 34.95);
        context.lineTo(50.31, 34.92);
        context.bezierCurveTo(36.7, 34.92, 26.25, 40.07, 15.86, 50.33);
        context.bezierCurveTo(15.38, 56.46, 15.55, 55.7, 15.55, 62.06);
        context.lineTo(15.56, 62.06);
        context.bezierCurveTo(26.06, 51.55, 36.6, 46.3, 50.37, 46.3);
        context.lineTo(50.37, 46.33);
        context.bezierCurveTo(64.14, 46.33, 74.15, 51.58, 84.65, 62.09);
        context.lineTo(84.66, 62.09);
        context.bezierCurveTo(84.66, 55.85, 84.74, 56.61, 84.36, 50.49);
        context.bezierCurveTo(73.94, 40.13, 63.98, 34.95, 50.31, 34.95);
        context.closePath();
        context.fillStyle = AvatarStyleKit.hair21;
        context.fill();


        //// Bezier 2639 Drawing
        context.beginPath();
        context.moveTo(50.31, 34.95);
        context.lineTo(50.31, 34.92);
        context.bezierCurveTo(36.7, 34.92, 26.25, 40.07, 15.86, 50.33);
        context.bezierCurveTo(15.38, 56.46, 15.55, 55.7, 15.55, 62.06);
        context.lineTo(15.56, 62.06);
        context.bezierCurveTo(26.06, 51.55, 36.6, 46.3, 50.37, 46.3);
        context.lineTo(50.37, 46.33);
        context.bezierCurveTo(64.14, 46.33, 74.15, 51.58, 84.65, 62.09);
        context.lineTo(84.66, 62.09);
        context.bezierCurveTo(84.66, 55.85, 84.74, 56.61, 84.36, 50.49);
        context.bezierCurveTo(73.94, 40.13, 63.98, 34.95, 50.31, 34.95);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2640 Drawing
        context.beginPath();
        context.moveTo(21.18, 14.77);
        context.bezierCurveTo(21.18, 14.77, 41.63, 22.42, 41.63, 42.04);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 610 Drawing
        oval(context, 16.95, 10.54, 8.5, 8.5);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Oval 611 Drawing
        oval(context, 16.95, 10.54, 8.5, 8.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Bezier 2641 Drawing
        context.beginPath();
        context.moveTo(79.36, 14.77);
        context.bezierCurveTo(79.36, 14.77, 58.91, 22.42, 58.91, 42.04);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();


        //// Oval 612 Drawing
        oval(context, 75.1, 10.54, 8.5, 8.5);
        context.fillStyle = AvatarStyleKit.hair16;
        context.fill();


        //// Oval 613 Drawing
        oval(context, 75.1, 10.54, 8.5, 8.5);
        context.strokeStyle = strokeColor;
        context.lineWidth = 1.12;
        context.stroke();
        
        context.restore();

    }

    function drawFaceWand(canvas, targetFrame, resizing) {
        //// General Declarations
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        var context = canvas.getContext('2d');
        var pixelRatio = canvas.paintCodePixelRatio;
        
        //// Resize to Target Frame
        context.save();
        var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 31, 49), targetFrame);
        context.translate(resizedFrame.x, resizedFrame.y);
        context.scale(resizedFrame.w / 31, resizedFrame.h / 49);


        //// Color Declarations
        var strokeColor = 'rgba(0, 0, 0, 1)';
        var fillColor7 = 'rgba(248, 218, 37, 1)';

        //// Bezier 2634 Drawing
        context.beginPath();
        context.moveTo(5.6, 41.25);
        context.lineTo(7.21, 41.25);
        context.lineTo(16.15, 18.05);
        context.lineTo(14.76, 17.51);
        context.lineTo(5.6, 41.25);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2635 Drawing
        context.beginPath();
        context.moveTo(5.6, 41.25);
        context.lineTo(7.21, 41.25);
        context.lineTo(16.15, 18.05);
        context.lineTo(14.76, 17.51);
        context.lineTo(5.6, 41.25);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();


        //// Bezier 2636 Drawing
        context.beginPath();
        context.moveTo(15.29, 18.26);
        context.lineTo(9.65, 19.5);
        context.lineTo(12.86, 14.64);
        context.lineTo(10.17, 9.89);
        context.lineTo(15.36, 11.4);
        context.lineTo(19.47, 7.41);
        context.lineTo(19.85, 13.13);
        context.lineTo(24.71, 15.49);
        context.lineTo(19.4, 17.51);
        context.lineTo(18.64, 22.96);
        context.lineTo(15.29, 18.26);
        context.closePath();
        context.fillStyle = fillColor7;
        context.fill();


        //// Bezier 2637 Drawing
        context.beginPath();
        context.moveTo(15.29, 18.26);
        context.lineTo(9.65, 19.5);
        context.lineTo(12.86, 14.64);
        context.lineTo(10.17, 9.89);
        context.lineTo(15.36, 11.4);
        context.lineTo(19.47, 7.41);
        context.lineTo(19.85, 13.13);
        context.lineTo(24.71, 15.49);
        context.lineTo(19.4, 17.51);
        context.lineTo(18.64, 22.96);
        context.lineTo(15.29, 18.26);
        context.closePath();
        context.strokeStyle = strokeColor;
        context.lineWidth = 1;
        context.stroke();
        
        context.restore();

    }

    //// Infrastructure

    function clearCanvas(canvas) {
        canvas = initializeCanvas(typeof canvas === 'string' ? document.getElementById(canvas) : canvas);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    // Possible arguments for 'resizing' parameter are:
    //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
    //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
    //   'stretch': The content is stretched to match the entire target rectangle.
    //   'center': The content is centered in the target rectangle, but it is NOT resized.
    function applyResizingBehavior(resizing, rect, targetRect) {
        if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
            return rect;
        }

        var scales = makeSize(0, 0);
        scales.w = Math.abs(targetRect.w / rect.w);
        scales.h = Math.abs(targetRect.h / rect.h);

        switch (resizing) {
            case 'aspectfit': {
                scales.w = Math.min(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'aspectfill': {
                scales.w = Math.max(scales.w, scales.h);
                scales.h = scales.w;
                break;
            }
            case 'stretch':
            case undefined:
                break;
            case 'center': {
                scales.w = 1;
                scales.h = 1;
                break;
            }
            default:
                throw 'Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.';
        }

        var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h));
        result.w *= scales.w;
        result.h *= scales.h;
        result.x = targetRect.x + (targetRect.w - result.w) / 2;
        result.y = targetRect.y + (targetRect.h - result.h) / 2;
        return result;
    }

    function oval(context, x, y, w, h) {
        context.save();
        context.beginPath();
        context.translate(x, y);
        context.scale(w/2, h/2);
        context.arc(1, 1, 1, 0, 2*Math.PI, false);
        context.closePath();
        context.restore();
    }

    function roundedRect(context, x, y, w, h, r) {
        context.beginPath();
        context.arc(x+r, y+r, r, Math.PI, 1.5*Math.PI);
        context.arc(x+w-r, y+r, r, 1.5*Math.PI, 2*Math.PI);
        context.arc(x+w-r, y+h-r, r, 0, 0.5*Math.PI);
        context.arc(x+r, y+h-r, r, 0.5*Math.PI, Math.PI);
        context.closePath();
    }

    function makeRect(x, y, w, h) {
        return { x: x, y: y, w: w, h: h };
    }

    function equalRects(r1, r2) {
        return r1.x === r2.x && r1.y === r2.y && r1.w == r2.w && r1.h === r2.h;
    }

    function makeSize(w, h) {
        return { w: w, h: h };
    }

    function initializeCanvas(canvas) {
        if ('paintCodePixelRatio' in canvas) return canvas;
        // This function should only be called once on each canvas.
        var context = canvas.getContext('2d');

        var devicePixelRatio = window.devicePixelRatio || 1;
        var backingStorePixelRatio = context.webkitBackingStorePixelRatio
            || context.mozBackingStorePixelRatio
            || context.msBackingStorePixelRatio
            || context.oBackingStorePixelRatio
            || context.backingStorePixelRatio
            || 1;

        var pixelRatio = devicePixelRatio / backingStorePixelRatio;

        canvas.style.width = canvas.width + 'px';
        canvas.style.height = canvas.height + 'px';
        canvas.width *= pixelRatio;
        canvas.height *= pixelRatio;
        canvas.paintCodePixelRatio = pixelRatio;

        context.scale(pixelRatio, pixelRatio);
        return canvas;
    }

    //// Public Interface

    // Colors
    AvatarStyleKit.col1 = 'rgba(26, 84, 250, 1)';
    AvatarStyleKit.col2 = 'rgba(105, 205, 177, 1)';
    AvatarStyleKit.col3 = 'rgba(242, 171, 48, 1)';
    AvatarStyleKit.col4 = 'rgba(0, 167, 243, 1)';
    AvatarStyleKit.col5 = 'rgba(242, 121, 167, 1)';
    AvatarStyleKit.col6 = 'rgba(0, 120, 0, 1)';
    AvatarStyleKit.col7 = 'rgba(109, 209, 48, 1)';
    AvatarStyleKit.col8 = 'rgba(248, 218, 37, 1)';
    AvatarStyleKit.col9 = 'rgba(242, 68, 38, 1)';
    AvatarStyleKit.col10 = 'rgba(87, 87, 86, 1)';
    AvatarStyleKit.col11 = 'rgba(235, 72, 99, 1)';
    AvatarStyleKit.col12 = 'rgba(198, 198, 198, 1)';
    AvatarStyleKit.col13 = 'rgba(127, 89, 29, 1)';
    AvatarStyleKit.col14 = 'rgba(255, 255, 255, 1)';
    AvatarStyleKit.col15 = 'rgba(246, 230, 82, 1)';
    AvatarStyleKit.col16 = 'rgba(161, 48, 215, 1)';
    AvatarStyleKit.col17 = 'rgba(164, 159, 244, 1)';
    AvatarStyleKit.col18 = 'rgba(157, 157, 156, 1)';
    AvatarStyleKit.col19 = 'rgba(25, 26, 26, 1)';
    AvatarStyleKit.col20 = 'rgba(255, 216, 244, 1)';
    AvatarStyleKit.skin1 = 'rgba(242, 229, 201, 1)';
    AvatarStyleKit.skin2 = 'rgba(230, 209, 181, 1)';
    AvatarStyleKit.skin3 = 'rgba(211, 193, 179, 1)';
    AvatarStyleKit.skin4 = 'rgba(199, 168, 140, 1)';
    AvatarStyleKit.skin5 = 'rgba(185, 146, 111, 1)';
    AvatarStyleKit.skin6 = 'rgba(139, 111, 89, 1)';
    AvatarStyleKit.skin7 = 'rgba(146, 100, 64, 1)';
    AvatarStyleKit.skin8 = 'rgba(110, 75, 48, 1)';
    AvatarStyleKit.skin9 = 'rgba(75, 57, 50, 1)';
    AvatarStyleKit.skin1Secondary = 'rgba(242, 229, 201, 1)';
    AvatarStyleKit.skin2Secondary = 'rgba(230, 209, 181, 1)';
    AvatarStyleKit.skin3Secondary = 'rgba(211, 193, 179, 1)';
    AvatarStyleKit.skin4Secondary = 'rgba(199, 168, 140, 1)';
    AvatarStyleKit.skin6Secondary = 'rgba(139, 111, 89, 1)';
    AvatarStyleKit.skin7Secondary = 'rgba(146, 100, 64, 1)';
    AvatarStyleKit.skin8Secondary = 'rgba(110, 75, 48, 1)';
    AvatarStyleKit.skin9Secondary = 'rgba(75, 57, 50, 1)';
    AvatarStyleKit.skin9Tertiary = 'rgba(0, 20, 10, 1)';
    AvatarStyleKit.hair1 = 'rgba(248, 248, 117, 1)';
    AvatarStyleKit.hair2 = 'rgba(248, 218, 37, 1)';
    AvatarStyleKit.hair3 = 'rgba(248, 188, 40, 1)';
    AvatarStyleKit.hair4 = 'rgba(255, 147, 3, 1)';
    AvatarStyleKit.hair5 = 'rgba(236, 87, 14, 1)';
    AvatarStyleKit.hair6 = 'rgba(113, 41, 28, 1)';
    AvatarStyleKit.hair7 = 'rgba(113, 94, 84, 1)';
    AvatarStyleKit.hair8 = 'rgba(139, 98, 57, 1)';
    AvatarStyleKit.hair9 = 'rgba(95, 56, 19, 1)';
    AvatarStyleKit.hair10 = 'rgba(25, 26, 26, 1)';
    AvatarStyleKit.hair11 = 'rgba(164, 159, 244, 1)';
    AvatarStyleKit.hair12 = 'rgba(241, 199, 165, 1)';
    AvatarStyleKit.hair13 = 'rgba(242, 121, 167, 1)';
    AvatarStyleKit.hair14 = 'rgba(105, 205, 177, 1)';
    AvatarStyleKit.hair15 = 'rgba(242, 68, 38, 1)';
    AvatarStyleKit.hair16 = 'rgba(242, 229, 0, 1)';
    AvatarStyleKit.hair17 = 'rgba(112, 189, 234, 1)';
    AvatarStyleKit.hair18 = 'rgba(109, 209, 48, 1)';
    AvatarStyleKit.hair19 = 'rgba(242, 229, 235, 1)';
    AvatarStyleKit.hair20 = 'rgba(113, 94, 84, 1)';
    AvatarStyleKit.hair21 = 'rgba(87, 87, 86, 1)';
    AvatarStyleKit.hair22 = 'rgba(0, 167, 243, 1)';
    AvatarStyleKit.hair23 = 'rgba(255, 255, 255, 1)';

    // Drawing Methods
    AvatarStyleKit.drawAvatarImage = drawAvatarImage;
    AvatarStyleKit.drawJesterHat = drawJesterHat;
    AvatarStyleKit.drawSeaBackground = drawSeaBackground;
    AvatarStyleKit.drawPlainBackground = drawPlainBackground;
    AvatarStyleKit.drawDesertBackground = drawDesertBackground;
    AvatarStyleKit.drawBeachBackground = drawBeachBackground;
    AvatarStyleKit.drawSpaceBackground = drawSpaceBackground;
    AvatarStyleKit.drawMountainsBackground = drawMountainsBackground;
    AvatarStyleKit.drawBlueScarf = drawBlueScarf;
    AvatarStyleKit.drawForestBackground = drawForestBackground;
    AvatarStyleKit.drawPatternBackground7 = drawPatternBackground7;
    AvatarStyleKit.drawPirateHat = drawPirateHat;
    AvatarStyleKit.drawPatternBackground6 = drawPatternBackground6;
    AvatarStyleKit.drawPatternBackground8 = drawPatternBackground8;
    AvatarStyleKit.drawPatternBackground3 = drawPatternBackground3;
    AvatarStyleKit.drawPatternBackground5 = drawPatternBackground5;
    AvatarStyleKit.drawPatternBackground4 = drawPatternBackground4;
    AvatarStyleKit.drawPatternBackground2 = drawPatternBackground2;
    AvatarStyleKit.drawPatternBackground1 = drawPatternBackground1;
    AvatarStyleKit.drawSquintEyes = drawSquintEyes;
    AvatarStyleKit.drawEyesNormal = drawEyesNormal;
    AvatarStyleKit.drawWideEyes = drawWideEyes;
    AvatarStyleKit.drawClosedEyes = drawClosedEyes;
    AvatarStyleKit.drawWideOpenEyes = drawWideOpenEyes;
    AvatarStyleKit.drawWideTiredEyes = drawWideTiredEyes;
    AvatarStyleKit.drawSmileMouth = drawSmileMouth;
    AvatarStyleKit.drawUnhappyMouth = drawUnhappyMouth;
    AvatarStyleKit.drawWorriedMouth = drawWorriedMouth;
    AvatarStyleKit.drawSadMouth = drawSadMouth;
    AvatarStyleKit.drawSmirkMouth = drawSmirkMouth;
    AvatarStyleKit.drawGrinMouth = drawGrinMouth;
    AvatarStyleKit.drawAnnoyedMouth = drawAnnoyedMouth;
    AvatarStyleKit.drawTongueMouth = drawTongueMouth;
    AvatarStyleKit.drawSmilingMouth = drawSmilingMouth;
    AvatarStyleKit.drawLipsMouth = drawLipsMouth;
    AvatarStyleKit.drawEekMouth = drawEekMouth;
    AvatarStyleKit.drawOMouth = drawOMouth;
    AvatarStyleKit.drawBoyHair6 = drawBoyHair6;
    AvatarStyleKit.drawBoyHair1 = drawBoyHair1;
    AvatarStyleKit.drawBoyHair2 = drawBoyHair2;
    AvatarStyleKit.drawBoyHair3 = drawBoyHair3;
    AvatarStyleKit.drawBoyHair4 = drawBoyHair4;
    AvatarStyleKit.drawBoyHair5 = drawBoyHair5;
    AvatarStyleKit.drawBoyHair7 = drawBoyHair7;
    AvatarStyleKit.drawBoyHair8 = drawBoyHair8;
    AvatarStyleKit.drawBoyHair9 = drawBoyHair9;
    AvatarStyleKit.drawBoyHair10 = drawBoyHair10;
    AvatarStyleKit.drawGirlHair1 = drawGirlHair1;
    AvatarStyleKit.drawGirlHair2 = drawGirlHair2;
    AvatarStyleKit.drawGirlHair3 = drawGirlHair3;
    AvatarStyleKit.drawGirlHair4 = drawGirlHair4;
    AvatarStyleKit.drawGirlHair5 = drawGirlHair5;
    AvatarStyleKit.drawGirlHair6 = drawGirlHair6;
    AvatarStyleKit.drawGirlHair7 = drawGirlHair7;
    AvatarStyleKit.drawGirlHair8 = drawGirlHair8;
    AvatarStyleKit.drawGirlHair9 = drawGirlHair9;
    AvatarStyleKit.drawGirlHair10 = drawGirlHair10;
    AvatarStyleKit.drawCatMask = drawCatMask;
    AvatarStyleKit.drawPandaMask = drawPandaMask;
    AvatarStyleKit.drawDisguiseMask = drawDisguiseMask;
    AvatarStyleKit.drawSnorkel = drawSnorkel;
    AvatarStyleKit.drawBatmanMask = drawBatmanMask;
    AvatarStyleKit.drawPuppyMask = drawPuppyMask;
    AvatarStyleKit.drawEyePatch = drawEyePatch;
    AvatarStyleKit.drawHeadScarf = drawHeadScarf;
    AvatarStyleKit.drawScaryMask = drawScaryMask;
    AvatarStyleKit.drawAstronautHelmet = drawAstronautHelmet;
    AvatarStyleKit.drawSombrero = drawSombrero;
    AvatarStyleKit.drawPinkCap = drawPinkCap;
    AvatarStyleKit.drawPinkWoolyHat = drawPinkWoolyHat;
    AvatarStyleKit.drawPinkScarf = drawPinkScarf;
    AvatarStyleKit.drawFlowerHairband = drawFlowerHairband;
    AvatarStyleKit.drawBlueWoolyHat = drawBlueWoolyHat;
    AvatarStyleKit.drawPinkHeadphones = drawPinkHeadphones;
    AvatarStyleKit.drawRoundGlasses = drawRoundGlasses;
    AvatarStyleKit.drawCrown = drawCrown;
    AvatarStyleKit.drawStarHeadband = drawStarHeadband;
    AvatarStyleKit.drawAngledGlasses = drawAngledGlasses;
    AvatarStyleKit.drawBlueTassleHat = drawBlueTassleHat;
    AvatarStyleKit.drawGreenHeadphones = drawGreenHeadphones;
    AvatarStyleKit.drawCoolShades = drawCoolShades;
    AvatarStyleKit.drawGreenHeadband = drawGreenHeadband;
    AvatarStyleKit.drawBlueCap = drawBlueCap;
    AvatarStyleKit.drawOrangeHeadband = drawOrangeHeadband;
    AvatarStyleKit.drawDarkShades = drawDarkShades;
    AvatarStyleKit.drawGreenWoolyHat = drawGreenWoolyHat;
    AvatarStyleKit.drawPlainShirt = drawPlainShirt;
    AvatarStyleKit.drawPlainShirtPocket = drawPlainShirtPocket;
    AvatarStyleKit.drawVNeck = drawVNeck;
    AvatarStyleKit.drawButtons = drawButtons;
    AvatarStyleKit.drawCollar = drawCollar;
    AvatarStyleKit.drawButtons2 = drawButtons2;
    AvatarStyleKit.drawVNeckCollar = drawVNeckCollar;
    AvatarStyleKit.drawWideNeck = drawWideNeck;
    AvatarStyleKit.drawPlainShirtHex = drawPlainShirtHex;
    AvatarStyleKit.drawPlainShirtCircle = drawPlainShirtCircle;
    AvatarStyleKit.drawPlainShirtStripe = drawPlainShirtStripe;
    AvatarStyleKit.drawPlainShirtStripe2 = drawPlainShirtStripe2;
    AvatarStyleKit.drawPlainShirtZip = drawPlainShirtZip;
    AvatarStyleKit.drawCollarButtons = drawCollarButtons;
    AvatarStyleKit.drawPlainShirtZigZag = drawPlainShirtZigZag;
    AvatarStyleKit.drawPlainShirtWave = drawPlainShirtWave;
    AvatarStyleKit.drawPullStrings = drawPullStrings;
    AvatarStyleKit.drawTassles = drawTassles;
    AvatarStyleKit.drawPatternBackground9 = drawPatternBackground9;
    AvatarStyleKit.drawPatternBackground10 = drawPatternBackground10;
    AvatarStyleKit.drawEgyptBackground = drawEgyptBackground;
    AvatarStyleKit.drawCastleBackground = drawCastleBackground;
    AvatarStyleKit.drawHeartsBackground = drawHeartsBackground;
    AvatarStyleKit.drawRainbowBackground = drawRainbowBackground;
    AvatarStyleKit.drawSnowyBackground = drawSnowyBackground;
    AvatarStyleKit.drawSeaBackground2 = drawSeaBackground2;
    AvatarStyleKit.drawConstellationsBackground = drawConstellationsBackground;
    AvatarStyleKit.drawArtBackground = drawArtBackground;
    AvatarStyleKit.drawWorldBackground = drawWorldBackground;
    AvatarStyleKit.drawWorldBackground2 = drawWorldBackground2;
    AvatarStyleKit.drawTreasureMapBackground = drawTreasureMapBackground;
    AvatarStyleKit.drawMusicBackground = drawMusicBackground;
    AvatarStyleKit.drawRomanBackground = drawRomanBackground;
    AvatarStyleKit.drawChemistryBackground = drawChemistryBackground;
    AvatarStyleKit.drawFootballBackground = drawFootballBackground;
    AvatarStyleKit.drawBalloonsBackground = drawBalloonsBackground;
    AvatarStyleKit.drawGirlHair11 = drawGirlHair11;
    AvatarStyleKit.drawGirlHair12 = drawGirlHair12;
    AvatarStyleKit.drawGirlHair13 = drawGirlHair13;
    AvatarStyleKit.drawGirlHair14 = drawGirlHair14;
    AvatarStyleKit.drawGirlHair15 = drawGirlHair15;
    AvatarStyleKit.drawGirlHair16 = drawGirlHair16;
    AvatarStyleKit.drawBoyHair11 = drawBoyHair11;
    AvatarStyleKit.drawBoyHair12 = drawBoyHair12;
    AvatarStyleKit.drawBoyHair13 = drawBoyHair13;
    AvatarStyleKit.drawBoyHair14 = drawBoyHair14;
    AvatarStyleKit.drawBoyHair15 = drawBoyHair15;
    AvatarStyleKit.drawBoyHair16 = drawBoyHair16;
    AvatarStyleKit.drawBoyHair17 = drawBoyHair17;
    AvatarStyleKit.drawPigMask = drawPigMask;
    AvatarStyleKit.drawElephantMask = drawElephantMask;
    AvatarStyleKit.drawGiraffeMask = drawGiraffeMask;
    AvatarStyleKit.drawLionMask = drawLionMask;
    AvatarStyleKit.drawTigerMask = drawTigerMask;
    AvatarStyleKit.drawReindeerMask = drawReindeerMask;
    AvatarStyleKit.drawZebraMask = drawZebraMask;
    AvatarStyleKit.drawDogMask = drawDogMask;
    AvatarStyleKit.drawUnicornMaskBk = drawUnicornMaskBk;
    AvatarStyleKit.drawMonkeyMask = drawMonkeyMask;
    AvatarStyleKit.drawUnicornMask = drawUnicornMask;
    AvatarStyleKit.drawFlowerShirt = drawFlowerShirt;
    AvatarStyleKit.drawPearlsShirt = drawPearlsShirt;
    AvatarStyleKit.drawMidStripeShirt = drawMidStripeShirt;
    AvatarStyleKit.drawVFrillCollarShirt = drawVFrillCollarShirt;
    AvatarStyleKit.drawHZigZagShirt = drawHZigZagShirt;
    AvatarStyleKit.drawHijabShirt = drawHijabShirt;
    AvatarStyleKit.drawHalloweenShirt = drawHalloweenShirt;
    AvatarStyleKit.drawBowtieShirt = drawBowtieShirt;
    AvatarStyleKit.drawTieShirt = drawTieShirt;
    AvatarStyleKit.drawCheckShirt = drawCheckShirt;
    AvatarStyleKit.drawWandShirt = drawWandShirt;
    AvatarStyleKit.drawFootballShirt1 = drawFootballShirt1;
    AvatarStyleKit.drawFootballShirt2 = drawFootballShirt2;
    AvatarStyleKit.drawFootballShirt3 = drawFootballShirt3;
    AvatarStyleKit.drawFootballShirt4 = drawFootballShirt4;
    AvatarStyleKit.drawFootballShirt5 = drawFootballShirt5;
    AvatarStyleKit.drawFootballShirt6 = drawFootballShirt6;
    AvatarStyleKit.drawFootballShirt7 = drawFootballShirt7;
    AvatarStyleKit.drawFootballShirt8 = drawFootballShirt8;
    AvatarStyleKit.drawFootballShirt9 = drawFootballShirt9;
    AvatarStyleKit.drawFootballShirt10 = drawFootballShirt10;
    AvatarStyleKit.drawFootballShirt11 = drawFootballShirt11;
    AvatarStyleKit.drawFootballShirt12 = drawFootballShirt12;
    AvatarStyleKit.drawFootballShirt13 = drawFootballShirt13;
    AvatarStyleKit.drawFootballShirt14 = drawFootballShirt14;
    AvatarStyleKit.drawFootballShirt15 = drawFootballShirt15;
    AvatarStyleKit.drawFootballShirt16 = drawFootballShirt16;
    AvatarStyleKit.drawFootballShirt17 = drawFootballShirt17;
    AvatarStyleKit.drawFootballShirt18 = drawFootballShirt18;
    AvatarStyleKit.drawFootballShirt19 = drawFootballShirt19;
    AvatarStyleKit.drawFootballShirt20 = drawFootballShirt20;
    AvatarStyleKit.drawFootballShirt21 = drawFootballShirt21;
    AvatarStyleKit.drawFootballShirt22 = drawFootballShirt22;
    AvatarStyleKit.drawFootballShirt23 = drawFootballShirt23;
    AvatarStyleKit.drawFootballShirt24 = drawFootballShirt24;
    AvatarStyleKit.drawRedFlower = drawRedFlower;
    AvatarStyleKit.drawLaurel = drawLaurel;
    AvatarStyleKit.drawFaceLightning = drawFaceLightning;
    AvatarStyleKit.drawWizardHat = drawWizardHat;
    AvatarStyleKit.drawGreenHairBow = drawGreenHairBow;
    AvatarStyleKit.drawCowboyHat = drawCowboyHat;
    AvatarStyleKit.drawWitchHat = drawWitchHat;
    AvatarStyleKit.drawMouseNose = drawMouseNose;
    AvatarStyleKit.drawRabbitEars = drawRabbitEars;
    AvatarStyleKit.drawSmallGreenBows = drawSmallGreenBows;
    AvatarStyleKit.drawGoldHoopEarrings = drawGoldHoopEarrings;
    AvatarStyleKit.drawHairPearls = drawHairPearls;
    AvatarStyleKit.drawSquareGlasses = drawSquareGlasses;
    AvatarStyleKit.drawVikingHelmet = drawVikingHelmet;
    AvatarStyleKit.drawSmallbeard = drawSmallbeard;
    AvatarStyleKit.drawEgyptHelmet = drawEgyptHelmet;
    AvatarStyleKit.drawRedBeret = drawRedBeret;
    AvatarStyleKit.drawWhiteGlasses = drawWhiteGlasses;
    AvatarStyleKit.drawBackwardsCap = drawBackwardsCap;
    AvatarStyleKit.drawFreckles = drawFreckles;
    AvatarStyleKit.drawLongMustache = drawLongMustache;
    AvatarStyleKit.drawKippah = drawKippah;
    AvatarStyleKit.drawLinesGlasses = drawLinesGlasses;
    AvatarStyleKit.drawSantaHat = drawSantaHat;
    AvatarStyleKit.drawChefHat = drawChefHat;
    AvatarStyleKit.drawBeeWings = drawBeeWings;
    AvatarStyleKit.drawBeeHeadband = drawBeeHeadband;
    AvatarStyleKit.drawFaceWand = drawFaceWand;

    // Utilities
    AvatarStyleKit.clearCanvas = clearCanvas;
    AvatarStyleKit.makeRect = makeRect;

})();
module.exports = AvatarStyleKit